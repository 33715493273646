import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../Functions/Owner";

interface AssetsOtherassetProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns otherasset component for advice template && document.
 */
export const AssetsOtherasset: React.FC<AssetsOtherassetProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  type Otherasset = {
    assets_ID: number | null;
    Description: string;
    Owner: string;
    Value: string;
  };

  const summaryOtherasset: Otherasset[] = group
    ? content?.ContentType === "Grouped"
      ? getGroupAssets(group).otherassets
      : content?.asset_ID
      ? getGroupAssets(group).otherassets.find(
          (otherasset: Otherasset) => content?.asset_ID === otherasset.assets_ID
        )
      : {}
    : contenttemplate?.ContentType === "Grouped"
    ? [{ Description: "Home Contents", Owner: "Joint", Value: "$320,000" }]
    : { Description: "Home Contents", Owner: "Joint", Value: "$320,000" };

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryOtherasset.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Description
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Owner
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Value
          </StyledText>
        </div>
      ) : (
        <h5>No other assets recorded</h5>
      )}
      {summaryOtherasset.map((other, objectIndex) => {
        return (
          <div
           className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(other.Description)}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(
                group
                  ? ownerOptionsUpdateAssetDefaultValue(other, group).text
                  : other.Owner
              )}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(other.Value, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div>Individual other asset view</div>
    </React.Fragment>
  );
};
