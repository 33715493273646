import React from "react";
import {
  Theme,
  useFindUniqueFeesettingsQuery,
} from "../../../../../../../../../../codegen/schema";
import { StyledText } from "../../../../../../../../../../Components/Advice/StyledText";
import { ComponentLoader } from "../../../../../../../../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../../../../../../../../Components/Refetch";

interface AdviceAgreementPaymentInstructionsProps {
  theme?: Theme;
}

/**
 * @created 01-02-2023
 * @updated 25-05-2023
 * @returns Returns advice agreement services component for advice template && document.
 */
export const AdviceAgreementPaymentInstructions: React.FC<
  AdviceAgreementPaymentInstructionsProps
> = ({ theme }) => {
  const {
    data: { findUniqueFeesettings: feesettings } = {},
    error: feesettingsError,
    loading: feesettingsLoading,
    refetch: feesettingsRefetch,
  } = useFindUniqueFeesettingsQuery();

  if (!feesettings && feesettingsLoading) {
    return <ComponentLoader />;
  }

  if (feesettingsError && !feesettingsLoading) {
    return (
      <Refetch
        refetch={() => {
          feesettingsRefetch();
        }}
      />
    );
  }

  if (!feesettings) return null;

  return (
    <React.Fragment>
      <div
        className="grid bg-neutral-50"
        style={{ gridTemplateColumns: "repeat(5, 3.5cm)" }}
      >
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
        />
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
        />
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
          
        >
          BSB
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
          
        >
          Account No.
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
          
        >
          Frequency
        </StyledText>
      </div>

      <div
        className="grid mt-2"
        style={{
          gridTemplateColumns: "repeat(5, 3.5cm)",
          borderBottom: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
        }}
      >
        <StyledText
          element={theme?.element.find((element) => element.Type === "P")}
          
        >
          John Smith
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
          className="col-start-2 col-span-full"
        >
          $5,000.00
        </StyledText>
      </div>
    </React.Fragment>
  );
};
