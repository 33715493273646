import { gql } from "@apollo/client";
import { scenarioFragment, scenarioCoreFragment } from "./fragment";

export const scenario = gql`
  subscription Scenario($where: scenarioWhereUniqueInput) {
    scenario(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenario
      }
    }
  }
  ${scenarioFragment}
`;

export const scenarioMany = gql`
  subscription ScenarioMany($where: scenarioWhereInput) {
    scenarioMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioCore
      }
    }
  }
  ${scenarioCoreFragment}
`;
