export function AssetAllocationModelDescription() {
  return (
    <p className="text-2xs italic">
      The asset allocation model displays the asset allocation of all groups
      assets as a percentage of the total asset value. This uses data directly
      from the group and not from the scenario data.
    </p>
  );
}

export function AssetAllocationCalculationTooltip() {
  return (
    <>
      <p>
        This model uses live data rather than scenario data. Every asset in MOAS
        can have an asset allocation added to it, this is used to calculate a
        groups total asset allocation.
        <br />
        <br />
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/group-page/financial-data/assets-and-liabilities#asset-allocation"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for more information on asset allocation values.
        </a>
      </p>

      <p>
        To find the groups total asset allocation, the asset value is multipled
        by the allocation then divided by the group asset total e.g <br />{" "}
        <br />
        <code>
          CashTotal = (assetValue X assetAllocationCashPercentage) /
          groupAssetsTotal
        </code>
      </p>
    </>
  );
}
