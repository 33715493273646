import {
  FindManyInsuranceQuery,
  FindManyInsuranceQueryVariables,
} from "../../../codegen/schema";
import { FindManyInsurance } from "..";
import { QueryResult } from "@apollo/client";

export function findManyInsuranceSubscription(
  query: Pick<
    QueryResult<FindManyInsuranceQuery, FindManyInsuranceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindManyInsurance(query);
}
