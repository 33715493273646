import { gql } from "@apollo/client";
import { modelCoreFragment, modelFragment } from "./fragment";

export const findUniqueModel = gql`
  query FindUniqueModel($where: modelWhereUniqueInput!) {
    findUniqueModel(where: $where) {
      ...model
    }
  }
  ${modelFragment}
`;

export const findManyModel = gql`
  query FindManyModel(
    $where: modelWhereInput
    $distinct: [ModelScalarFieldEnum!]
    $orderBy: [modelOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: modelWhereUniqueInput
  ) {
    findManyModel(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...modelCore
    }
  }
  ${modelCoreFragment}
`;
