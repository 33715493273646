import { gql } from "@apollo/client";

export const scenarioinsuranceCoreFragment = gql`
  fragment scenarioinsuranceCore on scenarioinsurance {
    ID
    scenario_ID
    scenario_advice_ID
    scenario_advice_groups_ID
    scenario_advice_groups_organisation_ID
    Type
    Name
    AnnualPremium
    ExistingCover
    RecommendedCover
    ExtinguishDebt
    EstatePlanningCost
    MedicalOneOffCost
    MedicalAnnualCost
    MedicalNumberOfYears
    PercentageOfCover
    BenefitToAge
    BenefitPeriod
    PercentagePaidBySuperannuation

    ClientInsured_scenarioclient_ID

    clients_ID
    insurance_ID

    create_time
    update_time
    created_by
    updated_by
  }
`;

export const scenarioinsuranceFragment = gql`
  fragment scenarioinsurance on scenarioinsurance {
    ...scenarioinsuranceCore
  }
  ${scenarioinsuranceCoreFragment}
`;
