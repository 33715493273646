import { QueryResult } from "@apollo/client";
import {
  OtherliabilitiesSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { otherliabilities } from "../subscription";

interface OtherliabilitiesResolverParams {
  prev: FindUniqueGroupQuery;
  payload: OtherliabilitiesSubscription["otherliabilities"];
}

function createOtherliabilities({
  prev,
  payload,
}: OtherliabilitiesResolverParams) {
  const {
    data: createOtherliabilities,
    clients_has_liabilities,
    entities_has_liabilities,
  } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          liabilities: {
            ...client.liabilities,
            otherliabilities: clients_has_liabilities.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.liabilities.otherliabilities, createOtherliabilities]
              : client.liabilities.otherliabilities,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          liabilities: {
            ...entity.liabilities,
            otherliabilities: entities_has_liabilities.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.liabilities.otherliabilities, createOtherliabilities]
              : entity.liabilities.otherliabilities,
          },
        };
      }),
    },
  });
}

function updateOtherliabilities({
  prev,
  payload,
}: OtherliabilitiesResolverParams) {
  const {
    data: updateOtherliabilities,
    clients_has_liabilities,
    entities_has_liabilities,
  } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the otherliabilities object
   * This is because updateOtherliabilities only returns the otherliabilities info
   * without assetallocation, notes, etc.
   */
  var updatedOtherliabilities = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.liabilities.otherliabilities.forEach((otherliabilities) => {
      if (
        otherliabilities.liabilities_ID ===
        updateOtherliabilities.liabilities_ID
      ) {
        result = {
          ...otherliabilities,
          ...updateOtherliabilities,
        };
      }
    });
    return result;
  }, updateOtherliabilities);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update otherliabilities first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        liabilities: {
          ...client.liabilities,
          otherliabilities: [
            ...client.liabilities.otherliabilities
              .map((otherliabilities) =>
                otherliabilities.ID === updatedOtherliabilities.ID
                  ? { ...otherliabilities, ...updatedOtherliabilities }
                  : otherliabilities
              )
              .filter(
                (otherliabilities) =>
                  ![...clients_has_liabilities]
                    .map((entry) => entry.liabilities_ID)
                    .includes(otherliabilities.liabilities_ID)
              ),
            ...(clients_has_liabilities.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? clients_has_liabilities.reduce<
                  Array<
                    OtherliabilitiesSubscription["otherliabilities"]["data"]
                  >
                >((relationResult, relation) => {
                  if (
                    relation.liabilities_ID ===
                      updatedOtherliabilities.liabilities_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [
                      ...relationResult,
                      updatedOtherliabilities,
                    ];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },

        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          liabilities: {
            ...goal.liabilities,
            otherliabilities: goal.liabilities.otherliabilities.map(
              (otherliabilities) =>
                otherliabilities.ID === updatedOtherliabilities.ID
                  ? { ...otherliabilities, ...updatedOtherliabilities }
                  : otherliabilities
            ),
          },
        })),
      })),

      /** Remove the existing otherliabilities object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        liabilities: {
          ...entity.liabilities,
          otherliabilities: [
            ...entity.liabilities.otherliabilities
              .map((otherliabilities) =>
                otherliabilities.ID === updatedOtherliabilities.ID
                  ? { ...otherliabilities, ...updatedOtherliabilities }
                  : otherliabilities
              )
              .filter(
                (otherliabilities) =>
                  ![...entities_has_liabilities]
                    .map((entry) => entry.liabilities_ID)
                    .includes(otherliabilities.liabilities_ID)
              ),
            ...(entities_has_liabilities.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? entities_has_liabilities.reduce<
                  Array<
                    OtherliabilitiesSubscription["otherliabilities"]["data"]
                  >
                >((relationResult, relation) => {
                  if (
                    relation.liabilities_ID ===
                      updatedOtherliabilities.liabilities_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [
                      ...relationResult,
                      updatedOtherliabilities,
                    ];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
      })),
    },
  });
}

function deleteOtherliabilities({
  prev,
  payload,
}: OtherliabilitiesResolverParams) {
  const { data: deleteOtherliabilities } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          liabilities: {
            ...client.liabilities,
            otherliabilities: [...client.liabilities.otherliabilities].filter(
              (otherliability) =>
                otherliability.ID !== deleteOtherliabilities.ID
            ),
          },
          /** Update otherliabilities in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            liabilities: {
              ...goal.liabilities,
              otherliabilities: goal.liabilities.otherliabilities.filter(
                (otherliabilities) =>
                  otherliabilities.ID !== deleteOtherliabilities.ID
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          liabilities: {
            ...entity.liabilities,
            otherliabilities: [...entity.liabilities.otherliabilities].filter(
              (otherliability) =>
                otherliability.ID !== deleteOtherliabilities.ID
            ),
          },
        };
      }),
    },
  });
}

function otherliabilitiesResolver({
  prev,
  payload,
}: OtherliabilitiesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createOtherliabilities({ prev, payload });

    case MutationType.Update:
      return updateOtherliabilities({ prev, payload });

    case MutationType.Delete:
      return deleteOtherliabilities({ prev, payload });

    default:
      return prev;
  }
}

export function otherliabilitiesFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: otherliabilities,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: OtherliabilitiesSubscription };
      }
    ) =>
      otherliabilitiesResolver({
        prev,
        payload: payload.subscriptionData.data.otherliabilities,
      }),
    variables: query.variables,
  });
}
