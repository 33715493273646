import { QueryResult } from "@apollo/client";
import {
  IncomeSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { income } from "../subscription";

interface IncomeResolverParams {
  prev: FindUniqueGroupQuery;
  payload: IncomeSubscription["income"];
}

function createIncome({ prev, payload }: IncomeResolverParams) {
  const income = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          income:
            income.clients_ID === client.ID
              ? [...client.income, income]
              : client.income,
        };
      }),
    },
  });
}

function updateIncome({ prev, payload }: IncomeResolverParams) {
  const income = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          income: client.income.map((entry) =>
            entry.ID === income.ID ? { ...entry, ...income } : entry
          ),
        };
      }),
    },
  });
}

function deleteIncome({ prev, payload }: IncomeResolverParams) {
  const income = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          income: [...client.income].filter((entry) => entry.ID !== income.ID),
        };
      }),
    },
  });
}

function incomeResolver({ prev, payload }: IncomeResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createIncome({ prev, payload });

    case MutationType.Update:
      return updateIncome({ prev, payload });

    case MutationType.Delete:
      return deleteIncome({ prev, payload });

    default:
      return prev;
  }
}

export function incomeFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: income,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: IncomeSubscription } }
    ) =>
      incomeResolver({
        prev,
        payload: payload.subscriptionData.data.income
      }),
    variables: query.variables,
  });
}
