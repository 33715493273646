import { QueryResult } from "@apollo/client";
import {
  ScenariocontributionwithdrawalSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { scenariocontributionwithdrawal } from "../subscription";

interface ScenariocontributionwithdrawalResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ScenariocontributionwithdrawalSubscription["scenariocontributionwithdrawal"];
}

function createScenariocontributionwithdrawal({
  prev,
  payload,
}: ScenariocontributionwithdrawalResolverParams) {
  const {
    data: scenariocontributionwithdrawal,
    scenarioasset_has_scenariocontributionwithdrawal,
    scenarioliability_has_scenariocontributionwithdrawal,
  } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) => ({
        ...advice,
        scenario: advice.scenario.map((scenario) => ({
          ...scenario,
          /** SCENARIO ASSET */
          scenarioasset: scenario.scenarioasset.map((scenarioasset) =>
            scenarioasset_has_scenariocontributionwithdrawal.some(
              (relation) => relation.scenarioasset_ID === scenarioasset.ID
            )
              ? {
                  ...scenarioasset,
                  scenariocontributionwithdrawal: [
                    ...scenarioasset.scenariocontributionwithdrawal,
                    scenariocontributionwithdrawal,
                  ],
                }
              : scenarioasset
          ),
          /** SCENARIO LIABILITY */
          scenarioliability: scenario.scenarioliability.map(
            (scenarioliability) =>
              scenarioliability_has_scenariocontributionwithdrawal.some(
                (relation) =>
                  relation.scenarioliability_ID === scenarioliability.ID
              )
                ? {
                    ...scenarioliability,
                    scenariocontributionwithdrawal: [
                      ...scenarioliability.scenariocontributionwithdrawal,
                      scenariocontributionwithdrawal,
                    ],
                  }
                : scenarioliability
          ),
        })),
      })),
    },
  });
}

function updateScenariocontributionwithdrawal({
  prev,
  payload,
}: ScenariocontributionwithdrawalResolverParams) {
  const scenariocontributionwithdrawal = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) => ({
        ...advice,
        scenario: advice.scenario.map((scenario) => ({
          ...scenario,
          /** SCENARIO ASSET */
          scenarioasset: scenario.scenarioasset.map((scenarioasset) => ({
            ...scenarioasset,
            scenariocontributionwithdrawal:
              scenarioasset.scenariocontributionwithdrawal.map((cw) =>
                cw.ID === scenariocontributionwithdrawal.ID
                  ? { ...cw, ...scenariocontributionwithdrawal }
                  : cw
              ),
          })),
          /** SCENARIO LIABILITY */
          scenarioliability: scenario.scenarioliability.map(
            (scenarioliability) => ({
              ...scenarioliability,
              scenariocontributionwithdrawal:
                scenarioliability.scenariocontributionwithdrawal.map((cw) =>
                  cw.ID === scenariocontributionwithdrawal.ID
                    ? { ...cw, ...scenariocontributionwithdrawal }
                    : cw
                ),
            })
          ),
        })),
      })),
    },
  });
}

function deleteScenariocontributionwithdrawal({
  prev,
  payload,
}: ScenariocontributionwithdrawalResolverParams) {
  const scenariocontributionwithdrawal = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) => ({
        ...advice,
        scenario: advice.scenario.map((scenario) => ({
          ...scenario,
          /** SCENARIO ASSET */
          scenarioasset: scenario.scenarioasset.map((scenarioasset) => ({
            ...scenarioasset,
            scenariocontributionwithdrawal:
              scenarioasset.scenariocontributionwithdrawal.filter(
                (cw) => cw.ID !== scenariocontributionwithdrawal.ID
              ),
          })),
          /** SCENARIO LIABILITY */
          scenarioliability: scenario.scenarioliability.map(
            (scenarioliability) => ({
              ...scenarioliability,
              scenariocontributionwithdrawal:
                scenarioliability.scenariocontributionwithdrawal.filter(
                  (cw) => cw.ID !== scenariocontributionwithdrawal.ID
                ),
            })
          ),
        })),
      })),
    },
  });
}

function scenariocontributionwithdrawalResolver({
  prev,
  payload,
}: ScenariocontributionwithdrawalResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenariocontributionwithdrawal({ prev, payload });

    case MutationType.Update:
      return updateScenariocontributionwithdrawal({ prev, payload });

    case MutationType.Delete:
      return deleteScenariocontributionwithdrawal({ prev, payload });

    default:
      return prev;
  }
}

export function scenariocontributionwithdrawalFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenariocontributionwithdrawal,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: {
          data: ScenariocontributionwithdrawalSubscription;
        };
      }
    ) =>
      scenariocontributionwithdrawalResolver({
        prev,
        payload: payload.subscriptionData.data.scenariocontributionwithdrawal,
      }),
    variables: query.variables,
  });
}
