import React, { useContext } from "react";

import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { StoreContext } from "../../../../../../../Datastore/Store";

import { LiveData } from "../../../Calculations";
import { RechartsCustomLabelPiechart, RechartsCustomRenderLegendPiechart } from "../../../../Utils/Recharts";


interface Props {
  liveData: LiveData[] | undefined;
}

export const AssetDebtCompositionLiveModelGraph: React.FC<Props> = ({
  liveData,
}) => {
  const [context] = useContext(StoreContext);

  if (!liveData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <PieChart>
        <Pie
          data={liveData}
          dataKey="datakey"
          labelLine={false}
          label={RechartsCustomLabelPiechart}
          cx={"50%"}
          cy={"50%"}
          innerRadius={40}
          outerRadius={100}
          legendType="circle"
          paddingAngle={1}
          minAngle={3}
          isAnimationActive={false}
        >
          {liveData.map((_, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={
                  index === 4
                    ? context.colours.purple300
                    : index === 5
                    ? context.colours.purple100
                    : index === 6
                    ? context.colours.purple500
                    : context.colours[Object.keys(context.colours)[index]]
                }
                strokeWidth={2}
              />
            );
          })}
        </Pie>
        <Legend
          content={RechartsCustomRenderLegendPiechart}
          layout={"vertical"}
          verticalAlign="middle"
          align="right"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
