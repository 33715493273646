import { QueryResult } from "@apollo/client";
import {
  FindUniqueFileQuery,
  FindUniqueFileQueryVariables,
} from "../../../codegen/schema";
import { fileFindUniqueFile } from "../reducers";

export function findUniqueFileSubscription(
  query: Pick<
    QueryResult<FindUniqueFileQuery, FindUniqueFileQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  fileFindUniqueFile(query);
}
