import React from "react";
import { SuperannuationInsuranceComparisonModelGraph } from "../Graph";
import { ComparisonData } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface SuperannuationInsurancePlaceholderModelProps {
  theme?: Theme;
}

var data: ComparisonData[] = [
  {
    scenarioDataKey: "Scenario 1",
    withInsurance: 450000,
    withoutInsurance: 500000,
  },
  {
    scenarioDataKey: "Scenario 2",
    withInsurance: 550000,
    withoutInsurance: 600000,
  },
  {
    scenarioDataKey: "Scenario 3",
    withInsurance: 350000,
    withoutInsurance: 400000,
  },
];

export const SuperannuationInsuranceComparisonPlaceholderModel = ({
  theme,
}: SuperannuationInsurancePlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Superannuation Insurance
        </StyledText>

        <ModelTooltip type={Model_Type.SuperannuationInsurance} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <SuperannuationInsuranceComparisonModelGraph comparisonData={data} />
      </div>
    </React.Fragment>
  );
};
