import { gql } from "@apollo/client";
import { furtheradviceFragment } from ".";

export const updateOneFurtheradvice = gql`
  mutation UpdateOneFurtherAdvice(
    $data: furtheradviceUpdateInput!
    $where: furtheradviceWhereUniqueInput!
  ) {
    updateOneFurtheradvice(data: $data, where: $where) {
      ...furtheradvice
    }
  }
  ${furtheradviceFragment}
`;
 