import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { liabilitiesCoreFragment } from "../liabilities";
import { notesFragment } from "../notes/fragment";

export const offsetCoreFragment = gql`
  fragment offsetCore on offsetCore {
    ID
    assets_ID
    Bank
    BSB
    AccountNumber
    Amount
    RelatedLoan
    Purpose
    OwnershipType
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const offsetFragment = gql`
  fragment offset on offset {
    ID
    assets_ID
    Bank
    BSB
    AccountNumber
    Amount
    RelatedLoan
    Purpose
    OwnershipType
    create_time
    update_time
    created_by
    updated_by

    assetallocation {
      ...assetallocation
    }
    liabilities {
      ...liabilitiesCore
    }
    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${liabilitiesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;
