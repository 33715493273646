import { Income } from "../../codegen/schema";

/** function netIncomeCalculation
 * @author Lara de Maroussem
 * @date 05-08-2021
 * @param {Object} income - to get taxable income and tax paid
 * @returns "GrossIncome - TaxPaid" which equals net income
 * @description function gets GrossIncome and Tax paid to calculate net income.
 */
export function netIncomeCalculation(income: Income) {
  return parseFloat(income.GrossIncome) - Math.abs(parseFloat(income.TaxPaid));
}
