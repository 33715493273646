import { ButtonProps } from "../../Buttons";
import { Fragment } from "react/jsx-runtime";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import clsx from "clsx";
import { Tooltip } from "../../Tooltip";
import { useEffect, useState } from "react";
import { AnchorProps } from "@headlessui/react/dist/internal/floating";

interface ButtonMenuProps {
  buttonProps: ButtonProps;
  options: Array<{
    disabled?: boolean;
    onClick: Function;
    text: string | JSX.Element;
    className?: string;
    tooltip?: string;
    icon?: string;
    key?: string;
    active?: boolean;
  }>;
  id?: string;
}

export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  buttonProps,
  options,
  id,
}) => {
  const [anchor, setAnchor] = useState("bottom-left");
  useEffect(() => {
    var dropdownDiv = document.getElementById("menu-" + id);
    var anchor = "";

    if (dropdownDiv) {
      var position = dropdownDiv.getBoundingClientRect();
      var x = position.left;
      var y = position.top;

      if (y > window.innerHeight / 2) {
        anchor = "top";
      } else {
        anchor = "bottom";
      }
      if (x > window.innerWidth / 2) {
        anchor += " end";
      } else {
        anchor += " start";
      }
    }

    setAnchor(anchor);
  }, [id]);

  return (
    <Menu
      as="div"
      className="relative text-left max-w-48 flex justify-center"
      id={"menu-" + id}
    >
      <Tooltip title={buttonProps.tooltip ?? ""}>
        <MenuButton
          className={clsx(
            buttonProps.type,
            buttonProps.shadow ? "shadow" : "",
            "inline-flex w-full gap-2 justify-center min-w-9 min-h-5 items-center py-2",
            "rounded-md text-neutral-300 hover:bg-gray-50"
          )}
          disabled={buttonProps.disabled}
        >
          {buttonProps.functionality &&
            buttonProps.functionality !== "none" && (
              <i
                className={clsx(
                  buttonProps.functionality === "add"
                    ? "fa-solid fa-plus"
                    : "fa-regular",
                  "icon "
                )}
              />
            )}

          {buttonProps.text}
        </MenuButton>
      </Tooltip>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          className={clsx(
            "[--anchor-gap:5px] z-50 w-44 rounded-md max-h-60 overflow-auto bg-white shadow-lg ring-1 ring-neutral-500 ring-opacity-5 outline-none"
          )}
          anchor={anchor as AnchorProps}
          portal={true}
        >
          <div className="py-1">
            {buttonProps.disabled !== true &&
              (options.length > 0 ? (
                options.map((option, index) => {
                  return (
                    <MenuItem key={index}>
                      {/* <Tooltip title={option.tooltip ? option.tooltip : ""}> */}
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          if (option.disabled !== true) option.onClick();
                          // setOpen(false);
                        }}
                        className={clsx(
                          option.active ? "font-semibold" : "font-normal",
                          "group w-full flex justify-between items-center px-4 py-2 gap-1 text-sm text-neutral-600 cursor-pointer hover:bg-blue-500 hover:text-white"
                        )}
                      >
                        <div className="flex gap-1 items-centre">
                          {option.icon && <div className={option.icon} />}
                          {option.text}
                        </div>
                        {option.active && (
                          <i className="fa-solid fa-check text-blue-500 group-hover:text-white" />
                        )}
                      </div>
                      {/* </Tooltip> */}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem>
                  <div
                    className={clsx(
                      "group w-full flex justify-between items-center px-4 py-2 gap-1 text-sm text-neutral-400"
                    )}
                  >
                    <i>No options</i>
                  </div>
                </MenuItem>
              ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};
