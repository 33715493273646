import { QueryResult } from "@apollo/client";
import {
  PagetemplateManySubscription,
  FindManyPagetemplateQuery,
  MutationType,
  FindManyPagetemplateQueryVariables,
} from "../../../codegen/schema";
import { pagetemplateMany } from "../subscription";

interface PagetemplateResolverParams {
  prev: FindManyPagetemplateQuery;
  payload: PagetemplateManySubscription["pagetemplateMany"];
}

function createPagetemplate({ prev, payload }: PagetemplateResolverParams) {
  const pagetemplate = payload.data;

  return Object.assign({}, prev, {
    findManyPagetemplate: [...prev.findManyPagetemplate, pagetemplate].sort(
      (a, b) => a.Position! - b.Position!
    ),
  });
}

function updatePagetemplate({ prev, payload }: PagetemplateResolverParams) {
  const pagetemplate = payload.data;

  return Object.assign({}, prev, {
    findManyPagetemplate: prev.findManyPagetemplate
      .map((pagetemplates) =>
        pagetemplates.ID === pagetemplate.ID
          ? {
              ...pagetemplates,
              ...pagetemplate,
            }
          : pagetemplates
      )
      .sort((a, b) => a.Position! - b.Position!),
  });
}

function deletePagetemplate({ prev, payload }: PagetemplateResolverParams) {
  const pagetemplate = payload.data;

  return Object.assign({}, prev, {
    findManyPagetemplate: prev.findManyPagetemplate
      .filter((pagetemplates) => pagetemplates.ID !== pagetemplate.ID)
      .sort((a, b) => a.Position! - b.Position!),
  });
}

function pagetemplateResolver({ prev, payload }: PagetemplateResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createPagetemplate({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyPagetemplate.some((pagetemplate) => pagetemplate.ID === data.ID)
        ? updatePagetemplate({ prev, payload })
        : createPagetemplate({ prev, payload });

    case MutationType.Delete:
      return deletePagetemplate({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyPagetemplate(
  query: Pick<
    QueryResult<FindManyPagetemplateQuery, FindManyPagetemplateQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: pagetemplateMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: PagetemplateManySubscription } }
    ) =>
      pagetemplateResolver({
        prev,
        payload: payload.subscriptionData.data.pagetemplateMany,
      }),
    variables: query.variables,
  });
}
