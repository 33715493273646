import { QueryResult } from "@apollo/client";
import { FindManyContenttemplate } from "..";
import {
  FindManyContenttemplateQuery,
  FindManyContenttemplateQueryVariables,
} from "../../../codegen/schema";

export function findManyContenttemplateSubscription(
  query: Pick<
    QueryResult<FindManyContenttemplateQuery, FindManyContenttemplateQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindManyContenttemplate(query);
}
