import { gql } from "@apollo/client";
import { cashFragment } from ".";
/** Cash */

export const createOneCash = gql`
  mutation CreateOneCash($data: cashCreateInput!) {
    createOneCash(data: $data) {
      ...cash
    }
  }
  ${cashFragment}
`;

export const updateOneCash = gql`
  mutation UpdateOneCash(
    $data: cashUpdateInput!
    $where: cashWhereUniqueInput!
  ) {
    updateOneCash(data: $data, where: $where) {
      ...cash
    }
  }
  ${cashFragment}
`;

export const deleteOneCash = gql`
  mutation deleteOneCash($where: cashWhereUniqueInput!) {
    deleteOneCash(where: $where) {
      ...cash
    }
  }
  ${cashFragment}
`;
