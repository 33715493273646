import { QueryResult } from "@apollo/client";
import {
  FindUniqueEstateplanningQuery,
  FindUniqueEstateplanningQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueEstateplanningQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, estateplanning_has_notes } = payload;

  if (!prev.findUniqueEstateplanning) return prev;
  return Object.assign({}, prev, {
    findUniqueEstateplanning: {
      ...prev.findUniqueEstateplanning,
      notes: estateplanning_has_notes.some(
        (relation) =>
          relation.estateplanning_ID === prev.findUniqueEstateplanning?.ID
      )
        ? [...prev.findUniqueEstateplanning.notes, notes]
        : prev.findUniqueEstateplanning.notes,
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueEstateplanning) return prev;
  return Object.assign({}, prev, {
    findUniqueEstateplanning: {
      ...prev.findUniqueEstateplanning,
      notes: prev.findUniqueEstateplanning.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueEstateplanning) return prev;
  return Object.assign({}, prev, {
    findUniqueEstateplanning: {
      ...prev.findUniqueEstateplanning,
      notes: prev.findUniqueEstateplanning.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueEstateplanning(
  query: Pick<
    QueryResult<
      FindUniqueEstateplanningQuery,
      FindUniqueEstateplanningQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes
      }),
    variables: query.variables,
  });
}
