import { QueryResult } from "@apollo/client";
import {
  SharesSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { shares } from "../subscription";

interface SharesResolverParams {
  prev: FindUniqueGroupQuery;
  payload: SharesSubscription["shares"];
}

function createShares({ prev, payload }: SharesResolverParams) {
  const { data: createShares, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            shares: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.shares, createShares]
              : client.assets.shares,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            shares: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.shares, createShares]
              : entity.assets.shares,
          },
        };
      }),
    },
  });
}

function updateShares({ prev, payload }: SharesResolverParams) {
  const { data: updateShares, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the shares object
   * This is because updateShares only returns the shares info
   * without assetallocation, notes, etc.
   */
  var updatedShares = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.shares.forEach((shares) => {
      if (shares.assets_ID === updateShares.assets_ID) {
        result = {
          ...shares,
          ...updateShares,
        };
      }
    });
    return result;
  }, updateShares);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update shares first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          shares: [
            ...client.assets.shares
              .map((shares) =>
                shares.ID === updatedShares.ID
                  ? { ...shares, ...updatedShares }
                  : shares
              )
              .filter(
                (shares) =>
                  ![...data.clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(shares.assets_ID)
              ),
            ...(data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? data.clients_has_assets.reduce<
                  Array<SharesSubscription["shares"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedShares.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedShares];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    shares: liability.assets.shares.map(
                      (shares) =>
                        shares.ID === updateShares.ID
                          ? {
                              ...shares,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateShares,
                            }
                          : shares /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            shares: goal.assets.shares.map((shares) =>
              shares.ID === updatedShares.ID
                ? { ...shares, ...updatedShares }
                : shares
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      shares: liability.assets.shares.map((shares) =>
                        shares.ID === updateShares.ID
                          ? {
                              ...shares,
                              /** Update loan info */
                              ...updateShares,
                            }
                          : shares
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing shares object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          shares: [
            ...entity.assets.shares
              .map((shares) =>
                shares.ID === updatedShares.ID
                  ? { ...shares, ...updatedShares }
                  : shares
              )
              .filter(
                (shares) =>
                  ![...data.entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(shares.assets_ID)
              ),
            ...(data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? data.entities_has_assets.reduce<
                  Array<SharesSubscription["shares"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedShares.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedShares];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    shares: liability.assets.shares.map(
                      (shares) =>
                        shares.ID === updateShares.ID
                          ? {
                              ...shares,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateShares,
                            }
                          : shares /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteShares({ prev, payload }: SharesResolverParams) {
  const { data: deleteShares } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            shares: [...client.assets.shares].filter(
              (shares) => shares.ID !== deleteShares.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      shares: liability.assets.shares.filter(
                        (shares) => shares.ID !== deleteShares.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              shares: goal.assets.shares.filter(
                (shares) => shares.ID !== deleteShares.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        shares: liability.assets.shares.filter(
                          (shares) => shares.ID !== deleteShares.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            shares: [...entity.assets.shares].filter(
              (shares) => shares.ID !== deleteShares.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      shares: liability.assets.shares.filter(
                        (shares) => shares.ID !== deleteShares.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function sharesResolver({ prev, payload }: SharesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createShares({ prev, payload });

    case MutationType.Update:
      return updateShares({ prev, payload });

    case MutationType.Delete:
      return deleteShares({ prev, payload });

    default:
      return prev;
  }
}

export function sharesFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: shares,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: SharesSubscription } }
    ) =>
      sharesResolver({
        prev,
        payload: payload.subscriptionData.data.shares,
      }),
    variables: query.variables,
  });
}
