import React from "react";
import { InsuranceDeathScenarioModelGraph } from "../Graph";
import { InsuranceDeathScenarioDataReturn } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";

interface InsuranceDeathPlaceholderModelProps {
  theme?: Theme;
}

var data: InsuranceDeathScenarioDataReturn[] = [
  {
    clientName: "John Smith",
    existingCover: 2600000,
    calculatedCover: 1833333.3333333335,
    recommendedCover: 3000000,
    gap: 400000,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
  {
    clientName: "Jane Smith",
    existingCover: 1100000,
    calculatedCover: 1500000,
    recommendedCover: 1200000,
    gap: 100000,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
];

export const InsuranceDeathScenarioPlaceholderModel = ({
  theme,
}: InsuranceDeathPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Death Insurance
        </StyledText>

        <ModelTooltip type={Model_Type.InsuranceDeath} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InsuranceDeathScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
