import { QueryResult } from "@apollo/client";
import {
  FindUniqueSalarypackagingQuery,
  FindUniqueSalarypackagingQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueSalarypackagingQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, salarypackaging_has_notes } = payload;

  if (!prev.findUniqueSalarypackaging) return prev;
  return Object.assign({}, prev, {
    findUniqueSalarypackaging: {
      ...prev.findUniqueSalarypackaging,
      notes: salarypackaging_has_notes.some(
        (relation) => relation.salarypackaging_ID === prev.findUniqueSalarypackaging?.ID
      )
        ? [...prev.findUniqueSalarypackaging.notes, notes]
        : prev.findUniqueSalarypackaging.notes,
    },
  });
}
 
function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueSalarypackaging) return prev;
  return Object.assign({}, prev, {
    findUniqueSalarypackaging: {
      ...prev.findUniqueSalarypackaging,
      notes: prev.findUniqueSalarypackaging.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueSalarypackaging) return prev;
  return Object.assign({}, prev, {
    findUniqueSalarypackaging: {
      ...prev.findUniqueSalarypackaging,
      notes: prev.findUniqueSalarypackaging.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueSalarypackaging(query: Pick<
  QueryResult<FindUniqueSalarypackagingQuery, FindUniqueSalarypackagingQueryVariables>,
  "subscribeToMore" | "variables"
>) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes
      }),
    variables: query.variables,
  });
}
