import { QueryResult } from "@apollo/client";
import {
  FindUniqueInsuranceQuery,
  FindUniqueInsuranceQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueInsuranceQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, insurance_has_notes } = payload;

  if (!prev.findUniqueInsurance) return prev;
  return Object.assign({}, prev, {
    findUniqueInsurance: {
      ...prev.findUniqueInsurance,
      notes: insurance_has_notes.some(
        (relation) =>
          relation.insurance_ID ===
          (prev.findUniqueInsurance!.ID as unknown as number)
      )
        ? [...prev.findUniqueInsurance.notes, notes]
        : prev.findUniqueInsurance.notes,
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueInsurance) return prev;
  return Object.assign({}, prev, {
    findUniqueInsurance: {
      ...prev.findUniqueInsurance,
      notes: prev.findUniqueInsurance.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueInsurance) return prev;
  return Object.assign({}, prev, {
    findUniqueInsurance: {
      ...prev.findUniqueInsurance,
      notes: prev.findUniqueInsurance.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueInsurance(
  query: Pick<
  QueryResult<FindUniqueInsuranceQuery, FindUniqueInsuranceQueryVariables>,
  "subscribeToMore" | "variables"
>
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes
      }),
    variables: query.variables,
  });
}
