import { gql } from "@apollo/client";
import { teamFragment } from ".";

export const createOneTeam = gql`
  mutation CreateOneTeam($data: teamCreateInput!) {
    createOneTeam(data: $data) {
      ...team
    }
  }
  ${teamFragment}
`;

export const updateOneTeam = gql`
  mutation UpdateOneTeam(
    $data: teamUpdateInput!
    $where: teamWhereUniqueInput!
  ) {
    updateOneTeam(data: $data, where: $where) {
      ...team
    }
  }
  ${teamFragment}
`;

export const deleteOneTeam = gql`
  mutation DeleteOneTeam($where: teamWhereUniqueInput!) {
    deleteOneTeam(where: $where) {
      ...team
    }
  }
  ${teamFragment}
`;
