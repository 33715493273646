import {
  ScenarioinsuranceSubscription,
  FindUniqueAdviceQuery,
  MutationType,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { scenarioinsurance } from "../subscription";
import { QueryResult } from "@apollo/client";

interface ScenarioinsuranceResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: ScenarioinsuranceSubscription["scenarioinsurance"];
}

function createScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioinsurance.scenario_ID
          ? {
              ...scenario,
              scenarioinsurance: [
                ...scenario.scenarioinsurance,
                scenarioinsurance,
              ],
            }
          : scenario
      ),
    },
  });
}

function updateScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioinsurance.scenario_ID
          ? {
              ...scenario,
              scenarioinsurance: scenario.scenarioinsurance.map(
                (scenarioinsurances) =>
                  scenarioinsurances.ID === scenarioinsurance.ID
                    ? { ...scenarioinsurances, ...scenarioinsurance }
                    : scenarioinsurances
              ),
            }
          : scenario
      ),
    },
  });
}

function deleteScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioinsurance.scenario_ID
          ? {
              ...scenario,
              scenarioinsurance: scenario.scenarioinsurance.filter(
                (scenarioinsurances) =>
                  scenarioinsurances.ID !== scenarioinsurance.ID
              ),
            }
          : scenario
      ),
    },
  });
}

function scenarioinsuranceResolver({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioinsurance({ prev, payload });

    case MutationType.Update:
      return updateScenarioinsurance({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioinsurance({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioinsuranceFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioinsurance,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioinsuranceSubscription };
      }
    ) =>
      scenarioinsuranceResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioinsurance,
      }),
    variables: query.variables,
  });
}
