import { gql } from "@apollo/client";
import { notesFragment } from "../notes/fragment";

export const feesCoreFragment = gql`
  fragment feesCore on fees {
    ID
    groups_ID
    groups_organisation_ID
    Department
    OngoingUpfront
    DateIssued
    Amount
    PaymentFrequency
    BSB
    AccountNumber
    FeeExpiryDate
    OptInDate
    FeePercent
    PortfolioValue
    FDSRequired
    EditedBy
    CollectedBy
    TotalAmount
    PaymentDetail
    Reference
    Details
    PaidBy
    PendingType
    Pending
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const feesFragment = gql`
  fragment fees on fees {
    ...feesCore

    notes {
      ...notes
    }
  }
  ${feesCoreFragment}
  ${notesFragment}
`;
