import { QueryResult } from "@apollo/client";
import {
  EntitiesSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { entities } from "../subscription";

interface EntitiesResolverParams {
  prev: FindUniqueGroupQuery;
  payload: EntitiesSubscription["entities"];
}
function createEntities({ prev, payload }: EntitiesResolverParams) {
  const entities = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      entities:
        entities.groups_ID === prev.findUniqueGroup.ID
          ? [...prev.findUniqueGroup.entities, entities]
          : prev.findUniqueGroup.entities,
    },
  });
}

function updateEntities({ prev, payload }: EntitiesResolverParams) {
  const entities = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      entities: prev.findUniqueGroup.entities.map((entity) =>
        entity.ID === entities.ID ? { ...entity, ...entities } : entity
      ),
    },
  });
}

function deleteEntities({ prev, payload }: EntitiesResolverParams) {
  const entities = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      entities: [...prev.findUniqueGroup.entities].filter(
        (entity) => entity.ID !== entities.ID
      ),
    },
  });
}

function entitiesResolver({ prev, payload }: EntitiesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createEntities({ prev, payload });

    case MutationType.Update:
      return updateEntities({ prev, payload });

    case MutationType.Delete:
      return deleteEntities({ prev, payload });

    default:
      return prev;
  }
}

export function entitiesFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: entities,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: EntitiesSubscription } }
    ) =>
      entitiesResolver({
        prev,
        payload: payload.subscriptionData.data.entities
      }),
    variables: query.variables,
  });
}
