import { QueryResult } from "@apollo/client";
import {
  FindManyEmploymentQuery,
  FindManyEmploymentQueryVariables,
} from "../../../codegen/schema";
import { FindManyEmployment } from "../reducers";

export function findManyEmploymentSubscription(
  query: Pick<
    QueryResult<FindManyEmploymentQuery, FindManyEmploymentQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindManyEmployment(query);
}
