import { gql } from "@apollo/client";
import { assetsFragment } from "../assets";
import { contributionswithdrawalsFragment } from "../contributionswithdrawals";
import { liabilitiesFragment } from "../liabilities";
import { notesFragment } from "../notes";

export const goalsCoreFragment = gql`
  fragment goalsCore on goals {
    ID
    clients_ID
    clients_groups_ID
    clients_groups_organisation_ID
    Type
    Term
    EndDate
    Target
    Description
    DateAchieved
    FinancialStrategy
    GoalTitle
    Who
    StartDate
    ModelType
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const goalsFragment = gql`
  fragment goals on goals {
    ...goalsCore

    contributionswithdrawals {
      ...contributionswithdrawals
    }

    assets {
      ...assets
    }

    liabilities {
      ...liabilities
    }

    notes {
      ...notes
    }
  }
  ${goalsCoreFragment}
  ${contributionswithdrawalsFragment}
  ${assetsFragment}
  ${liabilitiesFragment}
  ${notesFragment}
`;
