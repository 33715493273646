import React, { useContext } from "react";

import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  LineChart,
} from "recharts";

import { StoreContext } from "../../../../../../../Datastore/Store";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";
import {
  Advice,
  FindUniqueAdviceQuery,
} from "../../../../../../../codegen/schema";
import { ComparisonData } from "../../../Calculations";

interface Props {
  comparisonData: ComparisonData[] | undefined;
  advice?: Advice | FindUniqueAdviceQuery["findUniqueAdvice"];
  placeholder?: boolean;
}

export const LoanRepaymentComparisonModelGraph: React.FC<Props> = ({
  comparisonData,
  advice,
  placeholder = false,
}) => {
  const [context] = useContext(StoreContext);

  if (!comparisonData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <LineChart
        data={comparisonData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />

        <XAxis
          interval={comparisonData.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>

        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>

        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />

        <ReferenceLine y={0} stroke="#000" />

        {placeholder
          ? ["Scenario 1", "Scenario 2"].map((data, index) => {
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={data}
                  stroke={context.colours[Object.keys(context.colours)[index]]}
                  strokeWidth={2}
                  dot={false}
                  isAnimationActive={false}
                />
              );
            })
          : advice?.scenario.map((scenario, index) => (
              <Line
                key={scenario.ID}
                type="monotone"
                dataKey={scenario.Title ?? "Scenario " + index}
                stroke={context.colours[Object.keys(context.colours)[index]]}
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
            ))}

        <Line
          type="monotone"
          dataKey={"target"}
          stroke={context.colours.target}
          strokeWidth={1}
          isAnimationActive={false}
          dot={{
            strokeWidth: 1,
            r: 4,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
