import { QueryResult } from "@apollo/client";
import {
  GroupsManySubscription,
  FindManyGroupsTotalCountQuery,
  MutationType,
  FindManyGroupsTotalCountQueryVariables,
} from "../../../codegen/schema";
import { groupsMany } from "../subscription";

interface GroupsResolverParams {
  prev: FindManyGroupsTotalCountQuery;
  payload: GroupsManySubscription["groupsMany"];
}

function createGroups({ prev, payload }: GroupsResolverParams) {
  return Object.assign({}, prev, {
    findManyGroupsTotalCount: prev.findManyGroupsTotalCount + 1,
  });
}

function deleteGroups({ prev, payload }: GroupsResolverParams) {
  return Object.assign({}, prev, {
    findManyGroupsTotalCount: prev.findManyGroupsTotalCount - 1,
  });
}

function groupsResolver({ prev, payload }: GroupsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createGroups({ prev, payload });

    case MutationType.Delete:
      return deleteGroups({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyGroupsTotalCount(
  query: Pick<
    QueryResult<
      FindManyGroupsTotalCountQuery,
      FindManyGroupsTotalCountQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: groupsMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: GroupsManySubscription } }
    ) =>
      groupsResolver({
        prev,
        payload: payload.subscriptionData.data.groupsMany,
      }),
    variables: query.variables,
  });
}
