/** function staffHoursCost
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function staffHoursCost(service, field, feesettings) {
  return service[field + "Hours"] * feesettings[field + "Cost"];
}

/** function staffHoursTotalCost
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function staffHoursTotalCost(aa, field, feesettings) {
  return (
    [...aa.services].reduce(
      (result, service) => (result += service[field + "Hours"]),
      0
    ) * feesettings[field + "Cost"]
  );
}

/** function staffHoursTotalCostGST
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function staffHoursTotalCostGST(aa, field, feesettings) {
  return staffHoursTotalCost(aa, field, feesettings) * 0.1;
}

/** function requiredServicesTotalCost
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function requiredServicesTotalCost(aa) {
  return [...aa.services].reduce(
    (result, service) => (result += service.TotalCost),
    0
  );
}

/** function allocationSum
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function allocationSum(service) {
  return [...service.payee].reduce(
    (result, payee) => (result += payee.Allocation),
    0
  );
}

/** function serviceStaffCostTotal
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function serviceStaffCostTotal(service, feesettings) {
  return (
    staffHoursCost(service, "PrimaryAdviser", feesettings) +
    staffHoursCost(service, "AlternateAdviser", feesettings) +
    staffHoursCost(service, "SupportStaff", feesettings)
  );
}

/** function adviceAgreementTotalCost
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function adviceAgreementTotalCost(aa, feesettings) {
  return (
    staffHoursTotalCost(aa, "PrimaryAdviser", feesettings) +
    staffHoursTotalCost(aa, "AlternateAdviser", feesettings) +
    staffHoursTotalCost(aa, "SupportStaff", feesettings) +
    requiredServicesTotalCost(aa)
  );
}

/** function adviceAgreementTotalCostGST
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function adviceAgreementTotalCostGST(aa, feesettings) {
  return adviceAgreementTotalCost(aa, feesettings) * 0.1;
}

/** function payeeCalc
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function payeeCalc(aa, feesettings, payeeName) {
  return [...aa.services].reduce((result, service) => {
    var cost =
      service.Type === "Required Services"
        ? service.TotalCost
        : staffHoursCost(service, "PrimaryAdviser", feesettings) +
          staffHoursCost(service, "AlternateAdviser", feesettings) +
          staffHoursCost(service, "SupportStaff", feesettings);

    return (result +=
      (cost *
        (service.payee.find((payee) => payee.Name === payeeName)
          ? service.payee.find((payee) => payee.Name === payeeName).Allocation
          : 0)) /
      100);
  }, 0);
}

/** function payeeCalcGST
 * @author Lara de Maroussem
 * @date 05-05-2022
 */
export function payeeCalcGST(aa, feesettings, payeeName) {
  return payeeCalc(aa, feesettings, payeeName) * 0.1;
}
