export function AssetGrowthModelDescription() {
  return (
    <p className="text-2xs italic">
      The asset growth model displays the change in value over time of different
      asset classes of the group. This uses data directly from the group and not
      from the scenario data.
    </p>
  );
}

export function AssetGrowthCalculationTooltip() {
  return (
    <>
      <p>
        {" "}
        <b>X axis </b> <br />
        this graph starts with the earliest advice year recorded in MOAS and
        ends with the model end date. If no end date is supplied, the x axis
        will col-span-full 30 years.{" "}
      </p>

      <p>
        {" "}
        <b>Target </b> <br />
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#target"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for target breakdown.
        </a>
      </p>
      <p>
        <b>Assets</b>
        <br />
        This model uses the asset advice totals (see below for more information on advice totals).
        Total assets is calculated by adding all asset advice totals for each advice year recorded in MOAS.
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#advice-totals"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for more information on advice totals.
        </a>
      </p>
    </>
  );
}
