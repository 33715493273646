import { QueryResult } from "@apollo/client";
import {
  FeesManySubscription,
  FindManyFeesQuery,
  FindManyFeesQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { feesMany } from "../subscription";

interface FeesResolverParams {
  prev: FindManyFeesQuery;
  payload: FeesManySubscription["feesMany"];
}

function createFees({ prev, payload }: FeesResolverParams) {
  const fees = payload.data;

  return Object.assign({}, prev, {
    findManyFees: [...prev.findManyFees, fees],
  });
}

function updateFees({ prev, payload }: FeesResolverParams) {
  const fees = payload.data;

  return Object.assign({}, prev, {
    findManyFees: prev.findManyFees.map((entry) =>
      entry.ID === fees.ID ? { ...entry, ...fees } : entry
    ),
  });
}

function deleteFees({ prev, payload }: FeesResolverParams) {
  const fees = payload.data;

  return Object.assign({}, prev, {
    findManyFees: [...prev.findManyFees].filter(
      (entry) => entry.ID !== fees.ID
    ),
  });
}

function feesResolver({ prev, payload }: FeesResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createFees({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyFees.some((fees) => fees.ID === data.ID)
        ? updateFees({ prev, payload })
        : createFees({ prev, payload });

    case MutationType.Delete:
      return deleteFees({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyFees(
  query: Pick<
    QueryResult<FindManyFeesQuery, FindManyFeesQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: feesMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: FeesManySubscription } }
    ) =>
      feesResolver({
        prev,
        payload: payload.subscriptionData.data.feesMany,
      }),
    variables: query.variables,
  });
}
