import React, { Fragment } from "react";
import { FindUniqueUserQuery, useLogoutMutation } from "../../codegen/schema";

import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";

import { Avatar } from "../../Components/Display/Avatar";
import { Searchbar } from "./Searchbar";
import { NewGroup } from "./NewGroup";

interface TopMenuProps {
  user: FindUniqueUserQuery["findUniqueUser"];
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TopMenu: React.FC<TopMenuProps> = ({ user, setSidebarOpen }) => {
  const navigate = useNavigate();

  const [logoutMutation, { client: apolloClient }] = useLogoutMutation();

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-neutral-50 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <div
          className="flex content-center fa-solid fa-bars h-6 w-6 text-neutral-800"
          aria-hidden="true"
        />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-neutral-200 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <Searchbar />
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-neutral-100"
            aria-hidden="true"
          />
          <NewGroup
            buttonProps={{
              tooltip: "Add new group",
              type: "circle",
              functionality: "add",
            }}
          />
        </div>

        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {/* Separator */}
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-neutral-100"
            aria-hidden="true"
          />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <MenuButton className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              {user && (
                <Avatar
                  filePath={`application/organisation/ID=${user.staff_organisation_ID}/staff/ID=${user.staff_ID}/profile.png`}
                  staff_ID={user.staff_ID}
                />
              )}
              <span className="hidden lg:flex lg:items-center">
                <i
                  className="ml-2 h-5 w-5 text-neutral-400 icon fa-solid fa-chevron-down"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow ring-1 ring-neutral-50">
                <MenuItem>
                  <div
                    className={
                      "block px-3 py-1 text-sm leading-6 text-neutral-900 hover:bg-neutral-50 cursor-pointer"
                    }
                    onClick={async () =>
                      await logoutMutation()
                        .then(async () => {
                          /**
                           * Placeholder for cache clearing after logout.
                           * Need a more reliable mechanism to handle this.
                           */
                          await apolloClient
                            .clearStore()
                            .then(() => {
                              navigate("login");
                            })
                            .catch((error) => console.log(error));
                        })
                        .catch((error) => console.log(error))
                    }
                  >
                    Logout
                  </div>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};
