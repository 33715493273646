import { useContext } from "react";
import { StoreContext } from "../../../../../../../Datastore/Store";
import { InsuranceTraumaScenarioDataReturn } from "../../../Calculations";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  RechartsCustomReferenceLine,
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  scenarioData: InsuranceTraumaScenarioDataReturn[] | undefined;
}

export const InsuranceTraumaScenarioModelGraph: React.FC<Props> = ({
  scenarioData,
}) => {
  const [context] = useContext(StoreContext);

  if (!scenarioData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={scenarioData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />
        <XAxis
          interval={scenarioData.length > 12 ? 4 : 0}
          tickLine={false}
          minTickGap={1000}
          dataKey="clientName"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Clients"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>

        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value ($)"
            position="center"
            offset={-30}
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />
        <ReferenceLine y={0} stroke="#000" />

        <Bar
          dataKey={"existingCover"}
          fill={context.colours.blue200}
          stackId={1}
          isAnimationActive={false}
          maxBarSize={50}
        />

        <Scatter
          dataKey={"calculatedCover"}
          fill={context.colours.blue}
          stroke={context.colours.blue}
          strokeWidth={2}
          shape={<RechartsCustomReferenceLine />}
          isAnimationActive={false}
        />

        <Scatter
          dataKey={"recommendedCover"}
          fill={context.colours.green}
          stroke={context.colours.green}
          strokeWidth={2}
          shape={<RechartsCustomReferenceLine />}
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
