import { QueryResult } from "@apollo/client";
import {
  ModelSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { model } from "../subscription";

interface ModelResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ModelSubscription["model"];
}

function createModel({ prev, payload }: ModelResolverParams) {
  const model = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      model:
        model.groups_ID === prev.findUniqueGroup.ID
          ? [...prev.findUniqueGroup.model, model]
          : prev.findUniqueGroup.model,
    },
  });
}

function updateModel({ prev, payload }: ModelResolverParams) {
  const model = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      model: prev.findUniqueGroup.model.map((entry) =>
        entry.ID === model.ID ? { ...entry, ...model } : entry
      ),
    },
  });
}

function deleteModel({ prev, payload }: ModelResolverParams) {
  const model = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      model: [...prev.findUniqueGroup.model].filter(
        (models) => models.ID !== model.ID
      ),
    },
  });
}

function modelResolver({ prev, payload }: ModelResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createModel({ prev, payload });

    case MutationType.Update:
      return updateModel({ prev, payload });

    case MutationType.Delete:
      return deleteModel({ prev, payload });

    default:
      return prev;
  }
}

export function modelFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: model,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ModelSubscription } }
    ) =>
      modelResolver({
        prev,
        payload: payload.subscriptionData.data.model,
      }),
    variables: query.variables,
  });
}
