import { QueryResult } from "@apollo/client";
import {
  ScenarioliabilitySubscription,
  FindUniqueScenarioQuery,
  MutationType,
  FindUniqueScenarioQueryVariables,
} from "../../../codegen/schema";
import { scenarioliability } from "../subscription";

interface ScenarioliabilityResolverParams {
  prev: FindUniqueScenarioQuery;
  payload: ScenarioliabilitySubscription["scenarioliability"];
}
function createScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioliability:
        scenarioliability.scenario_ID === prev.findUniqueScenario.ID
          ? [...prev.findUniqueScenario.scenarioliability, scenarioliability]
          : prev.findUniqueScenario.scenarioliability,
    },
  });
}

function updateScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioliability: prev.findUniqueScenario.scenarioliability.map(
        (scenarioliabilitys) =>
          scenarioliability.ID === scenarioliabilitys.ID
            ? { ...scenarioliabilitys, ...scenarioliability }
            : scenarioliabilitys
      ),
    },
  });
}

function deleteScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioliability: [...prev.findUniqueScenario.scenarioliability].filter(
        (scenarioliabilitys) => scenarioliabilitys.ID !== scenarioliability.ID
      ),
    },
  });
}

function scenarioliabilityResolver({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioliability({ prev, payload });

    case MutationType.Update:
      return updateScenarioliability({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioliability({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioliabilityFindUniqueScenario(
  query: Pick<
    QueryResult<FindUniqueScenarioQuery, FindUniqueScenarioQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioliability,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioliabilitySubscription };
      }
    ) =>
      scenarioliabilityResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioliability,
      }),
    variables: query.variables,
  });
}
