import { QueryResult } from "@apollo/client";
import {
  AdviceagreementManySubscription,
  FindManyAdviceagreementQuery,
  FindManyAdviceagreementQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { adviceagreementMany } from "../subscription";

interface AdviceagreementResolverParams {
  prev: FindManyAdviceagreementQuery;
  payload: AdviceagreementManySubscription["adviceagreementMany"];
}

function createAdviceagreement({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const adviceagreement = payload.data;

  return Object.assign({}, prev, {
    findManyAdviceagreement: [...prev.findManyAdviceagreement, adviceagreement],
  });
}

function updateAdviceagreement({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const adviceagreement = payload.data;

  return Object.assign({}, prev, {
    findManyAdviceagreement: prev.findManyAdviceagreement.map((entry) =>
      adviceagreement.ID === entry.ID
        ? {
            ...entry,
            ...adviceagreement,
          }
        : { ...entry }
    ),
  });
}

function deleteAdviceagreement({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const adviceagreement = payload.data;

  return Object.assign({}, prev, {
    findManyAdviceagreement: prev.findManyAdviceagreement.filter(
      (entry) => entry.ID !== adviceagreement.ID
    ),
  });
}

function adviceagreementResolver({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAdviceagreement({ prev, payload });

    case MutationType.Update:
      return prev.findManyAdviceagreement.some(
        (adviceagreement) => adviceagreement.ID === data.ID
      )
        ? updateAdviceagreement({ prev, payload })
        : createAdviceagreement({ prev, payload });

    case MutationType.Delete:
      return deleteAdviceagreement({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyAdviceagreement(
  query: Pick<
    QueryResult<
      FindManyAdviceagreementQuery,
      FindManyAdviceagreementQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: adviceagreementMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceagreementManySubscription } }
    ) =>
      adviceagreementResolver({
        prev,
        payload: payload.subscriptionData.data.adviceagreementMany,
      }),
    variables: query.variables,
  });
}
