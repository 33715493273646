import { gql } from "@apollo/client";
import { vehiclesFragment } from ".";

export const createOneVehicles = gql`
  mutation CreateOneVehicles($data: vehiclesCreateInput!) {
    createOneVehicles(data: $data) {
      ...vehicles
    }
  }
  ${vehiclesFragment}
`;

export const updateOneVehicles = gql`
  mutation UpdateOneVehicles(
    $data: vehiclesUpdateInput!
    $where: vehiclesWhereUniqueInput!
  ) {
    updateOneVehicles(data: $data, where: $where) {
      ...vehicles
    }
  }
  ${vehiclesFragment}
`;

export const deleteOneVehicles = gql`
  mutation DeleteOneVehicles($where: vehiclesWhereUniqueInput!) {
    deleteOneVehicles(where: $where) {
      ...vehicles
    }
  }
  ${vehiclesFragment}
`;
