import { gql } from "@apollo/client";
import {
  adviceagreementFragment,
  adviceagreementCoreFragment,
} from "./fragment";

export const adviceagreement = gql`
  subscription Adviceagreement($where: adviceagreementWhereUniqueInput) {
    adviceagreement(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...adviceagreement
      }
    }
  }
  ${adviceagreementFragment}
`;

export const adviceagreementMany = gql`
  subscription AdviceagreementMany($where: adviceagreementWhereInput) {
    adviceagreementMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...adviceagreementCore
      }
    }
  }
  ${adviceagreementCoreFragment}
`;
