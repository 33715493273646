import { gql } from "@apollo/client";

export const roleFragment = gql`
  fragment role on role {
    ID
    staff_ID
    staff_organisation_ID
    team_ID
    team_organisation_ID
    teamrole_ID
    updated_by
    created_by
    update_time
    create_time
  }
`;
