import { QueryResult } from "@apollo/client";
import { FindManyAdviceTotalCount } from "..";
import {
  FindManyAdviceTotalCountQuery,
  FindManyAdviceTotalCountQueryVariables,
} from "../../../codegen/schema";

export default function findManyAdviceTotalCountSubscription(
  query: Pick<
    QueryResult<
      FindManyAdviceTotalCountQuery,
      FindManyAdviceTotalCountQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  FindManyAdviceTotalCount(query);
}
