import { QueryResult } from "@apollo/client";
import {
  PagetemplateManySubscription,
  FindManyPagetemplateTotalCountQuery,
  MutationType,
  FindManyPagetemplateTotalCountQueryVariables,
} from "../../../codegen/schema";
import { pagetemplateMany } from "../subscription";

interface PagetemplateResolverParams {
  prev: FindManyPagetemplateTotalCountQuery;
  payload: PagetemplateManySubscription["pagetemplateMany"];
}

function createPagetemplate({ prev, payload }: PagetemplateResolverParams) {
  return Object.assign({}, prev, {
    findManyPagetemplateTotalCount: prev.findManyPagetemplateTotalCount + 1,
  });
}

function deletePagetemplate({ prev, payload }: PagetemplateResolverParams) {
  return Object.assign({}, prev, {
    findManyPagetemplateTotalCount: prev.findManyPagetemplateTotalCount - 1,
  });
}

function pagetemplateResolver({ prev, payload }: PagetemplateResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createPagetemplate({ prev, payload });

    case MutationType.Delete:
      return deletePagetemplate({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyPagetemplateTotalCount(
  query: Pick<
    QueryResult<
      FindManyPagetemplateTotalCountQuery,
      FindManyPagetemplateTotalCountQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: pagetemplateMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: PagetemplateManySubscription } }
    ) =>
      pagetemplateResolver({
        prev,
        payload: payload.subscriptionData.data.pagetemplateMany,
      }),
    variables: query.variables,
  });
}
