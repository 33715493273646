import React, { useContext, useState } from "react";
import { Input } from "../../Components/Inputs/Input";
import { StoreContext } from "../../Datastore/Store";
import { Button } from "../../Components/Buttons";
import { useForgotPassworMutation } from "../../codegen/schema";

export default function ForgotPassword() {
  const [, dispatch] = useContext(StoreContext);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [forgotPasswordMutation, { loading }] = useForgotPassworMutation();

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    setInvalidEmail(false);
    setConfirmation(false);

    var inputs = Array.from(document.getElementsByTagName("input"));

    var email = "";

    inputs.forEach((input) => {
      if (input.id === "email") email = input.value;
    });

    if (validateEmail(email)) {
      setInvalidEmail(false);
      forgotPasswordMutation({ variables: { email } })
        .then(() => {
          setConfirmation(true);
          dispatch({
            type: "snackbar/success",
            payload: "Link sent, check your email inbox!",
          });
        })
        .catch((error) => {
          setConfirmation(false);
          dispatch({
            type: "snackbar/error",
            payload: error.message,
          });
        });
    } else {
      setInvalidEmail(true);
      dispatch({
        type: "snackbar/error",
        payload: "Please provide a valid email address.",
      });
    }
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  return (
    <div className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-24 w-auto"
          src={
            process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
            "/website/logos/1200pxX500px_default.png"
          }
          alt="MOAS logo"
        />
        <h2 className="mt-8 text-center text-2xl font-display font-bold leading-9 tracking-tight text-neutral-800">
          Forgot your password?
        </h2>
      </div>

      <div className="flex flex-col items-center">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <h5 className="text-center">
            Enter your email address below and we will send you a link to reset
            your password.
          </h5>
          <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-neutral-500"
              >
                Email address
              </label>
              <Input name="email" id="email" required />
            </div>

            {invalidEmail ? (
              <div className="flex gap-2 itens-center">
                <i className="icon text-semantic-error-500 fa-regular fa-circle-exclamation" />
                <h5 className="text-semantic-error-500">
                  Please enter a valid email.
                </h5>
              </div>
            ) : null}

            {confirmation ? (
              <div className="flex gap-2 itens-center">
                <i className="icon text-semantic-success-500 fa-regular fa-circle-exclamation" />
                <h5 className="text-semantic-success-500">
                  Link sent, check your email inbox!
                </h5>
              </div>
            ) : null}

            <Button
              type="default"
              text={"Reset my password"}
              buttontype="submit"
              loading={loading}
              disabled={loading}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
