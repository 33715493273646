import { FindUniqueUserQuery } from "../../../codegen/schema";

import { useLocation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { SidebarSmall } from "./Small";
import { SidebarFull } from "./Full";
import { SidebarGroup } from "./Group";
import { SidebarAdvice } from "./Advice";
import { SidebarDataVerification } from "./DataVerification";

export interface navigationOption {
  name: string;
  href: string;
  icon?: string;
  children?: navigationOption[];
}

interface SidebarNavigationProps {
  user: FindUniqueUserQuery["findUniqueUser"];
}

export const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
  user,
}) => {
  const location = useLocation();

  const navigation = [
    { name: "Home", href: "home", icon: "fa-house" },
    {
      name: "Advanced Search",
      href: "advancedsearch",
      icon: "fa-telescope",
    },
    {
      name: "Tasks",
      href: "tasks-module/tasks",
      icon: "fa-calendar",
      children: [
        { name: "Tasks", href: "tasks-module/tasks" },
        {
          name: "Projects",
          href: "tasks-module/projects",
          children: [
            { name: "Projects", href: "tasks-module/projects" },
            {
              name: "Project tasks",
              href: "tasks-module/projects/projecttasks",
            },
          ],
        },
        { name: "Opportunities", href: "tasks-module/opportunities" },
        {
          name: "Templates",
          href: "tasks-module/templates",
          children: [
            {
              name: "Tasks",
              href: "tasks-module/templates/tasks",
            },
            { name: "Projects", href: "tasks-module/templates/projects" },
            {
              name: "Opportunities",
              href: "tasks-module/templates/opportunities",
            },
          ],
        },
      ],
    },
    {
      name: "Advice",
      href: "advice-module/advice",
      icon: "fa-folder",
      children: [
        {
          name: "Advice",
          href: `advice-module/advice`,
        },
        {
          name: "Advice agreements",
          href: `advice-module/adviceagreements`,
        },
        {
          name: "Groups",
          href: `advice-module/groups`,
        },
      ],
    },
    {
      name: "Document Templater",
      href: "templater",
      icon: "fa-files",
    },
    {
      name: "Metrics",
      href: `staff/${user?.staff_ID}/metrics/advice`,
      icon: "fa-chart-scatter",
      children: [
        {
          name: "Advice",
          href: `staff/${user?.staff_ID}/metrics/advice`,
        },
        {
          name: "Revenue",
          href: `staff/${user?.staff_ID}/metrics/revenue`,
        },
        {
          name: "Groups",
          href: `staff/${user?.staff_ID}/metrics/groups`,
        },
        {
          name: "Tasks",
          href: `staff/${user?.staff_ID}/metrics/task-module`,
        },
        { name: "Targets", href: `staff/${user?.staff_ID}/metrics/targets` },
      ],
    },
    {
      name: "Settings",
      href: "settings",
      icon: "fa-gears",
      children: [
        { name: "Profile", href: "settings/profile" },
        ...(user?.role === "Admin"
          ? [
              { name: "Users", href: "settings/users" },
              {
                name: "Teams",
                href: "settings/teams",
                children: [
                  { name: "Teams", href: "settings/teams" },
                  { name: "Roles", href: "settings/teams/roles" },
                  {
                    name: "Groups",
                    href: "settings/teams/groups-not-assigned",
                  },
                ],
              },
              { name: "Tags", href: "settings/tags" },
              { name: "Fees", href: "settings/fees" },
              { name: "Organisation", href: "settings/organisation" },
            ]
          : []),
      ],
    },
  ];

  return (
    <Fragment>
      {location.pathname.includes("group/ID") &&
      !location.pathname.includes("advice/ID") &&
      !location.pathname.includes("data-verification") ? (
        <div className="flex flex-1 xl:flex">
          <SidebarSmall navigation={navigation} />
          <SidebarGroup user={user} />
        </div>
      ) : location.pathname.includes("advice/ID") &&
        !location.pathname.includes("document/ID") ? (
        <div className="flex flex-1 xl:flex">
          <SidebarSmall navigation={navigation} />
          <SidebarAdvice />
        </div>
      ) : location.pathname.includes("data-verification") ? (
        <div className="flex flex-1 xl:flex">
          <SidebarSmall navigation={navigation} />
          <SidebarDataVerification user={user} />
        </div>
      ) : location.pathname.includes("template/ID") ||
        location.pathname.includes("theme/ID") ||
        location.pathname.includes("document/ID") ? (
        <div className="flex flex-1 xl:flex">
          <SidebarSmall navigation={navigation} />
        </div>
      ) : (
        <SidebarFull navigation={navigation} />
      )}
    </Fragment>
  );
};
