import { gql } from "@apollo/client";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";
// import { assetsCoreFragment } from "../assets";
import { annuityCoreFragment } from "../annuity";
import { cashCoreFragment } from "../cash";
import { collectiblesCoreFragment } from "../collectibles";
import { otherassetsCoreFragment } from "../otherassets";
import { propertyCoreFragment } from "../property";
import { sharesCoreFragment } from "../shares";
import { superannuationCoreFragment } from "../superannuation";
import { vehiclesCoreFragment } from "../vehicles";

export const loans = gql`
  subscription Loans($where: loansWhereUniqueInput) {
    loans(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ID
        liabilities_ID
        Bank
        BSB
        AccountNumber
        TaxDeductible
        Purpose
        SecuredBy
        RelatedAsset
        InterestRate
        VariableFixed
        FixedExpiry
        InterestOnlyExpiry
        RepaymentPerMonth
        LoanLimit
        AvailableRedraw
        RepaymentType
        LoanOwnership
        CurrentLoanOutstanding
        LoanType
        LoanStartDate
        LoanExpiry
        Description
        InterestOnlyStartDate
        RateReviewedDate
        Offset
        Position
        Refinance
        create_time
        update_time
        created_by
        updated_by

        assets {
          annuity {
            ...annuityCore
          }

          cash {
            ...cashCore
          }

          collectibles {
            ...collectiblesCore
          }

          otherassets {
            ...otherassetsCore
          }

          offset {
            ID
            assets_ID
            Bank
            BSB
            AccountNumber
            Amount
            RelatedLoan
            Purpose
            OwnershipType
            create_time
            update_time
            created_by
            updated_by
          }

          property {
            ...propertyCore
          }

          shares {
            ...sharesCore
          }

          superannuation {
            ...superannuationCore
          }

          vehicles {
            ...vehiclesCore
          }
        }

        notes {
          ...notes
        }

        history {
          ...history
        }
      }
      assets_has_liabilities {
        assets_ID
        liabilities_ID
      }
      clients_has_liabilities {
        clients_ID
        liabilities_ID
      }
      entities_has_liabilities {
        entities_ID
        liabilities_ID
      }
    }
  }
  ${annuityCoreFragment}
  ${cashCoreFragment}
  ${collectiblesCoreFragment}
  ${otherassetsCoreFragment}
  ${propertyCoreFragment}
  ${sharesCoreFragment}
  ${superannuationCoreFragment}
  ${vehiclesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;

export const loansMany = gql`
  subscription LoansMany($where: loansWhereInput) {
    loansMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ID
        liabilities_ID
        Bank
        BSB
        AccountNumber
        TaxDeductible
        Purpose
        SecuredBy
        RelatedAsset
        InterestRate
        VariableFixed
        FixedExpiry
        InterestOnlyExpiry
        RepaymentPerMonth
        LoanLimit
        AvailableRedraw
        RepaymentType
        LoanOwnership
        CurrentLoanOutstanding
        LoanType
        LoanStartDate
        LoanExpiry
        Description
        InterestOnlyStartDate
        RateReviewedDate
        Offset
        Position
        Refinance
        create_time
        update_time
        created_by
        updated_by

        assets {
          annuity {
            ...annuityCore
          }

          cash {
            ...cashCore
          }

          collectibles {
            ...collectiblesCore
          }

          otherassets {
            ...otherassetsCore
          }

          offset {
            ID
            assets_ID
            Bank
            BSB
            AccountNumber
            Amount
            RelatedLoan
            Purpose
            OwnershipType
            create_time
            update_time
            created_by
            updated_by
          }

          property {
            ...propertyCore
          }

          shares {
            ...sharesCore
          }

          superannuation {
            ...superannuationCore
          }

          vehicles {
            ...vehiclesCore
          }
        }

        notes {
          ...notes
        }

        history {
          ...history
        }
      }
      assets_has_liabilities {
        assets_ID
        liabilities_ID
      }
      clients_has_liabilities {
        clients_ID
        liabilities_ID
      }
      entities_has_liabilities {
        entities_ID
        liabilities_ID
      }
    }
  }
  ${annuityCoreFragment}
  ${cashCoreFragment}
  ${collectiblesCoreFragment}
  ${otherassetsCoreFragment}
  ${propertyCoreFragment}
  ${sharesCoreFragment}
  ${superannuationCoreFragment}
  ${vehiclesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;
