import { gql } from "@apollo/client";
import { addressFragment } from ".";

export const createOneAddress = gql`
  mutation CreateOneAddress($data: addressCreateInput!) {
    createOneAddress(data: $data) {
      ...address
    }
  }
  ${addressFragment}
`;

export const updateOneAddress = gql`
  mutation UpdateOneAddress(
    $data: addressUpdateInput!
    $where: addressWhereUniqueInput!
  ) {
    updateOneAddress(data: $data, where: $where) {
      ...address
    }
  }
  ${addressFragment}
`;

export const deleteOneAddress = gql`
  mutation DeleteOneAddress($where: addressWhereUniqueInput!) {
    deleteOneAddress(where: $where) {
      ...address
    }
  }
  ${addressFragment}
`;
