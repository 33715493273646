import { QueryResult } from "@apollo/client";
import {
  PagetemplateSubscription,
  FindUniquePagetemplateQuery,
  MutationType,
  FindUniquePagetemplateQueryVariables,
} from "../../../codegen/schema";
import { pagetemplate } from "../subscription";

interface PagetemplateResolverParams {
  prev: FindUniquePagetemplateQuery;
  payload: PagetemplateSubscription["pagetemplate"];
}

function updatePagetemplate({ prev, payload }: PagetemplateResolverParams) {
  const pagetemplate = payload.data;

  if (!prev.findUniquePagetemplate) return prev;

  return Object.assign({}, prev, {
    findUniquePagetemplate:
      pagetemplate.ID === prev.findUniquePagetemplate.ID
        ? {
            ...prev.findUniquePagetemplate,
            ...pagetemplate,
          }
        : prev.findUniquePagetemplate,
  });
}

function pagetemplateResolver({ prev, payload }: PagetemplateResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createPagetemplate(prev, payload);

    case MutationType.Update:
      return updatePagetemplate({ prev, payload });

    // case MutationType.Delete:
    //   return deletePagetemplate({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniquePagetemplate(query: Pick<
  QueryResult<FindUniquePagetemplateQuery, FindUniquePagetemplateQueryVariables>,
  "subscribeToMore" | "variables"
>) {
  query.subscribeToMore({
    document: pagetemplate,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: PagetemplateSubscription } }
    ) =>
      pagetemplateResolver({
        prev,
        payload: payload.subscriptionData.data.pagetemplate
      }),
    variables: query.variables,
  });
}
