import { QueryResult } from "@apollo/client";
import {
  FindManyReviewQuery,
  FindManyReviewQueryVariables,
} from "../../../codegen/schema";
import { reviewFindManyReviews } from "../index";

export function findManyReviewsSubscription(
  query: Pick<
    QueryResult<FindManyReviewQuery, FindManyReviewQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  reviewFindManyReviews(query);
}
