import React from "react";
import {
  checkFieldIsEmpty,
  getGroupInsurance,
} from "../../../../../Functions/GeneralFunctions";
import {
  Content,
  Contenttemplate,
  Contenttemplate_InsuranceType,
  Content_InsuranceType,
  Insurance,
  Theme,
  FindUniqueGroupQuery,
  FindUniqueAdviceQuery,
} from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";

interface InsuranceComponentProps {
  content?: Content | undefined;
  contenttemplate?: Contenttemplate | undefined;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const InsuranceGrouped: React.FC<InsuranceComponentProps> = ({
  content,
  contenttemplate,
  theme,
  group,
  advice,
}) => {
  type InsuranceType = {
    Type: Content_InsuranceType | Contenttemplate_InsuranceType | string;
    Insurer: string;
    LifeInsured: string;
    AnnualPremium: number;
    PaymentFrequency: string;
    Anniversary: string;
    PolicyNumber: string;
    Cover: number;
  };

  const insurance: Insurance[] | InsuranceType[] = group
    ? content?.scenario_ID === null
      ? // Group insurance
        getGroupInsurance(group)
      : // Scenario insurance
        advice?.scenario.flatMap((scenario) => scenario.scenarioinsurance)
    : // In the template
      [
        {
          Type: "Trauma",
          Insurer: "BT",
          LifeInsured: "John Smith",
          AnnualPremium: 5000.0,
          PaymentFrequency: "Anually",
          Anniversary: "2023-01-01",
          PolicyNumber: "NA987654",
          Cover: 1000000.0,
        },
        {
          Type: "Death",
          Insurer: "Suncorp",
          LifeInsured: "Jane Smith",
          AnnualPremium: 2000.0,
          PaymentFrequency: "Anually",
          Anniversary: "2023-01-01",
          PolicyNumber: "NA987654",
          Cover: 500000.0,
        },
      ];

  return (
    <React.Fragment>
      <div
        className="flex flex-col w-full "
        style={{
          borderBottom: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
        }}
      >
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
        >
          Insurance
        </StyledText>
      </div>
      {insurance.length > 0 ? (
        <div className="grid grid-cols-5 gap-2 w-full">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Type
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Insurer
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Life Insured
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Cover
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Annual Premium
          </StyledText>
        </div>
      ) : (
        <h5>No insurance recorded</h5>
      )}
      {insurance.map((insurance, objectIndex) => {
        return (
          <div
            className="grid grid-cols-5 gap-2 mt-2 w-full"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(insurance.Type)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(insurance.Insurer)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(insurance.LifeInsured)}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(insurance.Cover, "dollar")}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(insurance.AnnualPremium, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  );
};
