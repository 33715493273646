import {
  ScenarioliabilitySubscription,
  FindUniqueAdviceQuery,
  MutationType,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { scenarioliability } from "../subscription";
import { QueryResult } from "@apollo/client";

interface ScenarioliabilityResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: ScenarioliabilitySubscription["scenarioliability"];
}

function createScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioliability.scenario_ID
          ? {
              ...scenario,
              scenarioliability: [
                ...scenario.scenarioliability,
                scenarioliability,
              ],
            }
          : scenario
      ),
    },
  });
}

function updateScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioliability.scenario_ID
          ? {
              ...scenario,
              scenarioliability: scenario.scenarioliability.map(
                (scenarioliabilitys) =>
                  scenarioliabilitys.ID === scenarioliability.ID
                    ? { ...scenarioliabilitys, ...scenarioliability }
                    : scenarioliabilitys
              ),
            }
          : scenario
      ),
    },
  });
}

function deleteScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioliability.scenario_ID
          ? {
              ...scenario,
              scenarioliability: scenario.scenarioliability.filter(
                (scenarioliabilitys) =>
                  scenarioliabilitys.ID !== scenarioliability.ID
              ),
            }
          : scenario
      ),
    },
  });
}

function scenarioliabilityResolver({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioliability({ prev, payload });

    case MutationType.Update:
      return updateScenarioliability({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioliability({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioliabilityFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioliability,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioliabilitySubscription };
      }
    ) =>
      scenarioliabilityResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioliability,
      }),
    variables: query.variables,
  });
}
