import React from "react";
import { IncomeOptimisationScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";

interface IncomeOptimisationPlaceholderModelProps {
  theme?: Theme;
}

var data: ScenarioData[] = [
  {
    taxBracket: "0%",
    [`John Smith Net Income`]: 18200,
    [`John Smith Tax Payable`]: 0,
    [`Jane Smith Net Income`]: 18200,
    [`Jane Smith Tax Payable`]: 0,
  },
  {
    taxBracket: "19%",
    [`John Smith Net Income`]: 21707,
    [`John Smith Tax Payable`]: 5092,
    [`Jane Smith Net Income`]: 21707,
    [`Jane Smith Tax Payable`]: 5092,
  },
  {
    taxBracket: "32.5%",
    [`John Smith Net Income`]: 50624,
    [`John Smith Tax Payable`]: 24375,
    [`Jane Smith Net Income`]: 43874.649999999994,
    [`Jane Smith Tax Payable`]: 21124.350000000002,
  },
  {
    taxBracket: "37%",
    [`John Smith Net Income`]: 37799,
    [`John Smith Tax Payable`]: 22200,
    [`Jane Smith Net Income`]: 0,
    [`Jane Smith Tax Payable`]: 0,
  },
  {
    taxBracket: "45%",
    [`John Smith Net Income`]: 0,
    [`John Smith Tax Payable`]: 0,
    [`Jane Smith Net Income`]: 0,
    [`Jane Smith Tax Payable`]: 0,
  },
];

export const IncomeOptimisationScenarioPlaceholderModel = ({
  theme,
}: IncomeOptimisationPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Income Optimisation
        </StyledText>

        <ModelTooltip type={Model_Type.IncomeOptimisation} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <IncomeOptimisationScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
