export type ResetData = {
  email?: string;
  password: string;
  confirmPassword: string;
  reset_token?: string;
  activation_token?: string;
};

export function validateEmail(email: string | undefined): boolean {
  var valid = false;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (email && emailRegex.test(email)) {
    valid = true;
  } else {
    valid = false;
  }

  return valid;
}

export function validatePassword(password: string | undefined): boolean {
  var valid = false;

  if (password) {
    valid = true;
  } else {
    valid = false;
  }

  return valid;
}

export function passwordMatch(data: {
  password: string | undefined;
  confirmPassword?: string | undefined;
}) {
  var valid = true;
  if (
    data.password === "" ||
    data.confirmPassword === "" ||
    data.password !== data.confirmPassword
  ) {
    valid = false;
  }
  return valid;
}
