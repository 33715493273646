import React from "react";
import {
  FindUniqueDocumenttemplateQuery,
  FindUniqueSectiontemplateQuery,
  SortOrder,
  Theme,
  useFindManyPagetemplateQuery,
  useFindManySectiontemplateQuery,
} from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";
import { ComponentLoader } from "../../../../Loaders/ComponentLoader";
import { Refetch } from "../../../../Refetch";

interface TableofContentsComponentProps {
  documenttemplate: FindUniqueDocumenttemplateQuery["findUniqueDocumenttemplate"];
  theme?: Theme;
}

export const TableofContentsTemplateComponent: React.FC<
  TableofContentsComponentProps
> = ({ documenttemplate, theme }) => {
  const {
    data: { findManySectiontemplate: sectiontemplates } = {},
    error: sectiontemplateError,
    loading: sectiontemplateLoading,
    refetch: sectiontemplateRefetch,
  } = useFindManySectiontemplateQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        documenttemplate_ID: { equals: documenttemplate?.ID },
      },
      orderBy: [
        {
          Position: SortOrder.Asc,
        },
      ],
    },
  });

  const {
    data: { findManyPagetemplate: pagetemplates } = {},
    error: pagetemplateError,
    loading: pagetemplateLoading,
    refetch: pagetemplateRefetch,
  } = useFindManyPagetemplateQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        sectiontemplate_documenttemplate_ID: { equals: documenttemplate?.ID },
      },
      orderBy: [
        {
          sectiontemplate: { Position: SortOrder.Asc },
        },
        {
          Position: SortOrder.Asc,
        },
      ],
    },
  });

  if (
    (!sectiontemplates && sectiontemplateLoading) ||
    (!pagetemplates && pagetemplateLoading)
  ) {
    return <ComponentLoader />;
  }

  if (
    (sectiontemplateError && !sectiontemplateLoading) ||
    (pagetemplateError && !pagetemplateLoading)
  ) {
    return (
      <Refetch
        refetch={() => {
          sectiontemplateRefetch();
          pagetemplateRefetch();
        }}
      />
    );
  }

  if (!sectiontemplates || !pagetemplates) return null;

  const TableofcontentsDiv = ({
    section,
    pageNumber,
  }: {
    section: FindUniqueSectiontemplateQuery["findUniqueSectiontemplate"];
    pageNumber: number;
  }) => {
    return (
      <div className="grid grid-cols-[80%,20%] items-end w-full">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
          style={{
            borderBottomColor: theme?.colour[0]?.Value ?? "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            margin: "0.2rem 0",
          }}
        >
          {section?.Title}
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          style={{
            borderBottomColor: theme?.colour[0]?.Value ?? "#000000",
            borderBottomStyle: "dashed",
            borderBottomWidth: "1px",
            textAlign: "center",
            margin: "0.2rem 0",
          }}
        >
          {pageNumber + 1}
        </StyledText>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-col w-full">
        {sectiontemplates &&
          sectiontemplates.map((section) => {
            if (section.Title === "Front pages") return null;

            var pageNumber = [...pagetemplates].findIndex(
              (page) => page.sectiontemplate_ID === section.ID
            );

            return (
              <React.Fragment key={section.ID}>
                <TableofcontentsDiv section={section} pageNumber={pageNumber} />
              </React.Fragment>
            );
          })}
      </div>
    </React.Fragment>
  );
};
