import { gql } from "@apollo/client";
import { feesFragment } from "./fragment";
/* Fees */
export const createOneFees = gql`
  mutation CreateOneFees($data: feesCreateInput!) {
    createOneFees(data: $data) {
      ...fees
    }
  }
  ${feesFragment}
`;

export const updateOneFees = gql`
  mutation UpdateOneFees(
    $data: feesUpdateInput!
    $where: feesWhereUniqueInput!
  ) {
    updateOneFees(data: $data, where: $where) {
      ...fees
    }
  }
  ${feesFragment}
`;

export const deleteOneFees = gql`
  mutation DeleteOneFees($where: feesWhereUniqueInput!) {
    deleteOneFees(where: $where) {
      ...fees
    }
  }
  ${feesFragment}
`;
