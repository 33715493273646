import { QueryResult } from "@apollo/client";
import {
  RoleSubscription,
  FindUniqueTeamQuery,
  MutationType,
  FindUniqueTeamQueryVariables,
} from "../../../codegen/schema";
import { role } from "../subscription";

interface RoleResolverParams {
  prev: FindUniqueTeamQuery;
  payload: RoleSubscription["role"];
}

function createRole({ prev, payload }: RoleResolverParams) {
  const role = payload.data;

  if (!prev.findUniqueTeam) return prev;

  return Object.assign({}, prev, {
    findUniqueTeam: {
      ...prev.findUniqueTeam,
      role:
        role.team_ID === prev.findUniqueTeam.ID
          ? [...prev.findUniqueTeam.role, role]
          : prev.findUniqueTeam.role,
    },
  });
}

function updateRole({ prev, payload }: RoleResolverParams) {
  const role = payload.data;

  if (!prev.findUniqueTeam) return prev;

  return Object.assign({}, prev, {
    findUniqueTeam: {
      ...prev.findUniqueTeam,
      role: prev.findUniqueTeam.role.map((ele) =>
        ele.ID === role.ID ? { ...ele, ...role } : ele
      ),
    },
  });
}

function deleteRole({ prev, payload }: RoleResolverParams) {
  const role = payload.data;

  if (!prev.findUniqueTeam) return prev;

  return Object.assign({}, prev, {
    findUniqueTeam: {
      ...prev.findUniqueTeam,
      role: [...prev.findUniqueTeam.role].filter((ele) => ele.ID !== role.ID),
    },
  });
}

function roleResolver({ prev, payload }: RoleResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createRole({ prev, payload });

    case MutationType.Update:
      return updateRole({ prev, payload });

    case MutationType.Delete:
      return deleteRole({ prev, payload });

    default:
      return prev;
  }
}

export function roleFindUniqueTeam(
  query: Pick<
    QueryResult<FindUniqueTeamQuery, FindUniqueTeamQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: role,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: RoleSubscription } }
    ) =>
      roleResolver({
        prev,
        payload: payload.subscriptionData.data.role,
      }),
    variables: query.variables,
  });
}
