import React from "react";

import { useFindUniqueFileQuery } from "../../../../codegen/schema";

/**
 * @author Lara Bindon
 * @date 12-10-2022
 * @description Returns upload image for documenbt/theme template creator
 */
interface ImageProps {
  fileName?: string;
  filePath: string;
  width?: number;
  height?: number;
  id: string | undefined;
  label: string;
}

export const Image: React.FC<ImageProps> = ({
  fileName,
  filePath,
  width,
  height,
  id,
  label,
}) => {
  const { data: { findUniqueFile: file } = {} } = useFindUniqueFileQuery({
    fetchPolicy: "cache-and-network",
    skip: !filePath || !fileName,
    variables: {
      filePath: filePath + fileName,
    },
  });

  // useEffect(() => {
  //   findUniqueFileSubscription(findUniqueFileResult);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return file && file.fileExists ? (
    <img
      id={id}
      alt={label}
      src={
        process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
        "/" +
        filePath +
        fileName +
        "#" +
        new Date().getTime()
      }
      width={width}
      height={height}
      // className={className}
      style={{ width: width, height: height }}
    />
  ) : (
    <div />
  );
};
