import React from "react";
import { TrustPlanningComparisonModelGraph } from "../Graph";
import { ComparisonData } from "../../../Calculations";
import dayjs from "dayjs";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";

interface TrustPlanningPlaceholderModelProps {
  theme?: Theme;
}

var data: ComparisonData[] = Array.from(Array(29).keys()).map((year) => ({
  year: dayjs().year() + year,
  "Scenario 1": 100000 * Math.pow(1 + 0.07, year),
  "Scenario 2": 100000 * Math.pow(1 + 0.1, year),
  "Scenario 3": 100000 * Math.pow(1 + 0.05, year),
  target: year === 29 ? 1200000 : undefined,
}));

export const TrustPlanningComparisonPlaceholderModel = ({
  theme,
}: TrustPlanningPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Trust Planning
        </StyledText>

        <ModelTooltip type={Model_Type.TrustPlanning} />
      </div>
      <h1
        className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <TrustPlanningComparisonModelGraph comparisonData={data} />
      </div>
    </React.Fragment>
  );
};
