export function AssetDebtCompositionModelDescription() {
  return (
    <p className="text-2xs italic">
      The asset and debt composition model displays the assets and debts of the
      group relative to each other. This uses data directly from the group and
      not from the scenario data.
    </p>
  );
}

export function AssetDebtCompositioCalculationTooltip() {
  return (
    <>
      <p>
        Asset and debt composition uses live data rather than scenario data.
        <br /> <br />
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#advice-totals"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          This graph uses advice totals. Click here for more information.
        </a>
        <br />
        Each asset and liability total is divided by the sum of all totals to determine
        the percentage of that asset / liability class. All totals will display in the legened even if
        the value is 0%.
      </p>
    </>
  );
}
