import { gql } from "@apollo/client";
import { clientsFragment, clientsCoreFragment } from "./fragment";

export const clients = gql`
  subscription Clients($where: clientsWhereUniqueInput) {
    clients(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...clients
      }
    }
  }
  ${clientsFragment}
`;

export const clientsMany = gql`
  subscription ClientsMany($where: clientsWhereInput) {
    clientsMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...clientsCore
      }
    }
  }
  ${clientsCoreFragment}
`;
