import {
  Content_ModelToRender,
  Contenttemplate_ModelToRender,
  Maybe,
  Theme,
} from "../../../../../../codegen/schema";
import { RetirementPlanningComparisonPlaceholderModel } from "../Comparison/Placeholder";
import { RetirementPlanningScenarioPlaceholderModel } from "../Scenario/Placeholder";

interface RetirementPlanningComparisonModelPlaceholderProps {
  ModelToRender?:
    | Maybe<Content_ModelToRender>
    | Maybe<Contenttemplate_ModelToRender>;
  theme?: Theme;
}

export const RetirementPlanningModelPlaceholder = ({
  ModelToRender,
  theme
}: RetirementPlanningComparisonModelPlaceholderProps) => {
  if (
    ModelToRender === Content_ModelToRender.Comparison ||
    ModelToRender === Contenttemplate_ModelToRender.Comparison
  ) {
    return <RetirementPlanningComparisonPlaceholderModel theme={theme}/>;
  } else if (
    ModelToRender === Content_ModelToRender.Scenario ||
    ModelToRender === Contenttemplate_ModelToRender.Scenario
  ) {
    return <RetirementPlanningScenarioPlaceholderModel theme={theme} />;
  } else return <p>Please choose a Scenario model or Comparison model.</p>;
};
