import { QueryResult } from "@apollo/client";
import {
  ScenarioassetSubscription,
  FindUniqueScenarioQuery,
  MutationType,
  FindUniqueScenarioQueryVariables,
} from "../../../codegen/schema";
import { scenarioasset } from "../subscription";

interface ScenarioassetResolverParams {
  prev: FindUniqueScenarioQuery;
  payload: ScenarioassetSubscription["scenarioasset"];
}
function createScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioasset:
        scenarioasset.scenario_ID === prev.findUniqueScenario.ID
          ? [...prev.findUniqueScenario.scenarioasset, scenarioasset]
          : prev.findUniqueScenario.scenarioasset,
    },
  });
}

function updateScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioasset: prev.findUniqueScenario.scenarioasset.map(
        (scenarioassets) =>
          scenarioasset.ID === scenarioassets.ID
            ? { ...scenarioassets, ...scenarioasset }
            : scenarioassets
      ),
    },
  });
}

function deleteScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioasset: [...prev.findUniqueScenario.scenarioasset].filter(
        (scenarioassets) => scenarioassets.ID !== scenarioasset.ID
      ),
    },
  });
}

function scenarioassetResolver({ prev, payload }: ScenarioassetResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioasset({ prev, payload });

    case MutationType.Update:
      return updateScenarioasset({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioasset({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioassetFindUniqueScenario(
  query: Pick<
    QueryResult<FindUniqueScenarioQuery, FindUniqueScenarioQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioasset,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioassetSubscription } }
    ) =>
      scenarioassetResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioasset,
      }),
    variables: query.variables,
  });
}
