import {
  Content_ModelToRender,
  Contenttemplate_ModelToRender,
  Maybe,
  Theme,
} from "../../../../../../codegen/schema";
import { SuperannuationInsuranceComparisonPlaceholderModel } from "../Comparison/Placeholder";
import { SuperannuationInsuranceScenarioPlaceholderModel } from "../Scenario/Placeholder";

interface SuperannuationInsuranceComparisonModelPlaceholderProps {
  ModelToRender?:
    | Maybe<Content_ModelToRender>
    | Maybe<Contenttemplate_ModelToRender>;
  theme?: Theme;
}

export const SuperannuationInsuranceModelPlaceholder = ({
  ModelToRender,
  theme,
}: SuperannuationInsuranceComparisonModelPlaceholderProps) => {
  if (
    ModelToRender === Content_ModelToRender.Comparison ||
    ModelToRender === Contenttemplate_ModelToRender.Comparison
  ) {
    return <SuperannuationInsuranceComparisonPlaceholderModel theme={theme} />;
  } else if (
    ModelToRender === Content_ModelToRender.Scenario ||
    ModelToRender === Contenttemplate_ModelToRender.Scenario
  ) {
    return <SuperannuationInsuranceScenarioPlaceholderModel theme={theme} />;
  } else return <p>Please choose a Scenario model or Comparison model.</p>;
};
