import { gql } from "@apollo/client";
import { reviewagendaFragment } from "./fragment";

export const createOneReviewagenda = gql`
  mutation CreateOneReviewagenda($data: reviewagendaCreateInput!) {
    createOneReviewagenda(data: $data) {
      ...reviewagenda
    }
  }
  ${reviewagendaFragment}
`;

export const updateOneReviewagenda = gql`
  mutation UpdateOneReviewagenda(
    $data: reviewagendaUpdateInput!
    $where: reviewagendaWhereUniqueInput!
  ) {
    updateOneReviewagenda(data: $data, where: $where) {
      ...reviewagenda
    }
  }
  ${reviewagendaFragment}
`;

export const deleteOneReviewagenda = gql`
  mutation DeleteOneReviewagenda($where: reviewagendaWhereUniqueInput!) {
    deleteOneReviewagenda(where: $where) {
      ...reviewagenda
    }
  }
  ${reviewagendaFragment}
`;
