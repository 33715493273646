import { QueryResult } from "@apollo/client";
import {
  TeamSubscription,
  FindUniqueTeamQuery,
  MutationType,
  FindUniqueTeamQueryVariables,
} from "../../../codegen/schema";
import { team } from "../subscription";

interface TeamResolverParams {
  prev: FindUniqueTeamQuery;
  payload: TeamSubscription["team"];
}

function updateTeam({ prev, payload }: TeamResolverParams) {
  const team = payload.data;

  if (!prev.findUniqueTeam) return prev;
  return Object.assign({}, prev, {
    findUniqueTeam:
      prev.findUniqueTeam.ID === team.ID
        ? {
            ...prev.findUniqueTeam,
            ...team,
          }
        : prev.findUniqueTeam,
  });
}

function deleteTeam({ prev, payload }: TeamResolverParams) {
  const team = payload.data;

  if (!prev.findUniqueTeam) return prev;
  return Object.assign({}, prev, {
    findUniqueTeam:
      prev.findUniqueTeam.ID === team.ID
        ? {
            ...prev.findUniqueTeam,
            undefined,
          }
        : prev.findUniqueTeam,
  });
}

function teamResolver({ prev, payload }: TeamResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createTeam(prev, payload);

    case MutationType.Update:
      return updateTeam({ prev, payload });

    case MutationType.Delete:
      return deleteTeam({ prev, payload });

    default:
      return prev;
  }
}

export function teamFindUniqueTeam(
  query: Pick<
    QueryResult<FindUniqueTeamQuery, FindUniqueTeamQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: team,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: TeamSubscription } }
    ) =>
      teamResolver({
        prev,
        payload: payload.subscriptionData.data.team,
      }),
    variables: query.variables,
  });
}
