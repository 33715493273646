import dayjs from "dayjs";
import React from "react";
import { RetirementPlanningScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface RetirementPlanningPlaceholderModelProps {
  theme?: Theme;
}

const initialValue = 100000;
const transferBalanceCap = 1900000;
var data: ScenarioData[] = [];

Array.from(Array(20).keys()).forEach((value) => {
  var previousYear = data[value - 1];

  data.push({
    year: dayjs().year() + value,
    growth: 0,
    growthTax: 0,
    projectedValue: previousYear
      ? initialValue * (1 + 0.05 / 12) ** (12 * value)
      : initialValue,
    target: value === 19 ? 500000 : undefined,
    concessionalContributions: previousYear
    ? 20000 * (1 + 0.025 / 12) ** (12 * value)
    : 20000,
    nonConcessionalContributions: 0,
    contributionsTax: previousYear
    ? (20000 * (1 + 0.025 / 12) ** (12 * value)) * 0.15
    : 20000 * 0.15,
    withdrawals: 0,
    transferBalanceCap: previousYear
      ? Math.floor(transferBalanceCap * (1 + 0.0225 / 1) ** (1 * value) / 100000) * 100000
      : transferBalanceCap,
  });
});

export const RetirementPlanningScenarioPlaceholderModel = ({
  theme,
}: RetirementPlanningPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Retirement Planning
        </StyledText>

        <ModelTooltip type={Model_Type.RetirementPlanning} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
      <RetirementPlanningScenarioModelGraph
        scenarioData={data}
        liveData={undefined}
      />
      </div>
    </React.Fragment>
  );
};
