import { QueryResult } from "@apollo/client";
import { staffFindCurrentStaff } from "../reducers";
import {
  FindCurrentStaffQuery,
  FindCurrentStaffQueryVariables,
} from "../../../codegen/schema";

export default function findCurrentStaffSubscription(
  query: Pick<
    QueryResult<FindCurrentStaffQuery, FindCurrentStaffQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  staffFindCurrentStaff(query);
}
