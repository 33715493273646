export const snackbar = (state, action) => {
  switch (action.type) {
    case "snackbar/reset":
      return {
        ...state,
        snackbar: {
          type: "",
          message: "",
          open: false,
        },
      };

    case "snackbar/error":
      return {
        ...state,
        snackbar: {
          type: "error",
          message: action.payload,
          open: true,
        },
      };

    case "snackbar/warning":
      return {
        ...state,
        snackbar: {
          type: "warning",
          message: action.payload,
          open: true,
        },
      };

    case "snackbar/success":
      return {
        ...state,
        snackbar: {
          type: "success",
          message: action.payload,
          open: true,
        },
      };

    default:
      return state;
  }
};
