import React from "react";
import {
  checkFieldIsEmpty,
  getGroupInsurance,
} from "../../../../../Functions/GeneralFunctions";
import {
  Content,
  Contenttemplate,
  Contenttemplate_InsuranceType,
  Content_InsuranceType,
  Insurance,
  Theme,
  FindUniqueGroupQuery,
  FindUniqueAdviceQuery,
} from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";

interface InsuranceComponentProps {
  content?: Content | undefined;
  contenttemplate?: Contenttemplate | undefined;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const InsuranceIndividual: React.FC<InsuranceComponentProps> = ({
  content,
  contenttemplate,
  theme,
  group,
  advice,
}) => {
  type InsuranceType = {
    Type: Content_InsuranceType | Contenttemplate_InsuranceType | string;
    LifeInsured: string;
    AnnualPremium: number;
    PaymentFrequency: string;
    Anniversary: string;
    PolicyNumber: string;
    Cover: number;
  };

  const insurance: Insurance | InsuranceType = group
    ? content?.insurance_ID
      ? // Group insurance
        {
          ...getGroupInsurance(group).find(
            (insurance: Insurance) => insurance.ID === content.insurance_ID
          ),
          notes: undefined,
        }
      : content?.scenarioinsurance_ID && advice
      ? // Scenario insurance
        advice?.scenario
          .flatMap((scenario) => scenario.scenarioinsurance)
          .find((insurance) => insurance.ID === content.scenarioinsurance_ID)
      : // No insurance assigned
        {}
    : // In the template
      {
        Type: contenttemplate
          ? (contenttemplate.InsuranceType as Contenttemplate_InsuranceType)
          : "",
        LifeInsured: "John Smith",
        AnnualPremium: 5000.0,
        PaymentFrequency: "Anually",
        Anniversary: "2023-01-01",
        PolicyNumber: "NA987654",
        Cover: 1000000.0,
      };

  return (
    <div className="w-full">
      <StyledText
        element={theme?.element.find((element) => element.Type === "H5")}
        className="flex items-center "
      >
        {contenttemplate
          ? contenttemplate.InsuranceType?.replaceAll("_", " ")
          : content?.InsuranceType?.replaceAll("_", " ")}
      </StyledText>
      <div className="grid grid-cols-2 ">
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Life Insured
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(insurance.LifeInsured)}
          </StyledText>
        </div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Annual premium
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(insurance.AnnualPremium, "dollar")}
          </StyledText>
        </div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Payment Frequency
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(insurance.PaymentFrequency)}
          </StyledText>
        </div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Anniversary
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(insurance.Anniversary, "date")}
          </StyledText>
        </div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Policy number
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(insurance.PolicyNumber)}
          </StyledText>
        </div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Cover
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(insurance.Cover, "dollar")}
          </StyledText>
        </div>
      </div>
    </div>
  );
};
