import React, { useEffect } from "react";
import {
  FindUniqueDocumenttemplateQuery,
  FindUniqueOrganisationQuery,
  Theme,
  useFindManyContenttemplateQuery,
} from "../../../../../../../codegen/schema";

import { PageContentComponents } from "./Components";
import { Editor } from "slate";
import { SlateSelected } from "../../../../../../../Components/Slate/Types";
import { WebsocketProvider } from "../../../../../../../Components/Slate/Yjs";
import { findManyContenttemplateSubscription } from "../../../../../../../API/contenttemplate/subscriptions";
import { ComponentLoader } from "../../../../../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../../../../../Components/Refetch";

interface PageTemplateProps {
  organisation: FindUniqueOrganisationQuery["findUniqueOrganisation"];
  documenttemplate: FindUniqueDocumenttemplateQuery["findUniqueDocumenttemplate"];
  theme?: Theme;
  pagetemplate_ID: number;
  activeEditor: Editor | undefined;
  setActiveEditor: React.Dispatch<React.SetStateAction<Editor | undefined>>;
  selected?: SlateSelected;
  setSelected: React.Dispatch<React.SetStateAction<SlateSelected | undefined>>;
  yjsProvider: WebsocketProvider;
}

export const PageContent: React.FC<PageTemplateProps> = ({
  organisation,
  documenttemplate,
  theme,
  pagetemplate_ID,
  activeEditor,
  setActiveEditor,
  selected,
  setSelected,
  yjsProvider,
}) => {
  const {
    data: { findManyContenttemplate: contenttemplates } = {},
    error: contentError,
    loading: contentLoading,
    refetch: contentRefetch,
    ...contentResult
  } = useFindManyContenttemplateQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        pagetemplate_ID: { equals: pagetemplate_ID },
      },
    },
  });

  useEffect(() => {
    findManyContenttemplateSubscription(contentResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!contenttemplates && contentLoading) {
    return <ComponentLoader />;
  }

  if (contentError && !contentLoading) {
    return (
      <Refetch
        refetch={() => {
          contentRefetch();
        }}
      />
    );
  }
  if (!contenttemplates) return null;

  return (
    <React.Fragment>
      {contenttemplates.map((contenttemplate, contentIndex) => {
        return (
          <React.Fragment key={contenttemplate.ID}>
            <PageContentComponents
              id={contenttemplate.ID}
              documenttemplate={documenttemplate}
              theme={theme}
              index={contentIndex}
              contenttemplate={contenttemplate}
              activeEditor={activeEditor}
              setActiveEditor={setActiveEditor}
              selected={selected}
              setSelected={setSelected}
              organisation={organisation}
              yjsProvider={yjsProvider}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
