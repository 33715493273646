import { gql } from "@apollo/client";
import { loansCoreFragment, loansFragment } from "../loans/fragment";
import {
  otherliabilitiesCoreFragment,
  otherliabilitiesFragment,
} from "../otherliabilities";

export const liabilitiesCoreFragment = gql`
  fragment liabilitiesCore on liabilitiesCoreCUSTOM {
    loans {
      ...loansCore
    }
    otherliabilities {
      ...otherliabilitiesCore
    }
  }
  ${loansCoreFragment}
  ${otherliabilitiesCoreFragment}
`;

export const liabilitiesFragment = gql`
  fragment liabilities on liabilitiesCUSTOM {
    loans {
      ...loans
    }
    otherliabilities {
      ...otherliabilities
    }
  }
  ${loansFragment}
  ${otherliabilitiesFragment}
`;
