import { gql } from "@apollo/client";
import { notesFragment } from "../notes/fragment";

export const insuranceCoreFragment = gql`
  fragment insuranceCore on insurance {
    ID
    Type
    Insurer
    Owner
    AnnualPremium
    PaymentFrequency
    Anniversary
    PolicyNumber
    Purpose
    Cover
    PolicyInSuper
    WaitingPeriod
    PremiumType
    BenefitToAge
    BenefitPeriod
    NonSuperOwner
    PolicyNonSuper
    NonSuperPolicyNumber
    NonSuperPremiumPerAnnum
    NonSuperPremiumFrequency
    NonSuperMonthlyPremium
    IncludedInDeathCover
    SuperOwner
    PolicySuper
    SuperPolicyNumber
    SuperPremiumPerAnnum
    SuperMonthlyPremium
    SuperPremiumFrequency
    BenefitType
    LifeInsured
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const insuranceFragment = gql`
  fragment insurance on insurance {
    ...insuranceCore
    notes {
      ...notes
    }
  }
  ${insuranceCoreFragment}
  ${notesFragment}
`;
