import { QueryResult } from "@apollo/client";
import {
  EmploymentSubscription,
  FindUniqueEmploymentQuery,
  FindUniqueEmploymentQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { employment } from "../subscription";

interface EmploymentResolverParams {
  prev: FindUniqueEmploymentQuery;
  payload: EmploymentSubscription["employment"];
}

function updateEmployment({ prev, payload }: EmploymentResolverParams) {
  const employment = payload.data;

  if (!prev.findUniqueEmployment) return prev;
  return Object.assign({}, prev, {
    findUniqueEmployment:
      employment.ID === prev.findUniqueEmployment.ID
        ? {
            ...prev.findUniqueEmployment,
            ...employment,
          }
        : prev.findUniqueEmployment,
  });
}

function deleteEmployment({ prev, payload }: EmploymentResolverParams) {
  const employment = payload.data;
  if (!prev.findUniqueEmployment) return prev;

  return Object.assign({}, prev, {
    findUniqueEmployment:
      prev.findUniqueEmployment.ID === employment.ID
        ? {
            ...prev.findUniqueEmployment,
            undefined,
          }
        : prev.findUniqueEmployment,
  });
}

function employmentResolver({ prev, payload }: EmploymentResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createEmployment(prev, payload);

    case MutationType.Update:
      return updateEmployment({ prev, payload });

    case MutationType.Delete:
      return deleteEmployment({ prev, payload });

    default:
      return prev;
  }
}
export function FindUniqueEmployment(
  query: Pick<
    QueryResult<FindUniqueEmploymentQuery, FindUniqueEmploymentQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: employment,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: EmploymentSubscription } }
    ) =>
      employmentResolver({
        prev,
        payload: payload.subscriptionData.data.employment
      }),
    variables: query.variables,
  });
}
