import { gql } from "@apollo/client";
import { insuranceCoreFragment, insuranceFragment } from "./fragment";

export const findUniqueInsurance = gql`
  query FindUniqueInsurance($where: insuranceWhereUniqueInput!) {
    findUniqueInsurance(where: $where) {
      ...insurance
    }
  }
  ${insuranceFragment}
`;

export const findManyInsurance = gql`
  query FindManyInsurance(
    $where: insuranceWhereInput
    $distinct: [InsuranceScalarFieldEnum!]
    $orderBy: [insuranceOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: insuranceWhereUniqueInput
  ) {
    findManyInsurance(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...insuranceCore
    }
  }
  ${insuranceCoreFragment}
`;
