import React from "react";
import {
  Annuity,
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../Functions/Owner";

interface AssetsAnnuityProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns annuity component for advice template && document.
 */
export const AssetsAnnuity: React.FC<AssetsAnnuityProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  // type Annuity = {
  //   assets_ID: number | null;
  //   Type: string;
  //   Owner: string;
  //   Value: string;
  // };

  const summaryAnnuity: Annuity[] = group
    ? content?.ContentType === "Grouped"
      ? getGroupAssets(group).annuity
      : content?.asset_ID
      ? getGroupAssets(group).annuity.find(
          (annuity: Annuity) => content?.asset_ID === annuity.assets_ID
        )
      : {}
    : contenttemplate?.ContentType === "Grouped"
    ? [
        {
          Type: "Lifetime",
          Owner: "Joint",
          ResidualCapitalValue: "$130,000",
        },
        {
          Type: "Lifetime",
          Owner: "Joint",
          ResidualCapitalValue: "$200,000",
        },
      ]
    : {
        Type: "Lifetime",
        Owner: "Joint",
        ResidualCapitalValue: "$200,000",
      };

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryAnnuity.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Type
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Owner
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Capital
          </StyledText>
        </div>
      ) : (
        <h5>No annuity recorded</h5>
      )}
      {summaryAnnuity.map((annuity, objectIndex) => {
        return (
          <div
            className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(annuity.Type)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(
                group &&
                  ownerOptionsUpdateAssetDefaultValue(annuity, group).text
              )}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(annuity.ResidualCapitalValue, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>Individual Annuity view</React.Fragment>
  );
};
