import { gql } from "@apollo/client";
import { assetsFragment } from "../assets";
import { scenarioFragment } from "../scenario/fragment";
import { scenarioassetFragment } from "../scenarioasset";
import { scenarioliabilityFragment } from "../scenarioliability/fragment";
import { scenarioclientFragment } from "../scenarioclient/fragment";
import { liabilitiesFragment } from "../liabilities";
import { scenarioinsuranceFragment } from "../scenarioinsurance";
import { scenariochildFragment } from "../scenariochild";
import { goalFragment } from "../goal";
import { notesFragment } from "../notes";

export const modelCoreFragment = gql`
  fragment modelCore on model {
    ID
    groups_ID
    groups_organisation_ID
    Title
    Type
    StartDate
    EndDate
    Target
    IncludeHistorical

    create_time
    update_time
    created_by
    updated_by
  }
`;

export const modelFragment = gql`
  fragment model on model {
    ...modelCore

    advice {
      ID
      groups_ID
      groups_organisation_ID
      Type
      Progress
      Date
      InvestmentPropertyTotal
      HomePropertyTotal
      SuperannuationAssetTotal
      HomeLoanTotal
      SuperannuationLoanTotal
      InvestmentLoanTotal
      InvestmentAssetTotal

      PrimaryAdviser

      create_time
      update_time
      completed_time
      created_by
      updated_by
      completed_by
      cancellation_date

      time_elapsed_verifydata
      time_elapsed_modelling
      time_elapsed_confirmation
      time_elapsed

      scenario {
        ...scenario
      }
    }

    assets {
      ...assets
    }

    liabilities {
      ...liabilities
    }

    scenarioasset {
      ...scenarioasset
    }

    scenarioliability {
      ...scenarioliability
    }

    scenarioclient {
      ...scenarioclient
    }

    scenarioinsurance {
      ...scenarioinsurance
    }

    scenariochild {
      ...scenariochild
    }

    goal {
      ...goal
    }

    notes {
      ...notes
    }
  }
  ${modelCoreFragment}
  ${scenarioFragment}
  ${scenarioassetFragment}
  ${scenarioliabilityFragment}
  ${scenarioclientFragment}
  ${scenarioinsuranceFragment}
  ${scenariochildFragment}
  ${assetsFragment}
  ${liabilitiesFragment}
  ${goalFragment}
  ${notesFragment}
`;
