import {
  FindUniqueEmploymentQuery,
  FindUniqueEmploymentQueryVariables,
} from "../../../codegen/schema";
import { notesFindUniqueEmployment } from "../../notes";
import { FindUniqueEmployment } from "../";
import { QueryResult } from "@apollo/client";

export default function findUniqueEmploymentSubscription(
  query: Pick<
    QueryResult<FindUniqueEmploymentQuery, FindUniqueEmploymentQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindUniqueEmployment(query);
  notesFindUniqueEmployment(query);
}
