export function GrossNetIncomeModelDescription() {
  return (
    <p className="text-2xs italic">
      The gross and net income model displays the gross income for clients in
      the group and the amount of tax paid for each year. This uses data
      directly from the group and not from the scenario data.
    </p>
  );
}

export function GrossNetIncomeCalculationTooltip() {
  return (
    <>
      <p>
        {" "}
        <b>X axis </b> <br />
        this graph starts with the earliest year recorded income was recorded in
        MOAS and ends with the current year.
      </p>

      <p>
        {" "}
        <b>Gross income, Taxable income and Tax paid </b> <br />
        These are all income values that can be added and edited year on year.
        Income can be added/edited on the group page in the income tab or in
        Verify Data.
      </p>
      <p>
        {" "}
        <b>Combined Net Income </b> <br />
        <code> = Total Taxable Income - Total tax paid</code> <br />
      </p>
    </>
  );
}
