import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";
import { otherliabilitiesCoreFragment } from "../otherliabilities";

export const propertyCoreFragment = gql`
  fragment propertyCore on propertyCore {
    ID
    assets_ID
    Type
    LotNumber
    UnitNumber
    StreetNumber
    StreetName
    Suburb
    Estate
    Postcode
    State
    Country
    PurchasePrice
    DatePurchased
    ManagingAgent
    Phone
    Email
    WeeklyRent
    MarketValue
    LastRegValue
    ValuationDate
    CapGrowth
    LVR
    FirstRented
    BankValuedBy
    Position
    IncomePaidTo_clients_ID

    OngoingCosts
    CostIndexation

    create_time
    created_by
    update_time
    updated_by
  }
`;

export const propertyFragment = gql`
  fragment property on property {
    ID
    assets_ID
    Type
    LotNumber
    UnitNumber
    StreetNumber
    StreetName
    Suburb
    Estate
    Postcode
    State
    Country
    PurchasePrice
    DatePurchased
    ManagingAgent
    Phone
    Email
    WeeklyRent
    MarketValue
    LastRegValue
    ValuationDate
    CapGrowth
    LVR
    FirstRented
    BankValuedBy
    Position
    IncomePaidTo_clients_ID

    OngoingCosts
    CostIndexation

    create_time
    created_by
    update_time
    updated_by

    assetallocation {
      ...assetallocation
    }
    liabilities {
      loans {
        ID
        liabilities_ID
        Bank
        BSB
        AccountNumber
        TaxDeductible
        Purpose
        SecuredBy
        RelatedAsset
        InterestRate
        VariableFixed
        FixedExpiry
        InterestOnlyExpiry
        RepaymentPerMonth
        LoanLimit
        AvailableRedraw
        RepaymentType
        LoanOwnership
        CurrentLoanOutstanding
        LoanType
        LoanStartDate
        LoanExpiry
        Description
        InterestOnlyStartDate
        RateReviewedDate
        Offset
        Position
        Refinance
        create_time
        update_time
        created_by
        updated_by
      }
      otherliabilities {
        ...otherliabilitiesCore
      }
    }
    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${otherliabilitiesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;
