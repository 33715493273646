import { QueryResult } from "@apollo/client";
import { FindUniquePagetemplate } from "..";
import {
  FindUniquePagetemplateQuery,
  FindUniquePagetemplateQueryVariables,
} from "../../../codegen/schema";

export function findUniquePagetemplateSubscription(
  query: Pick<
    QueryResult<FindUniquePagetemplateQuery, FindUniquePagetemplateQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindUniquePagetemplate(query);
}
