import { gql } from "@apollo/client";

export const advicegoalsFragment = gql`
  fragment advicegoals on advicegoals {
    ID
    review_ID
    review_groups_ID
    review_groups_organisation_ID
    AdviceGoal
    create_time
    created_by
    updated_by
    update_time
  }
`;
