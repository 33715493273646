import { QueryResult } from "@apollo/client";
import {
  ScenariochildSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { scenariochild } from "../subscription";

interface ScenariochildResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ScenariochildSubscription["scenariochild"];
}

function createScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenariochild.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenariochild.scenario_ID
                  ? {
                      ...scenario,
                      scenariochild: [...scenario.scenariochild, scenariochild],
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function updateScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenariochild.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenariochild.scenario_ID
                  ? {
                      ...scenario,
                      scenariochild: scenario.scenariochild.map(
                        (scenariochilds) =>
                          scenariochilds.ID === scenariochild.ID
                            ? { ...scenariochilds, ...scenariochild }
                            : scenariochilds
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function deleteScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenariochild.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenariochild.scenario_ID
                  ? {
                      ...scenario,
                      scenariochild: scenario.scenariochild.filter(
                        (scenariochilds) =>
                          scenariochilds.ID !== scenariochild.ID
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function scenariochildResolver({ prev, payload }: ScenariochildResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenariochild({ prev, payload });

    case MutationType.Update:
      return updateScenariochild({ prev, payload });

    case MutationType.Delete:
      return deleteScenariochild({ prev, payload });

    default:
      return prev;
  }
}

export function scenariochildFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenariochild,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenariochildSubscription } }
    ) =>
      scenariochildResolver({
        prev,
        payload: payload.subscriptionData.data.scenariochild,
      }),
    variables: query.variables,
  });
}
