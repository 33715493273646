import { gql } from "@apollo/client";
import { employmentFragment } from "./fragment";

export const employment = gql`
  subscription Employment($where: employmentWhereUniqueInput) {
    employment(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...employment
      }
    }
  }
  ${employmentFragment}
`;

export const employmentMany = gql`
  subscription EmploymentMany($where: employmentWhereInput) {
    employmentMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...employment
      }
    }
  }
  ${employmentFragment}
`;
