import React, { useContext } from "react";

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { LiveData } from "../../../Calculations";
import { StoreContext } from "../../../../../../../Datastore/Store";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  liveData: LiveData[] | undefined;
}

export const InvestmentPropertyDebtLiveModelGraph: React.FC<Props> = ({
  liveData,
}) => {
  const [context] = useContext(StoreContext);

  if (!liveData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={liveData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />
        <XAxis
          interval={liveData.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            dx={-10}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            offset={-30}
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />
        <ReferenceLine y={0} stroke="#000" />

        <Bar
          type="monotone"
          dataKey={"HomeLoanTotal"}
          fill={context.colours.green200}
          stroke={context.colours.green}
          strokeWidth={2}
          stackId={1}
          barSize={40}
          isAnimationActive={false}
          maxBarSize={50}
        />
        <Bar
          type="monotone"
          dataKey={"InvestmentLoanTotal"}
          fill={context.colours.blue200}
          stroke={context.colours.blue}
          strokeWidth={2}
          stackId={1}
          barSize={40}
          isAnimationActive={false}
          maxBarSize={50}
        />
        <Bar
          type="monotone"
          dataKey={"SuperannuationLoanTotal"}
          fill={context.colours.purple100}
          stroke={context.colours.purple300}
          strokeWidth={2}
          stackId={1}
          barSize={40}
          isAnimationActive={false}
          maxBarSize={50}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
