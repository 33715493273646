import { gql } from "@apollo/client";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";

export const otherliabilitiesCoreFragment = gql`
  fragment otherliabilitiesCore on otherliabilitiesCore {
    ID
    liabilities_ID
    Type
    Amount
    Description
    Owner
    Bank
    LoanLimit
    CurrentLoanOutstanding
    Refinance
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const otherliabilitiesFragment = gql`
  fragment otherliabilities on otherliabilities {
    ID
    liabilities_ID
    Type
    Amount
    Description
    Owner
    Bank
    LoanLimit
    CurrentLoanOutstanding
    Refinance
    create_time
    update_time
    created_by
    updated_by

    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${notesFragment}
  ${historyFragment}
`;
