import React, { createContext, useReducer } from "react";
import { rootReducer } from "./Reducers/index";

export const initialState = {
  snackbar: {
    class: "",
    message: "",
    open: false,
  },

  groupFilter: [],
  groups_ID: undefined,

  monthOptions: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  stateOptions: ["QLD", "NSW", "VIC", "TAS", "NT", "WA", "ACT", "SA"],

  projectTeamOptions: [
    "Accounting",
    "Finance",
    "Financial Planning",
    "Insurance",
    "Marketing",
    "Property",
    "Sales Management",
  ],

  staffRoleOptions: [
    "Primary adviser",
    "Alternate adviser",
    "CSO",
    "Insurance admin",
    "Alternate insurance admin",
    "Primary accountant",
    "Alternate accountant",
    "Property adviser",
    "Property associate",
    "Property admin",
    "Finance admin",
    "Alternate finance admin",
    "Loan writer",
    "Mortgageroker",
  ],

  assetOptions: [
    "Annuity",
    "Cash",
    "Collectibles",
    "Offset",
    "Other assets",
    "Property",
    "Shares",
    "Superannuation",
    "Vehicles",
  ],

  liabilityOptions: ["Loans", "Other liabilities"],

  bankOptions: [
    "ANZ Bank",
    "Bank of Queensland",
    "CBA",
    "Macquarie",
    "NAB",
    "St George",
    "Suncorp",
    "Westpac Bank",
  ],

  superannuationFundOptions: [
    "Australian Super",
    "BT Panorama",
    "BT Super Ref",
    "Hesta",
    "Mason Stevens",
    "MLC Navigator",
    "NetWealth",
    "QSuper",
    "Sun Super",
    "UNI",
  ],

  reviewSections: [
    {
      name: "Verify Data",
      moduleSection: 1,
    },
    {
      name: "Set Agenda",
      moduleSection: 2,
    },
    {
      name: "Prepare",
      moduleSection: 3,
    },
    {
      name: "Advice Agreement",
      moduleSection: 4,
    },
    {
      name: "PDF Preview",
      moduleSection: 5,
    },
    {
      name: "Confirmation",
      moduleSection: 6,
    },
  ],

  fonts: {
    primaryFont: "Poppins",
    secondaryFont: "Inter",
  },

  colours: {
    blue: "#5465ff",
    blue200: "#bbc1ff",
    lightPurple300: "#ce6afc",
    lightPurple: "#e7b5fe",
    pink: "#FF729F",
    pink200: "#FFDAE6",
    green: "#11BB70",
    green200: "#a0e4c6",
    green100: "#E7F8F1",
    red: "#FF5A5F",
    red200: "#ffadaf",
    orange: "#FC7A1E",
    orange200: "#FEBD8F",
    yellow: "#EEBA0F",
    yellow200: "#FFF2C5",
    blue100: "#eef0ff",
    purple100: "#E9E7ED",
    purple300: "#BEB8C9",
    purple500: "#9288A6",
    repayments: "#0e965a", // random green
    target: "#7C39FF", // old purple
    contributions: "#77E4AF", // random green
    withdrawals: "#FFCE85", // random orange
  },

  coloursTaxGraphTaxable: {
    blue: "#5465ff",
    green: "#11BB70",
    lightPurple300: "#ce6afc",
    pink: "#FFADAF",
  },

  coloursTaxGraphsGross: {
    blue200: "#bbc1ff",
    green200: "#a0e4c6",
    lightPurple: "#e7b5fe",
    pink200: "#FFDAE6",
  },

  coloursTaxGraphsTax: {
    blue800: "#3b47b3",
    green600: "#0a7043",
    lightPurple800: "#7c4097",
    pink600: "#b33f43",
  },

  propertyColours: [
    "#5465ff", // blue
    "#e7b5fe", // lightpurple
    "#11BB70", // green
    "#FC7A1E", //orange
    "#EEBA0F", // yellow
    "#FFADAF", // pink
    "#FF5A5F", // red
    "#B0D7FF",
    "#9D44B5",
    "#F35B04",
  ],
};

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const store = React.useMemo(() => {
    return [state, dispatch];
  }, [state, dispatch]);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
