import { gql } from "@apollo/client";
import { modelFragment, modelCoreFragment } from "./fragment";

export const model = gql`
  subscription Model($where: modelWhereUniqueInput) {
    model(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...model
      }
      model_has_advice {
        advice_ID
        model_ID
      }
      model_has_assets {
        assets_ID
        model_ID
      }
      model_has_goal {
        goal_ID
        model_ID
      }
      model_has_liabilities {
        liabilities_ID
        model_ID
      }
      model_has_scenariochild {
        scenariochild_ID
        model_ID
      }
      model_has_scenarioclient {
        scenarioclient_ID
        model_ID
      }
      model_has_scenarioinsurance {
        scenarioinsurance_ID
        model_ID
      }
      model_has_scenarioliability {
        scenarioliability_ID
        model_ID
      }
    }
  }
  ${modelFragment}
`;

export const modelMany = gql`
  subscription ModelMany($where: modelWhereInput) {
    modelMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...modelCore
      }
      model_has_advice {
        advice_ID
        model_ID
      }
      model_has_assets {
        assets_ID
        model_ID
      }
      model_has_goal {
        goal_ID
        model_ID
      }
      model_has_liabilities {
        liabilities_ID
        model_ID
      }
      model_has_scenariochild {
        scenariochild_ID
        model_ID
      }
      model_has_scenarioclient {
        scenarioclient_ID
        model_ID
      }
      model_has_scenarioinsurance {
        scenarioinsurance_ID
        model_ID
      }
      model_has_scenarioliability {
        scenarioliability_ID
        model_ID
      }
    }
  }
  ${modelCoreFragment}
`;
