import { QueryResult } from "@apollo/client";
import {
  FindUniqueReviewQuery,
  FindUniqueReviewQueryVariables,
  MutationType,
  BestinterestSubscription,
} from "../../../codegen/schema";
import { bestinterest } from "../subscription";

interface BestinterestResolverParams {
  prev: FindUniqueReviewQuery;
  payload: BestinterestSubscription["bestinterest"];
}

function createBestinterest({ prev, payload }: BestinterestResolverParams) {
  const bestinterest = payload.data;
  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview: {
      ...prev.findUniqueReview,
      bestinterest:
        bestinterest.review_ID === prev.findUniqueReview.ID
          ? [...prev.findUniqueReview.bestinterest, bestinterest]
          : prev.findUniqueReview.bestinterest,
    },
  });
}

function updateBestinterest({ prev, payload }: BestinterestResolverParams) {
  const bestinterest = payload.data;
  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview: {
      ...prev.findUniqueReview,
      bestinterest: prev.findUniqueReview.bestinterest.map((interest) =>
        interest.ID === bestinterest.ID
          ? { ...interest, ...bestinterest }
          : interest
      ),
    },
  });
}

function deleteBestinterest({ prev, payload }: BestinterestResolverParams) {
  const bestinterest = payload.data;
  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview: {
      ...prev.findUniqueReview,
      bestinterest: [...prev.findUniqueReview.bestinterest].filter(
        (interest) => interest.ID !== bestinterest.ID
      ),
    },
  });
}

function bestinterestResolver({ prev, payload }: BestinterestResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createBestinterest({ prev, payload });

    case MutationType.Update:
      return updateBestinterest({ prev, payload });

    case MutationType.Delete:
      return deleteBestinterest({ prev, payload });

    default:
      return prev;
  }
}

export function bestinterestFindUniqueReview(
  query: Pick<
    QueryResult<FindUniqueReviewQuery, FindUniqueReviewQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: bestinterest,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: BestinterestSubscription } }
    ) =>
      bestinterestResolver({
        prev,
        payload: payload.subscriptionData.data.bestinterest
      }),
    variables: query.variables,
  });
}
