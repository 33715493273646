import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";
import { otherliabilitiesCoreFragment } from "../otherliabilities";

export const vehiclesCoreFragment = gql`
  fragment vehiclesCore on vehiclesCore {
    ID
    assets_ID
    Type
    Make
    Model
    Year
    Value
    PurchasePrice
    DateAcquired
    RegistrationNumber
    OwnershipType
    create_time
    created_by
    update_time
    updated_by
  }
`;

/**
 * Loans core fragment has an initialisation issue here.
 * Using explicitly typed loans fields for now.
 */
export const vehiclesFragment = gql`
  fragment vehicles on vehicles {
    ID
    assets_ID
    Type
    Make
    Model
    Year
    Value
    PurchasePrice
    DateAcquired
    RegistrationNumber
    OwnershipType
    create_time
    created_by
    update_time
    updated_by

    assetallocation {
      ...assetallocation
    }

    notes {
      ...notes
    }

    history {
      ...history
    }

    liabilities {
      loans {
        ID
        liabilities_ID
        Bank
        BSB
        AccountNumber
        TaxDeductible
        Purpose
        SecuredBy
        RelatedAsset
        InterestRate
        VariableFixed
        FixedExpiry
        InterestOnlyExpiry
        RepaymentPerMonth
        LoanLimit
        AvailableRedraw
        RepaymentType
        LoanOwnership
        CurrentLoanOutstanding
        LoanType
        LoanStartDate
        LoanExpiry
        Description
        InterestOnlyStartDate
        RateReviewedDate
        Offset
        Position
        Refinance
        create_time
        update_time
        created_by
        updated_by
      }
      otherliabilities {
        ...otherliabilitiesCore
      }
    }
  }
  ${assetallocationFragment}
  ${notesFragment}
  ${historyFragment}
  ${otherliabilitiesCoreFragment}
`;
