import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../Functions/Owner";

interface AssetsCashProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns cash component for advice template && document.
 */
export const AssetsCash: React.FC<AssetsCashProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  type Cash = {
    assets_ID: number | null;
    Bank: string;
    Owner: string;
    Amount: string;
  };

  const summaryCash: Cash[] = group
    ? content?.ContentType === "Grouped"
      ? getGroupAssets(group).cash
      : content?.asset_ID
      ? getGroupAssets(group).cash.find(
          (cash: Cash) => content?.asset_ID === cash.assets_ID
        )
      : {}
    : contenttemplate?.ContentType === "Grouped"
    ? [
        { Bank: "Westpac", Owner: "Joint", Amount: "$20,000" },
        { Bank: "Westpac", Owner: "Joint", Amount: "$50,000" },
      ]
    : { Bank: "Westpac", Owner: "Joint", Amount: "$50,000" };

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryCash.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Bank
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Owner
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Amount
          </StyledText>
        </div>
      ) : (
        <h5>No cash recorded</h5>
      )}
      {summaryCash.map((cash, objectIndex) => {
        return (
          <div
          className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(cash.Bank)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(
                group
                  ? ownerOptionsUpdateAssetDefaultValue(cash, group).text
                  : cash.Owner
              )}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(cash.Amount, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>Individual Cash view</React.Fragment>
  );
};
