import React from "react";
import { ClientIncomeData, LiveData } from "../../../Calculations";
import { GrossNetIncomeLiveModelGraph } from "../Graph";
import dayjs from "dayjs";
import { StyledText } from "../../../../../StyledText";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";

interface GrossNetIncomePlaceholderModelProps {
  theme?: Theme;
}

var data: LiveData[] = [];

[
  dayjs().subtract(5, "year").year(),
  dayjs().subtract(4, "year").year(),
  dayjs().subtract(3, "year").year(),
  dayjs().subtract(2, "year").year(),
  dayjs().subtract(1, "year").year(),
  dayjs().year(),
].forEach((value, index) => {
  var clients: ClientIncomeData[] = [];
  clients.push({
    Name: "John Example",
    GrossIncome:
      index === 0
        ? 100000
        : index === 1
        ? 120000
        : index === 2
        ? 120000
        : index === 3
        ? 130000
        : 130000,
    TaxableIncome:
      index === 0
        ? 86000
        : index === 1
        ? 100000
        : index === 2
        ? 110000
        : index === 3
        ? 110000
        : 110000,
    TaxPaid: 40000,
  });
  clients.push({
    Name: "Jane Example",
    GrossIncome:
    index === 0
      ? 80000
      : index === 1
      ? 82000
      : index === 2
      ? 90000
      : index === 3
      ? 92000
      : 92000,
  TaxableIncome:
    index === 0
      ? 60000
      : index === 1
      ? 62000
      : index === 2
      ? 70000
      : index === 3
      ? 72000
      : 72000,
  TaxPaid:50000,
  });

  clients.forEach((client) => {
    var prevEntry = data.find((entry) => entry.year === value);

    if (prevEntry) {
      data = data.map((entry) => {
        /* For the same year, add each client as an object to the array */
        if (entry.year === value) {
          return {
            ...entry,
            [client.Name + " Gross"]: client.GrossIncome,
            [client.Name + " Taxable"]: client.TaxableIncome,
            [client.Name + " Tax"]: -Math.abs(client.TaxPaid),
          };
        } else return entry;
      });
    } else {
      /* Else if the year doesnt exist, push the new year and client to the array */
      data.push({
        year: value,
        [client.Name + " Gross"]: client.GrossIncome,
        [client.Name + " Taxable"]: client.TaxableIncome,
        [client.Name + " Tax"]: -Math.abs(client.TaxPaid),
      });
    }
  });
});

export const GrossNetIncomePlaceholderModel = ({
  theme,
}: GrossNetIncomePlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center "
        >
          Gross and Net Income
        </StyledText>

        <ModelTooltip type={Model_Type.GrossAndNetIncome} />
      </div>
      <h1
        className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <GrossNetIncomeLiveModelGraph
          liveData={data}
          groupClients={[
            { ID: 0, FirstName: "John", LastName: "Example" },
            { ID: 1, FirstName: "Jane", LastName: "Example" },
          ]}
        />
      </div>
    </React.Fragment>
  );
};
