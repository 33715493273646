import { gql } from "@apollo/client";
import { scenarioassetFragment } from "./fragment";

export const scenarioasset = gql`
  subscription Scenarioasset($where: scenarioassetWhereUniqueInput) {
    scenarioasset(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioasset
      }
      model_has_scenarioasset {
        model_ID
        scenarioasset_ID
      }
    }
  }
  ${scenarioassetFragment}
`;
export const scenarioassetMany = gql`
  subscription ScenarioassetMany($where: scenarioassetWhereInput) {
    scenarioassetMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioasset
      }
      model_has_scenarioasset {
        model_ID
        scenarioasset_ID
      }
    }
  }
  ${scenarioassetFragment}
`;
