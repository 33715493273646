import { gql } from "@apollo/client";

export const reviewagendaCoreFragment = gql`
  fragment reviewagendaCore on reviewagenda {
    ID
    groups_ID
    groups_organisation_ID
    Description
    created_by
    updated_by
    create_time
    update_time
  }
`;

export const reviewagendaFragment = gql`
  fragment reviewagenda on reviewagenda {
    ...reviewagendaCore
  }
  ${reviewagendaCoreFragment}
`;
