import { QueryResult } from "@apollo/client";
import {
  SalarypackagingSubscription,
  FindUniqueSalarypackagingQuery,
  MutationType,
  FindUniqueSalarypackagingQueryVariables,
} from "../../../codegen/schema";
import { salarypackaging } from "../subscription";

interface SalarypackagingResolverParams {
  prev: FindUniqueSalarypackagingQuery;
  payload: SalarypackagingSubscription["salarypackaging"];
}

function updateSalarypackaging({
  prev,
  payload,
}: SalarypackagingResolverParams) {
  const salarypackaging = payload.data;

  if (!prev.findUniqueSalarypackaging) return prev;
  return Object.assign({}, prev, {
    findUniqueSalarypackaging:
      salarypackaging.ID === prev.findUniqueSalarypackaging.ID
        ? {
            ...prev.findUniqueSalarypackaging,
            ...salarypackaging,
          }
        : prev.findUniqueSalarypackaging,
  });
}

function deleteSalarypackaging({
  prev,
  payload,
}: SalarypackagingResolverParams) {
  const salarypackaging = payload.data;

  if (!prev.findUniqueSalarypackaging) return prev;
  return Object.assign({}, prev, {
    findUniqueSalarypackaging:
      prev.findUniqueSalarypackaging.ID === salarypackaging.ID
        ? {
            ...prev.findUniqueSalarypackaging,
            undefined,
          }
        : prev.findUniqueSalarypackaging,
  });
}

function salarypackagingResolver({
  prev,
  payload,
}: SalarypackagingResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createSalarypackaging(prev, payload);

    case MutationType.Update:
      return updateSalarypackaging({ prev, payload });

    case MutationType.Delete:
      return deleteSalarypackaging({ prev, payload });

    default:
      return prev;
  }
}

export function salarypackagingFindUniqueSalarypackaging(
  query: Pick<
    QueryResult<
      FindUniqueSalarypackagingQuery,
      FindUniqueSalarypackagingQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: salarypackaging,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: SalarypackagingSubscription } }
    ) =>
      salarypackagingResolver({
        prev,
        payload: payload.subscriptionData.data.salarypackaging,
      }),
    variables: query.variables,
  });
}
