import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../codegen/schema";

import { GoalGrouped } from "./Grouped";
import { GoalIndividualPlaceholder } from "./Individual/Placeholder";
import { GoalIndividualData } from "./Individual/Data";

interface GoalComponentProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

export const GoalComponent: React.FC<GoalComponentProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  return contenttemplate?.ContentType === "Grouped" ||
    content?.ContentType === "Grouped" ? (
    <React.Fragment>
      <GoalGrouped
        content={content}
        contenttemplate={contenttemplate}
        group={group}
        theme={theme}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      {content ? (
        <GoalIndividualData content={content} group={group} theme={theme} />
      ) : (
        <GoalIndividualPlaceholder theme={theme} />
      )}
    </React.Fragment>
  );
};
