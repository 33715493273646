import { gql } from "@apollo/client";

export const historyFragment = gql`
  fragment history on history {
    ID
    Date
    Value
    created_by
    create_time
    updated_by
    update_time
  }
`;
