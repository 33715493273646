import { bestinterestFindUniqueReview } from "../../bestinterest";
import { furtheradviceFindUniqueReview } from "../../furtheradvice";
import { notesFindUniqueReview } from "../../notes";
import { reviewFindUniqueReview } from "..";
import { advicegoalsFindUniqueReview } from "../../advicegoals";
import {
  FindUniqueReviewQuery,
  FindUniqueReviewQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export function findUniqueReviewSubscriptions(
  query: Pick<
    QueryResult<FindUniqueReviewQuery, FindUniqueReviewQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  advicegoalsFindUniqueReview(query);
  bestinterestFindUniqueReview(query);
  furtheradviceFindUniqueReview(query);
  notesFindUniqueReview(query);
  reviewFindUniqueReview(query);
}
