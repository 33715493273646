import React from "react";
import { StyledText } from "../../../../StyledText";
import { Theme } from "../../../../../../codegen/schema";

interface GoalIndividualPlaceholderProps {
  theme?: Theme;
}

export const GoalIndividualPlaceholder: React.FC<
  GoalIndividualPlaceholderProps
> = ({ theme }) => {

  return (
    <div>
      <StyledText
        element={theme?.element.find((element) => element.Type === "H5")}
        className="flex items-center "
      >
        Goal title
      </StyledText>

      <StyledText
        element={theme?.element.find((element) => element.Type === "P")}
        className="flex items-center "
      >
        Goal description
      </StyledText>
    </div>
  );
};
