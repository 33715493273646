import { ReactText } from "react";
import { Surface, Symbols } from "recharts";
import { Payload as DefaultLegendPayload } from "recharts/types/component/DefaultLegendContent";
import {
  NameType,
  Payload as DefaultTooltipPayload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

export function RechartsCustomReferenceLine({
  x,
  y,
  width,
  height,
  stroke,
  strokeWidth,
}: any) {
  return (
    <line
      x1={x - width * 3}
      y1={y + height - strokeWidth * 2}
      x2={x + width * 4}
      y2={y + height - strokeWidth * 2}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  );
}

export interface RechartsCustomRenderTooltipPayload
  extends DefaultTooltipPayload<ValueType, NameType> {
  dataKey?: string | number | undefined;
}

export interface RechartsCustomRenderTooltipProps {
  active?: boolean | undefined;
  payload?: RechartsCustomRenderTooltipPayload[];
}

export function RechartsCustomRenderTooltip({
  active,
  payload,
}: RechartsCustomRenderTooltipProps) {
  if (active && payload) {
    if (payload[0] !== undefined) {
      return (
        <div className="card" style={{ padding: "1rem 0.5rem" }}>
          <h5>{payload[0].payload.year}</h5>

          {payload.map((entry, index) => {
            var dataKey = entry.dataKey!;

            if (typeof dataKey === "string") {
              if (dataKey !== "SMSFLoanTotal") {
                const regex = /([a-z])([A-Z])/g;
                const modifiedString = dataKey.replace(regex, "$1 $2");
                dataKey =
                  modifiedString.charAt(0).toUpperCase() +
                  modifiedString.slice(1);
              } else {
                dataKey = "SMSF loan total";
              }
            }

            return (
              <div key={index}>
                <div className="flex gap-2 items-center">
                  <h5 className="text-neutral-500 font-size_XS">{dataKey}:</h5>
                  <h5>
                    {parseFloat(entry.value?.toString()!).toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                      }
                    )}
                  </h5>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else return null;
  } else return null;
}

export function RechartsCustomRenderTooltipAdmin({
  active,
  payload,
}: RechartsCustomRenderTooltipProps) {
  if (active && payload) {
    if (payload[0] !== undefined) {
      return (
        <div className="card" style={{ padding: "1rem 0.5rem" }}>
          <h5>{payload[0].payload.year}</h5>

          {payload.map((entry, index) => {
            var dataKeyLowercase =
              entry.dataKey !== "inprogress"
                ? entry
                    .dataKey!.toString()
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                    .toLowerCase()
                : "In progress";

            return (
              <div key={index}>
                <div className="flex gap-2 items-center">
                  <h5 className="text-neutral-500 font-size_XS">
                    {dataKeyLowercase.charAt(0).toUpperCase() +
                      dataKeyLowercase.slice(1)}
                    :
                  </h5>
                  <h5>{entry.value?.toString()}</h5>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else return null;
  } else return null;
}

export interface RechartsCustomRenderLegendPayload
  extends DefaultLegendPayload {
  dataKey?: any;
  payload?: {
    strokeDasharray: string | number;
    dot?: {};
  };
}
export interface RechartsCustomRenderLegendProps {
  payload?: RechartsCustomRenderLegendPayload[];
}
export function RechartsCustomRenderLegend({
  payload,
}: RechartsCustomRenderLegendProps) {
  if (payload) {
    return (
      <div className="flex flex-wrap gap-2 justify-center">
        {payload.map((entry, index) => {
          var dataKey = entry.dataKey!;

          if (dataKey !== "SMSFLoanTotal") {
            const regex = /([a-z])([A-Z])/g;
            const modifiedString = dataKey.replace(regex, "$1 $2");
            dataKey =
              modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1);
          } else {
            dataKey = "SMSF loan total";
          }

          return (
            <div className={"flex items-center "} key={`item-${index}`}>
              <div
                className="flex items-center"
                style={{ width: 15, height: 15 }}
              >
                {entry.dataKey !== "target" ? (
                  <div
                    className={
                      "fa-circle text-2xs " +
                      (entry.payload?.dot ? "fa-regular" : "fa-solid")
                    }
                    style={{ color: entry.color }}
                  />
                ) : (
                  <>
                    {entry.payload?.dot ? (
                      <div
                        className={"fa-regular fa-circle text-2xs "}
                        style={{ color: entry.color }}
                      />
                    ) : (
                      <div
                        className="fa-solid fa-dash text-2xs"
                        style={{ color: entry.color }}
                      />
                    )}
                  </>
                )}
              </div>

              <p className={"text-xs font-medium colour-neutral-900"}>
                {dataKey}
              </p>
            </div>
          );
        })}
      </div>
    );
  } else return null;
}

export interface RechartsCustomRenderLegendPiechartPayload
  extends DefaultLegendPayload {
  payload?: {
    strokeDasharray: ReactText;
    datakey?: string | number;
  };
}
export interface RechartsCustomRenderLegendPiechartProps {
  payload?: RechartsCustomRenderLegendPiechartPayload[];
}
export function RechartsCustomRenderLegendPiechart({
  payload,
}: RechartsCustomRenderLegendPiechartProps) {
  if (payload) {
    return (
      <div className="flex flex-col justify-start">
        {[...payload].map((entry, index) => {
          return (
            <div className={"flex items-center m-2"} key={`item-${index}`}>
              <Surface
                width={15}
                height={15}
                viewBox={{ height: 15, width: 15 }}
              >
                <Symbols
                  cx={6}
                  cy={7}
                  type={"circle"}
                  size={30}
                  fill={entry.color}
                  stroke={entry.color}
                />
              </Surface>

              <div className="flex items-center gap-1 ">
                <p className={"font-medium text-2xs text-neutral-900"}>
                  {(entry.value.charAt(0).toUpperCase() + entry.value.slice(1))
                    .replace(/([A-Z])/g, " $1")
                    .trim()}
                </p>
                <h5 className="text-xs">{entry.payload?.datakey}%</h5>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else return null;
}

export interface RechartsCustomLabelPiechartPayload
  extends DefaultLegendPayload {
  dataKey?: number;
  cx?: number;
  cy?: number;
  midAngle?: number;
  outerRadius?: number;
}
export interface RechartsCustomLabelPiechartProps {
  payload?: RechartsCustomLabelPiechartPayload;
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}
export function RechartsCustomLabelPiechart({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  payload,
}: RechartsCustomLabelPiechartProps) {
  if (payload) {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return percent > 0.05 ? (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontFamily: "Poppins", fontSize: "10pt", fontWeight: "700" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : null;
  } else return null;
}
