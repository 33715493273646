import { QueryResult } from "@apollo/client";
import {
  AdviceSubscription,
  FindUniqueAdviceQuery,
  FindUniqueAdviceQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { advice } from "../subscription";

interface AdviceResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: AdviceSubscription["advice"];
}

function updateAdvice({ prev, payload }: AdviceResolverParams) {
  const advice = payload.data;
  if (!prev.findUniqueAdvice) return prev;
  return Object.assign({}, prev, {
    findUniqueAdvice:
      advice.ID === prev.findUniqueAdvice.ID
        ? {
            ...prev.findUniqueAdvice,
            ...advice,
          }
        : prev.findUniqueAdvice,
  });
}

function deleteAdvice({ prev, payload }: AdviceResolverParams) {
  const advice = payload.data;

  if (!prev.findUniqueAdvice) return prev;
  return Object.assign({}, prev, {
    findUniqueAdvice:
      prev.findUniqueAdvice.ID === advice.ID
        ? {
            ...prev.findUniqueAdvice,
            undefined,
          }
        : prev.findUniqueAdvice,
  });
}

function adviceResolver({ prev, payload }: AdviceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createAdvice(prev, payload);

    case MutationType.Update:
      return updateAdvice({ prev, payload });

    case MutationType.Delete:
      return deleteAdvice({ prev, payload });

    default:
      return prev;
  }
}

export function adviceFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: advice,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceSubscription } }
    ) =>
      adviceResolver({
        prev,
        payload: payload.subscriptionData.data.advice
      }),
    variables: query.variables,
  });
}
