import React, { Fragment } from "react";
import { MultiLevelDropdown } from "../../../Components/Menus/MultiLevelDropdown";
import { NewGroupExisting } from "./ExisitingGroup";
import { NewGroupNew } from "./NewGroup";
import { ButtonProps } from "../../../Components/Buttons";

interface NewGroupsProps {
  buttonProps: ButtonProps;
}

export const NewGroup = ({ buttonProps }: NewGroupsProps) => {
  return (
    <Fragment>
      <MultiLevelDropdown
        id="new-groups-button"
        buttonProps={buttonProps}
        options={[{ text: "Existing group" }, { text: "New group" }].map(
          (value) => ({
            text: value.text,
            options:
              value.text === "Existing group"
                ? ({ setMenuOpen }) => {
                    return <NewGroupExisting setMenuOpen={setMenuOpen} />;
                  }
                : value.text === "New group"
                ? ({ setMenuOpen }) => {
                    return <NewGroupNew setMenuOpen={setMenuOpen} />;
                  }
                : undefined,
          })
        )}
      />
    </Fragment>
  );
};
