import { gql } from "@apollo/client";

export const furtheradviceFragment = gql`
  fragment furtheradvice on furtheradvice {
    ID
    review_ID
    review_groups_ID
    review_groups_organisation_ID
    Type
    Detail
    Who
    Cost
    create_time
    update_time
    created_by
    updated_by
  }
`;
