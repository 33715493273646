import React, { useEffect } from "react";
import { Editor } from "slate";
import {
  FindUniqueDocumenttemplateQuery,
  FindUniqueOrganisationQuery,
  FindUniqueSectiontemplateQuery,
  SortOrder,
  Theme,
  useFindManyPagetemplateQuery,
} from "../../../../../codegen/schema";
import { SlateSelected } from "../../../../../Components/Slate/Types";
import { WebsocketProvider } from "../../../../../Components/Slate/Yjs";
import { findManyPagetemplateSubscription } from "../../../../../API/pagetemplate/subscriptions";
import { PageTemplate } from "./PageTemplate";
import { ComponentLoader } from "../../../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../../../Components/Refetch";

export const DocumenttemplateSection = ({
  documenttemplate,
  theme,
  sectiontemplate,
  activeEditor,
  setActiveEditor,
  selected,
  setSelected,
  yjsProvider,
  organisation,
}: {
  documenttemplate: FindUniqueDocumenttemplateQuery["findUniqueDocumenttemplate"];
  sectiontemplate: FindUniqueSectiontemplateQuery["findUniqueSectiontemplate"] ;
  organisation: FindUniqueOrganisationQuery["findUniqueOrganisation"];
  theme?: Theme;
  activeEditor: Editor | undefined;
  setActiveEditor: React.Dispatch<React.SetStateAction<Editor | undefined>>;
  selected?: SlateSelected;
  setSelected: React.Dispatch<React.SetStateAction<SlateSelected | undefined>>;
  yjsProvider: WebsocketProvider;
}) => {
  const {
    data: { findManyPagetemplate: pagetemplates } = {},
    error: pageError,
    loading: pageLoading,
    refetch: pageRefetch,
    ...pageResult
  } = useFindManyPagetemplateQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        sectiontemplate_ID: { equals: sectiontemplate?.ID },
      },
      orderBy: { Position: SortOrder.Asc },
    },
  });

  useEffect(() => {
    findManyPagetemplateSubscription(pageResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!pagetemplates && pageLoading) {
    return <ComponentLoader />;
  }

  if (pageError && !pageLoading) {
    return (
      <Refetch
        refetch={() => {
          pageRefetch();
        }}
      />
    );
  }

  if (!documenttemplate || !pagetemplates) return null;

  return (
    <React.Fragment>
      {[...pagetemplates].map((pagetemplate) => {
        return (
          <React.Fragment key={pagetemplate.ID}>
              <PageTemplate
                organisation={organisation}
                documenttemplate={documenttemplate}
                sectiontemplate={sectiontemplate}
                theme={theme}
                pagetemplate_ID={pagetemplate.ID}
                activeEditor={activeEditor}
                setActiveEditor={setActiveEditor}
                selected={selected}
                setSelected={setSelected}
                yjsProvider={yjsProvider}
              />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
