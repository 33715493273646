import { QueryResult } from "@apollo/client";
import {
  FindManyChildQuery,
  FindManyChildQueryVariables,
} from "../../../codegen/schema";
import { FindManyChild } from "../reducers";

export function findManyChildSubscription(
  query: Pick<
    QueryResult<FindManyChildQuery, FindManyChildQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindManyChild(query);
}
