import { gql } from "@apollo/client";
import { childCoreFragment, childFragment } from "./fragment";

export const findUniqueChild = gql`
  query FindUniqueChild($where: childWhereUniqueInput!) {
    findUniqueChild(where: $where) {
      ...child
    }
  }
  ${childFragment}
`;

export const findManyChild = gql`
  query FindManyChild(
    $where: childWhereInput
    $distinct: [ChildScalarFieldEnum!]
    $orderBy: [childOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: childWhereUniqueInput
  ) {
    findManyChild(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...childCore
    }
  }
  ${childCoreFragment}
`;
