export const groupFilter = (state, action) => {
  switch (action.type) {
    case "set/groupFilter":
      return {
        ...state,
        groupFilter: action.payload,
      };

    default:
      return state;
  }
};

export const groups_ID = (state, action) => {
  switch (action.type) {
    case "set/groups_ID":
      return {
        ...state,
        groups_ID: action.payload,
      };

    default:
      return state;
  }
};
