import { gql } from "@apollo/client";

export const notesFragment = gql`
  fragment notes on notes {
    ID
    Type
    Description
    Note
    LastEdited
    Category
    create_time
    created_by
    updated_by
    update_time
  }
`;
