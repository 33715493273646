import { gql } from "@apollo/client";
import { reviewagendaFragment } from "./fragment";

export const reviewagenda = gql`
  subscription Reviewagenda($where: reviewagendaWhereUniqueInput) {
    reviewagenda(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...reviewagenda
      }
    }
  }
  ${reviewagendaFragment}
`;

export const reviewagendaMany = gql`
  subscription ReviewagendaMany($where: reviewagendaWhereInput) {
    reviewagendaMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...reviewagenda
      }
    }
  }
  ${reviewagendaFragment}
`;

