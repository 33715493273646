// Import React dependencies.
import { YjsEditor } from "@slate-yjs/core";
import React from "react";
// Import the Slate editor factory.
import {
  Editor,
  Transforms,
  Element as SlateElement,
  Descendant,
  Location,
} from "slate";

// Import the Slate components and React plugin.
import { ReactEditor, RenderElementProps, RenderLeafProps } from "slate-react";
import {
  Element,
  FindUniqueAdviceQuery,
  FindUniqueGroupQuery,
  Theme,
} from "../../../codegen/schema";
import { StyledText } from "../../Advice/StyledText";

import {
  CustomElement,
  CustomText,
  MentionElement,
  TEXT_ALIGN_TYPES,
  TextElement,
} from "./types";
import { Mention } from "./mentions";
import { LinkComponent } from "./links";

export const CustomEditor = {
  /** HEADER STYLE */
  setHeaderStyle({ editor, element }: { editor: Editor; element: Element }) {
    let newProperties = {
      type: element.Type,
      letterSpacing: element.LetterSpacing,
    };

    /* Set new nodes */
    Transforms.setNodes<TextElement>(editor, newProperties);

    /* Reset children nodes to inherit (removes custom styling) */
    Editor.addMark(editor, "fontFamily", element.FontFamily);
    Editor.addMark(editor, "fontSize", element.FontSize);
    Editor.addMark(editor, "color", element.Colour);
    Editor.addMark(editor, "fontWeight", element.FontWeight);
    Editor.addMark(editor, "letterSpacing", element.LetterSpacing);
  },

  /** TOGGLE MARK */
  /** @description Toggle custom formatting marks */
  toggleMark({
    editor,
    format,
    value,
  }: {
    editor: Editor;
    format: keyof Omit<CustomText, "text">;
    value: string | number | boolean;
  }) {
    const isActive = CustomEditor.isMarkActive({ editor, format: format });

    /* If mark is active and format is bold, italic or underline remove the mark */
    if (
      isActive &&
      (format === "bold" || format === "italic" || format === "underline")
    ) {
      /* If format is bold, reset fontWeight mark also */
      if (format === "bold") {
        Editor.addMark(editor, "fontWeight", 400);
      }
      Editor.removeMark(editor, format);

      /* Else mark is NOT active */
    } else {
      /* if format is fontWeight and value is 700 || 800 (bold) add bold mark */
      if (format === "fontWeight" && (value === 700 || value === 800)) {
        Editor.addMark(editor, "bold", true);

        /* if format is fontWeight and value is anything but 700 && 800, remove the bold mark */
      } else if (format === "fontWeight") {
        Editor.removeMark(editor, "bold");
      } else if (format === "bold") {
        Editor.addMark(editor, "fontWeight", 800);
      }
      Editor.addMark(editor, format, value);
    }
  },

  /** @description Check if mark is active using provided format */
  isMarkActive({
    editor,
    format,
  }: {
    editor: Editor;
    format: keyof Omit<CustomText, "text">;
  }) {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  },

  /** TOGGLE BLOCK */
  /** @description Toggle element block, used for headings, text align and columns */
  toggleBlock({ editor, format }: { editor: Editor; format: string }) {
    /* Check if formatted block is active, if it is need to reset block to P. If not, set block type to format */
    const isActive = CustomEditor.isBlockActive({
      editor,
      format,
      blockType: TEXT_ALIGN_TYPES.includes(format) ? "align" : "type",
    });

    /* Unwrap all editor nodes */
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });

    let newProperties: Partial<SlateElement>;

    /* Assign values to newProperties slate element depending on format value */
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else if (format === "columns") {
      newProperties = {
        columns: isActive ? undefined : 2,
      };
    } else {
      newProperties = {
        type: isActive ? "P" : format,
      };
    }

    /* Set new nodes */
    Transforms.setNodes<SlateElement>(editor, newProperties);

    // RESELECT EDITOR
    if (editor.selection) {
      CustomEditor.reSelect({ editor, target: editor.selection });
    }
  },

  /** @description Check is block is active using provided format and block type. */
  /* i.e, format = "center" && blockType: align OR format = "ul" && blockType = "type" */
  isBlockActive({
    editor,
    format,
    blockType,
  }: {
    editor: Editor;
    format: string;
    blockType: "type" | "align" | "columns";
  }) {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => {
          return (
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.align === format
          );
        },
      })
    );

    return !!match;
  },

  /** LINE HEIGHT */
  setLineHeight({ editor, value }: { editor: Editor; value: number }) {
    Transforms.setNodes(
      editor,
      { lineHeight: parseFloat(value.toString()) },
      { split: false }
      // match: (n) => Editor.isBlock(editor, n),
    );
  },

  /**
   * @description Used for updating the content of the editor from external changes,
   * primarily subscriptions and websocket changes.
   */
  externalUpdate({
    editor,
    newValue,
  }: {
    editor: Editor;
    newValue: Descendant[];
  }): void {
    editor.children = newValue;
    editor.onChange();
  },

  /**
   * @description Used for re-selecting text within the content when interacting with
   * inputs in the tool bar. Selects work fine but this will be universal as it is
   * called in onBlur on the <Editable /> component.
   */
  reSelect({ editor, target }: { editor: Editor; target: Location }): void {
    Transforms.select(editor, target);
    if (!CustomEditor.checkElementType(editor, "mention")) {
      ReactEditor.focus(editor);
    }
  },

  checkElementType(editor: Editor, type: string) {
    const [match] = Array.from(
      Editor.nodes(editor, {
        match: (n) => SlateElement.isElementType(n, type),
      })
    );

    return !!match;
  },
};

export interface CustomRenderElementProps extends RenderElementProps {
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const RenderElement = (props: CustomRenderElementProps) => {
  const { attributes, children, element } = props;

  const themeElement = props.theme?.element.find(
    (element) => element.Type === props.element.type
  );

  switch (element.type) {
    case "mention":
      return (
        <Mention
          {...{ ...props, element: element as MentionElement }}
          group={props.group}
          advice={props.advice}
        />
      );
    case "ul":
      const ulElement = element as TextElement;
      return (
        <ul
          className="list-disc pr-6"
          style={{
            lineHeight: ulElement.lineHeight ? ulElement.lineHeight : 1.5,
            margin: 0,
            padding: "0 0 0 20px",
          }}
          {...attributes}
        >
          {children}
        </ul>
      );
    case "ol":
      const olElement = element as TextElement;
      return (
        <ol
          className="list-decimal pr-6"
          style={{
            lineHeight: olElement.lineHeight ? olElement.lineHeight : 1.5,
            margin: 0,
            padding: "0 0 0 20px",
          }}
          {...attributes}
        >
          {children}
        </ol>
      );
    case "list-item":
      const listItemElement = element as TextElement;
      return (
        <li
          style={{
            lineHeight: listItemElement.lineHeight
              ? listItemElement.lineHeight
              : 1.5,
            margin: 0,
          }}
          {...attributes}
        >
          {children}
        </li>
      );
    case "list-item-text":
      const listItemTextElement = element as TextElement;
      return (
        <div
          style={{
            lineHeight: listItemTextElement.lineHeight
              ? listItemTextElement.lineHeight
              : 1.5,
            margin: 0,
          }}
          {...attributes}
        >
          {children}
        </div>
      );
    case "numbered-list":
    case "bulleted-list":
      return <div {...props.attributes}>{props.children}</div>;
    case "link":
      return <LinkComponent {...props} />;
    default:
      const defaultElement = element as TextElement;
      return (
        <StyledText
          {...props.attributes}
          element={themeElement}
          style={{
            margin: 0,
            textAlign:
              (defaultElement.align as
                | "start"
                | "end"
                | "left"
                | "right"
                | "center"
                | "justify"
                | "initial"
                | "inherit"
                | "match-parent") || themeElement?.Alignment,
            columnCount: defaultElement.columns,
            lineHeight: defaultElement.lineHeight
              ? defaultElement.lineHeight
              : 1.5,
          }}
        >
          {children}
        </StyledText>
      );
  }
};

export const Leaf = (props: RenderLeafProps) => {
  return (
    <span
      {...props.attributes}
      style={{
        fontFamily: props.leaf.fontFamily ?? "inherit",
        fontSize: props.leaf.fontSize + "pt" ?? "inherit",
        fontWeight: props.leaf.bold
          ? 700
          : props.leaf.fontWeight
          ? props.leaf.fontWeight
          : "inherit",
        color: props.leaf.color ?? "inherit",
        backgroundColor: props.leaf.backgroundColor ?? "transparent",
        lineHeight: "inherit",
        letterSpacing: props.leaf.letterSpacing + "px" ?? "inherit",
        fontStyle: props.leaf.italic ? "italic" : "normal",
        textDecoration: props.leaf.underline ? "underline" : "none",
      }}
    >
      {props.children}
    </span>
  );
};

/**
 * @description Used for normalizing the Slate editor, specifically for
 * yjs binding. Ensures that editor always has at least one child during
 * updates where the document may be empty.
 */
export function withNormalize(
  editor: Editor & YjsEditor,
  slateInitialValue: CustomElement
): Editor & YjsEditor {
  // Ensure editor always has at least 1 valid child
  const { normalizeNode } = editor;
  editor.normalizeNode = (entry) => {
    const [node] = entry;

    if (!Editor.isEditor(node) || node.children.length > 0) {
      return normalizeNode(entry);
    }
  };

  return editor;
}
