import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../../Datastore/Store";
import {
  useCreateOneClientsMutation,
  useFindCurrentUserQuery,
  useFindManyGroupsQuery,
} from "../../../../codegen/schema";
import { ComponentLoader } from "../../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../../Components/Refetch";
import { Button } from "../../../../Components/Buttons";
import {
  Combobox,
  ComboboxOptionType,
} from "../../../../Components/Inputs/Combobox";
import { Input } from "../../../../Components/Inputs/Input";

export const NewGroupExisting = ({
  setMenuOpen,
}: {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const [, dispatch] = useContext(StoreContext);
  const [group, setGroup] = useState<
    | {
        ID: number;
        GroupName: string;
      }
    | undefined
  >(undefined);
  const [clients, setClients] = useState([
    {
      FirstName: "",
      LastName: "",
    },
  ]);

  const {
    data: { findCurrentUser: user } = {},
    error: userError,
    loading: userLoading,
    refetch: userRefetch,
  } = useFindCurrentUserQuery();

  const {
    data: { findManyGroups: allGroups } = {},
    error: allGroupsError,
    loading: allGroupsLoading,
    refetch: allGroupsRefetch,
  } = useFindManyGroupsQuery();

  const [createOneClientMutation] = useCreateOneClientsMutation();

  async function handleSubmit(event: MouseEvent) {
    event.preventDefault();

    /** If there is not a GroupName that already exists OR group not set */
    if (!group) {
      dispatch({
        type: "snackbar/error",
        payload: "Please select a group.",
      });
      return;
    }

    /** If no clientData */
    if (clients.length < 1) {
      dispatch({
        type: "snackbar/error",
        payload: "To add a new group, please add at least 1 client.",
      });
      return;
    }

    /** If haven't filled out first or last name */
    if (
      clients.some(
        (client) => client.FirstName === "" || client.LastName === ""
      )
    ) {
      dispatch({
        type: "snackbar/error",
        payload: "Provide a First and Last Name for each client.",
      });
      return;
    }

    Promise.all(
      clients.map((client) =>
        createOneClientMutation({
          variables: {
            data: {
              groups: {
                connect: {
                  ID: group.ID,
                },
              },
              FirstName: client.FirstName,
              LastName: client.LastName,
              created_by: user?.staff_ID,
              Status: "Active",
              estateplanning: { create: [{}] },
              interest: { create: [{}] },
            },
          },
        })
      )
    )
      .then((_results) => {
        // Succesful
        dispatch({
          type: "snackbar/success",
          payload: `${
            clients.length > 1
              ? clients.length + " clients"
              : clients.length + " client"
          } added successfully`,
        });

        // Close popover
        setMenuOpen(false);

        navigate(`/group/ID/${group.ID}`);
      })
      .catch((error) =>
        dispatch({ type: "snackbar/error", payload: error.message })
      );
  }

  if ((!user && userLoading) || (!allGroups && allGroupsLoading)) {
    return <ComponentLoader />;
  }

  if ((userError && !userLoading) || (allGroupsError && !allGroupsLoading)) {
    return (
      <Refetch
        refetch={() => {
          allGroupsRefetch();
          userRefetch();
        }}
      />
    );
  }

  if (!user || !allGroups) return null;

  return (
    <Fragment>
      <div className="flex flex-col gap-2 relative justify-center p-4 w-80">
        <h5>Select a group to add clients to: </h5>
        <div>
          <Combobox
            options={allGroups.map((group) => ({
              id: group.ID,
              value: group.GroupName,
            }))}
            placeholder="Select a group"
            mutation={(value: ComboboxOptionType) =>
              value
                ? setGroup({
                    ID: value.id,
                    GroupName: value.value,
                  })
                : setGroup(undefined)
            }
            defaultValue={undefined}
          />
        </div>

        <div className="flex gap-2 justify-between items-center">
          <h5> Add clients </h5>
          <Button
            tooltip="Add more clients to group"
            type="circle"
            functionality="add"
            onClick={() =>
              setClients([...clients, { FirstName: "", LastName: "" }])
            }
          />
        </div>
        <div className="grid grid-cols-2 gap-y-4 gap-x-2 items-center">
          {clients.map((client, index) => (
            <React.Fragment key={"client" + index}>
              <Input
                label="First name"
                name="FirstName"
                defaultValue={client?.FirstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClients(
                    clients.map((clientMap, mapIndex) =>
                      mapIndex === index
                        ? { ...clientMap, FirstName: event?.target.value }
                        : clientMap
                    )
                  )
                }
                required
              />

              <Input
                label="Last name"
                name="LastName"
                defaultValue={client?.LastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClients(
                    clients.map((clientMap, mapIndex) =>
                      mapIndex === index
                        ? { ...clientMap, LastName: event?.target.value }
                        : clientMap
                    )
                  )
                }
                required
              />
            </React.Fragment>
          ))}
        </div>
        <div className="w-full">
          <Button
            type="default"
            text="Add client"
            onClick={(event: MouseEvent) => handleSubmit(event)}
            disabled={clients.some(
              (client) => client.FirstName === "" || client.LastName === ""
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};
