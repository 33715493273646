import { QueryResult } from "@apollo/client";
import {
  ReviewSubscription,
  FindUniqueReviewQuery,
  MutationType,
  FindUniqueReviewQueryVariables,
} from "../../../codegen/schema";
import { review } from "../subscription";

interface ReviewResolverParams {
  prev: FindUniqueReviewQuery;
  payload: ReviewSubscription["review"];
}

function updateReview({ prev, payload }: ReviewResolverParams) {
  const review = payload.data;

  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview:
      review.ID === prev.findUniqueReview.ID
        ? {
            ...prev.findUniqueReview,
            ...review,
          }
        : prev.findUniqueReview,
  });
}

function reviewResolver({ prev, payload }: ReviewResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createReview(prev, payload);

    case MutationType.Update:
      return updateReview({ prev, payload });

    // case MutationType.Delete:
    //   return deleteReview({ prev, payload });

    default:
      return prev;
  }
}

export function reviewFindUniqueReview(
  query: Pick<
    QueryResult<FindUniqueReviewQuery, FindUniqueReviewQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: review,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ReviewSubscription } }
    ) =>
      reviewResolver({
        prev,
        payload: payload.subscriptionData.data.review,
      }),
    variables: query.variables,
  });
}
