import {
  FindCurrentStaffQuery,
  Staff,
} from "../../../../codegen/schema";

export function addAlpha(hexColor: string, opacity: number): string {
  const normalized = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
  return hexColor + normalized.toString(16).toUpperCase();
}

interface CursorDataProps {
  staff: Staff | FindCurrentStaffQuery["findCurrentStaff"];
}

export function cursorData({ staff }: CursorDataProps) {
  return {
    color: "#fcba03",
    name: `${staff?.FirstName} ${staff?.LastName}`,
  };
}
