import dayjs from "dayjs";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";
import { FindUniqueModelQuery } from "../../../../../codegen/schema";

export function RetirementPlanningModelDescription() {
  return (
    <p className="text-2xs italic">
      The retirement planning model displays the growth of superannuation
      accounts over time. If a client is added to the model that has income from
      one of the modelled superannuation accounts, the drawdown and minimum
      pension amounts will be deducted from the projected value, displaying
      drawdown over time.
    </p>
  );
}

export function RetirementPlanningStats({
  model,
}: {
  model: FindUniqueModelQuery["findUniqueModel"];
}) {
  return (
    <div className="flex gap text-2xs">
      <p>
        <b>Years to retirement</b> <br />
        {checkFieldIsEmpty(
          dayjs(model?.EndDate).fromNow(),
          "number"
        )}
      </p>
      <p>
        <b>Capital at retirement</b> <br />
        {checkFieldIsEmpty(model?.Target, "dollar")}
      </p>
    </div>
  );
}

export function RetirementPlanningCalculationTooltip() {
  return (
    <>
      <p>
        {" "}
        <b>X axis </b> <br />
        this graph starts with the model start date year and ends with the model
        end date year.
      </p>

      {/* <p>
        {" "}
        <b>Projected Value </b> <br />
        Text
      </p> */}
      <p>
        {" "}
        <b>Target</b> <br />
        The target for each scenario is displayed at the earliest retirement year to show capital at retirement.
      </p>
      {/* <p>
        {" "}
        <b>Growth</b> <br />
        Growth
      </p>
      <p>
        {" "}
        <b>Concessional Contributions</b> <br />
        Calculation
      </p>
      <p>
        {" "}
        <b>Non-Concessional Contributions</b> <br />
        Calculation
      </p>
      <p>
        {" "}
        <b>Contributions tax</b> <br />
        Calculation
      </p>
      <p>
        {" "}
        <b>Minimum pension</b> <br />
        Calculation
      </p>
      <p>
        {" "}
        <b>Income from super</b> <br />
        Calculation
      </p> */}
    </>
  );
}
