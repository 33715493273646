export function PropertyValueModelDescription() {
  return (
    <p className="text-2xs italic">
      The property value model displays the net equity of a property over time
      based on loan repayments, offset accounts, extra repayments, and property
      growth.
    </p>
  );
}

export function PropertyValueModelCalculationTooltip() {
  return (
    <>
      <p>
        <i className="fa-regular fa-circle colour-blue" />
        <b>Projected value</b>
        <br />
        <p>
          The projected property value will start as the sum of the scenario
          properties attached to the model. For each year following, the
          projected value increases based on each property's growth rate.
        </p>
      </p>

      <p>
        <i className="fa-regular fa-circle colour-success" />
        <b>Net equity</b>
        <br />
        <p>
          Net equity is the difference between the projected property value and
          the loans total value. This will change year on year as the property
          value increases and loans decrease.
        </p>
      </p>
      <p>
        <i className="fa-regular fa-circle colour-lightpurple-300" />
        <b>Live Properties</b>
        <br />
        <p>
          If a scenario property is created based on a live property and the
          property has history saved this value will populate. Each live
          property can have historical values saved. If the values do not look
          correct, edit the historical values in verify data or on the group
          page.
          <b className="font-weight_semi-bold">
            {" "}
            NOTE: Historical values generated by MOAS will not be editable.
          </b>
        </p>
      </p>
      <p>
        <i className="fa-regular fa-circle colour-warning" />
        <b>Live Loans</b>
        <br />
        <p>
          If the scenario property is based on a live property, the loans
          secured to the property will populate the Live Loans value. Live loans
          uses historical loan values and can be edited like properties, in
          verify data or on the group page.
        </p>
      </p>
      <p>
        <i className="fa-solid fa-circle " style={{ color: "#77E4AF" }} />
        <b>Contributions</b>
        <br />
        <p>
          Contributons are added either year on year (if an ongoing contribution
          is added) or as a one off based on the date set in the contributions
          section. Contributions are indexd based on the indexation rate set.
        </p>
      </p>
      <p>
        <i className="fa-solid fa-circle " style={{ color: "#FFCE85" }} />
        <b>Withdrawals</b>
        <br />
        <p>
          Withdrawls are either year on year (if an ongoing withdrawal is added)
          or as a one off based on the date set in the withdrawals section.
          Withdrawals are indexd based on the indexation rate set.
        </p>
      </p>
      {/* <p>
        <i className="fa-solid fa-circle " style={{ color: "#0e965a" }} />
        <b>Repayments</b>
        <br />
      </p>
      <p>
        <i className="fa-solid fa-circle colour-blue-200" />
        <b>Loans</b>
        <br />
      </p>
      <p>
        <i className="fa-solid fa-circle colour-blue" />
        <b>Interest</b>
        <br />
      </p> */}

      <p>
        <i className="fa-regular fa-circle" style={{ color: "#7C39FF" }} />
        <b>Target </b> <br />
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#target"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for target breakdown.
        </a>
      </p>
    </>
  );
}
