import { CPI } from "../../../../../../../Constants/Calculations";
import {
  Scenario,
  Scenarioasset,
} from "../../../../../../../codegen/schema";

interface ScenarioassetPropertyProjectionByYearProps {
  scenario: Scenario;
  scenarioasset: Scenarioasset;
  /** How many years from now to run the projection for. */
  year: number;
}

interface ScenarioassetPropertyProjectionByYearReturn {
  annualCost: number;
  annualRent: number;
  value: number;
}

/**
 * Returns the value, rental, and costs associated with a property based on n years from now.
 */
export function ScenarioassetPropertyProjectionByYear({
  scenario,
  scenarioasset,
  year,
}: ScenarioassetPropertyProjectionByYearProps): ScenarioassetPropertyProjectionByYearReturn {
  var annualCost = scenarioasset.OngoingCosts * Math.pow(1 + CPI, year);

  var value = scenarioasset.Value * Math.pow(1 + CPI, year);

  var annualRent =
    value * (scenarioasset.RentalYield / 100) * Math.pow(1 + CPI, year);

  return {
    annualCost,
    annualRent,
    value,
  };
}
