import { QueryResult } from "@apollo/client";
import {
  LoansSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { loans } from "../subscription";

interface LoansResolverParams {
  prev: FindUniqueGroupQuery;
  payload: LoansSubscription["loans"];
}

function createLoans({ prev, payload }: LoansResolverParams) {
  const { data: createLoans, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          liabilities: {
            ...client.liabilities,
            loans: data.clients_has_liabilities.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.liabilities.loans, createLoans]
              : client.liabilities.loans,
          },
          assets: {
            ...client.assets,
            offset: client.assets.offset.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [...asset.liabilities.loans, createLoans],
                    },
                  }
                : asset
            ),
            property: client.assets.property.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [...asset.liabilities.loans, createLoans],
                    },
                  }
                : asset
            ),
            vehicles: client.assets.vehicles.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [...asset.liabilities.loans, createLoans],
                    },
                  }
                : asset
            ),
          },
          goals: client.goals.map((goal) => {
            return {
              ...goal,
              /**
               * No liabilities reducer here because not sure if goal has this liability until
               * after it gets added to the goal.
               * Assets yes because the liability may be created and related to an asset.
               * This is not the case when adding an asset because the relation to the asset
               * is held by the liability. E.g. loan.SecuredBy.
               */
              assets: {
                ...goal.assets,
                offset: goal.assets.offset.map((asset) =>
                  data.assets_has_liabilities.some(
                    (relation) => relation.assets_ID === asset.assets_ID
                  )
                    ? {
                        ...asset,
                        liabilities: {
                          ...asset.liabilities,
                          loans: [...asset.liabilities.loans, createLoans],
                        },
                      }
                    : asset
                ),
                property: goal.assets.property.map((asset) =>
                  data.assets_has_liabilities.some(
                    (relation) => relation.assets_ID === asset.assets_ID
                  )
                    ? {
                        ...asset,
                        liabilities: {
                          ...asset.liabilities,
                          loans: [...asset.liabilities.loans, createLoans],
                        },
                      }
                    : asset
                ),
                vehicles: goal.assets.vehicles.map((asset) =>
                  data.assets_has_liabilities.some(
                    (relation) => relation.assets_ID === asset.assets_ID
                  )
                    ? {
                        ...asset,
                        liabilities: {
                          ...asset.liabilities,
                          loans: [...asset.liabilities.loans, createLoans],
                        },
                      }
                    : asset
                ),
              },
            };
          }),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          liabilities: {
            ...entity.liabilities,
            loans: data.entities_has_liabilities.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.liabilities.loans, createLoans]
              : entity.liabilities.loans,
          },
          assets: {
            ...entity.assets,
            offset: entity.assets.offset.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [...asset.liabilities.loans, createLoans],
                    },
                  }
                : asset
            ),
            property: entity.assets.property.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [...asset.liabilities.loans, createLoans],
                    },
                  }
                : asset
            ),
            vehicles: entity.assets.vehicles.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [...asset.liabilities.loans, createLoans],
                    },
                  }
                : asset
            ),
          },
        };
      }),
    },
  });
}

function updateLoans({ prev, payload }: LoansResolverParams) {
  const { data: updateLoans, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update loans first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        liabilities: {
          ...client.liabilities,
          loans: [
            ...client.liabilities.loans
              .map(
                (loan) =>
                  loan.ID === updateLoans.ID
                    ? /** Update loan */
                      {
                        ...loan,
                        ...updateLoans,
                      }
                    : loan /** Else return loan */
              )
              .filter(
                (loan) =>
                  ![...data.clients_has_liabilities]
                    .map((entry) => entry.liabilities_ID)
                    .includes(loan.liabilities_ID)
              ),
            ...(data.clients_has_liabilities.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? data.clients_has_liabilities.reduce<
                  Array<LoansSubscription["loans"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.liabilities_ID === updateLoans.liabilities_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updateLoans];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        /** Update assets based on assets_has_liabilities relations */
        assets: {
          ...client.assets,
          offset: client.assets.offset.map((asset) =>
            data.assets_has_liabilities.some(
              (relation) => relation.assets_ID === asset.assets_ID
            )
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: [
                      ...asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan info */
                                  ...updateLoans,
                                }
                              : loan /** Else return loan */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                      ...(data.assets_has_liabilities.some(
                        (relation) => relation.assets_ID === asset.assets_ID
                      )
                        ? data.assets_has_liabilities.reduce<
                            Array<LoansSubscription["loans"]["data"]>
                          >((relationResult, relation) => {
                            if (
                              relation.liabilities_ID ===
                                updateLoans.liabilities_ID &&
                              relation.assets_ID === asset.assets_ID
                            ) {
                              relationResult = [...relationResult, updateLoans];
                            }
                            return relationResult;
                          }, [])
                        : []),
                    ],
                  },
                }
              : asset.liabilities
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: asset.liabilities.loans
                      .map(
                        (loan) =>
                          loan.ID === updateLoans.ID
                            ? {
                                ...loan,
                                /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                ...updateLoans,
                              }
                            : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                      )
                      .filter(
                        /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                        (loan) =>
                          ![...data.assets_has_liabilities]
                            .map((entry) => entry.liabilities_ID)
                            .includes(loan.liabilities_ID)
                      ),
                  },
                }
              : asset
          ),
          property: client.assets.property.map((asset) =>
            data.assets_has_liabilities.some(
              (relation) => relation.assets_ID === asset.assets_ID
            )
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: [
                      ...asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan info */
                                  ...updateLoans,
                                }
                              : loan /** Else return loan */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                      ...(data.assets_has_liabilities.some(
                        (relation) => relation.assets_ID === asset.assets_ID
                      )
                        ? data.assets_has_liabilities.reduce<
                            Array<LoansSubscription["loans"]["data"]>
                          >((relationResult, relation) => {
                            if (
                              relation.liabilities_ID ===
                                updateLoans.liabilities_ID &&
                              relation.assets_ID === asset.assets_ID
                            ) {
                              relationResult = [...relationResult, updateLoans];
                            }
                            return relationResult;
                          }, [])
                        : []),
                    ],
                  },
                }
              : asset.liabilities
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: asset.liabilities.loans
                      .map(
                        (loan) =>
                          loan.ID === updateLoans.ID
                            ? {
                                ...loan,
                                /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                ...updateLoans,
                              }
                            : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                      )
                      .filter(
                        /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                        (loan) =>
                          ![...data.assets_has_liabilities]
                            .map((entry) => entry.liabilities_ID)
                            .includes(loan.liabilities_ID)
                      ),
                  },
                }
              : asset
          ),
          vehicles: client.assets.vehicles.map((asset) =>
            data.assets_has_liabilities.some(
              (relation) => relation.assets_ID === asset.assets_ID
            )
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: [
                      ...asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan info */
                                  ...updateLoans,
                                }
                              : loan /** Else return loan */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                      ...(data.assets_has_liabilities.some(
                        (relation) => relation.assets_ID === asset.assets_ID
                      )
                        ? data.assets_has_liabilities.reduce<
                            Array<LoansSubscription["loans"]["data"]>
                          >((relationResult, relation) => {
                            if (
                              relation.liabilities_ID ===
                                updateLoans.liabilities_ID &&
                              relation.assets_ID === asset.assets_ID
                            ) {
                              relationResult = [...relationResult, updateLoans];
                            }
                            return relationResult;
                          }, [])
                        : []),
                    ],
                  },
                }
              : asset.liabilities
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: asset.liabilities.loans
                      .map(
                        (loan) =>
                          loan.ID === updateLoans.ID
                            ? {
                                ...loan,
                                /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                ...updateLoans,
                              }
                            : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                      )
                      .filter(
                        /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                        (loan) =>
                          ![...data.assets_has_liabilities]
                            .map((entry) => entry.liabilities_ID)
                            .includes(loan.liabilities_ID)
                      ),
                  },
                }
              : asset
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            offset: goal.assets.offset.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [
                        ...asset.liabilities.loans
                          .map(
                            (loan) =>
                              loan.ID === updateLoans.ID
                                ? {
                                    ...loan,
                                    /** Update loan info */
                                    ...updateLoans,
                                  }
                                : loan /** Else return loan */
                          )
                          .filter(
                            /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                            (loan) =>
                              ![...data.assets_has_liabilities]
                                .map((entry) => entry.liabilities_ID)
                                .includes(loan.liabilities_ID)
                          ),
                        ...(data.assets_has_liabilities.some(
                          (relation) => relation.assets_ID === asset.assets_ID
                        )
                          ? data.assets_has_liabilities.reduce<
                              Array<LoansSubscription["loans"]["data"]>
                            >((relationResult, relation) => {
                              if (
                                relation.liabilities_ID ===
                                  updateLoans.liabilities_ID &&
                                relation.assets_ID === asset.assets_ID
                              ) {
                                relationResult = [
                                  ...relationResult,
                                  updateLoans,
                                ];
                              }
                              return relationResult;
                            }, [])
                          : []),
                      ],
                    },
                  }
                : asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                  ...updateLoans,
                                }
                              : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                    },
                  }
                : asset
            ),
            property: goal.assets.property.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [
                        ...asset.liabilities.loans
                          .map(
                            (loan) =>
                              loan.ID === updateLoans.ID
                                ? {
                                    ...loan,
                                    /** Update loan info */
                                    ...updateLoans,
                                  }
                                : loan /** Else return loan */
                          )
                          .filter(
                            /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                            (loan) =>
                              ![...data.assets_has_liabilities]
                                .map((entry) => entry.liabilities_ID)
                                .includes(loan.liabilities_ID)
                          ),
                        ...(data.assets_has_liabilities.some(
                          (relation) => relation.assets_ID === asset.assets_ID
                        )
                          ? data.assets_has_liabilities.reduce<
                              Array<LoansSubscription["loans"]["data"]>
                            >((relationResult, relation) => {
                              if (
                                relation.liabilities_ID ===
                                  updateLoans.liabilities_ID &&
                                relation.assets_ID === asset.assets_ID
                              ) {
                                relationResult = [
                                  ...relationResult,
                                  updateLoans,
                                ];
                              }
                              return relationResult;
                            }, [])
                          : []),
                      ],
                    },
                  }
                : asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                  ...updateLoans,
                                }
                              : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                    },
                  }
                : asset
            ),
            vehicles: goal.assets.vehicles.map((asset) =>
              data.assets_has_liabilities.some(
                (relation) => relation.assets_ID === asset.assets_ID
              )
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: [
                        ...asset.liabilities.loans
                          .map(
                            (loan) =>
                              loan.ID === updateLoans.ID
                                ? {
                                    ...loan,
                                    /** Update loan info */
                                    ...updateLoans,
                                  }
                                : loan /** Else return loan */
                          )
                          .filter(
                            /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                            (loan) =>
                              ![...data.assets_has_liabilities]
                                .map((entry) => entry.liabilities_ID)
                                .includes(loan.liabilities_ID)
                          ),
                        ...(data.assets_has_liabilities.some(
                          (relation) => relation.assets_ID === asset.assets_ID
                        )
                          ? data.assets_has_liabilities.reduce<
                              Array<LoansSubscription["loans"]["data"]>
                            >((relationResult, relation) => {
                              if (
                                relation.liabilities_ID ===
                                  updateLoans.liabilities_ID &&
                                relation.assets_ID === asset.assets_ID
                              ) {
                                relationResult = [
                                  ...relationResult,
                                  updateLoans,
                                ];
                              }
                              return relationResult;
                            }, [])
                          : []),
                      ],
                    },
                  }
                : asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                  ...updateLoans,
                                }
                              : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                    },
                  }
                : asset
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((loan) =>
              loan.ID === updateLoans.ID ? { ...loan, ...updateLoans } : loan
            ),
          },
        })),
      })),

      /** Remove the existing loans object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        liabilities: {
          ...entity.liabilities,
          loans: [
            ...entity.liabilities.loans
              .map((loan) =>
                loan.ID === updateLoans.ID ? { ...loan, ...updateLoans } : loan
              )
              .filter(
                (loan) =>
                  ![...data.entities_has_liabilities]
                    .map((entry) => entry.liabilities_ID)
                    .includes(loan.liabilities_ID)
              ),
            ...(data.entities_has_liabilities.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? data.entities_has_liabilities.reduce<
                  Array<LoansSubscription["loans"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.liabilities_ID === updateLoans.liabilities_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updateLoans];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        /** Update assets based on assets_has_liabilities relations */
        assets: {
          ...entity.assets,
          offset: entity.assets.offset.map((asset) =>
            data.assets_has_liabilities.some(
              (relation) => relation.assets_ID === asset.assets_ID
            )
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: [
                      ...asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan info */
                                  ...updateLoans,
                                }
                              : loan /** Else return loan */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                      ...(data.assets_has_liabilities.some(
                        (relation) => relation.assets_ID === asset.assets_ID
                      )
                        ? data.assets_has_liabilities.reduce<
                            Array<LoansSubscription["loans"]["data"]>
                          >((relationResult, relation) => {
                            if (
                              relation.liabilities_ID ===
                                updateLoans.liabilities_ID &&
                              relation.assets_ID === asset.assets_ID
                            ) {
                              relationResult = [...relationResult, updateLoans];
                            }
                            return relationResult;
                          }, [])
                        : []),
                    ],
                  },
                }
              : asset.liabilities
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: asset.liabilities.loans
                      .map(
                        (loan) =>
                          loan.ID === updateLoans.ID
                            ? {
                                ...loan,
                                /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                ...updateLoans,
                              }
                            : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                      )
                      .filter(
                        /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                        (loan) =>
                          ![...data.assets_has_liabilities]
                            .map((entry) => entry.liabilities_ID)
                            .includes(loan.liabilities_ID)
                      ),
                  },
                }
              : asset
          ),
          property: entity.assets.property.map((asset) =>
            data.assets_has_liabilities.some(
              (relation) => relation.assets_ID === asset.assets_ID
            )
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: [
                      ...asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan info */
                                  ...updateLoans,
                                }
                              : loan /** Else return loan */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                      ...(data.assets_has_liabilities.some(
                        (relation) => relation.assets_ID === asset.assets_ID
                      )
                        ? data.assets_has_liabilities.reduce<
                            Array<LoansSubscription["loans"]["data"]>
                          >((relationResult, relation) => {
                            if (
                              relation.liabilities_ID ===
                                updateLoans.liabilities_ID &&
                              relation.assets_ID === asset.assets_ID
                            ) {
                              relationResult = [...relationResult, updateLoans];
                            }
                            return relationResult;
                          }, [])
                        : []),
                    ],
                  },
                }
              : asset.liabilities
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: asset.liabilities.loans
                      .map(
                        (loan) =>
                          loan.ID === updateLoans.ID
                            ? {
                                ...loan,
                                /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                ...updateLoans,
                              }
                            : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                      )
                      .filter(
                        /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                        (loan) =>
                          ![...data.assets_has_liabilities]
                            .map((entry) => entry.liabilities_ID)
                            .includes(loan.liabilities_ID)
                      ),
                  },
                }
              : asset
          ),
          vehicles: entity.assets.vehicles.map((asset) =>
            data.assets_has_liabilities.some(
              (relation) => relation.assets_ID === asset.assets_ID
            )
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: [
                      ...asset.liabilities.loans
                        .map(
                          (loan) =>
                            loan.ID === updateLoans.ID
                              ? {
                                  ...loan,
                                  /** Update loan info */
                                  ...updateLoans,
                                }
                              : loan /** Else return loan */
                        )
                        .filter(
                          /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                          (loan) =>
                            ![...data.assets_has_liabilities]
                              .map((entry) => entry.liabilities_ID)
                              .includes(loan.liabilities_ID)
                        ),
                      ...(data.assets_has_liabilities.some(
                        (relation) => relation.assets_ID === asset.assets_ID
                      )
                        ? data.assets_has_liabilities.reduce<
                            Array<LoansSubscription["loans"]["data"]>
                          >((relationResult, relation) => {
                            if (
                              relation.liabilities_ID ===
                                updateLoans.liabilities_ID &&
                              relation.assets_ID === asset.assets_ID
                            ) {
                              relationResult = [...relationResult, updateLoans];
                            }
                            return relationResult;
                          }, [])
                        : []),
                    ],
                  },
                }
              : asset.liabilities
              ? {
                  ...asset,
                  liabilities: {
                    ...asset.liabilities,
                    loans: asset.liabilities.loans
                      .map(
                        (loan) =>
                          loan.ID === updateLoans.ID
                            ? {
                                ...loan,
                                /** Update loan.assets if loan has assets ONLY LOAN INFO */
                                ...updateLoans,
                              }
                            : loan /** Update loan.assets if loan has assets ONLY LOAN INFO */
                      )
                      .filter(
                        /** Remove loan if asset does not have assets_has_liabilities relation in assets_has_liabilities */
                        (loan) =>
                          ![...data.assets_has_liabilities]
                            .map((entry) => entry.liabilities_ID)
                            .includes(loan.liabilities_ID)
                      ),
                  },
                }
              : asset
          ),
        },
      })),
    },
  });
}

function deleteLoans({ prev, payload }: LoansResolverParams) {
  const { data: deleteLoans } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          liabilities: {
            ...client.liabilities,
            loans: [...client.liabilities.loans].filter(
              (loan) => loan.ID !== deleteLoans.ID
            ),
          },
          /** Remove deleted loan from any assets that may have the loan. */
          assets: {
            ...client.assets,
            offset: client.assets.offset.map((asset) =>
              asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans.filter(
                        (loan) => loan.ID !== deleteLoans.ID
                      ),
                    },
                  }
                : asset
            ),
            property: client.assets.property.map((asset) =>
              asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans.filter(
                        (loan) => loan.ID !== deleteLoans.ID
                      ),
                    },
                  }
                : asset
            ),
            vehicles: client.assets.vehicles.map((asset) =>
              asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans.filter(
                        (loan) => loan.ID !== deleteLoans.ID
                      ),
                    },
                  }
                : asset
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              offset: goal.assets.offset.map((asset) =>
                asset.liabilities
                  ? {
                      ...asset,
                      liabilities: {
                        ...asset.liabilities,
                        loans: asset.liabilities.loans.filter(
                          (loan) => loan.ID !== deleteLoans.ID
                        ),
                      },
                    }
                  : asset
              ),
              property: goal.assets.property.map((asset) =>
                asset.liabilities
                  ? {
                      ...asset,
                      liabilities: {
                        ...asset.liabilities,
                        loans: asset.liabilities.loans.filter(
                          (loan) => loan.ID !== deleteLoans.ID
                        ),
                      },
                    }
                  : asset
              ),
              vehicles: goal.assets.vehicles.map((asset) =>
                asset.liabilities
                  ? {
                      ...asset,
                      liabilities: {
                        ...asset.liabilities,
                        loans: asset.liabilities.loans.filter(
                          (loan) => loan.ID !== deleteLoans.ID
                        ),
                      },
                    }
                  : asset
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.filter(
                (loan) => loan.ID !== deleteLoans.ID
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          liabilities: {
            ...entity.liabilities,
            loans: [...entity.liabilities.loans].filter(
              (loan) => loan.ID !== deleteLoans.ID
            ),
          },
          /** Remove deleted loan from any assets that may have the loan. */
          assets: {
            ...entity.assets,
            offset: entity.assets.offset.map((asset) =>
              asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans.filter(
                        (loan) => loan.ID !== deleteLoans.ID
                      ),
                    },
                  }
                : asset
            ),
            property: entity.assets.property.map((asset) =>
              asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans.filter(
                        (loan) => loan.ID !== deleteLoans.ID
                      ),
                    },
                  }
                : asset
            ),
            vehicles: entity.assets.vehicles.map((asset) =>
              asset.liabilities
                ? {
                    ...asset,
                    liabilities: {
                      ...asset.liabilities,
                      loans: asset.liabilities.loans.filter(
                        (loan) => loan.ID !== deleteLoans.ID
                      ),
                    },
                  }
                : asset
            ),
          },
        };
      }),
    },
  });
}

function loansResolver({ prev, payload }: LoansResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createLoans({ prev, payload });

    case MutationType.Update:
      return updateLoans({ prev, payload });

    case MutationType.Delete:
      return deleteLoans({ prev, payload });

    default:
      return prev;
  }
}

export function loansFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: loans,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: LoansSubscription };
      }
    ) =>
      loansResolver({
        prev,
        payload: payload.subscriptionData.data.loans,
      }),
    variables: query.variables,
  });
}
