import React, { useEffect, useState } from "react";
import { Button, ButtonProps } from "../../Buttons";
import clsx from "clsx";

interface CardPopperProps {
  buttonProps: ButtonProps;
  id: string;
  children: ({
    setMenuOpen,
  }: {
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => JSX.Element;
}

export const CardPopper: React.FC<CardPopperProps> = ({
  buttonProps,
  id,
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  /* This determines the left and right position of the multi level dropdown depending on the 
  position of the button (i.e if button is on the right side of the screen, add a right classname) */
  useEffect(() => {
    var dropdownDiv = document.getElementById("dropdown-menu-id-" + id);
    if (dropdownDiv) {
      var position = dropdownDiv.getBoundingClientRect();
      var x = position.left;
      var y = position.top;

      if (x > window.innerWidth / 2) {
        dropdownDiv.classList.add("right-0");
      } else {
        dropdownDiv.classList.add("left-0");
      }

      if (y > window.innerHeight / 2) {
        dropdownDiv.classList.add("bottom-12");
      } else {
        dropdownDiv.classList.add("top-8");
      }
    }
  }, [menuOpen, id]);

  return (
    <React.Fragment>
      {menuOpen && (
        <div
          className="fixed inset-0 z-30 bg-neutral-300/5"
          onClick={() => setMenuOpen(false)}
        />
      )}
      <div
        className={clsx(
          menuOpen ? "z-40" : "",
          "relative text-left max-w-40 w-full inline-block justify-center"
        )}
        id={"menu-" + id}
      >
        <Button
          {...buttonProps}
          id={"dropdown-button-id-" + id}
          onClick={() => setMenuOpen(!menuOpen)}
        />
        <div
          className={clsx(
            menuOpen ? "flex" : "hidden",
            "absolute z-40 w-44 rounded-md max-h-128 min-w-72 bg-white shadow-lg ring-1 ring-neutral-500 ring-opacity-5 outline-none"
          )}
          id={"dropdown-menu-id-" + id}
        >
          <div className="px-2 py-1 w-full">{children({ setMenuOpen })}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
