import { gql } from "@apollo/client";
import { salarypackagingFragment } from "./fragment";

export const salarypackaging = gql`
  subscription Salarypackaging($where: salarypackagingWhereUniqueInput) {
    salarypackaging(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...salarypackaging
      }
    }
  }
  ${salarypackagingFragment}
`;

export const salarypackagingMany = gql`
  subscription SalarypackagingMany($where: salarypackagingWhereInput) {
    salarypackagingMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...salarypackaging
      }
    }
  }
  ${salarypackagingFragment}
`;
