import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";

import {
  useFindUniqueAdviceQuery,
  useFindUniqueGroupQuery,
} from "../../../../codegen/schema";

import { Refetch } from "../../../../Components/Refetch";
import { ComponentLoader } from "../../../../Components/Loaders/ComponentLoader";

import { SidebarAdviceVerifyData } from "./Verifydata";
import { SidebarAdviceModelling } from "./Modelling";

type Params = {
  groupsid: string;
  advice_ID: string;
};

export const SidebarAdvice = () => {
  const params = useParams<Params>();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: { findUniqueGroup: group } = {},
    error: groupError,
    loading: groupLoading,
    refetch: groupRefetch,
  } = useFindUniqueGroupQuery({
    fetchPolicy: "cache-and-network",
    variables: { where: { ID: parseInt(params.groupsid!) } },
  });

  const {
    data: { findUniqueAdvice: advice } = {},
    error: adviceError,
    loading: adviceLoading,
    refetch: adviceRefetch,
  } = useFindUniqueAdviceQuery({
    fetchPolicy: "cache-and-network",
    variables: { where: { ID: parseInt(params.advice_ID!) } },
  });

  const [navigation, setNavigation] = useState([
    {
      name: "Dashboard",
      href: "",
      icon: "fa-house",
      classname: "complete",
    },
    {
      name: "Verify data",
      href: "verifydata",
      icon: "fa-badge-check",
      classname: "",
    },
    {
      name: "Modelling",
      href: "modelling",
      icon: "fa-chart-area",
      classname: "",
    },
    {
      name: "Advice agreement",
      href: "adviceagreement",
      icon: "fa-handshake",
      classname: "",
    },
    { name: "Documents", href: "document", icon: "fa-file", classname: "" },
    {
      name: "Confirmation",
      href: "confirmation",
      icon: "fa-file-signature",
      classname: "",
    },
  ]);

  useEffect(() => {
    var updatedNavigation = navigation;

    if (advice)
      updatedNavigation.forEach((item) => {
        switch (item.name) {
          case "Verify data":
            if (advice.Progress! >= 20) {
              item.classname = "complete";
            } else {
              item.classname = "progress";
            }
            break;

          case "Modelling":
            if (advice.Progress! >= 40) {
              item.classname = "complete";
            } else if (advice.Progress! >= 20) {
              item.classname = "progress";
            } else {
              item.classname = "notcomplete";
            }
            break;

          case "Advice agreement":
            if (advice.Progress! >= 60) {
              item.classname = "complete";
            } else if (advice.Progress! >= 50) {
              item.classname = "progress";
            } else {
              item.classname = "notcomplete";
            }
            break;

          case "Documents":
            if (advice.Progress! >= 90) {
              item.classname = "complete";
            } else if (advice.Progress! >= 60) {
              item.classname = "progress";
            } else {
              item.classname = "notcomplete";
            }
            break;

          case "Confirmation":
            if (advice.Progress! >= 100) {
              item.classname = "complete";
            } else if (advice.Progress! >= 90) {
              item.classname = "progress";
            } else {
              item.classname = "notcomplete";
            }
            break;
        }
      });

    setNavigation(updatedNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advice]);

  if ((groupError && !groupLoading) || (adviceError && !adviceLoading)) {
    return (
      <Refetch
        refetch={() => {
          groupRefetch();
          adviceRefetch();
        }}
      />
    );
  }

  if ((!group && groupLoading) || (!advice && adviceLoading)) {
    return <ComponentLoader />;
  }

  const RenderNavItem = ({
    item,
    current,
    close,
  }: {
    item: {
      name: string;
      href: string;
      icon: string;
      classname: string;
    };
    current: boolean;
    close: (
      focusableElement?:
        | HTMLElement
        | React.MutableRefObject<HTMLElement | null>
        | undefined
    ) => void;
  }) => {
    return (
      advice &&
      group && (
        <li
          className={clsx(
            current ? "bg-neutral-50" : "hover:bg-neutral-50",
            item.classname !== "notcomplete"
              ? "cursor-pointer hover:text-blue-500"
              : "text-neutral-300 hover:bg-white",
            "flex justify-between items-center p-3 group gap-x-3 w-full rounded-md "
          )}
          key={item.name}
          onClick={() => {
            if (item.classname !== "notcomplete") {
              navigate(
                `/advice/ID/${advice.ID}/group/ID/${group.ID}/${item.href}`
              );
              if (!location.pathname.includes(item.href)) close();
            }
          }}
        >
          <div
            className={clsx(
              current ? " text-blue-500" : " ",
              item.classname !== "notcomplete"
                ? " group-hover:text-blue-500"
                : "text-neutral-300",
              "flex gap-x-3 items-center rounded-md text-sm leading-6 font-semibold text-nowrap"
            )}
          >
            <i
              className={clsx(
                item?.icon ? item.icon : "",
                item.classname !== "notcomplete"
                  ? " group-hover:text-blue-500"
                  : "",
                "fa-regular shrink-0 fa-xl"
              )}
              aria-hidden="true"
            />
            {item.name}
          </div>
          {item.classname === "complete" ? (
            <i
              className={"fa-solid fa-circle-check text-semantic-success-500"}
              aria-hidden="true"
            />
          ) : item.classname === "progress" ? (
            <i
              className={"fa-solid fa-circle-dot text-semantic-info-500"}
              aria-hidden="true"
            />
          ) : (
            ""
          )}
        </li>
      )
    );
  };

  return (
    group &&
    advice && (
      <div className="pl-24 flex grow flex-col gap-y-4 overflow-y-auto border-r border-neutral-100 bg-white pr-4 py-6">
        <h6 className="text-xs">Dashboard</h6>
        <h4>{advice.Type?.replaceAll("_", " ")}</h4>

        <h5
          className="text-xs cursor-pointer"
          onClick={() => navigate(`/group/ID/${group.ID}/advice`)}
        >
          {group?.GroupName}
        </h5>
        <div className="flex items-center gap-x-3 whitespace-nowrap">
          <div
            className="flex w-full h-3 bg-gray-200 rounded-full overflow-hidden bg-neutral-100"
            role="progressbar"
          >
            <div
              className="flex flex-col justify-center rounded-full overflow-hidden bg-blue-500 transition duration-500"
              style={{ width: advice.Progress + "%" }}
            />
          </div>

          <div className="w-10 text-end">
            <span className="text-xs font-semibold text-neutral-800">
              {advice.Progress} %
            </span>
          </div>
        </div>

        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => {
                  const pathname = location.pathname.substring(1);
                  const current =
                    item.name === "Dashboard"
                      ? pathname ===
                          `advice/ID/${advice.ID}/group/ID/${group.ID}/` ||
                        pathname ===
                          `advice/ID/${advice.ID}/group/ID/${group.ID}`
                      : pathname.includes(item.href);

                  return (
                    <Disclosure key={item.name} as="div" defaultOpen={current}>
                      {({ open, close }) => (
                        <>
                          {item.name !== "Verify data" &&
                          item.name !== "Modelling" ? (
                            <div>
                              <RenderNavItem
                                item={item}
                                current={current}
                                close={close}
                              />
                            </div>
                          ) : (
                            <>
                              <DisclosureButton
                                className={clsx(
                                  current
                                    ? "bg-neutral-50 text-blue-500"
                                    : "hover:bg-neutral-50 text-neutral-600",
                                  item.classname !== "notcomplete"
                                    ? "cursor-pointer hover:text-blue-500"
                                    : "text-neutral-300 hover:bg-white cursor-default",
                                  "group flex gap-x-3 p-0 items-center w-full text-left rounded-md text-sm leading-6 font-semibold"
                                )}
                              >
                                <RenderNavItem
                                  item={item}
                                  current={current}
                                  close={close}
                                />
                              </DisclosureButton>
                              {pathname.includes(item.href) && (
                                <DisclosurePanel
                                  as="ul"
                                  className="mt-1 px-2"
                                  static
                                >
                                  {item.name === "Verify data" ? (
                                    <SidebarAdviceVerifyData
                                      group={group}
                                      advice={advice}
                                    />
                                  ) : (
                                    <SidebarAdviceModelling
                                      group={group}
                                      advice={advice}
                                    />
                                  )}
                                </DisclosurePanel>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Disclosure>
                  );
                })}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    )
  );
};
