import { gql } from "@apollo/client";
import { goalsFragment } from ".";

/** Goals */
export const createOneGoals = gql`
  mutation CreateOneGoals($data: goalsCreateInput!) {
    createOneGoals(data: $data) {
      ...goals
    }
  }
  ${goalsFragment}
`;

export const updateOneGoals = gql`
  mutation UpdateOneGoals(
    $data: goalsUpdateInput!
    $where: goalsWhereUniqueInput!
  ) {
    updateOneGoals(data: $data, where: $where) {
      ...goals
    }
  }
  ${goalsFragment}
`;

export const deleteOneGoals = gql`
  mutation DeleteOneGoals($where: goalsWhereUniqueInput!) {
    deleteOneGoals(where: $where) {
      ...goals
    }
  }
  ${goalsFragment}
`;
