import { gql } from "@apollo/client";
import { contenttemplateFragment } from "./fragment";

export const contenttemplate = gql`
  subscription Contenttemplate($where: contenttemplateWhereUniqueInput) {
    contenttemplate(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...contenttemplate
      }
    }
  }
  ${contenttemplateFragment}
`;

export const contenttemplateMany = gql`
  subscription ContenttemplateMany($where: contenttemplateWhereInput) {
    contenttemplateMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...contenttemplate
      }
    }
  }
  ${contenttemplateFragment}
`;
