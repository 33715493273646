export function InvestmentPropertyDebtModelDescription() {
  return (
     <p className="text-2xs italic">
      The investment property debt model displays the distibution of property
      debt between home loans, investment loans, and any SMSF loans. This uses
      data directly from the group and not from the scenario data.
    </p>
  );
}

export function InvestmentPropertyDebtCalculationTooltip() {
  return (
    <>
      <p>
        {" "}
        <b>X axis </b> <br />
        this graph starts with the earliest advice year recorded in MOAS and
        ends with the model end date. If no end date is supplied, the x axis
        will col-span-full 30 years.{" "}
      </p>
      <p>
        <b>Loan Totals</b>
        <br />
        This model uses the liability advice totals (see below for more information on advice totals).
        Loan Totals are calculated by adding all liabilities based on type for each advice year recorded in MOAS.
        With the exception of Superannuation loan total, which is based on if the loan is owned by an SMSF entity.
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#advice-totals"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for more information on advice totals.
        </a>
      </p>
    </>
  );
}
