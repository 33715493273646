import React, { useEffect } from "react";
import {
  Content,
  FindUniqueGroupQuery,
  Theme,
  useFindManyChildQuery,
} from "../../../../codegen/schema";

import { findManyChildSubscription } from "../../../../API/child";
import { ChildContent } from "./Inputs";

export type ChildTemplateType = {
  ID: number;
  FirstName: string;
  LastName: string;
  Gender: string;
  DOB: string;
  Dependant: string;
  Comments: string;
  DependantToAge: string;
};

interface ChildComponentProps {
  content?: Content | undefined;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"] | undefined;
}

export const ChildComponent: React.FC<ChildComponentProps> = ({
  theme,
  content,
  group = undefined,
}) => {
  // Lazy query if coming from content
  const {
    data: { findManyChild: children = undefined } = {},
    ...childrenResult
  } = useFindManyChildQuery({
    skip: !group,
    variables: {
      where: { groups_ID: { equals: group?.ID } },
    },
  });

  useEffect(() => {
    findManyChildSubscription(childrenResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // This is used for template
  const childrenObject: ChildTemplateType[] = [
    {
      ID: 1,
      FirstName: "John Jnr",
      LastName: "Smith",
      Gender: "Male",
      DOB: "2013-01-1",
      Dependant: "Yes",
      Comments: "",
      DependantToAge: "18",
    },
    {
      ID: 2,
      FirstName: "Jane Jnr",
      LastName: "Smith",
      Gender: "Female",
      DOB: "2014-01-1",
      Dependant: "Yes",
      Comments: "",
      DependantToAge: "18",
    },
  ];

  return (
    <React.Fragment>
      <ChildContent
        children={group ? children ?? [] : childrenObject}
        theme={theme}
      />
    </React.Fragment>
  );
};
