import { gql } from "@apollo/client";
import { termsandconditionsFragment } from "./fragment";

export const termsandconditions = gql`
  subscription Termsandconditions($where: termsandconditionsWhereUniqueInput) {
    termsandconditions(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...termsandconditions
      }
    }
  }
  ${termsandconditionsFragment}
`;

export const termsandconditionsMany = gql`
  subscription TermsandconditionsMany($where: termsandconditionsWhereInput) {
    termsandconditionsMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...termsandconditions
      }
    }
  }
  ${termsandconditionsFragment}
`;
