import { gql } from "@apollo/client";
import { payeeFragment } from "./fragment";

export const payee = gql`
  subscription Payee($where: payeeWhereUniqueInput) {
    payee(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...payee
      }
      services_has_payee {
        services_ID
      }
    }
  }
  ${payeeFragment}
`;

export const payeeMany = gql`
  subscription PayeeMany($where: payeeWhereInput) {
    payeeMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...payee
      }
      services_has_payee {
        services_ID
      }
    }
  }
  ${payeeFragment}
`;

