import { Fragment } from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { SidebarNavigation } from "./Navigation";
import { FindUniqueUserQuery } from "../../codegen/schema";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: FindUniqueUserQuery["findUniqueUser"];
}

export const Sidebar: React.FC<SidebarProps> = ({
  sidebarOpen,
  setSidebarOpen,
  user,
}) => {
  const location = useLocation();

  return (
    <div>
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <div
                        className="fa-solid fa-times h-6 w-6 text-neutral-800"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>
                <SidebarNavigation user={user} />
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
      <div className={clsx(
        location.pathname.includes("template/ID") ||
        location.pathname.includes("theme/ID") ||
        location.pathname.includes("document/ID")
        ? "w-20" : "lg:w-72"
        ,"hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:flex-col")}>
        <SidebarNavigation user={user} />
      </div>
    </div>
  );
};
