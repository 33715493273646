import React from "react";
import {
  Contenttemplate,
  Contenttemplate_ModelType,
  Theme,
} from "../../../../../../../../../codegen/schema";

import { RetirementPlanningModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/RetirementPlanning/Graphs/Placeholder";
import { StyledText } from "../../../../../../../../../Components/Advice/StyledText";
import { SuperannuationInsuranceModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/SuperannuationInsurance/Graphs/Placeholder";
import { SuperannuationContributionSplittingModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/SuperannuationContributionSplitting/Graphs/Placeholder";
import { TrustPlanningModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/TrustPlanning/Graphs/Placeholder";
import { LongTermProgressPlaceholderModel } from "../../../../../../../../../Components/Advice/Models/LongTermProgress/Graphs/Live/Placeholder";
import { AssetAllocationPlaceholderModel } from "../../../../../../../../../Components/Advice/Models/AssetAllocation/Graphs/Live/Placeholder";
import { AssetDebtCompositionPlaceholderModel } from "../../../../../../../../../Components/Advice/Models/AssetDebtComposition/Graphs/Live/Placeholder";
import { AssetGrowthPlaceholderModel } from "../../../../../../../../../Components/Advice/Models/AssetGrowth/Graphs/Live/Placeholder";
import { GrossNetIncomePlaceholderModel } from "../../../../../../../../../Components/Advice/Models/GrossNetIncome/Graphs/Live/Placeholder";
import { IncomeOptimisationModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/IncomeOptimisation/Graphs/Placeholder";
import { IncomeTaxModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/IncomeTax/Graphs/Placeholder";
import { InsuranceDeathModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/InsuranceDeath/Graphs/Placeholder";
import { InsuranceIncomeProtectionModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/InsuranceIncomeProtection/Graphs/Placeholder";
import { InsuranceTPDModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/InsuranceTPD/Graphs/Placeholder";
import { InsuranceTraumaModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/InsuranceTrauma/Graphs/Placeholder";
import { InvestmentPropertyDebtPlaceholderModel } from "../../../../../../../../../Components/Advice/Models/InvestmentPropertyDebt/Graphs/Live/Placeholder";
import { LoanRepaymentModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/LoanRepayment/Graphs/Placeholder";
import { PropertyCashflowModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/PropertyCashflow/Graphs/Placeholder";
import { PropertyValueModelPlaceholder } from "../../../../../../../../../Components/Advice/Models/PropertyValue/Graphs/Placeholder";

interface ModelComponentProps {
  contenttemplate: Contenttemplate | undefined;
  theme?: Theme;
}

export const ModelComponent: React.FC<ModelComponentProps> = ({
  contenttemplate,
  theme,
}) => {
  if (!contenttemplate) return null;

  return (
    <React.Fragment>
      {/* <div
        className="flex items-center justify-center "
        style={{ height: "100%", width: "100%" }}
      >
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
        >
          {contenttemplate.ModelType?.replaceAll("_", " ")} -{" "}
          {contenttemplate.ModelToRender}
        </StyledText>
      </div> */}
      <div className="h-full w-full">
        {(() => {
          switch (contenttemplate.ModelType) {
            case Contenttemplate_ModelType.AssetAllocation:
              return <AssetAllocationPlaceholderModel theme={theme} />;
            case Contenttemplate_ModelType.AssetAndDebtComposition:
              return <AssetDebtCompositionPlaceholderModel theme={theme} />;
            case Contenttemplate_ModelType.AssetGrowth:
              return <AssetGrowthPlaceholderModel theme={theme} />;
            case Contenttemplate_ModelType.GrossAndNetIncome:
              return <GrossNetIncomePlaceholderModel theme={theme} />;
            case Contenttemplate_ModelType.IncomeOptimisation:
              return (
                <IncomeOptimisationModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.IncomeTax:
              return (
                <IncomeTaxModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.InsuranceDeath:
              return (
                <InsuranceDeathModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.InsuranceIncomeProtection:
              return (
                <InsuranceIncomeProtectionModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.InsuranceTpd:
              return (
                <InsuranceTPDModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.InsuranceTrauma:
              return (
                <InsuranceTraumaModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.InvestmentAndPropertyDebt:
              return <InvestmentPropertyDebtPlaceholderModel theme={theme} />;
            case Contenttemplate_ModelType.LoanRepayment:
              return (
                <LoanRepaymentModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.PropertyCashflow:
              return (
                <PropertyCashflowModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.PropertyValue:
              return (
                <PropertyValueModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );
            case Contenttemplate_ModelType.RetirementPlanning:
              return (
                <RetirementPlanningModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );

            case Contenttemplate_ModelType.LongTermProgress:
              return <LongTermProgressPlaceholderModel theme={theme} />;

            case Contenttemplate_ModelType.SuperannuationContributionSplitting:
              return (
                <SuperannuationContributionSplittingModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );

            case Contenttemplate_ModelType.SuperannuationInsurance:
              return (
                <SuperannuationInsuranceModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );

            case Contenttemplate_ModelType.TrustPlanning:
              return (
                <TrustPlanningModelPlaceholder
                  ModelToRender={contenttemplate.ModelToRender}
                  theme={theme}
                />
              );

            default:
              return (
                <div
                  className="flex items-center justify-center h-full w-full bg-neutral-50"
                >
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "H2"
                    )}
                  >
                    {contenttemplate.ModelType?.replaceAll("_", " ")} Graph
                  </StyledText>
                </div>
              );
          }
        })()}
      </div>
    </React.Fragment>
  );
};
