import React, { useEffect, useState } from "react";
import {
  Content,
  FindUniqueGroupQuery,
  Theme,
  useFindUniqueSalarypackagingQuery,
} from "../../../../codegen/schema";

import { SalarypackagingContent } from "./Inputs";
import findUniqueSalarypackagingSubscription from "../../../../API/salarypackaging/subscriptions/findUniqueSalarypackaging";

interface SalarypackagingComponentProps {
  content?: Content;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

export const SalarypackagingComponent: React.FC<
  SalarypackagingComponentProps
> = ({ content, theme, group }) => {
  const {
    data: { findUniqueSalarypackaging: salarypackaging } = {},
    ...salarypackagingResult
  } = useFindUniqueSalarypackagingQuery({
    skip: !content || !content.salarypackaging_ID,
    variables: { where: { ID: content?.salarypackaging_ID } },
  });

  useEffect(() => {
    findUniqueSalarypackagingSubscription(salarypackagingResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is used for template
  const [salarypackagingObject] = useState({
    clients_ID: 0,
    AvailableBalance: "",
    CapLimit: "-",
    PreTaxPayDeduction: "-",
    Admin: "-",
    HomeLoan: "-",
    Super: "-",
  });

  return (
    <React.Fragment>
      <SalarypackagingContent
        salarypackaging={salarypackaging ?? salarypackagingObject}
        theme={theme}
        group={group}
      />
    </React.Fragment>
  );
};
