import { gql } from "@apollo/client";
import { advicegoalsFragment } from ".";

export const createOneAdvicegoals = gql`
  mutation CreateOneAdvicegoals($data: advicegoalsCreateInput!) {
    createOneAdvicegoals(data: $data) {
      ...advicegoals
    }
  }
  ${advicegoalsFragment}
`;

export const updateOneAdvicegoals = gql`
  mutation UpdateOneAdvicegoals(
    $data: advicegoalsUpdateInput!
    $where: advicegoalsWhereUniqueInput!
  ) {
    updateOneAdvicegoals(data: $data, where: $where) {
      ...advicegoals
    }
  }
  ${advicegoalsFragment}
`;

export const deleteOneAdvicegoals = gql`
  mutation DeleteOneAdvicegoals($where: advicegoalsWhereUniqueInput!) {
    deleteOneAdvicegoals(where: $where) {
      ...advicegoals
    }
  }
  ${advicegoalsFragment}
`;
