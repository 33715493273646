import { useContext } from "react";
import { StoreContext } from "../../../../../../../Datastore/Store";
import { ScenarioData } from "../../../Calculations";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  RechartsCustomReferenceLine,
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  scenarioData: ScenarioData[] | undefined;
}

export const IncomeTaxScenarioModelGraph: React.FC<Props> = ({
  scenarioData,
}) => {
  const [context] = useContext(StoreContext);

  if (!scenarioData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={scenarioData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />
        <XAxis
          interval={0}
          tickLine={false}
          dataKey="scenarioDataKey"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            // value="Tax Bracket"
            value="Client"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value ($)"
            position="center"
            offset={-30}
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />
        <ReferenceLine y={0} stroke="#000" />

        <Bar
          dataKey={"taxableIncome"}
          fill={context.colours.blue200}
          isAnimationActive={false}
          stackId={1}
          maxBarSize={50}
        />

        <Bar
          dataKey={"incomeTax"}
          fill={context.colours.blue}
          isAnimationActive={false}
          stackId={1}
          maxBarSize={50}
        />

        <Bar
          dataKey={"superannuationTax"}
          fill={context.colours.purple100}
          isAnimationActive={false}
          stackId={1}
          maxBarSize={50}
        />

        <Bar
          dataKey={"studyRepayments"}
          fill={context.colours.orange200}
          isAnimationActive={false}
          stackId={1}
          maxBarSize={50}
        />

        <Bar
          dataKey={"medicareLevy"}
          fill={context.colours.red}
          isAnimationActive={false}
          stackId={1}
          maxBarSize={50}
        />

        <Bar
          dataKey={"pension"}
          fill={context.colours.green200}
          isAnimationActive={false}
          stackId={1}
          maxBarSize={50}
        />

        <Scatter
          dataKey={"netIncome"}
          fill={context.colours.green}
          stroke={context.colours.green}
          strokeWidth={2}
          shape={<RechartsCustomReferenceLine />}
          isAnimationActive={false}
        />

        {/* {scenarioData[0] &&
          Object.keys(scenarioData[0])
            .filter((key) => key !== "scenarioDataKey")
            .reduce<Array<string[]>>((accumulator, key, index) => {
              if (index % 2 === 0) {
                accumulator.push([key]);
              } else {
                accumulator[accumulator.length - 1].push(key);
              }

              return accumulator;
            }, [])
            .flatMap((key, keyIndex) =>
              key.map((entry, entryIndex) => (
                <Bar
                  key={entry}
                  dataKey={entry}
                  fill={
                    entryIndex === 0
                      ? context.colours[keyIndex]
                      : context.colours[keyIndex] + "1c"
                  }
                  stackId={keyIndex}
                  isAnimationActive={false}
                />
              ))
            )} */}

        {/* {scenarioData[0] &&
          Object.keys(scenarioData[scenarioData.length - 1])
            .filter((key) => key !== "scenarioDataKey")
            .reduce<Array<string[]>>((accumulator, key, index) => {
              if (index % 4 === 0) {
                accumulator.push([key]);
              } else {
                accumulator[accumulator.length - 1].push(key);
              }

              return accumulator;
            }, [])
            .flatMap((key, keyIndex) =>
              key.map((entry, entryIndex) => (
                <Bar
                  key={entry}
                  dataKey={entry}
                  fill={
                    entryIndex === 0
                      ? context.colours[keyIndex]
                      : context.colours[keyIndex] + "1c"
                  }
                  stackId={keyIndex}
                  isAnimationActive={false}
                />
              ))
            )} */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
