import { CONCESSIONAL_CONTRIBUTIONS_CAP } from "../../../../../Constants/Calculations";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";
import { IncomeRows } from "..";
import dayjs from "dayjs";

export default function CalculationDescriptipn(
  field: string,
  optionalElement?: JSX.Element | JSX.Element[]
) {
  var tooltip: string | JSX.Element = field;
  switch (field) {
    case IncomeRows.Salary:
      tooltip = <p>Gross salary form scenario client inputs.</p>;
      break;
    case IncomeRows.ExcessConcessionalContributions:
      tooltip = (
        <p>
          Any amount of concessional contributions over the cap of{" "}
          {/* {checkFieldIsEmpty(
            CONCESSIONAL_CONTRIBUTIONS_CAP.find(
              (entry) => entry.year === dayjs().year()
            )?.value,
            "dollar"
          )} */}
          {checkFieldIsEmpty(
            CONCESSIONAL_CONTRIBUTIONS_CAP.find((entry) => {
              // Determine the financial year based on the month
              // Australian financial year starts on July 1st
              const financialYear =
                dayjs().month() >= 6 ? dayjs().year() + 1 : dayjs().year(); // If month is January to June, use the current year

              // Find the entry by the calculated financial year
              return entry.year === financialYear;
            })?.value,
            "dollar"
          )}
          . This excess is taxed at the highest marginal tax rate of the client
          less the tax offset of 15% which was already paid as part of the
          concessional contribution.
        </p>
      );
      break;
    case IncomeRows.Pension:
      tooltip = <p>Pension income p.a.</p>;
      break;
    case IncomeRows.RentalIncome:
      tooltip = (
        <p>
          Rental income for owned properties based on "Income Paid To" field on
          scenario client.
        </p>
      );
      break;
    case IncomeRows.GrossIncome:
      tooltip = (
        <code className="text-xs">
          Gross income = salary + excess concessional contributions + rental
          income
        </code>
      );
      break;
    case IncomeRows.PropertyNonCashTaxDed:
      tooltip = <p>Non-cash property tax deductions (e.g. deprectiation).</p>;
      break;
    case IncomeRows.ExcessConcessionalContributionsTaxOffset:
      tooltip = (
        <p>
          15% of excess concessional contributions held as contributions tax.
          This is used to offset the excess concessional contributions tax at
          the highest marginal tax rate for the client.
        </p>
      );
      break;
    case IncomeRows.PropertyInterestTaxDed:
      tooltip = (
        <p>
          Any interest accrued by loans that are secured by investment
          properties owned by the client is tax deductible.
        </p>
      );
      break;
    case IncomeRows.PropertyExpensesTaxDed:
      tooltip = (
        <p>
          Any cash expenses the client may pay for investment properties owned
          by the client is tax deductible.
        </p>
      );
      break;
    case IncomeRows.SalarySacrificeCont:
      tooltip = (
        <p>
          Any pre-tax salary sacrifice made by the client is tax deductible.
        </p>
      );
      break;
    case IncomeRows.SalaryPackaging:
      tooltip = (
        <p>
          Any salary packaging payments made by the client are tax deductible.
        </p>
      );
      break;
    case IncomeRows.IncomeProtection:
      tooltip = (
        <p>
          Any income protection insurance premiums paid by the client are tax
          deductible. This does not include any portion paid through
          superannuation as that cannot be claimed as a tax deduction.
        </p>
      );
      break;
    case IncomeRows.AssessableIncome:
      tooltip = (
        <code className="text-xs">
          Assessable income = gross income - non-cash tax deductions - cash tax
          deductions
        </code>
      );
      break;
    case IncomeRows.EstimatedIncomeTax:
      tooltip = <p>Total marginal income tax payable by the client.</p>;
      break;
    case IncomeRows.SuperannuationTax:
      tooltip = (
        <p>
          Total DIV293 and ECC tax paid from superannuation not from income.
        </p>
      );
      break;
    case IncomeRows.AddBackNonCashTaxDed:
      tooltip = (
        <p>
          Add back any non-cash tax deductions to the client's income (Non-cash
          property deductions and salary packaging).
        </p>
      );
      break;
    case IncomeRows.EstimatedNetIncome:
      tooltip = (
        <code className="text-xs">
          Estimated net income = assessable income - estimated income tax +
          non-cash tax deductions + DIV293 & ECC tax paid by super
        </code>
      );
      break;
    case IncomeRows.EstimatedExpenses:
      tooltip = <p>Estimated annual living expenses of the client.</p>;
      break;
    case IncomeRows.EstimatedInsurance:
      tooltip = (
        <p>
          Insurances other than Income Protection are treated as after cash
          expenses.
        </p>
      );
      break;
    case IncomeRows.EstimatedCombinedRepayments:
      tooltip = (
        <p>
          Sum of all loan repayments that are not classed as tax deductible.
          E.g. P&I loan for Home, principal of loan repayment if interest is
          deductible for investment purposes.
        </p>
      );
      break;
    case IncomeRows.EstimatedCombinedAssetContributionsFromIncome:
      tooltip = (
        <p>
          Total amount of contributions to assets. E.g. $1,000 p.m. to offset
          account = $12,000 p.a.
        </p>
      );
      break;
    case IncomeRows.EstimatedCombinedLiabilityContributions:
      tooltip = (
        <p>
          Total amount of contributions to liabilities. E.g. $1,000 p.m.
          contribution to loan = $12,000 p.a.
          <br />
          *Note that these contributions do not include any extra loan
          repayments as they are included as part of the repayments above.
        </p>
      );
      break;
    case IncomeRows.CalculatedSurplus:
      tooltip = (
        <code className="text-xs">
          Calculated surplus = net income + DIV293 & ECC tax paid by super -
          living expenses - loan repayments - asset contributions - liability
          contributions - life insurance premiums non Income Protection
        </code>
      );
      break;
    default:
      tooltip = <p>{field}</p>;
      break;
  }
  return (
    <div className="cursor-pointer hover:text-blue-500">
      <div className="p-3">
        <h5 className="mb-2 text-center">{field}</h5>
        {tooltip}
        {optionalElement}
      </div>
    </div>
  );
}
