import { useLocation, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";

import {
  FindUniqueUserQuery,
  useFindUniqueGroupQuery,
} from "../../../../codegen/schema";
import { Refetch } from "../../../../Components/Refetch";
import { ComponentLoader } from "../../../../Components/Loaders/ComponentLoader";
import { GroupStatus } from "../../../../Pages/Group&Client/Settings/GroupStatus";

type Params = {
  groupsid: string;
};

interface SidebarDataVerificationProps {
  user: FindUniqueUserQuery["findUniqueUser"];
}

export const SidebarDataVerification = ({ user }: SidebarDataVerificationProps) => {
  const params = useParams<Params>();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: { findUniqueGroup: group } = {},
    error: groupError,
    loading: groupLoading,
    refetch: groupRefetch,
  } = useFindUniqueGroupQuery({
    fetchPolicy: "cache-and-network",
    variables: { where: { ID: parseInt(params.groupsid!) } },
  });

  if (groupError && !groupLoading) {
    return (
      <Refetch
        refetch={() => {
          groupRefetch();
        }}
      />
    );
  }

  if (!group && groupLoading) {
    return <ComponentLoader />;
  }

  return (
    group && (
      <div className="pl-24 flex grow flex-col gap-y-4 overflow-y-auto border-r border-neutral-100 bg-white pr-4 py-6">
        <div className="flex justify-between items-center">
          <GroupStatus buttonType="no-style" user={user} group={group} />
          <h6 className="text-xs">ID: {group.ID}</h6>
        </div>

        <h6 className="text-xs">Data verification</h6>

        <h4
          className="cursor-pointer"
          onClick={() => navigate(`/group/ID/${group.ID}/propertyfinance`)}
        >
          {group?.GroupName}
        </h4>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {verifyDataNav.map((item) => {
                  const pathname = location.pathname.substring(1);
                  const current =
                    item.name === "Client details"
                      ? pathname.includes(item.href) &&
                        !pathname.includes("income")
                      : pathname.includes(item.href);

                  return (
                    <li key={item.name}>
                      {!item.children ? (
                        <div
                          onClick={() => {
                            navigate(
                              `/data-verification/group/ID/${group.ID}/${item.href}`
                            );
                          }}
                          className={clsx(
                            current ? "bg-neutral-50" : "hover:bg-neutral-50",
                            "block rounded-md py-1 pr-2 pl-9 text-xs leading-6 text-neutral-600 cursor-pointer"
                          )}
                        >
                          {item.name}
                        </div>
                      ) : (
                        <Disclosure as="div">
                          {({ open, close }) => (
                            <>
                              <DisclosureButton
                                className={clsx(
                                  current
                                    ? "bg-neutral-50"
                                    : "hover:bg-neutral-50 text-neutral-600",
                                  "flex gap-x-3 items-center w-full rounded-md py-1 pr-2 pl-9 text-xs leading-6"
                                )}
                              >
                                {item.name}
                                <i
                                  className={clsx(
                                    open
                                      ? "rotate-90 text-neutral-400"
                                      : "text-neutral-400",
                                    "ml-auto h-5 w-5 shrink-0 icon fa-solid fa-chevron-right"
                                  )}
                                  aria-hidden="true"
                                />
                              </DisclosureButton>
                              {(pathname.includes(item.href) || open) && (
                                <DisclosurePanel
                                  as="ul"
                                  className="mt-1 px-2"
                                  static
                                >
                                  <>
                                    {item.children?.map((subItem) => {
                                      const subItemcurrent = pathname.includes(
                                        subItem.href
                                      );
                                      return (
                                        <li key={subItem.name}>
                                          {/* 44px */}
                                          <div
                                            onClick={() => {
                                              navigate(
                                                `/data-verification/group/ID/${group.ID}/${subItem.href}`
                                              );
                                              if (!pathname.includes(item.href))
                                                close();
                                            }}
                                            className={clsx(
                                              subItemcurrent
                                                ? "bg-neutral-50"
                                                : "hover:bg-neutral-50",
                                              "block rounded-md py-1 pr-2 pl-9 text-xs leading-6 text-neutral-600 cursor-pointer"
                                            )}
                                          >
                                            {subItem.name}
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </>
                                </DisclosurePanel>
                              )}
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    )
  );
};

export const verifyDataNav = [
  {
    name: "Group details",
    href: "group-details",
    children: [
      { name: "General", href: "group-details/general" },
      {
        name: "Entities",
        href: "group-details/entities/overview",
      },
    ],
  },
  {
    name: "Client details",
    href: "client",
    children: [
      { name: "Personal", href: "client/personal" },
      {
        name: "Contact",
        href: "client/contact",
      },
      {
        name: "Address",
        href: "client/address",
      },
      {
        name: "Identification",
        href: "client/id",
      },
      {
        name: "Dependants",
        href: "client/dependants",
      },
      {
        name: "Estate planninng",
        href: "client/estateplanning",
      },
    ],
  },
  {
    name: "Income",
    href: "income",
    children: [
      { name: "Income", href: "income/details/overview" },
      {
        name: "Employment",
        href: "income/employment",
      },
    ],
  },
  {
    name: "Assets & Liabilities",
    href: "assetsliabilities",
  },
  {
    name: "Insurance",
    href: "insurance",
  },
  {
    name: "Salary packaging",
    href: "salarypackaging",
  },
  {
    name: "Fees",
    href: "fees",
  },
  {
    name: "Download",
    href: "download",
  },
];
