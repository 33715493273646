import { QueryResult } from "@apollo/client";
import {
  EstateplanningManySubscription,
  FindManyEstateplanningQuery,
  FindManyEstateplanningQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { estateplanningMany } from "../subscription";

interface EstateplanningResolverParams {
  prev: FindManyEstateplanningQuery;
  payload: EstateplanningManySubscription["estateplanningMany"];
}

function updateEstateplanning({ prev, payload }: EstateplanningResolverParams) {
  const estateplanning = payload.data;

  return Object.assign({}, prev, {
    findManyEstateplanning: prev.findManyEstateplanning.map((entry) =>
      entry.ID === estateplanning.ID ? { ...entry, ...estateplanning } : entry
    ),
  });
}

function estateplanningResolver({
  prev,
  payload,
}: EstateplanningResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createEstateplanning({ prev, payload });

    case MutationType.Update:
      return updateEstateplanning({ prev, payload });

    // case MutationType.Delete:
    //   return deleteEstateplanning({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyEstateplanning(
  query: Pick<
    QueryResult<
      FindManyEstateplanningQuery,
      FindManyEstateplanningQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: estateplanningMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: EstateplanningManySubscription } }
    ) =>
      estateplanningResolver({
        prev,
        payload: payload.subscriptionData.data.estateplanningMany,
      }),
    variables: query.variables,
  });
}
