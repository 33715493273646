import { Editor, Element as SlateElement, Transforms, Node } from "slate";

import { ListsEditor, ListsSchema, ListType } from "@prezly/slate-lists";
import { TextElement, Type } from "../types";

/** @description Toggle list block, this needs to be seperate as we use a list plugin */
export const toggleListBlock = ({
  editor,
  format,
}: {
  editor: Editor;
  format: ListType;
}) => {
  /* Check if list block is active */
  const isActive = isListActive({
    editor,
    format,
  });

  var current;

  if (editor.selection) {
    current = Editor.fragment(editor, editor.selection)[0] as SlateElement;
  }

  /* If not active, wrap nodes in list format. Else, remove list and wrap in P element */
  if (
    (!isActive &&
      (format === ListType.ORDERED || format === ListType.UNORDERED)) ||
    (isActive && format !== current?.type)
  ) {
    ListsEditor.unwrapList(editor);
    ListsEditor.wrapInList(editor, format);
    ListsEditor.setListType(editor, format);
  } else {
    ListsEditor.unwrapList(editor);
    Transforms.setNodes(editor, { type: "P" });
  }

  //   // RESELECT EDITOR
  //   if (editor.selection) {
  //     CustomEditor.reSelect({ editor, target: editor.selection });
  //   }
};

export const isListActive = ({
  editor,
}: {
  editor: Editor;
  format: ListType;
}) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => {
      return (
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n.type === ListType.UNORDERED || n.type === ListType.ORDERED)
      );
    },
  });

  return !!match;
};

export const schemalists: ListsSchema = {
  isConvertibleToListTextNode(node: Node) {
    return (
      SlateElement.isElementType(node, Type.P) ||
      SlateElement.isElementType(node, Type.H1) ||
      SlateElement.isElementType(node, Type.H2) ||
      SlateElement.isElementType(node, Type.H3) ||
      SlateElement.isElementType(node, Type.H4) ||
      SlateElement.isElementType(node, Type.H5) ||
      SlateElement.isElementType(node, Type.H6)
    );
  },
  isDefaultTextNode(node: Node) {
    return SlateElement.isElementType(node, Type.P);
  },
  isListNode(node: Node, type?: ListType) {
    if (type === ListType.ORDERED) {
      return SlateElement.isElementType(node, Type.ORDERED_LIST);
    }
    if (type === ListType.UNORDERED) {
      return SlateElement.isElementType(node, Type.UNORDERED_LIST);
    }
    return (
      SlateElement.isElementType(node, Type.ORDERED_LIST) ||
      SlateElement.isElementType(node, Type.UNORDERED_LIST)
    );
  },
  isListItemNode(node: Node) {
    return SlateElement.isElementType(node, Type.LIST_ITEM);
  },
  isListItemTextNode(node: Node) {
    return SlateElement.isElementType(node, Type.LIST_ITEM_TEXT);
  },
  createDefaultTextNode(props: Partial<TextElement> = {}) {
    return { children: [{ text: "" }], ...props, type: Type.P };
  },
  createListNode(
    type: ListType = ListType.UNORDERED || ListType.ORDERED,
    props: Partial<TextElement> = {}
  ) {
    const nodeType =
      type === ListType.ORDERED ? Type.ORDERED_LIST : Type.UNORDERED_LIST;
    return { children: [{ text: "" }], ...props, type: nodeType };
  },
  createListItemNode(props: Partial<TextElement> = {}) {
    return { children: [{ text: "" }], ...props, type: Type.LIST_ITEM };
  },
  createListItemTextNode(props: Partial<TextElement> = {}) {
    return { children: [{ text: "" }], ...props, type: Type.LIST_ITEM_TEXT };
  },
};
