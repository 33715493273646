import React, { Fragment, useContext, useEffect } from "react";
import { StoreContext } from "../../../Datastore/Store";
import { Transition, TransitionChild } from "@headlessui/react";
import clsx from "clsx";

export default function Snackbar({
  type,
  open,
  message,
}: {
  type: "error" | "warning" | "success";
  open: boolean;
  message: string;
}) {
  const [context, dispatch] = useContext(StoreContext);

  const handleClose = () => {
    dispatch({ type: "snackbar/reset" });
  };

  function onExited() {
    if (context.snackbar.open) {
      dispatch({ type: "snackbar/reset" });
    }
  }

  /* Task view on close function */
  useEffect(() => {
    setTimeout(function () {
      onExited();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Transition appear={true} show={open} as={Fragment}>
        <div
          aria-live="assertive"
          className="pointer-events-none h-32 fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <TransitionChild
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={clsx(
                  type === "success"
                    ? "ring-semantic-success-500"
                    : type === "error"
                    ? "ring-semantic-error-500"
                    : "ring-semantic-warning-500",
                  `ring-semantic-${type}-500`,
                  "pointer-events-auto w-full max-w-xs overflow-hidden rounded-lg bg-white shadow-lg ring-1"
                )}
              >
                <div className="p-4">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <i
                        className={clsx(
                          type === "success"
                            ? "fa-circle-check"
                            : "fa-triangle-exclamation",
                          type === "success"
                            ? "text-semantic-success-500"
                            : type === "error"
                            ? "text-semantic-error-500"
                            : "text-semantic-warning-500",
                          "h-6 w-6 icon fa-regular"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        {message}
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <i
                          className="h-5 w-5 fa-solid fa-times"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Transition>
    </div>
  );
}
