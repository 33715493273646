import { QueryResult } from "@apollo/client";
import {
  GroupsManySubscription,
  FindManyGroupsQuery,
  MutationType,
  FindManyGroupsQueryVariables,
} from "../../../codegen/schema";
import { groupsMany } from "../subscription";

interface GroupsResolverParams {
  prev: FindManyGroupsQuery;
  payload: GroupsManySubscription["groupsMany"];
}

function createGroups({ prev, payload }: GroupsResolverParams) {
  const groups = payload.data;

  return Object.assign({}, prev, {
    findManyGroups: [...prev.findManyGroups, groups],
  });
}

function updateGroups({ prev, payload }: GroupsResolverParams) {
  const groups = payload.data;

  return Object.assign({}, prev, {
    findManyGroups: prev.findManyGroups.map((group) =>
      groups.ID === group.ID
        ? {
            ...group,
            ...groups,
          }
        : group
    ),
  });
}

function deleteGroups({ prev, payload }: GroupsResolverParams) {
  const groups = payload.data;

  return Object.assign({}, prev, {
    findManyGroups: [...prev.findManyGroups].filter(
      (group) => group.ID !== groups.ID
    ),
  });
}

function groupsResolver({ prev, payload }: GroupsResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createGroups({ prev, payload });

    case MutationType.Update:
      return prev.findManyGroups.some((groups) => groups.ID === data.ID)
        ? updateGroups({ prev, payload })
        : createGroups({ prev, payload });

    case MutationType.Delete:
      return deleteGroups({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyGroups(
  query: Pick<
    QueryResult<FindManyGroupsQuery, FindManyGroupsQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: groupsMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: GroupsManySubscription } }
    ) =>
      groupsResolver({
        prev,
        payload: payload.subscriptionData.data.groupsMany,
      }),
    variables: query.variables,
  });
}
