import { gql } from "@apollo/client";
import { notesFragment } from ".";

export const findUniqueNotes = gql`
  query FindUniqueNotes($where: notesWhereUniqueInput!) {
    findUniqueNotes(where: $where) {
      ...notes
    }
  }
  ${notesFragment}
`;

export const findManyNotes = gql`
  query FindManyNotes(
    $where: notesWhereInput
    $distinct: [NotesScalarFieldEnum!]
    $orderBy: [notesOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: notesWhereUniqueInput
  ) {
    findManyNotes(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...notes
    }
  }
  ${notesFragment}
`;
