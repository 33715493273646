import { QueryResult } from "@apollo/client";
import {
  ScenarioinsuranceSubscription,
  FindUniqueScenarioQuery,
  MutationType,
  FindUniqueScenarioQueryVariables,
} from "../../../codegen/schema";
import { scenarioinsurance } from "../subscription";

interface ScenarioinsuranceResolverParams {
  prev: FindUniqueScenarioQuery;
  payload: ScenarioinsuranceSubscription["scenarioinsurance"];
}
function createScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioinsurance:
        scenarioinsurance.scenario_ID === prev.findUniqueScenario.ID
          ? [...prev.findUniqueScenario.scenarioinsurance, scenarioinsurance]
          : prev.findUniqueScenario.scenarioinsurance,
    },
  });
}

function updateScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioinsurance: prev.findUniqueScenario.scenarioinsurance.map(
        (scenarioinsurances) =>
          scenarioinsurance.ID === scenarioinsurances.ID
            ? { ...scenarioinsurances, ...scenarioinsurance }
            : scenarioinsurances
      ),
    },
  });
}

function deleteScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioinsurance: [...prev.findUniqueScenario.scenarioinsurance].filter(
        (scenarioinsurances) => scenarioinsurances.ID !== scenarioinsurance.ID
      ),
    },
  });
}

function scenarioinsuranceResolver({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioinsurance({ prev, payload });

    case MutationType.Update:
      return updateScenarioinsurance({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioinsurance({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioinsuranceFindUniqueScenario(
  query: Pick<
    QueryResult<FindUniqueScenarioQuery, FindUniqueScenarioQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioinsurance,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioinsuranceSubscription };
      }
    ) =>
      scenarioinsuranceResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioinsurance,
      }),
    variables: query.variables,
  });
}
