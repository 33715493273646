import { gql } from "@apollo/client";
import { sharesFragment } from "./fragment";

export const shares = gql`
  subscription Shares($where: sharesWhereUniqueInput) {
    shares(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...shares
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${sharesFragment}
`;

export const sharesMany = gql`
  subscription SharesMany($where: sharesWhereInput) {
    sharesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...shares
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${sharesFragment}
`;
