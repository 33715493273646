import React from "react";
import {
  Content,
  Property,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../../Functions/GeneralFunctions";
import { capGrowthCalculation } from "../../../../../../Functions/Property";
import { StyledText } from "../../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../../Functions/Owner";

interface AssetsPropertyIndividualProps {
  content?: Content;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  theme?: Theme;
}

type OffsetPlaceholderType = {
  Bank?: string;
  Amount?: number;
};

type LoanPlaceholderType = {
  ID?: number;
  liabilites_ID?: number;
  Purpose?: string;
  Bank?: string;
  CurrentLoanOutstanding?: number;
  assets?: {
    offset: OffsetPlaceholderType[];
  };
  SecuredBy?: number;
};

export type PropertyPlaceholderType = {
  assets_ID: number;
  StreetName?: string;
  MarketValue?: number;
  PurchasePrice?: number;
  Type?: string;
  liabilities: {
    loans: LoanPlaceholderType[];
  };
  history: [];
  notes: [];
  assetallocation: {};
  build: [];
  existing: [];
};

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns individual property component for advice template && document.
 */
export const AssetsPropertyIndividual: React.FC<
  AssetsPropertyIndividualProps
> = ({ content, group, theme }) => {
  

  const individualProperty: PropertyPlaceholderType | Property = group
    ? content?.asset_ID
      ? getGroupAssets(group).property.find(
          (property: Property) => content?.asset_ID === property.assets_ID
        )
      : {}
    : {
        assets_ID: 1,
        MarketValue: 1200000,
        PurchasePrice: 500000,
        Type: "Home",
        liabilities: {
          loans: [
            {
              Purpose: "Home - Offset",
              Bank: "Westpac",
              CurrentLoanOutstanding: 250000,
              assets: {
                offset: [{ Bank: "Westpac", Amount: 150000 }],
              },
              SecuredBy: 1,
            },
            {
              Purpose: "Home Big Loan",
              Bank: "Westpac",
              CurrentLoanOutstanding: 500000,
              SecuredBy: 1,
            },
          ],
        },
      };

  if (!individualProperty) return null;

  return (
    <React.Fragment>
      {/* PROPERTY DETAIL  */}
      <div className="flex gap-2 items-center">
        <div
          className="icon fa-regular fa-house margin-right fa-lg"
          style={{ color: theme?.colour[0]?.Value ?? "#000000" }}
        />
        <div className="grid gap-2 grid-cols-5 items-center">
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H5")}
              className="flex items-center "
            >
              Owner
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              className="flex items-center "
            >
              {checkFieldIsEmpty(
                group &&
                  ownerOptionsUpdateAssetDefaultValue(individualProperty, group)
                    .text
              )}
            </StyledText>
          </div>
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H5")}
              className="flex items-center "
            >
              Type
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              className="flex items-center "
            >
              {checkFieldIsEmpty(individualProperty.Type, "dollar")}
            </StyledText>
          </div>
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H5")}
              className="flex items-center "
            >
              Market Value
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              className="flex items-center "
            >
              {checkFieldIsEmpty(individualProperty.MarketValue, "dollar")}
            </StyledText>
          </div>
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H5")}
              className="flex items-center "
            >
              Purchase Price
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              className="flex items-center "
            >
              {checkFieldIsEmpty(individualProperty.PurchasePrice, "dollar")}
            </StyledText>
          </div>
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H5")}
              className="flex items-center "
            >
              Capital Growth
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              className="flex items-center "
            >
              {checkFieldIsEmpty(
                capGrowthCalculation(individualProperty),
                "dollar"
              )}
            </StyledText>
          </div>
        </div>
      </div>
      <div className="grid gap-2 grid-cols-2 mt-[1cm]">
        {individualProperty?.liabilities?.loans &&
          individualProperty.liabilities.loans.map((loan, loanIndex) => {
            if (!loan || loan.SecuredBy !== individualProperty.assets_ID)
              return null;
            return (
              <div
                className="flex gap-2 items-start rounded-lg p-2"
                style={{
                  border: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
                }}
                key={loanIndex}
              >
                <div
                  className="icon fa-regular fa-credit-card mt-1"
                  style={{ color: theme?.colour[0]?.Value ?? "#000000" }}
                />
                <div className="grid gap-2 grid-cols-3">
                  <div className="flex flex-col mb-1">
                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "H5"
                      )}
                      className="flex items-center text-xs"
                    >
                      Purpose
                    </StyledText>

                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "H6"
                      )}
                      className="flex items-center "
                    >
                      {loan.Purpose}
                    </StyledText>
                  </div>
                  <div className="flex flex-col mb-2">
                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "H5"
                      )}
                      className="flex items-center text-xs"
                    >
                      Bank
                    </StyledText>

                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "H6"
                      )}
                      className="flex items-center "
                    >
                      {loan.Bank}
                    </StyledText>
                  </div>
                  <div className="flex flex-col mb-2">
                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "H5"
                      )}
                      className="flex items-center text-xs"
                    >
                      Loan Value
                    </StyledText>

                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "H6"
                      )}
                      className="flex items-center "
                    >
                      {checkFieldIsEmpty(loan.CurrentLoanOutstanding, "dollar")}
                    </StyledText>
                  </div>

                  {/* {loan?.assets?.offset &&
                    loan.assets.offset.map((offset, index) => {
                      if (!offset) return null;
                      return (
                        <div
                          className="flex-col_a col-span-full flex gap items-center"
                          key={index}
                        >
                          <div
                            className="icon offset text-base"
                            style={{
                              color: theme?.colour[0]?.Value ?? "#000000",
                            }}
                          />
                          <div className="grid gap flex-cols-2">
                            <div className="flex flex-col">
                              <StyledText
                                element={theme?.element.find(
                                  (element) => element.Type === "H5"
                                )}
                                className="flex items-center text-xs"
                              >
                                Bank
                              </StyledText>

                              <StyledText
                                element={theme?.element.find(
                                  (element) => element.Type === "H6"
                                )}
                                className="flex items-center "
                              >
                                {offset.Bank}
                              </StyledText>
                            </div>
                            <div className="flex flex-col">
                              <StyledText
                                element={theme?.element.find(
                                  (element) => element.Type === "H5"
                                )}
                                className="flex items-center text-xs"
                              >
                                Amount
                              </StyledText>
                              <StyledText
                                element={theme?.element.find(
                                  (element) => element.Type === "H6"
                                )}
                                className="flex items-center "
                              >
                                {checkFieldIsEmpty(offset.Amount, "dollar")}
                              </StyledText>
                            </div>
                          </div>
                        </div>
                      );
                    })} */}
                </div>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};
