import { QueryResult } from "@apollo/client";
import {
  ChildSubscription,
  FindUniqueChildQuery,
  FindUniqueChildQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { child } from "../subscription";

interface ChildResolverParams {
  prev: FindUniqueChildQuery;
  payload: ChildSubscription["child"];
}

function updateChild({ prev, payload }: ChildResolverParams) {
  const child = payload.data;

  if (!prev.findUniqueChild) return prev;
  return Object.assign({}, prev, {
    findUniqueChild:
      child.ID === prev.findUniqueChild.ID
        ? {
            ...prev.findUniqueChild,
            ...child,
          }
        : prev.findUniqueChild,
  });
}

function deleteChild({ prev, payload }: ChildResolverParams) {
  const child = payload.data;

  if (!prev.findUniqueChild) return prev;
  return Object.assign({}, prev, {
    findUniqueChild:
      prev.findUniqueChild.ID === child.ID
        ? {
            ...prev.findUniqueChild,
            undefined,
          }
        : prev.findUniqueChild,
  });
}

function childResolver({ prev, payload }: ChildResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createChild(prev, payload);

    case MutationType.Update:
      return updateChild({ prev, payload });

    case MutationType.Delete:
      return deleteChild({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniqueChild(
  query: Pick<
    QueryResult<FindUniqueChildQuery, FindUniqueChildQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: child,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ChildSubscription } }
    ) =>
      childResolver({
        prev,
        payload: payload.subscriptionData.data.child
      }),
    variables: query.variables,
  });
}
