import { gql } from "@apollo/client";
import { modelFragment } from ".";
/** Model */
export const createOneModel = gql`
  mutation CreateOneModel($data: modelCreateInput!) {
    createOneModel(data: $data) {
      ...model
    }
  }
  ${modelFragment}
`;

export const updateOneModel = gql`
  mutation UpdateOneModel(
    $data: modelUpdateInput!
    $where: modelWhereUniqueInput!
  ) {
    updateOneModel(data: $data, where: $where) {
      ...model
    }
  }
  ${modelFragment}
`;

export const deleteOneModel = gql`
  mutation DeleteOneModel($where: modelWhereUniqueInput!) {
    deleteOneModel(where: $where) {
      ...model
    }
  }
  ${modelFragment}
`;
