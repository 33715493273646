import React from "react";
import {
  Content,
  Contenttemplate,
  Contenttemplate_ContentType,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../codegen/schema";

import { StyledText } from "../../StyledText";
import { checkFieldIsEmpty } from "../../../../Functions/GeneralFunctions";

import dayjs from "dayjs";

// type ClientObj = {
//   ID: number;
//   Title: string;
//   FirstName: string;
//   LastName: string;
//   PreferredName: string | null;
//   DateOfBirth: Date | string;
//   MaritalStatus: string;
//   Spouse: string;
//   Status: string;
//   employment: [
//     {
//       ID: number;
//       Employer: string;
//       Occupation: string;
//       FinishDate: Date | null;
//     }
//   ];
//   income: [
//     {
//       ID: number;
//       TaxableIncome: string;
//       FinancialYear: number;
//       SurplusIncome: number;
//       EmployerSuperannuationContributions: number
//       AdditionalConcessionalContributions: number
//     }
//   ];
//   address: [
//     {
//       ID: number;
//       Type: string;
//       AddressLine1: string;
//       AddressLine2: null;
//       Suburb: string;
//       Postcode: number;
//       Country: string;
//       MoveOutDate: Date | null;
//     }
//   ];
// };

interface ClientComponentProps {
  group?: FindUniqueGroupQuery["findUniqueGroup"] | undefined;
  theme?: Theme;
  content?: Content;
  contenttemplate?: Contenttemplate;
}

export const ClientComponent: React.FC<ClientComponentProps> = ({
  group = undefined,
  theme,
  content,
  contenttemplate,
}) => {
  var clients = group
    ? group.clients
    : [
        {
          ID: 1,
          Title: "Dr",
          FirstName: "John",
          LastName: "Smith",
          PreferredName: null,
          DateOfBirth: "1985-06-12",
          MaritalStatus: "Married",
          Spouse: "Jane Smith",
          Status: "Active",
          employment: [
            {
              ID: 1,
              Employer: "Employer company",
              Occupation: "Occupation",
              FinishDate: null,
            },
          ],
          income: [
            {
              ID: 1,
              TaxableIncome: "$140,000",
              FinancialYear: dayjs().year(),
              SurplusIncome: 10000,
              AdditionalConcessionalContributions: 10000,
              EmployerSuperannuationContributions: 10000,
            },
          ],
          address: [
            {
              ID: 1,
              Type: "Home",
              AddressLine1: "27 Smith Street",
              AddressLine2: null,
              Suburb: "Smith Suburb",
              Postcode: 1234,
              Country: "Australia",
              MoveOutDate: null,
            },
          ],
        },
        {
          ID: 2,
          Title: "Dr",
          FirstName: "Jane",
          LastName: "Smith",
          PreferredName: null,
          DateOfBirth: "1985-06-12",
          MaritalStatus: "Married",
          Spouse: "John Smith",
          Status: "Active",
          employment: [
            {
              ID: 1,
              Employer: "Employer company",
              Occupation: "Occupation",
              FinishDate: null,
            },
          ],
          income: [
            {
              ID: 1,
              TaxableIncome: "$180,000",
              FinancialYear: dayjs().year(),
              SurplusIncome: 30000,
              AdditionalConcessionalContributions: 10000,
              EmployerSuperannuationContributions: 10000,
            },
          ],
          address: [
            {
              ID: 2,
              Type: "Home",
              AddressLine1: "27 Smith Street",
              AddressLine2: null,
              Suburb: "Smith Suburb",
              Postcode: 1234,
              Country: "Australia",
              MoveOutDate: null,
            },
          ],
        },
      ];

  if (!clients) return null;

  return (
    <React.Fragment>
      <div
        className={
          "grid w-full " +
          (content?.client_ID ||
          contenttemplate?.ContentType ===
            Contenttemplate_ContentType.Individual
            ? "individual"
            : "grid grid-cols-2 gap-1")
        }
      >
        {clients
          .filter((client, index) =>
            //If content template and content type is individual render one client else render content client ID
            contenttemplate &&
            contenttemplate.ContentType ===
              Contenttemplate_ContentType.Individual
              ? index === 0
              : content && content?.client_ID
              ? client.ID === content?.client_ID
              : true
          )
          .map((client) => {
            return (
              <div className="flex flex-col divide-y divide-neutral-200" key={client.ID}>
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "H5"
                  )}
                >
                  {client.Title ? client.Title + "." : ""} {client.FirstName}{" "}
                  {client.LastName}{" "}
                  {client.PreferredName !== null &&
                  client.PreferredName !== "" ? (
                    <>({client.PreferredName})</>
                  ) : (
                    ""
                  )}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                  className="h-[30px]"
                >
                  Date of Birth: {checkFieldIsEmpty(client.DateOfBirth, "date")}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                  className="h-[60px]"
                >
                  {client.employment.length > 0 ? (
                    <>
                      Employer:{" "}
                      {client.employment.map((employment, index) => {
                        var spaceString =
                          client && index !== client.employment.length - 1
                            ? " & "
                            : "";
                        if (employment.FinishDate !== null) return null;
                        return (
                          <React.Fragment key={employment.ID}>
                            {checkFieldIsEmpty(employment.Employer)}
                            {spaceString}
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    "-"
                  )}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                  className="h-[60px]"
                >
                  {client.employment.length > 0 ? (
                    <>
                      Occupation:{" "}
                      {client.employment.map((employment, index) => {
                        var spaceString =
                          client && index !== client.employment.length - 1
                            ? " & "
                            : "";
                        if (employment.FinishDate !== null) return null;
                        return (
                          <React.Fragment key={employment.ID}>
                            {checkFieldIsEmpty(employment.Occupation)}
                            {spaceString}
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    "-"
                  )}
                </StyledText>
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                  className="h-[30px]"
                >
                  {[...client.income].length > 0 ? (
                    <>
                      Income:{" "}
                      {[...client.income]
                        .sort((a, b) => b.FinancialYear! - a.FinancialYear!)
                        .map((income, index) => {
                          return (
                            index === 0 && (
                              <React.Fragment key={income.ID}>
                                {checkFieldIsEmpty(
                                  income.TaxableIncome,
                                  "dollar"
                                )}
                                {/* <StyledText
                              element={theme?.element.find(
                                (element) => element.Type === "P"
                              )}
                            >
                              {checkFieldIsEmpty("Superannuation Salary")}
                            </StyledText>

                            <StyledText
                              element={theme?.element.find(
                                (element) => element.Type === "P"
                              )}
                            >
                              {checkFieldIsEmpty("Concessional Rate")}
                            </StyledText>

                            <StyledText
                              element={theme?.element.find(
                                (element) => element.Type === "P"
                              )}
                            >
                              {checkFieldIsEmpty("Standard Contributions")}
                            </StyledText> */}

                                {/* <StyledText
                              element={theme?.element.find(
                                (element) => element.Type === "P"
                              )}
                            >
                              {checkFieldIsEmpty(income.EmployerSuperannuationContributions, "dollar")}
                            </StyledText>

                            <StyledText
                              element={theme?.element.find(
                                (element) => element.Type === "P"
                              )}
                            >
                              {checkFieldIsEmpty(income.AdditionalConcessionalContributions, "dollar")}
                            </StyledText>

                            <StyledText
                              element={theme?.element.find(
                                (element) => element.Type === "P"
                              )}
                            >
                              {checkFieldIsEmpty(income.SurplusIncome, "dollar")}
                            </StyledText> */}
                              </React.Fragment>
                            )
                          );
                        })}
                    </>
                  ) : (
                    "-"
                  )}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                  className="h-[30px]"
                >
                  Marital Status:
                  {checkFieldIsEmpty(client.MaritalStatus)}
                </StyledText>
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                  className="h-[30px]"
                >
                  Spouse:
                  {checkFieldIsEmpty(client.Spouse)}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                  className="h-[60px]"
                >
                  {client.address.length > 0 ? (
                    <>
                      {" "}
                      Address:{" "}
                      {client.address.map((address) => {
                        if (
                          address.MoveOutDate === null &&
                          address.Type === "Home"
                        ) {
                          return (
                            <React.Fragment key={address.ID}>
                              {checkFieldIsEmpty(address.AddressLine1)}{" "}
                              {checkFieldIsEmpty(address.AddressLine2)} <br />
                              {checkFieldIsEmpty(address.Suburb)}{" "}
                              {checkFieldIsEmpty(address.Postcode)}{" "}
                            </React.Fragment>
                          );
                        } else return null;
                      })}
                    </>
                  ) : (
                    "-"
                  )}
                </StyledText>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};
