import { gql } from "@apollo/client";
import { notesFragment } from "./fragment";

export const notes = gql`
  subscription Notes($where: notesWhereUniqueInput) {
    notes(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...notes
      }
      child_has_notes {
        child_ID
        notes_ID
      }
      opportunity_has_notes {
        opportunity_ID
        notes_ID
      }
      project_has_notes {
        project_ID
        notes_ID
      }
      review_has_notes {
        review_ID
        notes_ID
      }
      tasks_has_notes {
        tasks_ID
        notes_ID
      }
      address_has_notes {
        address_ID
        notes_ID
      }
      assets_has_notes {
        assets_ID
        notes_ID
      }
      clients_has_notes {
        clients_ID
        notes_ID
      }
      employment_has_notes {
        employment_ID
        notes_ID
      }
      estateplanning_has_notes {
        estateplanning_ID
        notes_ID
      }
      fees_has_notes {
        fees_ID
        notes_ID
      }
      goals_has_notes {
        goals_ID
        notes_ID
      }
      income_has_notes {
        income_ID
        notes_ID
      }
      insurance_has_notes {
        insurance_ID
        notes_ID
      }
      liabilities_has_notes {
        liabilities_ID
        notes_ID
      }
      entities_has_notes {
        entities_ID
        notes_ID
      }
      salarypackaging_has_notes {
        salarypackaging_ID
        notes_ID
      }
      advice_has_notes {
        advice_ID
        notes_ID
      }
      model_has_notes {
        model_ID
        notes_ID
      }
      groups_has_notes {
        groups_ID
        notes_ID
      }
    }
  }
  ${notesFragment}
`;

export const notesMany = gql`
  subscription NotesMany($where: notesWhereInput) {
    notesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...notes
      }
      child_has_notes {
        child_ID
        notes_ID
      }
      opportunity_has_notes {
        opportunity_ID
        notes_ID
      }
      project_has_notes {
        project_ID
        notes_ID
      }
      review_has_notes {
        review_ID
        notes_ID
      }
      tasks_has_notes {
        tasks_ID
        notes_ID
      }
      address_has_notes {
        address_ID
        notes_ID
      }
      assets_has_notes {
        assets_ID
        notes_ID
      }
      clients_has_notes {
        clients_ID
        notes_ID
      }
      employment_has_notes {
        employment_ID
        notes_ID
      }
      estateplanning_has_notes {
        estateplanning_ID
        notes_ID
      }
      fees_has_notes {
        fees_ID
        notes_ID
      }
      goals_has_notes {
        goals_ID
        notes_ID
      }
      income_has_notes {
        income_ID
        notes_ID
      }
      insurance_has_notes {
        insurance_ID
        notes_ID
      }
      liabilities_has_notes {
        liabilities_ID
        notes_ID
      }
      entities_has_notes {
        entities_ID
        notes_ID
      }
      salarypackaging_has_notes {
        salarypackaging_ID
        notes_ID
      }
      advice_has_notes {
        advice_ID
        notes_ID
      }
      model_has_notes {
        model_ID
        notes_ID
      }
      groups_has_notes {
        groups_ID
        notes_ID
      }
    }
  }
  ${notesFragment}
`;
