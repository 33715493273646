import { YjsEditor } from "@slate-yjs/core";
import { Editor, Transforms } from "slate";
import { MentionElement } from "../types";
import { RenderElementProps, useFocused, useSelected } from "slate-react";
import {
  FindManyStaffQuery,
  FindUniqueAdviceQuery,
  FindUniqueFeesettingsQuery,
  FindUniqueGroupQuery,
  FindUniqueOrganisationQuery,
  Staff,
  Theme,
  useFindManyStaffQuery,
  useFindUniqueFeesettingsQuery,
  useFindUniqueOrganisationQuery,
} from "../../../../codegen/schema";
import React from "react";
import {
  checkFieldIsEmpty,
  getSelectedStaff,
} from "../../../../Functions/GeneralFunctions";
import { netIncomeCalculation } from "../../../../Functions/Income";
import { AdviceTotalsCalculation } from "../../../Advice/Models/Utils/Calculations/General";
import {
  adviceAgreementTotalCost,
  adviceAgreementTotalCostGST,
} from "../../../../Pages/AdviceAgreement/CalculationFunctions";

export const insertMention = ({
  editor,
  mentionstring,
}: {
  editor: Editor & YjsEditor;
  mentionstring: string;
}) => {
  const mention: MentionElement = {
    type: "mention",
    mention: mentionstring,
    children: [
      {
        text: mentionstring,
        ...Editor.marks(editor),
      },
    ],
  };

  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export function withMentions(editor: Editor & YjsEditor): Editor & YjsEditor {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "mention" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "mention" ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === "mention" || markableVoid(element);
  };

  return editor;
}

/* MENTIONS */

export interface MentionProps extends RenderElementProps {
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
  element: MentionElement;
}

export const Mention = ({
  advice,
  group,
  theme,
  element,
  ...props
}: MentionProps) => {
  /* Mutations */
  const { data: { findUniqueOrganisation: organisation } = {} } =
    useFindUniqueOrganisationQuery();
  const { data: { findUniqueFeesettings: feesettings } = {} } =
    useFindUniqueFeesettingsQuery();
  const {
    data: { findManyStaff: allStaff } = {},
    error: allStaffError,
    loading: allStaffLoading,
  } = useFindManyStaffQuery();

  const selected = useSelected();
  const focused = useFocused();

  const style: React.CSSProperties = {
    padding: "1px 1px 1px",
    margin: "3px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    boxShadow: selected && focused ? "0 0 0 2px #E7B5FE" : "none",
    fontFamily: element.children[0].fontFamily || "inherit",
    fontSize: element.children[0].fontSize + "pt" || "inherit",
    fontWeight: element.children[0].fontWeight || "inherit",
    color: element.children[0].color || "inherit",
    backgroundColor: !group
      ? "#E9E7ED"
      : element.children[0].backgroundColor
      ? element.children[0].backgroundColor
      : "transparent",
    letterSpacing: element.children[0].letterSpacing || "inherit",
  };

  if (!allStaff && allStaffLoading) {
    return null;
  }

  if (allStaffError && !allStaffLoading) {
    return null;
  }

  return (
    <React.Fragment>
      <span
        {...props.attributes}
        contentEditable={false}
        data-cy={`mention-${element.mention?.replace(" ", "-")}`}
        style={style}
      >
        {element.mention &&
          getMentionString({
            mentionString: element.mention,
            advice,
            group,
            organisation,
            allStaff,
            feesettings,
          })}
      </span>
      {props.children}
    </React.Fragment>
  );
};

export interface GetMentionStringProps {
  mentionString: string;
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  organisation?: FindUniqueOrganisationQuery["findUniqueOrganisation"];
  allStaff?: FindManyStaffQuery["findManyStaff"];
  feesettings: FindUniqueFeesettingsQuery["findUniqueFeesettings"];
}

export const getMentionString = ({
  mentionString,
  advice,
  group,
  organisation,
  allStaff,
  feesettings,
}: GetMentionStringProps) => {
  var primaryAdviser: Staff =
    advice && getSelectedStaff(advice?.PrimaryAdviser, "object", allStaff);

  return !group || !advice
    ? mentionString
    : /* CLIENTS */
    mentionString?.includes("Clients")
    ? group.clients
        .filter((client) => client.Status !== "Archived")
        .reduce<string>((accumulator, client, index) => {
          var andSymbol =
            index <
            group.clients.filter((client) => client.Status !== "Archived")
              .length -
              1
              ? " & "
              : " ";

          var mostRecentIncomeObject = client.income.find(
            (income) =>
              Math.max(
                ...client.income.map((entry) => entry.FinancialYear ?? 0)
              ) === income.FinancialYear
          );

          return (accumulator +=
            mentionString === "Clients title & full names"
              ? client.Title +
                " " +
                client.FirstName +
                " " +
                client.LastName +
                andSymbol
              : mentionString === "Clients full names"
              ? client.FirstName + " " + client.LastName + andSymbol
              : mentionString === "Clients first names"
              ? client.FirstName + andSymbol
              : mentionString === "Clients net income most recent year" &&
                mostRecentIncomeObject
              ? client.FirstName +
                " " +
                client.LastName +
                " " +
                checkFieldIsEmpty(
                  netIncomeCalculation(mostRecentIncomeObject),
                  "dollar"
                ) +
                " (" +
                mostRecentIncomeObject.FinancialYear +
                ") " +
                andSymbol
              : "");
        }, "")
    : /* DOCUMENT DATE */
    mentionString === "Document date"
    ? checkFieldIsEmpty(advice.Date, "date")
    : /* NET WORTH */
    mentionString === "Group net worth"
    ? checkFieldIsEmpty(
        AdviceTotalsCalculation({ group }).totals.netWealth,
        "dollar"
      )
    : /* ADVICE AGREEMENT */
    mentionString.includes("Advice agreement") &&
      group.adviceagreement.filter(
        (agreement) => agreement.advice_ID === advice.ID
      )
    ? group.adviceagreement
        .filter((adviceagreement) => adviceagreement.advice_ID === advice.ID)
        .map((adviceagreement) => {
          if (!feesettings) return null;
          return mentionString ===
            "Advice agreement cost excl. GST" /* Advice agreement cost exluding GST */
            ? checkFieldIsEmpty(
                adviceAgreementTotalCost(adviceagreement, feesettings),
                "dollar"
              )
            : mentionString ===
              "Advice agreement cost GST" /* Advice agreement cost ONLY GST */
            ? checkFieldIsEmpty(
                adviceAgreementTotalCostGST(adviceagreement, feesettings),
                "dollar"
              )
            : /* Default is advice agreement cost including GST */
              checkFieldIsEmpty(
                adviceAgreementTotalCost(adviceagreement, feesettings) +
                  adviceAgreementTotalCostGST(adviceagreement, feesettings),
                "dollar"
              );
        })
    : /* ADVISER */
    mentionString.includes("Adviser")
    ? primaryAdviser
      ? mentionString === "Adviser title & full name"
        ? primaryAdviser.Title +
          " " +
          primaryAdviser.FirstName +
          " " +
          primaryAdviser.LastName
        : mentionString === "Adviser full name"
        ? primaryAdviser.FirstName + " " + primaryAdviser.LastName
        : mentionString === "Adviser first name"
        ? primaryAdviser.FirstName
        : mentionString === "Adviser post nominals"
        ? primaryAdviser.PostNominals
        : mentionString === "Adviser ASIC number"
        ? primaryAdviser.ASICNumber
        : mentionString === "Adviser credit licensee"
        ? primaryAdviser.CreditLicensee
        : mentionString === "Adviser credit licencee number"
        ? primaryAdviser.CreditLicenceeNumber
        : mentionString === "Adviser credit licence number"
        ? primaryAdviser.CreditLicenceNumber
        : mentionString === "Adviser FSG hyperlink"
        ? primaryAdviser.FSGHyperlink
        : mentionString === "Adviser FSG date"
        ? checkFieldIsEmpty(primaryAdviser.FSGCreatedDate, "date")
        : mentionString === "Adviser other licensing information"
        ? primaryAdviser.OtherLicensingInformation
        : ""
      : "No primary adviser selected"
    : /* ORGANISATION */
    mentionString.includes("Organisation") && organisation
    ? mentionString === "Organisation practice name"
      ? organisation.PracticeName
      : mentionString ===
        "Organisation corporate authorised representative name"
      ? organisation.CorporateAuthorisedRepresentativeName
      : mentionString ===
        "Organisation corporate authorised representative number"
      ? organisation.CorporateAuthorisedRepresentativeNumber
      : mentionString === "Organisation licensee name"
      ? organisation.LicenseeName
      : mentionString === "Organisation ABN"
      ? organisation.ABN
      : mentionString === "Organisation AFSL"
      ? organisation.AFSL
      : mentionString === "Organisation phone number"
      ? organisation.PhoneNumber
      : mentionString === "Organisation email"
      ? organisation.Email
      : mentionString === "Organisation website"
      ? organisation.Website
      : mentionString === "Organisation address"
      ? organisation.Address
      : ""
    : mentionString;
};
