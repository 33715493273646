import React from "react";
import {
  Theme,
  useFindUniqueFeesettingsQuery,
} from "../../../../../../../../../../codegen/schema";
import { StyledText } from "../../../../../../../../../../Components/Advice/StyledText";
import { ComponentLoader } from "../../../../../../../../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../../../../../../../../Components/Refetch";

interface Props {
  theme?: Theme;
}

/**
 * @created 21-06-2023
 * @updated -
 * @returns Returns advice agreement services component for advice template && document.
 */
export const AdviceAgreementService: React.FC<Props> = ({ theme }) => {
  const {
    data: { findUniqueFeesettings: feesettings } = {},
    error: feesettingsError,
    loading: feesettingsLoading,
    refetch: feesettingsRefetch,
  } = useFindUniqueFeesettingsQuery();

  var service = feesettings?.servicetypes[0];

  if (!feesettings && feesettingsLoading) {
    return <ComponentLoader />;
  }

  if (feesettingsError && !feesettingsLoading) {
    return (
      <Refetch
        refetch={() => {
          feesettingsRefetch();
        }}
      />
    );
  }

  if (!service) return null;

  return (
    <React.Fragment>
      <div className="w-full">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
        >
          {service.Name}
        </StyledText>

        {service.organisationservices.map((service) => {
          return (
            <div
              className="grid grid-cols-[80%,20%]"
              key={service.ID}
              style={{
                borderBottom:
                  "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
              }}
            >
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H5"
                )}
                
              >
                {service.Name}
              </StyledText>

              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H3"
                )}
                className="flex items-center justify-center"
              >
                <i
                  className="icon fa-solid fa-check text-base"
                  style={{
                    color: theme?.colour[0]?.Value ?? "#000000",
                  }}
                />
              </StyledText>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
