import { roleFindUniqueTeam } from "../../role";
import { teamFindUniqueTeam } from "..";
import { QueryResult } from "@apollo/client";
import {
  FindUniqueTeamQuery,
  FindUniqueTeamQueryVariables,
} from "../../../codegen/schema";

export default function findUniqueTeamSubscription(
  query: Pick<
    QueryResult<FindUniqueTeamQuery, FindUniqueTeamQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  teamFindUniqueTeam(query);
  roleFindUniqueTeam(query);
}
