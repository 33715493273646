import { QueryResult } from "@apollo/client";
import {
  ScenarioinsuranceSubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { scenarioinsurance } from "../subscription";

interface ScenarioinsuranceResolverParams {
  prev: FindUniqueModelQuery;
  payload: ScenarioinsuranceSubscription["scenarioinsurance"];
}

function createScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const { data: scenarioinsurance, model_has_scenarioinsurance } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioinsurance: model_has_scenarioinsurance.some(
        (relation) =>
          relation.model_ID === prev.findUniqueModel?.ID &&
          relation.scenarioinsurance_ID === scenarioinsurance.ID
      )
        ? [...prev.findUniqueModel.scenarioinsurance, scenarioinsurance]
        : prev.findUniqueModel.scenarioinsurance,
    },
  });
}

function updateScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const { data: scenarioinsurance, model_has_scenarioinsurance } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      /** If scenarioinsurance already in model.scenarioinsurance, update, else add to array if
       * model_has_scenarioinsurance */
      scenarioinsurance: prev.findUniqueModel.scenarioinsurance.some(
        (scenarioinsurances) => scenarioinsurances.ID === scenarioinsurance.ID
      )
        ? prev.findUniqueModel.scenarioinsurance.map((scenarioinsurances) =>
            scenarioinsurances.ID === scenarioinsurance.ID
              ? { ...scenarioinsurances, ...scenarioinsurance }
              : scenarioinsurances
          )
        : model_has_scenarioinsurance.some(
            (relation) =>
              relation.model_ID === prev.findUniqueModel?.ID &&
              relation.scenarioinsurance_ID === scenarioinsurance.ID
          )
        ? [...prev.findUniqueModel.scenarioinsurance, scenarioinsurance]
        : prev.findUniqueModel.scenarioinsurance,
    },
  });
}

function deleteScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioinsurance: [...prev.findUniqueModel.scenarioinsurance].filter(
        (scenarioinsurances) => scenarioinsurances.ID !== scenarioinsurance.ID
      ),
    },
  });
}

function scenarioinsuranceResolver({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioinsurance({ prev, payload });

    case MutationType.Update:
      return updateScenarioinsurance({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioinsurance({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioinsuranceFindUniqueModel(
  query: Pick<
    QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioinsurance,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioinsuranceSubscription };
      }
    ) =>
      scenarioinsuranceResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioinsurance,
      }),
    variables: query.variables,
  });
}
