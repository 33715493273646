import { gql } from "@apollo/client";
import { roleFragment } from "./fragment";

export const role = gql`
  subscription Role($where: roleWhereUniqueInput) {
    role(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...role
      }
    }
  }
  ${roleFragment}
`;

export const roleMany = gql`
  subscription RoleMany($where: roleWhereInput) {
    roleMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...role
      }
    }
  }
  ${roleFragment}
`;
