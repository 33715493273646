import { QueryResult } from "@apollo/client";
import {
  PropertySubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { property } from "../subscription";

interface PropertyResolverParams {
  prev: FindUniqueGroupQuery;
  payload: PropertySubscription["property"];
}

function createProperty({ prev, payload }: PropertyResolverParams) {
  const { data: createProperty, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            property: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.property, createProperty]
              : client.assets.property,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            property: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.property, createProperty]
              : entity.assets.property,
          },
        };
      }),
    },
  });
}

function updateProperty({ prev, payload }: PropertyResolverParams) {
  const { data: updateProperty, clients_has_assets, entities_has_assets } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the property object and merge together.
   * This is because updateProperty only returns the property info
   * without assetallocation, notes, etc.
   */
  var updatedProperty = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.property.forEach((property) => {
      if (property.assets_ID === updateProperty.assets_ID) {
        result = {
          ...property,
          ...updateProperty,
        };
      }
    });
    return result;
  }, updateProperty);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update property first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          property: [
            ...client.assets.property
              .map((property) =>
                property.ID === updatedProperty.ID
                  ? { ...property, ...updatedProperty }
                  : property
              )
              .filter(
                (property) =>
                  ![...clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(property.assets_ID)
              ),
            ...(clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? clients_has_assets.reduce<
                  Array<PropertySubscription["property"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedProperty.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedProperty];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    property: liability.assets.property.map(
                      (property) =>
                        property.ID === updateProperty.ID
                          ? {
                              ...property,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateProperty,
                            }
                          : property /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            property: goal.assets.property.map((property) =>
              property.ID === updatedProperty.ID
                ? { ...property, ...updatedProperty }
                : property
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      property: liability.assets.property.map((property) =>
                        property.ID === updateProperty.ID
                          ? {
                              ...property,
                              /** Update loan info */
                              ...updateProperty,
                            }
                          : property
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing property object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          property: [
            ...entity.assets.property
              .map((property) =>
                property.ID === updatedProperty.ID
                  ? { ...property, ...updatedProperty }
                  : property
              )
              .filter(
                (property) =>
                  ![...entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(property.assets_ID)
              ),
            ...(entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? entities_has_assets.reduce<
                  Array<PropertySubscription["property"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedProperty.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedProperty];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    property: liability.assets.property.map(
                      (property) =>
                        property.ID === updateProperty.ID
                          ? {
                              ...property,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateProperty,
                            }
                          : property /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteProperty({ prev, payload }: PropertyResolverParams) {
  const { data: deleteProperty } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            property: [...client.assets.property].filter(
              (property) => property.ID !== deleteProperty.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      property: liability.assets.property.filter(
                        (property) => property.ID !== deleteProperty.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              property: goal.assets.property.filter(
                (property) => property.ID !== deleteProperty.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        property: liability.assets.property.filter(
                          (property) => property.ID !== deleteProperty.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            property: [...entity.assets.property].filter(
              (property) => property.ID !== deleteProperty.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      property: liability.assets.property.filter(
                        (property) => property.ID !== deleteProperty.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function propertyResolver({ prev, payload }: PropertyResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createProperty({ prev, payload });

    case MutationType.Update:
      return updateProperty({ prev, payload });

    case MutationType.Delete:
      return deleteProperty({ prev, payload });

    default:
      return prev;
  }
}

export function propertyFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: property,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: PropertySubscription } }
    ) =>
      propertyResolver({
        prev,
        payload: payload.subscriptionData.data.property
      }),
    variables: query.variables,
  });
}
