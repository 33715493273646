import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../../../../../Datastore/Store";
import { LiveData, ScenarioData } from "../../../Calculations";

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  scenarioData: ScenarioData[] | undefined;
  liveData?: LiveData[] | undefined;
}

export const PropertyValueScenarioModelGraph: React.FC<Props> = ({
  scenarioData,
  liveData,
}) => {
  const [context] = useContext(StoreContext);

  const [data, setData] = useState<ScenarioData[]>([]);

  useEffect(() => {
    /* Need to combine the live and scenario data arrays. Live data will have historicalPropertiesValue, historicalLoansValue and year values.
    /* These need to be combinded with the scenario data array. 
    /* Check if duplicate years exist, if so add liveData object to scenarioData entry, else, create a new entry
    /* where scenario data values are 0 or undefined. */
    if (scenarioData && liveData) {
      var combinedData = [...scenarioData];

      liveData.forEach((liveEntry) => {
        var prevEntry = combinedData.find(
          (entry) => entry.year === liveEntry.year
        );

        if (prevEntry) {
          combinedData = combinedData.map((entry) =>
            entry.year === liveEntry.year
              ? {
                  ...entry,
                  historicalPropertyValue: liveEntry.historicalPropertyValue,
                  historicalLoanValue: liveEntry.historicalLoanValue,
                }
              : entry
          );
        } else {
          combinedData.push({
            ...liveEntry,
            contributions: 0,
            interest: 0,
            netEquity: undefined,
            offsets: 0,
            projectedValue: undefined,
            repayments: 0,
            withdrawals: 0,
            loans: undefined,
            loanContributions: 0,
            loanWithdrawals: 0,
            offsetContributions: 0,
            offsetWithdrawals: 0,
          });
        }
      });
      setData(combinedData.sort((a, b) => a.year - b.year));
    }
  }, [scenarioData, liveData]);

  if (!scenarioData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />
        <XAxis
          interval={data.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            offset={-30}
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />
        <ReferenceLine y={0} stroke="#000" />

        <Bar
          dataKey={"contributions"}
          fill={context.colours.contributions}
          stackId={1}
          isAnimationActive={false}
          maxBarSize={50}
        />

        <Bar
          dataKey={"withdrawals"}
          fill={context.colours.withdrawals}
          stackId={1}
          isAnimationActive={false}
          maxBarSize={50}
        />

        <Bar
          dataKey={"offsets"}
          fill={context.colours.blue}
          stackId={1}
          isAnimationActive={false}
          maxBarSize={50}
        />

        <Bar
          dataKey={"repayments"}
          fill={context.colours.repayments}
          stackId={1}
          isAnimationActive={false}
          maxBarSize={50}
        />

        <Bar
          dataKey={"loans"}
          fill={context.colours.blue200}
          stackId={1}
          isAnimationActive={false}
          maxBarSize={50}
        />

        <Bar
          dataKey={"interest"}
          fill={context.colours.lightPurple300}
          stackId={1}
          isAnimationActive={false}
          maxBarSize={50}
        />

        <Line
          type="monotone"
          dataKey={"projectedValue"}
          stroke={context.colours.blue}
          isAnimationActive={false}
        />

        <Line
          type="monotone"
          dataKey={"netEquity"}
          stroke={context.colours.green}
          isAnimationActive={false}
        />

        {!data.every((entry) => !entry.target || entry.target === 0) && (
          <Line
            type="monotone"
            dataKey={"target"}
            stroke={context.colours.target}
            isAnimationActive={false}
            dot={{
              strokeWidth: 1,
              r: 4,
            }}
          />
        )}

        {liveData && liveData.length > 0 && (
          <React.Fragment>
            <Line
              type="monotone"
              dataKey={"historicalPropertyValue"}
              stroke={context.colours.lightPurple300}
              isAnimationActive={false}
              strokeWidth={2}
              connectNulls={true}
            />

            <Line
              type="monotone"
              dataKey={"historicalLoanValue"}
              stroke={context.colours.orange}
              isAnimationActive={false}
              connectNulls={true}
            />
          </React.Fragment>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
