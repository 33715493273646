import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Range,
  Text,
  Transforms,
} from "slate";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from "slate-react";
import {
  withYHistory,
  withYjs,
  YjsEditor,
  withCursors,
  yTextToSlateElement,
} from "@slate-yjs/core";
import {
  getRemoteCaretsOnLeaf,
  getRemoteCursorsOnLeaf,
  useDecorateRemoteCursors,
} from "@slate-yjs/react";
import {
  withListsReact,
  onKeyDown as onKeyDownSlateLists,
  withLists,
  ListType,
} from "@prezly/slate-lists";
import * as Y from "yjs";
import { WebsocketProvider } from "../Yjs";

import {
  FindUniqueAdviceQuery,
  FindUniqueGroupQuery,
  Theme,
  useFindCurrentStaffQuery,
} from "../../../codegen/schema";
import { StoreContext } from "../../../Datastore/Store";

import { CustomEditor, Leaf, RenderElement, withNormalize } from "../Functions";
import { CursorData } from "../Types";
import { addAlpha, cursorData } from "../Yjs/Utils";
import { ErrorBoundary } from "../ErrorBoundary";
import { CustomElement, CustomText } from "../Functions/types";
import { withInlines } from "../Functions/links";
import { insertMention, withMentions } from "../Functions/mentions";
// import { HoveringToolbar } from "../../../Pages/TasksModule/Utlis/SlateDescription/HoveringToolbar";
import { toggleMark } from "../Functions/marks";
import { schemalists } from "../Functions/lists";
import { createPortal } from "react-dom";
import clsx from "clsx";

const MENTIONS = [
  "Clients title & full names",
  "Clients full names",
  "Clients first names",
  "Clients net income most recent year",
  "Document date",
  "Group net worth",
  "Adviser title & full name",
  "Adviser full name",
  "Adviser first name",
  "Adviser post nominals",
  "Adviser ASIC number",
  "Adviser credit licensee",
  "Adviser credit licence number",
  "Adviser FSG hyperlink",
  "Adviser FSG date",
  "Adviser other licensing information",
  "Advice agreement cost inc. GST",
  "Advice agreement cost excl. GST",
  "Advice agreement cost GST",
  "Organisation practice name",
  "Organisation corporate authorised representative name",
  "Organisation corporate authorised representative number",
  "Organisation licensee name",
  "Organisation ABN",
  "Organisation AFSL",
  "Organisation phone number",
  "Organisation email",
  "Organisation website",
  "Organisation address",
];

interface CustomEditableProps {
  id: string;
  editableKey: React.Key | null | undefined;
  divStyle?: React.CSSProperties;
  editableStyle?: React.CSSProperties;
  setActiveEditor: React.Dispatch<React.SetStateAction<Editor | undefined>>;
  theme?: Theme;
  __typename?: string;
  typeID?: number;
  yjsProvider: WebsocketProvider;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
  disabled?: boolean;
}

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export const CustomEditable = ({
  id,
  editableKey,
  divStyle,
  editableStyle,
  setActiveEditor,
  theme,
  __typename,
  typeID,
  yjsProvider,
  group,
  advice,
  disabled = false,
}: CustomEditableProps) => {
  const [context] = useContext(StoreContext);
  const { data: { findCurrentStaff: staff } = {} } = useFindCurrentStaffQuery();

  const initialValue = [
    {
      type: "P",
      children: [{ text: "" }],
    },
  ];

  const [value, setValue] = useState<Descendant[]>(initialValue);

  const [editor, setEditor] = useState<
    (BaseEditor & ReactEditor & YjsEditor) | undefined
  >(undefined);

  const [subdocument, setSubdocument] = useState<Y.Doc | undefined>(undefined);

  // When subdocs are loaded, setSubdocument which will then set the editor
  // This is specifically for when adding subdocs because an update to the yjsprovider
  // does not trigger a re render so this is how we render the editor.
  useEffect(() => {
    yjsProvider.doc.on("subdocs", ({ loaded, added, removed }) => {
      loaded.forEach((subdoc: Y.Doc) => {
        if (
          !subdocument &&
          subdoc.guid === `yjs?__typename=${__typename}&ID=${typeID}`
        ) {
          // console.log("Loaded: ", subdoc.guid);
          setSubdocument(subdoc);
        }
      });
      removed.forEach((subdoc: Y.Doc) => {
        if (
          !subdocument &&
          subdoc.guid === `yjs?__typename=${__typename}&ID=${typeID}`
        ) {
          console.log("Removed: ", subdoc.guid);
          editor?.deselect();
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set subdocument on initial load so the editor gets rendered.
  // Does not work with just the useEffect above for initial load.
  useEffect(() => {
    if (!subdocument) {
      const subdocs = yjsProvider.doc.getMap<Y.Doc>("subdocuments");
      const subdoc = subdocs.get(`yjs?__typename=${__typename}&ID=${typeID}`);

      if (subdoc) setSubdocument(subdoc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setup the editor
  useEffect(() => {
    if (subdocument) {
      const sharedType = subdocument.get("text", Y.XmlText) as Y.XmlText;
      const slateInitialValue = yTextToSlateElement(sharedType);

      const e: BaseEditor & ReactEditor & YjsEditor = withListsReact(
        withLists(schemalists)(
          withInlines(
            withMentions(
              withNormalize(
                withReact(
                  withCursors(
                    withYHistory(withYjs(createEditor(), sharedType)),
                    yjsProvider.awareness,
                    {
                      data: cursorData({ staff: staff }),
                    }
                  )
                ),
                slateInitialValue
              )
            )
          )
        )
      );

      setEditor(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdocument]);

  // Connect YjsEditor to the editor.
  useEffect(() => {
    if (editor) {
      YjsEditor.connect(editor);
      return () => YjsEditor.disconnect(editor);
    }
  }, [editor]);

  // Define a rendering function based on the element passed to `props`. We use
  // `useCallback` here to memoize the function for subsequent renders.
  const renderElement = useCallback(
    (props: RenderElementProps) => {
      return (
        <RenderElement {...props} theme={theme} group={group} advice={advice} />
      );
    },
    [advice, group, theme]
  );

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback((props: RenderLeafProps) => {
    getRemoteCursorsOnLeaf<CursorData, Text>(props.leaf).forEach((cursor) => {
      if (cursor.data) {
        cursor.data.color = context.colours.blue;
        props.children = (
          <span style={{ backgroundColor: addAlpha(cursor.data.color, 0.5) }}>
            {props.children}
          </span>
        );
      }
    });

    getRemoteCaretsOnLeaf<CursorData, Text>(props.leaf).forEach((caret) => {
      if (caret.data) {
        // const colourArray = Object.entries(context.colours)
        // caret.data.color = colourArray[
        //   Math.floor(Math.random() * colourArray.length)
        // ] as string;
        caret.data.color = context.colours.blue;

        props.children = (
          <span className="relative">
            <span contentEditable={false} className="yjs-cursor" />
            <span contentEditable={false} className="yjs-caret">
              {caret.data.name}
            </span>
            {props.children}
          </span>
        );
      }
    });

    return <Leaf {...props} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const container = useRef<HTMLDivElement | null>(null);
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");

  const mentions = MENTIONS.filter((c) =>
    c.toLowerCase().includes(search.toLowerCase())
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (
        editor &&
        event.key === "Tab" &&
        !CustomEditor.checkElementType(editor, ListType.ORDERED) &&
        !CustomEditor.checkElementType(editor, ListType.UNORDERED)
      ) {
        event.preventDefault();
        Editor.insertText(editor, "\u2003".toString());
      }
      if (editor && target && mentions.length > 0) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            const prevIndex = index >= mentions.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            const nextIndex = index <= 0 ? mentions.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention({
              editor,
              mentionstring: mentions[index],
            });
            setTarget(undefined);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(undefined);
            break;
        }
      }
    },
    [mentions, editor, index, target]
  );

  useEffect(() => {
    if (editor && target && mentions.length > 0) {
      const el = container.current;
      if (el) {
        const domRange = ReactEditor.toDOMRange(editor, target);
        const rect = domRange.getBoundingClientRect();
        el.style.top = `${rect.top + 25}px`;
        el.style.left = `${rect.left + window.pageXOffset}px`;
      }
    }
  }, [mentions.length, editor, index, search, target, container]);

  const EditableWithCursors = () => {
    const decorate = useDecorateRemoteCursors();
    return (
      // <RemoteCursorOverlay>
      <Editable
        scrollSelectionIntoView={() => undefined}
        placeholder={"Click here to add text..."}
        // Custom render is top stop text rendering vertically on awareness for some reason
        renderPlaceholder={({ attributes, children }) => {
          return (
            <span
              {...attributes}
              style={{
                opacity: 0.5,
                height: 0,
                width: "400px",
                position: "absolute",
                top: 0,
                zIndex: 0,
              }}
            >
              {children}
            </span>
          );
        }}
        id={id}
        key={editableKey}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        style={editableStyle}
        decorate={decorate}
        onDOMBeforeInput={(event: InputEvent) => {
          if (editor) {
            switch (event.inputType) {
              case "formatBold":
                event.preventDefault();
                return toggleMark({ editor: editor, format: "bold" });
              case "formatItalic":
                event.preventDefault();
                return toggleMark({ editor: editor, format: "italic" });
              case "formatUnderline":
                event.preventDefault();
                return toggleMark({ editor: editor, format: "underline" });
            }
          }
        }}
        onKeyDown={(event) => {
          onKeyDown(event);
          editor && onKeyDownSlateLists(editor, event);

          if (!event.ctrlKey) {
            return;
          }
        }}
        onError={(error) => console.log(error)}
        readOnly={disabled}
      />
      // </RemoteCursorOverlay>
    );
  };

  if (!editor) return null;

  return (
    <ErrorBoundary editor={editor}>
      <div
        className="slate"
        style={{ ...divStyle }}
        onClick={() => setActiveEditor(editor)}
      >
        <Slate
          editor={editor}
          initialValue={value}
          onChange={(value) => {
            setValue(value);
            const { selection } = editor;

            if (selection && Range.isCollapsed(selection)) {
              const [start] = Range.edges(selection);
              const lastCharacterPoint = Editor.before(editor, start, {
                unit: "character",
              });
              const lastCharacterRange =
                lastCharacterPoint &&
                Editor.range(editor, lastCharacterPoint, start);
              const lastCharacter =
                lastCharacterRange && Editor.string(editor, lastCharacterRange);

              // If the last character is the @ sign, show the autocomplete without any search term
              if (lastCharacterRange && lastCharacter === "@") {
                setTarget(lastCharacterRange);
                setSearch("");
                return;
              } else {
                // Get necessary data to get the last word.
                const lastWordPoint = Editor.before(editor, start, {
                  unit: "word",
                });
                const lastWordRange =
                  lastWordPoint && Editor.range(editor, lastWordPoint, start);
                const lastWord =
                  lastWordRange && Editor.string(editor, lastWordRange);

                // Get necessary items to get the character before the last word.
                const characterBeforeWordPoint =
                  lastWordPoint &&
                  Editor.before(editor, lastWordPoint, { unit: "character" });
                const characterBeforeWordRange =
                  characterBeforeWordPoint &&
                  Editor.range(editor, characterBeforeWordPoint, lastWordPoint);
                const characterBeforeWord =
                  characterBeforeWordRange &&
                  Editor.string(editor, characterBeforeWordRange);
                if (
                  characterBeforeWordPoint &&
                  lastWord &&
                  characterBeforeWord === "@"
                ) {
                  const newRange = Editor.range(
                    editor,
                    characterBeforeWordPoint,
                    start
                  );
                  setTarget(newRange);
                  setSearch(lastWord);
                  return;
                }
              }
            }
            setTarget(undefined);
          }}
        >
          {/* <HoveringToolbar type="document" /> */}
          <EditableWithCursors />
          {target && mentions.length > 0 && (
            <React.Fragment>
              {createPortal(
                <div
                  ref={container}
                  className="fixed z-10 p-2 bg-white overflow-auto max-h-52 rounded shadow"
                  style={{
                    top: "inherit",
                    left: "inherit",
                  }}
                  data-cy="mentions-portal"
                >
                  {mentions.map((mention, i) => {
                    return (
                      editor && (
                        <div
                          key={mention}
                          onMouseDown={(event: React.MouseEvent) => {
                            const eventTarget =
                              event.target as HTMLInputElement;
                            const id = parseInt(eventTarget.id);

                            event.preventDefault();

                            Transforms.select(editor, target);
                            insertMention({
                              editor,
                              mentionstring: mentions[id],
                            });
                            setTarget(undefined);
                          }}
                          className={clsx(
                            i === index ? "bg-blue-500" : "",
                            "p-1 group hover:bg-blue-500"
                          )}
                        >
                          <span
                            className={clsx(
                              i === index ? "text-white" : "",
                              "text-sm group-hover:text-white"
                            )}
                            id={i.toString()}
                          >
                            {mention}
                          </span>
                        </div>
                      )
                    );
                  })}
                </div>,
                document.body
              )}
            </React.Fragment>
          )}
          <div ref={container} />
        </Slate>
      </div>
    </ErrorBoundary>
  );
};
