import { gql } from "@apollo/client";

export const contenttemplateCoreFragment = gql`
  fragment contenttemplateCore on contenttemplate {
    ID
    pagetemplate_ID
    pagetemplate_sectiontemplate_ID
    pagetemplate_sectiontemplate_documenttemplate_ID
    pagetemplate_sectiontemplate_documenttemplate_organisation_ID
    Type
    ContentType
    Title
    Position
    Text
    YDoc
    PlaceholderText
    Size
    Height
    Width
    XPosition
    YPosition
    Locked
    ModelType
    ModelToRender

    InsuranceType
    AssetType
    LiabilityType
    AdviceAgreementType
    ImageType

    create_time
    update_time
    created_by
    updated_by
  }
`;

export const contenttemplateFragment = gql`
  fragment contenttemplate on contenttemplate {
    ...contenttemplateCore
  }
  ${contenttemplateCoreFragment}
`;
