import { gql } from "@apollo/client";
import { scenariocontributionwithdrawalFragment } from "../scenariocontributionwithdrawal";

export const scenarioliabilityCoreFragment = gql`
  fragment scenarioliabilityCore on scenarioliability {
    ID
    scenario_ID
    scenario_advice_ID
    scenario_advice_groups_ID
    scenario_advice_groups_organisation_ID

    Type
    Name
    Value
    InterestRate
    Repayment
    RepaymentFrequency
    StartDate
    Expiry
    AnnualFee
    AnnualFeeValue
    FixedOrVariable
    InterestOnlyOrPrincipalAndInterest
    InterestOnlyExpiryDate
    LoanSecuredBy_scenarioasset_ID
    LoanOffset_scenarioasset_ID
    Owner_scenarioclient_ID
    SMSFLoanPaidFromIncome

    liabilities_ID
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const scenarioliabilityFragment = gql`
  fragment scenarioliability on scenarioliability {
    ...scenarioliabilityCore

    scenariocontributionwithdrawal {
      ...scenariocontributionwithdrawal
    }
  }
  ${scenarioliabilityCoreFragment}
  ${scenariocontributionwithdrawalFragment}
`;
