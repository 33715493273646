import { gql } from "@apollo/client";
import { notesFragment } from "../notes/fragment";

export const estateplanningCoreFragment = gql`
  fragment estateplanningCore on estateplanning {
    ID
    clients_ID
    clients_groups_ID
    clients_groups_organisation_ID
    Will
    EPOA
    ProfessionalAdviser
    POAApointeeDetails
    FuneralPlan
    LocationOfDocuments
    HealthDirective
    LastUpdated
    TestamentaryTrust
    BDBNDetails
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const estateplanningFragment = gql`
  fragment estateplanning on estateplanning {
    ...estateplanningCore
    
    notes {
      ...notes
    }
  }
  ${estateplanningCoreFragment}
  ${notesFragment}
`;
