import { gql } from "@apollo/client";
import { otherassetsFragment } from ".";

export const createOneOtherasset = gql`
  mutation CreateOneOtherassets($data: otherassetsCreateInput!) {
    createOneOtherassets(data: $data) {
      ...otherassets
    }
  }
  ${otherassetsFragment}
`;

export const updateOneOtherasset = gql`
  mutation UpdateOneOtherassets(
    $data: otherassetsUpdateInput!
    $where: otherassetsWhereUniqueInput!
  ) {
    updateOneOtherassets(data: $data, where: $where) {
      ...otherassets
    }
  }
  ${otherassetsFragment}
`;

export const deleteOneOtherasset = gql`
  mutation DeleteOneOtherassets($where: otherassetsWhereUniqueInput!) {
    deleteOneOtherassets(where: $where) {
      ...otherassets
    }
  }
  ${otherassetsFragment}
`;
