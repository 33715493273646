import { gql } from "@apollo/client";
import { servicesFragment } from ".";

export const createOneServices = gql`
  mutation CreateOneServices($data: servicesCreateInput!) {
    createOneServices(data: $data) {
      ...services
    }
  }
  ${servicesFragment}
`;

export const updateOneServices = gql`
  mutation UpdateOneServices(
    $data: servicesUpdateInput!
    $where: servicesWhereUniqueInput!
  ) {
    updateOneServices(data: $data, where: $where) {
      ...services
    }
  }
  ${servicesFragment}
`;

export const deleteOneServices = gql`
  mutation DeleteOneServices($where: servicesWhereUniqueInput!) {
    deleteOneServices(where: $where) {
      ...services
    }
  }
  ${servicesFragment}
`;
