import {
  ScenarioclientSubscription,
  FindUniqueAdviceQuery,
  MutationType,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { scenarioclient } from "../subscription";
import { QueryResult } from "@apollo/client";

interface ScenarioclientResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: ScenarioclientSubscription["scenarioclient"];
}

function createScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioclient.scenario_ID
          ? {
              ...scenario,
              scenarioclient: [...scenario.scenarioclient, scenarioclient],
            }
          : scenario
      ),
    },
  });
}

function updateScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioclient.scenario_ID
          ? {
              ...scenario,
              scenarioclient: scenario.scenarioclient.map((scenarioclients) =>
                scenarioclients.ID === scenarioclient.ID
                  ? { ...scenarioclients, ...scenarioclient }
                  : scenarioclients
              ),
            }
          : scenario
      ),
    },
  });
}

function deleteScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioclient.scenario_ID
          ? {
              ...scenario,
              scenarioclient: scenario.scenarioclient.filter(
                (scenarioclients) => scenarioclients.ID !== scenarioclient.ID
              ),
            }
          : scenario
      ),
    },
  });
}

function scenarioclientResolver({
  prev,
  payload,
}: ScenarioclientResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioclient({ prev, payload });

    case MutationType.Update:
      return updateScenarioclient({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioclient({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioclientFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioclient,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioclientSubscription } }
    ) =>
      scenarioclientResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioclient,
      }),
    variables: query.variables,
  });
}
