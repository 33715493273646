import React, { Fragment, useEffect, useState } from "react";

import clsx from "clsx";
import {
  ComboboxButton,
  Combobox as ComboboxHeadlessUi,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from "@headlessui/react";
import { Tooltip, TooltipProps } from "../../Tooltip";
import { checkFieldIsEmpty } from "../../../Functions/GeneralFunctions";

export type ComboboxOptionType = {
  id: number;
  value: string;
  JSON?: string; //This is for document
};

interface ComboboxProps {
  id?: string; // necessary for required error
  label?: string; // Label above select component
  placeholder?: string;
  defaultValue?: ComboboxOptionType;
  options: ComboboxOptionType[];
  mutation?: (value: ComboboxOptionType) => void; //update mutation
  required?: boolean; // Required or not for submission
  disabled?: boolean;
  display?: "input" | "textarea";
  customQuery?: boolean;
  fontselect?: boolean;
  tooltip?: Omit<TooltipProps, "children">;
}

/**
 * @created 01-07-2021
 * @updated 02-05-2023
 * @description Input component.
 */
export const Combobox = ({
  id,
  label,
  placeholder,
  defaultValue,
  options,
  mutation,
  required,
  disabled,
  display = "input",
  customQuery = false,
  fontselect,
  tooltip,
}: ComboboxProps) => {
  const [query, setQuery] = useState("");
  const [value, setValue] = useState<ComboboxOptionType>(
    defaultValue ?? { id: 0, value: "" }
  );
  useEffect(() => {
    if (value !== defaultValue) setValue(defaultValue ?? { id: 0, value: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const filteredOptions =
    query === ""
      ? options.slice(0, 50)
      : options
          .filter((option) => {
            return option.value.toLowerCase().includes(query.toLowerCase());
          })
          .slice(0, 50);


  return (
    <Field disabled={disabled} className={"w-full"}>
       {disabled ? (
        <>
        
        
        <div className={clsx(label ? "mt-3" : "", "relative w-full")}>
          {label && (
            <div className="select-label">
              {" "}
              <div className="flex items-center gap-1">
                {label}
                {tooltip && (
                  <Tooltip
                    title={tooltip.title ?? ""}
                    background={tooltip.background ?? "dark"}
                    placement={tooltip.placement ?? "top"}
                  >
                    <div className="icon text-neutral-500 fa-regular fa-lg fa-circle-question" />
                  </Tooltip>
                )}
              </div>
            </div>
          )}
        <p className={"input-style"}>{checkFieldIsEmpty(value.value)}</p>

          </div>
          </>
       ) : (
      <ComboboxHeadlessUi
        as="div"
        value={value}
        immediate
        onChange={(option) => {
          setQuery("");
          option && setValue(option);
          if (mutation && option) mutation(option);
        }}
      >
        <div className={clsx(label ? "mt-3" : "", "relative w-full")}>
          {label && (
            <Label className="select-label">
              {" "}
              <div className="flex items-center gap-1">
                {label}
                {tooltip && (
                  <Tooltip
                    title={tooltip.title ?? ""}
                    background={tooltip.background ?? "dark"}
                    placement={tooltip.placement ?? "top"}
                  >
                    <div className="icon text-neutral-500 fa-regular fa-lg fa-circle-question" />
                  </Tooltip>
                )}
              </div>
            </Label>
          )}
          <ComboboxInput
            as={display}
            onChange={(event) => setQuery(event.target.value)}
            onBlur={() => setQuery("")}
            displayValue={(option: ComboboxOptionType) => option?.value}
            placeholder={placeholder}
          />

          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <i
              className="h-5 w-5 text-neutral-400 icon fa-regular fa-chevron-down fa-xs"
              aria-hidden="true"
            />
          </ComboboxButton>
          <ComboboxOptions 
          className="absolute z-50 pt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neutral-50 ring-opacity-5 focus:outline-none sm:text-sm">
            {query.length > 0 && customQuery && (
              <ComboboxOption
                value={{ id: null, value: query }}
                className="relative cursor-pointer select-none py-2 pl-8 pr-4 data-[focus]:bg-blue-100"
              >
                Create <span className="font-bold">"{query}"</span>
              </ComboboxOption>
            )}
            {filteredOptions.length === 0 && query === "" ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredOptions.map((option, index) => {
                return (
                  <ComboboxOption
                    key={option.id + "" + index}
                    value={option}
                    className={clsx(
                      option.value === value.value
                        ? "bg-blue-500 text-white selected"
                        : "text-neutral-600",
                      "relative cursor-pointer select-none combo-option font-normal py-2 pl-8 pr-4 hover:bg-blue-500 hover:text-white"
                    )}
                    style={
                      fontselect
                        ? { fontFamily: option.value }
                        : option.JSON
                        ? {
                            fontFamily: JSON.parse(option.JSON).FontFamily,
                            fontWeight: JSON.parse(option.JSON).FontWeight,
                            color: JSON.parse(option.JSON).Colour,
                          }
                        : {}
                    }
                  >
                    <>
                      <span
                        className={clsx(
                          "block truncate",
                          option.value === value.value && "font-semibold"
                        )}
                      >
                        {option.value}
                      </span>

                      {option.value === value.value && (
                        <span
                          className={clsx(
                            option.value === value.value
                              ? "text-white"
                              : "text-blue-500",
                            "absolute inset-y-0 left-0 flex items-center pl-1.5"
                          )}
                        >
                          <i
                            className="h-5 w-5 icon fa-solid fa-check"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                    </>
                  </ComboboxOption>
                );
              })
            )}
          </ComboboxOptions>
        </div>
      </ComboboxHeadlessUi>
       )}
    </Field>
  );
};
