import React from "react";
import { Tooltip } from "../Tooltip";
import clsx from "clsx";
import { Modal } from "../Modal";
import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export type ButtonType =
  | "default"
  | "alt"
  | "circle"
  | "circle_small"
  | "no-style"
  | "select"
  | "dropdown";

export interface ButtonProps {
  type: ButtonType;
  tooltip?: string;
  functionality?:
    | "none"
    | "add"
    | "delete"
    | "remove"
    | "link"
    | "delete-alt"
    | "cancel"
    | "back"
    | "next"
    | "up"
    | "down"
    | "edit"
    | "history"
    | "note"
    | "duplicate"
    | "save" /* SAVE IS DEPRECATED */;
  shadow?: boolean;
  width?: string;
  text?: string | JSX.Element;
  onClick?: Function;
  buttontype?: "button" | "submit";
  id?: string;
  disabled?: boolean;
  loading?: boolean;
  deleteMutationProps?: {
    deleteMutation: Function;
    mutationID: number | object;
    navigate: boolean;
    type?: string;
  };
  ariapressed?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  tooltip,
  type = "default",
  functionality = "none",
  shadow = false,
  width,
  text,
  onClick,
  buttontype = "button",
  id,
  disabled,
  loading,
  deleteMutationProps,
  ariapressed,
}) => {
  const navigate = useNavigate();

  var deleteMutationID = deleteMutationProps
    ? typeof deleteMutationProps.mutationID === "number"
      ? {
          ID: deleteMutationProps.mutationID,
        }
      : deleteMutationProps.mutationID
    : null;

  const RenderButton = () => {
    return (
      <Tooltip title={tooltip ? tooltip : ""}>
        <div>
          <button
            id={id}
            aria-pressed={ariapressed}
            className={clsx(
              loading && "loading ",
              type,
              functionality,
              functionality !== "none" ? "gap-2" : "",
              shadow ? "shadow" : ""
            )}
            onClick={(event) => {
              onClick && onClick(event);
            }}
            disabled={disabled || loading}
          >
            {functionality !== "none" ? (
              <i
                className={clsx(
                  functionality === "add"
                    ? "fa-solid fa-plus"
                    : functionality === "delete"
                    ? "fa-regular fa-trash"
                    : functionality === "edit"
                    ? "fa-regular fa-cog"
                    : functionality === "cancel"
                    ? "fa-regular fa-times"
                    : functionality === "back"
                    ? "fa-regular fa-chevron-left"
                    : functionality === "remove"
                    ? "fa-regular fa-square-minus"
                    : functionality === "link"
                    ? "fa-regular fa-link"
                    : "fa-regular",
                  "icon h-4"
                )}
              />
            ) : null}
            {text}

            {loading && <i className="icon fa-solid fa-spin fa-spinner ml-2" />}
          </button>
        </div>
      </Tooltip>
    );
  };
  return (
    <React.Fragment>
      {deleteMutationProps ? (
        <Modal
          buttonProps={{ type: type, text: text, functionality: functionality, tooltip: tooltip }}
        >
          {({ setOpen }) => (
            <div className="flex flex-col gap-y-6">
              <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center">
                  <div className="icon fa-regular fa-trash text-semantic-error-500" />
                  <h4>Delete {deleteMutationProps.type ?? "item"}</h4>
                </div>
                <Button
                  type="circle"
                  functionality={"cancel"}
                  onClick={() => setOpen(false)}
                />
              </div>
              <h5>
                This action <b>cannot</b> be undone. This{" "}
                {deleteMutationProps.type ?? "item"} will be permanently
                deleted.
              </h5>
              <div className="grid grid-cols-2 gap-4 items-center w-full">
                <button
                  className={"default delete"}
                  onClick={async (
                    event: React.MouseEvent<HTMLButtonElement>
                  ) => {
                    await deleteMutationProps
                      .deleteMutation({
                        variables: {
                          where: { ...deleteMutationID },
                        },
                      })
                      .then(() => {
                        setOpen(false);
                      })
                      .catch((error: ApolloError) => {});

                    deleteMutationProps.navigate && navigate("../");
                  }}
                >
                  {"I understand, delete this " +
                    (deleteMutationProps.type ?? "item")}
                </button>
                <Button
                  type="alt"
                  text={"Cancel"}
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
          )}
        </Modal>
      ) : (
        <RenderButton />
      )}
    </React.Fragment>
  );
};
