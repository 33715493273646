import { gql } from "@apollo/client";
import { notesFragment } from "../notes/fragment";

export const addressCoreFragment = gql`
  fragment addressCore on address {
    clients_groups_organisation_ID
    clients_groups_ID
    clients_ID
    ID
    Type
    Occupancy
    MoveOutDate
    MoveInDate
    Country
    State
    Postcode
    Suburb
    AddressLine2
    AddressLine1

    create_time
    created_by
    updated_by
    update_time
  }
`;

export const addressFragment = gql`
  fragment address on address {
    ...addressCore

    notes {
      ...notes
    }
  }
  ${addressCoreFragment}
  ${notesFragment}
`;
