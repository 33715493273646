import { gql } from "@apollo/client";
import { interestFragment } from ".";

export const updateOneInterest = gql`
  mutation UpdateOneInterest(
    $data: interestUpdateInput!
    $where: interestWhereUniqueInput!
  ) {
    updateOneInterest(data: $data, where: $where) {
      ...interest
    }
  }
  ${interestFragment}
`;
