import { gql } from "@apollo/client";
import { goalFragment } from "./fragment";

export const goal = gql`
  subscription Goal($where: goalWhereUniqueInput) {
    goal(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...goal
      }
    }
  }
  ${goalFragment}
`;

export const goalMany = gql`
  subscription GoalMany($where: goalWhereInput) {
    goalMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...goal
      }
    }
  }
  ${goalFragment}
`;
