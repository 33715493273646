import { gql } from "@apollo/client";

export const bestinterestFragment = gql`
  fragment bestinterest on bestinterest {
    ID
    review_ID
    review_groups_ID
    review_groups_organisation_ID
    BestInterest
    create_time
    created_by
    updated_by
    update_time
  }
`;
