import { QueryResult } from "@apollo/client";
import {
  FeesSubscription,
  FindUniqueFeesQuery,
  FindUniqueFeesQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { fees } from "../subscription";

interface FeesResolverParams {
  prev: FindUniqueFeesQuery;
  payload: FeesSubscription["fees"];
}

function updateFees({ prev, payload }: FeesResolverParams) {
  const fees = payload.data;

  if (!prev.findUniqueFees) return prev;
  return Object.assign({}, prev, {
    findUniqueFees:
      fees.ID === prev.findUniqueFees.ID
        ? {
            ...prev.findUniqueFees,
            ...fees,
          }
        : prev.findUniqueFees,
  });
}

function deleteFees({ prev, payload }: FeesResolverParams) {
  const fees = payload.data;

  if (!prev.findUniqueFees) return prev;
  return Object.assign({}, prev, {
    findUniqueFees:
      prev.findUniqueFees.ID === fees.ID
        ? {
            ...prev.findUniqueFees,
            undefined,
          }
        : prev.findUniqueFees,
  });
}

function feesResolver({ prev, payload }: FeesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createFees(prev, payload);

    case MutationType.Update:
      return updateFees({ prev, payload });

    case MutationType.Delete:
      return deleteFees({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniqueFees(
  query: Pick<
    QueryResult<FindUniqueFeesQuery, FindUniqueFeesQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: fees,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: FeesSubscription } }
    ) =>
      feesResolver({
        prev,
        payload: payload.subscriptionData.data.fees
      }),
    variables: query.variables,
  });
}
