import { QueryResult } from "@apollo/client";
import {
  FindUniqueTasksQuery,
  FindUniqueTasksQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueTasksQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, tasks_has_notes } = payload;

  if (!prev.findUniqueTasks) return prev;
  return Object.assign({}, prev, {
    findUniqueTasks: {
      ...prev.findUniqueTasks,
      notes: tasks_has_notes.some(
        (relation) => relation.tasks_ID === prev.findUniqueTasks?.ID
      )
        ? [...prev.findUniqueTasks.notes, notes]
        : prev.findUniqueTasks.notes,
    },
  });
}
 
function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueTasks) return prev;
  return Object.assign({}, prev, {
    findUniqueTasks: {
      ...prev.findUniqueTasks,
      notes: prev.findUniqueTasks.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueTasks) return prev;
  return Object.assign({}, prev, {
    findUniqueTasks: {
      ...prev.findUniqueTasks,
      notes: prev.findUniqueTasks.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueTasks(query: Pick<
  QueryResult<FindUniqueTasksQuery, FindUniqueTasksQueryVariables>,
  "subscribeToMore" | "variables"
>) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes
      }),
    variables: query.variables,
  });
}
