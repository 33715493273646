import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../Functions/Owner";

interface AssetsSharesProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns shares component for advice template && document.
 */
export const AssetsShares: React.FC<AssetsSharesProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  type Shares = {
    assets_ID: number | null;
    Type: string;
    Owner: string;
    Value: string;
  };

  const summaryShares: Shares[] = group
    ? content?.ContentType === "Grouped"
      ? getGroupAssets(group).shares
      : content?.asset_ID
      ? getGroupAssets(group).shares.find(
          (shares: Shares) => content?.asset_ID === shares.assets_ID
        )
      : {}
    : contenttemplate?.ContentType === "Grouped"
    ? [
        { Type: "Direct Shares", Owner: "Joint", Value: "$200,000" },
        { Type: "Suncorp", Owner: "Joint", Value: "$50,000" },
      ]
    : { Type: "Direct Shares", Owner: "Joint", Value: "$200,000" };

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryShares.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Type
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Owner
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Value
          </StyledText>
        </div>
      ) : (
        <h5>No shares recorded</h5>
      )}
      {summaryShares.map((share, objectIndex) => {
        return (
          <div
           className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(share.Type)}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(
                group
                  ? ownerOptionsUpdateAssetDefaultValue(share, group).text
                  : share.Owner
              )}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(share.Value, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>Individual Share view</React.Fragment>
  );
};
