import {
  Content_ModelToRender,
  Contenttemplate_ModelToRender,
  Maybe,
  Theme,
} from "../../../../../../codegen/schema";
import { TrustPlanningComparisonPlaceholderModel } from "../Comparison/Placeholder";
import { TrustPlanningScenarioPlaceholderModel } from "../Scenario/Placeholder";

interface TrustPlanningComparisonModelPlaceholderProps {
  ModelToRender?:
    | Maybe<Content_ModelToRender>
    | Maybe<Contenttemplate_ModelToRender>;
  theme?: Theme;
}

export const TrustPlanningModelPlaceholder = ({
  ModelToRender,
  theme,
}: TrustPlanningComparisonModelPlaceholderProps) => {
  if (
    ModelToRender === Content_ModelToRender.Comparison ||
    ModelToRender === Contenttemplate_ModelToRender.Comparison
  ) {
    return <TrustPlanningComparisonPlaceholderModel theme={theme} />;
  } else if (
    ModelToRender === Content_ModelToRender.Scenario ||
    ModelToRender === Contenttemplate_ModelToRender.Scenario
  ) {
    return <TrustPlanningScenarioPlaceholderModel theme={theme} />;
  } else return <p>Please choose a Scenario model or Comparison model.</p>;
};
