import { gql } from "@apollo/client";
import { notesFragment } from "../notes";

export const childCoreFragment = gql`
  fragment childCore on child {
    ID
    groups_ID
    groups_organisation_ID
    FirstName
    LastName
    Gender
    DOB
    Dependant
    Comments
    DependantToAge
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const childFragment = gql`
  fragment child on child {
    ...childCore

    notes {
      ...notes
    }
  }
  ${childCoreFragment}
  ${notesFragment}
`;
