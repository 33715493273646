import React from "react";
import { InsuranceTPDScenarioModelGraph } from "../Graph";
import { InsuranceTPDScenarioDataReturn } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";

interface InsuranceTPDPlaceholderModelProps {
  theme?: Theme;
}

var data: InsuranceTPDScenarioDataReturn[] = [
  {
    clientName: "John Smith",
    existingCover: 1500000,
    calculatedCover: 1466666.666666667,
    recommendedCover: 1400000,
    gap: -100000,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
  {
    clientName: "Jane Smith",
    existingCover: 800000,
    calculatedCover: 900000,
    recommendedCover: 1000000,
    gap: 200000,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
];

export const InsuranceTPDScenarioPlaceholderModel = ({
  theme,
}: InsuranceTPDPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
         TPD Insurance
        </StyledText>

        <ModelTooltip type={Model_Type.InsuranceTpd} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InsuranceTPDScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
