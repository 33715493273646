import { gql } from "@apollo/client";
import { feesFragment } from "./fragment";

export const fees = gql`
  subscription Fees($where: feesWhereUniqueInput) {
    fees(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...fees
      }
    }
  }
  ${feesFragment}
`;

export const feesMany = gql`
  subscription FeesMany($where: feesWhereInput) {
    feesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...fees
      }
    }
  }
  ${feesFragment}
`;
