import { gql } from "@apollo/client";
import { notesFragment } from "../notes";
import { historyFragment } from "../history";

export const createOneOtherliability = gql`
  mutation CreateOneOtherliabilities($data: otherliabilitiesCreateInput!) {
    createOneOtherliabilities(data: $data) {
      ID
      liabilities_ID
      Type
      Amount
      Description
      Owner
      Bank
      LoanLimit
      CurrentLoanOutstanding
      Refinance
      create_time
      update_time
      created_by
      updated_by
      notes {
        ...notes
      }
      history {
        ...history
      }
    }
  }
  ${notesFragment}
  ${historyFragment}
`;

export const updateOneOtherliability = gql`
  mutation UpdateOneOtherliabilities(
    $data: otherliabilitiesUpdateInput!
    $where: otherliabilitiesWhereUniqueInput!
  ) {
    updateOneOtherliabilities(data: $data, where: $where) {
      ID
      liabilities_ID
      Type
      Amount
      Description
      Owner
      Bank
      LoanLimit
      CurrentLoanOutstanding
      Refinance
      create_time
      update_time
      created_by
      updated_by
      notes {
        ...notes
      }
      history {
        ...history
      }
    }
  }
  ${notesFragment}
  ${historyFragment}
`;

export const deleteOneOtherliability = gql`
  mutation DeleteOneOtherliabilities(
    $where: otherliabilitiesWhereUniqueInput!
  ) {
    deleteOneOtherliabilities(where: $where) {
      ID
      liabilities_ID
      Type
      Amount
      Description
      Owner
      Bank
      LoanLimit
      CurrentLoanOutstanding
      Refinance
      create_time
      update_time
      created_by
      updated_by
      notes {
        ...notes
      }
      history {
        ...history
      }
    }
  }
  ${notesFragment}
  ${historyFragment}
`;
