import dayjs from "dayjs";
import {
  Scenarioliability,
  Scenarioliability_InterestOnlyOrPrincipalAndInterest,
  Scenarioliability_RepaymentFrequency,
} from "../../../../../../codegen/schema";

/**
 * Some general information:
 * - It is preferably to use StartDate and Expiry to determine the length of the loan
 * than a simple "Term" field. This is because if the loan term is 30 years, but it started
 * 15 years ago, the minimum repayments differ to that of a 15 year loan for the same amount.
 */

interface Props {
  scenarioliability: Scenarioliability;
}

interface ScenarioliabilityCalculatedFieldsReturn {
  /** @description Interest rate based on payment frequency. */
  calculatedInterestRate: number;
  /**
   * @description How often payments are made. Returns n amount of weeks, fortnights, or months
   * (52, 26, or 12).
   */
  frequency: number;
  /**
   * @description Should be gt 0. Calculated minimum loan repayments that are made based on expiry.
   * If expiry is null, assumes 30 year loan term.
   */
  minimumRepayments: number;
  /** @description Should be gte 0. Calculated minimum loan repayments while in IO period */
  interestOnlyMinimumRepayments: number;
}

export function ScenarioliabilityCalculatedFields({
  scenarioliability,
}: Props): ScenarioliabilityCalculatedFieldsReturn {
  // SETUP

  // If interest only, calculate minimum repayments as interest only
  // THEN calculate the remaining years to expiry
  var frequency =
    scenarioliability?.RepaymentFrequency === "Weekly"
      ? 52
      : scenarioliability?.RepaymentFrequency === "Fortnightly"
      ? 26
      : 12;

  /**
   * @description Interest rate based on payment frequency.
   */
  var calculatedInterestRate = scenarioliability.InterestRate / 100 / frequency;

  var interestOnlyMinimumRepayments =
    ((scenarioliability.InterestRate / 100) * scenarioliability.Value) /
    frequency;

  var interestOnlyYears =
    scenarioliability.InterestOnlyOrPrincipalAndInterest ===
      Scenarioliability_InterestOnlyOrPrincipalAndInterest.InterestOnly &&
    dayjs(scenarioliability.InterestOnlyExpiryDate).isValid() &&
    dayjs(scenarioliability.InterestOnlyExpiryDate).isAfter(dayjs())
      ? dayjs(scenarioliability.InterestOnlyExpiryDate).year() - dayjs().year()
      : 0;

  // MINIMUM REPAYMENTS
  var nRepaymentsTotal =
    (dayjs(scenarioliability?.Expiry).isValid()
      ? dayjs(scenarioliability?.Expiry).diff(dayjs(), "years") -
        interestOnlyYears
      : 30 - interestOnlyYears) * frequency;

  var intermediary = (1 + calculatedInterestRate) ** nRepaymentsTotal;

  var minimumRepayments =
    // If no more repayments to be made return 0
    nRepaymentsTotal === 0
      ? 0
      : intermediary === 1
      ? scenarioliability?.Value / nRepaymentsTotal
      : (scenarioliability?.Value * calculatedInterestRate * intermediary) /
        (intermediary - 1);

  return {
    calculatedInterestRate,
    frequency,
    minimumRepayments,
    interestOnlyMinimumRepayments,
  };
}

interface ScenarioliabilityLoanRepaymentByYearProps {
  scenarioliability: Scenarioliability;
  offset: number;
  year: number;
}

export function ScenarioliabilityLoanRepaymentByYear({
  scenarioliability,
  offset,
  year,
}: ScenarioliabilityLoanRepaymentByYearProps) {}

interface ScenarioliabilityLoanRepaymentScehduleProps {
  scenarioliability: Scenarioliability;
  offset: number;
  year: number;
}

export function ScenarioliabilityLoanRepaymentSchedule({
  scenarioliability,
}: ScenarioliabilityLoanRepaymentScehduleProps) {
  var { StartDate: startDate, Expiry: expiryDate } = scenarioliability;

  // Structure for loan data
  var loan = {
    expiryDate: dayjs(expiryDate).isValid() ? expiryDate : dayjs(),
    startDate: dayjs(startDate).isValid() ? startDate : dayjs(),
    term: 30,
    interest: {
      rate: scenarioliability.InterestRate / 100 ?? 0,
      rateByFrequency: 0,
    },
    repayments: {
      perYear: 0,
      total: 0,
    },
  };

  // Calculate loan term
  loan.term = dayjs(loan.expiryDate).year() - dayjs(loan.startDate).year();

  // Calculate repayments per year based on frequency
  loan.repayments.perYear =
    scenarioliability.RepaymentFrequency ===
    Scenarioliability_RepaymentFrequency.Weekly
      ? 52
      : scenarioliability.RepaymentFrequency ===
        Scenarioliability_RepaymentFrequency.Fortnightly
      ? 26
      : 12;

  // Calculate repayments for life of loan using term and repayments per year
  loan.repayments.total = loan.term * loan.repayments.perYear;

  // Calculate loan interest rate per payment period (repayments per year)
  loan.interest.rateByFrequency = loan.interest.rate / loan.repayments.perYear;
}
