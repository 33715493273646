import { gql } from "@apollo/client";
import { propertyFragment } from "./fragment";

export const property = gql`
  subscription Property($where: propertyWhereUniqueInput) {
    property(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...property
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${propertyFragment}
`;

export const propertyMany = gql`
  subscription PropertyMany($where: propertyWhereInput) {
    propertyMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...property
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${propertyFragment}
`;
