import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";

export const otherassetsCoreFragment = gql`
  fragment otherassetsCore on otherassetsCore {
    ID
    assets_ID
    Description
    Details
    Value
    DateAcquired
    PurchasePrice
    ReferenceNumber
    OwnershipType
    EstimatedAnnualIncome
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const otherassetsFragment = gql`
  fragment otherassets on otherassets {
    ID
    assets_ID
    Description
    Details
    Value
    DateAcquired
    PurchasePrice
    ReferenceNumber
    OwnershipType
    EstimatedAnnualIncome
    create_time
    created_by
    update_time
    updated_by

    assetallocation {
      ...assetallocation
    }
    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${notesFragment}
  ${historyFragment}
`;
