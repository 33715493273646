import { modelFindUniqueAdvice } from "../../model/reducers";
import { notesFindUniqueAdvice } from "../../notes";
import { scenarioFindUniqueAdvice } from "../../scenario/";
import { scenarioassetFindUniqueAdvice } from "../../scenarioasset";
import { scenariochildFindUniqueAdvice } from "../../scenariochild";
import { scenarioclientFindUniqueAdvice } from "../../scenarioclient";
import { scenariocontributionwithdrawalFindUniqueAdvice } from "../../scenariocontributionwithdrawal";
import { scenarioinsuranceFindUniqueAdvice } from "../../scenarioinsurance";
import { scenarioliabilityFindUniqueAdvice } from "../../scenarioliability";
import { teamFindUniqueAdvice } from "../../teams";
import { adviceFindUniqueAdvice } from "../";
import {
  FindUniqueAdviceQuery,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export default function findUniqueAdviceSubscription(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  adviceFindUniqueAdvice(query);
  modelFindUniqueAdvice(query);
  scenarioFindUniqueAdvice(query);
  scenarioassetFindUniqueAdvice(query);
  scenarioliabilityFindUniqueAdvice(query);
  scenarioclientFindUniqueAdvice(query);
  scenariocontributionwithdrawalFindUniqueAdvice(query);
  scenarioinsuranceFindUniqueAdvice(query);
  scenariochildFindUniqueAdvice(query);
  notesFindUniqueAdvice(query);
  teamFindUniqueAdvice(query);
}
