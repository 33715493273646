import React from "react";
import { FindUniqueGroupQuery, Theme } from "../../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../../Functions/Owner";

interface AssetsPropertyProps {
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  theme?: Theme;
}

/**
 * @created 01-02-2023
 * @updated 15-05-2023
 * @returns Returns grouped property component for advice template && document.
 */
export const AssetsPropertyGrouped: React.FC<AssetsPropertyProps> = ({
  group,
  theme,
}) => {
  type PropertyPlaceholderType = {
    assets_ID: number;
    Type: string;
    StreetName?: string;
    Owner?: string;
    MarketValue?: number;
    PurchasePrice?: number;
  };

  const groupedProperty: PropertyPlaceholderType[] = group
    ? getGroupAssets(group).property
    : [
        {
          assets_ID: 1,
          Type: "Home",
          StreetName: "Smith Street",
          Owner: "Joint",
          MarketValue: 1200000,
        },
        {
          assets_ID: 2,
          Type: "Investment",
          StreetName: "Main Street",
          Owner: "Joint",
          MarketValue: 850000,
        },
      ];

  return (
    <React.Fragment>
      {groupedProperty.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Address
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Owner
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Market Value
          </StyledText>
        </div>
      ) : (
        <h5>No properties recorded</h5>
      )}
      {groupedProperty
        .filter((property) => property.Type !== "Sold")
        .map((property, objectIndex) => {
          return (
            <div
              className="grid grid-cols-3 gap-2 mt-2"
              key={objectIndex}
            >
              <StyledText
                element={theme?.element.find((element) => element.Type === "P")}
                
              >
                {checkFieldIsEmpty(property.StreetName)}
              </StyledText>
              <StyledText
                element={theme?.element.find((element) => element.Type === "P")}
                
              >
                {checkFieldIsEmpty(
                  group
                    ? ownerOptionsUpdateAssetDefaultValue(property, group).text
                    : property.Owner
                )}
              </StyledText>

              <StyledText
                element={theme?.element.find((element) => element.Type === "P")}
                
              >
                {checkFieldIsEmpty(property.MarketValue, "dollar")}
              </StyledText>
            </div>
          );
        })}
    </React.Fragment>
  );
};
