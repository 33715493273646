import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";

export const sharesCoreFragment = gql`
  fragment sharesCore on sharesCore {
    ID
    assets_ID
    Type
    Location
    Value
    DateAcquired
    ReferenceNumber
    OwnershipType
    EstimatedAnnualIncome
    MinimumCashHolding
    ShareCode
    NumberOfShares
    UnitPrice
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const sharesFragment = gql`
  fragment shares on shares {
    ID
    assets_ID
    Type
    Location
    Value
    DateAcquired
    ReferenceNumber
    OwnershipType
    EstimatedAnnualIncome
    MinimumCashHolding
    ShareCode
    NumberOfShares
    UnitPrice
    create_time
    created_by
    update_time
    updated_by

    assetallocation {
      ...assetallocation
    }

    notes {
      ...notes
    }

    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${notesFragment}
  ${historyFragment}
`;
