import { Content_ModelType, Model_Type } from "../../../../../codegen/schema";

import { LongTermProgressCalculationTooltip } from "../../LongTermProgress/Description";
import { AssetAllocationCalculationTooltip } from "../../AssetAllocation/Description";
import { AssetDebtCompositioCalculationTooltip } from "../../AssetDebtComposition/Description";
import { AssetGrowthCalculationTooltip } from "../../AssetGrowth/Description";
import { GrossNetIncomeCalculationTooltip } from "../../GrossNetIncome/Description";
import { IncomeOptimisationCalculationTooltip } from "../../IncomeOptimisation/Description";
import { IncomeTaxCalculationTooltip } from "../../IncomeTax/Description";
import { InsuranceDeathCalculationTooltip } from "../../InsuranceDeath/Description";
import { InvestmentPropertyDebtCalculationTooltip } from "../../InvestmentPropertyDebt/Description";
import { PropertyValueModelCalculationTooltip } from "../../PropertyValue/Description";
import { RetirementPlanningCalculationTooltip } from "../../RetirementPlanning/Description";
import { CardPopper } from "../../../../Menus/CardPopper";

interface ModelTooltipProps {
  type?: Model_Type | Content_ModelType;
}

/**
 * @date 01-07-2021
 * @updated 11-10-2023
 * @description Model Tooltip component
 */
export const ModelTooltip = ({ type }: ModelTooltipProps) => {
  return (
    <div className="pointer">
      <CardPopper
        id={type + "-model-tooltip"}
        buttonProps={{
          tooltip: "Click for model calculation information",
          type: "circle",
          shadow: true,
          text: <div className={"icon fa-regular fa-circle-info "} />,
        }}
      >
        {({ setMenuOpen }) => {
          return (
            <div className="p-4">
              {type && (
                <>
                  <h5 className="text-base">
                    {type.replaceAll("_", " ")} calculation
                  </h5>

                  {(() => {
                    switch (type) {
                      case Model_Type.AssetAllocation:
                        return <AssetAllocationCalculationTooltip />;

                      case Model_Type.AssetAndDebtComposition:
                        return <AssetDebtCompositioCalculationTooltip />;

                      case Model_Type.AssetGrowth:
                        return <AssetGrowthCalculationTooltip />;

                      case Model_Type.GrossAndNetIncome:
                        return <GrossNetIncomeCalculationTooltip />;

                      case Model_Type.IncomeOptimisation:
                        return <IncomeOptimisationCalculationTooltip />;

                      case Model_Type.IncomeTax:
                        return <IncomeTaxCalculationTooltip />;

                      case Model_Type.InsuranceDeath:
                        return <InsuranceDeathCalculationTooltip />;

                      //   case Model_Type.InsuranceIncomeProtection:
                      //     return <InsuranceIncomeProtectionModelDescription />;

                      //   case Model_Type.InsuranceTpd:
                      //     return <InsuranceTPDModelDescription />;

                      //   case Model_Type.InsuranceTrauma:
                      //     return <InsuranceTraumaModelDescription />;

                      case Model_Type.InvestmentAndPropertyDebt:
                        return <InvestmentPropertyDebtCalculationTooltip />;

                      //   case Model_Type.LoanRepayment:
                      //     return <LoanRepaymentModelDescription />;

                      case Model_Type.LongTermProgress:
                        return <LongTermProgressCalculationTooltip />;

                      //   case Model_Type.PropertyCashflow:
                      //     return <PropertyCashflowModelDescription />;

                      case Model_Type.PropertyValue:
                        return <PropertyValueModelCalculationTooltip />;

                      case Model_Type.RetirementPlanning:
                        return <RetirementPlanningCalculationTooltip />;

                      //   case Model_Type.SuperannuationContributionSplitting:
                      //     return <SuperannuationContributionSplittingModelDescription />;

                      //   case Model_Type.SuperannuationInsurance:
                      //     return <SuperannuationInsuranceModelDescription />;

                      //   case Model_Type.TrustPlanning:
                      //     return <TrustPlanningModelDescription />;

                      default:
                        return null;
                    }
                  })()}
                </>
              )}
            </div>
          );
        }}
      </CardPopper>
    </div>
  );
};
