import { QueryResult } from "@apollo/client";
import {
  ChildManySubscription,
  FindManyChildQuery,
  FindManyChildQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { childMany } from "../subscription";

interface ChildResolverParams {
  prev: FindManyChildQuery;
  payload: ChildManySubscription["childMany"];
}

function createChild({ prev, payload }: ChildResolverParams) {
  const child = payload.data;

  return Object.assign({}, prev, {
    findManyChild: [...prev.findManyChild, child],
  });
}

function updateChild({ prev, payload }: ChildResolverParams) {
  const child = payload.data;

  return Object.assign({}, prev, {
    findManyChild: prev.findManyChild.map((entry) =>
      entry.ID === child.ID ? { ...entry, ...child } : entry
    ),
  });
}

function deleteChild({ prev, payload }: ChildResolverParams) {
  const child = payload.data;

  return Object.assign({}, prev, {
    findManyChild: [...prev.findManyChild].filter(
      (entry) => entry.ID !== child.ID
    ),
  });
}

function childResolver({ prev, payload }: ChildResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createChild({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyChild.some((child) => child.ID === data.ID)
        ? updateChild({ prev, payload })
        : createChild({ prev, payload });

    case MutationType.Delete:
      return deleteChild({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyChild(
  query: Pick<
    QueryResult<FindManyChildQuery, FindManyChildQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: childMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ChildManySubscription } }
    ) =>
      childResolver({
        prev,
        payload: payload.subscriptionData.data.childMany,
      }),
    variables: query.variables,
  });
}
