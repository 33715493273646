import { QueryResult } from "@apollo/client";
import {
  ScenarioassetSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { scenarioasset } from "../subscription";

interface ScenarioassetResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ScenarioassetSubscription["scenarioasset"];
}

function createScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioasset.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioasset.scenario_ID
                  ? {
                      ...scenario,
                      scenarioasset: [...scenario.scenarioasset, scenarioasset],
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function updateScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioasset.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioasset.scenario_ID
                  ? {
                      ...scenario,
                      scenarioasset: scenario.scenarioasset.map(
                        (scenarioassets) =>
                          scenarioassets.ID === scenarioasset.ID
                            ? { ...scenarioassets, ...scenarioasset }
                            : scenarioassets
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function deleteScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioasset.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioasset.scenario_ID
                  ? {
                      ...scenario,
                      scenarioasset: scenario.scenarioasset.filter(
                        (scenarioassets) =>
                          scenarioassets.ID !== scenarioasset.ID
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function scenarioassetResolver({ prev, payload }: ScenarioassetResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioasset({ prev, payload });

    case MutationType.Update:
      return updateScenarioasset({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioasset({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioassetFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioasset,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioassetSubscription } }
    ) =>
      scenarioassetResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioasset,
      }),
    variables: query.variables,
  });
}
