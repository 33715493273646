import { QueryResult } from "@apollo/client";
import {
  ModelSubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { model } from "../subscription";

interface ModelResolverParams {
  prev: FindUniqueModelQuery;
  payload: ModelSubscription["model"];
}

function updateModel({ prev, payload }: ModelResolverParams) {
  const model = payload.data;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel:
      model.ID === prev.findUniqueModel.ID
        ? {
            ...prev.findUniqueModel,
            ...model,
          }
        : prev.findUniqueModel,
  });
}

// function deleteModel({ prev, payload }: ModelResolverParams) {
//   const model = payload.data;

//   if (!prev.findUniqueModel) return prev;
//   return Object.assign({}, prev, {
//     findUniqueModel:
//       prev.findUniqueModel.ID === model.ID
//         ? {
//             ...prev.findUniqueModel,
//             undefined,
//           }
//         : prev.findUniqueModel,
//   });
// }

function modelResolver({ prev, payload }: ModelResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createModel(prev, payload);

    case MutationType.Update:
      return updateModel({ prev, payload });

    // case MutationType.Delete:
    //   return deleteModel({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniqueModel(query: Pick<
  QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
  "subscribeToMore" | "variables"
>) {
  query.subscribeToMore({
    document: model,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ModelSubscription } }
    ) =>
      modelResolver({
        prev,
        payload: payload.subscriptionData.data.model
      }),
    variables: query.variables,
  });
}
