/**
 * Current note structure and where they are located
 * child - group
 * entities - group
 * opportunity - group
 * project - group
 * review - group
 * tasks - group
 * fees - group
 *
 * address - client
 * annuity - client
 * cash - client.assets
 * client - client
 * collectibles - client.assets
 * employment - client
 * estateplanning - client
 * goals - client
 * income - client
 * insurance - client
 * loans - client.liabilities
 * offset - client.assets
 * otherassets - client.assets
 * otherliabilities - client.liabilities
 * property - client.assets
 * salarypackaging - client
 * shares - client.assets
 * superannuation - client.assets
 * vehicles - client.assets
 */

import { QueryResult } from "@apollo/client";
import {
  NotesSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueGroupQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  /* commented out fields have their own subscription */
  const {
    data: notes,
    groups_has_notes,
    // advice_has_notes,
    // child_has_notes,
    entities_has_notes,
    // opportunity_has_notes,
    // project_has_notes,
    // review_has_notes,
    // tasks_has_notes,
    // fees_has_notes,
    // address_has_notes,
    assets_has_notes,
    clients_has_notes,
    // employment_has_notes,
    // estateplanning_has_notes,
    goals_has_notes,
    income_has_notes,
    // insurance_has_notes,
    liabilities_has_notes,
    // salarypackaging_has_notes,
  } = payload;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Notes on group data structures */
      /** group notes */
      notes: groups_has_notes.some(
        (relation) => relation.groups_ID === prev.findUniqueGroup?.ID
      )
        ? [...prev.findUniqueGroup.notes, notes]
        : prev.findUniqueGroup.notes,


      /** Entities notes */
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return entities_has_notes.some(
          (relation) => relation.entities_ID === entity.ID
        )
          ? {
              ...entity,
              notes: [...entity.notes, notes],
            }
          : entity;
      }),


      /** Notes on client data structures */
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          /** Assets notes */
          assets: {
            ...client.assets,
            /** Annuity notes */
            annuity: client.assets.annuity.map((annuity) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === annuity.assets_ID
              )
                ? {
                    ...annuity,
                    notes: [...annuity.notes, notes],
                  }
                : annuity;
            }),
            /** Cash notes */
            cash: client.assets.cash.map((cash) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === cash.assets_ID
              )
                ? {
                    ...cash,
                    notes: [...cash.notes, notes],
                  }
                : cash;
            }),
            /** Collectibles notes */
            collectibles: client.assets.collectibles.map((collectibles) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === collectibles.assets_ID
              )
                ? {
                    ...collectibles,
                    notes: [...collectibles.notes, notes],
                  }
                : collectibles;
            }),
            /** Offset notes */
            offset: client.assets.offset.map((offset) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === offset.assets_ID
              )
                ? {
                    ...offset,
                    notes: [...offset.notes, notes],
                  }
                : offset;
            }),
            /** Otherassets notes */
            otherassets: client.assets.otherassets.map((otherassets) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === otherassets.assets_ID
              )
                ? {
                    ...otherassets,
                    notes: [...otherassets.notes, notes],
                  }
                : otherassets;
            }),
            /** Property notes */
            property: client.assets.property.map((property) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === property.assets_ID
              )
                ? {
                    ...property,
                    notes: [...property.notes, notes],
                  }
                : property;
            }),
            /** Shares notes */
            shares: client.assets.shares.map((shares) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === shares.assets_ID
              )
                ? {
                    ...shares,
                    notes: [...shares.notes, notes],
                  }
                : shares;
            }),
            /** Superannuation notes */
            superannuation: client.assets.superannuation.map(
              (superannuation) => {
                return assets_has_notes.some(
                  (relation) => relation.assets_ID === superannuation.assets_ID
                )
                  ? {
                      ...superannuation,
                      notes: [...superannuation.notes, notes],
                    }
                  : superannuation;
              }
            ),
            /** Vehicles notes */
            vehicles: client.assets.vehicles.map((vehicles) => {
              return assets_has_notes.some(
                (relation) => relation.assets_ID === vehicles.assets_ID
              )
                ? {
                    ...vehicles,
                    notes: [...vehicles.notes, notes],
                  }
                : vehicles;
            }),
          },
          /** Client notes */

          notes: clients_has_notes.some(
            (relation) => relation.clients_ID === client.ID
          )
            ? [...client.notes, notes]
            : client.notes,
          /** Goals notes */
          goals: client.goals.map((goals) => {
            return goals_has_notes.some(
              (relation) => relation.goals_ID === goals.ID
            )
              ? {
                  ...goals,
                  notes: [...goals.notes, notes],
                }
              : goals;
          }),
          /** Income notes */
          income: client.income.map((income) => {
            return income_has_notes.some(
              (relation) => relation.income_ID === income.ID
            )
              ? {
                  ...income,
                  notes: [...income.notes, notes],
                }
              : income;
          }),
          /** Liabilities notes */
          liabilities: {
            ...client.liabilities,
            /** Loans notes */
            loans: client.liabilities.loans.map((loans) => {
              return liabilities_has_notes.some(
                (relation) => relation.liabilities_ID === loans.liabilities_ID
              )
                ? {
                    ...loans,
                    notes: [...loans.notes, notes],
                  }
                : loans;
            }),
            /** Otherliabilities notes */
            otherliabilities: client.liabilities.otherliabilities.map(
              (otherliabilities) => {
                return liabilities_has_notes.some(
                  (relation) =>
                    relation.liabilities_ID === otherliabilities.liabilities_ID
                )
                  ? {
                      ...otherliabilities,
                      notes: [...otherliabilities.notes, notes],
                    }
                  : otherliabilities;
              }
            ),
          },
        };
      }),
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Notes on group data structures */
      /** groups */
      notes: prev.findUniqueGroup.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),

      /** Entities notes */
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          notes: entity.notes.map((note) =>
            note.ID === notes.ID ? { ...note, ...notes } : note
          ),
        };
      }),

      /** Notes on client data structures */
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          /** Assets notes */
          assets: {
            ...client.assets,
            /** Annuity notes */
            annuity: client.assets.annuity.map((annuity) => {
              return {
                ...annuity,
                notes: annuity.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Cash notes */
            cash: client.assets.cash.map((cash) => {
              return {
                ...cash,
                notes: cash.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Collectibles notes */
            collectibles: client.assets.collectibles.map((collectible) => {
              return {
                ...collectible,
                notes: collectible.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Offset notes */
            offset: client.assets.offset.map((offset) => {
              return {
                ...offset,
                notes: offset.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Otherassets notes */
            otherassets: client.assets.otherassets.map((otherasset) => {
              return {
                ...otherasset,
                notes: otherasset.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Property notes */
            property: client.assets.property.map((property) => {
              return {
                ...property,
                notes: property.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Shares notes */
            shares: client.assets.shares.map((shares) => {
              return {
                ...shares,
                notes: shares.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Superannuation notes */
            superannuation: client.assets.superannuation.map(
              (superannuation) => {
                return {
                  ...superannuation,
                  notes: superannuation.notes.map((note) =>
                    note.ID === notes.ID ? { ...note, ...notes } : note
                  ),
                };
              }
            ),
            /** Vehicles notes */
            vehicles: client.assets.vehicles.map((vehicles) => {
              return {
                ...vehicles,
                notes: vehicles.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
          },
          /** Client notes */

          notes: client.notes.map((note) =>
            note.ID === notes.ID ? { ...note, ...notes } : note
          ),

          /** Goals notes */
          goals: client.goals.map((goal) => {
            return {
              ...goal,
              notes: goal.notes.map((note) =>
                note.ID === notes.ID ? { ...note, ...notes } : note
              ),
            };
          }),
          /** Income notes */
          income: client.income.map((income) => {
            return {
              ...income,
              notes: income.notes.map((note) =>
                note.ID === notes.ID ? { ...note, ...notes } : note
              ),
            };
          }),
          /** Insurance notes */
          insurance: client.insurance.map((insurance) => {
            return {
              ...insurance,
              notes: insurance.notes.map((note) =>
                note.ID === notes.ID ? { ...note, ...notes } : note
              ),
            };
          }),
          /** Liabilities notes */
          liabilities: {
            ...client.liabilities,
            /** Loans notes */
            loans: client.liabilities.loans.map((loan) => {
              return {
                ...loan,
                notes: loan.notes.map((note) =>
                  note.ID === notes.ID ? { ...note, ...notes } : note
                ),
              };
            }),
            /** Otherliabilities notes */
            otherliabilities: client.liabilities.otherliabilities.map(
              (other) => {
                return {
                  ...other,
                  notes: other.notes.map((note) =>
                    note.ID === notes.ID ? { ...note, ...notes } : note
                  ),
                };
              }
            ),
          },
        };
      }),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  /* commented out fields have their own subscription */
  const notes = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Notes on group data structures */
      /** groups */
      notes: prev.findUniqueGroup.notes.filter((note) => note.ID !== notes.ID),

      /** Entities notes */
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          notes: entity.notes.filter((note) => note.ID !== notes.ID),
        };
      }),

      /** Notes on client data structures */
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          /** Assets notes */
          assets: {
            ...client.assets,
            /** Annuity notes */
            annuity: client.assets.annuity.map((annuity) => {
              return {
                ...annuity,
                notes: annuity.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Cash notes */
            cash: client.assets.cash.map((cash) => {
              return {
                ...cash,
                notes: cash.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Collectibles notes */
            collectibles: client.assets.collectibles.map((collectible) => {
              return {
                ...collectible,
                notes: collectible.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Offset notes */
            offset: client.assets.offset.map((offset) => {
              return {
                ...offset,
                notes: offset.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Otherassets notes */
            otherassets: client.assets.otherassets.map((otherasset) => {
              return {
                ...otherasset,
                notes: otherasset.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Property notes */
            property: client.assets.property.map((property) => {
              return {
                ...property,
                notes: property.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Shares notes */
            shares: client.assets.shares.map((shares) => {
              return {
                ...shares,
                notes: shares.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Superannuation notes */
            superannuation: client.assets.superannuation.map(
              (superannuation) => {
                return {
                  ...superannuation,
                  notes: superannuation.notes.filter(
                    (note) => note.ID !== notes.ID
                  ),
                };
              }
            ),
            /** Vehicles notes */
            vehicles: client.assets.vehicles.map((vehicles) => {
              return {
                ...vehicles,
                notes: vehicles.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
          },
          /** Client notes */
          notes: client.notes.filter((note) => note.ID !== notes.ID),

          /** Goals notes */
          goals: client.goals.map((goal) => {
            return {
              ...goal,
              notes: goal.notes.filter((note) => note.ID !== notes.ID),
            };
          }),
          /** Income notes */
          income: client.income.map((income) => {
            return {
              ...income,
              notes: income.notes.filter((note) => note.ID !== notes.ID),
            };
          }),
          /** Insurance notes */
          insurance: client.insurance.map((insurance) => {
            return {
              ...insurance,
              notes: insurance.notes.filter((note) => note.ID !== notes.ID),
            };
          }),
          /** Salarypackaging notes */
          salarypackaging: client.salarypackaging.map((salarypackaging) => {
            return {
              ...salarypackaging,
              notes: salarypackaging.notes.filter(
                (note) => note.ID !== notes.ID
              ),
            };
          }),

          /** Liabilities notes */
          liabilities: {
            ...client.liabilities,
            /** Loans notes */
            loans: client.liabilities.loans.map((loan) => {
              return {
                ...loan,
                notes: loan.notes.filter((note) => note.ID !== notes.ID),
              };
            }),
            /** Otherliabilities notes */
            otherliabilities: client.liabilities.otherliabilities.map(
              (other) => {
                return {
                  ...other,
                  notes: other.notes.filter((note) => note.ID !== notes.ID),
                };
              }
            ),
          },
        };
      }),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes,
      }),
    variables: query.variables,
  });
}
