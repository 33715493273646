import React, { useState } from "react";

import {
  Content,
  Contenttemplate,
  Theme,
  useFindUniqueOrganisationQuery,
} from "../../../../codegen/schema";
import { Image } from "../../../../Pages/DocumentTemplater/Utlis/Image";
import { ComponentLoader } from "../../../Loaders/ComponentLoader";
import { Refetch } from "../../../Refetch";

interface ImageComponentProps {
  content?: Content | undefined;
  contenttemplate?: Contenttemplate | undefined;
  theme?: Theme;
}

export const ImageComponent: React.FC<ImageComponentProps> = ({
  content,
  contenttemplate,
  theme,
}) => {
  const {
    data: { findUniqueOrganisation: organisation } = {},
    error: organisationError,
    loading: organisationLoading,
    refetch: organisationRefetch,
  } = useFindUniqueOrganisationQuery({ fetchPolicy: "cache-and-network" });

  const [contentObj] = useState(content ? content : contenttemplate);

  if (!organisation && organisationLoading) {
    return <ComponentLoader />;
  }

  if (organisationError && !organisationLoading) {
    return <Refetch refetch={organisationRefetch} />;
  }

  if (!organisation || !contentObj) return null;

  return (
    <div style={{ width: "100%" }}>
      {contentObj?.ImageType === "Background" ? null : (
        <Image
          fileName={contentObj.ImageType!.toLowerCase()}
          filePath={`application/organisation/ID=${organisation?.ID}/theme/ID=${theme?.ID}/images/`}
          id={contentObj.ImageType!}
          label={contentObj.ImageType!}
          width={contentObj.ImageType === "Logo" ? 400 : 100}
        />
      )}
    </div>
  );
};
