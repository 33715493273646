import { QueryResult } from "@apollo/client";
import { FindManyTermsandconditions } from "..";
import {
  FindManyTermsandconditionsQuery,
  FindManyTermsandconditionsQueryVariables,
} from "../../../codegen/schema";

export default function findManyTermsandconditionsSubscription(
  query: Pick<
    QueryResult<
      FindManyTermsandconditionsQuery,
      FindManyTermsandconditionsQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  FindManyTermsandconditions(query);
}
