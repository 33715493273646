import { gql } from "@apollo/client";
import { entitiesFragment } from ".";
/** Entities */
export const createOneEntities = gql`
  mutation CreateOneEntities($data: entitiesCreateInput!) {
    createOneEntities(data: $data) {
      ...entities
    }
  }
  ${entitiesFragment}
`;

export const updateOneEntities = gql`
  mutation UpdateOneEntities(
    $data: entitiesUpdateInput!
    $where: entitiesWhereUniqueInput!
  ) {
    updateOneEntities(data: $data, where: $where) {
      ...entities
    }
  }
  ${entitiesFragment}
`;

export const deleteOneEntities = gql`
  mutation DeleteOneEntities($where: entitiesWhereUniqueInput!) {
    deleteOneEntities(where: $where) {
      ...entities
    }
  }
  ${entitiesFragment}
`;
