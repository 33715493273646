import { gql } from "@apollo/client";
import { collectiblesCoreFragment, collectiblesFragment } from "./fragment";

export const collectibles = gql`
  subscription Collectibles($where: collectiblesWhereUniqueInput) {
    collectibles(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...collectibles
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${collectiblesFragment}
`;

export const collectiblesMany = gql`
  subscription CollectiblesMany($where: collectiblesWhereInput) {
    collectiblesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...collectibles
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${collectiblesCoreFragment}
`;
