import { QueryResult } from "@apollo/client";
import {
  InsuranceSubscription,
  FindUniqueInsuranceQuery,
  MutationType,
  FindUniqueInsuranceQueryVariables,
} from "../../../codegen/schema";
import { insurance } from "../subscription";

interface InsuranceResolverParams {
  prev: FindUniqueInsuranceQuery;
  payload: InsuranceSubscription["insurance"];
}

function updateInsurance({ prev, payload }: InsuranceResolverParams) {
  const insurance = payload.data;

  if (!prev.findUniqueInsurance) return prev;
  return Object.assign({}, prev, {
    findUniqueInsurance:
      insurance.ID === prev.findUniqueInsurance.ID
        ? {
            ...prev.findUniqueInsurance,
            ...insurance,
          }
        : prev.findUniqueInsurance,
  });
}

function deleteInsurance({ prev, payload }: InsuranceResolverParams) {
  const insurance = payload.data;

  if (!prev.findUniqueInsurance) return prev;
  return Object.assign({}, prev, {
    findUniqueInsurance:
      prev.findUniqueInsurance.ID === insurance.ID
        ? {
            ...prev.findUniqueInsurance,
            undefined,
          }
        : prev.findUniqueInsurance,
  });
}

function insuranceResolver({ prev, payload }: InsuranceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createInsurance(prev, payload);

    case MutationType.Update:
      return updateInsurance({ prev, payload });

    case MutationType.Delete:
      return deleteInsurance({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniqueInsurance(
  query: Pick<
    QueryResult<FindUniqueInsuranceQuery, FindUniqueInsuranceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: insurance,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: InsuranceSubscription } }
    ) =>
      insuranceResolver({
        prev,
        payload: payload.subscriptionData.data.insurance
      }),
    variables: query.variables,
  });
}
