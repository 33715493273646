import { gql } from "@apollo/client";
import { scenariocontributionwithdrawalFragment } from ".";
/** Scenariocontributionwithdrawal */
export const createOneScenariocontributionwithdrawal = gql`
  mutation CreateOneScenariocontributionwithdrawal(
    $data: scenariocontributionwithdrawalCreateInput!
  ) {
    createOneScenariocontributionwithdrawal(data: $data) {
      ...scenariocontributionwithdrawal
    }
  }
  ${scenariocontributionwithdrawalFragment}
`;

export const updateOneScenariocontributionwithdrawal = gql`
  mutation UpdateOneScenariocontributionwithdrawal(
    $data: scenariocontributionwithdrawalUpdateInput!
    $where: scenariocontributionwithdrawalWhereUniqueInput!
  ) {
    updateOneScenariocontributionwithdrawal(data: $data, where: $where) {
      ...scenariocontributionwithdrawal
    }
  }
  ${scenariocontributionwithdrawalFragment}
`;

export const deleteOneScenariocontributionwithdrawal = gql`
  mutation DeleteOneScenariocontributionwithdrawal(
    $where: scenariocontributionwithdrawalWhereUniqueInput!
  ) {
    deleteOneScenariocontributionwithdrawal(where: $where) {
      ...scenariocontributionwithdrawal
    }
  }
  ${scenariocontributionwithdrawalFragment}
`;
