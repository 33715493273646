import { gql } from "@apollo/client";
import { adviceCoreFragment, adviceFragment } from "./fragment";

export const findUniqueAdvice = gql`
  query FindUniqueAdvice($where: adviceWhereUniqueInput!) {
    findUniqueAdvice(where: $where) {
      ...advice
    }
  }
  ${adviceFragment}
`;

export const findManyAdvice = gql`
  query FindManyAdvice(
    $where: adviceWhereInput
    $distinct: [AdviceScalarFieldEnum!]
    $orderBy: [adviceOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: adviceWhereUniqueInput
  ) {
    findManyAdvice(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...adviceCore
    }
  }
  ${adviceCoreFragment}
`;

export const findManyAdviceTotalCount = gql`
  query findManyAdviceTotalCount($where: adviceWhereInput) {
    findManyAdviceTotalCount(where: $where)
  }
`;
