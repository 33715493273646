import React, { useEffect, useState } from "react";

import {
  AdviceCoreFragment,
  Content,
  Maybe,
  Scenario,
  Scenarioclient_Type,
  Scenarioinsurance_Type,
  Theme,
} from "../../../../codegen/schema";
import {
  ScenarioclientIncomeTax,
  ScenarioclientIncomeTaxReturn,
} from "../../Models/Utils/Calculations/Scenarioclient";
import { CashflowComparison } from "./Comparison";
import { CashflowIndividual } from "./Individual";
import { StyledText } from "../../StyledText";
import dayjs from "dayjs";

/* Warning: if you make changes to calculations make sure you do BOTH individual and comparison view */

// Commented out rows are not implemented BUT SHOULD BE
export enum IncomeRows {
  Salary = "Salary",
  ExcessConcessionalContributions = "Excess concessional contributions",
  ExcessConcessionalContributionsTaxOffset = "Excess concessional contr. tax offset",
  Pension = "Pension",
  RentalIncome = "Total rental income",
  GrossIncome = "Gross income",
  PropertyNonCashTaxDed = "Property non-cash",
  SalaryPackaging = "Salary packaging",
  IncomeProtection = "Income protection premium",
  PropertyInterestTaxDed = "Investment property interest",
  PropertyExpensesTaxDed = "Investment property expenses",
  PropertyTaxDed = "Total property cash deductions",
  SalarySacrificeCont = "Salary sacrifice contributions",
  AssessableIncome = "Assessable income",
  EstimatedIncomeTax = "Est. income tax",
  SuperannuationTax = "Est. DIV293 & ECC tax paid by super",
  AddBackNonCashTaxDed = "Add back non-cash tax ded.",
  EstimatedNetIncome = "Est. net income",
  EstimatedExpenses = "Est. expenses",
  EstimatedInsurance = "Est. life insurance premiums",
  EstimatedCombinedRepayments = "Est. repayments",
  EstimatedCombinedAssetContributionsFromIncome = "Est. asset  contr. from income",
  EstimatedCombinedLiabilityContributions = "Est. liability contributions",
  CalculatedSurplus = "Calculated surplus",
}

export interface ClientIncome extends ScenarioclientIncomeTaxReturn {
  ID: number;
  Name: string | null | undefined;
  PayDIV293TaxFromIncome: Maybe<number> | undefined;
  PayExcessConcessionalContributionsTaxFromIncome: Maybe<number> | undefined;
}

const incomePlaceholder: ClientIncome[] = [
  {
    Name: "John Smith",
    ID: 1,
    PayDIV293TaxFromIncome: 1,
    PayExcessConcessionalContributionsTaxFromIncome: 1,
    brackets: [
      {
        netIncome: 18200,
        taxPayable: 0,
        perDollar: 0,
      },
      {
        netIncome: 26799,
        taxPayable: 0,
        perDollar: 0.19,
      },
      {
        netIncome: 44613.975,
        taxPayable: 29024.025,
        perDollar: 0.325,
      },
      {
        netIncome: 0,
        taxPayable: 0,
        perDollar: 0.37,
      },
      {
        netIncome: 0,
        taxPayable: 0,
        perDollar: 0.45,
      },
    ],
    insurances: [
      {
        text: "IP test",
        type: Scenarioinsurance_Type.IncomeProtection,
        preimumPerYear: 1000,
      },
    ],
    income: {
      deductions: {
        rentalPropertyExpenses: 1000,
        rentalPropertyInterest: 10000,
        rentalPropertyNonCash: 1000,
        incomeProtectionInsurance: 1000,
        salaryPackage: 0,
        salarySacrifice: 5000,
      },
      estimatedAnnualExpenses: 12000,
      gross: 124639,
      pension: 30000,
      rental: 59000,
      splittingReceived: 0,
      splittingSent: 0,
    },
    deductibleLoans: [],
    rentalProperties: [
      {
        text: "Property 1",
        income: 29000,
        nonCashTaxDeductions: 500,
        expenses: 1000,
      },
      {
        text: "Property 2",
        income: 30000,
        nonCashTaxDeductions: 500,
        expenses: 1000,
      },
    ],
    superannuation: {
      concessionalContributions: 32500,
      excessConcessionalContributions: 2500,
      contributionSplittingReceived: 20625,
      contributionSplittingSent: 0,
      nonConcessionalContributions: 0,
    },
    tax: {
      concessionalContributions: 4875,
      excessConcessionalContributions: 1000,
      excessConcessionalContributionsTaxOffset: 2500 * 0.15,
      div293: 0,
      income: 29024.025,
      medicareLevy: 2372.78,
      nonConcessionalContributions: 0,
      studyRepayments: 0,
    },
    totals: {
      combinedLoanRepayments: 100000,
      combinedScenarioassetContributions: 10000,
      combinedScenarioassetContributionsFromIncome: 10000,
      combinedScenarioliabilityContributions: 5000,
      netIncome: 87242.195,
      superannuationTax: 4875,
      surplus: 75242.195,
      taxPayable: 31396.805,
      taxableIncome: 118639,
    },
  },
  {
    Name: "Jane Smith",
    ID: 2,
    PayDIV293TaxFromIncome: 0,
    PayExcessConcessionalContributionsTaxFromIncome: 0,
    brackets: [
      {
        netIncome: 18200,
        taxPayable: 0,
        perDollar: 0,
      },
      {
        netIncome: 26799,
        taxPayable: 0,
        perDollar: 0.19,
      },
      {
        netIncome: 69907,
        taxPayable: 5092,
        perDollar: 0.325,
      },
      {
        netIncome: 30532,
        taxPayable: 29467,
        perDollar: 0.37,
      },
      {
        netIncome: 32020.349,
        taxPayable: 120137.6501,
        perDollar: 0.45,
      },
    ],
    income: {
      deductions: {
        rentalPropertyExpenses: 0,
        rentalPropertyInterest: 0,
        rentalPropertyNonCash: 0,
        incomeProtectionInsurance: 0,
        salaryPackage: 0,
        salarySacrifice: 0,
      },
      estimatedAnnualExpenses: 0,
      gross: 332159,
      pension: 0,
      rental: 0,
      splittingReceived: 0,
      splittingSent: 0,
    },
    insurances: [],
    deductibleLoans: [],
    rentalProperties: [],
    superannuation: {
      concessionalContributions: 27500,
      contributionSplittingReceived: 0,
      excessConcessionalContributions: 0,
      contributionSplittingSent: 20625,
      nonConcessionalContributions: 0,
    },
    tax: {
      concessionalContributions: 4125,
      excessConcessionalContributions: 0,
      excessConcessionalContributionsTaxOffset: 0,
      div293: 8250,
      income: 154696.6502,
      medicareLevy: 0,
      nonConcessionalContributions: 0,
      studyRepayments: 0,
    },
    totals: {
      combinedLoanRepayments: 100000,
      combinedScenarioassetContributions: 10000,
      combinedScenarioassetContributionsFromIncome: 10000,
      combinedScenarioliabilityContributions: 5000,
      netIncome: 177462.349,
      superannuationTax: 12375,
      surplus: 177462.349,
      taxPayable: 154696.6502,
      taxableIncome: 332159,
    },
  },
];

/**
 * Returns income and cashflow component.
 *
 * NOTE: Net income and calculated surplus are adjusted with salary packaging
 * as directed by Wealthmed.
 */
export const IncomeComponent = ({
  theme,
  content,
  scenario,
  scenarios,
  advice,
  title = true,
}: {
  theme?: Theme;
  content?: Content;
  scenario?: Scenario;
  scenarios?: Scenario[];
  advice?: AdviceCoreFragment;
  title?: boolean;
}) => {
  const [clientsIncome, setClientsIncome] = useState<
    ClientIncome[] | undefined
  >(undefined);

  useEffect(() => {
    if (!scenario && !content) {
      setClientsIncome(incomePlaceholder);
    } else if (content && scenarios) {
      if (scenario) {
        setClientsIncome(
          scenario?.scenarioclient
            .filter(
              (scenarioclient) =>
                scenarioclient.Type === Scenarioclient_Type.Client
            )
            .map((scenarioclient) => ({
              ID: scenarioclient.ID,
              Name: scenarioclient.Name,
              PayDIV293TaxFromIncome: scenarioclient.PayDIV293TaxFromIncome,
              PayExcessConcessionalContributionsTaxFromIncome:
                scenarioclient.PayExcessConcessionalContributionsTaxFromIncome,
              ...ScenarioclientIncomeTax({
                scenarioclient,
                scenario: scenario!,
              }),
            }))
        );
      } else setClientsIncome(undefined);
    } else if (scenario) {
      setClientsIncome(
        scenario.scenarioclient
          .filter(
            (scenarioclient) =>
              scenarioclient.Type === Scenarioclient_Type.Client
          )
          .map((scenarioclient) => ({
            ID: scenarioclient.ID,
            Name: scenarioclient.Name,
            PayDIV293TaxFromIncome: scenarioclient.PayDIV293TaxFromIncome,
            PayExcessConcessionalContributionsTaxFromIncome:
              scenarioclient.PayExcessConcessionalContributionsTaxFromIncome,
            ...ScenarioclientIncomeTax({
              scenarioclient,
              scenario: scenario!,
            }),
          }))
      );
    }
  }, [content, scenario, scenarios]);

  // For comparison view in modelling/cashflow and document
  if (
    (!scenario && !content && scenarios) ||
    (content && scenarios && content?.scenario_ID === null)
  )
    return (
      <React.Fragment>
        {title && (
          <StyledText
            element={theme?.element.find((element) => element.Type === "H4")}
            style={{ margin: 0 }}
          >
            Comparison Cashflow {dayjs(advice?.create_time).format("MMMM YYYY")}
          </StyledText>
        )}
        <CashflowComparison scenarios={scenarios} theme={theme} />
      </React.Fragment>
    );

  // For document template and when a scenario is selected
  if (clientsIncome)
    return (
      <React.Fragment>
        {title && (
          <StyledText
            element={theme?.element.find((element) => element.Type === "H4")}
            style={{ margin: 0 }}
          >
            {scenario?.Title ?? "Scenario "} - Cashflow{" "}
            {dayjs(advice?.create_time).format("MMMM YYYY")}
          </StyledText>
        )}
        <CashflowIndividual clientsIncome={clientsIncome} theme={theme} />
      </React.Fragment>
    );
};
