import { notesFindUniqueInsurance } from "../../notes";
import { FindUniqueInsurance } from "..";
import {
  FindUniqueInsuranceQuery,
  FindUniqueInsuranceQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export default function findUniqueInsuranceSubscription(
  query: Pick<
    QueryResult<FindUniqueInsuranceQuery, FindUniqueInsuranceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindUniqueInsurance(query);
  notesFindUniqueInsurance(query);
}
