import { gql } from "@apollo/client";
import { assetallocationFragment } from "./fragment";

export const assetallocation = gql`
  subscription Assetallocation($where: assetallocationWhereUniqueInput) {
    assetallocation(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...assetallocation
      }
    }
  }
  ${assetallocationFragment}
`;

export const assetallocationMany = gql`
  subscription AssetallocationMany($where: assetallocationWhereInput) {
    assetallocationMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...assetallocation
      }
    }
  }
  ${assetallocationFragment}
`;