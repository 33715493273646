import { QueryResult } from "@apollo/client";
import {
  TeamManySubscription,
  FindManyTeamQuery,
  MutationType,
  FindManyTeamQueryVariables,
} from "../../../codegen/schema";
import { teamMany } from "../subscription";

interface TeamResolverParams {
  prev: FindManyTeamQuery;
  payload: TeamManySubscription["teamMany"];
}

function createTeam({ prev, payload }: TeamResolverParams) {
  const team = payload.data;

  return Object.assign({}, prev, {
    findManyTeam: [...prev.findManyTeam, team],
  });
}

function updateTeam({ prev, payload }: TeamResolverParams) {
  const team = payload.data;

  return Object.assign({}, prev, {
    findManyTeam: prev.findManyTeam.map((entry) =>
      entry.ID === team.ID ? { ...entry, ...team } : entry
    ),
  });
}

function deleteTeam({ prev, payload }: TeamResolverParams) {
  const team = payload.data;

  return Object.assign({}, prev, {
    findManyTeam: [...prev.findManyTeam].filter((entry) => entry.ID !== team.ID),
  });
}

function teamResolver({ prev, payload }: TeamResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createTeam({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyTeam.some((team) => team.ID === data.ID)
        ? updateTeam({ prev, payload })
        : createTeam({ prev, payload });

    case MutationType.Delete:
      return deleteTeam({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyTeam(
  query: Pick<
    QueryResult<FindManyTeamQuery, FindManyTeamQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: teamMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: TeamManySubscription } }
    ) =>
      teamResolver({
        prev,
        payload: payload.subscriptionData.data.teamMany,
      }),
    variables: query.variables,
  });
}
