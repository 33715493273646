import React from "react";
import Lottie from "lottie-react";
import animation from "./lottie-logo/MOAS_logo_animation.json";

export default function FullPageLoader() {
  return (
    <div className="fixed h-screen w-screen left-0 top-0 flex justify-center items-center z-50 bg-neutral-50">
    <Lottie animationData={animation} loop={true} />
    </div>
  );
}
