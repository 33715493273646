import { gql } from "@apollo/client";
import { adviceFragment } from ".";
/** Advice */
export const createOneAdvice = gql`
  mutation CreateOneAdvice($data: adviceCreateInput!) {
    createOneAdvice(data: $data) {
      ...advice
    }
  }
  ${adviceFragment}
`;

export const updateOneAdvice = gql`
  mutation UpdateOneAdvice(
    $data: adviceUpdateInput!
    $where: adviceWhereUniqueInput!
  ) {
    updateOneAdvice(data: $data, where: $where) {
      ...advice
    }
  }
  ${adviceFragment}
`;

export const deleteOneAdvice = gql`
  mutation DeleteOneAdvice($where: adviceWhereUniqueInput!) {
    deleteOneAdvice(where: $where) {
      ...advice
    }
  }
  ${adviceFragment}
`;
