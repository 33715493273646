import { QueryResult } from "@apollo/client";
import {
  AdviceagreementSubscription,
  FindUniqueAdviceagreementQuery,
  FindUniqueAdviceagreementQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { adviceagreement } from "../subscription";

interface AdviceagreementResolverParams {
  prev: FindUniqueAdviceagreementQuery;
  payload: AdviceagreementSubscription["adviceagreement"];
}

function updateAdviceagreement({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const adviceagreement = payload.data;

  if (!prev.findUniqueAdviceagreement) return prev;

  return Object.assign({}, prev, {
    findUniqueAdviceagreement:
      adviceagreement.ID === prev.findUniqueAdviceagreement.ID
        ? {
            ...prev.findUniqueAdviceagreement,
            ...adviceagreement,
          }
        : { ...prev.findUniqueAdviceagreement },
  });
}

// function deleteAdviceagreement({
//   prev,
//   payload,
// }: AdviceagreementResolverParams) {
//   const adviceagreement = payload.data;

//   if (!prev.findUniqueAdviceagreement) return prev;

//   return Object.assign({}, prev, {
//     findUniqueAdviceagreement:
//       adviceagreement.ID === prev.findUniqueAdviceagreement.ID
//         ? {
//             ...prev.findUniqueAdviceagreement,
//             ...adviceagreement,
//           }
//         : prev.findUniqueAdviceagreement,
//   });
// }

function adviceagreementResolver({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createAdviceagreement(prev, payload);

    case MutationType.Update:
      return updateAdviceagreement({ prev, payload });

    // case MutationType.Delete:
    //   return deleteAdviceagreement({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniqueAdviceagreement(
  query: Pick<
    QueryResult<
      FindUniqueAdviceagreementQuery,
      FindUniqueAdviceagreementQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: adviceagreement,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceagreementSubscription } }
    ) =>
      adviceagreementResolver({
        prev,
        payload: payload.subscriptionData.data.adviceagreement
      }),
    variables: query.variables,
  });
}
