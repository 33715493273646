import { gql } from "@apollo/client";

export const pagetemplateFragment = gql`
  fragment pagetemplate on pagetemplate {
    ID
    sectiontemplate_ID
    sectiontemplate_documenttemplate_ID
    sectiontemplate_documenttemplate_organisation_ID
    Title
    Position
    Locked
    PageNumber
    Footer
    create_time
    update_time
    created_by
    updated_by
  }
`;