import React from "react";
import { useFindUniqueThemeQuery } from "../../../../../codegen/schema";
import { ComponentLoader } from "../../../../Loaders/ComponentLoader";
import { Refetch } from "../../../../Refetch";

interface ColourInputThemeColoursProps {
  themeID: number;
  setColor: Function /* set parent state colour function */;
}

export const ColourInputThemeColours: React.FC<
  ColourInputThemeColoursProps
> = ({ themeID, setColor }) => {
  const {
    data: { findUniqueTheme: theme } = {},
    error: themeError,
    loading: themeLoading,
    refetch: themeRefetch,
  } = useFindUniqueThemeQuery({
    fetchPolicy: "cache-and-network",
    variables: { where: { ID: themeID } },
  });

  if (!theme && themeLoading) {
    return <ComponentLoader />;
  }

  if (themeError && !themeLoading) {
    return <Refetch refetch={themeRefetch} />;
  }

  if (!theme) return null;

  return (
    <React.Fragment>
      <div
        className="flex flex-wrap gap-y-3 gap-x-1 items-center"
      >
        {theme.colour.map((colour) => {
          if (!colour.Value) return null;
          return (
            <div
              className="icon fa-solid fa-circle text-lg cursor-pointer"
              key={colour.ID}
              style={{
                color: colour.Value,
              }}
              onClick={() => {
                setColor(colour.Value);
              }}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};
