import { QueryResult } from "@apollo/client";
import {
  MutationType,
  FindUniqueGroupQuery,
  AssetallocationSubscription,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { assetallocation } from "../subscription";

interface AssetallocationResolverParams {
  prev: FindUniqueGroupQuery;
  payload: AssetallocationSubscription["assetallocation"];
}

function createAssetallocation({
  prev,
  payload,
}: AssetallocationResolverParams) {
  const assetallocation = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            annuity: client.assets.annuity.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            cash: client.assets.cash.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            collectibles: client.assets.collectibles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            offset: client.assets.offset.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            otherassets: client.assets.otherassets.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            property: client.assets.property.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            shares: client.assets.shares.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            superannuation: client.assets.superannuation.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            vehicles: client.assets.vehicles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            annuity: entity.assets.annuity.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            cash: entity.assets.cash.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            collectibles: entity.assets.collectibles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            offset: entity.assets.offset.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            otherassets: entity.assets.otherassets.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            property: entity.assets.property.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            shares: entity.assets.shares.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            superannuation: entity.assets.superannuation.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
            vehicles: entity.assets.vehicles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation,
                  }
                : asset
            ),
          },
        };
      }),
    },
  });
}

function updateAssetallocation({
  prev,
  payload,
}: AssetallocationResolverParams) {
  const assetallocation = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            annuity: client.assets.annuity.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            cash: client.assets.cash.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            collectibles: client.assets.collectibles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            offset: client.assets.offset.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            otherassets: client.assets.otherassets.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            property: client.assets.property.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            shares: client.assets.shares.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            superannuation: client.assets.superannuation.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            vehicles: client.assets.vehicles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            annuity: entity.assets.annuity.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            cash: entity.assets.cash.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            collectibles: entity.assets.collectibles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            offset: entity.assets.offset.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            otherassets: entity.assets.otherassets.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            property: entity.assets.property.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            shares: entity.assets.shares.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            superannuation: entity.assets.superannuation.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
            vehicles: entity.assets.vehicles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation: asset.assetallocation
                      ? asset.assetallocation.ID === assetallocation.ID
                        ? {
                            ...asset.assetallocation,
                            ...assetallocation,
                          }
                        : asset.assetallocation
                      : asset.assetallocation,
                  }
                : asset
            ),
          },
        };
      }),
    },
  });
}

function deleteAssetallocation({
  prev,
  payload,
}: AssetallocationResolverParams) {
  const assetallocation = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            annuity: client.assets.annuity.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            cash: client.assets.cash.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            collectibles: client.assets.collectibles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            offset: client.assets.offset.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            otherassets: client.assets.otherassets.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            property: client.assets.property.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            shares: client.assets.shares.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            superannuation: client.assets.superannuation.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            vehicles: client.assets.vehicles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            annuity: entity.assets.annuity.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            cash: entity.assets.cash.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            collectibles: entity.assets.collectibles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            offset: entity.assets.offset.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            otherassets: entity.assets.otherassets.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            property: entity.assets.property.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            shares: entity.assets.shares.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            superannuation: entity.assets.superannuation.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
            vehicles: entity.assets.vehicles.map((asset) =>
              asset.assets_ID === assetallocation.assets_ID
                ? {
                    ...asset,
                    assetallocation:
                      asset.assetallocation?.ID === assetallocation.ID
                        ? null
                        : asset.assetallocation,
                  }
                : asset
            ),
          },
        };
      }),
    },
  });
}

function assetallocationResolver({
  prev,
  payload,
}: AssetallocationResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAssetallocation({ prev, payload });

    case MutationType.Update:
      return updateAssetallocation({ prev, payload });

    case MutationType.Delete:
      return deleteAssetallocation({ prev, payload });

    default:
      return prev;
  }
}

export function assetallocationFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: assetallocation,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AssetallocationSubscription } }
    ) =>
      assetallocationResolver({
        prev,
        payload: payload.subscriptionData.data.assetallocation,
      }),
    variables: query.variables,
  });
}
