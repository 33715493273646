import { QueryResult } from "@apollo/client";
import {
  InterestSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { interest } from "../subscription";

interface InterestResolverParams {
  prev: FindUniqueGroupQuery;
  payload: InterestSubscription["interest"];
}

function updateInterest({ prev, payload }: InterestResolverParams) {
  const interest = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          interest:
            client.interest.ID === interest.ID
              ? { ...client.interest, ...interest }
              : client.interest,
        };
      }),
    },
  });
}

function interestResolver({ prev, payload }: InterestResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createInterest({ prev, payload });

    case MutationType.Update:
      return updateInterest({ prev, payload });

    // case MutationType.Delete:
    //   return deleteInterest({ prev, payload });

    default:
      return prev;
  }
}

export function interestFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: interest,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: InterestSubscription } }
    ) =>
      interestResolver({
        prev,
        payload: payload.subscriptionData.data.interest
      }),
    variables: query.variables,
  });
}
