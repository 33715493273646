import { QueryResult } from "@apollo/client";
import {
  InsuranceManySubscription,
  FindManyInsuranceQuery,
  MutationType,
  FindManyInsuranceQueryVariables,
} from "../../../codegen/schema";
import { insuranceMany } from "../subscription";

interface InsuranceResolverParams {
  prev: FindManyInsuranceQuery;
  payload: InsuranceManySubscription["insuranceMany"];
}

function createInsurance({ prev, payload }: InsuranceResolverParams) {
  const insurance = payload.data;

  return Object.assign({}, prev, {
    findManyInsurance: [...prev.findManyInsurance, insurance],
  });
}

function updateInsurance({ prev, payload }: InsuranceResolverParams) {
  const insurance = payload.data;

  return Object.assign({}, prev, {
    findManyInsurance: prev.findManyInsurance.map((entry) =>
      entry.ID === insurance.ID ? { ...entry, ...insurance } : entry
    ),
  });
}

function deleteInsurance({ prev, payload }: InsuranceResolverParams) {
  const insurance = payload.data;

  return Object.assign({}, prev, {
    findManyInsurance: [...prev.findManyInsurance].filter(
      (entry) => entry.ID !== insurance.ID
    ),
  });
}

function insuranceResolver({ prev, payload }: InsuranceResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createInsurance({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyInsurance.some(
        (insurance) => insurance.ID === data.ID
      )
        ? updateInsurance({ prev, payload })
        : createInsurance({ prev, payload });

    case MutationType.Delete:
      return deleteInsurance({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyInsurance(
  query: Pick<
    QueryResult<FindManyInsuranceQuery, FindManyInsuranceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: insuranceMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: InsuranceManySubscription } }
    ) =>
      insuranceResolver({
        prev,
        payload: payload.subscriptionData.data.insuranceMany,
      }),
    variables: query.variables,
  });
}
