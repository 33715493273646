import { gql } from "@apollo/client";
import { superannuationFragment } from "./fragment";

export const superannuation = gql`
  subscription Superannuation($where: superannuationWhereUniqueInput) {
    superannuation(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...superannuation
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${superannuationFragment}
`;

export const superannuationMany = gql`
  subscription SuperannuationMany($where: superannuationWhereInput) {
    superannuationMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...superannuation
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${superannuationFragment}
`;
