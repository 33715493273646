import { QueryResult } from "@apollo/client";
import {
  GoalSubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { goal } from "../subscription";

interface GoalResolverParams {
  prev: FindUniqueModelQuery;
  payload: GoalSubscription["goal"];
}

function updateGoal({ prev, payload }: GoalResolverParams) {
  const goal = payload.data;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      goal: prev.findUniqueModel.goal.map((goals) =>
        goals.ID === goal.ID ? { ...goals, ...goal } : goals
      ),
    },
  });
}

function deleteGoal({ prev, payload }: GoalResolverParams) {
  const goal = payload.data;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      goal: [...prev.findUniqueModel.goal].filter(
        (goals) => goals.ID !== goal.ID
      ),
    },
  });
}

function goalResolver({ prev, payload }: GoalResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createGoal({ prev, payload });

    case MutationType.Update:
      return updateGoal({ prev, payload });

    case MutationType.Delete:
      return deleteGoal({ prev, payload });

    default:
      return prev;
  }
}

export function goalFindUniqueModel(query: Pick<
    QueryResult<
      FindUniqueModelQuery,
      FindUniqueModelQueryVariables
    >,
    "subscribeToMore" | "variables"
  >) {
  query.subscribeToMore({
    document: goal,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: GoalSubscription } }
    ) =>
      goalResolver({
        prev,
        payload: payload.subscriptionData.data.goal
      }),
    variables: query.variables,
  });
}
