import { QueryResult } from "@apollo/client";
import {
  ModelSubscription,
  FindUniqueAdviceQuery,
  MutationType,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { model } from "../subscription";

interface ModelResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: ModelSubscription["model"];
}

function createModel({ prev, payload }: ModelResolverParams) {
  const { data: model, model_has_advice } = payload;

  if (!prev.findUniqueAdvice) return prev;
  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      model: model_has_advice.some(
        (relation) =>
          relation.model_ID === model.ID &&
          relation.advice_ID === prev.findUniqueAdvice?.ID
      )
        ? [...prev.findUniqueAdvice.model, model]
        : prev.findUniqueAdvice.model,
    },
  });
}

function updateModel({ prev, payload }: ModelResolverParams) {
  const { data: model, model_has_advice } = payload;

  if (!prev.findUniqueAdvice) return prev;
  // model_has_advice here only has relations for the one model
  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      /** If model already in advice.model, update, else add to array if model_has_advice */
      model:
        // if no relations remove from array
        !model_has_advice.some(
          (relation) =>
            relation.model_ID === model.ID &&
            relation.advice_ID === prev.findUniqueAdvice?.ID
        )
          ? prev.findUniqueAdvice.model.filter((entry) => entry.ID !== model.ID)
          : // if relation and not in array, add
          model_has_advice.some(
              (relation) =>
                relation.model_ID === model.ID &&
                relation.advice_ID === prev.findUniqueAdvice?.ID
            ) &&
            !prev.findUniqueAdvice.model.some((entry) => entry.ID === model.ID)
          ? [...prev.findUniqueAdvice.model, model]
          : // else if in array and relation, update
            prev.findUniqueAdvice.model.map((entry) =>
              entry.ID === model.ID ? { ...entry, ...model } : entry
            ),
    },
  });
}

function deleteModel({ prev, payload }: ModelResolverParams) {
  const model = payload.data;

  if (!prev.findUniqueAdvice) return prev;
  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      model: [...prev.findUniqueAdvice.model].filter(
        (models) => models.ID !== model.ID
      ),
    },
  });
}

function modelResolver({ prev, payload }: ModelResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createModel({ prev, payload });

    case MutationType.Update:
      return updateModel({ prev, payload });

    case MutationType.Delete:
      return deleteModel({ prev, payload });

    default:
      return prev;
  }
}

export function modelFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: model,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ModelSubscription } }
    ) =>
      modelResolver({
        prev,
        payload: payload.subscriptionData.data.model,
      }),
    variables: query.variables,
  });
}
