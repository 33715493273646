import React from "react";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";
import {
  Content,
  Contenttemplate,
  Theme,
  FindUniqueGroupQuery,
  FindUniqueAdviceQuery,
  Goal,
} from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";

interface InsuranceComponentProps {
  content?: Content | undefined;
  contenttemplate?: Contenttemplate | undefined;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const GoalGrouped: React.FC<InsuranceComponentProps> = ({
  theme,
  group,
  content,
}) => {
  type GoalType = {
    ID: number;
    Title: string;
    Description: string;
  };

  const goals: Goal[] | GoalType[] = group
    ? content
      ? [...group.goal]
      : []
    : // In the template
      [
        {
          ID: 1,
          Title: "Goal Title",
          Description: "Description",
        },
        {
          ID: 2,
          Title: "Goal Title",
          Description: "Description",
        },
        {
          ID: 3,
          Title: "Goal Title",
          Description: "Description",
        },
        {
          ID: 4,
          Title: "Goal Title",
          Description: "Description",
        },
      ];

  return (
    <React.Fragment>
      <div
        className="flex flex-col w-full"
        style={{
          borderBottom: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
        }}
      >
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          
        >
          Goals
        </StyledText>
      </div>

      {goals.length === 0 ? (
        <h5>No goals recorded</h5>
      ) : (
        goals.map((goal) => {
          return (
            <div key={goal.ID}>
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H5"
                )}
              >
                {checkFieldIsEmpty(goal.Title)}
              </StyledText>

              <StyledText
                element={theme?.element.find((element) => element.Type === "P")}
              >
                {checkFieldIsEmpty(goal.Description)}
              </StyledText>
            </div>
          );
        })
      )}
    </React.Fragment>
  );
};
