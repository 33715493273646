import React from "react";
import { getGroupAssets } from "../../../../../../Functions/GeneralFunctions";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../../codegen/schema";

interface AssetsSuperannuationProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns superannuation component for advice template && document.
 */
export const AssetsSuperannuationIndividual: React.FC<
  AssetsSuperannuationProps
> = ({ content, contenttemplate, theme, group }) => {
  type Superannuation = {
    assets_ID: number | null;
    FundName: string;
    Owner: string;
    Value: string;
  };

  const summarySuperannuation: Superannuation = group
    ? content?.asset_ID
      ? getGroupAssets(group).superannuation.find(
          (superannuation: Superannuation) =>
            content?.asset_ID === superannuation.assets_ID
        )
      : {}
    : { FundName: "Mason Stevens", Owner: "Jane Smith", Value: "$600,000" };

  return <React.Fragment>Individual Super view {summarySuperannuation.FundName}</React.Fragment>;
};
