import { gql } from "@apollo/client";
import { reviewCoreFragment, reviewFragment } from "./fragment";

export const findReviewPDF = gql`
  query FindReviewPDF($id: Int!) {
    findReviewPDF(ID: $id)
  }
`;

export const findUniqueReview = gql`
  query FindUniqueReview($where: reviewWhereUniqueInput!) {
    findUniqueReview(where: $where) {
      ...review
    }
  }
  ${reviewFragment}
`;

export const findManyReview = gql`
  query FindManyReview(
    $where: reviewWhereInput
    $distinct: [ReviewScalarFieldEnum!]
    $orderBy: [reviewOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: reviewWhereUniqueInput
  ) {
    findManyReview(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...reviewCore
    }
  }
  ${reviewCoreFragment}
`;
