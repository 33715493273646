import React, { useEffect } from "react";
import { findUniqueFileSubscription } from "../../../API/file";
import {
  useFindManyStaffQuery,
  useFindUniqueFileQuery,
} from "../../../codegen/schema";

import { getSelectedStaff } from "../../../Functions/GeneralFunctions";
import { ComponentLoader } from "../../Loaders/ComponentLoader";
import { Tooltip } from "../../Tooltip";

interface AvatarProps {
  staff_ID: number | null | undefined;
  filePath: string;
}
/**
 * @date 13-01-2022
 * @updated 24-05-2024
 */
export const Avatar = ({ staff_ID, filePath }: AvatarProps) => {
  const { data: { findUniqueFile: file } = {}, ...findUniqueFileResult } =
    useFindUniqueFileQuery({
      fetchPolicy: "cache-and-network",
      skip: !filePath,
      variables: {
        filePath: filePath,
      },
    });

  const {
    data: { findManyStaff: allStaff } = {},
    error: allStaffError,
    loading: allStaffLoading,
  } = useFindManyStaffQuery();

  useEffect(() => {
    findUniqueFileSubscription(findUniqueFileResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var staffMember =
    allStaff && staff_ID
      ? getSelectedStaff(staff_ID, "object", allStaff)
      : undefined;

  return allStaff && !allStaffLoading && !allStaffError && staffMember ? (
    file && file.fileExists && staffMember ? (
      <img
        className="relative h-8 w-8 rounded-full bg-neutral-50 shadow-sm z-5"
        alt={"Profile"}
        src={
          process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
          "/" +
          filePath +
          "#" +
          new Date().getTime()
        }
      />
    ) : (
      <Tooltip title={staffMember.FirstName + " " + staffMember.LastName}>
        <div
          className="relative flex items-center justify-center h-8 w-8 text-xs rounded-full
         bg-neutral-50 shadow-sm font-semibold text-neutral-800 z-5"
        >
          {staffMember
            ? getSelectedStaff(staffMember.ID, "initials", allStaff)
            : ""}
        </div>
      </Tooltip>
    )
  ) : (
    <ComponentLoader />
  );
};
