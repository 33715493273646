import { QueryResult } from "@apollo/client";
import {
  ScenarioinsuranceSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { scenarioinsurance } from "../subscription";

interface ScenarioinsuranceResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ScenarioinsuranceSubscription["scenarioinsurance"];
}

function createScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioinsurance.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioinsurance.scenario_ID
                  ? {
                      ...scenario,
                      scenarioinsurance: [
                        ...scenario.scenarioinsurance,
                        scenarioinsurance,
                      ],
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function updateScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioinsurance.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioinsurance.scenario_ID
                  ? {
                      ...scenario,
                      scenarioinsurance: scenario.scenarioinsurance.map(
                        (scenarioinsurances) =>
                          scenarioinsurances.ID === scenarioinsurance.ID
                            ? { ...scenarioinsurances, ...scenarioinsurance }
                            : scenarioinsurances
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function deleteScenarioinsurance({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const scenarioinsurance = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioinsurance.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioinsurance.scenario_ID
                  ? {
                      ...scenario,
                      scenarioinsurance: scenario.scenarioinsurance.filter(
                        (scenarioinsurances) =>
                          scenarioinsurances.ID !== scenarioinsurance.ID
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function scenarioinsuranceResolver({
  prev,
  payload,
}: ScenarioinsuranceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioinsurance({ prev, payload });

    case MutationType.Update:
      return updateScenarioinsurance({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioinsurance({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioinsuranceFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioinsurance,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioinsuranceSubscription };
      }
    ) =>
      scenarioinsuranceResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioinsurance,
      }),
    variables: query.variables,
  });
}
