import { gql } from "@apollo/client";
import { employmentCoreFragment, employmentFragment } from "./fragment";

export const findUniqueEmployment = gql`
  query FindUniqueEmployment($where: employmentWhereUniqueInput!) {
    findUniqueEmployment(where: $where) {
      ...employment
    }
  }
  ${employmentFragment}
`;

export const findManyEmployment = gql`
  query FindManyEmployment(
    $where: employmentWhereInput
    $distinct: [EmploymentScalarFieldEnum!]
    $orderBy: [employmentOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: employmentWhereUniqueInput
  ) {
    findManyEmployment(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...employmentCore
    }
  }
  ${employmentCoreFragment}
`;
