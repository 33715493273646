import { scenarioassetFindUniqueScenario } from "../../scenarioasset";
import { scenariochildFindUniqueScenario } from "../../scenariochild";
import { scenarioclientFindUniqueScenario } from "../../scenarioclient";
import { scenariocontributionwithdrawalFindUniqueScenario } from "../../scenariocontributionwithdrawal";
import { scenarioinsuranceFindUniqueScenario } from "../../scenarioinsurance";
import { scenarioliabilityFindUniqueScenario } from "../../scenarioliability";
import { scenarioFindUniqueScenario } from "..";
import {
  FindUniqueScenarioQuery,
  FindUniqueScenarioQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export default function findUniqueScenarioSubscription(
  query: Pick<
    QueryResult<FindUniqueScenarioQuery, FindUniqueScenarioQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  scenarioFindUniqueScenario(query);
  scenarioassetFindUniqueScenario(query);
  scenarioliabilityFindUniqueScenario(query);
  scenarioclientFindUniqueScenario(query);
  scenariocontributionwithdrawalFindUniqueScenario(query);
  scenarioinsuranceFindUniqueScenario(query);
  scenariochildFindUniqueScenario(query);
}
