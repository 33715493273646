import { gql } from "@apollo/client";
import { tagFragment } from "./fragment";

export const tag = gql`
  subscription Tag($where: tagWhereUniqueInput) {
    tag(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...tag
      }
    }
  }
  ${tagFragment}
`;

export const tagMany = gql`
  subscription TagMany($where: tagWhereInput) {
    tagMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...tag
      }
    }
  }
  ${tagFragment}
`;
