import { QueryResult } from "@apollo/client";
import {
  ServicesSubscription,
  FindUniqueAdviceagreementQuery,
  MutationType,
  FindUniqueAdviceagreementQueryVariables,
} from "../../../codegen/schema";
import { services } from "../subscription";

interface ServicesResolverParams {
  prev: FindUniqueAdviceagreementQuery;
  payload: ServicesSubscription["services"];
}

function createServices({ prev, payload }: ServicesResolverParams) {
  const services = payload.data;

  if (!prev.findUniqueAdviceagreement) return prev;
  return Object.assign({}, prev, {
    findUniqueAdviceagreement: {
      ...prev.findUniqueAdviceagreement,
      services:
        services.adviceagreement_ID === prev.findUniqueAdviceagreement.ID
          ? [...prev.findUniqueAdviceagreement.services, services]
          : prev.findUniqueAdviceagreement.services,
    },
  });
}

function updateServices({ prev, payload }: ServicesResolverParams) {
  const services = payload.data;

  if (!prev.findUniqueAdviceagreement) return prev;
  return Object.assign({}, prev, {
    findUniqueAdviceagreement: {
      ...prev.findUniqueAdviceagreement,
      services: prev.findUniqueAdviceagreement.services.map((service) =>
        service.ID === services.ID ? { ...service, ...services } : service
      ),
    },
  });
}

function deleteServices({ prev, payload }: ServicesResolverParams) {
  const services = payload.data;

  if (!prev.findUniqueAdviceagreement) return prev;
  return Object.assign({}, prev, {
    findUniqueAdviceagreement: {
      ...prev.findUniqueAdviceagreement,
      services: [...prev.findUniqueAdviceagreement.services].filter(
        (service) => service.ID !== services.ID
      ),
    },
  });
}

function servicesResolver({ prev, payload }: ServicesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createServices({ prev, payload });

    case MutationType.Update:
      return updateServices({ prev, payload });

    case MutationType.Delete:
      return deleteServices({ prev, payload });

    default:
      return prev;
  }
}

export function servicesFindUniqueAdviceagreement(
  query: Pick<
    QueryResult<
      FindUniqueAdviceagreementQuery,
      FindUniqueAdviceagreementQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: services,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ServicesSubscription } }
    ) =>
      servicesResolver({
        prev,
        payload: payload.subscriptionData.data.services,
      }),
    variables: query.variables,
  });
}
