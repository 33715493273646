import dayjs from "dayjs";
import {
  Scenarioasset,
  Scenariocontributionwithdrawal_Frequency,
  Scenariocontributionwithdrawal_OngoingOrOneoff,
  Scenariocontributionwithdrawal_Type,
  Scenarioliability,
} from "../../../../../../codegen/schema";

interface ScenariocontributionwithdrwawalCalculatedProps {
  scenarioitem: Scenarioasset | Scenarioliability;
}

interface ScenariocontributionwithdrwawalCalculatedReturn {
  year: number;
  contributions: number;
  contributionsFromIncome: number;
  withdrawals: number;
}

/**
 * @description Returns an array of conrtibutions and withdrawals by year.
 */
export function ScenariocontributionwithdrwawalCalculated({
  scenarioitem,
}: ScenariocontributionwithdrwawalCalculatedProps): ScenariocontributionwithdrwawalCalculatedReturn[] {
  if (!scenarioitem.scenariocontributionwithdrawal) return [];

  var data: ScenariocontributionwithdrwawalCalculatedReturn[] = [];

  scenarioitem.scenariocontributionwithdrawal?.forEach((cw) => {
    // If one off contribution or withdrawal
    if (
      cw?.OngoingOrOneoff ===
        Scenariocontributionwithdrawal_OngoingOrOneoff.OneOff &&
      dayjs(cw?.OneOffDate).isValid()
    ) {
      // If year already exists
      if (data.some((entry) => entry.year === dayjs(cw?.OneOffDate).year())) {
        data = data.map((entry) =>
          entry.year === dayjs(cw?.OneOffDate).year()
            ? {
                ...entry,
                contributions:
                  entry.contributions +
                  (cw?.Type === Scenariocontributionwithdrawal_Type.Contribution
                    ? cw.Value
                    : 0),
                contributionsFromIncome:
                  entry.contributionsFromIncome +
                  (cw?.Type ===
                    Scenariocontributionwithdrawal_Type.Contribution &&
                  cw.FromIncome
                    ? cw.Value
                    : 0),
                withdrawals:
                  entry.withdrawals +
                  (cw?.Type === Scenariocontributionwithdrawal_Type.Withdrawal
                    ? cw.Value
                    : 0),
              }
            : entry
        );
      }
      // If year does not yet exist
      else {
        data.push({
          year: dayjs(cw?.OneOffDate).year(),
          contributions:
            cw?.Type === Scenariocontributionwithdrawal_Type.Contribution
              ? cw.Value
              : 0,
          contributionsFromIncome:
            cw?.Type === Scenariocontributionwithdrawal_Type.Contribution &&
            cw.FromIncome
              ? cw.Value
              : 0,
          withdrawals:
            cw?.Type === Scenariocontributionwithdrawal_Type.Withdrawal
              ? cw.Value
              : 0,
        });
      }
    }
    // If ongoing contribution or withdrawal
    else if (
      cw?.OngoingOrOneoff ===
        Scenariocontributionwithdrawal_OngoingOrOneoff.Ongoing &&
      dayjs(cw?.StartDate).isValid() &&
      dayjs(cw?.EndDate).isValid()
    ) {
      // Calculate amount per year
      var calculatedValue =
        cw?.Frequency === Scenariocontributionwithdrawal_Frequency.Weekly
          ? cw?.Value * 52
          : cw?.Frequency ===
            Scenariocontributionwithdrawal_Frequency.Fortnightly
          ? cw?.Value * 26
          : cw?.Frequency === Scenariocontributionwithdrawal_Frequency.Monthly
          ? cw?.Value * 12
          : cw?.Value;

      // Find amount of years from start to end
      var diffYears = dayjs(cw?.StartDate).diff(dayjs(cw?.EndDate));

      // Loop for each year
      Array.from(Array(diffYears).keys()).forEach((year) => {
        // Calculate the actual year (i.e. not 0,1,2 but 2020, 2021, 2022)
        var currentYear = dayjs(cw?.StartDate).add(year, "years").year();

        // If year already exists, add to it
        if (data.some((entry) => entry.year === currentYear)) {
          data = data.map((entry) =>
            entry.year === currentYear
              ? {
                  ...entry,
                  contributions:
                    entry.contributions +
                    (cw?.Type ===
                    Scenariocontributionwithdrawal_Type.Contribution
                      ? calculatedValue
                      : 0),
                  contributionsFromIncome:
                    entry.contributionsFromIncome +
                    (cw?.Type ===
                      Scenariocontributionwithdrawal_Type.Contribution &&
                    cw.FromIncome
                      ? calculatedValue
                      : 0),
                  withdrawals:
                    entry.withdrawals +
                    (cw?.Type === Scenariocontributionwithdrawal_Type.Withdrawal
                      ? calculatedValue
                      : 0),
                }
              : entry
          );
        }
        // Else push new object to data
        else {
          data.push({
            year: currentYear,
            contributions:
              cw?.Type === Scenariocontributionwithdrawal_Type.Contribution
                ? calculatedValue
                : 0,
            contributionsFromIncome:
              cw?.Type === Scenariocontributionwithdrawal_Type.Contribution &&
              cw.FromIncome
                ? calculatedValue
                : 0,
            withdrawals:
              cw?.Type === Scenariocontributionwithdrawal_Type.Withdrawal
                ? calculatedValue
                : 0,
          });
        }
      });
    }
  });

  return data;
}

interface ScenariocontributionwithdrwawalCalculatedForYearProps {
  scenarioitem: Scenarioasset | Scenarioliability;
  /** Year as 2022, 2023, etc. Not 0, 1, 2. */
  year: number;
}

/**
 * @description Returns an array of conrtibutions and withdrawals by year.
 */
export function ScenariocontributionwithdrwawalCalculatedForYear({
  scenarioitem,
  /** Current year plus the year of the contribution/withdrawal */
  year,
}: ScenariocontributionwithdrwawalCalculatedForYearProps): ScenariocontributionwithdrwawalCalculatedReturn {
  var data: ScenariocontributionwithdrwawalCalculatedReturn = {
    year,
    contributions: 0,
    contributionsFromIncome: 0,
    withdrawals: 0,
  };

  scenarioitem.scenariocontributionwithdrawal?.forEach((cw) => {
    // If one off contribution or withdrawal
    if (
      cw?.OngoingOrOneoff ===
        Scenariocontributionwithdrawal_OngoingOrOneoff.OneOff &&
      dayjs(cw?.OneOffDate).isValid()
    ) {
      // If year already exists
      if (year === dayjs(cw?.OneOffDate).year()) {
        var value = cw.Value;

        // Check for indexation
        if (cw.Indexation) {
          value =
            value * Math.pow(1 + cw.Indexation / 100, year - dayjs().year());
        }

        data.contributions +=
          cw?.Type === Scenariocontributionwithdrawal_Type.Contribution
            ? value
            : 0;

        data.contributionsFromIncome +=
          cw?.Type === Scenariocontributionwithdrawal_Type.Contribution &&
          cw.FromIncome
            ? value
            : 0;

        data.withdrawals +=
          cw?.Type === Scenariocontributionwithdrawal_Type.Withdrawal
            ? value
            : 0;
      }
    }
    // If ongoing contribution or withdrawal
    else if (
      cw?.OngoingOrOneoff ===
        Scenariocontributionwithdrawal_OngoingOrOneoff.Ongoing &&
      dayjs(cw?.StartDate).isValid() &&
      dayjs(cw?.EndDate).isValid()
    ) {
      if (
        dayjs(cw.StartDate).year() <= year &&
        dayjs(cw.EndDate).year() >= year
      ) {
        // Calculate amount per year
        var calculatedValue =
          cw?.Frequency === Scenariocontributionwithdrawal_Frequency.Weekly
            ? cw?.Value * 52
            : cw?.Frequency ===
              Scenariocontributionwithdrawal_Frequency.Fortnightly
            ? cw?.Value * 26
            : cw?.Frequency === Scenariocontributionwithdrawal_Frequency.Monthly
            ? cw?.Value * 12
            : cw?.Value;

        // Check for indexation
        if (cw.Indexation) {
          calculatedValue =
            calculatedValue *
            Math.pow(1 + cw.Indexation / 100, year - dayjs().year());
        }

        data.contributions +=
          cw?.Type === Scenariocontributionwithdrawal_Type.Contribution
            ? calculatedValue
            : 0;

        data.contributionsFromIncome +=
          cw?.Type === Scenariocontributionwithdrawal_Type.Contribution &&
          cw.FromIncome
            ? calculatedValue
            : 0;

        data.withdrawals +=
          cw?.Type === Scenariocontributionwithdrawal_Type.Withdrawal
            ? calculatedValue
            : 0;
      }
    }
  });

  return data;
}
