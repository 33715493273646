import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";
import { otherliabilitiesCoreFragment } from "../otherliabilities";

export const offset = gql`
  subscription Offset($where: offsetWhereUniqueInput) {
    offset(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ID
        assets_ID
        Bank
        BSB
        AccountNumber
        Amount
        RelatedLoan
        Purpose
        OwnershipType
        create_time
        update_time
        created_by
        updated_by

        assetallocation {
          ...assetallocation
        }
        liabilities {
          loans {
            ID
            liabilities_ID
            Bank
            BSB
            AccountNumber
            TaxDeductible
            Purpose
            SecuredBy
            RelatedAsset
            InterestRate
            VariableFixed
            FixedExpiry
            InterestOnlyExpiry
            RepaymentPerMonth
            LoanLimit
            AvailableRedraw
            RepaymentType
            LoanOwnership
            CurrentLoanOutstanding
            LoanType
            LoanStartDate
            LoanExpiry
            Description
            InterestOnlyStartDate
            RateReviewedDate
            Offset
            Position
            Refinance
            create_time
            update_time
            created_by
            updated_by
          }
          otherliabilities {
            ...otherliabilitiesCore
          }
        }
        notes {
          ...notes
        }
        history {
          ...history
        }
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${assetallocationFragment}
  ${otherliabilitiesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;

export const offsetMany = gql`
  subscription OffsetMany($where: offsetWhereInput) {
    offsetMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ID
        assets_ID
        Bank
        BSB
        AccountNumber
        Amount
        RelatedLoan
        Purpose
        OwnershipType
        create_time
        update_time
        created_by
        updated_by

        assetallocation {
          ...assetallocation
        }
        liabilities {
          loans {
            ID
            liabilities_ID
            Bank
            BSB
            AccountNumber
            TaxDeductible
            Purpose
            SecuredBy
            RelatedAsset
            InterestRate
            VariableFixed
            FixedExpiry
            InterestOnlyExpiry
            RepaymentPerMonth
            LoanLimit
            AvailableRedraw
            RepaymentType
            LoanOwnership
            CurrentLoanOutstanding
            LoanType
            LoanStartDate
            LoanExpiry
            Description
            InterestOnlyStartDate
            RateReviewedDate
            Offset
            Position
            Refinance
            create_time
            update_time
            created_by
            updated_by
          }
          otherliabilities {
            ...otherliabilitiesCore
          }
        }
        notes {
          ...notes
        }
        history {
          ...history
        }
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${assetallocationFragment}
  ${otherliabilitiesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;
