import { QueryResult } from "@apollo/client";
import {
  ScenarioclientSubscription,
  FindUniqueScenarioQuery,
  MutationType,
  FindUniqueScenarioQueryVariables,
} from "../../../codegen/schema";
import { scenarioclient } from "../subscription";

interface ScenarioclientResolverParams {
  prev: FindUniqueScenarioQuery;
  payload: ScenarioclientSubscription["scenarioclient"];
}
function createScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioclient:
        scenarioclient.scenario_ID === prev.findUniqueScenario.ID
          ? [...prev.findUniqueScenario.scenarioclient, scenarioclient]
          : prev.findUniqueScenario.scenarioclient,
    },
  });
}

function updateScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioclient: prev.findUniqueScenario.scenarioclient.map(
        (scenarioclients) =>
          scenarioclient.ID === scenarioclients.ID
            ? { ...scenarioclients, ...scenarioclient }
            : scenarioclients
      ),
    },
  });
}

function deleteScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenarioclient: [...prev.findUniqueScenario.scenarioclient].filter(
        (scenarioclients) => scenarioclients.ID !== scenarioclient.ID
      ),
    },
  });
}

function scenarioclientResolver({
  prev,
  payload,
}: ScenarioclientResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioclient({ prev, payload });

    case MutationType.Update:
      return updateScenarioclient({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioclient({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioclientFindUniqueScenario(
  query: Pick<
    QueryResult<FindUniqueScenarioQuery, FindUniqueScenarioQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioclient,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioclientSubscription } }
    ) =>
      scenarioclientResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioclient,
      }),
    variables: query.variables,
  });
}
