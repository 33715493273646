import {
  AdviceagreementSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { adviceagreement } from "../subscription";
import { QueryResult } from "@apollo/client";

interface AdviceagreementResolverParams {
  prev: FindUniqueGroupQuery;
  payload: AdviceagreementSubscription["adviceagreement"];
}

function createAdviceagreement({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const adviceagreement = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement:
        adviceagreement.groups_ID === prev.findUniqueGroup.ID
          ? [...prev.findUniqueGroup.adviceagreement, adviceagreement]
          : prev.findUniqueGroup.adviceagreement,
    },
  });
}

function updateAdviceagreement({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const adviceagreement = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement: prev.findUniqueGroup.adviceagreement.map((entry) =>
        entry.ID === adviceagreement.ID
          ? {
              ...entry,
              ...adviceagreement,
            }
          : entry
      ),
    },
  });
}

function deleteAdviceagreement({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const adviceagreement = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement: [...prev.findUniqueGroup.adviceagreement].filter(
        (entry) => entry.ID !== adviceagreement.ID
      ),
    },
  });
}

function adviceagreementResolver({
  prev,
  payload,
}: AdviceagreementResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAdviceagreement({ prev, payload });

    case MutationType.Update:
      return updateAdviceagreement({ prev, payload });

    case MutationType.Delete:
      return deleteAdviceagreement({ prev, payload });

    default:
      return prev;
  }
}

export function adviceagreementFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: adviceagreement,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceagreementSubscription } }
    ) =>
      adviceagreementResolver({
        prev,
        payload: payload.subscriptionData.data.adviceagreement
      }),
    variables: query.variables,
  });
}
