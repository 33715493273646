import { gql } from "@apollo/client";
import { scenariochildFragment } from "./fragment";

export const scenariochild = gql`
  subscription Scenariochild($where: scenariochildWhereUniqueInput) {
    scenariochild(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenariochild
      }
      model_has_scenariochild {
        model_ID
        scenariochild_ID
      }
    }
  }
  ${scenariochildFragment}
`;
export const scenariochildMany = gql`
  subscription ScenariochildMany($where: scenariochildWhereInput) {
    scenariochildMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenariochild
      }
      model_has_scenariochild {
        model_ID
        scenariochild_ID
      }
    }
  }
  ${scenariochildFragment}
`;
