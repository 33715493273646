export const Termsandconditions_v1 = `
General Site Usage
Last Revised: 25th October 2023

Software as a Service (SaaS) agreement
Parties

Supplier
MOAS App Pty Ltd ACN 676 653 339

Customer
You, the accepting party

## Background

1. The Supplier provides a software as a service solution that assists customers with providing advice to their clients.
2. The Customer wishes to access the Platform and the Services.
3. This agreement sets out the terms on which the Supplier will provide the Customer with access to the Platform and the Services.

## Agreements

### 1 Term

This agreement will commence on the Effective Date and will continue to operate unless it is terminated by the parties.

### 2 Hosted Services

- 2.1. The Supplier will create an Administrator Account for the Customer and provide the Customer with login details for that account. The Supplier grants the Customer the right to set up a User Account for each of its Authorised Users.
- 2.2. The Supplier grants to the Customer and the Authorised Users the non-exclusive, non-transferrable right to access and use the Hosted Services for the internal business purposes of the Customer during the Term.
- 2.3. To the maximum extent permitted by law (and except to the extent expressly permitted in this agreement), the right to access and use of the Hosted Services granted by the Supplier to the Customer under clause 2.2 is subject to the following conditions:
  - a. the Customer must not permit any unauthorised person to access or use the Hosted Services or Platform, including any person that was previously, but is no longer, an Authorised User;
  - b. the Customer must use reasonable endeavours, including reasonable security measures relating to Administrator Account access details, to ensure that no unauthorised person may gain access to the Hosted Services or Platform using an Administrator Account;
  - c. the Customer must ensure that its Authorised Users use reasonable endeavours, including reasonable security measures relating to User Account access details, to ensure that no unauthorised person may gain access to the Hosted Services or Platform using a User Account; and
  - d. the Customer must (and must ensure that its Authorised Users do) not:
    - i. copy, republish, create a derivate work from, redistribute or reproduce any content or material from the Hosted Services or Platform by any means, except as permitted by this agreement;
    - ii. make any alteration or modification to the Hosted Services or Platform;
    - iii. reverse engineer, reverse compile or disassemble or directly or indirectly allow or cause a third party to reverse engineer, reverse compile or disassemble the whole or any part of the Hosted Services or the Platform;
    - iv. use or access the Hosted Services or Platform in any way to provide goods or services which are or may be competitive to the services offered by the Supplier to third parties;
    - v. license, sell, rent, lease, transfer, assign, distribute, display, disclose or otherwise commercially exploit, or otherwise make the Hosted Services or Platform available to any third parties;
    - vi. use the Hosted Services or Platform in any way that causes, or may cause, damage to the Hosted Services or Platform or impairment of the availability or accessibility of the Hosted Services or Platform;
    - vii. use the Hosted Services or Platform in any way that is unlawful, illegal, fraudulent or harmful or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;
    - viii. use the Hosted Services or Platform in any way that could introduce viruses, trojan horses, worms, logic bombs, spyware or any other program or material which is malicious or technologically harmful; or
    - ix. use the Hosted Services or Platform in any way to attempt to gain unauthorised access to, interfere with, damage or disrupt any part of the Hosted Services or Platform.
- 2.4. In addition to any legal remedies available to the Supplier under this agreement or otherwise, any unauthorised use, alteration, modification, reproduction, publication, disclosure or transfer of the Hosted Services or Platform will entitle the Supplier to any available equitable remedy or relief against the Customer.
- 2.5. The Supplier will be responsible for maximising the Uptime of the Hosted Services.
- 2.6. The Uptime of the Hosted Services will exclude any downtime due to:
  - a. a Force Majeure Event;
  - b. a fault or failure of the Customer's computer systems or networks;
  - c. any breach by the Customer of this agreement;
  - d. Maintenance Services carried out in accordance with this agreement; and
  - e. downtime of any critical third party service providers of the Supplier or of any third party telecommunications service providers.
- 2.7. For the avoidance of doubt, the Customer has no right to access the software code (including object code, intermediate code and source code) of the Platform, either during or after the Term.
- 2.8. The Customer is solely responsible for the acts or omissions of the Authorised Users and must ensure that they comply with the terms of this agreement as if they were the Customer. Any breach of these terms by Authorised Users will be deemed to be a breach by the Customer for which the Customer must indemnify and keep indemnified the Supplier.
- 2.9. The Customer must immediately revoke access to the Hosted Services and the Platform from any person who ceases to be an Authorised User.
- 2.10. The Customer remains liable for the actions of any person who retains access to the Hosted Services or the Platform in breach of clause 2.9.

### 3 Maintenance Services

- 3.1. The Supplier will provide the Maintenance Services to the Customer during the Term when necessary and in accordance with this clause 3.
- 3.2. The Supplier will, where practicable, give to the Customer at least [10] Business Days' prior written notice of scheduled Maintenance Services that are likely to affect the availability of the Hosted Services or are likely to have a material negative impact upon the Hosted Services.
- 3.3. The Supplier will provide all scheduled Maintenance Services outside Business Hours or as otherwise agreed by the parties.
- 3.4. The Supplier may apply Updates or Upgrades to the Platform during the Term.
- 3.5. The Supplier will give to the Customer written notice of the application of any Update or Upgrade to the Platform.
- 3.6. The Supplier will apply each Update or Upgrade to the Platform within any period notified by the Supplier to the Customer or agreed by the parties in writing.
- 3.7. If the Supplier applies any Upgrade or Update in connection with the Maintenance Services during the Term:
  - a. this agreement applies in all respects to that Update or Upgrade to the extent that it is incorporated into or replaces the Hosted Services; and
  - b. the Hosted Services will be deemed to be amended to the extent that the specifications for the Update or Upgrade supersede the existing Hosted Services.
- 3.8. The Customer acknowledges that the Supplier is not responsible for the interim failure or non-availability of the Hosted Services as a result of the Maintenance Services being performed.

### 4 Support Services

- 4.1. The Supplier will provide the Support Services to the Customer during the Term when necessary and in accordance with Schedule 3 (Support Services).
- 4.2. The Supplier will, at the request of the Customer and for the sole purpose of providing Support Services, access and operate within the Customer’s Administrator Account. This access may include, but is not limited to, viewing, editing, creating, and deleting data on behalf of the Customer.
- 4.3. If at any time the Customer believes there is a Hosted Services Defect such that the Hosted Services do not comply with or cannot be used in conformity with the Hosted Services Specifications in all material respects, the Customer must notify the Supplier of such perceived Hosted Services Defect.
- 4.4. The Supplier will investigate the perceived Hosted Services Defect notified pursuant to clause 4.2 and will, upon the verification of the existence of the Hosted Services Defect, rectify such Hosted Services Defect without charge to the Customer.
- 4.5. The Supplier will not be liable for and is not obliged under clause 4.3 to rectify any Hosted Services Defect that is caused by the Customer or a third party, including the failure of the Customer or a third party to use the Hosted Services or Platform in accordance with this agreement or the specifications issued by the Supplier.

### 5 Documentation

- 5.1. The Supplier will give the Customer access to the Associated Documentation.
- 5.2. The Associated Documentation may not be used by the Customer except to assist in the normal use of the Hosted Services by the Customer.

### 6 Client Groups

- 6.1. The Hosted Services will permit the Customer (or its Authorised Users) to establish a group of one or more entities for each client, for the purposes of using the Hosted Services in relation to that client (Client Group).
- 6.2. The Customer (or its Authorised Users) must designate each Client Group as an Active Group, Archived Group or Lead Group.
- 6.3. The Customer acknowledges that:
  - a. it is responsible for designating the Client Groups in accordance with clause 6.2;
  - b. the Supplier is not required to refund any Fees charged as a result of Client Groups that were inadvertently designated as Active Groups; and
  - c. the availability of the Hosted Services and the Platform in relation to a Client Group will depend on whether the Client Group is designated as an Active Group, Archived Group or Lead Group, and the Supplier may limit the Customer and its Authorised Users’ access to the Services and Platform in relation to Archived Groups and Lead Groups.

### 7 Fees

- 7.1. The Supplier will charge monthly Fees to the Customer for each Client Group that was designated as an Active Group at any time during the preceding month.
- 7.2. The Supplier will not charge Fees for any Client Group that was designated as an Archived Group or Lead Group for the whole preceding month.
- 7.3. The Customer acknowledges and agrees that:
  - a. all Fees incurred by it using its Administrator Account, or its Authorised Users using a User Account, will be charged to the Customer; and
  - b. the Supplier is permitted (or authorised to permit any third party) to store and charge any payment method associated with the Customer for proper purposes in connection with this agreement.
- 7.4. The Customer must pay the Fees using any payment method reasonably required by the Supplier, which may be changed at any time by the Supplier by written notice to the Customer.
- 7.5. All Fees are non-refundable unless otherwise agreed in writing with the Supplier.

### 8 Customer obligations

- 8.1. Save to the extent that the parties have agreed otherwise in writing, the Customer must provide to the Supplier, or procure for the Supplier, such:
  - a. co-operation, support and advice;
  - b. information and documentation; and
  - c. governmental, legal and regulatory licences, consents and permits,
  - d. as are reasonably necessary to enable the Supplier to perform its obligations under this agreement.
- 8.2. The Customer must provide to the Supplier, or procure for the Supplier, such access to the Customer's computer hardware, software, networks and systems as may be reasonably required by the Supplier to enable the Supplier to perform its obligations under this agreement.
- 8.3. The Customer acknowledges that it must maintain reliable access to the internet through devices capable of accessing web-based content in order to obtain the Services from the Supplier.
- 8.4 The Customer must:
  - a. not carry on, operate, or be engaged in the establishment of a business, either directly or indirectly, whether alone or jointly with or on behalf of anybody else, that is based on the information, methodologies, technologies, or services provided by the Supplier through the Platform and is materially similar to, or competes with, the Services offered by the Supplier;
  - b. not solicit for employment, either directly or indirectly, any person who is employed or contracted by the Supplier; and
  - c. promptly advise the Supplier if a person who is employed or contracted by the Supplier seeks to be employed or contracted by the Customer.
- 8.5. The Customer agrees that the Supplier may identify the Customer as a recipient of its services and use its name and logos in sales presentations, marketing materials and press releases.
- 8.6. During the Term, the Customer must provide such commercial data as the Supplier reasonably requires and keep the Supplier informed as to:
  - a. any feedback received in relation to access to and use of the Hosted Services; and
  - b. material changes to the business operations and activities of the Customer.

### 9 Customer Data

- 9.1. The Customer grants to the Supplier a non-exclusive licence to copy, reproduce, store, distribute, publish, export, adapt, edit and translate the Customer Data to the extent reasonably required for the performance of the Supplier’s obligations and the exercise of the Supplier’s rights under this agreement, together with the right to sub-license these rights to its service providers and subcontractors (including hosting, connectivity and telecommunications service providers) to the extent reasonably required for the performance of the Supplier’s obligations and the exercise of the Supplier’s rights under this agreement.
- 9.2. The Customer warrants to the Supplier that the Customer Data when used by the Supplier in accordance with this agreement will not infringe the Intellectual Property Rights of any person and will not breach the provisions of any law, statute or regulation in any applicable jurisdiction.
- 9.3. The Customer acknowledges that the Supplier has no obligations to retain the Customer Data and the Customer Data may be irretrievably deleted by the Supplier at any time after [60 days] following the termination of this agreement.
- 9.4. The Customer is solely responsible for the collection, entry, legality, reliability, integrity, accuracy and quality of the Customer Data that is uploaded the Platform.
- 9.5. The Customer warrants that it has obtained all necessary consents for the Supplier’s collection, use, storage, disclosure and other dealings with the Customer Data as contemplated by this agreement.

### 10 Intellectual Property Rights

- 10.1. The Supplier is the owner or the licensee of all Intellectual Property Rights in the whole or any part of the material contained on the Platform, and in the material published on it. Those works are protected by Intellectual Property Rights laws and treaties around the world. All such rights are reserved.
- 10.2. The Customer must not:
  - a. infringe Intellectual Property Rights in the whole or any part of the material contained on the Platform; and
  - b. reproduce, copy, transmit, adapt, publish or communicate or otherwise exercise the Intellectual Property Rights in the whole or any part of the material contained on the Platform except with the prior written consent from the Supplier.
- 10.3. Nothing in this agreement operates to assign or transfer any Intellectual Property Rights from the Supplier to the Customer.
- 10.4. The parties acknowledge that any Intellectual Property Rights arising out of the performance of the Services by the Supplier are the exclusive property of the Supplier, which automatically vest in the Supplier upon the creation. For the avoidance of doubt, this includes all Intellectual Property Rights in the Platform Materials.

### 11 Platform Materials

- 11.1. The Supplier grants the Customer a non-transferable, non-exclusive, royalty-free licence to use, alter and exploit the Platform Materials, only in the ordinary course of the Customer’s business and for a purpose connected with providing services to the Client Group.
- 11.2. The Customer is solely responsible for ensuring all information contained in the Platform Materials is accurate, and the Supplier is not responsible for any errors contained within the Platform Materials.
- 11.3. The Customer will ensure it has all rights (including all Intellectual Property Rights) to use any fonts, styles, images or other materials incorporated into the Platform Materials, and will indemnify the Supplier for any claim arising out of or relating to the Customer’s breach of this clause 11.3.

### 12 Data protection

- 12.1. The Customer warrants to the Supplier that it has the legal right to disclose all Personal Information that it does in fact disclose to the Supplier under or in connection with this agreement (including the names and other details of all Authorised Users and clients of the Customer) and that the processing of that Personal Information by the Supplier in accordance with this agreement will not breach any applicable data protection or data privacy laws, including the Privacy Act 1988 (Cth).
- 12.2. The Supplier must ensure that access to the Customer Personal Information is limited to those personnel who have a reasonable need to access the Customer Personal Information to enable the Supplier to perform its obligations under this agreement.
- 12.3. The Customer warrants that it has obtained, or will obtain, any necessary consents in relation to the processing of the Customer Personal Information and takes sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness and ownership of all of Customer Personal Information.

### 13 Advice disclaimer

- 13.1. Where the Customer or an Authorised User provides the Platform Materials to clients, or uses or incorporates any part of the Platform Materials in the provision of advice, the Customer acknowledges and will ensure all clients acknowledge in writing that:
  - a. the Supplier is not:
    - i. an AFSL holder or an authorised representative of an AFSL holder; or
    - ii. an ACL holder or an authorised representative of an ACL holder, and is not permitted to provide advice relating to financial products or credit;
  - b. any advice provided to the client is solely provided by the Customer or an Authorised User (as applicable); and
  - c. the Platform Materials:
    - i. are for general information purposes only;
    - ii. do not constitute advice; and
    - iii. do not take into account the particular circumstances of any of the Customer’s clients.
- 13.2. Where the Customer or an Authorised User uses the Services, Platform or Platform Materials to provide advice to clients, the Customer is solely responsible for ensuring the person providing such advice holds all necessary licences, permits and qualifications to provide the advice (including, but not limited to, an AFSL or an ACL).

### 14 Confidentiality

- 14.1. Each party (Recipient) must keep secret and confidential and not disclose any information relating to another party or its business (which is or has been disclosed to the Recipient by the other party, its representatives or advisers), the Platform, the Services, this agreement or its terms, except:
  - a. where the information is in the public domain as at the date of this agreement (or subsequently becomes in the public domain other than by breach of any obligation of confidentiality binding on the Recipient);
  - b. if the Recipient is required to disclose the information by applicable law or the rules of any recognised stock exchange or other document with statutory content requirements, provided that the Recipient has, to the extent practicable having regard to those obligations and the required timing of the disclosure, consulted with the provider of the information as to the form and content of the disclosure;
  - c. where the disclosure is expressly permitted under this agreement;
  - d. if disclosure is made to its officers, employees and professional advisers to the extent necessary to enable the Recipient to properly perform its obligations under this agreement or to conduct their business generally, in which case the Recipient must ensure that such persons keep the information secret and confidential and do not disclose the information to any other person;
  - e. where the disclosure is required for use in legal proceedings regarding this agreement; or
  - f. if the party to whom the information relates has consented in writing before the disclosure.
- 14.2. Each Recipient must ensure that its directors, officers, employees, agents, representatives and Related Bodies Corporate comply in all respects with the Recipient’s obligations under this clause 13.

### 15 Warranties

- 15.1. The Supplier warrants to the Customer that:
  - a. the execution of this agreement by the Supplier has been properly authorised;
  - b. the Supplier has the legal right and authority to enter into this agreement and to perform its obligations under this agreement;
  - c. the Supplier will comply with all applicable Australian legal and regulatory requirements applying to the exercise of the Supplier's rights and the fulfilment of the Supplier's obligations under this agreement;
  - d. the Supplier will use reasonable endeavours to provide the Services with reasonable care and skill in accordance with the terms of this agreement; and
  - e. this agreement constitutes a legal, valid and binding obligation on it, enforceable in accordance with its terms by appropriate legal remedy.
- 15.2. The Customer warrants to the Supplier that:
  - a. the execution of this agreement by the Customer has been properly authorised;
  - b. the Customer has the legal right and authority to enter into this agreement and to perform its obligations under this agreement;
  - c. the Customer will comply with all applicable legal and regulatory requirements applying to the exercise of the Customer’s rights and the fulfilment of the Customer’s obligations under this agreement; and
  - d. this agreement constitutes a legal, valid and binding obligation on it, enforceable in accordance with its terms by appropriate legal remedy.
- 15.3. All of the parties' warranties and representations in respect of the subject matter of this agreement are expressly set out in this agreement. To the maximum extent permitted by applicable law, no other warranties or representations concerning the subject matter of this agreement will be implied into this agreement or any related contract.
- 15.4. The Customer acknowledges that, in considering whether or not to engage the Supplier for the Hosted Services and enter into this agreement, it did so on the basis that the Supplier expressly excluded any reliance by the Customer on information given to the Customer or statements or representations of the Supplier, other than as expressly set out in this agreement.

### 16 Customer acknowledgements

- 16.1. The Customer acknowledges that:
  - a. the Services are supplied to it on an ‘as is’ basis;
  - b. the Customer’s use of the Services is at its own risk;
  - c. the Supplier makes no warranty as to the suitability of the Hosted Services for the Customer’s purposes;
  - d. the Platform is never wholly free from defects, errors, bugs and interruptions;
  - e. subject to the other provisions of this agreement, the Supplier gives no warranty or representation that the Platform will be wholly free from defects, errors, bugs and interruptions;
  - f. the Platform is never entirely free from security vulnerabilities;
  - g. subject to the other provisions of this agreement, the Supplier gives no warranty or representation that the Platform will be entirely secure;
  - h. the Supplier is not responsible for the failure or non-availability of the Platform, and any consequential inability of the Customer to use or access the Hosted Services will not affect the Supplier’s rights to receive any amounts due to it in accordance with this agreement; and
  - i. the Supplier does not warrant or represent that the Platform or the use of the Platform by the Customer will not give rise to any legal or financial liability on the part of the Customer or any other person.
- 16.2. The Customer acknowledges and agrees that if it breaches a term of this agreement, the Supplier may:
  - a. refuse to supply the Services or parts of the Services to the Customer;
  - b. suspend or terminate the Customer’s access to the Services or parts of them; and
  - c. take whatever action it considers appropriate to recover any loss, damage or expenses that the Supplier has incurred or suffered as a result of the Customer’s breach.

### 17 Indemnities

- 17.1. The Customer must indemnify and keep the Supplier indemnified against any and all claims, liabilities, damages, losses, costs and expenses (including legal expenses and amounts suffered or incurred by the Supplier) arising directly or indirectly as a result of or in connection with:
  - a. any breach of this agreement by the Customer or any other servant, agent or contractor of the Customer or any of their personnel;
  - b. any negligent or wilful act or omission or misfeasance of the Customer or any other servant, agent or contractor of the Customer or any of their personnel;
  - c. any claims by the Customer or any other servant, agent or contractor of the Customer, or any of their personnel, which are excluded under clause 18.3;
  - d. any act or omission of an Authorised User (or any former Authorised User that has retained access to the Hosted Services or the Platform) in connection with the Services;
  - e. any goods or services made available to Authorised Users (or any former Authorised User that has retained access to the Hosted Services or the Platform) in connection with the Services; or
  - f. any act or omission of the Customer or any other servant, agent or contractor of the Customer or any of their personnel that is fraudulent or likely to be considered fraudulent by a reasonable person.
- 17.2. The Customer acknowledges that it is not necessary for the Supplier to make any payment before enforcing the right of indemnity under clause 17.1 against the Customer.

### 18 Limitations and exclusions of liability

- 18.1. To the full extent permitted by law, and except where expressly indicated in this agreement, the Supplier excludes any and all liability under this agreement and makes no warranties, guarantees, representations or indemnities in relation to the Services or the Platform.
- 18.2. The Supplier excludes liability for any costs, expenses, losses or damages incurred by the Customer:
  - a. as a result of the Customer’s access and use of the Services or the Platform; and
  - b. as a result of the suspension or termination of the Customer’s access and use of the Services or the Platform caused through the Supplier’s exercise of its rights and obligations under this agreement.
- 18.3. The Supplier will not be liable to the Customer (or any person claiming under or through the Customer) in respect of:
  - a. any losses arising out of a Force Majeure Event;
  - b. any loss of profits or anticipated savings;
  - c. any loss of revenue or income, or loss of or damage to reputation;
  - d. any loss of use or production;
  - e. any loss of business, contracts or opportunities;
  - f. any loss or corruption of any data, database or software; or
  - g. any special, indirect or consequential loss or damage.
- 18.4. The aggregate liability of the Supplier to the Customer under this agreement will not exceed the total of the amounts paid by the Customer to the Supplier in respect of Fees paid under this agreement in the 12-month period preceding the date of the relevant claim.
- 18.5. Subject to the other terms of this clause 18, the Supplier excludes all rights, representations, guarantees, conditions, warranties, undertakings, remedies or other terms in relation to the Services that are not expressly set out in this agreement to the maximum extent permitted by law.
- 18.6. Nothing in this agreement is intended to have the effect of excluding, restricting or modifying the application of all or any of the provisions of Part 5-4 of the Australian Consumer Law, or the exercise of a right conferred by such a provision, or any liability of the Supplier in relation to a failure to comply with a guarantee that applies under Division 1 of Part 3-2 of the Australian Consumer Law to a supply of goods or services.
- 18.7. If the Supplier is liable to the Customer in relation to a failure to comply with a guarantee that applies under Division 1 of Part 3-2 of the Australian Consumer Law that cannot be excluded, the Supplier's total liability to the Customer for that failure is limited to the resupply of the services or the payment of the cost of resupply.

### 19 Force Majeure Event

- 19.1. The Supplier is not liable for any delay or failure to perform its obligations if such failure or delay is due to a Force Majeure Event.
- 19.2. The Supplier will notify the Customer as soon as practicable of any anticipated delay caused by a Force Majeure Event and the performance of the Supplier’s obligations under this agreement shall be suspended for the period of the delay.
- 19.3. If a delay due to a Force Majeure Event exceeds [30] days, the Supplier may terminate this agreement immediately on providing notice to the Customer.
- 19.4. If the Supplier gives a notice to the Customer under clause 19.3, the Supplier will refund moneys previously paid by the Customer under this agreement for which no goods or services have been provided, but is entitled to retain a reasonable sum in relation to services rendered or costs and expenses incurred by the Supplier prior to termination.

### 20 Termination

- 20.1. Without limiting the generality of any other clause of this agreement, the Supplier may terminate this agreement immediately by notice in writing if:
  - a. any payment due from the Customer to the Supplier pursuant to this agreement remains unpaid;
  - b. the Customer breaches any clause of this agreement and that breach is irremediable or (if that breach is capable of remedy) the Customer fails to remedy that breach within a period of 14 days after being notified in writing to do so;
  - c. the Customer suffers an Insolvency Event;
  - d. the Customer suffers a Change in Control;
  - e. the Customer purports to assign, transfer or otherwise deal with its contractual rights or obligations in breach of this agreement; or
  - f. the Customer ceases or threatens to cease conducting its business in the normal manner.
- 20.2. On termination for any reason:
  - a. all rights granted to the Customer under this agreement will cease;
  - b. the Customer will cease all activities authorised by this agreement; and
  - c. the Customer will immediately pay to the Supplier any sums due to the Supplier under this agreement.
  - d. the Supplier may close the Administrator Account and any User Accounts associated with the Administrator Account;
  - e. the Supplier may retain any moneys paid;
  - f. the Supplier will be regarded as discharged from any further obligations under this agreement; and
  - g. the Supplier is entitled to pursue any additional or alternative remedies provided by law.

### 21 Assignment

- 21.1. The Supplier may assign all or some of the benefits of this agreement to a third party at any time by providing notice in writing to the Customer.

### 22 Dispute resolution

- 22.1. Subject to clause 21.4, any disputes arising in connection with this agreement that cannot be settled by negotiation between the parties or their representatives will be submitted to mediation.
- 22.2. The mediator will be appointed by the chair for the time being of the Resolution Institute ACN 008 651 232.
- 22.3. The mediator will conduct the mediation in accordance with the guidelines agreed between the parties or, if the parties cannot agree within 14 days following the appointment of the mediator, in accordance with the guidelines set by the mediator. The costs and expenses of the mediator will be shared by the parties equally.
- 22.4. Before referring a matter to mediation pursuant to clause 21.1:
  - a. the parties must use all reasonable endeavours acting in good faith to resolve such dispute amicably within 10 Business Days after the date on which the dispute arose;
  - b. if the dispute is not resolved in accordance with clause 21.4(a), the dispute must be referred to the persons nominated by each respective party within five Business Days to enable those nominated persons to use all reasonable endeavours acting in good faith to resolve such dispute amicably within 10 Business Days after the date on which the dispute is referred to them; and
  - c. if the dispute is not resolved in accordance with clause 21.4(b), the dispute must be referred to the respective chief executive officers (or equivalent) of each party within 5 Business Days to enable the chief executive officers to use all reasonable endeavours acting in good faith to resolve such dispute amicably within 10 Business Days after the date on which the dispute is referred to them.
- 22.5. Nothing in this clause 21 prevents a party from seeking urgent equitable relief before an appropriate court in the State of Queensland.
- 22.6. At all times during the preparation for, hearing of or deliberation over a dispute as referred to in this clause 21, the parties must to the extent practicable proceed to complete their respective obligations under this agreement.

### 23 References to and calculations of time

- 23.1. Where time is to be calculated by reference to a day or event, that day or the day of that event is excluded.
- 23.2. Where something is done or received after 5.00 pm on any day, it will be taken to have been done or received on the following day.
- 23.3. Where a provision in this agreement requires anything to be done on a Saturday, Sunday or public holiday, that matter or thing may be done or will be taken to have been done on the next succeeding day that is not a Saturday, Sunday or public holiday.

### 24 GST

- 24.1. Unless expressly stated otherwise, the consideration for any supply under or in connection with this agreement has been fixed without regard to the impact of GST.
- 24.2. If GST is or becomes payable on a Taxable Supply made under or in connection with this agreement, the party providing consideration for that Taxable Supply must pay an additional amount equal to the GST payable on the Taxable Supply.
- 24.3. The additional amount payable under clause 23.2 must be paid at the same time as the consideration for the Taxable Supply or on the date on which the party making the supply delivers a Tax Invoice (whichever is later).

### 25 General

**Construction**

- 25.1. In this agreement:
  - a. words in the singular include the plural and vice versa;
  - b. words indicating any gender indicate the appropriate gender;
  - c. where a word or phrase is given a particular meaning, other parts of speech and grammatical forms of that word or phrase have corresponding meanings;
  - d. a reference to a person is to be construed as a reference to an individual, body corporate, unincorporated association, partnership, joint venture or government body;
  - e. references to any document (including this agreement) include references to the document as amended, consolidated, supplemented, novated or replaced;
  - f. a reference to a statute includes a reference to or citation of all enactments amending or consolidating the statute and to an enactment substituted for the statute; and
  - g. headings are included for convenience only and do not affect interpretation of this agreement.

**Survival**

- 25.2. The following clauses survive termination of this agreement together with any other term which by its nature is intended to do so:
  - a. clause 13 (Confidentiality);
  - b. clause 18 (Limitation of liability);
  - c. clause 20 (Termination); and
  - d. clause 24.17 (Governing law)

**Counterparts**

- 25.3. This agreement may be signed in counterparts. Each counterpart will be deemed an original and the counterparts taken together constitute one and the same instrument.
- 25.4. A copy of a counterpart sent by electronic communication (as defined in the in Electronic Transactions (Queensland) Act 2001 (Qld)):
  - a. will be deemed an original counterpart;
  - b. is sufficient evidence of the signing of the original; and
  - c. may be produced in evidence for all purposes in place of the original.

**Reference to a party**

- 25.5. Any reference to a party in this agreement includes, and any obligation or benefit under this agreement will bind or take effect for the benefit of, that party’s executors, administrators, successors in title and assigns.

**Relationship of the parties**

- 25.6. Nothing in this agreement creates any relationship of employment, agency or partnership between the parties or their personnel.

**Assignment**

- 25.7. The benefit of this agreement will not be dealt with in any way by the Customer (whether by assignment, sub-licensing or otherwise) without the Supplier’s written consent.

**Sub-contracts**

- 25.8. The Supplier may sub-contract for the performance of this agreement or any part of this agreement upon obtaining the Customer’s prior written consent.
- 25.9. The Customer must not unreasonably withhold consent for the Supplier to sub-contract in accordance with clause 24.8.

**Waiver**

- 25.10. No right under this agreement will be deemed to be waived except by notice in writing signed by each party.
- 25.11. A waiver by the Supplier pursuant to clause 24.10 will not prejudice its rights in respect of any subsequent breach of this agreement by the Customer.
- 25.12. Subject to clause 24.10, any failure by the Supplier to enforce any clause of this agreement, or any forbearance, delay or indulgence granted by the Supplier to the Customer, will not be construed as a waiver of the Supplier’s rights under this agreement.

**Entire Agreement**

- 25.13. This agreement constitutes the entire agreement between the parties. Any prior arrangements, agreements, representations or undertakings are superseded.

**Severability**

- 25.14. If any provision of this agreement is held invalid, unenforceable or illegal for any reason, this agreement will remain otherwise in full force apart from the such provision which will be deemed deleted.

**Amendments to be in writing**

- 25.15. No amendment to this agreement has any force unless it is in writing.

**No reliance**

- 25.16. No party has relied on any statement, representation, assurance or warranty made or given by any other party, except as expressly set out in this agreement.

**Governing law**

- 25.17. This agreement will be construed in accordance with the laws in force in Queensland and the parties submit to the jurisdiction of the courts of Queensland.

**Notices**

- 25.18. All notices given under this agreement must be in writing and may be delivered in person or by mail or by the medium specified in the address for service notified by each party to the other.
- 25.19. A party may change its particulars for service by notice in writing to the other parties.
- 25.20. A notice sent by post will be deemed:
  - a. given on the day it is posted; and
  - b. received six days after posting.
- 25.21. A notice sent by facsimile transmission will be deemed received on the date stated on the facsimile transmission report produced by the machine sending the facsimile.
- 25.22. A notice sent by email will be deemed received at the time and on the date that it is sent, unless the sender receives notification that the delivery of the email was unsuccessful, in which case the email will not be deemed to have been received.
- 25.23. For the purposes of clause 24.22, ‘delivery’ of an email means the time that an email reaches the recipient’s server.

**Further assurances**

- 25.24. Each party will sign and complete all further documents and do anything else that may be reasonably necessary to effect, perfect or complete the provisions of this agreement and the transactions to which it relates.

**Joint and several**

- 25.25. An obligation of two or more persons under this agreement binds them jointly and severally and every expressed or implied agreement or undertaking by which two or more persons derive any benefit in terms of this agreement will take effect for the benefit of those persons jointly and severally.

**No merger**

- 25.26. The rights and obligations of the parties contained in this agreement will not be extinguished by or upon completion.

**Time of the essence**

- 25.27. Time will be of the essence as regards a date or period determined under this agreement except that a date or period may be altered by agreement in which case time will be of the essence for date or period as altered.

## 26 Definitions

The meanings of the terms used in this agreement are set out below.

| _Term_                             | _Meaning_                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               |
| :--------------------------------- | :-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| **Administrator Account**          | an account enabling the Customer to access and use the Hosted Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
| **ACL**                            | Australian Credit Licence                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               |
| **AFSL**                           | Australian Financial Services Licence                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   |
| **Australian Consumer Law**        | the Australian Consumer Law contained in Schedule 2 to the Competition and Consumer Act 2010 (Cth)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      |
| **Active Group**                   | means any Client Group designated as an ‘active group’ by the Customer or an Authorised User, and will generally include Client Groups to which the Customer is actively providing services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             |
| **Archived Group**                 | means any Client Group designated as an ‘archived group’ by the Customer, and will generally include Client Groups to which the Customer is no longer actively providing services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       |
| **Associated Documentation**       | any operating manuals and other printed or online materials including users’ manuals, programming manuals, modification manuals, flow charts, drawings and software listings which are designed to assist or supplement the understanding or application of the Hosted Services or the Platform                                                                                                                                                                                                                                                                                                                                                                                                                                         |
| **Authorised User**                | any person who is an employee or subcontractor of the Customer and is designated by the Customer as an authorised user of the Platform                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
| **Business Day**                   | any weekday other than a bank or public holiday in [Brisbane, Australia]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                |
| **Business Hours**                 | the hours of 9.00 am to 5.00 pm on a Business Day                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       |
| **Change in Control**              | in relation to a party, the occurrence of any of the following:                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         |
|                                    | (a) the sale of all or substantially all of that party’s assets                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         |
|                                    | (b) a change in the shareholding of the party (provided that the party is not listed on a recognised securities exchange) that results in a different person or group of persons having control of the composition of the board of directors or more than 50% of the shares giving a right to vote at general meetings                                                                                                                                                                                                                                                                                                                                                                                                                  |
|                                    | (c) a change in the shareholding of the party’s holding company (provided that the holding company is not listed on a recognised securities exchange) that results in a different person or group of persons having control of the composition of the board of directors or more than 50% of the shares giving a right to vote at general meetings                                                                                                                                                                                                                                                                                                                                                                                      |
| **Client Group**                   | has the meaning given in clause 6.1                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     |
| **Customer Data**                  | (a) all content, data, information and other materials, in whatever format, provided to the Supplier by or on behalf of the Customer in connection with the Services (Raw Data)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         |
|                                    | (b) all content, data, information and other materials, in whatever format, generated, stored, processed, retrieved, printed or produced using the Raw Data                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             |
| **Customer Personal Information**  | Personal Information that is processed by the Supplier on behalf of the Customer in relation to this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          |
| **Effective Date**                 | the date of this agreement or such other date that is agreed in writing between the parties                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             |
| **Excluded Maintenance Services**  | any of the following:                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   |
|                                    | (a) correction of errors or defects arising directly or indirectly from a failure of the Customer to perform or observe any of its obligations in this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (b) training of persons nominated by the Customer                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       |
|                                    | (c) rectification of errors caused by a fault in any designated equipment or devices                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    |
|                                    | (d) equipment maintenance                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               |
|                                    | (e) diagnosis or rectification of faults not associated with the Hosted Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (f) enhancements designed to extend the Hosted Services to provide facilities or capabilities not contained in the Hosted Services Specifications or otherwise not contemplated by the parties at the date of this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            |
|                                    | (g) furnishing or maintenance of accessories, attachments, supplies, consumables or associated items, whether or not manufactured or distributed by the Supplier                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (h) maintenance made more difficult by a failure of the Customer to perform or observe any of its obligations in this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         |
| **Excluded Support Services**      | any of the following:                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   |
|                                    | (a) correction of errors or defects arising directly or indirectly from a failure of the Customer to perform or observe any of its obligations in this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (b) any issue caused by the improper use of the Hosted Services by the Customer including operation of the Hosted Services in a manner other than that specified by the Supplier                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (c) any issue caused by any modification, revision, variation, translation or alteration to the Hosted Services made without the prior consent of the Supplier                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          |
|                                    | (d) any issue caused by the use of the Hosted Services by a person not authorised to use the Hosted Services pursuant to this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 |
|                                    | (e) diagnosis or rectification of faults not associated with the Hosted Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (f) training of persons nominated by the Customer                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       |
| **Fees**                           | the fees charged by the Supplier for the Hosted Services, as may be altered or amended from time to time at the sole discretion of the Supplier without notice to the Customer                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          |
| **Force Majeure Event**            | means any circumstance not in the Supplier’s reasonable control including, without limitation:                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          |
|                                    | (a) acts of God, flood, drought, earthquake or other natural disaster                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   |
|                                    | (b) epidemic or pandemic or public health crisis                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (c) terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed conflict, imposition of sanctions, embargo or breaking off of diplomatic relations                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              |
|                                    | (d) nuclear, chemical or biological contamination, or sonic boom                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (e) any law or any action taken by a government or public authority, including without limitation imposing an export or import restriction, quota or prohibition, or failing to grant a necessary licence or consent                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    |
|                                    | (f) collapse of buildings, fire, explosion or accident                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
|                                    | (g) cybercrime, cyber incidents and data breaches                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       |
|                                    | (h) any labour or trade dispute, strikes, industrial action or lockouts                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 |
|                                    | (i) non-performance by suppliers or subcontractors                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      |
|                                    | (j) interruption or failure of a utility service                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
| **GST**                            | has the same meaning as in the GST Act                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
| **GST Act**                        | A New Tax System (Goods and Services Tax) Act 1999 (Cth)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                |
| **Hosted Services**                | the software modules of the Supplier, as specified in the Hosted Services Specifications, together with any modifications to or enhancements or adaptations of such software modules developed or created by or on behalf of the Supplier during the Term                                                                                                                                                                                                                                                                                                                                                                                                                                                                               |
| **Hosted Services Defect**         | a defect, error or bug in the Platform having a material adverse effect on the appearance, operation, functionality or performance of the Hosted Services, but excluding any defect, error or bug caused by or arising as a result of any of the following:                                                                                                                                                                                                                                                                                                                                                                                                                                                                             |
|                                    | (a) any act or omission of the Customer or any person authorised by the Customer to use the Platform or Hosted Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 |
|                                    | (b) a failure of the Customer to perform or observe any of its obligations in this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            |
|                                    | (c) an incompatibility between the Platform or Hosted Services and any other system, network, application, program, hardware or software not specified as compatible in the Hosted Services Specifications                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              |
| **Hosted Services Specifications** | the specifications for the Platform and Hosted Services set out in Schedule 2 (Hosted Services Specifications)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          |
| **Insolvency Event**               | the occurrence of any one or more of the following events in relation to a party:                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       |
|                                    | (a) it is insolvent as defined by section 95A of the Corporations Act as disclosed in its accounts or otherwise, states that it is insolvent, is presumed to be insolvent under an applicable law (including under section 459C(2) or section 585 of the Corporations Act) or otherwise is, or states that it is, unable to pay all its debts as and when they become due and payable                                                                                                                                                                                                                                                                                                                                                   |
|                                    | (b) any step is taken to appoint a receiver, a receiver and manager, a liquidator or a provisional liquidator or other like person to it or any of its assets, operations or business                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   |
|                                    | (c) an administrator is appointed to it under section 436A, section 436B or section 436C of the Corporations Act                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
|                                    | (d) a controller (as defined in section 9 of the Corporations Act) is appointed to it or any of its assets                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              |
|                                    | (e) an application is made to a court for an order, or an order is made, that it be wound up, declared bankrupt or that a provisional liquidator, receiver or receiver and manager be appointed, and that application is not withdrawn, struck out or dismissed within 14 days of it being made                                                                                                                                                                                                                                                                                                                                                                                                                                         |
|                                    | (f) any step is taken to enter into an arrangement or composition with one or more of its creditors, or an assignment for the benefit of one or more of its creditors, in each case other than to carry out a reconstruction or amalgamation while solvent                                                                                                                                                                                                                                                                                                                                                                                                                                                                              |
|                                    | (g) it proposes a winding-up, dissolution or reorganisation, moratorium, deed of company arrangement or other administration involving one or more of its creditors                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     |
|                                    | (h) it is taken to have failed to comply with a statutory demand under section 459F(1) of the Corporations Act                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          |
|                                    | (i) a notice is issued under section 601AA or section 601AB of the Corporations Act and not withdrawn or dismissed within 14 days                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       |
|                                    | (j) it ceases to carry on business or threatens to do so, other than in accordance with the terms of this agreement                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     |
|                                    | (k) anything that occurs under the law of any jurisdiction which has a substantially similar effect to any of the events set out in paragraphs (a) to (j) of this definition                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            |
| **Intellectual Property Rights**   | patents, utility models, rights to inventions, copyright and related rights, trade marks and service marks, trade names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer programs, database rights, rights to preserve the confidentiality of information (including know-how and trade secrets) and any other intellectual property rights, including all applications for (and rights to apply for and be granted), renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any part of the world |
| **Lead Group**                     | means any Client Group designated as a ‘lead group’ by the Customer, and will generally include Client Groups to which the Customer intends to provide services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         |
| **Maintenance Services**           | the general maintenance of the Platform and Hosted Services, and the application of Updates and Upgrades to be performed by the Supplier in accordance with the terms of this agreement but for the avoidance of doubt this excludes the Excluded Maintenance Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
| **Personal Information**           | has the meaning given in the Privacy Act 1988 (Cth)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     |
| **Platform**                       | the platform managed by the Supplier and used by the Supplier to provide the Hosted Services, including the application and database software for the Hosted Services and the system and server software used to provide the Hosted Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            |
| **Platform Materials**             | means:                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
|                                    | (a) any data or information contained within or viewable on the Platform, whether or not such data or information was entered by the Customer or its Authorised Users                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   |
|                                    | (b) any documents or other materials generated or downloaded through the use of the Services or Platform by the Customer or its Authorised Users                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |
| **Related Body Corporate**         | has the meaning given to that term in section 50 of the Corporations Act                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                |
| **Services**                       | any services that the Supplier provides to the Customer, or has an obligation to provide to the Customer under this agreement including Hosted Services, Support Services and Maintenance Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      |
| **Support Services**               | reasonably necessary support in relation to the use of, and the identification and resolution of errors in, the Hosted Services to ensure that the Hosted Services remain in substantial conformity with the Hosted Services Specification to be performed by the Supplier but for the avoidance of doubt this excludes the Excluded Support Services                                                                                                                                                                                                                                                                                                                                                                                   |
| **Taxable Supply**                 | has the same meaning as in the GST Act                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
| **Tax Invoice**                    | has the same meaning as in the GST Act                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  |
| **Term**                           | the term of this agreement, commencing on the Effective Date and continuing until this agreement expires or terminates in accordance with its terms                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     |
| **Update**                         | a hotfix, patch or minor version update to the Platform                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 |
| **Upgrade**                        | a major version upgrade of the Platform                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 |
| **Uptime**                         | the percentage of time during a given period when the Hosted Services are available at the gateway between public internet and the network of the hosting services provider for the Hosted Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     |
| **User Account**                   | an account enabling an Authorised User to access and use the Hosted Services                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            |

#### Support Services

This Schedule 3 (Support Services) sets out the service levels applicable to the Support Services.

**Training**

1. The Supplier will provide training to the Customer and its Authorised Users on the use of the Hosted Services, at the reasonable request of the Customer.
2. The Supplier will conduct training either online or at the Customer’s premises (at the discretion of the Supplier).

**Helpdesk**

3. The Customer may contact the helpdesk supplied by the Supplier as follows:

Email: support@moasapp.com
Phone: on 0414 920 001

4. The Customer may use the helpdesk for the purposes of requesting and, where applicable, receiving the Support Services; and the Customer must not use the helpdesk for any other purpose.
5. The helpdesk will be operational during Business Hours during the Term.
6. The Customer must ensure that all requests for Support Services that it makes are made through the helpdesk.
7. The Customer will ensure that any request for Support Services includes a documented example of the defect or error that it alleges prevents conformity of the Hosted Services with the Hosted Services Specifications, to the extent such information is relevant to the request.
8. The Customer will, if so requested by the Supplier or its helpdesk representatives, a listing of output and any other data required in order to reproduce operating conditions similar to those present when any alleged defect or error in the Hosted Services was discovered.

**Provision of Support Services**

9. The Support Services will be provided remotely, save to the extent that the parties agree otherwise in writing.

**Limitations on Support Services**

10. If the total hours spent by the personnel of the Supplier performing the Support Services during any calendar month exceeds [40 hours] then:
    - the Supplier will cease to have an obligation to provide Support Services to the Customer during the remainder of that period; and
    - the Supplier may agree to provide Support Services to the Customer during the remainder of that period, but the provision of those Support Services will be subject to additional charges to be agreed between the parties.

`;
