import { gql } from "@apollo/client";
import { notesFragment } from "../notes/fragment";

export const employmentCoreFragment = gql`
  fragment employmentCore on employment {
    ID
    clients_ID
    clients_groups_ID
    clients_groups_organisation_ID
    Employer
    Occupation
    StartDate
    EmploymentBasis
    FinishDate
    JobTitle
    EmployerGroup
    EmploymentType
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const employmentFragment = gql`
  fragment employment on employment {
    ID
    clients_ID
    clients_groups_ID
    clients_groups_organisation_ID
    Employer
    Occupation
    StartDate
    EmploymentBasis
    FinishDate
    JobTitle
    EmployerGroup
    EmploymentType
    create_time
    created_by
    update_time
    updated_by
    notes {
      ...notes
    }
  }
  ${notesFragment}
`;
