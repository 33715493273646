import { gql } from "@apollo/client";
import { incomeFragment } from ".";

export const createOneIncome = gql`
  mutation CreateOneIncome($data: incomeCreateInput!) {
    createOneIncome(data: $data) {
      ...income
    }
  }
  ${incomeFragment}
`;

export const updateOneIncome = gql`
  mutation UpdateOneIncome(
    $data: incomeUpdateInput!
    $where: incomeWhereUniqueInput!
  ) {
    updateOneIncome(data: $data, where: $where) {
      ...income
    }
  }
  ${incomeFragment}
`;

export const deleteOneIncome = gql`
  mutation DeleteOneIncome($where: incomeWhereUniqueInput!) {
    deleteOneIncome(where: $where) {
      ...income
    }
  }
  ${incomeFragment}
`;
