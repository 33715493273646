import { gql } from "@apollo/client";

export const userFragment = gql`
  fragment user on user {
    staff_ID
    staff_organisation_ID
    email
    position
    role
    viewedupdates
    templateadmin
    tasktemplateadmin
    updated_by
    update_time
    last_login
  }
`;
