import { gql } from "@apollo/client";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";
// import { assetsCoreFragment } from "../assets";
import { annuityCoreFragment } from "../annuity";
import { cashCoreFragment } from "../cash";
import { collectiblesCoreFragment } from "../collectibles";
import { otherassetsCoreFragment } from "../otherassets";
import { propertyCoreFragment } from "../property";
import { sharesCoreFragment } from "../shares";
import { superannuationCoreFragment } from "../superannuation";
import { vehiclesCoreFragment } from "../vehicles";

export const loansCoreFragment = gql`
  fragment loansCore on loansCore {
    ID
    liabilities_ID
    Bank
    BSB
    AccountNumber
    TaxDeductible
    Purpose
    SecuredBy
    RelatedAsset
    InterestRate
    VariableFixed
    FixedExpiry
    InterestOnlyExpiry
    RepaymentPerMonth
    LoanLimit
    AvailableRedraw
    RepaymentType
    LoanOwnership
    CurrentLoanOutstanding
    LoanType
    LoanStartDate
    LoanExpiry
    Description
    InterestOnlyStartDate
    RateReviewedDate
    Offset
    Position
    Refinance
    create_time
    update_time
    created_by
    updated_by
  }
`;

/**
 * Property core fragment has an initialisation issue here.
 * Using explicitly typed project fields for now.
 */
export const loansFragment = gql`
  fragment loans on loans {
    ID
    liabilities_ID
    Bank
    BSB
    AccountNumber
    TaxDeductible
    Purpose
    SecuredBy
    RelatedAsset
    InterestRate
    VariableFixed
    FixedExpiry
    InterestOnlyExpiry
    RepaymentPerMonth
    LoanLimit
    AvailableRedraw
    RepaymentType
    LoanOwnership
    CurrentLoanOutstanding
    LoanType
    LoanStartDate
    LoanExpiry
    Description
    InterestOnlyStartDate
    RateReviewedDate
    Offset
    Position
    Refinance
    create_time
    update_time
    created_by
    updated_by

    assets {
      annuity {
        ...annuityCore
      }

      cash {
        ...cashCore
      }

      collectibles {
        ...collectiblesCore
      }

      otherassets {
        ...otherassetsCore
      }

      offset {
        ID
        assets_ID
        Bank
        BSB
        AccountNumber
        Amount
        RelatedLoan
        Purpose
        OwnershipType
        create_time
        update_time
        created_by
        updated_by
      }

      property {
        ...propertyCore
      }

      shares {
        ...sharesCore
      }

      superannuation {
        ...superannuationCore
      }

      vehicles {
        ...vehiclesCore
      }
    }

    notes {
      ...notes
    }

    history {
      ...history
    }
  }
  ${annuityCoreFragment}
  ${cashCoreFragment}
  ${collectiblesCoreFragment}
  ${otherassetsCoreFragment}
  ${propertyCoreFragment}
  ${sharesCoreFragment}
  ${superannuationCoreFragment}
  ${vehiclesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;
