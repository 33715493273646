import { gql } from "@apollo/client";
import { insuranceFragment } from ".";
/** Insurance */
export const createOneInsurance = gql`
  mutation CreateOneInsurance($data: insuranceCreateInput!) {
    createOneInsurance(data: $data) {
      ...insurance
    }
  }
  ${insuranceFragment}
`;

export const updateOneInsurance = gql`
  mutation UpdateOneInsurance(
    $data: insuranceUpdateInput!
    $where: insuranceWhereUniqueInput!
  ) {
    updateOneInsurance(data: $data, where: $where) {
      ...insurance
    }
  }
  ${insuranceFragment}
`;

export const deleteOneInsurance = gql`
  mutation DeleteOneInsurance($where: insuranceWhereUniqueInput!) {
    deleteOneInsurance(where: $where) {
      ...insurance
    }
  }
  ${insuranceFragment}
`;
