import { gql } from "@apollo/client";
import { addressFragment } from ".";

export const address = gql`
  subscription Address($where: addressWhereUniqueInput) {
    address(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...address
      }
    }
  }
  ${addressFragment}
`;

export const addressMany = gql`
  subscription AddressMany($where: addressWhereInput) {
    addressMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...address
      }
    }
  }
  ${addressFragment}
`;
