/** CREATE DataSelect returns ------------------------------------------------------------------ */

import dayjs from "dayjs";

/**
 *
 * @param {float} value
 * @param {int} decimals
 */
export function round(value, decimals) {
  return !value
    ? 0
    : Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

/**
 * DEPRECIATED - REMOVING WITH REVIEW TOOL
 * @author Lara de Maroussem
 * @date 25-11-2021
 * @param {Int} value - number value to be checked against null & undefined.
 * @returns {Int} valid number value.
 * @description Checks if value provided (number) is not null or undefined, if so return 0.
 */
export function numberValid(value) {
  return value !== null && value !== undefined ? value : 0;
}

/** * DEPRECIATED - REMOVING WITH REVIEW TOOL
 * @author Lara de Maroussem
 * @date 25-11-2021
 * @param {String} value - string value to be checked against null & undefined.
 * @returns {String} valid number value.
 * @description Checks if value provided (string) is not null or undefined, if so return false.
 */
export function stringValid(string) {
  return string !== null && string !== undefined && string !== ""
    ? true
    : false;
}

/**
 * @author Lara de Maroussem
 * @date 23-11-2021
 * @param {string} month - month string to be converted to dayjs date
 * @returns {Object} dayjs date object.
 * @description Gets a dayjs date object based on month string provided.
 */
export function getDateFromMonthString(month) {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var monthNumber = months.indexOf(month);

  return dayjs().date(1).month(monthNumber);
}

/** function checkFieldIsEmpty
 * Check if the 'field' (value) is empty, if empty a "-" is returned.
 * Else, the value is returned.
 * Based on the type provided, the value can be converted to a dollar or date field.
 *
 * @author Lara de Maroussem
 */
export function checkFieldIsEmpty(value, type, dateFormat, staffData) {
  if (value === null || !value || value === "Select") {
    return "-";
  } else if (!isNaN(value) && type === "dollar") {
    return parseFloat(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    });
  } else if (type === "date") {
    if (dateFormat) {
      return dayjs(value).format(dateFormat);
    } else {
      return dayjs(value).format("DD/MM/YYYY");
    }
  } else if (type === "percent") {
    return value + "%";
  } else {
    return value;
  }
}

/** function capitaliseText
 * @author Lara de Maroussem
 * @date 27-07-2021
 * @param {var} Value - Value to be capitalised
 * @returns variable to be capitalised
 * @description Takes text and capitalises first letter of first word
 * (For datebase fields that are dynamically mapped)
 */
export function capitaliseText(value) {
  var valueLowercase = value
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();

  /*If value is NOT all uppercase, keep first letter a capital and the rest lowercase. 
    Else, leave all as capital */
  return value.toUpperCase() !== value
    ? valueLowercase.charAt(0).toUpperCase() + valueLowercase.slice(1)
    : value;
}

export function getAge(DOB) {
  return new Date(Date.now() - new Date(DOB).getTime()).getUTCFullYear() - 1970;
}

/**
 * @author Taylor Bindon
 * @date 15-11-2021
 * @param {Number | null | undefined} field Type field containing the staff_ID value
 * @param returnType Type to return. "value", "text", or "object".
 * @param allStaff The allStaff array from findManyStaff.
 * @returns {Number | String | Object | ReactNode} Returns either staff_ID Number, staff Full Name String, or staff Object.
 */
export function getSelectedStaff(field, returnType, allStaff) {
  var result;
  allStaff.forEach((staff) => {
    /**
     * Does not have a staff.user !== null check
     * because we need all staff here for previous staff members.
     */
    if (
      staff.ID === parseInt(field) ||
      staff.FirstName + " " + staff.LastName === field
    ) {
      if (returnType === "value") {
        result = staff.ID;
      } else if (returnType === "text") {
        result = staff.FirstName + " " + staff.LastName;
      } else if (
        returnType === "initials" &&
        staff.FirstName !== null &&
        staff.LastName !== null
      ) {
        result = staff.FirstName.charAt(0) + " " + staff.LastName.charAt(0);
      } else if (returnType === "object") {
        result = staff;
      }
    }
  });

  return result;
}

// Get the staff list for alternate planner to create relationship
export function getStaffOptions(allStaff, group, type) {
  var options = [];
  /* Go through all staff and add staff text +
  /* value to options array IF user role matches provided
  /* type && user is NOT assigned to another role within the
  /* same group (i.e primary adviser will not show on alternate
  /* adviser list). */

  allStaff.forEach((staff) => {
    if (staff.user !== null) {
      if (
        type.includes("Adviser") &&
        (staff.Team === "Financial Planning" ||
          staff.user.position === "Inter Adviser" ||
          staff.user.position === "Financial Adviser")
      ) {
        options.push({
          text: `${staff.FirstName} ${staff.LastName}`,
          value: staff.ID,
        });
      } else if (
        type.includes("Accountant") &&
        (staff.Team === "Accounting" ||
          staff.user.position === "Inter Accountant" ||
          staff.user.position === "Accountant")
      ) {
        options.push({
          text: `${staff.FirstName} ${staff.LastName}`,
          value: staff.ID,
        });
      } else if (
        type === "CSO" &&
        (staff.Team === "CSO" ||
          staff.user.position === "Client Service Operator")
      ) {
        options.push({
          text: `${staff.FirstName} ${staff.LastName}`,
          value: staff.ID,
        });
      } else if (
        type.includes("Property") &&
        (staff.Team === "Property" ||
          staff.user.position === "Property Admin" ||
          staff.user.position === "Property Adviser")
      ) {
        options.push({
          text: `${staff.FirstName} ${staff.LastName}`,
          value: staff.ID,
        });
      } else if (
        type.includes("Insurance") &&
        (staff.Team === "Insurance" ||
          staff.user.position === "Insurance Admin")
      ) {
        options.push({
          text: `${staff.FirstName} ${staff.LastName}`,
          value: staff.ID,
        });
      } else if (
        (type.includes("Finance") ||
          type === "Loan Writer" ||
          type === "Mortgage Broker") &&
        (staff.Team === "Finance" || staff.user.position === "Finance Admin")
      ) {
        options.push({
          text: `${staff.FirstName} ${staff.LastName}`,
          value: staff.ID,
        });
      }
    }
  });

  return options;
}

export function getOwnershipTypeOptions() {
  return ["Client", "Joint", "Joint Tenants", "Tenants in Common", "Entity"];
}

// Get all assets for a group
export function getGroupAssets(group) {
  return [...group.clients]
    .concat([...group.entities])
    .reduce((result, client) => {
      Object.keys(client.assets).forEach((assetType) => {
        if (assetType !== "__typename") {
          result[assetType] = result[assetType]
            ? [
                ...new Set([
                  ...result[assetType],
                  ...client.assets[assetType].filter(
                    (item) =>
                      !result[assetType].some((entry) => item.ID === entry.ID)
                  ),
                ]),
              ]
            : client.assets[assetType];
        }
      });
      return result;
    }, {});
}

// Get all liabilities for a group
export function getGroupLiabilities(group) {
  return [...group.clients]
    .concat([...group.entities])
    .reduce((result, client) => {
      Object.keys(client.liabilities).forEach((liabilityType) => {
        if (liabilityType !== "__typename") {
          result[liabilityType] = result[liabilityType]
            ? [
                ...new Set([
                  ...result[liabilityType],
                  ...client.liabilities[liabilityType].filter(
                    (item) =>
                      !result[liabilityType].some(
                        (entry) => item.ID === entry.ID
                      )
                  ),
                ]),
              ]
            : client.liabilities[liabilityType];
        }
      });
      return result;
    }, {});
}

// Depreciated for read many
export function getGroupInsurance(group) {
  return [...group.clients].reduce((result, client) => {
    client.insurance.forEach((insurance) => {
      result.push(insurance);
    });

    return result;
  }, []);
}

export function getAssetValue(asset) {
  if (asset.Value !== undefined) {
    return round(asset.Value, 2);
  } else if (asset.MarketValue !== undefined) {
    return round(asset.MarketValue, 2);
  } else if (asset.Amount !== undefined) {
    return round(asset.Amount, 2);
  } else {
    return 0;
  }
}

export function getLiabilityValue(liability) {
  if (
    liability.CurrentLoanOutstanding !== undefined &&
    liability.CurrentLoanOutstanding !== null
  ) {
    return round(liability.CurrentLoanOutstanding, 2);
  } else if (liability.Amount !== undefined && liability.Amount !== null) {
    return round(liability.Amount, 2);
  } else {
    return 0;
  }
}

export function getPropertyColour(group, property) {
  var propertyIndex = "";

  var propertyMap = [...group.clients]
    .concat([...group.entities])
    .reduce((result, client) => {
      return [
        ...new Set([
          ...result,
          ...client.assets.property.filter(
            (item) => !result.some((entry) => item.ID === entry.ID)
          ),
        ]),
      ];
    }, [])
    .sort((a, b) =>
      a.Type === null ? 1 : b.Type === null ? -1 : a.Type?.localeCompare(b.Type)
    )
    .map((property) => {
      return property;
    });

  propertyMap.forEach((propertyMap, index) => {
    if (property.ID === propertyMap.ID) {
      propertyIndex = index;
    }
  });

  return propertyIndex;
}

export function getPropertyColoursForLoans(group, loan) {
  var securedPropertyIndex = 0;
  var relatedPropertyIndex = 0;

  // Map loan properties and context properties to find related and secured by properties
  // and colours.

  var propertyMap = [...group.clients]
    .concat([...group.entities])
    .reduce((result, client) => {
      return [
        ...new Set([
          ...result,
          ...client.assets.property.filter(
            (item) => !result.some((entry) => item.ID === entry.ID)
          ),
        ]),
      ];
    }, [])
    .sort((a, b) =>
      a.Type === null ? 1 : b.Type === null ? -1 : a.Type?.localeCompare(b.Type)
    )
    .map((property) => {
      return property;
    });

  if (loan) {
    propertyMap.forEach((propertyMap, index) => {
      if (parseInt(loan.SecuredBy) === propertyMap.assets_ID) {
        securedPropertyIndex = index;
        // securedPropertyColour = String.fromCharCode(65 + index)
        //   ? String.fromCharCode(65 + index)
        //   : "none";
      }

      if (parseInt(loan.RelatedAsset) === propertyMap.assets_ID) {
        relatedPropertyIndex = index;
        // relatedPropertyColour = String.fromCharCode(65 + index)
        //   ? String.fromCharCode(65 + index)
        //   : "none";
      }
    });

    // [...group.clients].concat([...group.entities]).forEach((client) =>
    //   Object.keys(client.assets).forEach((assetType) => {
    //     if (assetType !== "__typename" && assetType !== "property") {
    //       client.assets[assetType].forEach((asset) => {
    //         if (loan.SecuredBy === asset.assets_ID) {
    //           securedAsset = asset;
    //         }

    //         if (loan.RelatedAsset === asset.assets_ID) {
    //           relatedAsset = asset;
    //         }
    //       });
    //     }
    //   })
    // );
  }

  return {
    securedPropertyIndex: securedPropertyIndex,
    relatedPropertyIndex: relatedPropertyIndex,
  };
}

/**
 * @author Taylor Bindon
 * @date 07-09-2021
 * @description Returns array of text and value assets for related asset and secured by.
 */
export function relatedAssetOptions(group) {
  return [
    {
      text: "None",
      value: null,
    },
    ...[...group.clients]
      .concat([...group.entities])
      .reduce(
        (result, client) => [
          ...new Set([
            ...result,
            ...client.assets.property.filter(
              (item) => !result.some((entry) => item.ID === entry.ID)
            ),
          ]),
        ],
        []
      )
      .sort((a, b) =>
        !a.Type || a.Type === null
          ? 1
          : !b.Type || b.Type === null
          ? -1
          : a.Type.localeCompare(b.Type)
      )
      .map((property) => ({
        text: `Property: ${property.Type}${
          property.UnitNumber !== "" &&
          property.UnitNumber !== null &&
          property.UnitNumber !== undefined
            ? ` ${property.UnitNumber}/${property.StreetNumber} ${property.StreetName}, ${property.Suburb}, ${property.State}`
            : ` ${property.StreetNumber} ${property.StreetName}${
                property.Suburb !== "" && property.Suburb !== null
                  ? `, ${property.Suburb}`
                  : ""
              }${
                property.State !== "" && property.State !== null
                  ? `, ${property.State}`
                  : ""
              }`
        }`,
        value: property.assets_ID,
      })),
    ...[...group.clients]
      .concat([...group.entities])
      .reduce(
        (result, client) => [
          ...new Set([
            ...result,
            ...client.assets.vehicles.filter(
              (item) => !result.some((entry) => item.ID === entry.ID)
            ),
          ]),
        ],
        []
      )
      .map((vehicle) => ({
        text: `Vehicle: ${vehicle.Make} ${
          vehicle.Model !== null ? vehicle.Model : ""
        } ${checkFieldIsEmpty(vehicle.Value, "dollar")}`,
        value: vehicle.assets_ID,
      })),
  ];
}

/**
 * @author Taylor Bindon
 * @date 07-09-2021
 * @description Returns array of text and value assets for related asset and secured by.
 */
export function relatedAssetDefaultValue(value, group) {
  return relatedAssetOptions(group).reduce(
    (result, option) => {
      if (parseInt(option.value) === parseInt(value)) result = option;
      return result;
    },
    { text: "None", value: "" }
  );
}

/**
 * @author Taylor Bindon
 * @date 07-09-2021
 * @description Returns array of text and value offsets for offset options in loans adding and editing.
 */
export function offsetOptions(group) {
  return [
    {
      text: "None",
      value: null,
    },
    ...[...group.clients]
      .concat([...group.entities])
      .reduce(
        (result, client) => [
          ...new Set([
            ...result,
            ...client.assets.offset.filter(
              (item) => !result.some((entry) => item.ID === entry.ID)
            ),
          ]),
        ],
        []
      )
      .map((offset) => ({
        text: `Offset: ${offset.Bank} ${offset.Amount}`,
        value: offset.assets_ID,
      })),
  ];
}

/**
 * @author Taylor Bindon
 * @date 07-09-2021
 * @description Returns array of text and value offsets for offset options in loans adding and editing.
 */
export function offsetDefaultValue(value, group) {
  return offsetOptions(group).reduce(
    (result, option) => {
      if (parseInt(option.value) === parseInt(value)) result = option;
      return result;
    },
    { text: "None", value: "" }
  );
}

/**
 * @author Taylor Bindon
 * @date 12-11-2021
 * @param {Object} group object
 * @param {Object} loan object
 * @returns Returns array of offsets related to provided loan for property loan.
 * @description Map clients + entities then loans and match up withj provided loan.ID
 * to find related offsets. Return array  of offset objects. Used when mapping properties =>
 * loans.
 */
export function getOffsetForPropertyLoan(group, loan) {
  return [...group.clients]
    .concat(...group.entities)
    .reduce((result, client) => {
      client.liabilities.loans.forEach((innerLoan) => {
        if (loan.ID === innerLoan.ID && innerLoan.assets) {
          result = innerLoan.assets.offset;
        }
      });
      return result;
    }, []);
}
