import clsx from "clsx";

import {
  FindUniqueGroupQuery,
  FindUniqueUserQuery,
  useFindManyStaffQuery,
  useUpdateOneGroupsMutation,
} from "../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getSelectedStaff,
} from "../../../../Functions/GeneralFunctions";

import { Refetch } from "../../../../Components/Refetch";
import { ButtonMenu } from "../../../../Components/Menus/ButtonMenu";
import { Tooltip } from "../../../../Components/Tooltip";
import { ComponentLoader } from "../../../../Components/Loaders/ComponentLoader";

import dayjs from "dayjs";

interface GroupStatusProps {
  user: FindUniqueUserQuery["findUniqueUser"];
  group: FindUniqueGroupQuery["findUniqueGroup"];
  buttonType: "no-style" | "alt";
}

export const GroupStatus: React.FC<GroupStatusProps> = ({
  user,
  group,
  buttonType,
}) => {
  const [updateOneGroup, { loading: updateOneGroupLoading }] =
    useUpdateOneGroupsMutation();

  const {
    data: { findManyStaff: allStaff } = {},
    error: allStaffError,
    loading: allStaffLoading,
    refetch: allStaffRefetch,
  } = useFindManyStaffQuery();

  if (allStaffError && !allStaffLoading) {
    return (
      <Refetch
        refetch={() => {
          allStaffRefetch();
        }}
      />
    );
  }

  if (!allStaff && allStaffLoading) {
    return <ComponentLoader />;
  }

  return (
    group &&
    user && (
      <div className="flex gap-2 items-center">
        <ButtonMenu
          id="group-status"
          buttonProps={{
            type: buttonType,
            loading: updateOneGroupLoading,
            text: (
              <h6
                className={clsx(
                  "flex gap-2 items-center text-xs",
                  group.date_archived
                    ? "text-neutral-300"
                    : group.Status === "Active"
                    ? "text-semantic-success-500"
                    : group.Status === "Lead"
                    ? "text-semantic-warning-500"
                    : group.Status === "Prospect"
                    ? "text-semantic-info-500"
                    : "text-neutral-300"
                )}
              >
                {group.date_archived && "Archived "}
                {group.Status}

                {updateOneGroupLoading && (
                  <i className="fa-solid fa-spinner fa-spin" />
                )}
              </h6>
            ),
          }}
          options={[
            {
              text: "Active",
              active: group.Status === "Active",
              onClick: () => updateGroup({ status: "Active" }),
            },
            {
              text: "Prospect",
              active: group.Status === "Prospect",
              onClick: () => updateGroup({ status: "Prospect" }),
            },
            {
              text: "Lead",
              active: group.Status === "Lead",
              onClick: () => updateGroup({ status: "Lead" }),
            },
          ]}
        />

        <Tooltip
          title={
            group.date_archived
              ? `Archived on ${dayjs(group.date_archived).format(
                  "DD/MM/YYYY"
                )} by ${checkFieldIsEmpty(
                  getSelectedStaff(group.archived_by, "text", allStaff)
                )}`
              : group.Status === "Active"
              ? // If group is active check if we have data for when they were made active, otherwise show date created
                group.date_converted !== null
                ? `Converted to Active on 
                    ${dayjs(group.date_converted).format(
                      "DD/MM/YYYY"
                    )} by ${checkFieldIsEmpty(
                    getSelectedStaff(group.converted_by, "text", allStaff)
                  )}`
                : `Group created ${dayjs(group.create_time).format(
                    "DD/MM/YYYY"
                  )} by ${checkFieldIsEmpty(
                    getSelectedStaff(group.created_by, "text", allStaff)
                  )}`
              : group.Status === "Lead"
              ? // If group is a lead check if we have data for when they were made to a lead, otherwise show date created
                group.date_converted !== null
                ? `Converted to Lead on ${dayjs(group.date_converted).format(
                    "DD/MM/YYYY"
                  )} by ${checkFieldIsEmpty(
                    getSelectedStaff(group.converted_by, "text", allStaff)
                  )}`
                : `Group created ${dayjs(group.create_time).format(
                    "DD/MM/YYYY"
                  )} by ${checkFieldIsEmpty(
                    getSelectedStaff(group.created_by, "text", allStaff)
                  )}`
              : group.Status === "Prospect"
              ? group.date_converted !== null
                ? `Converted to Prospect on ${dayjs(
                    group.date_converted
                  ).format("DD/MM/YYYY")} by ${checkFieldIsEmpty(
                    getSelectedStaff(group.converted_by, "text", allStaff)
                  )}`
                : `Group created ${dayjs(group.create_time).format(
                    "DD/MM/YYYY"
                  )} by ${checkFieldIsEmpty(
                    getSelectedStaff(group.created_by, "text", allStaff)
                  )}`
              : `Group created ${dayjs(group.create_time).format(
                  "DD/MM/YYYY"
                )} by ${checkFieldIsEmpty(
                  getSelectedStaff(group.created_by, "text", allStaff)
                )}`
          }
        >
          <div className="fa-regular fa-circle-info text-neutral-400 text-sm" />
        </Tooltip>
      </div>
    )
  );

  function updateGroup({ status }: { status: string }) {
    if (group && user) {
      updateOneGroup({
        variables: {
          where: {
            ID: group.ID,
          },
          data: {
            Status: { set: status },
            date_converted: {
              set: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            },
            converted_by: { set: user.staff_ID },
            date_archived: { set: null },
            archived_by: { set: null },
            updated_by: { set: user.staff_ID },
            update_time: {
              set: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            },
            start_date:
              status !== "Lead"
                ? {
                    set: group.start_date ?? dayjs().format("YYYY-MM-DD"),
                  }
                : { set: group.start_date },
          },
        },
      });
    }
  }
};
