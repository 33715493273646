import React from "react";
import { PropertyValueScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";
import dayjs from "dayjs";

interface PropertyValuePlaceholderModelProps {
  theme?: Theme;
}

var data: ScenarioData[] = [];

Array.from(Array(11).keys()).forEach((value, index) => {
  data.push({
    year: dayjs().year() + value,
    initialValue: 0,
    offsets: 0,
    projectedValue: 0,
    loans: 0,
    interest: 0,
    repayments: 0,
    contributions: 0,
    withdrawals: 0,
    loanContributions: 0,
    loanWithdrawals: 0,
    offsetContributions: 0,
    offsetWithdrawals: 0,
    target: 0,
    netEquity: 0,
  });
});

export const PropertyValueScenarioPlaceholderModel = ({
  theme,
}: PropertyValuePlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Property Value
        </StyledText>

        <ModelTooltip type={Model_Type.PropertyValue} />
      </div>
      <h1 className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest">
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <PropertyValueScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
