import { gql } from "@apollo/client";
import { notesFragment } from "../notes";
import { scenarioFragment } from "../scenario";
import { teamFragment } from "../teams";
import { modelCoreFragment } from "../model";

export const adviceCoreFragment = gql`
  fragment adviceCore on advice {
    ID
    groups_ID
    groups_organisation_ID
    Type
    Progress
    Date
    InvestmentPropertyTotal
    HomePropertyTotal
    SuperannuationAssetTotal
    HomeLoanTotal
    SuperannuationLoanTotal
    InvestmentLoanTotal
    InvestmentAssetTotal

    PrimaryAdviser

    create_time
    update_time
    completed_time
    created_by
    updated_by
    completed_by
    cancellation_date

    time_elapsed_verifydata
    time_elapsed_modelling
    time_elapsed_confirmation
    time_elapsed
  }
`;

/** Model core is not initialised in time here */
export const adviceFragment = gql`
  fragment advice on advice {
    ...adviceCore

    model {
      ...modelCore
    }

    scenario {
      ...scenario
    }

    notes {
      ...notes
    }

    team {
      ...team
    }
  }
  ${adviceCoreFragment}
  ${modelCoreFragment}
  ${scenarioFragment}
  ${notesFragment}
  ${teamFragment}
`;
