import { gql } from "@apollo/client";
import { incomeFragment } from "./fragment";

export const income = gql`
  subscription Income($where: incomeWhereUniqueInput) {
    income(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...income
      }
    }
  }
  ${incomeFragment}
`;

export const incomMany = gql`
  subscription IncomeMany($where: incomeWhereInput) {
    incomeMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...income
      }
    }
  }
  ${incomeFragment}
`;
