import React from "react";
import { IncomeTaxScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";

interface IncomeTaxPlaceholderModelProps {
  theme?: Theme;
}

var data: ScenarioData[] = [
  {
    incomeTax: -51667,
    medicareLevy: -3600,
    netIncome: 124733,
    pension: 0,
    scenarioDataKey: "John Smith",
    studyRepayments: -0,
    superannuationTax: -0,
    taxableIncome: 180000
  },
  {
    incomeTax: -26216.350000000002,
    medicareLevy: -2200,
    netIncome: 81583.65,
    pension: 0,
    scenarioDataKey: "Jane Smith",
    studyRepayments: -0,
    superannuationTax: -0,
    taxableIncome: 110000
  },
];

export const IncomeTaxScenarioPlaceholderModel = ({
  theme,
}: IncomeTaxPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Income Tax
        </StyledText>

        <ModelTooltip type={Model_Type.IncomeTax} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <IncomeTaxScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
