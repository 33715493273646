import { gql } from "@apollo/client";
import { cashCoreFragment, cashFragment } from "./fragment";

export const cash = gql`
  subscription Cash($where: cashWhereUniqueInput) {
    cash(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...cash
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${cashFragment}
`;

export const cashMany = gql`
  subscription CashMany($where: cashWhereInput) {
    cashMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...cash
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${cashCoreFragment}
`;
