import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../../Functions/Owner";

interface AssetsOffsetProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns offset component for advice template && document.
 */
export const AssetsOffsetGrouped: React.FC<AssetsOffsetProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  type Offset = {
    assets_ID: number | null;
    Bank: string;
    Owner: string;
    Amount: string;
  };

  const summaryOffset: Offset[] = group
    ? content?.ContentType === "Grouped"
      ? getGroupAssets(group).offset.flatMap(({ ...rest }) => rest)
      : getGroupAssets(group).offset.find(
          (offset: Offset) => content?.asset_ID === offset.assets_ID
        )
    : contenttemplate?.ContentType === "Grouped"
    ? [{ Bank: "Westpac", Owner: "Joint", Amount: "$200,000" }]
    : { Bank: "Westpac", Owner: "Joint", Amount: "$200,000" };

  return (
    <React.Fragment>
      {summaryOffset.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Bank
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Owner
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Amount
          </StyledText>
        </div>
      ) : (
        <h5>No offset recorded</h5>
      )}
      {summaryOffset.map((offset, objectIndex) => {
        return (
          <div
           className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(offset.Bank)}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(
                group
                  ? ownerOptionsUpdateAssetDefaultValue(offset, group).text
                  : offset.Owner
              )}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(offset.Amount, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  );
};
