import React from "react";
import { PropertyCashflowScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";
import dayjs from "dayjs";

interface PropertyCashflowPlaceholderModelProps {
  theme?: Theme;
}

var data: ScenarioData[] = [];

Array.from(Array(11).keys()).forEach((value, index) => {
  data.push({
    year: dayjs().year() + value,
    offsets: 0,
    loans: index === 10 ? 0 : -520000,
    repayments: index === 10 ? 0 : -60000,
    interest:
      index === 0
        ? -19618.003864049395
        : index === 1
        ? -17851.34653685641
        : index === 2
        ? -16014.0229165757
        : index === 3
        ? -14103.206351483765
        : index === 4
        ? -12115.95712378815
        : index === 5
        ? -10049.217926984711
        : index === 6
        ? -7899.809162309134
        : index === 7
        ? -5664.424047046533
        : index === 8
        ? -3339.6235271734295
        : index === 9
        ? -921.8309865054022
        : 0,
    contributions: 0,
    withdrawals: 0,
    offsetContributions: 0,
    offsetWithdrawals: 0,
    loanContributions: 0,
    loanWithdrawals: 0,
    netIncome:
      index === 0
        ? 30715.56295612587
        : index === 1
        ? 35757.06295612587
        : index === 2
        ? 41066.422456125874
        : index === 3
        ? 46657.851209625864
        : index === 4
        ? 52546.31235106135
        : index === 5
        ? 58747.56233027292
        : index === 6
        ? 65278.19296360831
        : index === 7
        ? 72155.67571384057
        : index === 8
        ? 79398.40831703188
        : index === 9
        ? 87025.76388073295
        : 158842.5796283758,
    ongoingCosts: 0,
    projectedValue:
      index === 0
        ? 95500
        : index === 1
        ? 100561.5
        : index === 2
        ? 105891.2595
        : index === 3
        ? 111503.4962535
        : index === 4
        ? 117413.18155493548
        : index === 5
        ? 123636.08017734706
        : index === 6
        ? 130188.79242674644
        : index === 7
        ? 137088.798425364
        : index === 8
        ? 144354.50474190828
        : index === 9
        ? 152005.2934932294
        : 160061.57404837056,
    target: index === 10 ? 200000 : undefined,
  });
});

export const PropertyCashflowScenarioPlaceholderModel = ({
  theme,
}: PropertyCashflowPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Property Cashflow
        </StyledText>

        <ModelTooltip type={Model_Type.PropertyCashflow} />
      </div>
      <h1 className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest">
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <PropertyCashflowScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
