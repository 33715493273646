import { gql } from "@apollo/client";
import { tagFragment } from ".";

export const createOneTag = gql`
  mutation CreateOneTag($data: tagCreateInput!) {
    createOneTag(data: $data) {
      ...tag
    }
  }
  ${tagFragment}
`;

export const updateOneTag = gql`
  mutation UpdateOneTag($data: tagUpdateInput!, $where: tagWhereUniqueInput!) {
    updateOneTag(data: $data, where: $where) {
      ...tag
    }
  }
  ${tagFragment}
`;

export const deleteOneTag = gql`
  mutation DeleteOneTag($where: tagWhereUniqueInput!) {
    deleteOneTag(where: $where) {
      ...tag
    }
  }
  ${tagFragment}
`;
