import { gql } from "@apollo/client";
import { estateplanningCoreFragment, estateplanningFragment } from "./fragment";

export const findUniqueEstateplanning = gql`
  query FindUniqueEstateplanning($where: estateplanningWhereUniqueInput!) {
    findUniqueEstateplanning(where: $where) {
      ...estateplanning
    }
  }
  ${estateplanningFragment}
`;

export const findManyEstateplanning = gql`
  query FindManyEstateplanning(
    $where: estateplanningWhereInput
    $distinct: [EstateplanningScalarFieldEnum!]
    $orderBy: [estateplanningOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: estateplanningWhereUniqueInput
  ) {
    findManyEstateplanning(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...estateplanningCore
    }
  }
  ${estateplanningCoreFragment}
`;
