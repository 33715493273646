import React from "react";
import { IncomeTaxComparisonModelGraph } from "../Graph";
import { ComparisonData } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface IncomeTaxPlaceholderModelProps {
  theme?: Theme;
}

var data: ComparisonData[] = [
  {
    scenarioDataKey: "Scenario 1",
    [`John Smith Net Income`]: 124733,
    [`John Smith Tax Payable`]: 55267,
    [`Jane Smith Net Income`]: 81583.65,
    [`Jane Smith Tax Payable`]: 28416.350000000002,
  },
  {
    scenarioDataKey: "Scenario 2",
    [`John Smith Net Income`]: 112533.73999999999,
    [`John Smith Tax Payable`]: 47466.26,
    [`Jane Smith Net Income`]: 81583.65,
    [`Jane Smith Tax Payable`]: 28416.350000000002,
  },
];

export const IncomeTaxComparisonPlaceholderModel = ({
  theme,
}: IncomeTaxPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Income Tax
        </StyledText>

        <ModelTooltip type={Model_Type.IncomeTax} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <IncomeTaxComparisonModelGraph comparisonData={data} />
      </div>
    </React.Fragment>
  );
};
