import { gql } from "@apollo/client";
import { groupsFragment, groupsCoreFragment } from "./fragment";

export const groups = gql`
  subscription Groups($where: groupsWhereUniqueInput) {
    groups(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...groups
      }
    }
  }
  ${groupsFragment}
`;

export const groupsMany = gql`
  subscription GroupsMany($where: groupsWhereInput) {
    groupsMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...groupsCore
      }
    }
  }
  ${groupsCoreFragment}
`;
