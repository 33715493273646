import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../../../../../Datastore/Store";
import { LiveData, ScenarioData } from "../../../Calculations";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  scenarioData: ScenarioData[] | undefined;
  liveData: LiveData[] | undefined;
}

export const SuperannuationContributionSplittingScenarioModelGraph: React.FC<
  Props
> = ({ scenarioData, liveData }) => {
  const [context] = useContext(StoreContext);

  const [data, setData] = useState<ScenarioData[]>([]);

  useEffect(() => {
    /* Need to combine the live and scenario data arrays. Live data will have historicalPropertiesValue, historicalLoansValue and year values.
    /* These need to be combinded with the scenario data array. 
    /* Check if duplicate years exist, if so add liveData object to scenarioData entry, else, create a new entry
    /* where scenario data values are 0 or undefined. */
    if (scenarioData && liveData) {
      var combinedData = [...scenarioData];

      liveData.forEach((liveEntry) => {
        var prevEntry = combinedData.find(
          (entry) => entry.year === liveEntry.year
        );

        if (prevEntry) {
          combinedData = combinedData.map((entry) =>
            entry.year === liveEntry.year
              ? {
                  ...entry,
                  historicalSuperannuationValue:
                    liveEntry.historicalSuperannuationValue,
                }
              : entry
          );
        } else {
          combinedData.push({
            ...liveEntry,
            projectedValue: 0,
            withdrawals: 0,
            concessionalContributions: 0,
            contributionsTax: 0,
            growth: 0,
            growthTax: 0,
            nonConcessionalContributions: 0,
            transferBalanceCap: 0,
          });
        }
      });
      setData(combinedData.sort((a, b) => a.year - b.year));
    }
  }, [scenarioData, liveData]);

  if (!scenarioData || !liveData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
        barGap={"5%"}
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />
        <XAxis
          interval={data.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            offset={-30}
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />
        <ReferenceLine y={0} stroke="#000" />

        <Line
          type="monotone"
          dataKey={"transferBalanceCap"}
          stroke={context.colours.contributions}
          isAnimationActive={false}
          dot={false}
        />

        {/* {scenarioData
          .reduce<string[]>(
            (accumulator, entry) => [
              ...new Set([
                ...accumulator,
                ...Object.keys(entry).filter(
                  (key) => key !== "year" && key !== "transferBalanceCap"
                ),
              ]),
            ],
            []
          )
          .reduce<Array<string[]>>((accumulator, key, index) => {
            if (index % 3 === 0) {
              accumulator.push([key]);
            } else {
              accumulator[accumulator.length - 1].push(key);
            }
            return accumulator;
          }, [])
          .flatMap((key, keyIndex) =>
            key.map((entry, entryIndex) => (
              <Bar
                key={entry}
                dataKey={entry}
                fill={
                  entry.includes("sent")
                    ? context.colours.orange400
                    : entry.includes("received")
                    ? context.colours.green400
                    : context.colours.blue400
                }
                isAnimationActive={false}
                maxBarSize={50}
                stackId={keyIndex}
              />
            ))
          )} */}

        {scenarioData[0] &&
          scenarioData
            .reduce<string[]>(
              (accumulator, entry) => [
                ...new Set([
                  ...accumulator,
                  ...Object.keys(entry).filter(
                    (key) => key !== "year" && key !== "transferBalanceCap"
                  ),
                ]),
              ],
              []
            )
            .map((key, keyIndex) => (
              <Bar
                key={key}
                dataKey={key}
                fill={context.colours[Object.keys(context.colours)[keyIndex]]}
                isAnimationActive={false}
                maxBarSize={50}
              />
            ))}

        {liveData && liveData.length > 0 && (
          <Line
            type="monotone"
            dataKey={"historicalSuperannuationValue"}
            stroke={context.colours.lightPurple300}
            isAnimationActive={false}
            strokeWidth={2}
            connectNulls={true}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
