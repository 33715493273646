import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import {
  SortOrder,
  useFindManyClientsQuery,
  useFindManyEntitiesQuery,
  useFindManyGroupsQuery,
} from "../../../codegen/schema";

import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";

import { ComponentLoader } from "../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../Components/Refetch";

interface FilterOptionsReturn {
  ID: number;
  groupID: number;
  type: "groups" | "clients" | "entities";
  groupname?: string;
  status?: string;
  date_archived?: Date;
  clientname?: string;
  phonenumber?: string;
  email?: string;
  entityname?: string;
}

export const Searchbar: React.FC = () => {
  const navigate = useNavigate();

  const {
    data: { findManyGroups: allGroups } = {},
    error: allGroupsError,
    loading: allGroupsLoading,
    refetch: allGroupsRefetch,
  } = useFindManyGroupsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      orderBy: {
        Status: SortOrder.Asc,
      },
    },
  });

  const {
    data: { findManyClients: allClients } = {},
    error: allClientsError,
    loading: allClientsLoading,
    refetch: allClientsRefetch,
  } = useFindManyClientsQuery({
    fetchPolicy: "cache-and-network",
  });

  const {
    data: { findManyEntities: allEntities } = {},
    error: allEntitiesError,
    loading: allEntitiesLoading,
    refetch: allEntitiesRefetch,
  } = useFindManyEntitiesQuery({
    fetchPolicy: "cache-and-network",
  });

  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<FilterOptionsReturn[]>(
    []
  );

  if (
    (!allGroups && allGroupsLoading) ||
    (!allClients && allClientsLoading) ||
    (!allEntities && allEntitiesLoading)
  ) {
    return <ComponentLoader />;
  }

  if (
    (allGroupsError && !allGroupsLoading) ||
    (allClientsError && !allClientsLoading) ||
    (allEntitiesError && !allEntitiesLoading)
  ) {
    return (
      <Refetch
        refetch={() => {
          allGroupsRefetch();
          allClientsRefetch();
          allEntitiesRefetch();
        }}
      />
    );
  }

  return (
    <Fragment>
      <div className="w-full bg-white transition-all relative flex flex-col">
        <Combobox
          onChange={(option: FilterOptionsReturn) => {
            option && navigate(`/group/ID/${option.groupID}`);
          }}
          onClose={() => {
            setQuery("");
            setFilteredOptions([]);
          }}
        >
          <div className="relative flex flex-1 items-center w-full">
            <div
              className="pointer-events-none absolute h-5 w-5 text-neutral-400 icon fa-regular fa-magnifying-glass"
              aria-hidden="true"
            />
            <ComboboxInput
              placeholder="Search groups or clients..."
              className="input-search"
              onChange={(event) => {
                filterOptionOnChange({ query: event?.target.value });
                setQuery(event?.target.value);
              }}
            />
          </div>
          {query && (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <ComboboxOptions
                static
                className="absolute top-16 max-h-72 w-full scroll-py-2 overflow-y-auto py-2 text-sm text-neutral-800 bg-white shadow rounded-sm"
              >
                {filteredOptions.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredOptions.map(
                    (option, index) =>
                      option && (
                        <ComboboxOption
                          key={option.ID + option.type + index}
                          value={option}
                          as={Fragment}
                        >
                          {({ focus, selected }) => (
                            <div
                              className={clsx(
                                focus && "bg-blue-500 text-white font-semibold",
                                "group flex gap-2 p-2 cursor-pointer"
                              )}
                            >
                              {option.type === "groups"
                                ? option.groupname
                                : option.type === "clients"
                                ? option.clientname
                                : option.entityname}
                            </div>
                          )}
                        </ComboboxOption>
                      )
                  )
                )}
              </ComboboxOptions>
            </Transition>
          )}
        </Combobox>
      </div>
    </Fragment>
  );

  function filterOptionOnChange({ query }: { query: string }) {
    var options: FilterOptionsReturn[] = [];

    if (allGroups && allClients && allEntities)
      if (options.length < 50) {
        [...allGroups, ...allClients, ...allEntities].forEach((option) => {
          switch (option.__typename) {
            case "groups":
              // If GroupName or ID match input
              if (
                option.GroupName.toLowerCase().includes(query.toLowerCase()) ||
                option.ID.toString() === query
              ) {
                options.push({
                  ID: option.ID,
                  groupID: option.ID,
                  type: "groups",
                  groupname: option.GroupName,
                  status: option.Status ?? "",
                  date_archived: option.date_archived,
                });
              }

              break;

            case "clients":
              // If client names, numbers or emails match the query
              if (
                option?.FirstName?.toLowerCase().includes(
                  query.toLowerCase()
                ) ||
                option?.LastName?.toLowerCase().includes(query.toLowerCase()) ||
                option?.PreferredName?.toLowerCase().includes(
                  query.toLowerCase()
                ) ||
                (
                  option?.FirstName?.toLowerCase() +
                  " " +
                  option?.LastName?.toLowerCase()
                ).includes(query.toLowerCase()) ||
                (
                  option?.PreferredName?.toLowerCase() +
                  " " +
                  option?.LastName?.toLowerCase()
                ).includes(query.toLowerCase()) ||
                (option.MobileNumber !== null &&
                  (option?.MobileNumber?.toLowerCase()
                    .replace(/ /g, "")
                    .includes(query.toLowerCase()) ||
                    option?.MobileNumber?.toLowerCase().includes(
                      query.toLowerCase()
                    ))) ||
                (option.OtherMobile !== null
                  ? option?.OtherMobile?.toLowerCase()
                      .replace(/ /g, "")
                      .includes(query.toLowerCase()) ||
                    option?.OtherMobile?.toLowerCase().includes(
                      query.toLowerCase()
                    )
                  : "") ||
                (option.HomeNumber !== null &&
                  (option?.HomeNumber?.toLowerCase()
                    .replace(/ /g, "")
                    .includes(query.toLowerCase()) ||
                    option?.HomeNumber?.toLowerCase().includes(
                      query.toLowerCase()
                    ))) ||
                (option.WorkNumber !== null &&
                  (option?.WorkNumber?.toLowerCase()
                    .replace(/ /g, "")
                    .includes(query.toLowerCase()) ||
                    option?.WorkNumber?.toLowerCase().includes(
                      query.toLowerCase()
                    ))) ||
                (option.PersonalEmail !== null &&
                  option?.PersonalEmail?.toLowerCase().includes(
                    query.toLowerCase()
                  )) ||
                (option.WorkEmail !== null &&
                  option?.WorkEmail?.toLowerCase().includes(
                    query.toLowerCase()
                  ))
              ) {
                options.push({
                  ID: option.ID,
                  groupID: option.groups_ID,
                  type: "clients",
                  clientname: option.FirstName + " " + option.LastName,
                  phonenumber: option.MobileNumber ?? undefined,
                  email: option.PreferredEmail ?? undefined,
                });
              }

              break;

            case "entities":
              if (option?.Name?.toLowerCase().includes(query.toLowerCase())) {
                // var entityGroup = allGroups?.find(
                //   (group) => group.ID === option.groups_ID
                // );
                // if (entityGroup) {
                options.push({
                  ID: option.ID,
                  groupID: option.groups_ID,
                  type: "entities",
                  entityname: option.Name,
                });
                // }
              }
              break;

            default:
              break;
          }
        });
      }
    if (query !== "") {
      setFilteredOptions(options.slice(0, 50));
    } else {
      setFilteredOptions([]);
    }
  }
};
