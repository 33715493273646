import { gql } from "@apollo/client";
import { insuranceFragment } from "./fragment";

export const insurance = gql`
  subscription Insurance($where: insuranceWhereUniqueInput) {
    insurance(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...insurance
      }
      clients_has_insurance {
        insurance_ID
        clients_ID
      }
    }
  }
  ${insuranceFragment}
`;

export const insuranceMany = gql`
  subscription InsuranceMany($where: insuranceWhereInput) {
    insuranceMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...insurance
      }
      clients_has_insurance {
        insurance_ID
        clients_ID
      }
    }
  }
  ${insuranceFragment}
`;

