import dayjs from "dayjs";
import {
  Annuity,
  Cash,
  Collectibles,
  Offset,
  Shares,
  Superannuation,
  Property,
  Vehicles,
  Otherassets,
  Loans,
  Otherliabilities,
  UpdateOneAnnuityMutationFn,
  UpdateOneCashMutationFn,
  UpdateOneCollectiblesMutationFn,
  UpdateOneOffsetMutationFn,
  UpdateOneSharesMutationFn,
  UpdateOnePropertyMutationFn,
  UpdateOneVehiclesMutationFn,
  UpdateOneOtherassetsMutationFn,
  UpdateOneLoansMutationFn,
  UpdateOneOtherliabilitiesMutationFn,
  UpdateOneSuperannuationMutationFn,
  FindCurrentUserQuery,
  UpdateOneInsuranceMutationFn,
  Insurance,
  AssetsCustom,
  FindUniqueGroupQuery,
  LiabilitiesCustom,
} from "../../codegen/schema";

/**
 * @created 21/09/2022
 * @updated 06/02/2024
 * @description Runs update asset mutation for the owner relation
 */
export function updateAssetOwner(
  value: string,
  mutation:
    | UpdateOneAnnuityMutationFn
    | UpdateOneCashMutationFn
    | UpdateOneCollectiblesMutationFn
    | UpdateOneOffsetMutationFn
    | UpdateOneSharesMutationFn
    | UpdateOneSuperannuationMutationFn
    | UpdateOnePropertyMutationFn
    | UpdateOneVehiclesMutationFn
    | UpdateOneOtherassetsMutationFn,
  asset:
    | Annuity
    | Cash
    | Collectibles
    | Offset
    | Shares
    | Superannuation
    | Property
    | Vehicles
    | Otherassets,
  user: FindCurrentUserQuery["findCurrentUser"]
) {
  const relation_ID = [...JSON.parse(value).data];
  // ? [...JSON.parse((event.target as HTMLInputElement).value).data]
  // : [...JSON.parse(value.value).data];
  var relationType = Object.keys(relation_ID[0]).includes("entities_ID")
    ? "entities"
    : "clients";

  mutation({
    variables: {
      where: {
        ID: asset.ID,
      },
      data: {
        updated_by: { set: user?.staff_ID },
        update_time: { set: dayjs().format("YYYY-MM-DD HH:mm:ss") },
        assets: {
          update: {
            data:
              relationType === "clients"
                ? {
                    entities_has_assets: {
                      deleteMany: [{ assets_ID: { in: [asset.assets_ID] } }],
                    },
                    clients_has_assets: {
                      deleteMany: [
                        {
                          clients_ID: {
                            notIn: relation_ID.flatMap(
                              (relation) => relation.clients_ID
                            ),
                          },
                        },
                      ],
                      createMany: { skipDuplicates: true, data: relation_ID },
                    },
                  }
                : {
                    clients_has_assets: {
                      deleteMany: [{ assets_ID: { in: [asset.assets_ID] } }],
                    },
                    entities_has_assets: {
                      deleteMany: [{ assets_ID: { in: [asset.assets_ID] } }],

                      create: [
                        {
                          entities: {
                            connect: {
                              ID: relation_ID[0].entities_ID,
                            },
                          },
                        },
                      ],
                    },
                  },
          },
        },
      },
    },
  });
}

/**
 * @created 21/09/2022
 * @updated 06/02/2024
 * @description Runs update liabiliity mutation for the owner relation
 */
export function updateLiabilityOwner(
  value: string,
  mutation: UpdateOneLoansMutationFn | UpdateOneOtherliabilitiesMutationFn,
  liability: Loans | Otherliabilities,
  user: FindCurrentUserQuery["findCurrentUser"]
) {
  const relation_ID = [...JSON.parse(value).data];
  var relationType = Object.keys(relation_ID[0]).includes("entities_ID")
    ? "entities"
    : "clients";

  mutation({
    variables: {
      where: {
        ID: liability.ID,
      },
      data: {
        updated_by: { set: user?.staff_ID },
        update_time: { set: dayjs().format("YYYY-MM-DD HH:mm:ss") },
        liabilities: {
          update: {
            data:
              relationType === "clients"
                ? {
                    entities_has_liabilities: {
                      deleteMany: [
                        {
                          liabilities_ID: { in: [liability.liabilities_ID] },
                        },
                      ],
                    },
                    clients_has_liabilities: {
                      deleteMany: [
                        {
                          clients_ID: {
                            notIn: relation_ID.flatMap(
                              (relation) => relation.clients_ID
                            ),
                          },
                        },
                      ],
                      createMany: { skipDuplicates: true, data: relation_ID },
                    },
                  }
                : {
                    clients_has_liabilities: {
                      deleteMany: [
                        {
                          liabilities_ID: { in: [liability.liabilities_ID] },
                        },
                      ],
                    },
                    entities_has_liabilities: {
                      deleteMany: [
                        {
                          liabilities_ID: { in: [liability.liabilities_ID] },
                        },
                      ],
                      create: [
                        {
                          entities: {
                            connect: {
                              ID: relation_ID[0].entities_ID,
                            },
                          },
                        },
                      ],
                    },
                  },
          },
        },
      },
    },
  });
}

/**
 * @author Samantha Harrison
 * @date 21/09/2022
 * @description Runs specified mutation on insurance to update the owner relation
 */
export function updateInsuranceOwner(
  value: string,
  mutation: UpdateOneInsuranceMutationFn,
  insurance: Insurance,
  user: FindCurrentUserQuery["findCurrentUser"]
) {
  mutation({
    variables: {
      where: {
        ID: insurance.ID,
      },
      data: {
        clients_has_insurance: {
          deleteMany: [
            {
              insurance_ID: { equals: insurance.ID },
            },
          ],
          create: [
            {
              clients: {
                connect: {
                  ID: [...JSON.parse(value).data][0].clients_ID,
                },
              },
            },
          ],
        },
        updated_by: { set: user?.staff_ID },
        update_time: { set: dayjs().format("YYYY-MM-DD HH:mm:ss") },
      },
    },
  });
}

/**
 * @author Taylor Bindon
 * @date 29-09-2021
 * @updated 08-02-2024
 * @description Returns object of text and value to determine selected value in dataselect component.
 */
export function ownerOptionsUpdateAssetDefaultValue(
  asset: { assets_ID: number | null | undefined },
  group: FindUniqueGroupQuery["findUniqueGroup"]
): {
  text: string;
  value: string;
} {
  var result = { text: "", value: "" };

  if (!asset.assets_ID || !group) return result;

  if (
    [...group.entities].some((entity) =>
      (Object.keys(entity.assets) as Array<keyof AssetsCustom>).some(
        (assetType) => {
          if (assetType !== "__typename") {
            if (
              entity.assets[assetType].some(
                (entry: { assets_ID: number }) =>
                  entry.assets_ID === asset.assets_ID
              )
            )
              return true;
          }
          return false;
        }
      )
    )
  ) {
    [...group.entities].forEach((entity) => {
      (Object.keys(entity.assets) as Array<keyof AssetsCustom>).forEach(
        (assetType) => {
          if (assetType !== "__typename") {
            if (
              entity.assets[assetType].some(
                (entry) => entry.assets_ID === asset.assets_ID
              )
            ) {
              result = {
                text: entity.Name ?? "",
                value: JSON.stringify({
                  data: [
                    {
                      // assets_ID: asset.assets_ID,
                      entities_ID: entity.ID,
                      entities_groups_ID: group.ID,
                      entities_groups_organisation_ID: group.organisation_ID,
                    },
                  ],
                }),
              };
            }
          }
        }
      );
    });
  } else if (
    group.clients.length > 1 &&
    [...group.clients].every((client) =>
      (Object.keys(client.assets) as Array<keyof AssetsCustom>).some(
        (assetType) => {
          if (assetType !== "__typename") {
            if (
              client.assets[assetType].some(
                (entry) => entry.assets_ID === asset.assets_ID
              )
            )
              return true;
          }
          return false;
        }
      )
    )
  ) {
    result = {
      text: "Joint",
      value: JSON.stringify({
        data: group.clients.map((client) => ({
          // assets_ID: asset.assets_ID,
          clients_ID: client.ID,
          clients_groups_ID: group.ID,
          clients_groups_organisation_ID: group.organisation_ID,
        })),
      }),
    };
  } else {
    [...group.clients].forEach((client) => {
      (Object.keys(client.assets) as Array<keyof AssetsCustom>).forEach(
        (assetType) => {
          if (assetType !== "__typename") {
            if (
              client.assets[assetType].some(
                (entry) => entry.assets_ID === asset.assets_ID
              )
            ) {
              result = {
                text: client.FirstName + " " + client.LastName,
                value: JSON.stringify({
                  data: [
                    {
                      // assets_ID: asset.assets_ID,
                      clients_ID: client.ID,
                      clients_groups_ID: group.ID,
                      clients_groups_organisation_ID: group.organisation_ID,
                    },
                  ],
                }),
              };
            }
          }
        }
      );
    });
  }

  return result;
}

/**
 * @author Taylor Bindon
 * @date 29-09-2021
 * @updated 08-02-2024
 * @description Returns object of text and value to determine selected value in dataselect component.
 */
export function ownerOptionsUpdateLiabilityDefaultValue(
  liability: { liabilities_ID: number },
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  var result = { text: "", value: "" };

  if (!liability || !group) return result;

  if (
    group.entities.some((entity) =>
      (Object.keys(entity.liabilities) as Array<keyof LiabilitiesCustom>).some(
        (liabilityType) => {
          if (liabilityType !== "__typename") {
            if (
              entity.liabilities[liabilityType].some(
                (entry) => entry.liabilities_ID === liability.liabilities_ID
              )
            )
              return true;
          }
          return false;
        }
      )
    )
  ) {
    group.entities.forEach((entity) => {
      (
        Object.keys(entity.liabilities) as Array<keyof LiabilitiesCustom>
      ).forEach((liabilityType) => {
        if (liabilityType !== "__typename") {
          if (
            entity.liabilities[liabilityType].some(
              (entry) => entry.liabilities_ID === liability.liabilities_ID
            )
          ) {
            result = {
              text: entity.Name ?? "",
              value: JSON.stringify({
                data: [
                  {
                    // liabilities_ID: liability.liabilities_ID,
                    entities_ID: entity.ID,
                    entities_groups_ID: group.ID,
                    entities_groups_organisation_ID: group.organisation_ID,
                  },
                ],
              }),
            };
          }
        }
      });
    });
  } else if (
    group.clients.length > 1 &&
    group.clients.every((client) =>
      (Object.keys(client.liabilities) as Array<keyof LiabilitiesCustom>).some(
        (liabilityType) => {
          if (liabilityType !== "__typename") {
            if (
              client.liabilities[liabilityType].some(
                (entry) => entry.liabilities_ID === liability.liabilities_ID
              )
            )
              return true;
          }
          return false;
        }
      )
    )
  ) {
    result = {
      text: "Joint",
      value: JSON.stringify({
        data: group.clients.map((client) => ({
          // liabilities_ID: liability.liabilities_ID,
          clients_ID: client.ID,
          clients_groups_ID: group.ID,
          clients_groups_organisation_ID: group.organisation_ID,
        })),
      }),
    };
  } else {
    group.clients.forEach((client) => {
      (
        Object.keys(client.liabilities) as Array<keyof LiabilitiesCustom>
      ).forEach((liabilityType) => {
        if (liabilityType !== "__typename") {
          if (
            client.liabilities[liabilityType].some(
              (entry) => entry.liabilities_ID === liability.liabilities_ID
            )
          ) {
            result = {
              text: client.FirstName + " " + client.LastName,
              value: JSON.stringify({
                data: [
                  {
                    // liabilities_ID: liability.liabilities_ID,
                    clients_ID: client.ID,
                    clients_groups_ID: group.ID,
                    clients_groups_organisation_ID: group.organisation_ID,
                  },
                ],
              }),
            };
          }
        }
      });
    });
  }
  return result;
}

/**
 * @author Taylor Bindon
 * @date 07-09-2021
 * @updated 08-02-2024
 * @description Returns array of text and value objects to determine owners for form submission. Clients, joint, and entities.
 */
export function ownerOptionsUpdateClientsAndEntitiesManyToMany(
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  var ownerList: Array<{ text: string; value: string }> = [];

  if (!group) return ownerList;

  if (group.clients?.length > 1) {
    ownerList.push({
      text: "Joint",
      value: JSON.stringify({
        data: group.clients.map((client) => {
          return {
            clients_ID: client.ID,
            clients_groups_ID: group.ID,
            clients_groups_organisation_ID: group.organisation_ID,
          };
        }),
      }),
    });
  }
  group.clients?.forEach((client) => {
    ownerList.push({
      text: client.FirstName + " " + client.LastName,
      value: JSON.stringify({
        data: [
          {
            clients_ID: client.ID,
            clients_groups_ID: group.ID,
            clients_groups_organisation_ID: group.organisation_ID,
          },
        ],
      }),
    });
  });
  group.entities?.forEach((entity) => {
    ownerList.push({
      text: entity.Name ?? "",
      value: JSON.stringify({
        data: [
          {
            entities_ID: entity.ID,
            entities_groups_ID: group.ID,
            entities_groups_organisation_ID: group.organisation_ID,
          },
        ],
      }),
    });
  });
  return ownerList;
}
