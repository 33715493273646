import { gql } from "@apollo/client";

export const termsandconditionsFragment = gql`
  fragment termsandconditions on termsandconditions {
    ID
    staff_ID
    staff_organisation_ID
    Version
    create_time
    update_time
    created_by
    updated_by
  }
`;
