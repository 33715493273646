import { QueryResult } from "@apollo/client";
import {
  ContenttemplateManySubscription,
  FindManyContenttemplateQuery,
  MutationType,
  FindManyContenttemplateQueryVariables,
} from "../../../codegen/schema";
import { contenttemplateMany } from "../subscription";

interface ContenttemplateResolverParams {
  prev: FindManyContenttemplateQuery;
  payload: ContenttemplateManySubscription["contenttemplateMany"];
}

function createContenttemplate({
  prev,
  payload,
}: ContenttemplateResolverParams) {
  const contenttemplate = payload.data;

  return Object.assign({}, prev, {
    findManyContenttemplate: [
      ...prev.findManyContenttemplate,
      contenttemplate,
    ].sort((a, b) => a.Position! - b.Position!),
  });
}

function updateContenttemplate({
  prev,
  payload,
}: ContenttemplateResolverParams) {
  const contenttemplate = payload.data;

  return Object.assign({}, prev, {
    findManyContenttemplate: prev.findManyContenttemplate
      .map((contenttemplates) =>
        contenttemplates.ID === contenttemplate.ID
          ? {
              ...contenttemplates,
              ...contenttemplate,
            }
          : contenttemplates
      )
      .sort((a, b) => a.Position! - b.Position!),
  });
}

function deleteContenttemplate({
  prev,
  payload,
}: ContenttemplateResolverParams) {
  const contenttemplate = payload.data;

  return Object.assign({}, prev, {
    findManyContenttemplate: prev.findManyContenttemplate
      .filter((contenttemplates) => contenttemplates.ID !== contenttemplate.ID)
      // .sort((a, b) => a.Position! - b.Position!),
  });
}

function contenttemplateResolver({
  prev,
  payload,
}: ContenttemplateResolverParams) {
  const { data, mutationType } = payload;
  
  switch (mutationType) {
    case MutationType.Create:
      return createContenttemplate({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyContenttemplate.some(
        (contenttemplate) => contenttemplate.ID === data.ID
      )
        ? updateContenttemplate({ prev, payload })
        : createContenttemplate({ prev, payload });

    case MutationType.Delete:
      return deleteContenttemplate({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyContenttemplate(
  query: Pick<
    QueryResult<
      FindManyContenttemplateQuery,
      FindManyContenttemplateQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: contenttemplateMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ContenttemplateManySubscription } }
    ) =>
      contenttemplateResolver({
        prev,
        payload: payload.subscriptionData.data.contenttemplateMany,
      }),
    variables: query.variables,
  });
}
