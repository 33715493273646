import { gql } from "@apollo/client";
import { bestinterestFragment } from ".";

export const createOneBestinterest = gql`
  mutation CreateOneBestinterest($data: bestinterestCreateInput!) {
    createOneBestinterest(data: $data) {
      ...bestinterest
    }
  }
  ${bestinterestFragment}
`;

export const updateOneBestinterest = gql`
  mutation UpdateOneBestinterest(
    $data: bestinterestUpdateInput!
    $where: bestinterestWhereUniqueInput!
  ) {
    updateOneBestinterest(data: $data, where: $where) {
      ...bestinterest
    }
  }
  ${bestinterestFragment}
`;

export const deleteOneBestinterest = gql`
  mutation DeleteOneBestinterest($where: bestinterestWhereUniqueInput!) {
    deleteOneBestinterest(where: $where) {
      ...bestinterest
    }
  }
  ${bestinterestFragment}
`;
