import * as React from "react";
import { Modal } from "../Modal";

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

const useNavigatorOnLine = () => {
  const [status, setStatus] = React.useState(getOnLineStatus());
  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return status;
};

export const NetworkStatusIndicator = () => {
  const isOnline = useNavigatorOnLine();
  console.log("Online? ", isOnline);
  return (
    <Modal modalOpen={!isOnline}>
      {({ setOpen }) => (
        <div>
          <div className="flex flex-col items-center mt-2 gap-4">
            <div className="icon fa-regular fa-triangle-exclamation fa-xl h-5 " />

            <h4>Oops, looks like your network might be having trouble</h4>
            <p>
              We've put things on hold for now until your network connection
              stabilises.
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};
