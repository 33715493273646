import { gql } from "@apollo/client";
import { contenttemplateFragment } from "./fragment";

export const findUniqueContenttemplate = gql`
  query FindUniqueContenttemplate($where: contenttemplateWhereUniqueInput!) {
    findUniqueContenttemplate(where: $where) {
      ...contenttemplate
    }
  }
  ${contenttemplateFragment}
`;

export const findManyContenttemplate = gql`
  query FindManyContenttemplate(
    $where: contenttemplateWhereInput
    $distinct: [ContenttemplateScalarFieldEnum!]
    $orderBy: [contenttemplateOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: contenttemplateWhereUniqueInput
  ) {
    findManyContenttemplate(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...contenttemplate
    }
  }
  ${contenttemplateFragment}
`;
