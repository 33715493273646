import { gql } from "@apollo/client";
import { scenariocontributionwithdrawalFragment } from "../scenariocontributionwithdrawal";

export const scenarioassetCoreFragment = gql`
  fragment scenarioassetCore on scenarioasset {
    ID
    scenario_ID
    scenario_advice_ID
    scenario_advice_groups_ID
    scenario_advice_groups_organisation_ID
    Name
    Type
    Value
    RateOfReturn
    RentalYield
    OngoingCosts
    CostIndexation

    AnnualFee
    AnnualFeeValue

    SuperannuationInsurance
    SuperannuationInsuranceIndexation

    PropertyType
    PropertyIncomePaidTo_scenarioclient_ID
    PropertyNonCashTaxDeductions

    assets_ID

    create_time
    update_time
    created_by
    updated_by
  }
`;

export const scenarioassetFragment = gql`
  fragment scenarioasset on scenarioasset {
    ...scenarioassetCore

    scenariocontributionwithdrawal {
      ...scenariocontributionwithdrawal
    }
  }
  ${scenarioassetCoreFragment}
  ${scenariocontributionwithdrawalFragment}
`;
