import React from "react";
import { LiveData } from "../../../Calculations";
import { InvestmentPropertyDebtLiveModelGraph } from "../Graph";
import dayjs from "dayjs";
import { StyledText } from "../../../../../StyledText";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";

interface InvestmentPropertyDebtPlaceholderModelProps {
  theme?: Theme;
}

var data: LiveData[] = [];

Array.from(Array(5).keys()).forEach((value, index) => {
  data.push({
    year: dayjs().subtract(5, "year").year() + value,
    HomeLoanTotal:
      index === 0
        ? -300000
        : index === 1
        ? -300000
        : index === 2
        ? -320000
        : index === 3
        ? -320000
        : index === 4
        ? -400000
        : index === 5
        ? -380000
        : undefined,
    InvestmentLoanTotal:
      index === 0
        ? 0
        : index === 1
        ? -800000
        : index === 2
        ? -800000
        : index === 3
        ? -1200000
        : index === 4
        ? -1200000
        : index === 5
        ? -1400000
        : undefined,
    SuperannuationLoanTotal: index === 0
    ? 0
    : index === 1
    ? 0
    : index === 2
    ? -120000
    : index === 3
    ? -120000
    : index === 4
    ? -140000
    : index === 5
    ? -140000
    : undefined,
  });
});

export const InvestmentPropertyDebtPlaceholderModel = ({
  theme,
}: InvestmentPropertyDebtPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Investment and Property Debt
        </StyledText>

        <ModelTooltip type={Model_Type.InvestmentAndPropertyDebt} />
      </div>
      <h1
 className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InvestmentPropertyDebtLiveModelGraph liveData={data} />
      </div>
    </React.Fragment>
  );
};
