import { gql } from "@apollo/client";
import { otherassetsFragment } from "./fragment";

export const otherassets = gql`
  subscription Otherassets($where: otherassetsWhereUniqueInput) {
    otherassets(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...otherassets
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${otherassetsFragment}
`;

export const otherassetsMany = gql`
  subscription OtherassetsMany($where: otherassetsWhereInput) {
    otherassetsMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...otherassets
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${otherassetsFragment}
`;
