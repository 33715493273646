import { Maybe } from "graphql/jsutils/Maybe";
import { Descendant } from "slate";

export type CustomElement =
  | TextElement
  | LinkElement
  | MentionElement
  | CheckListItemElement;

export type LinkElement = { type: "link"; align?: string, url: string; children: Descendant[] };

export type MentionElement = {
  type: "mention";
  align?: string,
  mention: string;
  children: CustomText[];
};

export type CheckListItemElement = {
  type: "check-list-item";
  align?: string,
  checked: boolean;
  children: CustomText[];
};

export type TextElement = {
  type: Maybe<string>;
  align?: string;
  columns?: 2 | undefined;
  lineHeight?: number | undefined;
  checked?: Boolean;
  children: CustomText[];
};

export type CustomText = {
  text: string;
  fontFamily?: Maybe<string>;
  fontSize?: Maybe<number>;
  fontWeight?: Maybe<number>;
  color?: Maybe<string>;
  letterSpacing?: Maybe<number>;
  backgroundColor?: Maybe<string>;
  bold?: Boolean;
  underline?: Boolean;
  italic?: Boolean;
  children?: Array<CustomText>;
};

export enum Type {
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  H5 = "H5",
  H6 = "H6",
  P = "P",
  MENTION = "mention",
  ORDERED_LIST = "ol",
  UNORDERED_LIST = "ul",
  LIST_ITEM = "list-item",
  LIST_ITEM_TEXT = "list-item-text",
  CHECK_LIST = "check-list-item",
}

export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
