import React, { useEffect, useState } from "react";

import { HexAlphaColorPicker } from "react-colorful";
import { useDebounce } from "use-debounce";
import {
  FindCurrentUserQuery,
  Theme,
  useFindManyThemeQuery,
} from "../../../../codegen/schema";
import { ComponentLoader } from "../../../Loaders/ComponentLoader";
import { ColourInputThemeColours } from "./ThemeColours";
import { Input } from "..";
import { Refetch } from "../../../Refetch";
import { CardPopper } from "../../../Menus/CardPopper";

import dayjs from "dayjs";
import clsx from "clsx";

interface ColourInputProps {
  defaultValue: string | undefined;
  name: string;
  label?: string;
  mutationID?: { ID: number } /* Mutation update object i.e {ID: id} */;
  mutation?: Function /* updateOneMutation */;
  onChange?: (value: string) => void;
  user?: FindCurrentUserQuery["findCurrentUser"];
  theme?: Theme;
  disabled?: boolean;
  icon?: string;
}

export const ColourInput: React.FC<ColourInputProps> = ({
  defaultValue,
  name,
  label,
  mutationID,
  mutation,
  onChange,
  user,
  theme,
  disabled,
  icon = "fa-solid fa-circle",
}) => {
  const {
    data: { findManyTheme: themes } = {},
    error: themesError,
    loading: themesLoading,
    refetch: themesRefetch,
  } = useFindManyThemeQuery({
    fetchPolicy: "cache-and-network",
  });

  const [color, setColor] = useState<string | undefined>(
    defaultValue === null ? "#ffffff00" : defaultValue
  );
  const [value] = useDebounce(color, 500);

  useEffect(() => {
    if (defaultValue !== null || defaultValue !== undefined) {
      setColor(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value !== defaultValue) {
      mutation &&
        mutationID &&
        mutation({
          variables: {
            where: { ID: mutationID.ID },
            data: {
              [name]: { set: value },
              updated_by: user ? { set: user.staff_ID } : { set: undefined },
              update_time: { set: dayjs().format("YYYY-MM-DD HH:mm:ss") },
            },
          },
        });
    }

    if (value) onChange && onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (!themes && themesLoading) {
    return <ComponentLoader />;
  }

  if (themesError && !themesLoading) {
    return (
      <Refetch
        refetch={() => {
          themesRefetch();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="relative w-full ">
        <label htmlFor="name" className={"select-label"}>
          {label}
        </label>
        <div className="w-full">
          <CardPopper
            id={"colour-picker"}
            buttonProps={{
              type: "select",
              text: (
                <div className="flex gap-2 w-full items-center justify-between">
                  <div
                    className={clsx(icon, "icon text-base pl-2")}
                    style={{ color: value !== "#FFFFFF" ? value : "#000000" }}
                  />
                  <div className="icon fa-regular fa-chevron-down text-xs text-neutral-400 h-6 w-6 ml-1" />
                </div>
              ),
              disabled: disabled,
            }}
          >
            {({ setMenuOpen }) => {
              return (
                <div className="max-w-xs p-3 flex flex-col gap-2 items-center">
                  <HexAlphaColorPicker color={color} onChange={setColor} />
                  <Input
                    label="HEX value"
                    name="Colour"
                    defaultValue={value}
                    className={"p border"}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setColor(event.target.value);
                    }}
                  />
                  {name === "Highlight" && (
                    <div className="flex gap-2 flex-wrap w-full justify-between items-center p-1 border-t border-neutral-100">
                      <p className="font-bold text-neutral-600">
                        Highlight colours
                      </p>
                      <div className="flex flex-wrap gap-y-3 gap-x-1 items-center">
                        <div
                          className="icon fa-solid fa-ban text-lg cursor-pointer"
                          style={{
                            color: "#000000",
                          }}
                          onClick={() => {
                            setColor("#FFFFFF");
                          }}
                        />
                        {["#FEFF02", "#00FF00", "#00FFFF", "#FF01FF"].map(
                          (colour, index) => {
                            return (
                              <div
                                className="icon fa-solid fa-circle text-lg cursor-pointer"
                                key={index}
                                style={{
                                  color: colour,
                                }}
                                onClick={() => {
                                  setColor(colour);
                                }}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}

                  {theme ? (
                    <div
                      className="flex gap-2 flex-wrap w-full justify-between items-center p-1 border-t border-neutral-100"
                      key={theme.ID}
                    >
                      <p className="font-bold text-neutral-600">{theme.Name}</p>
                      <ColourInputThemeColours
                        themeID={theme.ID}
                        setColor={setColor}
                      />
                    </div>
                  ) : (
                    themes?.map((theme) => {
                      return (
                        <div
                          className="flex gap-2 flex-wrap w-full justify-between items-center p-1 border-t border-neutral-100"
                          key={theme.ID}
                        >
                          <p className="font-bold text-neutral-600">
                            {theme.Name}
                          </p>
                          <ColourInputThemeColours
                            themeID={theme.ID}
                            setColor={setColor}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
              );
            }}
          </CardPopper>
        </div>
      </div>
    </React.Fragment>
  );
};
