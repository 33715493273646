import { gql } from "@apollo/client";
import { termsandconditionsFragment } from "./fragment";

export const createOneTermsandconditions = gql`
  mutation CreateOneTermsandconditions($data: termsandconditionsCreateInput!) {
    createOneTermsandconditions(data: $data) {
      ...termsandconditions
    }
  }
  ${termsandconditionsFragment}
`;
