import dayjs from "dayjs";
import {
  getGroupAssets,
  getGroupLiabilities,
} from "../../../../../../Functions/GeneralFunctions";
import {
  FindUniqueGroupQuery,
  AssetsCustom,
  LiabilitiesCustom,
} from "../../../../../../codegen/schema";

interface AdviceTotalsProps {
  group: FindUniqueGroupQuery["findUniqueGroup"];
}

export interface AdviceTotalsReturn {
  /** Home property total */
  HomePropertyTotal: number;
  /** All properties total (excluding home) */
  InvestmentPropertyTotal: number;
  /** Investments total (all assets bar home properties and superannuation) */
  InvestmentAssetTotal: number;
  /** Superanniation and SMSF assets total */
  SuperannuationAssetTotal: number;
  /** Home debt total */
  HomeLoanTotal: number;
  /** Investments debt (all debt bar home and superannuation) */
  InvestmentLoanTotal: number;
  /** Superannuation debt total */
  SuperannuationLoanTotal: number;
  /** Object containing all totals. */
  totals: {
    /** Total gross assets, all assets */
    grossAssets: number;
    /** Total gross liabilities, all liabilities */
    grossLiabilities: number;
    /** Total net wealth, grossAssets - grossLiabilities */
    netWealth: number;
    /** Total net investment wealth, InvestmentAssetTotal + InvestmentPropertyTotal - InvestmentLoanTotal */
    netInvestmentWealth: number;
    /** Total net superannuation wealth, SuperannuationAssetTotal - SuperannuationLoanTotal */
    netSuperannuationWealth: number;
  };
}

export function AdviceTotalsCalculation({
  group,
}: AdviceTotalsProps): AdviceTotalsReturn {
  var data: AdviceTotalsReturn = {
    HomePropertyTotal: 0,
    InvestmentPropertyTotal: 0,
    InvestmentAssetTotal: 0,
    SuperannuationAssetTotal: 0,
    HomeLoanTotal: 0,
    InvestmentLoanTotal: 0,
    SuperannuationLoanTotal: 0,
    totals: {
      grossAssets: 0,
      grossLiabilities: 0,
      netWealth: 0,
      netInvestmentWealth: 0,
      netSuperannuationWealth: 0,
    },
  };

  /* Get group's assets */
  var assets = getGroupAssets(group);

  /* Get group's liabilities */
  var liabilities = getGroupLiabilities(group);

  /** @description Calculate asset totals */
  (Object.keys(assets) as Array<keyof AssetsCustom>).forEach((assetType) => {
    if (assetType !== "__typename") {
      if (assets[assetType].length > 0) {
        assets[assetType].forEach((asset: any) => {
          if (
            group?.entities.some(
              (entity) =>
                entity.Type === "SMSF" &&
                entity.assets![assetType]!.some(
                  (entry: { assets_ID: number }) =>
                    entry.assets_ID === asset.assets_ID
                )
            )
          ) {
            if (asset.Type !== "Sold") {
              data.SuperannuationAssetTotal += asset.MarketValue
                ? parseInt(asset.MarketValue)
                : asset.Value
                ? parseInt(asset.Value)
                : asset.Amount
                ? parseInt(asset.Amount)
                : asset.TotalAnnual
                ? parseInt(asset.TotalAnnual)
                : 0;
            }
          } else {
            switch (assetType) {
              case "property":
                if (asset.Type !== "Sold") {
                  if (asset.Type !== "Home") {
                    data.InvestmentPropertyTotal += asset.MarketValue
                      ? parseInt(asset.MarketValue)
                      : 0;
                  } else {
                    data.HomePropertyTotal += asset.MarketValue
                      ? parseInt(asset.MarketValue)
                      : 0;
                  }
                }
                break;

              case "superannuation":
                data.SuperannuationAssetTotal += asset.Value
                  ? parseInt(asset.Value)
                  : 0;
                break;

              default:
                data.InvestmentAssetTotal += asset.Value
                  ? parseInt(asset.Value)
                  : asset.Amount
                  ? parseInt(asset.Amount)
                  : asset.TotalAnnual
                  ? parseInt(asset.TotalAnnual)
                  : 0;
                break;
            }
          }
        });
      }
    }
  });

  /** @description Calculate liability totals */
  (Object.keys(liabilities) as Array<keyof LiabilitiesCustom>).forEach(
    // eslint-disable-next-line no-loop-func
    (liabilityType) => {
      if (liabilityType !== "__typename") {
        if (liabilities[liabilityType].length > 0) {
          liabilities[liabilityType].forEach((liability: any) => {
            if (
              group?.entities.some(
                (entity) =>
                  entity.Type === "SMSF" &&
                  entity.liabilities![liabilityType]!.some(
                    (entry: any) =>
                      entry.liabilities_ID === liability.liabilities_ID
                  )
              )
            ) {
              data.SuperannuationLoanTotal += liability.CurrentLoanOutstanding
                ? parseInt(liability.CurrentLoanOutstanding)
                : liability.CurrentLoanOutstanding
                ? parseInt(liability.CurrentLoanOutstanding)
                : 0;
            } else {
              switch (liabilityType) {
                case "loans":
                  if (liability.LoanType === "Home") {
                    data.HomeLoanTotal += liability.CurrentLoanOutstanding
                      ? parseInt(liability.CurrentLoanOutstanding)
                      : 0;
                  } else if (liability.LoanType === "Investment") {
                    data.InvestmentLoanTotal += liability.CurrentLoanOutstanding
                      ? parseInt(liability.CurrentLoanOutstanding)
                      : 0;
                  }
                  break;

                case "otherliabilities":
                  if (liability.Type === "Home") {
                    data.HomeLoanTotal += liability.CurrentLoanOutstanding
                      ? parseInt(liability.CurrentLoanOutstanding)
                      : 0;
                  } else {
                    data.InvestmentLoanTotal += liability.CurrentLoanOutstanding
                      ? parseInt(liability.CurrentLoanOutstanding)
                      : 0;
                  }
                  break;

                default:
                  break;
              }
            }
          });
        }
      }
    }
  );

  data.totals.grossAssets =
    data.InvestmentPropertyTotal +
    data.InvestmentAssetTotal +
    data.SuperannuationAssetTotal +
    data.HomePropertyTotal;

  data.totals.grossLiabilities =
    data.HomeLoanTotal +
    data.InvestmentLoanTotal +
    data.SuperannuationLoanTotal;

  data.totals.netWealth =
    data.totals.grossAssets - data.totals.grossLiabilities;

  data.totals.netInvestmentWealth =
    data.InvestmentAssetTotal +
    data.InvestmentPropertyTotal -
    data.InvestmentLoanTotal;

  data.totals.netSuperannuationWealth =
    data.SuperannuationAssetTotal - data.SuperannuationLoanTotal;

  return data;
}

interface AdviceTotalsPerYearProps {
  group: FindUniqueGroupQuery["findUniqueGroup"];
  startYear: number;
  years: number;
}

export interface AdviceTotalsPerYearReturn {
  year: number;
  totalAssets: number;
  totalLiabilities: number;
  HomePropertyTotal: number;
  InvestmentPropertyTotal: number;
  InvestmentAssetTotal: number;
  SuperannuationAssetTotal: number;
  HomeLoanTotal: number;
  InvestmentLoanTotal: number;
  SuperannuationLoanTotal: number;
}

export function AdviceTotalsPerYearCalculation({
  group,
  startYear,
  years,
}: AdviceTotalsPerYearProps): AdviceTotalsPerYearReturn[] {
  var data: AdviceTotalsPerYearReturn[] = [];

  /* Using startYear amd years prop, map through every year and create a data entry */
  for (var i = 0; i <= years; i++) {
    var previousYearData = i > 0 ? data[data.length - 1] : data[0];

    var totalAssets = 0;
    var totalLiabilities = 0;

    var HomePropertyTotal = 0;
    var InvestmentPropertyTotal = 0;
    var InvestmentAssetTotal = 0;
    var SuperannuationAssetTotal = 0;

    var HomeLoanTotal = 0;
    var InvestmentLoanTotal = 0;
    var SuperannuationLoanTotal = 0;

    var year = i === 0 ? startYear : previousYearData.year + 1;

    /* For current year, use the AdviceTotalsCalculation */
    if (dayjs().year() === year) {
      var currentYearTotals = AdviceTotalsCalculation({ group });

      HomePropertyTotal = currentYearTotals.HomePropertyTotal;
      InvestmentPropertyTotal = currentYearTotals.InvestmentPropertyTotal;
      InvestmentAssetTotal = currentYearTotals.InvestmentAssetTotal;
      SuperannuationAssetTotal = currentYearTotals.SuperannuationAssetTotal;

      HomeLoanTotal = currentYearTotals.HomeLoanTotal;
      InvestmentLoanTotal = currentYearTotals.InvestmentLoanTotal;
      SuperannuationLoanTotal = currentYearTotals.SuperannuationLoanTotal;

      totalAssets =
        HomePropertyTotal +
        InvestmentPropertyTotal +
        InvestmentAssetTotal +
        SuperannuationAssetTotal;

      totalLiabilities =
        HomeLoanTotal + InvestmentLoanTotal + SuperannuationLoanTotal;
    } else if (dayjs().year() !== year) {
      /* For every other year, use previous advice totals */
      if (group?.advice) {
        // eslint-disable-next-line no-loop-func
        group.advice.forEach((advice) => {
          if (dayjs(advice.Date).year() === year) {
            HomePropertyTotal = advice.HomePropertyTotal;
            InvestmentPropertyTotal = advice.InvestmentPropertyTotal;
            InvestmentAssetTotal = advice.InvestmentAssetTotal;
            SuperannuationAssetTotal = advice.SuperannuationAssetTotal;

            HomeLoanTotal = advice.HomeLoanTotal;
            InvestmentLoanTotal = advice.InvestmentLoanTotal;
            SuperannuationLoanTotal = advice.SuperannuationLoanTotal;

            totalAssets =
              HomePropertyTotal +
              InvestmentPropertyTotal +
              InvestmentAssetTotal +
              SuperannuationAssetTotal;

            totalLiabilities =
              HomeLoanTotal + InvestmentLoanTotal + SuperannuationLoanTotal;
          }
        });
      }
    }

    data.push({
      year,
      totalAssets,
      totalLiabilities,
      HomePropertyTotal,
      InvestmentPropertyTotal,
      InvestmentAssetTotal,
      SuperannuationAssetTotal,
      HomeLoanTotal,
      InvestmentLoanTotal,
      SuperannuationLoanTotal,
    });
  }
  return data;
}

/* Adding individual asset and liabiliity values to history totals */
/* Kept this for now in case we need it later  */

// else if (dayjs().year() > year) {
//   if (asset.history.length > 0) {
//     asset.history.forEach((history: History) => {
//       if (dayjs(history.Date).year() === year) {
//         totalAssets += history.Value ? parseInt(history.Value) : 0;

//         if (
//           group?.entities.some(
//             (entity) =>
//               entity.Type === "SMSF" &&
//               entity.assets![assetType]!.some(
//                 (entry: any) => entry.assets_ID === asset.assets_ID
//               )
//           )
//         ) {
//           if (asset.Type !== "Sold") {
//             superAssetTotal += parseInt(history.Value);
//           }
//         } else {
//           switch (assetType) {
//             case "property":
//               if (asset.Type !== "Sold") {
//                 if (asset.Type !== "Home") {
//                   propertyAssetTotal += parseInt(history.Value);
//                 } else {
//                   homeAssetTotal += parseInt(history.Value);
//                 }
//               }
//               break;

//             case "superannuation":
//               superAssetTotal += parseInt(history.Value);
//               break;

//             default:
//               investmentsAssetTotal += parseInt(history.Value);
//               break;
//           }
//         }
//       }
//     });
//   }
// }
// else if (dayjs().year() > year) {
//   if (liability.history.length > 0) {
//     liability.history.forEach((history: History) => {
//       if (dayjs(history.Date).year() === year) {
//         totalLiabilities += history.Value
//           ? parseInt(history.Value)
//           : 0;

//         if (
//           group?.entities.some(
//             (entity) =>
//               entity.Type === "SMSF" &&
//               entity.liabilities![liabilityType]!.some(
//                 (entry: any) =>
//                   entry.liabilities_ID ===
//                   liability.liabilities_ID
//               )
//           )
//         ) {
//           SMSFLoanTotal += parseInt(history.Value);
//         }
//         switch (liabilityType) {
//           case "loans":
//             if (liability.LoanType === "Home") {
//               homeLoanTotal += parseInt(history.Value);
//             } else if (liability.LoanType === "Investment") {
//               investmentLoanTotal += parseInt(history.Value);
//             }
//             break;

//           case "otherliabilities":
//             if (liability.Type === "Home") {
//               homeLoanTotal += parseInt(history.Value);
//             } else {
//               investmentLoanTotal += parseInt(history.Value);
//             }
//             break;

//           default:
//             break;
//         }
//       }
//     });
//   }
// }
