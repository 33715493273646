import { gql } from "@apollo/client";
import { payeeFragment } from "./fragment";

export const createOnePayee = gql`
  mutation CreateOnePayee($data: payeeCreateInput!) {
    createOnePayee(data: $data) {
      ...payee
    }
  }
  ${payeeFragment}
`;

export const updateOnePayee = gql`
  mutation UpdateOnePayee(
    $data: payeeUpdateInput!
    $where: payeeWhereUniqueInput!
  ) {
    updateOnePayee(data: $data, where: $where) {
      ...payee
    }
  }
  ${payeeFragment}
`;

export const deleteOnePayee = gql`
  mutation DeleteOnePayee($where: payeeWhereUniqueInput!) {
    deleteOnePayee(where: $where) {
      ...payee
    }
  }
  ${payeeFragment}
`;
