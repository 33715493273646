import { gql } from "@apollo/client";
import { fileFragment } from "./fragment";

export const uploadOneFile = gql`
  mutation UploadOneFile($file: File!, $name: String!) {
    uploadOneFile(file: $file, name: $name) {
      ...file
    }
  }
  ${fileFragment}
`;

export const deleteOneFile = gql`
  mutation DeleteOneFile($filePath: String!, $name: String!) {
    deleteOneFile(filePath: $filePath, name: $name) {
      ...file
    }
  }
  ${fileFragment}
`;
