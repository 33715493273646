import React from "react";
import { PropertyValueComparisonModelGraph } from "../Graph";
import { ComparisonData } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";
import dayjs from "dayjs";

interface PropertyValuePlaceholderModelProps {
  theme?: Theme;
}

var data: ComparisonData[] = [];

Array.from(Array(11).keys()).forEach((value, index) => {
  data.push({
    year: dayjs().year() + value,
    [`Scenario 1`]:
      index === 0
        ? 30715.56295612587
        : index === 1
        ? 35757.06295612587
        : index === 2
        ? 41066.422456125874
        : index === 3
        ? 46657.851209625864
        : index === 4
        ? 52546.31235106135
        : index === 5
        ? 58747.56233027292
        : index === 6
        ? 65278.19296360831
        : index === 7
        ? 72155.67571384057
        : index === 8
        ? 79398.40831703188
        : index === 9
        ? 87025.76388073295
        : index === 10
        ? 158842.5796283758
        : 0,
    [`Scenario 2`]:
      index === 0
        ? 20248.108317038146
        : index === 1
        ? 22778.858317038146
        : index === 2
        ? 25443.738067038146
        : index === 3
        ? 28249.856443788143
        : index === 4
        ? 31204.699094505886
        : index === 5
        ? 34316.148405711676
        : index === 6
        ? 37592.50453041137
        : index === 7
        ? 41042.50752972014
        : index === 8
        ? 44675.36068799229
        : index === 9
        ? 48500.75506365285
        : index === 10
        ? 80030.78702418528
        : 0,
  });
});

export const PropertyValueComparisonPlaceholderModel = ({
  theme,
}: PropertyValuePlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Property Value
        </StyledText>

        <ModelTooltip type={Model_Type.PropertyValue} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <PropertyValueComparisonModelGraph
          comparisonData={data}
          placeholder={true}
        />
      </div>
    </React.Fragment>
  );
};
