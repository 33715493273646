import { QueryResult } from "@apollo/client";
import {
  ScenarioassetSubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { scenarioasset } from "../subscription";

interface ScenarioassetResolverParams {
  prev: FindUniqueModelQuery;
  payload: ScenarioassetSubscription["scenarioasset"];
}

function createScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const { data: scenarioasset, model_has_scenarioasset } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioasset: model_has_scenarioasset.some(
        (relation) =>
          relation.model_ID === prev.findUniqueModel?.ID &&
          relation.scenarioasset_ID === scenarioasset.ID
      )
        ? [...prev.findUniqueModel.scenarioasset, scenarioasset]
        : prev.findUniqueModel.scenarioasset,
    },
  });
}

function updateScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const { data: scenarioasset, model_has_scenarioasset } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      /** If scenarioasset already in model.scenarioasset, update, else add to array if
       * model_has_scenarioasset */
      scenarioasset: prev.findUniqueModel.scenarioasset.some(
        (scenarioassets) => scenarioassets.ID === scenarioasset.ID
      )
        ? prev.findUniqueModel.scenarioasset.map((scenarioassets) =>
            scenarioassets.ID === scenarioasset.ID
              ? { ...scenarioassets, ...scenarioasset }
              : scenarioassets
          )
        : model_has_scenarioasset.some(
            (relation) =>
              relation.model_ID === prev.findUniqueModel?.ID &&
              relation.scenarioasset_ID === scenarioasset.ID
          )
        ? [...prev.findUniqueModel.scenarioasset, scenarioasset]
        : prev.findUniqueModel.scenarioasset,
    },
  });
}

function deleteScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioasset: [...prev.findUniqueModel.scenarioasset].filter(
        (scenarioassets) => scenarioassets.ID !== scenarioasset.ID
      ),
    },
  });
}

function scenarioassetResolver({ prev, payload }: ScenarioassetResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioasset({ prev, payload });

    case MutationType.Update:
      return updateScenarioasset({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioasset({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioassetFindUniqueModel(
  query: Pick<
    QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioasset,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioassetSubscription } }
    ) =>
      scenarioassetResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioasset,
      }),
    variables: query.variables,
  });
}
