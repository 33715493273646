import { gql } from "@apollo/client";
import { servicesFragment } from "../services";

export const adviceagreementCoreFragment = gql`
  fragment adviceagreementCore on adviceagreement {
    ID
    groups_ID
    groups_organisation_ID
    Title
    Style
    Status
    PrimaryAdviser
    PrimaryAdviserCost
    AlternateAdviser
    AlternateAdviserCost
    SupportStaff
    SupportStaffCost
    DatePrepared
    PeriodStart
    PeriodEnd
    review_ID
    advice_ID

    Total
    TotalGST

    cancellation_date
    CancellationReason

    completed_date
    completed_by

    created_by
    create_time
    updated_by
    update_time
    time_elapsed
  }
`;

export const adviceagreementFragment = gql`
  fragment adviceagreement on adviceagreement {
    ...adviceagreementCore

    services {
      ...services
    }
  }
  ${adviceagreementCoreFragment}
  ${servicesFragment}
`;
