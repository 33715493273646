import { gql } from "@apollo/client";
import { adviceFragment } from "../advice";
import { adviceagreementFragment } from "../adviceagreement";
import { childFragment } from "../child";
import { clientsFragment } from "../clients";
import { entitiesFragment } from "../entities";
import { modelCoreFragment } from "../model/fragment";
import { reviewFragment } from "../review";
import { reviewagendaFragment } from "../reviewagenda";
import { teamFragment } from "../teams";
import { goalCoreFragment } from "../goal";
import { notesFragment } from "../notes";
import { tagFragment } from "../tag";
import { feesFragment } from "../fees";

export const groupsCoreFragment = gql`
  fragment groupsCore on groups {
    ID
    organisation_ID
    GroupName
    Status
    PrimaryContact
    PreferredContactDay
    ContactFrequency
    ReviewMonth
    ReviewFrequency
    ReferredBy
    LeadSource
    DateOfLastAdvice
    Billing
    start_date
    date_archived
    date_converted
    archived_by
    converted_by
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const groupsFragment = gql`
  fragment groups on groups {
    ...groupsCore

    clients {
      ...clients
    }

    child {
      ...child
    }

    entities {
      ...entities
    }

    review {
      ...review
    }

    reviewagenda {
      ...reviewagenda
    }

    adviceagreement {
      ...adviceagreement
    }

    advice {
      ...advice
    }

    model {
      ...modelCore
    }

    team {
      ...team
    }

    goal {
      ...goalCore
    }

    notes {
      ...notes
    }

    tag {
      ...tag
    }

    fees {
      ...fees
    }
  }
  ${groupsCoreFragment}
  ${clientsFragment}
  ${childFragment}
  ${entitiesFragment}
  ${reviewFragment}
  ${reviewagendaFragment}
  ${adviceagreementFragment}
  ${adviceFragment}
  ${modelCoreFragment}
  ${teamFragment}
  ${goalCoreFragment}
  ${notesFragment}
  ${tagFragment}
  ${feesFragment}
`;
