import { gql } from "@apollo/client";

export const goalCoreFragment = gql`
  fragment goalCore on goal {
    ID
    groups_ID
    groups_organisation_ID
    Title
    Description
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const goalFragment = gql`
  fragment goal on goal {
    ...goalCore
  }
  ${goalCoreFragment}
`;
