import { gql } from "@apollo/client";
import { salarypackagingFragment } from ".";

/* Salary Packaging */
export const createOneSalarypackaging = gql`
  mutation CreateOneSalarypackaging($data: salarypackagingCreateInput!) {
    createOneSalarypackaging(data: $data) {
      ...salarypackaging
    }
  }
  ${salarypackagingFragment}
`;

export const updateOneSalarypackaging = gql`
  mutation UpdateOneSalarypackaging(
    $data: salarypackagingUpdateInput!
    $where: salarypackagingWhereUniqueInput!
  ) {
    updateOneSalarypackaging(data: $data, where: $where) {
      ...salarypackaging
    }
  }
  ${salarypackagingFragment}
`;

export const deleteOneSalarypackaging = gql`
  mutation DeleteOneSalarypackaging($where: salarypackagingWhereUniqueInput!) {
    deleteOneSalarypackaging(where: $where) {
      ...salarypackaging
    }
  }
  ${salarypackagingFragment}
`;
