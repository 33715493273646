import { gql } from "@apollo/client";
import { pagetemplateFragment } from "./fragment";

export const pagetemplate = gql`
  subscription Pagetemplate($where: pagetemplateWhereUniqueInput) {
    pagetemplate(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...pagetemplate
      }
    }
  }
  ${pagetemplateFragment}
`;

export const pagetemplateMany = gql`
  subscription PagetemplateMany($where: pagetemplateWhereInput) {
    pagetemplateMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...pagetemplate
      }
    }
  }
  ${pagetemplateFragment}
`;