import { gql } from "@apollo/client";
import { childFragment } from "./fragment";

export const child = gql`
  subscription Child($where: childWhereUniqueInput) {
    child(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...child
      }
    }
  }
  ${childFragment}
`;

export const childMany = gql`
  subscription ChildMany($where: childWhereInput) {
    childMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...child
      }
    }
  }
  ${childFragment}
`;
