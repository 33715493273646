import { gql } from "@apollo/client";
import { payeeFragment } from "../payee";

export const servicesCoreFragment = gql`
  fragment servicesCore on services {
    ID
    adviceagreement_ID
    adviceagreement_groups_ID
    adviceagreement_groups_organisation_ID
    Name
    Type
    PrimaryAdviserHours
    PrimaryAdviserCost
    AlternateAdviserHours
    AlternateAdviserCost
    SupportStaffHours
    SupportStaffCost
    TotalCost
  }
`;

export const servicesFragment = gql`
  fragment services on services {
    ...servicesCore
    payee {
      ...payee
    }
  }
  ${servicesCoreFragment}
  ${payeeFragment}
`;