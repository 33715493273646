import React, { useContext } from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueAdviceQuery,
  FindUniqueGroupQuery,
  Theme,
  useUpdateOneContentMutation,
  useUpdateOneContenttemplateMutation,
} from "../../../../codegen/schema";

import { Editor } from "slate";
import { StoreContext } from "../../../../Datastore/Store";
import { WebsocketProvider } from "../../../Slate/Yjs";

import CustomSlate from "../../../Slate";

interface TextComponentProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  setActiveEditor: React.Dispatch<React.SetStateAction<Editor | undefined>>;
  theme?: Theme;
  yjsProvider: WebsocketProvider;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
  disabled?: boolean;
}

export const TextComponent: React.FC<TextComponentProps> = ({
  content,
  contenttemplate,
  setActiveEditor,
  theme,
  yjsProvider,
  group,
  advice,
  disabled,
}) => {
  const [, dispatch] = useContext(StoreContext);

  const [updateOneContentMutation] = useUpdateOneContentMutation({
    onCompleted() {
      dispatch({
        type: "snackbar/success",
        payload: "Content updated",
      });
    },
    onError(error) {
      dispatch({ type: "snackbar/error", payload: error.message });
    },
  });

  const [updateOneContenttemplateMutation] =
    useUpdateOneContenttemplateMutation({
      onCompleted() {
        dispatch({
          type: "snackbar/success",
          payload: "Content updated",
        });
      },
      onError(error) {
        dispatch({ type: "snackbar/error", payload: error.message });
      },
    });

  const mutation = content
    ? updateOneContentMutation
    : updateOneContenttemplateMutation;

  if (!mutation && (!content || !contenttemplate)) return null;

  return (
    <React.Fragment>
      <CustomSlate.Editable
        __typename={content ? content?.__typename : contenttemplate?.__typename}
        typeID={content ? content?.ID : contenttemplate?.ID}
        id={
          "editable: " +
          (content ? content?.ID : contenttemplate?.ID)?.toString()
        }
        editableKey={content ? content?.ID : contenttemplate?.ID}
        divStyle={{ height: "auto", padding: "2px 0", width: "99%" }}
        editableStyle={{ height: "100%" }}
        setActiveEditor={setActiveEditor}
        theme={theme}
        yjsProvider={yjsProvider}
        group={group}
        advice={advice}
        disabled={disabled}
      />
    </React.Fragment>
  );
};
