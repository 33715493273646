import { gql } from "@apollo/client";
import { roleFragment } from ".";

export const createOneRole = gql`
  mutation CreateOneRole($data: roleCreateInput!) {
    createOneRole(data: $data) {
      ...role
    }
  }
  ${roleFragment}
`;

export const updateOneRole = gql`
  mutation UpdateOneRole(
    $data: roleUpdateInput!
    $where: roleWhereUniqueInput!
  ) {
    updateOneRole(data: $data, where: $where) {
      ...role
    }
  }
  ${roleFragment}
`;

export const deleteOneRole = gql`
  mutation DeleteOneRole($where: roleWhereUniqueInput!) {
    deleteOneRole(where: $where) {
      ...role
    }
  }
  ${roleFragment}
`;
