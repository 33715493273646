import React from "react";
import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../Functions/GeneralFunctions";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../Functions/Owner";

interface AssetsVehicleProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns vehicle component for advice template && document.
 */
export const AssetsVehicle: React.FC<AssetsVehicleProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  type Vehicle = {
    assets_ID: number | null;
    Make: string;
    Model: string;
    Owner: string;
    Value: string;
  };

  const summaryVehicle: Vehicle[] = group
    ? content?.ContentType === "Grouped"
      ? getGroupAssets(group).vehicles
      : content?.asset_ID
      ? getGroupAssets(group).vehicles.find(
          (vehicle: Vehicle) => content?.asset_ID === vehicle.assets_ID
        )
      : {}
    : contenttemplate?.ContentType === "Grouped"
    ? [
        {
          Make: "Landrover",
          Model: "Range Rover",
          Owner: "Joint",
          Value: "$80,000",
        },
        { Make: "Audi", Model: "Q5", Owner: "Joint", Value: "$48,500" },
      ]
    : {
        Make: "Landrover",
        Model: "Range Rover",
        Owner: "Joint",
        Value: "$80,000",
      };

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryVehicle.length > 0 ? (
        <div className="grid grid-cols-4 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Make
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Model
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Owner
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Value
          </StyledText>
        </div>
      ) : (
        <h5>No vehicles recorded</h5>
      )}
      {summaryVehicle.map((vehicle, objectIndex) => {
        return (
          <div
            className="grid grid-cols-4 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(vehicle.Make)}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(vehicle.Model)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(
                group
                  ? ownerOptionsUpdateAssetDefaultValue(vehicle, group).text
                  : vehicle.Owner
              )}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(vehicle.Value, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div>Individual offset view</div>
    </React.Fragment>
  );
};
