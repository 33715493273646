import { QueryResult } from "@apollo/client";
import {
  FindUniqueEmploymentQuery,
  FindUniqueEmploymentQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueEmploymentQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, employment_has_notes } = payload;

  if (!prev.findUniqueEmployment) return prev;
  return Object.assign({}, prev, {
    findUniqueEmployment: {
      ...prev.findUniqueEmployment,
      notes: employment_has_notes.some(
        (relation) => relation.employment_ID === prev.findUniqueEmployment?.ID
      )
        ? [...prev.findUniqueEmployment.notes, notes]
        : prev.findUniqueEmployment.notes,
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueEmployment) return prev;
  return Object.assign({}, prev, {
    findUniqueEmployment: {
      ...prev.findUniqueEmployment,
      notes: prev.findUniqueEmployment.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueEmployment) return prev;
  return Object.assign({}, prev, {
    findUniqueEmployment: {
      ...prev.findUniqueEmployment,
      notes: prev.findUniqueEmployment.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueEmployment(
  query: Pick<
    QueryResult<FindUniqueEmploymentQuery, FindUniqueEmploymentQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes,
      }),
    variables: query.variables,
  });
}
