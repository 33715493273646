import { QueryResult } from "@apollo/client";
import {
  ReviewManySubscription,
  FindManyReviewQuery,
  MutationType,
  FindManyReviewQueryVariables,
} from "../../../codegen/schema";
import { reviewMany } from "../subscription";

interface ReviewResolverParams {
  prev: FindManyReviewQuery;
  payload: ReviewManySubscription["reviewMany"];
}

function createReview({ prev, payload }: ReviewResolverParams) {
  const review = payload.data;

  return Object.assign({}, prev, {
    findManyReview: [...prev.findManyReview, review],
  });
}

function updateReview({ prev, payload }: ReviewResolverParams) {
  const review = payload.data;

  return Object.assign({}, prev, {
    findManyReview: prev.findManyReview.map((entry) =>
      entry.ID === review.ID ? { ...entry, ...review } : entry
    ),
  });
}

function deleteReview({ prev, payload }: ReviewResolverParams) {
  const review = payload.data;

  return Object.assign({}, prev, {
    findManyReview: [...prev.findManyReview].filter(
      (entry) => entry.ID !== review.ID
    ),
  });
}

function reviewResolver({ prev, payload }: ReviewResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createReview({ prev, payload });

    case MutationType.Update:
      return prev.findManyReview.some((review) => review.ID === data.ID)
        ? updateReview({ prev, payload })
        : createReview({ prev, payload });

    case MutationType.Delete:
      return deleteReview({ prev, payload });

    default:
      return prev;
  }
}

export function reviewFindManyReviews(
  query: Pick<
    QueryResult<FindManyReviewQuery, FindManyReviewQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: reviewMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ReviewManySubscription } }
    ) =>
      reviewResolver({
        prev,
        payload: payload.subscriptionData.data.reviewMany,
      }),
    variables: query.variables,
  });
}
