import { gql } from "@apollo/client";
import { staffFragment } from "./fragment";

export const staff = gql`
  subscription Staff($where: staffWhereUniqueInput) {
    staff(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...staff
      }
    }
  }
  ${staffFragment}
`;

export const staffMany = gql`
  subscription StaffMany($where: staffWhereInput) {
    staffMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...staff
      }
    }
  }
  ${staffFragment}
`;
