import { gql } from "@apollo/client";
import { addressCoreFragment, addressFragment } from "./fragment";

export const findUniqueAddress = gql`
  query FindUniqueAddress($where: addressWhereUniqueInput!) {
    findUniqueAddress(where: $where) {
      ...address
    }
  }
  ${addressFragment}
`;

export const findManyAddress = gql`
  query FindManyAddress(
    $where: addressWhereInput
    $orderBy: [addressOrderByWithRelationInput!]
    $distinct: [AddressScalarFieldEnum!]
    $take: Int
    $skip: Int
    $cursor: addressWhereUniqueInput
  ) {
    findManyAddress(
      where: $where
      orderBy: $orderBy
      distinct: $distinct
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...addressCore
    }
  }
  ${addressCoreFragment}
`;
