import { QueryResult } from "@apollo/client";
import { FindManyPagetemplate } from "..";
import {
  FindManyPagetemplateQuery,
  FindManyPagetemplateQueryVariables,
} from "../../../codegen/schema";

export function findManyPagetemplateSubscription(
  query: Pick<
    QueryResult<FindManyPagetemplateQuery, FindManyPagetemplateQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindManyPagetemplate(query);
}
