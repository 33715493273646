import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { checkFieldIsEmpty } from "../../../Functions/GeneralFunctions";
import { Tooltip, TooltipProps } from "../../Tooltip";

interface Props {
  id?: string; // necessary for required error
  label?: string; // Label above select component
  defaultValue?: string | null | undefined;
  options: Array<string>;
  mutation?: (value: string) => void; //update mutation
  required?: boolean; // Required or not for submission
  disabled?: boolean;
  tooltip?: Omit<TooltipProps, "children">;
}

/**
 * @created 01-05-2024
 * @updated 01-05-2024
 * @description New select component.
 */
export const Select: React.FC<Props> = ({
  id,
  label,
  defaultValue,
  options,
  mutation,
  required,
  disabled,
  tooltip,
}) => {
  const [value, setValue] = useState<string>(defaultValue ?? "");
  useEffect(() => {
    if (value !== defaultValue) setValue(defaultValue ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <React.Fragment>
      <Listbox value={value} onChange={setValue}>
        {({ open }) => (
          <div className={clsx(label ? "mt-3" : "", "relative w-full")}>
            <label
              htmlFor="name"
              className={clsx(disabled ? "disabled" : "", "select-label")}
            >
              <div className="flex items-center gap-1">
                {label}
                {tooltip && (
                  <Tooltip
                    title={tooltip.title ?? ""}
                    background={tooltip.background ?? "dark"}
                    placement={tooltip.placement ?? "top"}
                  >
                    <div className="icon text-neutral-500 fa-regular fa-lg fa-circle-question" />
                  </Tooltip>
                )}
              </div>{" "}
            </label>
            {disabled ? (
              <p className={"select-style"}>
                {checkFieldIsEmpty(value.replaceAll("_", " "))}
              </p>
            ) : (
              <ListboxButton className={"select"}>
                <span className="block truncate">
                  {value.replaceAll("_", " ")}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <i
                    className="h-5 w-5 text-neutral-400 icon fa-regular fa-chevron-down"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
            )}

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neutral-50 ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option, index) => (
                  <ListboxOption
                    key={index}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-blue-500 text-white" : "text-neutral-600",
                        "relative cursor-pointer select-none py-2 pl-8 pr-4"
                      )
                    }
                    value={option}
                    onClick={() => {
                      if (mutation) mutation(option);
                    }}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={clsx(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.replaceAll("_", " ")}
                        </span>

                        {selected && (
                          <span
                            className={clsx(
                              active ? "text-white" : "text-blue-500",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                            )}
                          >
                            <i
                              className="h-5 w-5 icon fa-solid fa-check"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        )}
      </Listbox>
    </React.Fragment>
  );
};
