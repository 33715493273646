import React from "react";
import {
  FindUniqueGroupQuery,
  FindUniqueSalarypackagingQuery,
  FindUniqueThemeQuery,
} from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";

type SalarypackagingType = {
  clients_ID: number;
  AvailableBalance: string;
  CapLimit: string;
  PreTaxPayDeduction: string;
  Admin: string;
  HomeLoan: string;
  Super: string;
};

export const SalarypackagingContent = ({
  salarypackaging,
  theme,
  group,
}: {
  salarypackaging:
    | FindUniqueSalarypackagingQuery["findUniqueSalarypackaging"]
    | SalarypackagingType;
  theme: FindUniqueThemeQuery["findUniqueTheme"];
  group: FindUniqueGroupQuery["findUniqueGroup"];
}) => {
  return (
    <React.Fragment>
      <StyledText
        element={theme?.element.find((element) => element.Type === "H5")}
        className="flex items-center "
      >
        {group ? (
          <>
            {
              group.clients.find(
                (client) => client.ID === salarypackaging?.clients_ID
              )?.FirstName
            }{" "}
            {
              group.clients.find(
                (client) => client.ID === salarypackaging?.clients_ID
              )?.LastName
            }
          </>
        ) : (
          "John Smith"
        )}
      </StyledText>
      <div className="grid grid-cols-3">
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Available Balance
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(salarypackaging?.AvailableBalance, "dollar")}
          </StyledText>
        </div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Cap Limit
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(salarypackaging?.CapLimit, "dollar")}
          </StyledText>
        </div>

        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Pre-Tax Pay Deduction
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(salarypackaging?.PreTaxPayDeduction, "dollar")}
          </StyledText>
        </div>

        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Admin
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(salarypackaging?.Admin, "dollar")}
          </StyledText>
        </div>

        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Home Loan
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(salarypackaging?.HomeLoan, "dollar")}
          </StyledText>
        </div>

        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            style={{ fontSize: "9pt" }}
          >
            Super
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "P")}
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(salarypackaging?.Super, "percent")}
          </StyledText>
        </div>
      </div>
    </React.Fragment>
  );
};
