import React from "react";
import { LiveData } from "../../../Calculations";
import { AssetGrowthLiveModelGraph } from "../Graph";
import dayjs from "dayjs";
import { StyledText } from "../../../../../StyledText";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";

interface AssetGrowthPlaceholderModelProps {
  theme?: Theme;
}

var data: LiveData[] = [];

Array.from(Array(10).keys()).forEach((value, index) => {
  var targetIncreasePerYear = (2000000 - 120000) / 10 - 1;
  var target = 120000 + targetIncreasePerYear * index;

  data.push({
    year: dayjs().subtract(5, "year").year() + value,
    target: target,
    totalAssets:
      index === 0
        ? 600000
        : index === 1
        ? 2000000
        : index === 2
        ? 2200000
        : index === 3
        ? 3500000
        : index === 4
        ? 4000000
        : index === 5
        ? 4000000
        : undefined,
    HomePropertyTotal:
      index === 0
        ? 500000
        : index === 1
        ? 640000
        : index === 2
        ? 670000
        : index === 3
        ? 900000
        : index === 4
        ? 900000
        : index === 5
        ? 950000
        : undefined,
    InvestmentPropertyTotal:
      index === 0
        ? 0
        : index === 1
        ? 600000
        : index === 2
        ? 1200000
        : index === 3
        ? 1200000
        : index === 4
        ? 2000000
        : index === 5
        ? 2000000
        : undefined,
    InvestmentAssetTotal:
      index === 0
        ? 0
        : index === 1
        ? 0
        : index === 2
        ? 40000
        : index === 3
        ? 80000
        : index === 4
        ? 200000
        : index === 5
        ? 220000
        : undefined,
    SuperannuationAssetTotal:
      index === 0
        ? 100000
        : index === 1
        ? 120000
        : index === 2
        ? 160000
        : index === 3
        ? 200000
        : index === 4
        ? 300000
        : index === 5
        ? 350000
        : undefined,
  });
});

export const AssetGrowthPlaceholderModel = ({
  theme,
}: AssetGrowthPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Asset Growth
        </StyledText>

        <ModelTooltip type={Model_Type.AssetGrowth} />
      </div>
      <h1 className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest">
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <AssetGrowthLiveModelGraph liveData={data} />
      </div>
    </React.Fragment>
  );
};
