import { CustomEditable } from "./Editable";
import { CustomEditor } from "./Functions";
import { Toolbar } from "./Toolbar";

const CustomSlate = {
    Editable: CustomEditable,
    functions: CustomEditor,
    Toolbar,
};

export default CustomSlate
