import React from "react";
import {
  Content,
  Contenttemplate,
  Property,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../codegen/schema";

import { AssetsSuperannuationGrouped } from "./Grouped";
import { AssetsSuperannuationIndividual } from "./Individual";

interface AssetsPropertyProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  property?: Property;
  theme?: Theme;
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns property component for advice template && document.
 */
export const AssetsSuperannuation: React.FC<AssetsPropertyProps> = ({
  content,
  contenttemplate,
  group,
  property,
  theme,
}) => {
  return contenttemplate?.ContentType === "Grouped" ||
    content?.ContentType === "Grouped" ? (
    <React.Fragment>
      <AssetsSuperannuationGrouped
        content={content}
        contenttemplate={contenttemplate}
        group={group}
        theme={theme}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <AssetsSuperannuationIndividual
        content={content}
        contenttemplate={contenttemplate}
        group={group}
        theme={theme}
      />
    </React.Fragment>
  );
};
