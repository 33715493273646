import { gql } from "@apollo/client";
import { annuityFragment } from ".";

/** Annuity */
export const createOneAnnuity = gql`
  mutation CreateOneAnnuity($data: annuityCreateInput!) {
    createOneAnnuity(data: $data) {
      ...annuity
    }
  }
  ${annuityFragment}
`;

export const updateOneAnnuity = gql`
  mutation UpdateOneAnnuity(
    $data: annuityUpdateInput!
    $where: annuityWhereUniqueInput!
  ) {
    updateOneAnnuity(data: $data, where: $where) {
      ...annuity
    }
  }
  ${annuityFragment}
`;

export const deleteOneAnnuity = gql`
  mutation DeleteOneAnnuity($where: annuityWhereUniqueInput!) {
    deleteOneAnnuity(where: $where) {
      ...annuity
    }
  }
  ${annuityFragment}
`;
