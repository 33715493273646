import { gql } from "@apollo/client";
import { furtheradviceFragment } from "./fragment";

export const furtheradvice = gql`
  subscription Furtheradvice($where: furtheradviceWhereUniqueInput) {
    furtheradvice(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...furtheradvice
      }
    }
  }
  ${furtheradviceFragment}
`;

export const furtheradviceMany = gql`
  subscription FurtheradviceMany($where: furtheradviceWhereInput) {
    furtheradviceMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...furtheradvice
      }
    }
  }
  ${furtheradviceFragment}
`;
