import { gql } from "@apollo/client";
import { scenariochildFragment } from "./fragment";

export const createOneScenariochild = gql`
  mutation CreateOneScenariochild($data: scenariochildCreateInput!) {
    createOneScenariochild(data: $data) {
      ...scenariochild
    }
  }
  ${scenariochildFragment}
`;

export const updateOneScenariochild = gql`
  mutation UpdateOneScenariochild(
    $data: scenariochildUpdateInput!
    $where: scenariochildWhereUniqueInput!
  ) {
    updateOneScenariochild(data: $data, where: $where) {
      ...scenariochild
    }
  }
  ${scenariochildFragment}
`;

export const deleteOneScenariochild = gql`
  mutation DeleteOneScenariochild($where: scenariochildWhereUniqueInput!) {
    deleteOneScenariochild(where: $where) {
      ...scenariochild
    }
  }
  ${scenariochildFragment}
`;
