import { gql } from "@apollo/client";
import {
  salarypackagingCoreFragment,
  salarypackagingFragment,
} from "./fragment";

export const findUniqueSalarypackaging = gql`
  query FindUniqueSalarypackaging($where: salarypackagingWhereUniqueInput!) {
    findUniqueSalarypackaging(where: $where) {
      ...salarypackaging
    }
  }
  ${salarypackagingFragment}
`;

export const findManySalarypackaging = gql`
  query FindManySalarypackaging(
    $where: salarypackagingWhereInput
    $distinct: [SalarypackagingScalarFieldEnum!]
    $orderBy: [salarypackagingOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: salarypackagingWhereUniqueInput
  ) {
    findManySalarypackaging(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...salarypackagingCore
    }
  }
  ${salarypackagingCoreFragment}
`;
