import React, { useState } from "react";

import { Button, ButtonProps } from "../Buttons";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

interface ModalProps {
  buttonProps?: ButtonProps;
  children: ({
    setOpen,
  }: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => JSX.Element;
  modalOptions?: { defaultOpen: boolean; disableClickAway: boolean };
  modalOpen?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @created 16-02-2023
 * @updated 16-02-2023
 * @returns Returns default modal.
 */
export const Modal = ({
  buttonProps,
  children,
  modalOptions = { defaultOpen: false, disableClickAway: false },
  modalOpen,
  setModalOpen,
}: ModalProps) => {
  const [open, setOpen] = useState(modalOptions.defaultOpen ?? false);

  return (
    <React.Fragment>
      {buttonProps && (
        <Button
          {...buttonProps}
          onClick={() => {
            if (buttonProps.onClick) buttonProps.onClick();
            setOpen(true);
          }}
        />
      )}

      <Transition appear show={modalOpen ? modalOpen : open}>
        <Dialog
          as="div"
          className="relative z-50 focus:outline-none"
          onClose={() =>
            modalOptions.disableClickAway === false
              ? setModalOpen
                ? setModalOpen(false)
                : setOpen(false)
              : undefined
          }
        >
          <div className="fixed inset-0 bg-neutral-900/30" aria-hidden="true" />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-2xl rounded-xl bg-white/80 p-6 backdrop-blur-2xl">
                  {children({ setOpen })}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};
