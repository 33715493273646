import { QueryResult } from "@apollo/client";
import {
  FindUniqueReviewQuery,
  FindUniqueReviewQueryVariables,
  MutationType,
  FurtheradviceSubscription,
} from "../../../codegen/schema";
import { furtheradvice } from "../subscription";

interface FurtheradviceResolverParams {
  prev: FindUniqueReviewQuery;
  payload: FurtheradviceSubscription["furtheradvice"];
}

// function createFurtheradvice(prev, payload) {
//   const { data: furtheradvice } = payload.subscriptionData.data.furtheradvice;

//   return Object.assign({}, prev, {
//     findUniqueReview: {
//       ...prev.findUniqueReview,
//       furtheradvice:
//         furtheradvice.review_ID ===
//         prev.findUniqueReview.ID
//           ? [
//               ...prev.findUniqueReview.furtheradvice,
//               furtheradvice,
//             ]
//           : prev.findUniqueReview.furtheradvice,
//     },
//   });
// }

function updateFurtheradvice({ prev, payload }: FurtheradviceResolverParams) {
  const furtheradvice = payload.data;

  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview: {
      ...prev.findUniqueReview,
      furtheradvice: prev.findUniqueReview.furtheradvice.map((advice) =>
        advice.ID === furtheradvice.ID
          ? { ...advice, ...furtheradvice }
          : advice
      ),
    },
  });
}

// function deleteFurtheradvice(prev, payload) {
//   const { data: furtheradvice } = payload.subscriptionData.data.furtheradvice;

//   return Object.assign({}, prev, {
//     findUniqueReview: {
//       ...prev.findUniqueReview,
//       furtheradvice: [...prev.findUniqueReview.furtheradvice].filter(
//         (advice) =>
//           advice.ID !== furtheradvice.ID
//       ),
//     },
//   });
// }

function furtheradviceResolver({ prev, payload }: FurtheradviceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createNote({ prev, payload });

    case MutationType.Update:
      return updateFurtheradvice({ prev, payload });

    // case MutationType.Delete:
    //   return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function furtheradviceFindUniqueReview(
  query: Pick<
    QueryResult<FindUniqueReviewQuery, FindUniqueReviewQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: furtheradvice,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: FurtheradviceSubscription } }
    ) =>
      furtheradviceResolver({
        prev,
        payload: payload.subscriptionData.data.furtheradvice
      }),
    variables: query.variables,
  });
}
