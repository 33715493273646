import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Editor } from "slate";

import {
  Contenttemplate,
  Contenttemplate_AdviceAgreementType,
  Contenttemplate_AssetType,
  Contenttemplate_ContentType,
  Contenttemplate_ImageType,
  Contenttemplate_InsuranceType,
  Contenttemplate_ModelToRender,
  Contenttemplate_ModelType,
  Contenttemplate_Type,
  Documenttemplate_Orientation,
  FindUniqueDocumenttemplateQuery,
  FindUniqueOrganisationQuery,
  Theme,
  useCreateOneContenttemplateMutation,
  useDeleteOneContenttemplateMutation,
  useUpdateOneContenttemplateMutation,
} from "../../../../../../../../codegen/schema";
import { StoreContext } from "../../../../../../../../Datastore/Store";

import { SlateSelected } from "../../../../../../../../Components/Slate/Types";
import {
  contentDrag,
  contentHeightResize,
  contentWidthResizeLeft,
  contentWidthResizeRight,
  inlineStyles,
} from "../../../../../../../../Components/Document/Functions";

import { ButtonMenu } from "../../../../../../../../Components/Menus/ButtonMenu";
import { Button } from "../../../../../../../../Components/Buttons";
import { Image } from "../../../../../../Utlis/Image";

// import { TextComponent } from "../../../../../../../Components/Advice/Content/Text";
import { InsuranceComponent } from "../../../../../../../../Components/Advice/Content/Insurance";
import { EntitiesComponent } from "../../../../../../../../Components/Advice/Content/Entities";
import { EstatePlanningComponent } from "../../../../../../../../Components/Advice/Content/EstatePlanning";
import { ChildComponent } from "../../../../../../../../Components/Advice/Content/Child";
import { ClientComponent } from "../../../../../../../../Components/Advice/Content/Client";
import { LiabilitiesComponent } from "../../../../../../../../Components/Advice/Content/Liabilities";
import { AssetsComponent } from "../../../../../../../../Components/Advice/Content/Assets";
import { GoalComponent } from "../../../../../../../../Components/Advice/Content/Goal";
import { ModelComponent } from "./Model";
import { SalarypackagingComponent } from "../../../../../../../../Components/Advice/Content/Salarypackaging";
import { ImageComponent } from "../../../../../../../../Components/Advice/Content/Image";
import { TableofContentsTemplateComponent } from "../../../../../../../../Components/Advice/Content/TableOfContents/Template";
import { TextComponent } from "../../../../../../../../Components/Advice/Content/Text";
import { WebsocketProvider } from "../../../../../../../../Components/Slate/Yjs";
import { IncomeComponent } from "../../../../../../../../Components/Advice/Content/Income";
import { AdviceAgreementServicesSummary } from "./Adviceagreement/Summary";
import { AdviceAgreementService } from "./Adviceagreement/Service";
import { AdviceAgreementServiceCost } from "./Adviceagreement/Servicecost";
import { AdviceAgreementPaymentInstructions } from "./Adviceagreement/Paymentinstructions";

interface PageContentComponentsProps {
  id: number;
  index: number;
  documenttemplate: FindUniqueDocumenttemplateQuery["findUniqueDocumenttemplate"];
  style?: React.CSSProperties | undefined;
  theme?: Theme;
  isDragging?: boolean;
  contenttemplate: Contenttemplate | undefined;
  activeEditor: Editor | undefined;
  setActiveEditor: React.Dispatch<React.SetStateAction<Editor | undefined>>;
  selected?: SlateSelected;
  setSelected: React.Dispatch<React.SetStateAction<SlateSelected | undefined>>;
  active?: boolean | null | undefined;
  organisation: FindUniqueOrganisationQuery["findUniqueOrganisation"];
  yjsProvider: WebsocketProvider;
}

export const PageContentComponents = forwardRef<
  HTMLDivElement,
  PageContentComponentsProps
>((props, ref) => {
  const {
    documenttemplate,
    activeEditor,
    setActiveEditor,
    selected,
    setSelected,
    contenttemplate,
    isDragging,
    style,
    // active,
    theme,
    organisation,
    yjsProvider,
  } = props;

  const [, dispatch] = useContext(StoreContext);
  const [height, setHeight] = useState(contenttemplate?.Height);
  const [XPosition, setXPosition] = useState(contenttemplate?.XPosition);
  const [width, setWidth] = useState(contenttemplate?.Width);

  const [createOneContenttemplateMutation] =
    useCreateOneContenttemplateMutation({
      onCompleted() {
        dispatch({
          type: "snackbar/success",
          payload: "Content created",
        });
      },
      onError(error) {
        dispatch({ type: "snackbar/error", payload: error.message });
      },
    });

  const [
    updateOneContenttemplateMutation,
    { loading: updateOneContenttemplateMutationLoading },
  ] = useUpdateOneContenttemplateMutation({
    onCompleted() {
      // Dispatch success snackbar
      dispatch({
        type: "snackbar/success",
        payload: "Content updated",
      });
    },
    onError(error) {
      dispatch({ type: "snackbar/error", payload: error.message });
    },
  });

  const [deleteOneContenttemplateMutation] =
    useDeleteOneContenttemplateMutation({
      onCompleted(data) {
        dispatch({
          type: "cricleSnackbar/success",
          payload: `${data.deleteOneContenttemplate?.Type?.replaceAll(
            "_",
            " "
          )} deleted`,
        });
      },
      onError(error) {
        dispatch({
          type: "snackbar/error",
          payload: error.message,
        });
      },
    });

  // ONLY USED FOR HEIGHT AND WIDTH BC CANT RESIZE THEM WITH element.style
  // KEEP HERE
  useEffect(() => {
    if (contenttemplate) {
      // setSize(contenttemplate?.Size);
      setXPosition(contenttemplate.XPosition);
      setHeight(contenttemplate.Height);
      setWidth(contenttemplate.Width);
    }
  }, [contenttemplate]);

  if (!contenttemplate) return null;

  return (
    <React.Fragment>
      {contenttemplate.ImageType !== Contenttemplate_ImageType.Background ? (
        <div
          className={
            "relative flex flex-col items-start justify-start row-start-1 " +
            (selected?.contenttemplate?.ID === contenttemplate.ID
              ? "border border-neutral-300 "
              : "") +
            (XPosition === 2 && width !== 2 ? "col-start-2 " : "col-start-1 ") +
            (width === 2 ? " col-span-2" : "")
          }
          key={contenttemplate.ID}
          style={inlineStyles({
            active: selected?.contenttemplate?.ID === contenttemplate.ID,
            isDragging,
            height,
            style,
            yPosition: contenttemplate.YPosition,
          })}
          onClick={(event) => {
            // setActiveEditor(editor);
            setSelected({ ...selected, contenttemplate: contenttemplate });
          }}
          // ID needed for dragging
          id={`draggable content ${contenttemplate.ID}`}
        >
          <label
            className={
              selected?.contenttemplate?.ID === contenttemplate.ID
                ? "h-3 w-3 rounded-xl border cursor-move border-neutral-300 bg-white absolute z-10"
                : ""
            }
            style={{
              left: "calc(50% - 6px)",
              top: "-6px",
            }}
            // ID passed to contentDrag to get element that moves
            id={contenttemplate.ID.toString()}
            onMouseDown={(event) =>
              contentDrag({
                callback: ({ xPosition, yPosition }) =>
                  updateOneContenttemplateMutation({
                    variables: {
                      where: {
                        ID: contenttemplate.ID,
                      },
                      data: {
                        YPosition: { set: yPosition },
                        XPosition: { set: xPosition },
                      },
                    },
                  }),
                content: contenttemplate,
                event,
                setXPosition,
              })
            }
          />

          {/* IF not model, allow resizing */}
          {contenttemplate.Type !== Contenttemplate_Type.Model && (
            <React.Fragment>
              <label
                className={
                  selected?.contenttemplate?.ID === contenttemplate.ID
                    ? "h-3 w-3 rounded-xl border cursor-row-resize border-neutral-300 bg-white absolute z-10"
                    : ""
                }
                style={{
                  left: "calc(50% - 6px)",
                  bottom: "-6px",
                }}
                // ID passed to height resize
                id={contenttemplate.ID.toString()}
                onMouseDown={(event) =>
                  contentHeightResize({
                    callback: ({ height }) =>
                      updateOneContenttemplateMutation({
                        variables: {
                          where: {
                            ID: contenttemplate.ID,
                          },
                          data: {
                            Height: { set: height },
                          },
                        },
                      }),
                    content: contenttemplate,
                    event,
                    height,
                    setHeight,
                  })
                }
              />

              {(contenttemplate.XPosition === 1 ||
                contenttemplate.Width === 2) && (
                <label
                  className={
                    selected?.contenttemplate?.ID === contenttemplate.ID
                      ? "h-3 w-3 rounded-xl border cursor-col-resize border-neutral-300 bg-white absolute z-10"
                      : ""
                  }
                  style={{
                    top: "calc(50% - 6px)",
                    right: "-6px",
                  }}
                  // ID passed width resize
                  id={contenttemplate.ID.toString()}
                  onMouseDown={(event) =>
                    contentWidthResizeRight({
                      callback: ({ width, xPosition }) =>
                        updateOneContenttemplateMutation({
                          variables: {
                            where: {
                              ID: contenttemplate.ID,
                            },
                            data: {
                              Width: { set: width },
                              XPosition: { set: xPosition },
                            },
                          },
                        }),
                      content: contenttemplate,
                      event,
                      setWidth,
                      setXPosition,
                    })
                  }
                />
              )}

              {(contenttemplate.XPosition === 2 ||
                contenttemplate.Width === 2) && (
                <label
                  className={
                    selected?.contenttemplate?.ID === contenttemplate.ID
                      ? "h-3 w-3 rounded-xl border cursor-col-resize border-neutral-300 bg-white absolute z-10"
                      : ""
                  }
                  style={{
                    top: "calc(50% - 6px)",
                    left: "-6px",
                  }}
                  // ID passed to width resize
                  id={contenttemplate.ID.toString()}
                  onMouseDown={(event) =>
                    contentWidthResizeLeft({
                      callback: ({ width, xPosition }) =>
                        updateOneContenttemplateMutation({
                          variables: {
                            where: {
                              ID: contenttemplate.ID,
                            },
                            data: {
                              Width: { set: width },
                              XPosition: { set: xPosition },
                            },
                          },
                        }),
                      content: contenttemplate,
                      event,
                      setWidth,
                      setXPosition,
                    })
                  }
                />
              )}
            </React.Fragment>
          )}

          {/* DUPLICATE, CHANGE, LOCK & DELETE  */}
          {selected?.contenttemplate?.ID === contenttemplate.ID && (
            <div
              className="flex gap-1 items-center absolute -right-3 z-10"
              style={{ top: "-20px" }}
            >
              {(contenttemplate.AssetType ===
                Contenttemplate_AssetType.Property ||
                contenttemplate.Type === Contenttemplate_Type.Insurance ||
                contenttemplate.Type === Contenttemplate_Type.Goal ||
                contenttemplate.Type === Contenttemplate_Type.Client) && (
                <ButtonMenu
                  buttonProps={{
                    tooltip: `Change layout?`,
                    type: "circle",
                    functionality: "none",
                    shadow: true,
                    text: (
                      <div
                        className={"icon fa-regular fa-table-layout fa-xs"}
                      />
                    ),
                  }}
                  options={["Grouped", "Individual"].map((value) => ({
                    value,
                    text: value.replaceAll("_", " "),
                    disabled: updateOneContenttemplateMutationLoading,
                    onClick: () =>
                      updateOneContenttemplateMutation({
                        variables: {
                          where: {
                            ID: contenttemplate.ID,
                          },
                          data: {
                            ContentType: {
                              set: value as Contenttemplate_ContentType,
                            },
                            InsuranceType: {
                              set:
                                contenttemplate.InsuranceType === null &&
                                contenttemplate.Type ===
                                  Contenttemplate_Type.Insurance
                                  ? Contenttemplate_InsuranceType.Trauma
                                  : contenttemplate.InsuranceType,
                            },
                            Width: {
                              set:
                                (value === "Individual" &&
                                  contenttemplate.AssetType ===
                                    Contenttemplate_AssetType.Property) ||
                                (value === "Grouped" &&
                                  contenttemplate.Type ===
                                    Contenttemplate_Type.Goal) ||
                                (value === "Grouped" &&
                                  contenttemplate.Type ===
                                    Contenttemplate_Type.Client)
                                  ? 2
                                  : 1,
                            },
                          },
                        },
                      }),
                  }))}
                />
              )}

              {contenttemplate.Type === Contenttemplate_Type.Insurance &&
                contenttemplate.ContentType ===
                  Contenttemplate_ContentType.Individual && (
                  <ButtonMenu
                    buttonProps={{
                      tooltip: `Change insurance type?`,
                      type: "circle",
                      functionality: "none",
                      shadow: true,
                      text: (
                        <div className={"icon fa-regular fa-car-burst fa-xs"} />
                      ),
                    }}
                    options={Object.values(Contenttemplate_InsuranceType).map(
                      (value) => ({
                        value,
                        text: value.replaceAll("_", " "),
                        disabled: updateOneContenttemplateMutationLoading,
                        onClick: () =>
                          updateOneContenttemplateMutation({
                            variables: {
                              where: {
                                ID: contenttemplate.ID,
                              },
                              data: {
                                InsuranceType: { set: value },
                              },
                            },
                          }),
                      })
                    )}
                  />
                )}

              {contenttemplate.Type === Contenttemplate_Type.Model &&
                contenttemplate.ModelType !==
                  Contenttemplate_ModelType.AssetAllocation &&
                contenttemplate.ModelType !==
                  Contenttemplate_ModelType.AssetAndDebtComposition &&
                contenttemplate.ModelType !==
                  Contenttemplate_ModelType.AssetGrowth &&
                contenttemplate.ModelType !==
                  Contenttemplate_ModelType.GrossAndNetIncome &&
                contenttemplate.ModelType !==
                  Contenttemplate_ModelType.InvestmentAndPropertyDebt &&
                contenttemplate.ModelType !==
                  Contenttemplate_ModelType.LongTermProgress && (
                  <ButtonMenu
                    buttonProps={{
                      tooltip: `Change model to render?`,
                      type: "circle",
                      functionality: "none",
                      shadow: true,
                      text: (
                        <div className={"icon fa-regular fa-repeat fa-xs"} />
                      ),
                    }}
                    options={["Comparison", "Scenario"].map((value) => ({
                      value,
                      text: value.replaceAll("_", " "),
                      disabled: updateOneContenttemplateMutationLoading,
                      onClick: () =>
                        updateOneContenttemplateMutation({
                          variables: {
                            where: {
                              ID: contenttemplate.ID,
                            },
                            data: {
                              ModelToRender: {
                                set: value as Contenttemplate_ModelToRender,
                              },
                            },
                          },
                        }),
                    }))}
                  />
                )}

              <Button
                tooltip={
                  contenttemplate.Locked === 0
                    ? `Lock content? Locked content cannot be edited, deleted, styled or re-sized in the document editor.`
                    : `Unlock content? Enables editing of this content in the document editor.`
                }
                type="circle"
                shadow={true}
                text={
                  <i
                    className={
                      "icon fa-regular fa-xs " +
                      (contenttemplate.Locked === 0 ? "fa-unlock" : "fa-lock")
                    }
                  />
                }
                onClick={async (event: React.MouseEvent) => {
                  event.stopPropagation();
                  updateOneContenttemplateMutation({
                    variables: {
                      where: {
                        ID: contenttemplate.ID,
                      },
                      data: {
                        Locked: { set: contenttemplate.Locked === 0 ? 1 : 0 },
                      },
                    },
                  });
                }}
              />
              <Button
                tooltip="Duplicate"
                type="circle"
                shadow={true}
                text={<i className="icon fa-regular fa-copy fa-xs" />}
                onClick={async (event: React.MouseEvent) => {
                  var {
                    ID,
                    pagetemplate_ID,
                    pagetemplate_sectiontemplate_ID,
                    pagetemplate_sectiontemplate_documenttemplate_ID,
                    pagetemplate_sectiontemplate_documenttemplate_organisation_ID,
                    update_time,
                    updated_by,
                    create_time,
                    created_by,
                    __typename,
                    ...contenttemplateData
                  } = { ...contenttemplate };

                  event.stopPropagation();
                  createOneContenttemplateMutation({
                    variables: {
                      data: {
                        ...contenttemplateData,
                        XPosition:
                          contenttemplateData.XPosition! === 1 &&
                          contenttemplateData.Size !== 2
                            ? 2
                            : 1,
                        pagetemplate: {
                          connect: {
                            ID: pagetemplate_ID,
                          },
                        },
                      },
                    },
                  }).then((result) => {
                    if (activeEditor) activeEditor.deselect();

                    // If text component, sync to server
                    if (
                      result.data &&
                      result.data.createOneContenttemplate.Type ===
                        Contenttemplate_Type.Text
                    ) {
                      yjsProvider.duplicateSubdoc({
                        newGuid: `yjs?__typename=${result.data.createOneContenttemplate.__typename}&ID=${result.data.createOneContenttemplate.ID}`,
                        duplicateGuid: `yjs?__typename=${contenttemplate.__typename}&ID=${contenttemplate.ID}`,
                      });
                    }
                    setSelected({
                      ...selected,
                      contenttemplate: result.data?.createOneContenttemplate,
                    });
                  });
                }}
              />

              <Button
                tooltip="Delete"
                type="circle"
                shadow={true}
                text={<i className="icon fa-regular fa-trash fa-xs" />}
                onClick={async (event: React.MouseEvent) => {
                  event.stopPropagation();
                  yjsProvider.removeSubdoc(
                    `yjs?__typename=${contenttemplate.__typename}&ID=${contenttemplate.ID}`
                  );

                  if (activeEditor) activeEditor.deselect();

                  deleteOneContenttemplateMutation({
                    variables: {
                      where: {
                        ID: contenttemplate.ID,
                      },
                    },
                  });
                  setSelected({ ...selected, contenttemplate: undefined });
                }}
              />
            </div>
          )}

          {/* CONTENT  */}
          <>
            {contenttemplate.Type === Contenttemplate_Type.AdviceAgreement ? (
              <>
                {contenttemplate.AdviceAgreementType ===
                Contenttemplate_AdviceAgreementType.IndividualService ? (
                  <AdviceAgreementService theme={theme} />
                ) : contenttemplate.AdviceAgreementType ===
                  Contenttemplate_AdviceAgreementType.ServicesSummary ? (
                  <AdviceAgreementServicesSummary theme={theme} />
                ) : contenttemplate.AdviceAgreementType ===
                  Contenttemplate_AdviceAgreementType.ServiceCost ? (
                  <AdviceAgreementServiceCost theme={theme} />
                ) : contenttemplate.AdviceAgreementType ===
                  Contenttemplate_AdviceAgreementType.PaymentInstructions ? (
                  <AdviceAgreementPaymentInstructions theme={theme} />
                ) : (
                  ""
                )}
              </>
            ) : contenttemplate.Type === Contenttemplate_Type.Asset ? (
              <AssetsComponent
                contenttemplate={contenttemplate}
                theme={theme}
              />
            ) : contenttemplate.Type === Contenttemplate_Type.Child ? (
              <ChildComponent theme={theme} />
            ) : contenttemplate.Type === Contenttemplate_Type.Client ? (
              <ClientComponent
                theme={theme}
                contenttemplate={contenttemplate}
              />
            ) : contenttemplate.Type === Contenttemplate_Type.Entity ? (
              <EntitiesComponent theme={theme} />
            ) : contenttemplate.Type === Contenttemplate_Type.EstatePlanning ? (
              <EstatePlanningComponent theme={theme} />
            ) : contenttemplate.Type === Contenttemplate_Type.Goal ? (
              <GoalComponent theme={theme} contenttemplate={contenttemplate} />
            ) : contenttemplate.Type === Contenttemplate_Type.Image ? (
              <ImageComponent contenttemplate={contenttemplate} theme={theme} />
            ) : contenttemplate.Type === Contenttemplate_Type.Income ? (
              <IncomeComponent theme={theme} />
            ) : contenttemplate.Type === Contenttemplate_Type.Insurance ? (
              <InsuranceComponent
                contenttemplate={contenttemplate}
                theme={theme}
              />
            ) : contenttemplate.Type === Contenttemplate_Type.Liability ? (
              <LiabilitiesComponent
                contenttemplate={contenttemplate}
                theme={theme}
              />
            ) : contenttemplate.Type === Contenttemplate_Type.Model ? (
              <ModelComponent contenttemplate={contenttemplate} theme={theme} />
            ) : contenttemplate.Type ===
              Contenttemplate_Type.SalaryPackaging ? (
              <SalarypackagingComponent theme={theme} />
            ) : contenttemplate.Type ===
              Contenttemplate_Type.TableOfContents ? (
              <TableofContentsTemplateComponent
                documenttemplate={documenttemplate}
                theme={theme}
              />
            ) : contenttemplate.Type === Contenttemplate_Type.Text ? (
              <TextComponent
                contenttemplate={contenttemplate}
                setActiveEditor={setActiveEditor}
                theme={theme}
                yjsProvider={yjsProvider}
              />
            ) : null}
          </>
        </div>
      ) : (
        <div
          className={
            "document-content " +
            (contenttemplate?.ID === contenttemplate.ID ? "selected " : "")
          }
          style={{ position: "absolute", right: 0, top: 0, zIndex: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            // setActiveEditor(editor);
            setSelected({ ...selected, contenttemplate: contenttemplate });
          }}
        >
          {contenttemplate?.ID === contenttemplate.ID && (
            <div
              className="flex gap-2 items-center"
              style={{
                position: "absolute",
                top: "-10px",
                right: "20px",
                zIndex: 3,
              }}
            >
              <Button
                tooltip="Delete"
                type="circle"
                shadow={true}
                text={<i className="icon fa-regular fa-trash fa-xs" />}
                onClick={async (event: React.MouseEvent) => {
                  event.stopPropagation();
                  yjsProvider.removeSubdoc(
                    `yjs?__typename=${contenttemplate.__typename}&ID=${contenttemplate.ID}`
                  );

                  if (activeEditor) activeEditor.deselect();

                  deleteOneContenttemplateMutation({
                    variables: {
                      where: {
                        ID: contenttemplate.ID,
                      },
                    },
                    optimisticResponse: {
                      deleteOneContenttemplate: {
                        ID: contenttemplate.ID,
                        pagetemplate_ID: contenttemplate.pagetemplate_ID,
                        pagetemplate_sectiontemplate_ID:
                          contenttemplate.pagetemplate_sectiontemplate_ID,
                        pagetemplate_sectiontemplate_documenttemplate_ID:
                          contenttemplate.pagetemplate_sectiontemplate_documenttemplate_ID,
                        pagetemplate_sectiontemplate_documenttemplate_organisation_ID:
                          contenttemplate.pagetemplate_sectiontemplate_documenttemplate_organisation_ID,
                      },
                    },
                  });
                  setSelected({ ...selected, contenttemplate: undefined });
                }}
              />
            </div>
          )}
          <Image
            fileName="background"
            filePath={`application/organisation/ID=${organisation?.ID}/theme/ID=${theme?.ID}/images/`}
            id={"background"}
            label="Background"
            height={
              documenttemplate?.Orientation ===
              Documenttemplate_Orientation.Portrait
                ? 1122.519685 /* 29.7cm to pixels */
                : 793.7007874 /* 21cm to pixels */
            }
          />
        </div>
      )}
    </React.Fragment>
  );
});
