import { QueryResult } from "@apollo/client";
import {
  FindManyAddressQuery,
  FindManyAddressQueryVariables,
  MutationType,
  AddressManySubscription,
} from "../../../codegen/schema";
import { addressMany } from "../subscription";

interface AddressResolverParams {
  prev: FindManyAddressQuery;
  payload: AddressManySubscription["addressMany"];
}

function createAddress({ prev, payload }: AddressResolverParams) {
  const address = payload.data;

  return Object.assign({}, prev, {
    findManyAddress: [...prev.findManyAddress, address],
  });
}

function updateAddress({ prev, payload }: AddressResolverParams) {
  const address = payload.data;

  return Object.assign({}, prev, {
    findManyAddress: prev.findManyAddress.map((entry) =>
      entry.ID === address.ID ? { ...entry, ...address } : entry
    ),
  });
}

function deleteAddress({ prev, payload }: AddressResolverParams) {
  const address = payload.data;

  return Object.assign({}, prev, {
    findManyAddress: [...prev.findManyAddress].filter(
      (entry) => entry.ID !== address.ID
    ),
  });
}

function addressResolver({ prev, payload }: AddressResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAddress({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyAddress.some((address) => address.ID === data.ID)
        ? updateAddress({ prev, payload })
        : createAddress({ prev, payload });

    case MutationType.Delete:
      return deleteAddress({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyAddress(
  query: Pick<
    QueryResult<FindManyAddressQuery, FindManyAddressQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: addressMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AddressManySubscription } }
    ) =>
      addressResolver({
        prev,
        payload: payload.subscriptionData.data.addressMany,
      }),
    variables: query.variables,
  });
}
