import { QueryResult } from "@apollo/client";
import {
  GroupsSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { groups } from "../subscription";

interface GroupsResolverParams {
  prev: FindUniqueGroupQuery;
  payload: GroupsSubscription["groups"];
}

function updateGroups({ prev, payload }: GroupsResolverParams) {
  const groups = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup:
      groups.ID === prev.findUniqueGroup.ID
        ? {
            ...prev.findUniqueGroup,
            ...groups,
          }
        : prev.findUniqueGroup,
  });
}

function groupsResolver({ prev, payload }: GroupsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createGroups(prev, payload);

    case MutationType.Update:
      return updateGroups({ prev, payload });

    // case MutationType.Delete:
    //   return deleteGroups({ prev, payload });

    default:
      return prev;
  }
}

export function groupsFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: groups,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: GroupsSubscription } }
    ) =>
      groupsResolver({
        prev,
        payload: payload.subscriptionData.data.groups
      }),
    variables: query.variables,
  });
}
