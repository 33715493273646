import {
  ScenariochildSubscription,
  FindUniqueAdviceQuery,
  MutationType,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { scenariochild } from "../subscription";
import { QueryResult } from "@apollo/client";

interface ScenariochildResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: ScenariochildSubscription["scenariochild"];
}

function createScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenariochild.scenario_ID
          ? {
              ...scenario,
              scenariochild: [...scenario.scenariochild, scenariochild],
            }
          : scenario
      ),
    },
  });
}

function updateScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenariochild.scenario_ID
          ? {
              ...scenario,
              scenariochild: scenario.scenariochild.map((scenariochilds) =>
                scenariochilds.ID === scenariochild.ID
                  ? { ...scenariochilds, ...scenariochild }
                  : scenariochilds
              ),
            }
          : scenario
      ),
    },
  });
}

function deleteScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenariochild.scenario_ID
          ? {
              ...scenario,
              scenariochild: scenario.scenariochild.filter(
                (scenariochilds) => scenariochilds.ID !== scenariochild.ID
              ),
            }
          : scenario
      ),
    },
  });
}

function scenariochildResolver({ prev, payload }: ScenariochildResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenariochild({ prev, payload });

    case MutationType.Update:
      return updateScenariochild({ prev, payload });

    case MutationType.Delete:
      return deleteScenariochild({ prev, payload });

    default:
      return prev;
  }
}

export function scenariochildFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenariochild,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenariochildSubscription } }
    ) =>
      scenariochildResolver({
        prev,
        payload: payload.subscriptionData.data.scenariochild,
      }),
    variables: query.variables,
  });
}
