import dayjs from "dayjs";
import React from "react";
import { TrustPlanningScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { CPI } from "../../../../../../../Constants/Calculations";
import { StyledText } from "../../../../../StyledText";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";

interface TrustPlanningPlaceholderModelProps {
  theme?: Theme;
}

const initialValue = 100000;
var data: ScenarioData[] = Array.from(Array(29).keys()).map((year) => ({
  year: dayjs().year() + year,
  capital: initialValue * Math.pow(1 + 0.07, year),
  contributions: 5000 * Math.pow(1 + CPI, year),
  fees: -10000 * Math.pow(1 + CPI, year),
  growth:
    year === 0
      ? 0
      : initialValue * Math.pow(1 + 0.07, year) -
        initialValue * Math.pow(1 + 0.07, year - 1),
  withdrawals: year === 20 ? -50000 : 0,
  target: year === 29 ? 1200000 : undefined,
}));

export const TrustPlanningScenarioPlaceholderModel = ({
  theme,
}: TrustPlanningPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Trust Planning
        </StyledText>

        <ModelTooltip type={Model_Type.TrustPlanning} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
      <TrustPlanningScenarioModelGraph
        scenarioData={data}
        liveData={undefined}
      />
      </div>
    </React.Fragment>
  );
};
