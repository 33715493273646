import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../../Datastore/Store";
import {
  useCreateOneClientsMutation,
  useCreateOneGroupsMutation,
  useFindCurrentUserQuery,
  useFindManyGroupsQuery,
} from "../../../../codegen/schema";
import { ComponentLoader } from "../../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../../Components/Refetch";
import { Button } from "../../../../Components/Buttons";
import { Input } from "../../../../Components/Inputs/Input";
import { Select } from "../../../../Components/Inputs/Select";
import dayjs from "dayjs";

export const NewGroupNew = ({
  setMenuOpen,
}: {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const [, dispatch] = useContext(StoreContext);
  const {
    data: { findCurrentUser: user } = {},
    error: userError,
    loading: userLoading,
    refetch: userRefetch,
  } = useFindCurrentUserQuery();

  const {
    data: { findManyGroups: allGroups } = {},
    error: allGroupsError,
    loading: allGroupsLoading,
    refetch: allGroupsRefetch,
  } = useFindManyGroupsQuery();

  const [createOneGroupMutation] = useCreateOneGroupsMutation();
  const [createOneClientMutation] = useCreateOneClientsMutation();

  const [group, setGroup] = useState({
    GroupName: "",
    Status: "Active",
  });

  const [clients, setClients] = useState([
    {
      FirstName: "",
      LastName: "",
    },
  ]);

  async function handleSubmit(event: MouseEvent) {
    event.preventDefault();

    /** If no clientData */
    if (group.GroupName === "") {
      dispatch({
        type: "snackbar/error",
        payload: "Please provide a Group name.",
      });
      return;
    }

    /** If no clientData */
    if (clients.length < 1) {
      dispatch({
        type: "snackbar/error",
        payload: "To add a new group, please add at least 1 client.",
      });
      return;
    }

    /** If GroupName already exists */
    if (
      allGroups &&
      allGroups.some(
        (entry) =>
          entry.GroupName.toLowerCase() === group.GroupName.toLowerCase()
      )
    ) {
      dispatch({
        type: "snackbar/error",
        payload: "This group name is already in use, please try another.",
      });
      return;
    }

    /** If haven't filled out first or last name */
    if (
      clients.some(
        (client) => client.FirstName === "" || client.LastName === ""
      )
    ) {
      dispatch({
        type: "snackbar/error",
        payload: "Provide a First and Last Name for each client.",
      });
      return;
    }

    createOneGroupMutation({
      variables: {
        data: {
          GroupName: group.GroupName,
          Status: group.Status,
          start_date:
            group.Status === "Active" || group.Status === "Prospect"
              ? dayjs().format("YYYY-MM-DD")
              : undefined,
          created_by: user?.staff_ID,
          organisation: {
            connect: {
              ID: user?.staff_organisation_ID,
            },
          },
        },
      },
    })
      .then(async (groupResults) => {
        await Promise.all(
          clients.map((client) =>
            createOneClientMutation({
              variables: {
                data: {
                  groups: {
                    connect: {
                      ID_organisation_ID: {
                        ID: groupResults.data?.createOneGroups.ID!,
                        organisation_ID:
                          groupResults.data?.createOneGroups.organisation_ID!,
                      },
                    },
                  },
                  FirstName: client?.FirstName,
                  LastName: client?.LastName,
                  created_by: user?.staff_ID,
                  Status: "Active",
                  estateplanning: { create: [{}] },
                  interest: { create: [{}] },
                },
              },
            })
          )
        );
        // Succesful
        dispatch({
          type: "snackbar/success",
          payload: `${groupResults.data?.createOneGroups.GroupName} added successfully`,
        });

        // Clear option
        // Close popover
        setMenuOpen(false);

        navigate(`/group/ID/${groupResults.data?.createOneGroups.ID}`);
      })
      .catch((error) =>
        dispatch({ type: "snackbar/error", payload: error.message })
      );
  }

  if ((!user && userLoading) || (!allGroups && allGroupsLoading)) {
    return <ComponentLoader />;
  }

  if ((userError && !userLoading) || (allGroupsError && !allGroupsLoading)) {
    return (
      <Refetch
        refetch={() => {
          allGroupsRefetch();
          userRefetch();
        }}
      />
    );
  }

  if (!user || !allGroups) return null;

  return (
    <Fragment>
      <div className="flex flex-col gap-2 relative justify-center p-4 w-80">
        <h5>Add new group</h5>

        <div className="grid grid-cols-2 gap-y-4 gap-x-2 items-center">
          <Select
            label="Status"
            defaultValue={group.Status}
            options={["Active", "Prospect", "Lead"]}
            mutation={(value: string) => setGroup({ ...group, Status: value })}
            required
          />

          <Input
            label="Group name"
            name="GroupName"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setGroup({ ...group, GroupName: event?.target.value })
            }
            required
          />
        </div>

        <div className="flex gap-2 justify-between items-center">
          <h5>Add clients to new group</h5>
          <Button
            tooltip="Add more clients to group"
            type="circle"
            functionality="add"
            onClick={() =>
              setClients([...clients, { FirstName: "", LastName: "" }])
            }
          />
        </div>

        <div className="grid grid-cols-2 gap-y-4 gap-x-2 items-center">
          {clients.map((client, index) => (
            <React.Fragment key={"client" + index}>
              <Input
                label="Client first name"
                defaultValue={client?.FirstName}
                name="FirstName"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClients(
                    clients.map((clientMap, mapIndex) =>
                      mapIndex === index
                        ? { ...clientMap, FirstName: event?.target.value }
                        : clientMap
                    )
                  )
                }
                required
              />

              <Input
                label="Client last name"
                name="LastName"
                defaultValue={client?.LastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClients(
                    clients.map((clientMap, mapIndex) =>
                      mapIndex === index
                        ? { ...clientMap, LastName: event?.target.value }
                        : clientMap
                    )
                  )
                }
                required
              />
            </React.Fragment>
          ))}
        </div>
        <div className="w-full">
          <Button
            type="default"
            text="Add group"
            onClick={(event: MouseEvent) => handleSubmit(event)}
            disabled={clients.some(
              (client) => client.FirstName === "" || client.LastName === ""
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};
