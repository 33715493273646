import { gql } from "@apollo/client";

export const contributionswithdrawalsFragment = gql`
  fragment contributionswithdrawals on contributionswithdrawals {
    ID
    goals_ID
    goals_clients_ID
    goals_clients_groups_ID
    goals_clients_groups_organisation_ID
    StartDate
    EndDate
    Amount
    Category
    OneOffDate
    Type
    Frequency
    update_time
    created_by
    updated_by
  }
`;
