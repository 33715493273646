import React from "react";
import { LiveData } from "../../../Calculations";
import { AssetAllocationLiveModelGraph } from "../Graph";
import { StyledText } from "../../../../../StyledText";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";

interface LongTermProgressPlaceholderModelProps {
  theme?: Theme;
}

var data: LiveData[] = [
  { datakey: 20, name: "CashTotal" },
  { datakey: 50, name: "PropertyDirectTotal" },
  { datakey: 5, name: "AustralianSharesTotal" },
  { datakey: 15, name: "InternationalSharesTotal" },
  { datakey: 10, name: "OtherTotal" },
];

export const AssetAllocationPlaceholderModel = ({
  theme,
}: LongTermProgressPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Asset Allocation
        </StyledText>

        <ModelTooltip type={Model_Type.AssetAllocation} />
      </div>
      <h1
        className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
        </h1>
      <div className="opacity-50">
        <AssetAllocationLiveModelGraph liveData={data} />
      </div>
    </React.Fragment>
  );
};
