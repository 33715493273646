import { QueryResult } from "@apollo/client";
import {
  ScenarioliabilitySubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { scenarioliability } from "../subscription";

interface ScenarioliabilityResolverParams {
  prev: FindUniqueModelQuery;
  payload: ScenarioliabilitySubscription["scenarioliability"];
}

function createScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const { data: scenarioliability, model_has_scenarioliability } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioliability: model_has_scenarioliability.some(
        (relation) =>
          relation.model_ID === prev.findUniqueModel?.ID &&
          relation.scenarioliability_ID === scenarioliability.ID
      )
        ? [...prev.findUniqueModel.scenarioliability, scenarioliability]
        : prev.findUniqueModel.scenarioliability,
    },
  });
}

function updateScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const { data: scenarioliability, model_has_scenarioliability } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      /** If scenarioliability already in model.scenarioliability, update, else add to array if
       * model_has_scenarioliability */
      scenarioliability: prev.findUniqueModel.scenarioliability.some(
        (scenarioliabilitys) => scenarioliabilitys.ID === scenarioliability.ID
      )
        ? prev.findUniqueModel.scenarioliability.map((scenarioliabilitys) =>
            scenarioliabilitys.ID === scenarioliability.ID
              ? { ...scenarioliabilitys, ...scenarioliability }
              : scenarioliabilitys
          )
        : model_has_scenarioliability.some(
            (relation) =>
              relation.model_ID === prev.findUniqueModel?.ID &&
              relation.scenarioliability_ID === scenarioliability.ID
          )
        ? [...prev.findUniqueModel.scenarioliability, scenarioliability]
        : prev.findUniqueModel.scenarioliability,
    },
  });
}

function deleteScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioliability: [...prev.findUniqueModel.scenarioliability].filter(
        (scenarioliabilitys) => scenarioliabilitys.ID !== scenarioliability.ID
      ),
    },
  });
}

function scenarioliabilityResolver({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioliability({ prev, payload });

    case MutationType.Update:
      return updateScenarioliability({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioliability({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioliabilityFindUniqueModel(
  query: Pick<
    QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioliability,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioliabilitySubscription };
      }
    ) =>
      scenarioliabilityResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioliability,
      }),
    variables: query.variables,
  });
}
