import { gql } from "@apollo/client";

export const scenariocontributionwithdrawalCoreFragment = gql`
  fragment scenariocontributionwithdrawalCore on scenariocontributionwithdrawal {
    ID
    Type
    Frequency
    Value
    StartDate
    EndDate
    OneOffDate
    Indexation
    OngoingOrOneoff
    FromIncome

    create_time
    update_time
    created_by
    updated_by
  }
`;

export const scenariocontributionwithdrawalFragment = gql`
  fragment scenariocontributionwithdrawal on scenariocontributionwithdrawal {
    ...scenariocontributionwithdrawalCore
  }
  ${scenariocontributionwithdrawalCoreFragment}
`;
