import { gql } from "@apollo/client";
import { adviceagreementFragment } from ".";

export const createOneAdviceagreement = gql`
  mutation CreateOneAdviceagreement(
    $data: adviceagreementCreateInput!
  ) {
    createOneAdviceagreement(data: $data) {
      ...adviceagreement
    }
  }
  ${adviceagreementFragment}
`;

export const updateOneAdviceagreement = gql`
  mutation UpdateOneAdviceagreement(
    $data: adviceagreementUpdateInput!
    $where: adviceagreementWhereUniqueInput!
  ) {
    updateOneAdviceagreement(data: $data, where: $where) {
      ...adviceagreement
    }
  }
  ${adviceagreementFragment}
`;

export const deleteOneAdviceagreement = gql`
  mutation DeleteOneAdviceagreement(
    $where: adviceagreementWhereUniqueInput!
  ) {
    deleteOneAdviceagreement(where: $where) {
      ...adviceagreement
    }
  }
  ${adviceagreementFragment}
`;
