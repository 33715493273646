import { PropertyPlaceholderType } from "../../Components/Advice/Content/Assets/Property/Individual";
import { FindUniqueGroupQuery, Property } from "../../codegen/schema";
import { getGroupAssets } from "../GeneralFunctions";

/** function maxLendCalculation
 * @date 27-07-2021
 * @returns variable "Max Lend"
 * @description function gets properties Market Value and LVR, multiplies them together
 * and divides by 100 to return Max Lend.
 */
export function maxLendCalculation(property: Property) {
  //Max lend is calculated by multiplying the marketvalue with LVR then dividing vy 100.
  if (
    property.MarketValue !== null &&
    property.LVR !== null &&
    property.Type !== "Sold"
  ) {
    return (parseInt(property.MarketValue) * parseInt(property.LVR)) / 100;
  }
}

/** function availableEquity
 * @date 03-08-2021
 * @returns variable "AvailableEquity"
 * @description function gets properties Max lend (Market Value & LVR) and current
 * loan outstanding and returns AvailableEquity
 */
export function availableEquityCalculation(property: Property) {
  var maxLend = maxLendCalculation(property);
  var loanTotal = 0;

  if (property.liabilities.loans) {
    if (property.liabilities.loans.length > 0) {
      property.liabilities.loans.forEach((loan) => {
        if (loan.SecuredBy === property.assets_ID) {
          loanTotal += parseInt(loan.LoanLimit);
        }
      });
    } else loanTotal += 0;
  }
  if (!maxLend) return undefined;
  return maxLend - loanTotal;
}

/** function capGrowthCalculation
 * @date 27-07-2021
 * @returns variable "CapitalGrowth"
 * @description function gets properties Market Value and Purchase Price
 * and returns Capital Growth
 */
export function capGrowthCalculation(
  property: Property | PropertyPlaceholderType
) {
  //Capital Growth is calculated by subtracting Market Value by Purchase Price.
  if (
    property.MarketValue !== null &&
    property.PurchasePrice !== null &&
    property.Type !== "Sold"
  ) {
    return parseInt(property.MarketValue) - parseInt(property.PurchasePrice);
  }
}

/** function marketValueTotalCalculation
 * @date 03-08-2021
 * @returns variable "Market Value Total"
 * @description function gets ALL properties Market Value then adds all
 *  values together to return Market Value Total.
 */
export function marketValueTotalCalculation(
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  var MarketValueTotal = 0;

  if (getGroupAssets(group).property)
    getGroupAssets(group).property.forEach((property: Property) => {
      if (property.Type !== "Sold") {
        MarketValueTotal += parseInt(property.MarketValue);
      }
    });

  return MarketValueTotal;
}

/** function maxLendTotalCalculation
 * @date 03-08-2021
 * @returns variable "Max Lend Total"
 * @description function gets ALL properties Market Value and LVR, multiplies them together,
 * divides by 100 then adds all property max lend values together to return Max Lend Total.
 */
export function maxLendTotalCalculation(
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  //Max lend is calculated by multiplying the marketvalue with LVR then dividing vy 100.
  var MaxLendTotal = 0;

  if (getGroupAssets(group).property)
    getGroupAssets(group).property.forEach((property: Property) => {
      if (property.Type !== "Sold") {
        MaxLendTotal +=
          (parseInt(property.MarketValue) * parseInt(property.LVR)) / 100;
      }
    });

  return MaxLendTotal;
}

/** function availableEquityTotalCalculation
 * @date 03-08-2021
 * @returns "maxLendTotal - loanTotal" which creates AvailableEquityTotal
 * @description function gets properties Max lend (Market Value & LVR) and current
 * loan outstanding and returns AvailableEquityTotal
 */
export function availableEquityTotalCalculation(
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  var maxLendTotal = maxLendTotalCalculation(group);
  var loanTotal = 0;

  if (getGroupAssets(group).property)
    getGroupAssets(group).property.forEach((property: Property) => {
      if (property.Type !== "Sold") {
        if (property.liabilities.loans) {
          if (property.liabilities.loans.length > 0) {
            property.liabilities.loans.forEach((loan) => {
              if (loan.SecuredBy === property.assets_ID) {
                loanTotal += parseInt(loan.LoanLimit);
              }
            });
          } else loanTotal += 0;
        }
      }
    });

  return maxLendTotal - loanTotal;
}

/** function totalPropertyLoansCalculation
 * @date 04-08-2021
 * @returns variable totalLoans (for each individual property)
 * @description function maps all loans secured by a singular property and
 * returns total Loan amount.
 */
export function totalPropertyLoansCalculation(property: Property) {
  var totalLoans = 0;

  if (property.liabilities.loans && property.Type !== "Sold") {
    if (property.liabilities.loans.length > 0) {
      property.liabilities.loans.forEach((loan) => {
        if (loan.SecuredBy === property.assets_ID) {
          // WM have asked for this to be LoanLimit
          totalLoans += parseInt(loan.LoanLimit);
        }
      });
    } else totalLoans += 0;
  }

  return totalLoans;
}

/** function totalEquityPropertiesCalculation
 * @date 04-08-2021
 * @returns "marketValue - totalLoans" which creates total  equity (for an individual property)
 * @description function maps all loans secured by a singular property, gets the total
 * Loan amount then miniuses the marketValue by totalLoans to get the equity.
 * Total equity is value of property minus current loan outstanding. Available equity
 * uses loan limit (total amount borrowed) */
export function totalEquityPropertiesCalculation(property: Property) {
  var marketValue = 0;
  var totalLoans = 0;

  if (property.Type !== "Sold") {
    if (property.MarketValue !== null) {
      marketValue = parseInt(property.MarketValue);
    }

    if (property.liabilities.loans) {
      if (property.liabilities.loans.length > 0) {
        property.liabilities.loans.forEach((loan) => {
          if (loan.SecuredBy === property.assets_ID) {
            if (loan.LoanLimit !== null) {
              // WM have asked for this to be LoanLimit
              totalLoans += parseInt(loan.LoanLimit);
            } else totalLoans += 0;
          }
        });
      } else totalLoans += 0;
    }
  }
  return marketValue - totalLoans;
}

/** function totalLoanRepayCalculation
 * @author Lara de Maroussem
 * @date 04-08-2021
 * @param group - group object to get all property loans
 * @returns variable totalLoanRepay
 * @description function maps all loans secured by properties and
 * returns total Loan Repay.
 */
export function totalLoanRepayCalculation(
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  var totalLoanRepay = 0;

  if (getGroupAssets(group).property)
    getGroupAssets(group).property.forEach((property: Property) => {
      if (property.Type !== "Sold") {
        if (property.liabilities.loans) {
          if (property.liabilities.loans.length > 0) {
            property.liabilities.loans.forEach((loan) => {
              if (loan.SecuredBy === property.assets_ID) {
                totalLoanRepay += parseInt(loan.RepaymentPerMonth);
              }
            });
          } else totalLoanRepay += 0;
        }
      }
    });

  return totalLoanRepay;
}

/** function totalPropertyLoansGroupCalculation
 * @author Lara de Maroussem
 * @date 04-08-2021
 * @param {Array} grouo - to get all properties and loans
 * @returns variable totalLoansGroup (loans total for group)
 * @description function maps all loans secured by all properties and
 * returns total Loan amount for group.
 */
export function totalPropertyLoansGroupCalculation(
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  var totalLoans = 0;

  if (getGroupAssets(group).property)
    getGroupAssets(group).property.forEach((property: Property) => {
      if (property.liabilities.loans && property.Type !== "Sold") {
        if (property.liabilities.loans.length > 0) {
          property.liabilities.loans.forEach((loan) => {
            if (loan.SecuredBy === property.assets_ID) {
              totalLoans += parseInt(loan.LoanLimit);
            }
          });
        } else totalLoans += 0;
      }
    });

  return totalLoans;
}

/** function totalEquityPropertyGroupCalculation
 * @author Lara de Maroussem
 * @date 04-08-2021
 * @param group - to get all properties and loans
 * @returns variable totalLoansGroup (loans total for group)
 * @description function gets marketValue total (from function above)
 * and minuses that value from total loans (from function above)
 */
export function totalEquityPropertyGroupCalculation(
  group: FindUniqueGroupQuery["findUniqueGroup"]
) {
  var marketValue = marketValueTotalCalculation(group);
  var totalLoans = totalPropertyLoansGroupCalculation(group);

  return marketValue - totalLoans;
}
