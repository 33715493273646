/**
 * @description Average weekly ordinary time earnings. Pre-tax average income.
 * This will increase by WPI each year.
 */
export const AWOTE = 1805.9; // Multiply this by CPI

/** @description Wage price index */
export const WPI = 0.025; // 2.5%

/** @description Consumer price index */
export const CPI = 0.0225; // 2.25%

export const DIV293_THRESHOLD = 250000;

/**
 * Cap is calculated before tax. E.g. 27,500 in contributions, that would then be taxed.
 * https://www.ato.gov.au/Individuals/Super/Growing-and-keeping-track-of-your-super/Caps-limits-and-tax-on-super-contributions/Concessional-contributions-cap/
 * IF YOU UPDATE THIS ALSO NEED TO UPDATE YEARS IN CALCULATIONS/SCENARIOCLIENT/INDEX.TS */
export const CONCESSIONAL_CONTRIBUTIONS_CAP = [
  { year: 2018, value: 25000 },
  { year: 2019, value: 25000 },
  { year: 2020, value: 25000 },
  { year: 2021, value: 25000 },
  { year: 2022, value: 27500 },
  { year: 2023, value: 27500 },
  { year: 2024, value: 27500 },
  { year: 2025, value: 30000 },
];

/** https://www.ato.gov.au/Individuals/Super/Growing-and-keeping-track-of-your-super/Caps-limits-and-tax-on-super-contributions/Non-concessional-contributions-cap/
 *  * IF YOU UPDATE THIS ALSO NEED TO UPDATE YEARS IN CALCULATIONS/SCENARIOCLIENT/INDEX.TS */
export const NONCONCESSIONAL_CONTRIBUTIONS_CAP = [
  { year: 2014, value: 150000 },
  { year: 2015, value: 180000 },
  { year: 2016, value: 180000 },
  { year: 2017, value: 180000 },
  { year: 2018, value: 100000 },
  { year: 2019, value: 100000 },
  { year: 2020, value: 100000 },
  { year: 2021, value: 100000 },
  { year: 2022, value: 110000 },
  { year: 2023, value: 110000 },
  { year: 2024, value: 110000 },
  { year: 2025, value: 120000 },
];

/** https://www.ato.gov.au/Individuals/Super/Withdrawing-and-using-your-super/Transfer-balance-cap/
 *  * IF YOU UPDATE THIS ALSO NEED TO UPDATE YEARS IN CALCULATIONS/SCENARIOCLIENT/INDEX.TS */
export const TRANSFER_BALANCE_CAP = [
  { year: 2020, value: 1600000 },
  { year: 2021, value: 1600000 },
  { year: 2022, value: 1700000 },
  { year: 2023, value: 1700000 },
  { year: 2024, value: 1900000 },
  { year: 2025, value: 1900000 },
];

/**
 * Australia minimum pension drawdown rates by age.
 * https://www.ato.gov.au/Rates/Key-superannuation-rates-and-thresholds/?page=9
 */
export const MINIMUM_PENSION_DRAWDOWN = {
  under65: 0.04,
  from65to74: 0.05,
  from75to79: 0.06,
  from80to84: 0.07,
  from85to89: 0.09,
  from90to94: 0.11,
  over95: 0.14,
};
