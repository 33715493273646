import { Button } from "../Buttons";

interface RefetchProps {
  refetch: Function;
}

export const Refetch: React.FC<RefetchProps> = ({ refetch }) => {
  return (
    <div className="card mx-2 my-4">
        <div className="flex items-center gap-2">
        <div className="icon fa-regular fa-cloud-exclamation" />
      <h5 >
        Oops...
        </h5>
        </div>
      <p>
        There was an error loading page content.
      </p>
      <Button
        type="alt"
        text="Click here to retry"
        onClick={() => {
          refetch();
        }}
      />
    </div>
  );
};
