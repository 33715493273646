import { gql } from "@apollo/client";
import { scenarioinsuranceFragment } from "./fragment";

export const createOneScenarioinsurance = gql`
  mutation CreateOneScenarioinsurance($data: scenarioinsuranceCreateInput!) {
    createOneScenarioinsurance(data: $data) {
      ...scenarioinsurance
    }
  }
  ${scenarioinsuranceFragment}
`;

export const updateOneScenarioinsurance = gql`
  mutation UpdateOneScenarioinsurance(
    $data: scenarioinsuranceUpdateInput!
    $where: scenarioinsuranceWhereUniqueInput!
  ) {
    updateOneScenarioinsurance(data: $data, where: $where) {
      ...scenarioinsurance
    }
  }
  ${scenarioinsuranceFragment}
`;

export const deleteOneScenarioinsurance = gql`
  mutation DeleteOneScenarioinsurance(
    $where: scenarioinsuranceWhereUniqueInput!
  ) {
    deleteOneScenarioinsurance(where: $where) {
      ...scenarioinsurance
    }
  }
  ${scenarioinsuranceFragment}
`;
