import React, { useEffect, useMemo, useState } from "react";
import * as Y from "yjs";

import { useParams } from "react-router-dom";
import { Editor } from "slate";

import {
  SortOrder,
  useFindManySectiontemplateQuery,
  useFindUniqueDocumenttemplateQuery,
  useFindUniqueOrganisationQuery,
  useFindUniqueThemeLazyQuery,
} from "../../../../codegen/schema";

import { SlateSelected } from "../../../../Components/Slate/Types";
import { WebsocketProvider } from "../../../../Components/Slate/Yjs";
import { DocumenttemplateSection } from "../DocumentTemplate/SectionTemplate";

export default function DocumenttemplateDownload() {
  const { documenttemplateid } = useParams();
  const [activeEditor, setActiveEditor] = useState<Editor | undefined>(
    undefined
  );
  const [selected, setSelected] = useState<SlateSelected | undefined>(
    undefined
  );
  const [
    findUniqueTheme,
    { data: { findUniqueTheme: theme = undefined } = {} },
  ] = useFindUniqueThemeLazyQuery();

  const { data: { findUniqueDocumenttemplate: documenttemplate } = {} } =
    useFindUniqueDocumenttemplateQuery({
      fetchPolicy: "cache-and-network",
      variables: { where: { ID: parseInt(documenttemplateid!) } },
      onCompleted: (data) => {
        findUniqueTheme({
          variables: {
            where: {
              ID: data.findUniqueDocumenttemplate?.theme_ID
                ? data.findUniqueDocumenttemplate?.theme_ID
                : 1,
            },
          },
        });
      },
    });

  const { data: { findManySectiontemplate: sectiontemplates } = {} } =
    useFindManySectiontemplateQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        where: {
          documenttemplate_ID: { equals: parseInt(documenttemplateid!) },
        },
        orderBy: { Position: SortOrder.Asc },
      },
    });

  const { data: { findUniqueOrganisation: organisation } = {} } =
    useFindUniqueOrganisationQuery({ fetchPolicy: "cache-and-network" });

  /** YJS IMPLEMENTATION */
  const doc = new Y.Doc({
    guid: `yjs?__typename=documenttemplate&ID=${documenttemplateid}`,
  });
  const yjsProvider = useMemo(
    () =>
      new WebsocketProvider(
        process.env.REACT_APP_WS_HOST!,
        `yjs?__typename=documenttemplate&ID=${documenttemplateid}`,
        doc
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Connect editor and yjsProvider in useEffect to comply with concurrent mode
  // requirements.
  useEffect(() => {
    yjsProvider.connect();

    yjsProvider.doc.on("subdocs", ({ added, removed, loaded }) => {
      added.forEach(async (subdoc: Y.Doc) => {
        subdoc.load();
      });
    });

    return () => yjsProvider.disconnect();
  }, [yjsProvider]);

  if (!organisation || !documenttemplate || !theme || !sectiontemplates) {
    return <>Error - please try again</>;
  }

  return (
    <React.Fragment>
      {[...sectiontemplates].map((sectiontemplate) => {
        return (
          <React.Fragment key={sectiontemplate.ID}>
            <div style={{ position: "relative" }}>
              <DocumenttemplateSection
                organisation={organisation}
                documenttemplate={documenttemplate}
                sectiontemplate={sectiontemplate}
                theme={theme}
                activeEditor={activeEditor}
                setActiveEditor={setActiveEditor}
                selected={selected}
                setSelected={setSelected}
                yjsProvider={yjsProvider}
              />
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
