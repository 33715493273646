import { QueryResult } from "@apollo/client";
import {
  OffsetSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { offset } from "../subscription";

interface OffsetResolverParams {
  prev: FindUniqueGroupQuery;
  payload: OffsetSubscription["offset"];
}

function createOffset({ prev, payload }: OffsetResolverParams) {
  const {
    data: createOffset,
    clients_has_assets,
    entities_has_assets,
  } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            offset: clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.offset, createOffset]
              : client.assets.offset,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            offset: entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.offset, createOffset]
              : entity.assets.offset,
          },
        };
      }),
    },
  });
}

function updateOffset({ prev, payload }: OffsetResolverParams) {
  const {
    data: updateOffset,
    clients_has_assets,
    entities_has_assets,
  } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the offset object
   * This is because updateOffset only returns the offset info
   * without assetallocation, notes, etc.
   */
  var updatedOffset = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.offset.forEach((prevOffset) => {
      if (prevOffset.assets_ID === updateOffset.assets_ID) {
        result = {
          ...prevOffset,
          ...updateOffset,
        };
      }
    });
    return result;
  }, updateOffset);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update offset first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          offset: [
            ...client.assets.offset
              .map((prevOffset) =>
                prevOffset.ID === updatedOffset.ID
                  ? { ...prevOffset, ...updatedOffset }
                  : prevOffset
              )
              .filter(
                (prevOffset) =>
                  ![...clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(prevOffset.assets_ID)
              ),
            ...(clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? clients_has_assets.reduce<
                  Array<OffsetSubscription["offset"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedOffset.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedOffset];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    offset: liability.assets.offset.map(
                      (offset) =>
                        offset.ID === updateOffset.ID
                          ? {
                              ...offset,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateOffset,
                            }
                          : offset /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            offset: goal.assets.offset.map((prevOffset) =>
              prevOffset.ID === updatedOffset.ID
                ? { ...prevOffset, ...updatedOffset }
                : prevOffset
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      offset: liability.assets.offset.map((offset) =>
                        offset.ID === updateOffset.ID
                          ? {
                              ...offset,
                              /** Update loan info */
                              ...updateOffset,
                            }
                          : offset
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing offset object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          offset: [
            ...entity.assets.offset
              .map((prevOffset) =>
                prevOffset.ID === updatedOffset.ID
                  ? { ...prevOffset, ...updatedOffset }
                  : prevOffset
              )
              .filter(
                (prevOffset) =>
                  ![...entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(prevOffset.assets_ID)
              ),
            ...(entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? entities_has_assets.reduce<
                  Array<OffsetSubscription["offset"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedOffset.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedOffset];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    offset: liability.assets.offset.map(
                      (offset) =>
                        offset.ID === updateOffset.ID
                          ? {
                              ...offset,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateOffset,
                            }
                          : offset /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteOffset({ prev, payload }: OffsetResolverParams) {
  const { data: deleteOffset } = payload;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            offset: [...client.assets.offset].filter(
              (prevOffset) => prevOffset.ID !== deleteOffset.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      offset: liability.assets.offset.filter(
                        (offset) => offset.ID !== deleteOffset.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              offset: goal.assets.offset.filter(
                (prevOffset) => prevOffset.ID !== deleteOffset.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        offset: liability.assets.offset.filter(
                          (offset) => offset.ID !== deleteOffset.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            offset: [...entity.assets.offset].filter(
              (prevOffset) => prevOffset.ID !== deleteOffset.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      offset: liability.assets.offset.filter(
                        (offset) => offset.ID !== deleteOffset.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function offsetResolver({ prev, payload }: OffsetResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createOffset({ prev, payload });

    case MutationType.Update:
      return updateOffset({ prev, payload });

    case MutationType.Delete:
      return deleteOffset({ prev, payload });

    default:
      return prev;
  }
}

export function offsetFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: offset,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: OffsetSubscription } }
    ) =>
      offsetResolver({
        prev,
        payload: payload.subscriptionData.data.offset,
      }),
    variables: query.variables,
  });
}
