import dayjs from "dayjs";
import React from "react";
import { SuperannuationContributionSplittingScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { CPI } from "../../../../../../../Constants/Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface SuperannuationContributionSplittingScenarioPlaceholderModelProps {
  theme?: Theme;
}

const initialValue = 100000;
var data: ScenarioData[] = [];

Array.from(Array(20).keys()).forEach((value) => {
  data.push({
    year: dayjs().year() + value,
    transferBalanceCap:
      Math.floor((1900000 * Math.pow(1 + CPI, value)) / 100000) * 100000,
    "John's superannuation": initialValue * Math.pow(1 + 0.03, value),
    "Jane's superannuation": initialValue * Math.pow(1 + 0.05, value),
  });
});

export const SuperannuationContributionSplittingScenarioPlaceholderModel = ({
  theme,
}: SuperannuationContributionSplittingScenarioPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Superannuation Contribution Splitting
        </StyledText>

        <ModelTooltip type={Model_Type.SuperannuationContributionSplitting} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <SuperannuationContributionSplittingScenarioModelGraph
          scenarioData={data}
          liveData={undefined}
        />
      </div>
    </React.Fragment>
  );
};
