import { gql } from "@apollo/client";
import { employmentFragment } from ".";

export const createOneEmployment = gql`
  mutation CreateOneEmployment($data: employmentCreateInput!) {
    createOneEmployment(data: $data) {
      ...employment
    }
  }
  ${employmentFragment}
`;

export const updateOneEmployment = gql`
  mutation UpdateOneEmployment(
    $data: employmentUpdateInput!
    $where: employmentWhereUniqueInput!
  ) {
    updateOneEmployment(data: $data, where: $where) {
      ...employment
    }
  }
  ${employmentFragment}
`;

export const deleteOneEmployment = gql`
  mutation DeleteOneEmployment($where: employmentWhereUniqueInput!) {
    deleteOneEmployment(where: $where) {
      ...employment
    }
  }
  ${employmentFragment}
`;
