import { gql } from "@apollo/client";

export const interestFragment = gql`
  fragment interest on interest {
    ID
    clients_ID
    clients_groups_ID
    clients_groups_organisation_ID
    SalaryPackaging
    TaxAccounting
    CarPurchase
    BudgetingCashFlow
    RetirementSuper
    Property
    IncomeProtection
    PropertyShares
    AgedCare
    FiveYearPlan
    MarriageDivorceFamily
    BestLifePossible
  }
`;
