import {
  Content_ModelToRender,
  Contenttemplate_ModelToRender,
  Maybe,
  Theme,
} from "../../../../../../codegen/schema";
import { IncomeOptimisationComparisonPlaceholderModel } from "../Comparison/Placeholder";
import { IncomeOptimisationScenarioPlaceholderModel } from "../Scenario/Placeholder";

interface IncomeOptimisationComparisonModelPlaceholderProps {
  ModelToRender?:
    | Maybe<Content_ModelToRender>
    | Maybe<Contenttemplate_ModelToRender>;
  theme?: Theme;
}

export const IncomeOptimisationModelPlaceholder = ({
  ModelToRender,
  theme,
}: IncomeOptimisationComparisonModelPlaceholderProps) => {
  if (
    ModelToRender === Content_ModelToRender.Comparison ||
    ModelToRender === Contenttemplate_ModelToRender.Comparison
  ) {
    return <IncomeOptimisationComparisonPlaceholderModel theme={theme} />;
  } else if (
    ModelToRender === Content_ModelToRender.Scenario ||
    ModelToRender === Contenttemplate_ModelToRender.Scenario
  ) {
    return <IncomeOptimisationScenarioPlaceholderModel theme={theme} />;
  } else return <p>Please choose a Scenario model or Comparison model.</p>;
};
