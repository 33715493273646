import React from "react";
import {
  Content,
  FindUniqueAdviceQuery,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../codegen/schema";
import { EntitiesComponentScenarioClient } from "./ScenarioClient";
import { EntitiesComponentEntity } from "./Entity";

interface EntitiesComponentProps {
  content?: Content | undefined;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const EntitiesComponent: React.FC<EntitiesComponentProps> = ({
  advice,
  content,
  theme,
  group,
}) => {
  var entity = group
    ? content?.entity_ID
      ? // Group entity
        group?.entities.find((entity) => entity.ID === content?.entity_ID)
      : // No entity assigned
        undefined
    : // In the template
      undefined;

  var scenarioclient = group
    ? content?.scenarioentity_ID
      ? // Scenario entity
        advice?.scenario
          .flatMap((scenario) => scenario.scenarioclient)
          .find((entity) => entity.ID === content.scenarioentity_ID)
      : // No entity assigned
        undefined
    : // In the template
      undefined;

  var dummyData = {
    Name: "Entity Name",
    Type: "SMSF",
    ABN: "12345678",
    ACN: "12345678",
    Description: "Entity Description",
  };

  var emptyEntity = {
    Name: "",
    Type: "",
    ABN: "",
    ACN: "",
    Description: "",
  };

  return (
    <React.Fragment>
      {advice ? (
        scenarioclient ? (
          <EntitiesComponentScenarioClient
            entity={scenarioclient}
            theme={theme}
          />
        ) : entity ? (
          <EntitiesComponentEntity entity={entity} theme={theme} />
        ) : (
          <EntitiesComponentEntity entity={emptyEntity} theme={theme} />
        )
      ) : (
        <EntitiesComponentEntity entity={dummyData} theme={theme} />
      )}
    </React.Fragment>
  );
};
