import { QueryResult } from "@apollo/client";
import {
  ClientsManySubscription,
  FindManyClientsQuery,
  FindManyClientsQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { clientsMany } from "../subscription";

interface ClientsResolverParams {
  prev: FindManyClientsQuery;
  payload: ClientsManySubscription["clientsMany"];
}

function createClients({ prev, payload }: ClientsResolverParams) {
  const clients = payload.data;

  return Object.assign({}, prev, {
    findManyClients: [...prev.findManyClients, clients],
  });
}

function updateClients({ prev, payload }: ClientsResolverParams) {
  const clients = payload.data;

  return Object.assign({}, prev, {
    findManyClients: prev.findManyClients.map((entry) =>
      entry.ID === clients.ID ? { ...entry, ...clients } : entry
    ),
  });
}

function deleteClients({ prev, payload }: ClientsResolverParams) {
  const clients = payload.data;

  return Object.assign({}, prev, {
    findManyClients: [...prev.findManyClients].filter(
      (entry) => entry.ID !== clients.ID
    ),
  });
}

function clientsResolver({ prev, payload }: ClientsResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createClients({ prev, payload });

    case MutationType.Update:
      return prev.findManyClients.some((clients) => clients.ID === data.ID)
        ? updateClients({ prev, payload })
        : createClients({ prev, payload });

    case MutationType.Delete:
      return deleteClients({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyClients(
  query: Pick<
    QueryResult<FindManyClientsQuery, FindManyClientsQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: clientsMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ClientsManySubscription } }
    ) =>
      clientsResolver({
        prev,
        payload: payload.subscriptionData.data.clientsMany,
      }),
    variables: query.variables,
  });
}
