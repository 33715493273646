import { QueryResult } from "@apollo/client";
import { userFindCurrentUser } from "../reducers";
import {
  FindCurrentUserQuery,
  FindCurrentUserQueryVariables,
} from "../../../codegen/schema";

export default function findCurrentUserSubscription(
  query: Pick<
    QueryResult<FindCurrentUserQuery, FindCurrentUserQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  userFindCurrentUser(query);
}
