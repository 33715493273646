import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  FindUniqueAdviceQuery,
  Theme,
} from "../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupLiabilities,
} from "../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../StyledText";

interface LiabilitiesOtherliabilityProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"] | undefined;
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns Otherliability component for advice template && document.
 */
export const LiabilitiesOtherliability: React.FC<
  LiabilitiesOtherliabilityProps
> = ({ content, contenttemplate, theme, group, advice }) => {
  type Otherliability = {
    liabilities_ID: number | null;
    Type: string;
    Bank: string;
    Amount: string;
  };

  const summaryOtherliability: Otherliability[] = content
    ? content?.ContentType === "Grouped"
      ? getGroupLiabilities(group).otherliabilities
      : content?.liability_ID
      ? getGroupLiabilities(group).otherliabilities.find(
          (otherliability: Otherliability) =>
            content?.liability_ID === otherliability.liabilities_ID
        )
      : {}
    : [
        {
          Type: "Personal loan",
          Amount: "$30,000",
          Bank: "Westpac",
        },
        {
          Type: "Credit card",
          Amount: "$5,000",
          Bank: "Westpac",
        },
      ];

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryOtherliability.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Type
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Bank
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Amount
          </StyledText>
        </div>
      ) : (
        <h5>No other liabilities recorded</h5>
      )}
      {summaryOtherliability.map((liability, objectIndex) => {
        return (
          <div
           className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(liability.Type)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(liability.Bank)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(liability.Amount, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>Individual Other Liability view</React.Fragment>
  );
};
