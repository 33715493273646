import { gql } from "@apollo/client";

export const assetallocationFragment = gql`
  fragment assetallocation on assetallocation {
    ID
    assets_ID
    Cash
    FixedInterest
    InternationalShares
    AustralianShares
    PropertyDirect
    PropertyGeneral
    EmergingMarkets
    Other
    AverageAnnualRateOfReturn
    create_time
    created_by
    update_time
    updated_by
  }
`;
