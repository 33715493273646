import { QueryResult } from "@apollo/client";
import {
  FindUniqueAddressQuery,
  FindUniqueAddressQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueAddressQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, address_has_notes } = payload;

  if (!prev.findUniqueAddress) return prev;
  return Object.assign({}, prev, {
    findUniqueAddress: {
      ...prev.findUniqueAddress,
      notes: address_has_notes.some(
        (relation) => relation.address_ID === prev.findUniqueAddress?.ID
      )
        ? [...prev.findUniqueAddress.notes, notes]
        : prev.findUniqueAddress.notes,
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueAddress) return prev;
  return Object.assign({}, prev, {
    findUniqueAddress: {
      ...prev.findUniqueAddress,
      notes: prev.findUniqueAddress.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueAddress) return prev;
  return Object.assign({}, prev, {
    findUniqueAddress: {
      ...prev.findUniqueAddress,
      notes: prev.findUniqueAddress.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueAddress(
  query: Pick<
    QueryResult<FindUniqueAddressQuery, FindUniqueAddressQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes,
      }),
    variables: query.variables,
  });
}
