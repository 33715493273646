import { QueryResult } from "@apollo/client";
import {
  ScenarioSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { scenario } from "../subscription";

interface ScenarioResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ScenarioSubscription["scenario"];
}

function createScenario({ prev, payload }: ScenarioResolverParams) {
  const scenario = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenario.advice_ID
          ? {
              ...advice,
              scenario: [...advice.scenario, scenario],
            }
          : advice
      ),
    },
  });
}

function updateScenario({ prev, payload }: ScenarioResolverParams) {
  const scenario = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) => {
        return {
          ...advice,
          scenario: advice.scenario.map((scenarios) =>
            scenarios.ID === scenario.ID
              ? { ...scenarios, ...scenario }
              : scenario
          ),
        };
      }),
    },
  });
}

function deleteScenario({ prev, payload }: ScenarioResolverParams) {
  const scenario = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) => {
        return {
          ...advice,
          scenario: [...advice.scenario].filter(
            (scenarios) => scenarios.ID !== scenario.ID
          ),
        };
      }),
    },
  });
}

function scenarioResolver({ prev, payload }: ScenarioResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenario({ prev, payload });

    case MutationType.Update:
      return updateScenario({ prev, payload });

    case MutationType.Delete:
      return deleteScenario({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenario,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioSubscription } }
    ) =>
      scenarioResolver({
        prev,
        payload: payload.subscriptionData.data.scenario,
      }),
    variables: query.variables,
  });
}
