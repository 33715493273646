import React from "react";
import { LoanRepaymentComparisonModelGraph } from "../Graph";
import { ComparisonData } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";
import dayjs from "dayjs";

interface LoanRepaymentPlaceholderModelProps {
  theme?: Theme;
}

var data: ComparisonData[] = [];

Array.from(Array(12).keys()).forEach((value, index) => {
  data.push({
    year: dayjs().year() + value,
    [`Scenario 1`]:
      index === 0
        ? -475989.9866681602
        : index === 1
        ? -435419.57280304667
        : index === 2
        ? -393226.3423833287
        : index === 3
        ? -349345.38274682197
        : index === 4
        ? -303709.184724855
        : index === 5
        ? -256247.53878200927
        : index === 6
        ? -206887.42700144977
        : index === 7
        ? -155552.91074966785
        : index === 8
        ? -102165.0138478147
        : index === 9
        ? -46641.60106988743
        : index === 10
        ? 11102.74821915695
        : index === 11
        ? undefined
        : 0,
    [`Scenario 2`]:
      index === 0
        ? -212193.1976425386
        : index === 1
        ? -196634.2690946712
        : index === 2
        ? -180082.68090544987
        : index === 3
        ? -162475.1013897562
        : index === 4
        ? -143744.15830096125
        : index === 5
        ? -123818.18104310118
        : index === 6
        ? -102620.92643618965
        : index === 7
        ? -80071.28698535716
        : index === 8
        ? -56082.98053756158
        : index === 9
        ? -30564.220138396628
        : index === 10
        ? -3417.3628257649543
        : index === 11
        ? 25461.463983412625
        : 0,
  });
});

export const LoanRepaymentComparisonPlaceholderModel = ({
  theme,
}: LoanRepaymentPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Loan Repayment
        </StyledText>

        <ModelTooltip type={Model_Type.LoanRepayment} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <LoanRepaymentComparisonModelGraph
          comparisonData={data}
          placeholder={true}
        />
      </div>
    </React.Fragment>
  );
};
