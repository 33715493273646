import { gql } from "@apollo/client";
import { pagetemplateFragment } from ".";

export const createOnePagetemplate = gql`
  mutation CreateOnePagetemplate($data: pagetemplateCreateInput!) {
    createOnePagetemplate(data: $data) {
      ...pagetemplate
    }
  }
  ${pagetemplateFragment}
`;

export const updateOnePagetemplate = gql`
  mutation UpdateOnePagetemplate(
    $data: pagetemplateUpdateInput!
    $where: pagetemplateWhereUniqueInput!
  ) {
    updateOnePagetemplate(data: $data, where: $where) {
      ...pagetemplate
    }
  }
  ${pagetemplateFragment}
`;

export const deleteOnePagetemplate = gql`
  mutation DeleteOnePagetemplate($where: pagetemplateWhereUniqueInput!) {
    deleteOnePagetemplate(where: $where) {
      ...pagetemplate
    }
  }
  ${pagetemplateFragment}
`;
