import { gql } from "@apollo/client";
import { estateplanningFragment } from "./fragment";

export const estateplanning = gql`
  subscription Estateplanning($where: estateplanningWhereUniqueInput) {
    estateplanning(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...estateplanning
      }
    }
  }
  ${estateplanningFragment}
`;

export const estateplanningMany = gql`
  subscription EstateplanningMany($where: estateplanningWhereInput) {
    estateplanningMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...estateplanning
      }
    }
  }
  ${estateplanningFragment}
`;
