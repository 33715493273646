import { QueryResult } from "@apollo/client";
import {
  StaffSubscription,
  FindCurrentStaffQuery,
  MutationType,
  FindCurrentStaffQueryVariables,
} from "../../../codegen/schema";
import { staff } from "../subscription";

interface StaffResolverParams {
  prev: FindCurrentStaffQuery;
  payload: StaffSubscription["staff"];
}

function updateStaff({ prev, payload }: StaffResolverParams) {
  const staff = payload.data;

  if (!prev.findCurrentStaff) return prev;
  return Object.assign({}, prev, {
    findCurrentStaff:
      staff.ID === prev.findCurrentStaff.ID
        ? {
            ...prev.findCurrentStaff,
            ...staff,
          }
        : prev.findCurrentStaff,
  });
}

function staffResolver({ prev, payload }: StaffResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createStaff(prev, payload);

    case MutationType.Update:
      return updateStaff({ prev, payload });

    // case MutationType.Delete:
    //   return deleteStaff({ prev, payload });

    default:
      return prev;
  }
}

export function staffFindCurrentStaff(
  query: Pick<
    QueryResult<FindCurrentStaffQuery, FindCurrentStaffQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: staff,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: StaffSubscription } }
    ) =>
      staffResolver({
        prev,
        payload: payload.subscriptionData.data.staff,
      }),
    variables: query.variables,
  });
}
