import { QueryResult } from "@apollo/client";
import {
  FileSubscription,
  FindUniqueFileQuery,
  FindUniqueFileQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { file } from "../subscription";

interface FileResolverParams {
  prev: FindUniqueFileQuery;
  payload: FileSubscription["file"];
}

function createFile({ prev, payload }: FileResolverParams) {
  const file = payload.data;

  /**
   * 1. Check if previous filepath includes the updated file path.
   * 2. If true: set new findUniqueFile object and append a cache buster
   *    to the end of the file path.
   * 3. If false: return the previous findUniqueFile as nothing has changed,
   *    so no re-render is required.
   */
  if (!prev.findUniqueFile) return prev;
  return Object.assign({}, prev, {
    findUniqueFile: prev.findUniqueFile.filePath.includes(file.filePath)
      ? {
          ...file,
          filePath: file.filePath + "#" + new Date().getTime(),
        }
      : { ...prev.findUniqueFile },
  });
}

function deleteFile({ prev, payload }: FileResolverParams) {
  const file = payload.data;

  if (!prev.findUniqueFile) return prev;
  return Object.assign({}, prev, {
    findUniqueFile: prev.findUniqueFile.filePath.includes(file.filePath)
      ? {
          ...prev.findUniqueFile,
          undefined,
        }
      : { ...prev.findUniqueFile },
  });
}

function fileResolver({ prev, payload }: FileResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createFile({ prev, payload });

    // case MutationType.Update:
    //   return updateFile({ prev, payload });

    case MutationType.Delete:
      return deleteFile({ prev, payload });

    default:
      return prev;
  }
}

export function fileFindUniqueFile(
  query: Pick<
    QueryResult<FindUniqueFileQuery, FindUniqueFileQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: file,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: FileSubscription } }
    ) =>
      fileResolver({
        prev,
        payload: payload.subscriptionData.data.file
      }),
    variables: query.variables,
  });
}
