import { gql } from "@apollo/client";
import { scenarioinsuranceFragment } from "./fragment";

export const scenarioinsurance = gql`
  subscription Scenarioinsurance($where: scenarioinsuranceWhereUniqueInput) {
    scenarioinsurance(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioinsurance
      }
      model_has_scenarioinsurance {
        model_ID
        scenarioinsurance_ID
      }
    }
  }
  ${scenarioinsuranceFragment}
`;
export const scenarioinsuranceMany = gql`
  subscription ScenarioinsuranceMany($where: scenarioinsuranceWhereInput) {
    scenarioinsuranceMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioinsurance
      }
      model_has_scenarioinsurance {
        model_ID
        scenarioinsurance_ID
      }
    }
  }
  ${scenarioinsuranceFragment}
`;
