import { QueryResult } from "@apollo/client";
import {
  AdviceSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { advice } from "../subscription";

interface AdviceResolverParams {
  prev: FindUniqueGroupQuery;
  payload: AdviceSubscription["advice"];
}

function createAdvice({ prev, payload }: AdviceResolverParams) {
  const advice = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice:
        advice.groups_ID === prev.findUniqueGroup.ID
          ? [advice, ...prev.findUniqueGroup.advice]
          : prev.findUniqueGroup.advice,
    },
  });
}

function updateAdvice({ prev, payload }: AdviceResolverParams) {
  const advice = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((data) =>
        data.ID === advice.ID ? { ...data, ...advice } : data
      ),
    },
  });
}

function deleteAdvice({ prev, payload }: AdviceResolverParams) {
  const advice = payload.data;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: [...prev.findUniqueGroup.advice].filter(
        (data) => data.ID !== advice.ID
      ),
    },
  });
}

function adviceResolver({ prev, payload }: AdviceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAdvice({ prev, payload });

    case MutationType.Update:
      return updateAdvice({ prev, payload });

    case MutationType.Delete:
      return deleteAdvice({ prev, payload });

    default:
      return prev;
  }
}

export function adviceFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: advice,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceSubscription } }
    ) =>
      adviceResolver({
        prev,
        payload: payload.subscriptionData.data.advice
      }),
    variables: query.variables,
  });
}
