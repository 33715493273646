// import { addressFindUniqueGroup } from "../../address";
import { adviceagreementFindUniqueGroup } from "../../adviceagreement";
// import { advicegoalsFindUniqueGroup } from "../../advicegoals";
import { annuityFindUniqueGroup } from "../../annuity";
import { assetallocationFindUniqueGroup } from "../../assetallocation";
import { cashFindUniqueGroup } from "../../cash";
// import { childFindUniqueGroup } from "../../child";
import { clientsFindUniqueGroup } from "../../clients";
import { collectiblesFindUniqueGroup } from "../../collectibles";
import { contributionswithdrawalsFindUniqueGroup } from "../../contributionswithdrawals";
// import { employmentFindUniqueGroup } from "../../employment";
import { entitiesFindUniqueGroup } from "../../entities";
// import { estateplanningFindUniqueGroup } from "../../estateplanning";
// import { feesFindUniqueGroup } from "../../fees";
// import { furtheradviceFindUniqueGroup } from "../../furtheradvice";
import { goalFindUniqueGroup } from "../../goal";
import { goalsFindUniqueGroup } from "../../goals";
import { groupsFindUniqueGroup } from "..";
import { incomeFindUniqueGroup } from "../../income";
// import { insuranceFindUniqueGroup } from "../../insurance";
import { interestFindUniqueGroup } from "../../interest";
import { loansFindUniqueGroup } from "../../loans";
import { notesFindUniqueGroup } from "../../notes";
import { offsetFindUniqueGroup } from "../../offset";
// import { opportunityFindUniqueGroup } from "../../opportunity";
import { otherassetsFindUniqueGroup } from "../../otherassets";
import { otherliabilitiesFindUniqueGroup } from "../../otherliabilities";
// import { phasesFindUniqueGroup } from "../../phases";
// import { projectsFindUniqueGroup } from "../../projects";
import { propertyFindUniqueGroup } from "../../property";
import { reviewagendaFindUniqueGroup } from "../../reviewagenda";
// import { salarypackagingFindUniqueGroup } from "../../salarypackaging";
import { sharesFindUniqueGroup } from "../../shares";
import { superannuationFindUniqueGroup } from "../../superannuation";
// import { tasksFindUniqueGroup } from "../../tasks";
import { teamFindUniqueGroup } from "../../teams";
import { vehiclesFindUniqueGroup } from "../../vehicles";
import { servicesFindUniqueGroup } from "../../services";
import { payeeFindUniqueGroup } from "../../payee";
import { modelFindUniqueGroup } from "../../model";
// import { documentFindUniqueGroup } from "../../document";
import { scenarioFindUniqueGroup } from "../../scenario";
import { adviceFindUniqueGroup } from "../../advice";
import { scenarioassetFindUniqueGroup } from "../../scenarioasset";
import { scenarioliabilityFindUniqueGroup } from "../../scenarioliability";
import { scenariocontributionwithdrawalFindUniqueGroup } from "../../scenariocontributionwithdrawal";
import { historyFindUniqueGroup } from "../../history";
import { scenarioclientFindUniqueGroup } from "../../scenarioclient";
import { scenarioinsuranceFindUniqueGroup } from "../../scenarioinsurance";
import { scenariochildFindUniqueGroup } from "../../scenariochild";
import {
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";
// import { staffFindCurrentStaff } from "./staff";

export function findUniqueGroupSubscription(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  // addressFindUniqueGroup(query);
  adviceagreementFindUniqueGroup(query);
  // advicegoalsFindUniqueGroup(query);
  annuityFindUniqueGroup(query);
  assetallocationFindUniqueGroup(query);
  cashFindUniqueGroup(query);
  collectiblesFindUniqueGroup(query);
  // childFindUniqueGroup(query);
  clientsFindUniqueGroup(query);
  contributionswithdrawalsFindUniqueGroup(query);
  // employmentFindUniqueGroup(query);
  entitiesFindUniqueGroup(query);
  // estateplanningFindUniqueGroup(query);
  // feesFindUniqueGroup(query);
  // furtheradviceFindUniqueGroup(query);
  goalFindUniqueGroup(query);
  goalsFindUniqueGroup(query);
  groupsFindUniqueGroup(query);
  historyFindUniqueGroup(query);
  incomeFindUniqueGroup(query);
  // insuranceFindUniqueGroup(query);
  interestFindUniqueGroup(query);
  loansFindUniqueGroup(query);
  notesFindUniqueGroup(query);
  offsetFindUniqueGroup(query);
  // opportunityFindUniqueGroup(query);
  otherassetsFindUniqueGroup(query);
  otherliabilitiesFindUniqueGroup(query);
  payeeFindUniqueGroup(query);
  // phasesFindUniqueGroup(query);
  // projectsFindUniqueGroup(query);
  propertyFindUniqueGroup(query);
  reviewagendaFindUniqueGroup(query);
  // Commented out for testing
  // salarypackagingFindUniqueGroup(query);
  servicesFindUniqueGroup(query);
  sharesFindUniqueGroup(query);
  /** Not necessary at the moment. Staff not on group object. */
  // staffFindCurrentStaff(query);
  superannuationFindUniqueGroup(query);
  // tasksFindUniqueGroup(query);
  vehiclesFindUniqueGroup(query);
  /** Statement of advice subscriptions */
  adviceFindUniqueGroup(query);
  // documentFindUniqueGroup(query);
  modelFindUniqueGroup(query);
  scenarioFindUniqueGroup(query);
  scenarioassetFindUniqueGroup(query);
  scenarioliabilityFindUniqueGroup(query);
  scenarioclientFindUniqueGroup(query);
  scenariocontributionwithdrawalFindUniqueGroup(query);
  scenarioinsuranceFindUniqueGroup(query);
  scenariochildFindUniqueGroup(query);
  teamFindUniqueGroup(query);
}
