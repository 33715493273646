import {
  FindUniqueAdviceQuery,
  FindUniqueAdviceQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";
import { QueryResult } from "@apollo/client";

interface NotesResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, advice_has_notes } = payload;

  if (!prev.findUniqueAdvice) return prev;
  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      notes: advice_has_notes.some(
        (relation) => relation.advice_ID === prev.findUniqueAdvice?.ID
      )
        ? [...prev.findUniqueAdvice.notes, notes]
        : prev.findUniqueAdvice.notes,
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueAdvice) return prev;
  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      notes: prev.findUniqueAdvice.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueAdvice) return prev;
  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      notes: prev.findUniqueAdvice.notes.filter((note) => note.ID !== notes.ID),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes,
      }),
    variables: query.variables,
  });
}
