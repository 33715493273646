import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";

export const collectiblesCoreFragment = gql`
  fragment collectiblesCore on collectiblesCore {
    ID
    assets_ID
    Type
    Value
    ReferenceNumber
    Notes
    OwnershipType
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const collectiblesFragment = gql`
  fragment collectibles on collectibles {
    ID
    assets_ID
    Type
    Value
    ReferenceNumber
    Notes
    OwnershipType
    create_time
    created_by
    update_time
    updated_by

    assetallocation {
      ...assetallocation
    }
    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${notesFragment}
  ${historyFragment}
`;
