import { gql } from "@apollo/client";
import { annuityCoreFragment, annuityFragment } from "../annuity";
import { cashCoreFragment, cashFragment } from "../cash";
import {
  collectiblesCoreFragment,
  collectiblesFragment,
} from "../collectibles";
import { offsetCoreFragment, offsetFragment } from "../offset/fragment";
import {
  otherassetsCoreFragment,
  otherassetsFragment,
} from "../otherassets/fragment";
import { propertyCoreFragment, propertyFragment } from "../property/fragment";
import { sharesCoreFragment, sharesFragment } from "../shares/fragment";
import {
  superannuationCoreFragment,
  superannuationFragment,
} from "../superannuation/fragment";
import { vehiclesCoreFragment, vehiclesFragment } from "../vehicles/fragment";

export const assetsCoreFragment = gql`
  fragment assetsCore on assetsCoreCUSTOM {
    annuity {
      ...annuityCore
    }

    cash {
      ...cashCore
    }

    collectibles {
      ...collectiblesCore
    }

    otherassets {
      ...otherassetsCore
    }

    offset {
      ...offsetCore
    }

    property {
      ...propertyCore
    }

    shares {
      ...sharesCore
    }

    superannuation {
      ...superannuationCore
    }

    vehicles {
      ...vehiclesCore
    }
  }
  ${annuityCoreFragment}
  ${cashCoreFragment}
  ${collectiblesCoreFragment}
  ${otherassetsCoreFragment}
  ${offsetCoreFragment}
  ${propertyCoreFragment}
  ${sharesCoreFragment}
  ${superannuationCoreFragment}
  ${vehiclesCoreFragment}
`;

export const assetsFragment = gql`
  fragment assets on assetsCUSTOM {
    annuity {
      ...annuity
    }

    cash {
      ...cash
    }

    collectibles {
      ...collectibles
    }

    otherassets {
      ...otherassets
    }

    offset {
      ...offset
    }

    property {
      ...property
    }

    shares {
      ...shares
    }

    superannuation {
      ...superannuation
    }

    vehicles {
      ...vehicles
    }
  }
  ${annuityFragment}
  ${cashFragment}
  ${collectiblesFragment}
  ${otherassetsFragment}
  ${offsetFragment}
  ${propertyFragment}
  ${sharesFragment}
  ${superannuationFragment}
  ${vehiclesFragment}
`;
