import React, { useContext } from "react";

import {
  CartesianGrid,
  Label,
  Legend,
  ComposedChart,
  Bar,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { StoreContext } from "../../../../../../../Datastore/Store";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";
import { InsuranceDeathComparisonDataReturn } from "../../../Calculations";

interface Props {
  comparisonData: InsuranceDeathComparisonDataReturn[] | undefined;
}

export const InsuranceDeathComparisonModelGraph: React.FC<Props> = ({
  comparisonData,
}) => {
  const [context] = useContext(StoreContext);

  if (!comparisonData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={comparisonData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />

        <XAxis
          interval={comparisonData.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="scenarioDataKey"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Scenarios"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>

        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>

        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />

        <ReferenceLine y={0} stroke="#000" />

        {comparisonData[0] &&
          comparisonData
            .reduce<Array<string>>(
              (accumulator, entry) => [
                ...new Set([
                  ...accumulator,
                  ...Object.keys(entry).filter(
                    (key) => key !== "scenarioDataKey"
                  ),
                ]),
              ],
              []
            )
            .reduce<Array<string[]>>((accumulator, key, index) => {
              if (index % 2 === 0) {
                accumulator.push([key]);
              } else {
                accumulator[accumulator.length - 1].push(key);
              }
              return accumulator;
            }, [])
            .flatMap((key, keyIndex) =>
              key.map((entry, entryIndex) => (
                <Bar
                  key={entry}
                  dataKey={entry}
                  fill={
                    context.colours[
                      Object.keys(context.colours)[
                        keyIndex === 1
                          ? keyIndex + 1 + entryIndex
                          : keyIndex + entryIndex
                      ]
                    ]
                  }
                  stackId={keyIndex}
                  isAnimationActive={false}
                  maxBarSize={50}
                />
              ))
            )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
