import { gql } from "@apollo/client";
import { clientsCoreFragment } from "./fragment";

export const findManyClients = gql`
  query FindManyClients(
    $where: clientsWhereInput
    $distinct: [ClientsScalarFieldEnum!]
    $take: Int
    $skip: Int
    $cursor: clientsWhereUniqueInput
  ) {
    findManyClients(
      where: $where
      distinct: $distinct
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...clientsCore
    }
  }
  ${clientsCoreFragment}
`;
