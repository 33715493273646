import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "../../../Components/Loaders/FullPageLoader";
import { Input } from "../../../Components/Inputs/Input";

import { Button } from "../../../Components/Buttons";
import {
  useResendOtpMutation,
  useVerifyOtpMutation,
} from "../../../codegen/schema";

export default function MFA({ email }: { email: string }) {
  document.title = "MOAS | Verify Login";
  const [otp, setOtp] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [resendCooldown, setResendCooldown] = useState(0); // 30 seconds cooldown
  const navigate = useNavigate();

  const [verifyOtp, { loading }] = useVerifyOtpMutation();
  const [resendOtp, { loading: resending }] = useResendOtpMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const { data } = await verifyOtp({
        variables: { email, OTP: otp },
      });

      if (data?.verifyOTP) {
        navigate("/home"); // Redirect to your application's main page
      }
    } catch (err: any) {
      setError(err.message || "Invalid OTP. Please try again.");
    }
  };

  // Handle Resend OTP
  const handleResendOtp = async () => {
    console.log("clicked");
    setError(null);

    try {
      await resendOtp({ variables: { email } });
    } catch (err: any) {
      setError(err.message || "Failed to resend OTP. Please try again.");
    }
  };

  // Cooldown timer
  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setTimeout(
        () => setResendCooldown(resendCooldown - 1),
        1000
      );
      return () => clearTimeout(timer);
    }
  }, [resendCooldown]);

  function renderLoading() {
    if (loading) {
      return <FullPageLoader />;
    }
  }
  return (
    <div className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-24 w-auto"
          src={
            process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
            "/website/logos/1200pxX500px_default.png"
          }
          alt="MOAS logo"
        />
        <h2 className="mt-8 text-center text-2xl font-display font-bold leading-9 tracking-tight text-neutral-800">
          Please check your email
        </h2>
        <h5 className="text-center">
          We've sent a one time code to your email.
        </h5>
      </div>
      {renderLoading()}

      <div className="flex flex-col items-center">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-neutral-500"
              >
                Enter Code
              </label>
              <Input
                id={"otp"}
                name="otp"
                type="number"
                defaultValue={otp}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setOtp(event.target.value)
                }
                required
              />
              <div className="flex justify-center mt-2 ">
                {resending ? (
                  <h5>
                    Resending{" "}
                    <i className="icon text-semantic-info-100 fa-regular fa-circle-loading" />
                  </h5>
                ) : resendCooldown > 0 ? (
                  <h5 className="">Resend code in {resendCooldown}s</h5>
                ) : (
                  <>
                    <h5 className="">Didn't receive a code? </h5>{" "}
                    <h5
                      className="text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                      onClick={() => {
                        if (!resending && resendCooldown === 0) {
                          setResendCooldown(30);
                          handleResendOtp();
                        }
                      }}
                    >
                      Resend
                    </h5>
                  </>
                )}
              </div>
            </div>

            {error && (
              <div className="flex gap-2 itens-center">
                <i className="icon text-semantic-error-500 fa-regular fa-circle-exclamation" />
                <h5 className="text-semantic-error-500">
                  Invalid code. Please check your email and try again.
                </h5>
              </div>
            )}

            <Button
              type="alt"
              loading={loading}
              buttontype="button"
              onClick={() => window.location.reload()}
              text="Cancel"
            />
            <Button
              type="default"
              loading={loading}
              buttontype="submit"
              text="Verify"
            />
          </form>
        </div>
      </div>
    </div>
  );
}
