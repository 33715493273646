import React, { useContext } from "react";

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { LiveData } from "../../../Calculations";
import { StoreContext } from "../../../../../../../Datastore/Store";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Client {
  ID: number;
  FirstName: string | undefined | null;
  LastName: string | undefined | null;
}

interface Props {
  liveData: LiveData[] | undefined;
  groupClients: Client[];
}

export const GrossNetIncomeLiveModelGraph: React.FC<Props> = ({
  liveData,
  groupClients,
}) => {
  const [context] = useContext(StoreContext);

  if (!liveData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={liveData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />
        <XAxis
          xAxisId="forward"
          interval={liveData.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>
        {/* Using second xAxis to render bars on top of each other (not stacked but layered) */}
        <XAxis
          xAxisId="behind"
          interval={liveData.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          hide={true}
        />

        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            offset={-30}
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />
        <ReferenceLine y={0} stroke="#000" xAxisId="forward" />

        {groupClients.length > 1 && (
          <Line
            xAxisId="behind"
            dataKey={"Combined Net Income"}
            stroke={context.colours.purple500}
            isAnimationActive={false}
            dot={{
              strokeWidth: 1,
              r: 4,
            }}
          />
        )}

        {groupClients.map((client, index) => {
          return (
            <React.Fragment key={client.ID}>
              <Bar
                xAxisId="behind"
                dataKey={client.FirstName + " " + client.LastName + " Gross"}
                fill={
                  context.coloursTaxGraphsGross[
                    Object.keys(context.coloursTaxGraphsGross)[index]
                  ]
                }
                isAnimationActive={false}
                maxBarSize={50}
              />

              <Bar
                xAxisId="forward"
                dataKey={client.FirstName + " " + client.LastName + " Taxable"}
                stackId={client.ID}
                fill={
                  context.coloursTaxGraphTaxable[
                    Object.keys(context.coloursTaxGraphTaxable)[index]
                  ]
                }
                isAnimationActive={false}
                maxBarSize={50}
              />

              <Bar
                xAxisId="forward"
                dataKey={client.FirstName + " " + client.LastName + " Tax"}
                stackId={client.ID}
                fill={
                  context.coloursTaxGraphsTax[
                    Object.keys(context.coloursTaxGraphsTax)[index]
                  ]
                }
                isAnimationActive={false}
                maxBarSize={50}
              />
            </React.Fragment>
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
