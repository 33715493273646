import { gql } from "@apollo/client";
import { groupsFragment } from ".";
import { groupsCoreFragment } from "./fragment";

export const createOneGroup = gql`
  mutation CreateOneGroups($data: groupsCreateInput!) {
    createOneGroups(data: $data) {
      ...groups
    }
  }
  ${groupsFragment}
`;

export const updateOneGroup = gql`
  mutation UpdateOneGroups(
    $data: groupsUpdateInput!
    $where: groupsWhereUniqueInput!
  ) {
    updateOneGroups(data: $data, where: $where) {
      ...groups
    }
  }
  ${groupsFragment}
`;

export const deleteOneGroup = gql`
  mutation DeleteOneGroups($where: groupsWhereUniqueInput!) {
    deleteOneGroups(where: $where) {
      ...groupsCore
    }
  }
  ${groupsCoreFragment}
`;
