import { gql } from "@apollo/client";

export const scenariochildCoreFragment = gql`
  fragment scenariochildCore on scenariochild {
    ID
    scenario_ID
    scenario_advice_ID
    scenario_advice_groups_ID
    scenario_advice_groups_organisation_ID
    
    Name
    Dependant
    DependantToAge
    DOB
    Gender
    EducationCostPerYear
    OtherCostPerYear
    Indexation

    child_ID
    
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const scenariochildFragment = gql`
  fragment scenariochild on scenariochild {
    ...scenariochildCore
  }
  ${scenariochildCoreFragment}
`;
