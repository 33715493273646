import React, { FormEvent, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../Components/Buttons";
import { Input } from "../../Components/Inputs/Input";
import { useResetPasswordMutation } from "../../codegen/schema";
import clsx from "clsx";
import { ResetData, passwordMatch, validateEmail } from "../Functions";
import { StoreContext } from "../../Datastore/Store";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [, dispatch] = useContext(StoreContext);
  const [confirmation, setConfirmation] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setInvalidEmail(false);
    setPasswordMatchError(false);
    setConfirmation(false);

    var data: ResetData = {
      email: "",
      password: "",
      confirmPassword: "",
      reset_token: token,
    };
    var inputs = Array.from(document.getElementsByTagName("input"));

    inputs.forEach((input) => {
      const key = input.name as keyof ResetData;
      if (key in data) {
        data[key] = input.value;
      }
    });

    // Validate email
    if (!validateEmail(data.email)) {
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
    }

    // Validate passwords
    if (!passwordMatch(data)) {
      setPasswordMatchError(true);
      return;
    } else {
      setPasswordMatchError(false);
    }

    // If both valid run reset password mutation
    if (
      validateEmail(data.email) &&
      passwordMatch(data) &&
      data.email &&
      token
    ) {
      resetPasswordMutation({
        variables: { ...data, email: data.email, reset_token: token },
      })
        .then(() => {
          setConfirmation(true);
          dispatch({
            type: "snackbar/success",
            payload: "Password reset successful!",
          });
          navigate("../../login");
        })
        .catch((error) =>
          dispatch({
            type: "snackbar/error",
            payload: error.message,
          })
        );
    }
  }

  return (
    <div className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-24 w-auto"
          src={
            process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
            "/website/logos/1200pxX500px_default.png"
          }
          alt="MOAS logo"
        />
        <h2 className="mt-8 text-center text-2xl font-display font-bold leading-9 tracking-tight text-neutral-800">
          Reset password
        </h2>
      </div>

      <div className="flex flex-col items-center">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <h5 className="text-center">
            Please enter your email address and confirm you new password.
          </h5>
          <form
            className="space-y-6"
            onSubmit={(event) => handleSubmit(event)}
            id="Forgot Password"
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-neutral-500"
              >
                Email address
              </label>
              <Input name="email" id="email" required />
            </div>
            <div className="relative">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-neutral-500"
              >
                New password
              </label>
              <Input
                name="password"
                id="password"
                type={showPasswordNew ? "text" : "password"}
                required
              />
              <div
                className={clsx(
                  showPasswordNew ? "fa-lock-open" : "fa-lock",
                  "icon fa-regular text-neutral-500 absolute cursor-pointer right-2 top-12"
                )}
                onClick={() => setShowPasswordNew(!showPasswordNew)}
              />
            </div>

            <div className="relative">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium leading-6 text-neutral-500"
              >
                Confirm password
              </label>
              <Input
                name="confirmPassword"
                id="confirmPassword"
                type={showPasswordConfirm ? "text" : "password"}
                required
              />
              <div
                className={clsx(
                  showPasswordConfirm ? "fa-lock-open" : "fa-lock",
                  "icon fa-regular text-neutral-500 absolute cursor-pointer right-2 top-12"
                )}
                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              />
            </div>

            {invalidEmail && (
              <div className="flex gap-2 itens-center">
                <i className="icon text-semantic-error-500 fa-regular fa-circle-exclamation" />
                <h5 className="text-semantic-error-500">Invalid email.</h5>
              </div>
            )}

            {passwordMatchError && (
              <div className="flex gap-2 itens-center">
                <i className="icon text-semantic-error-500 fa-regular fa-circle-exclamation" />
                <h5 className="text-semantic-error-500">
                  Passwords do not match.
                </h5>
              </div>
            )}

            <Button
              type="default"
              text={
                confirmation === false ? (
                  "Reset Password"
                ) : (
                  <Link to="/login" className="colour-white">
                    Take me to the Login page!
                  </Link>
                )
              }
              buttontype="submit"
              loading={loading}
              disabled={loading}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
