import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";

export const superannuationCoreFragment = gql`
  fragment superannuationCore on superannuationCore {
    ID
    assets_ID
    FundName
    Type
    SuperAuthority
    MemberNumber
    AnnualRollOver
    RetainForInsurance
    Value
    OwnershipType
    MinimumPension
    MaximumPension
    PensionIncome
    MinimumCashHolding

    AnnualFeeType
    AnnualFeeValue
    AnnualInsurancePremium
    InsuranceIndexation

    create_time
    created_by
    update_time
    updated_by
  }
`;

export const superannuationFragment = gql`
  fragment superannuation on superannuation {
    ID
    assets_ID
    FundName
    Type
    SuperAuthority
    MemberNumber
    AnnualRollOver
    RetainForInsurance
    Value
    OwnershipType
    MinimumPension
    MaximumPension
    PensionIncome
    MinimumCashHolding

    AnnualFeeType
    AnnualFeeValue
    AnnualInsurancePremium
    InsuranceIndexation

    create_time
    created_by
    update_time
    updated_by

    assetallocation {
      ...assetallocation
    }

    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${notesFragment}
  ${historyFragment}
`;
