import React, { useEffect, useState } from "react";
import {
  Scenario,
  Scenarioclient_Type,
  Scenarioinsurance_Type,
  Theme,
} from "../../../../../codegen/schema";
import { StyledText } from "../../../StyledText";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";
import { ScenarioclientIncomeTax } from "../../../Models/Utils/Calculations/Scenarioclient";
import { ClientIncome, IncomeRows } from "..";
import CalculationDescription from "../Description";
import { Tooltip } from "../../../../Tooltip";

interface ClientsIncomeMap extends Scenario {
  clientsIncome: ClientIncome[];
}

/**
 * Returns income and cashflow component.
 *
 * NOTE: Net income and calculated surplus are adjusted with salary packaging
 * as directed by Wealthmed.
 */
export const CashflowComparison = ({
  scenarios,
  theme,
}: {
  scenarios: Scenario[];
  theme?: Theme;
}) => {
  const [clientsIncomeMap, setClientsIncomeMap] = useState<
    Array<ClientsIncomeMap>
  >([]);

  useEffect(() => {
    setClientsIncomeMap(
      scenarios.map((scenario) => ({
        ...scenario,
        clientsIncome: scenario.scenarioclient
          .filter(
            (scenarioclient) =>
              scenarioclient.Type === Scenarioclient_Type.Client
          )
          .map((scenarioclient) => ({
            ID: scenarioclient.ID,
            Name: scenarioclient.Name,
            PayDIV293TaxFromIncome: scenarioclient.PayDIV293TaxFromIncome,
            PayExcessConcessionalContributionsTaxFromIncome:
              scenarioclient.PayExcessConcessionalContributionsTaxFromIncome,
            ...ScenarioclientIncomeTax({
              scenarioclient,
              scenario: scenario!,
            }),
          })),
      }))
    );
  }, [scenarios]);

  return (
    <React.Fragment>
      <table className={`w-full`}>
        <thead>
          <tr>
            {/* HEADERS */}
            <th className="py-0">
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H5"
                )}
                className="text-left"
              >
                Income & cashflow
              </StyledText>
            </th>
            {/* Scenario names */}
            {scenarios.map((scenario) => (
              <th className="py-0" key={scenario.ID}>
                <div className="flex items-center gap-2 ">
                  <div
                    className="icon fa-regular fa-memo"
                    style={{
                      color: theme?.colour[0]?.Value ?? "#000000",
                    }}
                  />
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {scenario.Title}
                  </StyledText>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-neutral-100">
          {/* ROWS */}
          {/* INCOME */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={scenarios.length + 1}
            >
              Income
            </th>
          </tr>
          {/* SALARY */}

          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.Salary,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.gross -
                                    client.income.rental -
                                    client.superannuation
                                      .excessConcessionalContributions
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.Salary}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.gross -
                              client.income.rental -
                              client.superannuation
                                .excessConcessionalContributions),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>

          {/* EXCESS CONCESSIONAL CONTRIBUTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.ExcessConcessionalContributions,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.superannuation
                                    .excessConcessionalContributions
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.ExcessConcessionalContributions}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.superannuation
                                .excessConcessionalContributions),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* PENSION */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.Pension,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(client.income.pension),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.Pension}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator += client.income.pension),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>

          {/* RENTAL INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.RentalIncome,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>

                            {client.rentalProperties.map((property) => (
                              <p>
                                {property.text}:{" "}
                                {checkFieldIsEmpty(
                                  Math.round(property.income),
                                  "dollar"
                                )}
                              </p>
                            ))}
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(client.income.rental),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                      {/* RENTAL INCOME LIST */}
                      {/* {clientsIncomeMap
                        .reduce<Array<string>>((accumulator, scenario) => {
                          scenario.clientsIncome.forEach((scenarioclientTax) =>
                            scenarioclientTax.rentalProperties.forEach(
                              (entry) => accumulator.push(entry.text)
                            )
                          );

                          return [...new Set(accumulator)];
                        }, [])
                        .map((rentalName) => (
                          <tr className="hover:bg-neutral-50" key={rentalName}>
                            <td className="py-0">
                              <div className="flex items-center gap-2">
                                <StyledText
                                  element={theme?.element.find(
                                    (element) => element.Type === "P"
                                  )}
                                >
                                  {rentalName}
                                </StyledText>
                              </div>
                            </td>
                            {clientsIncomeMap.map((scenario) => {
                              var totalRental = scenario.clientsIncome.reduce(
                                (accumulator, clientIncome) => {
                                  var rental =
                                    clientIncome.rentalProperties.find(
                                      (entry) => entry.text === rentalName
                                    );
                                  return (accumulator += rental
                                    ? rental.income
                                    : 0);
                                },
                                0
                              );

                              return (
                                <td className="py-0">
                                  <div className="flex items-center gap-2">
                                    <StyledText
                                      element={theme?.element.find(
                                        (element) => element.Type === "P"
                                      )}
                                    >
                                      {checkFieldIsEmpty(
                                        Math.round(totalRental),
                                        "dollar"
                                      )}
                                    </StyledText>
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        ))} */}
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.RentalIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator += client.income.rental),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* GROSS INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.GrossIncome,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(client.income.gross),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.GrossIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator += client.income.gross),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* NON-CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={scenarios.length + 1}
            >
              Non-cash tax deductions
            </th>
          </tr>
          {/* PROPERTY NON CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.PropertyNonCashTaxDed,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2 gap-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            {client.rentalProperties.map((property) => (
                              <p>
                                {property.text}:{" "}
                                {checkFieldIsEmpty(
                                  Math.round(property.nonCashTaxDeductions),
                                  "dollar"
                                )}
                              </p>
                            ))}
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions.rentalPropertyNonCash
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.PropertyNonCashTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions.rentalPropertyNonCash),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>

          {/* CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={scenarios.length + 1}
            >
              Cash tax deductions
            </th>
          </tr>
          {/* EXCESS CONCESSIONAL CONTRIBUTIONS TAX OFFSET */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.ExcessConcessionalContributionsTaxOffset,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.tax
                                    .excessConcessionalContributionsTaxOffset
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.ExcessConcessionalContributionsTaxOffset}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.tax
                                .excessConcessionalContributionsTaxOffset),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* INVESTMENT PROPERTY INTEREST TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.PropertyInterestTaxDed,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2 gap-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            {client.deductibleLoans.map((loan) => (
                              <p>
                                {loan.text}:{" "}
                                {checkFieldIsEmpty(
                                  Math.round(loan.interest),
                                  "dollar"
                                )}
                              </p>
                            ))}
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions
                                    .rentalPropertyInterest
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.PropertyInterestTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions.rentalPropertyInterest),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* INVESTMENT PROPERTY EXPENSES TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.PropertyExpensesTaxDed,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2 gap-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            {client.rentalProperties.map((property) => (
                              <p>
                                {property.text}:{" "}
                                {checkFieldIsEmpty(
                                  Math.round(property.expenses),
                                  "dollar"
                                )}
                              </p>
                            ))}
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions
                                    .rentalPropertyExpenses
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.PropertyExpensesTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions.rentalPropertyExpenses),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* TOTAL PROPERTY TAX DEDUCTIONS */}
          {/* <tr className="hover:bg-neutral-50"> */}
          {/* <td className="py-0">
              <div className="flex items-center gap-2">
                {!location.pathname.includes("document") &&
                  CalculationDescription(IncomeRows.PropertyTaxDed)}

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {IncomeRows.PropertyTaxDed}
                </StyledText>
              </div>
            </td> */}
          {/* {scenarios.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.scenarioclient
                          .filter(
                            (scenarioclient) =>
                              scenarioclient.Type === Scenarioclient_Type.Client
                          )
                          .map((scenarioclient) => ({
                            ID: scenarioclient.ID,
                            Name: scenarioclient.Name,
                            ...ScenarioclientIncomeTax({
                              scenarioclient,
                              scenario: scenario!,
                            }),
                          }))
                          .reduce(
                            (accumulator, client) =>
                              (accumulator +=
                                client.income.deductions
                                  .rentalPropertyInterest +
                                client.income.deductions
                                  .rentalPropertyExpenses),
                            0
                          )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))} */}
          {/* </tr> */}
          {/* SALARY SACRIFICING CONTRIBUTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.SalarySacrificeCont,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions.salarySacrifice
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.SalarySacrificeCont}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions.salarySacrifice),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* SALARY PACKAGING */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.SalaryPackaging,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions.salaryPackage
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.SalaryPackaging}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions.salaryPackage),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* INCOME PROTECTION INSURANCE */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.IncomeProtection,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            {client.insurances
                              .filter(
                                (insurance) =>
                                  insurance.type ===
                                  Scenarioinsurance_Type.IncomeProtection
                              )
                              .map((insurance) => (
                                <p>
                                  {insurance.text}:{" "}
                                  {checkFieldIsEmpty(
                                    Math.round(insurance.preimumPerYear),
                                    "dollar"
                                  )}
                                </p>
                              ))}
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions
                                    .incomeProtectionInsurance
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.IncomeProtection}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions
                                .incomeProtectionInsurance),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>

          {/* INCOME AND TAX */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={scenarios.length + 1}
            >
              Income and tax
            </th>
          </tr>
          {/* ASSESSABLE INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.AssessableIncome,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(client.totals.taxableIncome),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.AssessableIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator += client.totals.taxableIncome),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ESTIMATED INCOME TAX */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedIncomeTax,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              Income tax:{" "}
                              {checkFieldIsEmpty(
                                Math.round(client.tax.income),
                                "dollar"
                              )}
                            </p>
                            <p>
                              Medicare levy:{" "}
                              {checkFieldIsEmpty(
                                Math.round(client.tax.medicareLevy),
                                "dollar"
                              )}
                            </p>
                            <p>
                              Study repayments:{" "}
                              {checkFieldIsEmpty(
                                Math.round(client.tax.studyRepayments),
                                "dollar"
                              )}
                            </p>
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(client.totals.taxPayable),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedIncomeTax}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator += client.totals.taxPayable),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ESTIMATED SUPERANNUATION TAX PAYABLE FROM INCOME*/}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.SuperannuationTax,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              DIV293 tax:{" "}
                              {checkFieldIsEmpty(
                                Math.round(client.tax.div293),
                                "dollar"
                              )}
                            </p>
                            <p>
                              ECC tax:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.tax.excessConcessionalContributions
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.SuperannuationTax}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              (client.PayDIV293TaxFromIncome === 0
                                ? client.tax.div293
                                : 0) +
                              (client.PayExcessConcessionalContributionsTaxFromIncome ===
                              0
                                ? client.tax.excessConcessionalContributions
                                : 0)),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ADD BACK NON CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.AddBackNonCashTaxDed,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              Property non-cash:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions.rentalPropertyNonCash
                                ),
                                "dollar"
                              )}
                            </p>
                            <p>
                              Salary packaging:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions.salaryPackage
                                ),
                                "dollar"
                              )}
                            </p>
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.deductions
                                    .rentalPropertyNonCash +
                                    client.income.deductions.salaryPackage
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.AddBackNonCashTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions.rentalPropertyNonCash +
                              client.income.deductions.salaryPackage),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ESTIMATED NET INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedNetIncome,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.totals.netIncome +
                                    client.income.deductions.salaryPackage +
                                    client.income.deductions
                                      .rentalPropertyNonCash +
                                    (client.PayDIV293TaxFromIncome === 0
                                      ? client.tax.div293
                                      : 0) +
                                    (client.PayExcessConcessionalContributionsTaxFromIncome ===
                                    0
                                      ? client.tax
                                          .excessConcessionalContributions
                                      : 0)
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedNetIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.totals.netIncome +
                              client.income.deductions.salaryPackage +
                              client.income.deductions.rentalPropertyNonCash +
                              (client.PayDIV293TaxFromIncome === 0
                                ? client.tax.div293
                                : 0) +
                              (client.PayExcessConcessionalContributionsTaxFromIncome ===
                              0
                                ? client.tax.excessConcessionalContributions
                                : 0)),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>

          {/* AFTER TAX EXPENSES */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={scenarios.length + 1}
            >
              After tax expenses
            </th>
          </tr>
          {/* ESTIMATED LIVING EXPENSES */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedExpenses,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            <p>
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.income.estimatedAnnualExpenses
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedExpenses}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.estimatedAnnualExpenses),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ESTIMATED INSURANCE PREMIUMS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedInsurance,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        {scenario.clientsIncome.map((client) => (
                          <div>
                            <p>{client.Name}</p>
                            {client.insurances
                              .filter(
                                (insurance) =>
                                  insurance.type !==
                                  Scenarioinsurance_Type.IncomeProtection
                              )
                              .map((insurance) => (
                                <p>
                                  {insurance.text}:{" "}
                                  {checkFieldIsEmpty(
                                    Math.round(insurance.preimumPerYear),
                                    "dollar"
                                  )}
                                </p>
                              ))}
                            <p className="font-bold">
                              Total:{" "}
                              {checkFieldIsEmpty(
                                Math.round(
                                  client.insurances
                                    .filter(
                                      (insurance) =>
                                        insurance.type !==
                                        Scenarioinsurance_Type.IncomeProtection
                                    )
                                    .reduce(
                                      (accumulator, insurance) =>
                                        (accumulator +=
                                          insurance.preimumPerYear),
                                      0
                                    )
                                ),
                                "dollar"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedInsurance}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator += client.insurances
                              .filter(
                                (insurance) =>
                                  insurance.type !==
                                  Scenarioinsurance_Type.IncomeProtection
                              )
                              .reduce(
                                (insuranceAccumulator, insurance) =>
                                  (insuranceAccumulator +=
                                    insurance.preimumPerYear),
                                0
                              )),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ESTIMATED LOAN REPAYMENTS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedCombinedRepayments,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        <div>
                          <p>
                            Total:{" "}
                            {checkFieldIsEmpty(
                              -Math.round(
                                scenario.clientsIncome.reduce(
                                  (accumulator, client) =>
                                    (accumulator =
                                      client.totals.combinedLoanRepayments),
                                  0
                                ) -
                                  scenario.clientsIncome.reduce(
                                    (accumulator, client) =>
                                      (accumulator +=
                                        client.income.deductions
                                          .rentalPropertyInterest),
                                    0
                                  )
                              ),
                              "dollar"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedCombinedRepayments}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator =
                              client.totals.combinedLoanRepayments),
                          0
                        ) -
                          scenario.clientsIncome.reduce(
                            (accumulator, client) =>
                              (accumulator +=
                                client.income.deductions
                                  .rentalPropertyInterest),
                            0
                          )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ESTIMATED ASSET CONTRIBUTIONS FROM INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedCombinedAssetContributionsFromIncome,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        <div>
                          <p>
                            Total:{" "}
                            {checkFieldIsEmpty(
                              -Math.round(
                                scenario.clientsIncome.reduce(
                                  (accumulator, client) =>
                                    (accumulator =
                                      client.totals
                                        .combinedScenarioassetContributionsFromIncome),
                                  0
                                )
                              ),
                              "dollar"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedCombinedAssetContributionsFromIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator =
                              client.totals
                                .combinedScenarioassetContributionsFromIncome),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
          {/* ESTIMATED LIABILITY CONTRIBUTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedCombinedLiabilityContributions,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        <div>
                          <p>
                            Total:{" "}
                            {checkFieldIsEmpty(
                              -Math.round(
                                scenario.clientsIncome.reduce(
                                  (accumulator, client) =>
                                    (accumulator =
                                      client.totals
                                        .combinedScenarioliabilityContributions),
                                  0
                                )
                              ),
                              "dollar"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedCombinedLiabilityContributions}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator =
                              client.totals
                                .combinedScenarioliabilityContributions),
                          0
                        )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>

          {/* ESTIMATED SURPLUS */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={scenarios.length + 1}
            >
              Estimated surplus
            </th>
          </tr>
          {/* CALCULATED SURPLUS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.CalculatedSurplus,
                  clientsIncomeMap.map((scenario) => (
                    <div className="mt-2">
                      <h5>{scenario.Title}</h5>
                      <div
                        className={`grid grid-cols-${scenario.clientsIncome.length} gap-2`}
                      >
                        <div>
                          <p>
                            Total:{" "}
                            {checkFieldIsEmpty(
                              Math.round(
                                scenario.clientsIncome.reduce(
                                  (accumulator, client) =>
                                    (accumulator +=
                                      client.totals.surplus +
                                      client.income.deductions.salaryPackage +
                                      client.income.deductions
                                        .rentalPropertyNonCash +
                                      client.income.deductions
                                        .rentalPropertyInterest +
                                      (client.PayDIV293TaxFromIncome === 0
                                        ? client.tax.div293
                                        : 0) +
                                      (client.PayExcessConcessionalContributionsTaxFromIncome ===
                                      0
                                        ? client.tax
                                            .excessConcessionalContributions
                                        : 0) -
                                      client.insurances
                                        .filter(
                                          (insurance) =>
                                            insurance.type !==
                                            Scenarioinsurance_Type.IncomeProtection
                                        )
                                        .reduce(
                                          (insuranceAccumulator, insurance) =>
                                            (insuranceAccumulator +=
                                              insurance.preimumPerYear),
                                          0
                                        ) -
                                      (client.totals.combinedLoanRepayments +
                                        client.totals
                                          .combinedScenarioassetContributionsFromIncome +
                                        client.totals
                                          .combinedScenarioliabilityContributions) /
                                        scenario.scenarioclient.filter(
                                          (scenarioclient) =>
                                            scenarioclient.Type ===
                                            Scenarioclient_Type.Client
                                        ).length),
                                  0
                                )
                              ),
                              "dollar"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.CalculatedSurplus}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {clientsIncomeMap.map((scenario) => (
              <td className="py-0">
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        scenario.clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.totals.surplus +
                              client.income.deductions.salaryPackage +
                              client.income.deductions.rentalPropertyNonCash +
                              client.income.deductions.rentalPropertyInterest +
                              (client.PayDIV293TaxFromIncome === 0
                                ? client.tax.div293
                                : 0) +
                              (client.PayExcessConcessionalContributionsTaxFromIncome ===
                              0
                                ? client.tax.excessConcessionalContributions
                                : 0) -
                              client.insurances
                                .filter(
                                  (insurance) =>
                                    insurance.type !==
                                    Scenarioinsurance_Type.IncomeProtection
                                )
                                .reduce(
                                  (insuranceAccumulator, insurance) =>
                                    (insuranceAccumulator +=
                                      insurance.preimumPerYear),
                                  0
                                ) -
                              (client.totals.combinedLoanRepayments +
                                client.totals
                                  .combinedScenarioassetContributionsFromIncome +
                                client.totals
                                  .combinedScenarioliabilityContributions) /
                                scenario.scenarioclient.filter(
                                  (scenarioclient) =>
                                    scenarioclient.Type ===
                                    Scenarioclient_Type.Client
                                ).length),
                          0
                        )
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
