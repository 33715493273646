import { QueryResult } from "@apollo/client";
import {
  ServicesSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { services } from "../subscription";

interface ServicesResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ServicesSubscription["services"];
}

function createServices({ prev, payload }: ServicesResolverParams) {
  const services = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement: prev.findUniqueGroup.adviceagreement.map((agreement) =>
        services.adviceagreement_ID === agreement.ID
          ? {
              ...agreement,
              services: [...agreement.services, services],
            }
          : agreement
      ),
    },
  });
}

function updateServices({ prev, payload }: ServicesResolverParams) {
  const services = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement: prev.findUniqueGroup.adviceagreement.map((agreement) => {
        return {
          ...agreement,
          services: agreement.services.map((service) =>
            service.ID === services.ID ? { ...service, ...services } : service
          ),
        };
      }),
    },
  });
}

function deleteServices({ prev, payload }: ServicesResolverParams) {
  const services = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement: prev.findUniqueGroup.adviceagreement.map((agreement) => {
        return {
          ...agreement,
          services:
            services.adviceagreement_ID === agreement.ID
              ? agreement.services.filter(
                  (service) => service.ID !== services.ID
                )
              : agreement.services,
        };
      }),
    },
  });
}

function servicesResolver({ prev, payload }: ServicesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createServices({ prev, payload });

    case MutationType.Update:
      return updateServices({ prev, payload });

    case MutationType.Delete:
      return deleteServices({ prev, payload });

    default:
      return prev;
  }
}

export function servicesFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: services,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ServicesSubscription } }
    ) =>
      servicesResolver({
        prev,
        payload: payload.subscriptionData.data.services,
      }),
    variables: query.variables,
  });
}
