import { QueryResult } from "@apollo/client";
import {
  SuperannuationSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { superannuation } from "../subscription";

interface SuperannuationResolverParams {
  prev: FindUniqueGroupQuery;
  payload: SuperannuationSubscription["superannuation"];
}

function createSuperannuation({ prev, payload }: SuperannuationResolverParams) {
  const { data: createSuperannuation, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            superannuation: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.superannuation, createSuperannuation]
              : client.assets.superannuation,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            superannuation: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.superannuation, createSuperannuation]
              : entity.assets.superannuation,
          },
        };
      }),
    },
  });
}

function updateSuperannuation({ prev, payload }: SuperannuationResolverParams) {
  const { data: updateSuperannuation, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the superannuation object
   * This is because updateSuperannuation only returns the superannuation info
   * without assetallocation, notes, etc.
   */
  var updatedSuperannuation = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.superannuation.forEach((superannuation) => {
      if (superannuation.assets_ID === updateSuperannuation.assets_ID) {
        result = {
          ...superannuation,
          ...updateSuperannuation,
        };
      }
    });
    return result;
  }, updateSuperannuation);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update superannuation first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          superannuation: [
            ...client.assets.superannuation
              .map((superannuation) =>
                superannuation.ID === updatedSuperannuation.ID
                  ? { ...superannuation, ...updatedSuperannuation }
                  : superannuation
              )
              .filter(
                (superannuation) =>
                  ![...data.clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(superannuation.assets_ID)
              ),
            ...(data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? data.clients_has_assets.reduce<
                  Array<SuperannuationSubscription["superannuation"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedSuperannuation.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedSuperannuation];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    superannuation: liability.assets.superannuation.map(
                      (superannuation) =>
                        superannuation.ID === updateSuperannuation.ID
                          ? {
                              ...superannuation,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateSuperannuation,
                            }
                          : superannuation /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            superannuation: goal.assets.superannuation.map((superannuation) =>
              superannuation.ID === updatedSuperannuation.ID
                ? { ...superannuation, ...updatedSuperannuation }
                : superannuation
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      superannuation: liability.assets.superannuation.map(
                        (superannuation) =>
                          superannuation.ID === updateSuperannuation.ID
                            ? {
                                ...superannuation,
                                /** Update loan info */
                                ...updateSuperannuation,
                              }
                            : superannuation
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing superannuation object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          superannuation: [
            ...entity.assets.superannuation
              .map((superannuation) =>
                superannuation.ID === updatedSuperannuation.ID
                  ? { ...superannuation, ...updatedSuperannuation }
                  : superannuation
              )
              .filter(
                (superannuation) =>
                  ![...data.entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(superannuation.assets_ID)
              ),
            ...(data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? data.entities_has_assets.reduce<
                  Array<SuperannuationSubscription["superannuation"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedSuperannuation.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedSuperannuation];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    superannuation: liability.assets.superannuation.map(
                      (superannuation) =>
                        superannuation.ID === updateSuperannuation.ID
                          ? {
                              ...superannuation,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateSuperannuation,
                            }
                          : superannuation /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteSuperannuation({ prev, payload }: SuperannuationResolverParams) {
  const { data: deleteSuperannuation } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            superannuation: [...client.assets.superannuation].filter(
              (superannuation) => superannuation.ID !== deleteSuperannuation.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      superannuation: liability.assets.superannuation.filter(
                        (superannuation) =>
                          superannuation.ID !== deleteSuperannuation.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              superannuation: goal.assets.superannuation.filter(
                (superannuation) =>
                  superannuation.ID !== deleteSuperannuation.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        superannuation: liability.assets.superannuation.filter(
                          (superannuation) =>
                            superannuation.ID !== deleteSuperannuation.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            superannuation: [...entity.assets.superannuation].filter(
              (superannuation) => superannuation.ID !== deleteSuperannuation.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      superannuation: liability.assets.superannuation.filter(
                        (superannuation) =>
                          superannuation.ID !== deleteSuperannuation.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function superannuationResolver({
  prev,
  payload,
}: SuperannuationResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createSuperannuation({ prev, payload });

    case MutationType.Update:
      return updateSuperannuation({ prev, payload });

    case MutationType.Delete:
      return deleteSuperannuation({ prev, payload });

    default:
      return prev;
  }
}

export function superannuationFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: superannuation,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: SuperannuationSubscription } }
    ) =>
      superannuationResolver({
        prev,
        payload: payload.subscriptionData.data.superannuation,
      }),
    variables: query.variables,
  });
}
