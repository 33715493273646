import { Editor } from "slate";
import { CustomText } from "../types";

export const toggleMark = ({
  editor,
  format,
}: {
  editor: Editor;
  format: keyof Omit<CustomText, "text">;
}) => {
  const isActive = isMarkActive({ editor, format });

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isMarkActive = ({
  editor,
  format,
}: {
  editor: Editor;
  format: keyof Omit<CustomText, "text">;
}) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
