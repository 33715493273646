import { gql } from "@apollo/client";
import { contributionswithdrawalsFragment } from ".";

export const createOneContributionswithdrawals = gql`
  mutation CreateOneContributionswithdrawals(
    $data: contributionswithdrawalsCreateInput!
  ) {
    createOneContributionswithdrawals(data: $data) {
      ...contributionswithdrawals
    }
  }
  ${contributionswithdrawalsFragment}
`;

export const updateOneContributionswithdrawals = gql`
  mutation UpdateOneContributionswithdrawals(
    $data: contributionswithdrawalsUpdateInput!
    $where: contributionswithdrawalsWhereUniqueInput!
  ) {
    updateOneContributionswithdrawals(data: $data where: $where) {
      ...contributionswithdrawals
    }
  }
  ${contributionswithdrawalsFragment}
`;

export const deleteOneContributionswithdrawals = gql`
  mutation DeleteOneContributionswithdrawals(
    $where: contributionswithdrawalsWhereUniqueInput!
  ) {
    deleteOneContributionswithdrawals(where: $where) {
      ...contributionswithdrawals
    }
  }
  ${contributionswithdrawalsFragment}
`;
