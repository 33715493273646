import { gql } from "@apollo/client";
import { teamFragment, teamCoreFragment } from "./fragment";

export const findUniqueTeam = gql`
  query FindUniqueTeam($where: teamWhereUniqueInput!) {
    findUniqueTeam(where: $where) {
      ...team
    }
  }
  ${teamFragment}
`;

export const findManyTeam = gql`
  query FindManyTeam(
    $where: teamWhereInput
    $distinct: [TeamScalarFieldEnum!]
    $orderBy: [teamOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: teamWhereUniqueInput
  ) {
    findManyTeam(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...teamCore
    }
  }
  ${teamCoreFragment}
`;
