import {
  ScenarioSubscription,
  FindUniqueAdviceQuery,
  MutationType,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { scenario } from "../subscription";
import { QueryResult } from "@apollo/client";

interface ScenarioResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: ScenarioSubscription["scenario"];
}

function createScenario({ prev, payload }: ScenarioResolverParams) {
  const scenario = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice:
      prev.findUniqueAdvice.ID === scenario.advice_ID
        ? {
            ...prev.findUniqueAdvice,
            scenario: [...prev.findUniqueAdvice.scenario, scenario],
          }
        : prev.findUniqueAdvice,
  });
}

function updateScenario({ prev, payload }: ScenarioResolverParams) {
  const scenario = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice:
      prev.findUniqueAdvice.ID === scenario.advice_ID
        ? {
            ...prev.findUniqueAdvice,
            scenario: prev.findUniqueAdvice.scenario.map((scenarios) =>
              scenarios.ID === scenario.ID
                ? {
                    ...scenarios,
                    ...scenario,
                  }
                : scenarios
            ),
          }
        : prev.findUniqueAdvice,
  });
}

function deleteScenario({ prev, payload }: ScenarioResolverParams) {
  const scenario = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice:
      prev.findUniqueAdvice.ID === scenario.advice_ID
        ? {
            ...prev.findUniqueAdvice,
            scenario: prev.findUniqueAdvice.scenario.filter(
              (scenarios) => scenarios.ID !== scenario.ID
            ),
          }
        : prev.findUniqueAdvice,
  });
}

function scenarioResolver({ prev, payload }: ScenarioResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenario({ prev, payload });

    case MutationType.Update:
      return updateScenario({ prev, payload });

    case MutationType.Delete:
      return deleteScenario({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenario,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioSubscription } }
    ) =>
      scenarioResolver({
        prev,
        payload: payload.subscriptionData.data.scenario,
      }),
    variables: query.variables,
  });
}
