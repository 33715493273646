import React from "react";
import {
  Content,
  Contenttemplate,
  Maybe,
} from "../../../codegen/schema";

interface InlineStylesProps {
  active?: boolean | null;
  isDragging?: boolean;
  height?: number | Maybe<number>;
  yPosition?: number | Maybe<number>;
  style?: React.CSSProperties;
}

export const inlineStyles = ({
  active,
  isDragging,
  height,
  yPosition,
  style,
}: InlineStylesProps) => {
  return {
    opacity: isDragging ? "0.5" : "1",
    transformOrigin: "0 0",
    height: height ? `${height}px` : `100%`,
    top: yPosition ?? 0,
    left: 0,
    width: "100%",
    border: active ? "1px solid #C4C4C4" : "none", // grey-400
    borderRadius: "5px",
    zIndex: 1,
    backgroundColor: "transparent",
    ...style,
  };
};

interface ContentDragProps {
  content: Content | Contenttemplate;
  event: React.MouseEvent;
  callback: ({
    yPosition,
    xPosition,
  }: {
    yPosition: number;
    xPosition: number;
  }) => void;
  setXPosition: React.Dispatch<React.SetStateAction<number | null | undefined>>;
}

export function contentDrag({
  content,
  event,
  callback,
  setXPosition,
}: ContentDragProps) {
  event.stopPropagation();
  event.preventDefault();

  const pageID = (content as Content).page_ID
    ? (content as Content).page_ID
    : (content as Contenttemplate).pagetemplate_ID;

  const element = document.getElementById(
    `draggable content ${event.currentTarget.id}`
  );

  const pageContentRect = document
    .getElementById(`page-content ${pageID}`)
    ?.getBoundingClientRect();


  if (element && pageContentRect) {
    var width = content.Width ?? 1;
    var XStart = content.XPosition ?? 1;
    var XCurrent = XStart;
    var YStart = content.YPosition ?? 0;
    var YCurrent = YStart;
    var rectangleStart = element!.getBoundingClientRect();

    function dragMove(e: MouseEvent) {
      e = e || window.event;
      e.preventDefault();

      var currentRectangle = element!.getBoundingClientRect();

      if (width !== 2) {
        var flipPoint = 0;

        if (XCurrent === 1) {
          flipPoint =
            30 +
            (XCurrent === XStart
              ? rectangleStart.right
              : currentRectangle.right);
        } else {
          flipPoint =
            -30 +
            (XCurrent === XStart ? rectangleStart.left : currentRectangle.left);
        }

        if (XCurrent === 1) {
          if (e.clientX > flipPoint) {
            XCurrent = 2;
          }
        } else {
          if (e.clientX < flipPoint) {
            XCurrent = 1;
          }
        }

        setXPosition(XCurrent);
      }

      YCurrent = Math.floor(e.clientY - pageContentRect!.top);

      // Put back on page accounting for height if south of page content bottom
      if (
        YCurrent + currentRectangle.height >=
        Math.floor(pageContentRect!.height)
      ) {
        YCurrent = Math.floor(
          pageContentRect!.height - currentRectangle.height
        );
      }

      // Set back to top if north of page content top
      if (YCurrent < 0) {
        YCurrent = 0;
      }

      element!.style.top = YCurrent.toString() + "px";
    }

    function dragEnd(e: MouseEvent) {
      e = e || window.event;
      e.preventDefault();
      var newColumn = XCurrent;
      var newTop = YCurrent;

      // element!.removeEventListener("mousedown", dragStart)
      document.body.removeEventListener("mousemove", dragMove);
      document.body.removeEventListener("mouseup", dragEnd);

      // Update content height after state ops and move finished
      if (newTop !== content.YPosition || newColumn !== content.XPosition) {
        callback({ yPosition: newTop, xPosition: newColumn });
      }
    }

    document.body.addEventListener("mousemove", dragMove);
    document.body.addEventListener("mouseup", dragEnd, { once: true });
  }
}

interface ContentHeightResizeProps {
  callback: ({ height }: { height: number }) => void;
  content: Content | Contenttemplate;
  event: React.MouseEvent;
  height?: number | null;
  setHeight: React.Dispatch<React.SetStateAction<number | null | undefined>>;
}

export function contentHeightResize({
  callback,
  content,
  event,
  height,
  setHeight,
}: ContentHeightResizeProps) {
  event.stopPropagation();
  event.preventDefault();

  const pageID = (content as Content).page_ID
    ? (content as Content).page_ID
    : (content as Contenttemplate).pagetemplate_ID;

  var startHeight = event.clientY;
  var newHeight = startHeight;

  const element = document.getElementById(
    `draggable content ${event.currentTarget.id}`
  );

  const pageContentRect = document
    .getElementById(`page-content ${pageID}`)
    ?.getBoundingClientRect();

  if (pageContentRect && element) {
    function onMouseMove(this: HTMLElement, event: MouseEvent) {
      var rect = element!.getBoundingClientRect();
      var mousePosition = (height ?? 0) + event.clientY - startHeight;
      // Minimum height of 25, if model min height 320
      // Don't need a check here bc can't resize model
      var minHeight = 25;
      newHeight = mousePosition > minHeight ? mousePosition : minHeight;

      // Not past footer
      newHeight =
        event.clientY >= pageContentRect!.bottom
          ? Math.floor(pageContentRect!.bottom - rect.top)
          : newHeight;

      setHeight(
        // If less than footer start, pageY
        // If greater than min content height, pageY
        newHeight
      );
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);

      // Update contenttemplate height after state ops and move finished
      if (newHeight !== content.Height) {
        callback({ height: newHeight });
      }
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
}

interface ContentWidthResizeProps {
  callback: ({
    width,
    xPosition,
  }: {
    width: number;
    xPosition: number;
  }) => void;
  content: Content | Contenttemplate;
  event: React.MouseEvent;
  setWidth: React.Dispatch<React.SetStateAction<number | null | undefined>>;
  setXPosition: React.Dispatch<React.SetStateAction<number | null | undefined>>;
}

export function contentWidthResizeRight({
  callback,
  content,
  event,
  setWidth,
  setXPosition,
}: ContentWidthResizeProps) {
  event.stopPropagation();
  event.preventDefault();

  const element = document.getElementById(
    `draggable content ${event.currentTarget.id}`
  );

  if (element) {
    var startWidth = content.Width ?? 1;
    var currentWidth = startWidth;
    var rectangleStart = element!.getBoundingClientRect();

    function onMouseMove(this: HTMLElement, event: MouseEvent) {
      event.preventDefault();

      var currentRectangle = element!.getBoundingClientRect();

      var flipPoint = 0;

      if (currentWidth === 2) {
        flipPoint =
          currentWidth === startWidth
            ? (rectangleStart.right + rectangleStart.left) / 2
            : (currentRectangle.right + currentRectangle.left) / 2;
      } else {
        flipPoint =
          currentWidth === startWidth
            ? rectangleStart.right + 20
            : (rectangleStart.right + rectangleStart.left) / 2;
      }

      currentWidth = event.clientX > flipPoint ? 2 : 1;

      setWidth(currentWidth);
      setXPosition(1);
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);

      // Update contenttemplate height after state ops and move finished
      if (currentWidth !== content.Width) {
        callback({ width: currentWidth, xPosition: 1 });
      }
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
}

export function contentWidthResizeLeft({
  callback,
  content,
  event,
  setWidth,
  setXPosition,
}: ContentWidthResizeProps) {
  event.stopPropagation();
  event.preventDefault();

  const element = document.getElementById(
    `draggable content ${event.currentTarget.id}`
  );

  if (element) {
    var startWidth = content.Width ?? 1;
    var currentWidth = startWidth;
    var rectangleStart = element!.getBoundingClientRect();

    function onMouseMove(this: HTMLElement, event: MouseEvent) {
      event.preventDefault();

      var currentRectangle = element!.getBoundingClientRect();

      var flipPoint = 0;

      if (currentWidth === 2) {
        flipPoint =
          currentWidth === startWidth
            ? (rectangleStart.right + rectangleStart.left) / 2
            : (currentRectangle.right + currentRectangle.left) / 2;
      } else {
        flipPoint =
          currentWidth === startWidth
            ? rectangleStart.left - 20
            : (rectangleStart.right + rectangleStart.left) / 2;
      }

      currentWidth = event.clientX < flipPoint ? 2 : 1;

      setWidth(currentWidth);
      setXPosition(startWidth === 1 ? 2 : 2);
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);

      // Update contenttemplate height after state ops and move finished
      if (currentWidth !== content.Width) {
        callback({ width: currentWidth, xPosition: startWidth === 1 ? 2 : 2 });
      }
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
}
