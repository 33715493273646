import { payeeFindUniqueAdviceagreement } from "../../payee";
import { servicesFindUniqueAdviceagreement } from "../../services";
import { FindUniqueAdviceagreement } from "..";
import {
  FindUniqueAdviceagreementQuery,
  FindUniqueAdviceagreementQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export function findUniqueAdviceagreementSubscriptions(
  query: Pick<
    QueryResult<
      FindUniqueAdviceagreementQuery,
      FindUniqueAdviceagreementQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  FindUniqueAdviceagreement(query);
  servicesFindUniqueAdviceagreement(query);
  payeeFindUniqueAdviceagreement(query);
}
