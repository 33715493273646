import { gql } from "@apollo/client";

export const scenarioclientsCoreFragment = gql`
  fragment scenarioclientsCore on scenarioclient {
    ID
    scenario_ID
    scenario_advice_ID
    scenario_advice_groups_ID
    scenario_advice_groups_organisation_ID
    Type
    EntityType

    Name
    GrossIncome
    Residency
    FinancialYear
    MedicareExemption
    SalaryPackage
    StudyRepayments

    IncomeFromSuper
    IncomeFromSuper_scenarioasset_ID
    IncomeFromSuperAmount
    IncomeFromSuperIndexation

    DateOfBirth
    RetirementAge
    EstimatedMonthlyLivingExpenses

    NominatedSuperannuation_scenarioasset_ID
    EmployerSuperannuationContributions
    SalarySacrifice
    AdditionalConcessionalContributions
    NonConcessionalContributions

    SuperannuationContributionSplitting
    SuperannuationContributionSplitting_scenarioclient_ID

    IncomeSplitting
    IncomeSplitting_scenarioclient_ID

    PensionIncome

    CarriedForwardSuperannuationContributionsCapIncrease

    PayDIV293TaxFromIncome
    PayExcessConcessionalContributionsTaxFromIncome

    clients_ID
    entities_ID
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const scenarioclientFragment = gql`
  fragment scenarioclient on scenarioclient {
    ...scenarioclientsCore
  }
  ${scenarioclientsCoreFragment}
`;
