import React, { useEffect, useState } from "react";
import {
  Element,
  FindUniqueDocumenttemplateQuery,
  FindUniqueThemeQuery,
  useFindManyPagetemplateQuery,
  SortOrder,
  FindUniqueSectiontemplateQuery,
  FindUniquePagetemplateQuery,
} from "../../../../../../codegen/schema";
import { StyledText } from "../../../../../../Components/Advice/StyledText";

interface PageHeaderTemplateProps {
  documenttemplate: FindUniqueDocumenttemplateQuery["findUniqueDocumenttemplate"];
  sectiontemplate: FindUniqueSectiontemplateQuery["findUniqueSectiontemplate"];
  theme: FindUniqueThemeQuery["findUniqueTheme"];
  pagetemplate: FindUniquePagetemplateQuery["findUniquePagetemplate"];
}

export const PageHeader: React.FC<PageHeaderTemplateProps> = ({
  documenttemplate,
  sectiontemplate,
  theme,
  pagetemplate,
}) => {
  const {
    data: { findManyPagetemplate: pagetemplates } = {},
    // error: contentError,
    // loading: contentLoading,
    refetch: pageRefetch,
    // ...pageResult
  } = useFindManyPagetemplateQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        sectiontemplate_documenttemplate_ID: { equals: documenttemplate?.ID },
      },
      orderBy: [
        {
          sectiontemplate: { Position: SortOrder.Asc },
        },
        {
          Position: SortOrder.Asc,
        },
      ],
    },
  });

  const [pageNumber, setPageNumber] = useState<number | undefined>(undefined);
  const [pageNumberStyle, setPageNumberStyle] = useState<Element | undefined>(
    undefined
  );

  useEffect(() => {
    if (pagetemplates) {
      // Don't kill me
      pageRefetch();

      var currentPageNumber =
        pagetemplates?.findIndex((page) => page.ID === pagetemplate?.ID) + 1;

      setPageNumber(currentPageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenttemplate, sectiontemplate, pagetemplates]);

  useEffect(() => {
    setPageNumberStyle(
      theme?.element.find((element) => element.Type === "PageNo")
    );
  }, [theme]);

  return (
    <React.Fragment>
      <div
        className=" flex items-center justify-center self-start h-full"
        style={{
          backgroundColor: theme?.PageNumberBgColour ?? "#000000",
          width: "2cm",
        }}
      >
        <p
          style={
            pageNumberStyle
              ? {
                  fontSize: pageNumberStyle.FontSize + "pt" ?? "12pt",
                  fontFamily: pageNumberStyle.FontFamily ?? "Poppins",
                  fontWeight: pageNumberStyle.FontWeight ?? 700,
                  color: pageNumberStyle.Colour ?? "#000000",
                }
              : {}
          }
        >
          {pageNumber}
        </p>
      </div>
      <StyledText
        element={theme?.element.find((element) => element.Type === "H6")}
        className="ml-2 self-center"
      >
        {sectiontemplate?.Title}
      </StyledText>
    </React.Fragment>
  );
};
