import { QueryResult } from "@apollo/client";
import {
  AdviceManySubscription,
  FindManyAdviceTotalCountQuery,
  MutationType,
  FindManyAdviceTotalCountQueryVariables,
} from "../../../codegen/schema";
import { adviceMany } from "../subscription";

interface AdviceResolverParams {
  prev: FindManyAdviceTotalCountQuery;
  payload: AdviceManySubscription["adviceMany"];
}

function createAdvice({ prev, payload }: AdviceResolverParams) {
  return Object.assign({}, prev, {
    findManyAdviceTotalCount: prev.findManyAdviceTotalCount + 1,
  });
}

function deleteAdvice({ prev, payload }: AdviceResolverParams) {
  return Object.assign({}, prev, {
    findManyAdviceTotalCount: prev.findManyAdviceTotalCount - 1,
  });
}

function adviceResolver({ prev, payload }: AdviceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAdvice({ prev, payload });

    case MutationType.Delete:
      return deleteAdvice({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyAdviceTotalCount(
  query: Pick<
    QueryResult<
      FindManyAdviceTotalCountQuery,
      FindManyAdviceTotalCountQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: adviceMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceManySubscription } }
    ) =>
      adviceResolver({
        prev,
        payload: payload.subscriptionData.data.adviceMany,
      }),
    variables: query.variables,
  });
}
