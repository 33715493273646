import React, { useState } from "react";
import MFA from "./MFA";
import Login from "./Login";

export default function Authentication() {
  const [step, setStep] = useState<"login" | "otp">("login");
  const [email, setEmail] = useState("");

  const handleOtpSent = (email: string) => {
    setEmail(email);
    setStep("otp");
  };

  return (
    <div className="authentication-container">
      {step === "login" && <Login onOTPSent={handleOtpSent} />}
      {step === "otp" && <MFA email={email} />}
    </div>
  );
}
