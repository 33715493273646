import { QueryResult } from "@apollo/client";
import {
  SalarypackagingManySubscription,
  FindManySalarypackagingQuery,
  MutationType,
  FindManySalarypackagingQueryVariables,
} from "../../../codegen/schema";
import { salarypackagingMany } from "../subscription";

interface SalarypackagingResolverParams {
  prev: FindManySalarypackagingQuery;
  payload: SalarypackagingManySubscription["salarypackagingMany"];
}

function createSalarypackaging({
  prev,
  payload,
}: SalarypackagingResolverParams) {
  const salarypackaging = payload.data;

  return Object.assign({}, prev, {
    findManySalarypackaging: [...prev.findManySalarypackaging, salarypackaging],
  });
}

function updateSalarypackaging({
  prev,
  payload,
}: SalarypackagingResolverParams) {
  const salarypackaging = payload.data;

  return Object.assign({}, prev, {
    findManySalarypackaging: prev.findManySalarypackaging.map((entry) =>
      entry.ID === salarypackaging.ID ? { ...entry, ...salarypackaging } : entry
    ),
  });
}

function deleteSalarypackaging({
  prev,
  payload,
}: SalarypackagingResolverParams) {
  const salarypackaging = payload.data;

  return Object.assign({}, prev, {
    findManySalarypackaging: [...prev.findManySalarypackaging].filter(
      (entry) => entry.ID !== salarypackaging.ID
    ),
  });
}

function salarypackagingResolver({
  prev,
  payload,
}: SalarypackagingResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createSalarypackaging({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManySalarypackaging.some(
        (salarypackaging) => salarypackaging.ID === data.ID
      )
        ? updateSalarypackaging({ prev, payload })
        : createSalarypackaging({ prev, payload });

    case MutationType.Delete:
      return deleteSalarypackaging({ prev, payload });

    default:
      return prev;
  }
}

export function salarypackagingFindManySalarypackaging(
  query: Pick<
    QueryResult<
      FindManySalarypackagingQuery,
      FindManySalarypackagingQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: salarypackagingMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: SalarypackagingManySubscription } }
    ) =>
      salarypackagingResolver({
        prev,
        payload: payload.subscriptionData.data.salarypackagingMany,
      }),
    variables: query.variables,
  });
}
