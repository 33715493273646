export function LongTermProgressModelDescription() {
  return (
    <p className="text-2xs italic">
      The long term progress model displays the groups net total wealth and net
      investment wealth over time.
    </p>
  );
}

export function LongTermProgressCalculationTooltip() {
  return (
    <>
      <p>
        {" "}
        <b>X axis </b> <br />
        this graph starts with the earliest advice year recorded in MOAS and
        ends with the model end date. If no end date is supplied, the x axis
        will col-span-full 30 years.{" "}
      </p>

      <p>
        {" "}
        <b>Target </b> <br />
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#target"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for target breakdown.
        </a>
      </p>
      <p>
        <b>Net total wealth</b>
        <br />
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#net-total-wealth"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for net total wealth value breakdown.
        </a>
      </p>

      <p>
        <b>Net investment wealth</b>
        <br />
        <a
          className="flex gap-2 items-center"
          href="https://docs.moasapp.com/modelling/calculations#net-investment-wealth"
        >
          {" "}
          <i className="icon fa-regular fa-link fa-sm" />
          click here for net investment wealth value breakdown.
        </a>
      </p>
    </>
  );
}
