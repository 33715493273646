import { gql } from "@apollo/client";
import { notesFragment } from "../notes/fragment";

export const salarypackagingCoreFragment = gql`
  fragment salarypackagingCore on salarypackaging {
    ID
    clients_ID
    clients_groups_ID
    clients_groups_organisation_ID
    Provider
    OtherProvider
    AccountID
    PayrollNumber
    Super
    ReimbursementAccountBank
    ReimbursementAccountName
    ReimbursementAccountBSB
    ReimbursementAccountNumber
    HomeLoan
    Rent
    CarParking
    ChildCare
    AdviserFee
    PrivateTravel
    Utilities
    MotorVehicleExpenses
    HealthInsurance
    HELPDebt
    NovatedLease
    NovatedLeaseProvider
    NovatedLeasePreTax
    NovatedLeasePostTax
    NovatedLeaseEndDate
    Active
    NovatedLeaseBalloonPayment
    PackagingUsername
    PackagingPassword
    AvailableBalance
    CapLimit
    PreTaxPayDeduction
    PostTaxPayDeduction
    Notes
    Admin
    create_time
    created_by
    update_time
    updated_by
  }
`;

export const salarypackagingFragment = gql`
  fragment salarypackaging on salarypackaging {
    ...salarypackagingCore
    notes {
      ...notes
    }
  }
  ${salarypackagingCoreFragment}
  ${notesFragment}
`;
