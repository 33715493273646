import { QueryResult } from "@apollo/client";
import {
  ScenarioliabilitySubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { scenarioliability } from "../subscription";

interface ScenarioliabilityResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ScenarioliabilitySubscription["scenarioliability"];
}

function createScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioliability.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioliability.scenario_ID
                  ? {
                      ...scenario,
                      scenarioliability: [
                        ...scenario.scenarioliability,
                        scenarioliability,
                      ],
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function updateScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioliability.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioliability.scenario_ID
                  ? {
                      ...scenario,
                      scenarioliability: scenario.scenarioliability.map(
                        (scenarioliabilitys) =>
                          scenarioliabilitys.ID === scenarioliability.ID
                            ? { ...scenarioliabilitys, ...scenarioliability }
                            : scenarioliabilitys
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function deleteScenarioliability({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const scenarioliability = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioliability.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioliability.scenario_ID
                  ? {
                      ...scenario,
                      scenarioliability: scenario.scenarioliability.filter(
                        (scenarioliabilitys) =>
                          scenarioliabilitys.ID !== scenarioliability.ID
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function scenarioliabilityResolver({
  prev,
  payload,
}: ScenarioliabilityResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioliability({ prev, payload });

    case MutationType.Update:
      return updateScenarioliability({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioliability({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioliabilityFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioliability,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ScenarioliabilitySubscription };
      }
    ) =>
      scenarioliabilityResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioliability,
      }),
    variables: query.variables,
  });
}
