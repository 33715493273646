import { QueryResult } from "@apollo/client";
import {
  MutationType,
  FindManyAdviceQuery,
  AdviceManySubscription,
  FindManyAdviceQueryVariables,
} from "../../../codegen/schema";
import { adviceMany } from "../subscription";

interface AdviceResolverParams {
  prev: FindManyAdviceQuery;
  payload: AdviceManySubscription["adviceMany"];
}

function createAdvice({ prev, payload }: AdviceResolverParams) {
  const advice = payload.data;

  return Object.assign({}, prev, {
    findManyAdvice: [advice, ...prev.findManyAdvice],
  });
}

function updateAdvice({ prev, payload }: AdviceResolverParams) {
  const data = payload.data;

  return Object.assign({}, prev, {
    findManyAdvice: prev.findManyAdvice.map((advice) =>
      advice.ID === data.ID
        ? {
            ...advice,
            ...data,
          }
        : advice
    ),
  });
}

function deleteAdvice({ prev, payload }: AdviceResolverParams) {
  const data = payload.data;

  return Object.assign({}, prev, {
    findManyAdvice: prev.findManyAdvice.filter(
      (advice) => advice.ID !== data.ID
    ),
  });
}

function adviceResolver({ prev, payload }: AdviceResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAdvice({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyAdvice.some((advice) => advice.ID === data.ID)
        ? updateAdvice({ prev, payload })
        : createAdvice({ prev, payload });

    case MutationType.Delete:
      return deleteAdvice({ prev, payload });

    default:
      return prev;
  }
}

export function adviceFindManyAdvice(
  query: Pick<
    QueryResult<FindManyAdviceQuery, FindManyAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: adviceMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceManySubscription } }
    ) =>
      adviceResolver({
        prev,
        payload: payload.subscriptionData.data.adviceMany,
      }),
    variables: query.variables,
  });
}
