import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  DisclosurePanel,
} from "@headlessui/react";
import {
  FindUniqueAdviceQuery,
  FindUniqueGroupQuery,
} from "../../../../../codegen/schema";

interface SidebarAdviceModellingProps {
  group: FindUniqueGroupQuery["findUniqueGroup"];
  advice: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const SidebarAdviceModelling: React.FC<SidebarAdviceModellingProps> = ({
  group,
  advice,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const modellingNav = [
    {
      name: "Goals",
      href: "goals",
    },
    {
      name: "Scenarios",
      href: "scenario",
    },
    {
      name: "Modelling",
      href: "model",
    },
    {
      name: "Cashflow",
      href: "cashflow",
    },
  ];

  return (
    group &&
    advice && (
      <DisclosurePanel as="ul" className="mt-1 px-2">
        {modellingNav.map((item) => {
          const pathname = location.pathname.substring(1);
          const current =
            item.name === "Modelling"
              ? pathname.includes("modelling/model")
              : pathname.includes(item.href);

          return (
            <li key={item.name}>
              <div
                onClick={() => {
                  navigate(
                    `/advice/ID/${advice.ID}/group/ID/${group.ID}/modelling/${item.href}`
                  );
                }}
                className={clsx(
                  current ? "bg-neutral-50" : "hover:bg-neutral-50",
                  "block rounded-md py-1 pr-2 pl-9 text-xs leading-6 text-neutral-600 cursor-pointer"
                )}
              >
                {item.name}
              </div>
            </li>
          );
        })}
      </DisclosurePanel>
    )
  );
};
