import { gql } from "@apollo/client";
import { goalsFragment } from "./fragment";

export const goals = gql`
  subscription Goals($where: goalsWhereUniqueInput) {
    goals(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...goals
      }
      goals_has_assets {
        assets_ID
      }
      goals_has_liabilities {
        liabilities_ID
      }
    }
  }
  ${goalsFragment}
`;

export const goalsMany = gql`
  subscription GoalsMany($where: goalsWhereInput) {
    goalsMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...goals
      }
      goals_has_assets {
        assets_ID
      }
      goals_has_liabilities {
        liabilities_ID
      }
    }
  }
  ${goalsFragment}
`;
