import React from "react";
import { FindUniqueGroupQuery, Theme } from "../../../../codegen/schema";

import {
  capitaliseText,
  checkFieldIsEmpty,
} from "../../../../Functions/GeneralFunctions";
import { StyledText } from "../../StyledText";
import dayjs from "dayjs";

interface EstatestateplanninglanningComponentProps {
  group?: FindUniqueGroupQuery["findUniqueGroup"] | undefined;
  theme?: Theme;
}

export const EstatePlanningComponent: React.FC<
  EstatestateplanninglanningComponentProps
> = ({ group, theme }) => {
  type ClientPlaceholderType = {
    FirstName: string;
    LastName: string;
    estateplanning: EstateplanningPlaceholderType;
  };

  type EstateplanningPlaceholderType = {
    Will: string;
    EPOA: string;
    POAApointeeDetails: string;
    ProfessionalAdviser: string;
    LocationOfDocuments: string;
    HealthDirective: string;
    LastUpdated: string;
    notes: [];
  };

  const estateplanningPlaceholder: ClientPlaceholderType[] = [
    {
      FirstName: "John",
      LastName: "Smith",
      estateplanning: {
        Will: "Yes",
        EPOA: "Yes",
        POAApointeeDetails: "Jane Smith",
        ProfessionalAdviser: "Lawyer Firm",
        LocationOfDocuments: "Lawyer Firm",
        HealthDirective: "Yes",
        LastUpdated: dayjs().format("DD/MM/YYYY"),
        notes: [],
      },
    },
    {
      FirstName: "Jane",
      LastName: "Smith",
      estateplanning: {
        Will: "Yes",
        EPOA: "Yes",
        POAApointeeDetails: "John Smith",
        ProfessionalAdviser: "Lawyer Firm",
        LocationOfDocuments: "Lawyer Firm",
        HealthDirective: "Yes",
        LastUpdated: dayjs().format("DD/MM/YYYY"),
        notes: [],
      },
    },
  ];

  return (
    <React.Fragment>
      {group
        ? group.clients
            .filter((client) => client.Status !== "Archived")
            .map((client) => {
              if (!client.estateplanning) return null;
              return (
                <React.Fragment key={client.ID}>
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "H5"
                    )}
                    className="flex items-center"
                  >
                    {client.FirstName} {client.LastName}
                  </StyledText>
                  <div className="grid grid-cols-3 gap-2 mb-1">
                    <div className="flex flex-col">
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "H6"
                        )}
                        style={{ fontSize: "9pt" }}
                      >
                        Will
                      </StyledText>
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "P"
                        )}
                        style={{ fontSize: "10pt" }}
                      >
                        {checkFieldIsEmpty(client.estateplanning.Will)}
                      </StyledText>
                    </div>
                    <div className="flex flex-col">
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "H6"
                        )}
                        style={{ fontSize: "9pt" }}
                      >
                        Enduring Power of Attorney
                      </StyledText>
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "P"
                        )}
                        style={{ fontSize: "10pt" }}
                      >
                        {checkFieldIsEmpty(client.estateplanning.EPOA)}
                      </StyledText>
                    </div>
                    <div className="flex flex-col">
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "H6"
                        )}
                        style={{ fontSize: "9pt" }}
                      >
                        Enduring POA Detials
                      </StyledText>
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "P"
                        )}
                        style={{ fontSize: "10pt" }}
                      >
                        {checkFieldIsEmpty(
                          client.estateplanning.POAApointeeDetails
                        )}
                      </StyledText>
                    </div>
                    <div className="flex flex-col">
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "H6"
                        )}
                        style={{ fontSize: "9pt" }}
                      >
                        ProfessionalAdviser
                      </StyledText>
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "P"
                        )}
                        style={{ fontSize: "10pt" }}
                      >
                        {checkFieldIsEmpty(
                          client.estateplanning.ProfessionalAdviser
                        )}
                      </StyledText>
                    </div>
                    <div className="flex flex-col">
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "H6"
                        )}
                        style={{ fontSize: "9pt" }}
                      >
                        Location of documents
                      </StyledText>
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "P"
                        )}
                        style={{ fontSize: "10pt" }}
                      >
                        {checkFieldIsEmpty(
                          client.estateplanning.LocationOfDocuments
                        )}
                      </StyledText>
                    </div>
                    <div className="flex flex-col">
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "H6"
                        )}
                        style={{ fontSize: "9pt" }}
                      >
                        Health Directive
                      </StyledText>
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "P"
                        )}
                        style={{ fontSize: "10pt" }}
                      >
                        {checkFieldIsEmpty(
                          client.estateplanning.HealthDirective
                        )}
                      </StyledText>
                    </div>
                    <div className="flex flex-col">
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "H6"
                        )}
                        style={{ fontSize: "9pt" }}
                      >
                        Last updated
                      </StyledText>
                      <StyledText
                        element={theme?.element.find(
                          (element) => element.Type === "P"
                        )}
                        style={{ fontSize: "10pt" }}
                      >
                        {checkFieldIsEmpty(
                          client.estateplanning.LastUpdated,
                          "date"
                        )}
                      </StyledText>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
        : estateplanningPlaceholder.map((clientplaceholder, index) => {
            return (
              <React.Fragment key={index}>
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "H5"
                  )}
                  className="flex items-center"
                >
                  {clientplaceholder.FirstName} {clientplaceholder.LastName}
                </StyledText>
                <div className="grid grid-cols-3 gap-2 mb-1">
                  {(
                    Object.keys(clientplaceholder.estateplanning) as Array<
                      keyof EstateplanningPlaceholderType
                    >
                  ).map((field, fieldIndex) => {
                    if (!field.includes("_") && field !== "notes") {
                      return (
                        <div className="flex flex-col " key={fieldIndex}>
                          <StyledText
                            element={theme?.element.find(
                              (element) => element.Type === "H6"
                            )}
                            style={{ fontSize: "9pt" }}
                          >
                            {field === "EPOA"
                              ? "Enduring Power of Attorney "
                              : field === "POAApointeeDetails"
                              ? "Enduring POA Details"
                              : capitaliseText(field)}
                          </StyledText>
                          <StyledText
                            element={theme?.element.find(
                              (element) => element.Type === "P"
                            )}
                            style={{ fontSize: "10pt" }}
                          >
                            {checkFieldIsEmpty(
                              clientplaceholder.estateplanning[field]
                            )}
                          </StyledText>
                        </div>
                      );
                    } else return null;
                  })}
                </div>
              </React.Fragment>
            );
          })}
    </React.Fragment>
  );
};
