import { gql } from "@apollo/client";
import { notesFragment } from ".";

export const createOneNotes = gql`
  mutation CreateOneNotes($data: notesCreateInput!) {
    createOneNotes(data: $data) {
      ...notes
    }
  }
  ${notesFragment}
`;

export const updateOneNotes = gql`
  mutation UpdateOneNotes(
    $data: notesUpdateInput!
    $where: notesWhereUniqueInput!
  ) {
    updateOneNotes(data: $data, where: $where) {
      ...notes
    }
  }
  ${notesFragment}
`;

export const deleteOneNotes = gql`
  mutation DeleteOneNotes($where: notesWhereUniqueInput!) {
    deleteOneNotes(where: $where) {
      ...notes
    }
  }
  ${notesFragment}
`;

/**
 * Current note structure and where they are located
 * child - group
 * entities - group
 * opportunity - group
 * projects - group
 * review - group
 * tasks - group
 *
 * address - client
 * annuity - client
 * cash - client.assets
 * client - client
 * collectibles - client.assets
 * employment - client
 * estateplanning - client
 * fees - client
 * goals - client
 * income - client
 * insurance - client
 * loans - client.liabilities
 * offset - client.assets
 * otherassets - client.assets
 * otherliabilities - client.liabilities
 * property - client.assets
 * salarypackaging - client
 * shares - client.assets
 * superannuation - client.assets
 * vehicles - client.assets
 */
