import { QueryResult } from "@apollo/client";
import {
  EntitiesManySubscription,
  FindManyEntitiesQuery,
  FindManyEntitiesQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { entitiesMany } from "../subscription";

interface EntitiesResolverParams {
  prev: FindManyEntitiesQuery;
  payload: EntitiesManySubscription["entitiesMany"];
}

function createEntities({ prev, payload }: EntitiesResolverParams) {
  const entities = payload.data;

  return Object.assign({}, prev, {
    findManyEntities: [...prev.findManyEntities, entities],
  });
}

function updateEntities({ prev, payload }: EntitiesResolverParams) {
  const entities = payload.data;

  return Object.assign({}, prev, {
    findManyEntities: prev.findManyEntities.map((entity) =>
      entity.ID === entities.ID ? { ...entity, ...entities } : entity
    ),
  });
}

function deleteEntities({ prev, payload }: EntitiesResolverParams) {
  const entities = payload.data;

  return Object.assign({}, prev, {
    findManyEntities: [...prev.findManyEntities].filter(
      (entity) => entity.ID !== entities.ID
    ),
  });
}

function entitiesResolver({ prev, payload }: EntitiesResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createEntities({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyEntities.some((entities) => entities.ID === data.ID)
        ? updateEntities({ prev, payload })
        : createEntities({ prev, payload });

    case MutationType.Delete:
      return deleteEntities({ prev, payload });

    default:
      return prev;
  }
}

export function entitiesFindManyEntities(
  query: Pick<
    QueryResult<FindManyEntitiesQuery, FindManyEntitiesQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: entitiesMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: EntitiesManySubscription } }
    ) =>
      entitiesResolver({
        prev,
        payload: payload.subscriptionData.data.entitiesMany,
      }),
    variables: query.variables,
  });
}
