import { gql } from "@apollo/client";
import { vehiclesFragment } from "./fragment";

export const vehicles = gql`
  subscription Vehicles($where: vehiclesWhereUniqueInput) {
    vehicles(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...vehicles
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${vehiclesFragment}
`;

export const vehiclesMany = gql`
  subscription VehiclesMany($where: vehiclesWhereInput) {
    vehiclesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...vehicles
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${vehiclesFragment}
`;
