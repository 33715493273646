import React from "react";
import { InsuranceDeathComparisonModelGraph } from "../Graph";
import { InsuranceDeathComparisonDataReturn } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface InsuranceDeathPlaceholderModelProps {
  theme?: Theme;
}

var data: InsuranceDeathComparisonDataReturn[] = [
  {
    scenarioDataKey: "Scenario 1",
    [`John Smith Existing Cover`]: 2600000,
    [`John Smith Cover Adjustment`]: 100000,
    [`Jane Smith Existing Cover`]: 1100000,
    [`Jane Smith Cover Adjustment`]: 100000,
  },
  {
    scenarioDataKey: "Scenario 2",
    [`John Smith Existing Cover`]: 2585943,
    [`John Smith Cover Adjustment`]: -2585943,
    [`Jane Smith Existing Cover`]: 800221,
    [`Jane Smith Cover Adjustment`]: -800221,
  },
];

export const InsuranceDeathComparisonPlaceholderModel = ({
  theme,
}: InsuranceDeathPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Death insurance
        </StyledText>

        <ModelTooltip type={Model_Type.InsuranceDeath} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InsuranceDeathComparisonModelGraph comparisonData={data} />
      </div>
    </React.Fragment>
  );
};
