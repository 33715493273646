import { gql } from "@apollo/client";
import { adviceFragment, adviceCoreFragment } from "./fragment";

export const advice = gql`
  subscription Advice($where: adviceWhereUniqueInput) {
    advice(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...advice
      }
      advice_has_team {
        advice_ID
        team_ID
      }
      model_has_advice {
        model_ID
        advice_ID
      }
    }
  }
  ${adviceFragment}
`;

export const adviceMany = gql`
  subscription AdviceMany($where: adviceWhereInput) {
    adviceMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...adviceCore
      }
      advice_has_team {
        advice_ID
        team_ID
      }
      model_has_advice {
        model_ID
        advice_ID
      }
    }
  }
  ${adviceCoreFragment}
`;
