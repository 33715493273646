import { QueryResult } from "@apollo/client";
import {
  FindManyUserQuery,
  FindManyUserQueryVariables,
  MutationType,
  UserManySubscription,
} from "../../../codegen/schema";
import { userMany } from "../subscription";

interface UserResolverParams {
  prev: FindManyUserQuery;
  payload: UserManySubscription["userMany"];
}

function updateUser({ prev, payload }: UserResolverParams) {
  const user = payload.data;

  if (!prev.findManyUser) return prev;
  return Object.assign({}, prev, {
    findManyUsers: prev.findManyUser.map((entry) =>
      entry.staff_ID === user.staff_ID
        ? {
            ...entry,
            ...user,
          }
        : entry
    ),
  });
}

function deleteUser({ prev, payload }: UserResolverParams) {
  const user = payload.data;

  if (!prev.findManyUser) return prev;
  return Object.assign({}, prev, {
    findManyUsers: prev.findManyUser.filter(
      (entry) => entry.staff_ID !== user.staff_ID
    ),
  });
}

function userResolver({ prev, payload }: UserResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createUser(prev, payload);

    case MutationType.Update:
      return updateUser({ prev, payload });

    case MutationType.Delete:
      return deleteUser({ prev, payload });

    default:
      return prev;
  }
}

export function userFindManyUser(
  query: Pick<
    QueryResult<FindManyUserQuery, FindManyUserQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: userMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: UserManySubscription } }
    ) =>
      userResolver({
        prev,
        payload: payload.subscriptionData.data.userMany,
      }),
    variables: query.variables,
  });
}
