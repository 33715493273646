import { gql } from "@apollo/client";
import { scenarioassetFragment } from "./fragment";

export const createOneScenarioasset = gql`
  mutation CreateOneScenarioasset($data: scenarioassetCreateInput!) {
    createOneScenarioasset(data: $data) {
      ...scenarioasset
    }
  }
  ${scenarioassetFragment}
`;

export const updateOneScenarioasset = gql`
  mutation UpdateOneScenarioasset(
    $data: scenarioassetUpdateInput!
    $where: scenarioassetWhereUniqueInput!
  ) {
    updateOneScenarioasset(data: $data, where: $where) {
      ...scenarioasset
    }
  }
  ${scenarioassetFragment}
`;

export const deleteOneScenarioasset = gql`
  mutation DeleteOneScenarioasset($where: scenarioassetWhereUniqueInput!) {
    deleteOneScenarioasset(where: $where) {
      ...scenarioasset
    }
  }
  ${scenarioassetFragment}
`;
