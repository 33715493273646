import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  FindUniqueAdviceQuery,
  FindUniqueGroupQuery,
} from "../../../../../codegen/schema";
import { verifyDataNav } from "../../DataVerification";

interface SidebarAdviceVerifyDataProps {
  group: FindUniqueGroupQuery["findUniqueGroup"];
  advice: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const SidebarAdviceVerifyData: React.FC<
  SidebarAdviceVerifyDataProps
> = ({ group, advice }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    group &&
    advice && (
      <DisclosurePanel as="ul" className="mt-1 px-2">
        {verifyDataNav.map((item) => {
          const pathname = location.pathname.substring(1);
          const current =
            item.name === "Client details"
              ? pathname.includes(item.href) && !pathname.includes("income")
              : pathname.includes(item.href);

          return (
            <li key={item.name}>
              {!item.children ? (
                <div
                  onClick={() => {
                    navigate(
                      `/advice/ID/${advice.ID}/group/ID/${group.ID}/verifydata/${item.href}`
                    );
                  }}
                  className={clsx(
                    current ? "bg-neutral-50" : "hover:bg-neutral-50",
                    "block rounded-md py-1 pr-2 pl-9 text-xs leading-6 text-neutral-600 cursor-pointer"
                  )}
                >
                  {item.name}
                </div>
              ) : (
                <Disclosure as="div">
                  {({ open, close }) => (
                    <>
                      <DisclosureButton
                        className={clsx(
                          current
                            ? "bg-neutral-50"
                            : "hover:bg-neutral-50 text-neutral-600",
                          "flex gap-x-3 items-center w-full rounded-md py-1 pr-2 pl-9 text-xs leading-6"
                        )}
                      >
                        {item.name}
                        <i
                          className={clsx(
                            open
                              ? "rotate-90 text-neutral-400"
                              : "text-neutral-400",
                            "ml-auto h-5 w-5 shrink-0 icon fa-solid fa-chevron-right"
                          )}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      {(pathname.includes(item.href) || open) && (
                        <DisclosurePanel as="ul" className="mt-1 px-2" static>
                          <>
                            {item.children?.map((subItem) => {
                              const subItemcurrent = pathname.includes(
                                subItem.href
                              );
                              return (
                                <li key={subItem.name}>
                                  {/* 44px */}
                                  <div
                                    onClick={() => {
                                      navigate(
                                        `/advice/ID/${advice.ID}/group/ID/${group.ID}/verifydata/${subItem.href}`
                                      );
                                      if (!pathname.includes(item.href))
                                        close();
                                    }}
                                    className={clsx(
                                      subItemcurrent
                                        ? "bg-neutral-50"
                                        : "hover:bg-neutral-50",
                                      "block rounded-md py-1 pr-2 pl-9 text-xs leading-6 text-neutral-600 cursor-pointer"
                                    )}
                                  >
                                    {subItem.name}
                                  </div>
                                </li>
                              );
                            })}
                          </>
                        </DisclosurePanel>
                      )}
                    </>
                  )}
                </Disclosure>
              )}
            </li>
          );
        })}
      </DisclosurePanel>
    )
  );
};
