import { gql } from "@apollo/client";
import { roleFragment } from "../role";

export const teamCoreFragment = gql`
  fragment teamCore on team {
    ID
    organisation_ID
    Text
    Colour
    created_by
    create_time
    updated_by
    update_time
  }
`;

export const teamFragment = gql`
  fragment team on team {
    ...teamCore

    role {
      ...role
    }
  }
  ${teamCoreFragment}
  ${roleFragment}
`;
