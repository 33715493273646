import { adviceFindManyAdvice } from "../reducers";
import {
  FindManyAdviceQuery,
  FindManyAdviceQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export default function findManyAdviceSubscription(
  query: Pick<
    QueryResult<FindManyAdviceQuery, FindManyAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  adviceFindManyAdvice(query);
}
