import React from "react";

import { StyledText } from "../../../StyledText";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";
import { Scenarioinsurance_Type, Theme } from "../../../../../codegen/schema";
import { ClientIncome, IncomeRows } from "..";
import CalculationDescription from "../Description";
import { Tooltip } from "../../../../Tooltip";

/**
 * Returns income and cashflow component.
 *
 * NOTE: Net income and calculated surplus are adjusted with salary packaging
 * as directed by Wealthmed.
 */
export const CashflowIndividual = ({
  clientsIncome,
  theme,
}: {
  clientsIncome: ClientIncome[];
  theme?: Theme;
}) => {
  return (
    <React.Fragment>
      <table className={`w-full`}>
        <thead>
          <tr>
            {/* HEADERS */}
            <th>
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H5"
                )}
                className="text-left py-0"
              >
                Income & cashflow
              </StyledText>
            </th>

            {/* Client names */}
            {clientsIncome.map((client) => (
              <th className="py-0" key={client.ID}>
                <div className="flex items-center gap-2 ">
                  <div
                    className="icon fa-regular fa-user-circle"
                    style={{
                      color: theme?.colour[0]?.Value ?? "#000000",
                    }}
                  />
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "H5"
                    )}
                  >
                    {client.Name}
                  </StyledText>
                </div>
              </th>
            ))}

            {/* Combined */}
            <th className="py-0">
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H5"
                )}
                className="text-left"
              >
                Total
              </StyledText>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-neutral-100">
          {/* ROWS */}
          {/* INCOME */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={clientsIncome.length + 2} // + 2 for left column and combined column
            >
              Income
            </th>
          </tr>
          {/* SALARY */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.Salary,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.gross -
                                client.income.rental -
                                client.superannuation
                                  .excessConcessionalContributions
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.Salary}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        client.income.gross -
                          client.income.rental -
                          client.superannuation.excessConcessionalContributions
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.gross -
                            client.income.rental -
                            client.superannuation
                              .excessConcessionalContributions),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>
          {/* EXCESS CONCESSIONAL CONTRIBUTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.ExcessConcessionalContributions,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(
                              client.superannuation
                                .excessConcessionalContributions
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.ExcessConcessionalContributions}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        client.superannuation.excessConcessionalContributions
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.superannuation
                              .excessConcessionalContributions),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>
          {/* PENSION */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.Pension,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(client.income.pension),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.Pension}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(client.income.pension),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator += client.income.pension),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>
          {/* RENTAL INCOME LIST */}
          {/* {clientsIncome
            .reduce<Array<string>>((accumulator, client) => {
              client.rentalProperties.forEach((entry) =>
                accumulator.push(entry.text)
              );
              return [...new Set(accumulator)];
            }, []) */}
          {/* .map((rentalName) => (
              <tr className="hover:bg-neutral-50" key={rentalName}>
                <td className="py-0">
                  <div className="flex items-center gap-2">
                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "P"
                      )}
                    >
                      {rentalName}
                    </StyledText>
                  </div>
                </td> */}
          {/* INDIVIDUAL CLIENTS */}
          {/* {clientsIncome.map((client) => {
                  var rental = client.rentalProperties.find(
                    (entry) => entry.text === rentalName
                  );

                  return (
                    <td className="py-0">
                      <div className="flex items-center gap-2">
                        <StyledText
                          element={theme?.element.find(
                            (element) => element.Type === "P"
                          )}
                        >
                          {checkFieldIsEmpty(
                            Math.round(rental ? rental.income : 0),
                            "dollar"
                          )}
                        </StyledText>
                      </div>
                    </td>
                  );
                })} */}
          {/* COMBINED */}
          {/* <td className="py-0">
                  <div className="flex items-center gap-2">
                    <StyledText
                      element={theme?.element.find(
                        (element) => element.Type === "P"
                      )}
                    >
                      {checkFieldIsEmpty(
                        Math.round(
                          clientsIncome.reduce((accumulator, client) => {
                            var rental = client.rentalProperties.find(
                              (entry) => entry.text === rentalName
                            );
                            return (accumulator += rental ? rental.income : 0);
                          }, 0)
                        ),
                        "dollar"
                      )}
                    </StyledText>
                  </div>
                </td> */}
          {/* </tr>
            ))} */}
          {/* RENTAL INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.RentalIncome,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        {client.rentalProperties.map((property) => (
                          <p key={property.text}>
                            {property.text}:{" "}
                            {checkFieldIsEmpty(
                              Math.round(property.income),
                              "dollar"
                            )}
                          </p>
                        ))}
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(client.income.rental),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.RentalIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>

            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(client.income.rental),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator += client.income.rental),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>

          {/* GROSS INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.GrossIncome,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(client.income.gross),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.GrossIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(client.income.gross),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator += client.income.gross),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>

          {/* NON-CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={clientsIncome.length + 2}
            >
              Non-cash tax deductions
            </th>
          </tr>
          {/* PROPERTY NON CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.PropertyNonCashTaxDed,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        {client.rentalProperties.map((property) => (
                          <p key={property.text}>
                            {property.text}:{" "}
                            {checkFieldIsEmpty(
                              Math.round(property.nonCashTaxDeductions),
                              "dollar"
                            )}
                          </p>
                        ))}
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.deductions.rentalPropertyNonCash
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.PropertyNonCashTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        client.income.deductions.rentalPropertyNonCash
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.rentalPropertyNonCash),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>

          {/* CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={clientsIncome.length + 2}
            >
              Cash tax deductions
            </th>
          </tr>
          {/* EXCESS CONCESSIONAL CONTRIBUTIONS TAX OFFSET */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.ExcessConcessionalContributionsTaxOffset,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(
                              client.tax
                                .excessConcessionalContributionsTaxOffset
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.ExcessConcessionalContributionsTaxOffset}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        client.tax.excessConcessionalContributionsTaxOffset
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.tax
                              .excessConcessionalContributionsTaxOffset),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* INVESTMENT PROPERTY INTEREST TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.PropertyInterestTaxDed,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        {client.deductibleLoans.map((loan) => (
                          <p key={loan.text}>
                            {loan.text}:{" "}
                            {checkFieldIsEmpty(
                              Math.round(loan.interest),
                              "dollar"
                            )}
                          </p>
                        ))}
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.deductions.rentalPropertyInterest
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.PropertyInterestTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        client.income.deductions.rentalPropertyInterest
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.rentalPropertyInterest),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* INVESTMENT PROPERTY EXPENSES TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.PropertyExpensesTaxDed,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        {client.rentalProperties.map((property) => (
                          <p key={property.text}>
                            {property.text}:{" "}
                            {checkFieldIsEmpty(
                              Math.round(property.expenses),
                              "dollar"
                            )}
                          </p>
                        ))}
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.deductions.rentalPropertyExpenses
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.PropertyExpensesTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        client.income.deductions.rentalPropertyExpenses
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.rentalPropertyExpenses),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* TOTAL PROPERTY TAX DEDUCTIONS */}
          {/* <tr className="hover:bg-neutral-50"> */}
          {/* <td className="py-0">
              <div className="flex items-center gap-2">
                {!location.pathname.includes("document") &&
                  CalculationDescription(IncomeRows.PropertyTaxDed)}

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {IncomeRows.PropertyTaxDed}
                </StyledText>
              </div>
            </td> */}
          {/* INDIVIDUAL CLIENTS */}
          {/* {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        client.income.deductions.rentalPropertyExpenses +
                          client.income.deductions.rentalPropertyInterest
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))} */}
          {/* COMBINED */}
          {/* <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.rentalPropertyExpenses +
                            client.income.deductions.rentalPropertyInterest),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td> */}
          {/* </tr> */}
          {/* SALARY SACRIFICING CONTRIBUTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.SalarySacrificeCont,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.deductions.salarySacrifice
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.SalarySacrificeCont}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(client.income.deductions.salarySacrifice),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.salarySacrifice),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* SALARY PACKAGING */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.SalaryPackaging,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(client.income.deductions.salaryPackage),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.SalaryPackaging}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(client.income.deductions.salaryPackage),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.salaryPackage),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>

          {/* INCOME PROTECTION INSURANCE */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.IncomeProtection,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        {client.insurances
                          .filter(
                            (insurance) =>
                              insurance.type ===
                              Scenarioinsurance_Type.IncomeProtection
                          )
                          .map((insurance) => (
                            <p>
                              {insurance.text}:{" "}
                              {checkFieldIsEmpty(
                                Math.round(insurance.preimumPerYear),
                                "dollar"
                              )}
                            </p>
                          ))}
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.deductions.incomeProtectionInsurance
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.IncomeProtection}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        client.income.deductions.incomeProtectionInsurance
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.incomeProtectionInsurance),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>

          {/* INCOME AND TAX */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={clientsIncome.length + 2}
            >
              Income and tax
            </th>
          </tr>
          {/* ASSESSABLE INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.AssessableIncome,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(client.totals.taxableIncome),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.AssessableIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(client.totals.taxableIncome),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator += client.totals.taxableIncome),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ESTIMATED INCOME TAX */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedIncomeTax,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          Income tax:{" "}
                          {checkFieldIsEmpty(
                            Math.round(client.tax.income),
                            "dollar"
                          )}
                        </p>
                        <p>
                          Medicare levy:{" "}
                          {checkFieldIsEmpty(
                            Math.round(client.tax.medicareLevy),
                            "dollar"
                          )}
                        </p>
                        <p>
                          Study repayments:{" "}
                          {checkFieldIsEmpty(
                            Math.round(client.tax.studyRepayments),
                            "dollar"
                          )}
                        </p>
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(client.totals.taxPayable),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedIncomeTax}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(client.totals.taxPayable),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator += client.totals.taxPayable),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ESTIMATED SUPERANNUATION TAX PAYABLE FROM INCOME*/}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.SuperannuationTax,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          DIV293 tax:{" "}
                          {checkFieldIsEmpty(
                            Math.round(client.tax.div293),
                            "dollar"
                          )}
                        </p>
                        <p>
                          ECC tax:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.tax.excessConcessionalContributions
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.SuperannuationTax}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        (client.PayDIV293TaxFromIncome === 0
                          ? client.tax.div293
                          : 0) +
                          (client.PayExcessConcessionalContributionsTaxFromIncome ===
                          0
                            ? client.tax.excessConcessionalContributions
                            : 0)
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            (client.PayDIV293TaxFromIncome === 0
                              ? client.tax.div293
                              : 0) +
                            (client.PayExcessConcessionalContributionsTaxFromIncome ===
                            0
                              ? client.tax.excessConcessionalContributions
                              : 0)),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ADD BACK NON CASH TAX DEDUCTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.AddBackNonCashTaxDed,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          Property non-cash:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.deductions.rentalPropertyNonCash
                            ),
                            "dollar"
                          )}
                        </p>
                        <p>
                          Salary packaging:{" "}
                          {checkFieldIsEmpty(
                            Math.round(client.income.deductions.salaryPackage),
                            "dollar"
                          )}
                        </p>
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.income.deductions.rentalPropertyNonCash +
                                client.income.deductions.salaryPackage
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.AddBackNonCashTaxDed}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        client.income.deductions.rentalPropertyNonCash +
                          client.income.deductions.salaryPackage
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.deductions.rentalPropertyNonCash +
                            client.income.deductions.salaryPackage),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ESTIMATED NET INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedNetIncome,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(
                              client.totals.netIncome +
                                client.income.deductions.salaryPackage +
                                client.income.deductions.rentalPropertyNonCash +
                                (client.PayDIV293TaxFromIncome === 0
                                  ? client.tax.div293
                                  : 0) +
                                (client.PayExcessConcessionalContributionsTaxFromIncome ===
                                0
                                  ? client.tax.excessConcessionalContributions
                                  : 0)
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedNetIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {checkFieldIsEmpty(
                      Math.round(
                        client.totals.netIncome +
                          client.income.deductions.salaryPackage +
                          client.income.deductions.rentalPropertyNonCash +
                          (client.PayDIV293TaxFromIncome === 0
                            ? client.tax.div293
                            : 0) +
                          (client.PayExcessConcessionalContributionsTaxFromIncome ===
                          0
                            ? client.tax.excessConcessionalContributions
                            : 0)
                      ),
                      "dollar"
                    )}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.totals.netIncome +
                            client.income.deductions.salaryPackage +
                            client.income.deductions.rentalPropertyNonCash +
                            (client.PayDIV293TaxFromIncome === 0
                              ? client.tax.div293
                              : 0) +
                            (client.PayExcessConcessionalContributionsTaxFromIncome ===
                            0
                              ? client.tax.excessConcessionalContributions
                              : 0)),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>

          {/* AFTER TAX EXPENSES */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={clientsIncome.length + 2}
            >
              After tax expenses
            </th>
          </tr>
          {/* ESTIMATED LIVING EXPENSES */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedExpenses,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        <p>
                          {checkFieldIsEmpty(
                            Math.round(client.income.estimatedAnnualExpenses),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedExpenses}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(client.income.estimatedAnnualExpenses),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.income.estimatedAnnualExpenses),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ESTIMATED INSURANCE PREMIUMS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedInsurance,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>{client.Name}</p>
                        {client.insurances
                          .filter(
                            (insurance) =>
                              insurance.type !==
                              Scenarioinsurance_Type.IncomeProtection
                          )
                          .map((insurance) => (
                            <p>
                              {insurance.text}:{" "}
                              {checkFieldIsEmpty(
                                Math.round(insurance.preimumPerYear),
                                "dollar"
                              )}
                            </p>
                          ))}
                        <p className="font-bold">
                          Total:{" "}
                          {checkFieldIsEmpty(
                            Math.round(
                              client.insurances
                                .filter(
                                  (insurance) =>
                                    insurance.type !==
                                    Scenarioinsurance_Type.IncomeProtection
                                )
                                .reduce(
                                  (accumulator, insurance) =>
                                    (accumulator += insurance.preimumPerYear),
                                  0
                                )
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedInsurance}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    (
                    {checkFieldIsEmpty(
                      -Math.round(
                        client.insurances
                          .filter(
                            (insurance) =>
                              insurance.type !==
                              Scenarioinsurance_Type.IncomeProtection
                          )
                          .reduce(
                            (insuranceAccumulator, insurance) =>
                              (insuranceAccumulator +=
                                insurance.preimumPerYear),
                            0
                          )
                      ),
                      "dollar"
                    )}
                    )
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator += client.insurances
                            .filter(
                              (insurance) =>
                                insurance.type !==
                                Scenarioinsurance_Type.IncomeProtection
                            )
                            .reduce(
                              (insuranceAccumulator, insurance) =>
                                (insuranceAccumulator +=
                                  insurance.preimumPerYear),
                              0
                            )),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ESTIMATED LOAN REPAYMENTS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedCombinedRepayments,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    {clientsIncome.map((client) => (
                      <div key={client.ID}>
                        <p>
                          Total:{" "}
                          {checkFieldIsEmpty(
                            -Math.round(
                              clientsIncome.reduce(
                                (accumulator, client) =>
                                  (accumulator =
                                    client.totals.combinedLoanRepayments),
                                0
                              ) -
                                clientsIncome.reduce(
                                  (accumulator, client) =>
                                    (accumulator +=
                                      client.income.deductions
                                        .rentalPropertyInterest),
                                  0
                                )
                            ),
                            "dollar"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedCombinedRepayments}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {/* {checkFieldIsEmpty(
                      Math.round(client.totals.combinedLoanRepayments),
                      "dollar"
                    )} */}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator = client.totals.combinedLoanRepayments),
                        0
                      ) -
                        clientsIncome.reduce(
                          (accumulator, client) =>
                            (accumulator +=
                              client.income.deductions.rentalPropertyInterest),
                          0
                        )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ESTIMATED ASSET CONTRIBUTIONS FROM INCOME */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedCombinedAssetContributionsFromIncome,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    <p>
                      Total:{" "}
                      {checkFieldIsEmpty(
                        -Math.round(
                          clientsIncome.reduce(
                            (accumulator, client) =>
                              (accumulator =
                                client.totals
                                  .combinedScenarioassetContributionsFromIncome),
                            0
                          )
                        ),
                        "dollar"
                      )}
                    </p>
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedCombinedAssetContributionsFromIncome}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {/* {checkFieldIsEmpty(
                      Math.round(client.totals.combinedScenarioassetContributionsFromIncome),
                      "dollar"
                    )} */}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator =
                            client.totals
                              .combinedScenarioassetContributionsFromIncome),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>
          {/* ESTIMATED LIABILITY CONTRIBUTIONS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.EstimatedCombinedLiabilityContributions,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    <p>
                      Total:{" "}
                      {checkFieldIsEmpty(
                        -Math.round(
                          clientsIncome.reduce(
                            (accumulator, client) =>
                              (accumulator =
                                client.totals
                                  .combinedScenarioliabilityContributions),
                            0
                          )
                        ),
                        "dollar"
                      )}
                    </p>
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.EstimatedCombinedLiabilityContributions}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    style={{ color: "red" }}
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {/* {checkFieldIsEmpty(
                      Math.round(client.totals.combinedScenarioliabilityContributions),
                      "dollar"
                    )} */}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  style={{ color: "red" }}
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  (
                  {checkFieldIsEmpty(
                    -Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator =
                            client.totals
                              .combinedScenarioliabilityContributions),
                        0
                      )
                    ),
                    "dollar"
                  )}
                  )
                </StyledText>
              </div>
            </td>
          </tr>

          {/* ESTIMATED SURPLUS */}
          <tr className="hover:bg-neutral-50">
            <th
              className="text-left bg-neutral-50 py-0"
              colSpan={clientsIncome.length + 2}
            >
              Estimated surplus
            </th>
          </tr>
          {/* CALCULATED SURPLUS */}
          <tr className="hover:bg-neutral-50">
            <td className="py-0">
              <Tooltip
                background="light"
                title={CalculationDescription(
                  IncomeRows.CalculatedSurplus,
                  <div
                    className={`mt-2 grid grid-cols-${clientsIncome.length} gap-2`}
                  >
                    <p>
                      Total:{" "}
                      {checkFieldIsEmpty(
                        Math.round(
                          clientsIncome.reduce(
                            (accumulator, client) =>
                              (accumulator +=
                                client.totals.surplus +
                                client.income.deductions.salaryPackage +
                                client.income.deductions.rentalPropertyNonCash +
                                client.income.deductions
                                  .rentalPropertyInterest +
                                (client.PayDIV293TaxFromIncome === 0
                                  ? client.tax.div293
                                  : 0) +
                                (client.PayExcessConcessionalContributionsTaxFromIncome ===
                                0
                                  ? client.tax.excessConcessionalContributions
                                  : 0) -
                                client.insurances
                                  .filter(
                                    (insurance) =>
                                      insurance.type !==
                                      Scenarioinsurance_Type.IncomeProtection
                                  )
                                  .reduce(
                                    (insuranceAccumulator, insurance) =>
                                      (insuranceAccumulator +=
                                        insurance.preimumPerYear),
                                    0
                                  ) -
                                (client.totals.combinedLoanRepayments +
                                  client.totals
                                    .combinedScenarioassetContributionsFromIncome +
                                  client.totals
                                    .combinedScenarioliabilityContributions) /
                                  clientsIncome.length),
                            0
                          )
                        ),
                        "dollar"
                      )}
                    </p>
                  </div>
                )}
                placement="bottom"
              >
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {IncomeRows.CalculatedSurplus}
                  </StyledText>
                </div>
              </Tooltip>
            </td>
            {/* INDIVIDUAL CLIENTS */}
            {clientsIncome.map((client) => (
              <td className="py-0" key={client.ID}>
                <div className="flex items-center gap-2">
                  <StyledText
                    element={theme?.element.find(
                      (element) => element.Type === "P"
                    )}
                  >
                    {/* {checkFieldIsEmpty(
                      Math.round(client.totals.combinedScenarioliabilityContributions),
                      "dollar"
                    )} */}
                  </StyledText>
                </div>
              </td>
            ))}
            {/* COMBINED */}
            <td className="py-0">
              <div className="flex items-center gap-2">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(
                    Math.round(
                      clientsIncome.reduce(
                        (accumulator, client) =>
                          (accumulator +=
                            client.totals.surplus +
                            client.income.deductions.salaryPackage +
                            client.income.deductions.rentalPropertyNonCash +
                            client.income.deductions.rentalPropertyInterest +
                            (client.PayDIV293TaxFromIncome === 0
                              ? client.tax.div293
                              : 0) +
                            (client.PayExcessConcessionalContributionsTaxFromIncome ===
                            0
                              ? client.tax.excessConcessionalContributions
                              : 0) -
                            client.insurances
                              .filter(
                                (insurance) =>
                                  insurance.type !==
                                  Scenarioinsurance_Type.IncomeProtection
                              )
                              .reduce(
                                (insuranceAccumulator, insurance) =>
                                  (insuranceAccumulator +=
                                    insurance.preimumPerYear),
                                0
                              ) -
                            (client.totals.combinedLoanRepayments +
                              client.totals
                                .combinedScenarioassetContributionsFromIncome +
                              client.totals
                                .combinedScenarioliabilityContributions) /
                              clientsIncome.length),
                        0
                      )
                    ),
                    "dollar"
                  )}
                </StyledText>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
