import { QueryResult } from "@apollo/client";
import {
  StaffSubscription,
  FindUniqueStaffQuery,
  MutationType,
  FindUniqueStaffQueryVariables,
} from "../../../codegen/schema";
import { staff } from "../subscription";

interface StaffResolverParams {
  prev: FindUniqueStaffQuery;
  payload: StaffSubscription["staff"];
}

function updateStaff({ prev, payload }: StaffResolverParams) {
  const staff = payload.data;

  if (!prev.findUniqueStaff) return prev;
  return Object.assign({}, prev, {
    findUniqueStaff:
      staff.ID === prev.findUniqueStaff.ID
        ? {
            ...prev.findUniqueStaff,
            ...staff,
          }
        : prev.findUniqueStaff,
  });
}

function staffResolver({ prev, payload }: StaffResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createStaff(prev, payload);

    case MutationType.Update:
      return updateStaff({ prev, payload });

    // case MutationType.Delete:
    //   return deleteStaff({ prev, payload });

    default:
      return prev;
  }
}

export function staffFindUniqueStaff(
  query: Pick<
    QueryResult<FindUniqueStaffQuery, FindUniqueStaffQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: staff,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: StaffSubscription } }
    ) =>
      staffResolver({
        prev,
        payload: payload.subscriptionData.data.staff,
      }),
    variables: query.variables,
  });
}
