import React from "react";
import {
  Content,
  FindUniqueGroupQuery,
  Theme,
  // useUpdateOneGoalMutation,
} from "../../../../../../codegen/schema";
// import { MutationParams } from "../../../../../MutationParams";
import {
  // StyledInput,
  StyledText,
  // StyledTextarea,
} from "../../../../StyledText";

interface GoalIndividualDataProps {
  content?: Content;
  theme?: Theme;
  group: FindUniqueGroupQuery["findUniqueGroup"];
}

export const GoalIndividualData: React.FC<GoalIndividualDataProps> = ({
  content,
  theme,
  group,
}) => {
  const goalObject =
    group && group.goal.length > 0
      ? group.goal.find((goal) => goal.ID === content?.goal_ID)
      : null;

  // const [updateOneGoalMutation] = useUpdateOneGoalMutation({
  //   ...MutationParams("Goal", "updated"),
  // });

  // Resize text area height on load
  // useEffect(() => {
  //   var textarea =
  //     goalObject && document.getElementById("goal-textarea-" + goalObject.ID);

  //   if (textarea) textarea.style.height = textarea.scrollHeight + "px";
  // }, [goalObject]);

  return goalObject ? (
    <div className="w-full">
      <StyledText
        element={theme?.element.find((element) => element.Type === "H5")}
        className="flex items-center "
      >
        {goalObject.Title ?? "Goal Title"}
      </StyledText>

      <StyledText
        element={theme?.element.find((element) => element.Type === "P")}
        className="flex items-center "
      >
        {goalObject.Description ?? "Goal Description"}
      </StyledText>

      {/* <StyledInput
        element={theme?.element.find((element) => element.Type === "H5")}
        className="flex items-center -bottom"
        defaultValue={goalObject.Title ?? "Goal Title"}
        placeholder="Goal Title"
        onBlur={async (event: React.FocusEvent<HTMLInputElement>) => {
          updateOneGoalMutation({
            variables: {
              where: {
                ID: goalObject.ID,
              },
              data: {
                Title: { set: event.target.value },
              },
            },
          });
        }}
      />

      <StyledTextarea
        id={"goal-textarea-" + goalObject.ID}
        element={theme?.element.find((element) => element.Type === "P")}
        className="flex items-center "
        defaultValue={goalObject.Description ?? "Goal Description"}
        placeholder="Goal Title"
        onBlur={async (event: React.FocusEvent<HTMLTextAreaElement>) => {
          updateOneGoalMutation({
            variables: {
              where: {
                ID: goalObject.ID,
              },
              data: {
                Description: { set: event.target.value },
              },
            },
          });
        }}
        onKeyUp={(event) => {
          event.currentTarget.style.height =
            event.currentTarget.scrollHeight + "px";
        }}
      /> */}
    </div>
  ) : (
    <div>Group has no goals</div>
  );
};
