import { QueryResult } from "@apollo/client";
import {
  AddressSubscription,
  FindUniqueAddressQuery,
  FindUniqueAddressQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { address } from "../subscription";

interface AddressResolverParams {
  prev: FindUniqueAddressQuery;
  payload: AddressSubscription["address"];
}

function updateAddress({ prev, payload }: AddressResolverParams) {
  const address = payload.data;

  if (!prev.findUniqueAddress) return prev;
  return Object.assign({}, prev, {
    findUniqueAddress:
      address.ID === prev.findUniqueAddress.ID
        ? {
            ...prev.findUniqueAddress,
            ...address,
          }
        : prev.findUniqueAddress,
  });
}

function deleteAddress({ prev, payload }: AddressResolverParams) {
  const address = payload.data;

  if (!prev.findUniqueAddress) return prev;
  return Object.assign({}, prev, {
    findUniqueAddress:
      prev.findUniqueAddress.ID === address.ID
        ? {
            ...prev.findUniqueAddress,
            undefined,
          }
        : prev.findUniqueAddress,
  });
}

function addressResolver({ prev, payload }: AddressResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createAddress(prev, payload);

    case MutationType.Update:
      return updateAddress({ prev, payload });

    case MutationType.Delete:
      return deleteAddress({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniqueAddress(
  query: Pick<
    QueryResult<FindUniqueAddressQuery, FindUniqueAddressQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: address,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AddressSubscription } }
    ) => 
      addressResolver({
        prev,
        payload: payload.subscriptionData.data.address,
      }),
    variables: query.variables,
  });
}
