import React from "react";
import { LoanRepaymentScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";
import dayjs from "dayjs";

interface LoanRepaymentPlaceholderModelProps {
  theme?: Theme;
}

var data: ScenarioData[] = [];

Array.from(Array(11).keys()).forEach((value, index) => {
  var previousYear = data[value - 1];

  data.push({
    year: dayjs().year() + value,
    offsets: previousYear ? previousYear.offsets + 12000 : 1000,
    loans: previousYear
      ? previousYear.loans + previousYear.interest + previousYear.repayments
      : -520000,
    repayments: 46600,
    interest:
      index === 0
        ? -19630.325981543363
        : index === 1
        ? -18071.52544826977
        : index === 2
        ? -16450.372893665233
        : index === 3
        ? -14764.374236876514
        : index === 4
        ? -13010.935633816247
        : index === 5
        ? -11187.359486633564
        : index === 6
        ? -9290.840293563577
        : index === 7
        ? -7318.460332770792
        : index === 8
        ? -5267.185173546292
        : index === 9
        ? -3133.859007952814
        : index === 10
        ? -915.199795735597
        : 0,
    contributions: 12000,
    withdrawals: 0,
    offsetContributions: 12000,
    offsetWithdrawals: 0,
    loanContributions: 0,
    loanWithdrawals: 0,
    netValue:
      index === 0
        ? -476029.9866681602
        : index === 1
        ? -435501.17280304676
        : index === 2
        ? -393351.20638332877
        : index === 3
        ? -349515.24130682205
        : index === 4
        ? -303925.837627255
        : index === 5
        ? -256512.85780050536
        : index === 6
        ? -207203.35878068567
        : index === 7
        ? -155921.47980007326
        : index === 8
        ? -102588.32566023627
        : index === 9
        ? -47121.84535480583
        : index === 10
        ? 10563.294162841798
        : 0,
    target: index === 10 ? 200000 : undefined,
  });
});

export const LoanRepaymentScenarioPlaceholderModel = ({
  theme,
}: LoanRepaymentPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Loan Repayment
        </StyledText>

        <ModelTooltip type={Model_Type.LoanRepayment} />
      </div>
      <h1 className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest">
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <LoanRepaymentScenarioModelGraph scenarioData={data} liveData={[]} />
      </div>
    </React.Fragment>
  );
};
