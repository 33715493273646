import { QueryResult } from "@apollo/client";
import {
  FindUniqueChildQuery,
  FindUniqueChildQueryVariables,
} from "../../../codegen/schema";
import { notesFindUniqueChild } from "../../notes";
import { FindUniqueChild } from "../reducers";

export default function findUniqueChildSubscription(
  query: Pick<
    QueryResult<FindUniqueChildQuery, FindUniqueChildQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindUniqueChild(query);
  notesFindUniqueChild(query);
}
