import React from "react";
import { Entities, Theme } from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";

interface EntitiesComponentEntityProps {
  entity:
    | Entities
    | Pick<Entities, "Name" | "Type" | "ABN" | "ACN" | "Description">;
  theme?: Theme;
}

export const EntitiesComponentEntity: React.FC<
  EntitiesComponentEntityProps
> = ({ entity, theme }) => {
  if (!entity) return null;

  return (
    <React.Fragment>
      <div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H4")}
            
            style={{ fontSize: "12pt" }}
          >
            {checkFieldIsEmpty(entity.Name)}
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H5")}
            
            style={{ fontSize: "10pt" }}
          >
            {checkFieldIsEmpty(entity.Type)}
          </StyledText>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              
              style={{ fontSize: "10pt" }}
            >
              ABN
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
              style={{ fontSize: "9pt" }}
            >
              {checkFieldIsEmpty(entity.ABN)}
            </StyledText>
          </div>
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              
              style={{ fontSize: "10pt" }}
            >
              ACN
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
              style={{ fontSize: "9pt" }}
            >
              {checkFieldIsEmpty(entity.ACN)}
            </StyledText>
          </div>

          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              
              style={{ fontSize: "10pt" }}
            >
              Description
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
              style={{ fontSize: "9pt" }}
            >
              {checkFieldIsEmpty(entity.Description)}
            </StyledText>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
