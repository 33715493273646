import { gql } from "@apollo/client";
import { advicegoalsFragment } from "../advicegoals";
import { bestinterestFragment } from "../bestinterest";
import { furtheradviceFragment } from "../furtheradvice";
import { notesFragment } from "../notes";

export const reviewCoreFragment = gql`
  fragment reviewCore on review {
    ID
    groups_ID
    groups_organisation_ID
    Year
    Type
    StyleType
    SurplusIncomeCom
    SurplusIncomeRec
    SalaryPackagingCom
    SalaryPackagingRec
    PensionIncomeCom
    PensionIncomeRec
    BusinessTrustCom
    BusinessTrustRec
    FamilyPaymentsCom
    FamilyPaymentsRec
    IncomeSummary
    ManagementCom
    ManagementRec
    OneOffExpensesCom
    OneOffExpensesRec
    ExpensesIncomeCom
    ExpensesIncomeRec
    CashflowSummary
    OffsetManagementCom
    OffsetManagementRec
    LoanStructureCom
    LoanStructureRec
    InterestRateCom
    InterestRateRec
    LoanRepaymentStrategyCom
    LoanRepaymentStrategyRec
    DeductibleVNonCom
    DeductibleVNonRec
    DebtRatioCom
    DebtRatioRec
    LiabilitiesManagementSummary
    HomeCom
    HomeRec
    InvestmentCom
    InvestmentRec
    CashCom
    CashRec
    RetirementSavingsCom
    RetirementSavingsRec
    SharesCom
    SharesRec
    AssetAllocationCom
    AssetAllocationRec
    OverallPerformanceCom
    OverallPerformanceRec
    IndividualInvestmentsCom
    IndividualInvestmentsRec
    InvestmentPlatformsCom
    InvestmentPlatformsRec
    CashHoldingsCom
    CashHoldingsRec
    AssetGrowthSummary
    InvestmentManagementSummary
    CapitalPreservationSummary
    IncomeProtectionCom
    IncomeProtectionRec
    DeathCoverCom
    DeathCoverRec
    TraumaCom
    TraumaRec
    TPDCom
    TPDRec
    BusinessInsuranceCom
    BusinessInsuranceRec
    ChildCom
    ChildRec
    BusinessOverheadCom
    BusinessOverheadRec
    OtherCom
    OtherRec
    RiskManagementManagementSummary
    RiskManagementProductSummary
    RiskManagementSummary
    ContributionsCom
    ContributionsRec
    ReqRateReturnCom
    ReqRateReturnRec
    SuperSplittingCom
    SuperSplittingRec
    DIV293Com
    DIV293Rec
    ExcessContributionsCom
    ExcessContributionsRec
    RetirementSummary
    WillCom
    WillRec
    EnduringPoACom
    EnduringPoARec
    SuperNominationsCom
    SuperNominationsRec
    HealthDirectivesCom
    HealthDirectivesRec
    SpecialConsiderationsCom
    SpecialConsiderationsRec
    EstatePlanningSummary
    FeeReturn
    PropertyAssetTotal
    InvestmentsAssetTotal
    SuperAssetTotal
    HomeAssetTotal
    HomeLoanTotal
    InvestmentLoanTotal
    SMSFLoanTotal
    Date_Time_Created
    Date_Completed
    update_time
    updated_by
    created_by
    Edited
    Time_Elapsed
    Progress
    PrimaryAdviser
    AlternateAdviser
    PrimaryAccountant
    AlternateAccountant
    CSO
    NumberMeetingPerYear
    NumberReviewsPerYear
    ContactDetails
    HistoricalData
    Outcome
    OutcomeDetails
    IncomeCashflowIncluded
    LiabilitiesDebtIncluded
    AssetsInvesetmentsIncluded
    RiskManagementIncluded
    RetirementPlanningIncluded
    EstatePlanningIncluded
    Date_Document_Completed
    ClientSignOffDate
    ReviewPeriodStart
    ReviewPeriodEnd
  }
`;

export const reviewFragment = gql`
  fragment review on review {
    ...reviewCore

    advicegoals {
      ...advicegoals
    }

    bestinterest {
      ...bestinterest
    }

    furtheradvice {
      ...furtheradvice
    }

    notes {
      ...notes
    }
  }
  ${reviewCoreFragment}
  ${notesFragment}
  ${advicegoalsFragment}
  ${bestinterestFragment}
  ${furtheradviceFragment}
`;
