import { gql } from "@apollo/client";
import { superannuationFragment } from ".";

/** Superannuation */
export const createOneSuperannuation = gql`
  mutation CreateOneSuperannuation($data: superannuationCreateInput!) {
    createOneSuperannuation(data: $data) {
      ...superannuation
    }
  }
  ${superannuationFragment}
`;

export const updateOneSuperannuation = gql`
  mutation UpdateOneSuperannuation(
    $data: superannuationUpdateInput!
    $where: superannuationWhereUniqueInput!
  ) {
    updateOneSuperannuation(data: $data, where: $where) {
      ...superannuation
    }
  }
  ${superannuationFragment}
`;
export const deleteOneSuperannuation = gql`
  mutation DeleteOneSuperannuation($where: superannuationWhereUniqueInput!) {
    deleteOneSuperannuation(where: $where) {
      ...superannuation
    }
  }
  ${superannuationFragment}
`;
