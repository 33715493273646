import { QueryResult } from "@apollo/client";
import {
  TermsandconditionsManySubscription,
  FindManyTermsandconditionsQuery,
  MutationType,
  FindManyTermsandconditionsQueryVariables,
} from "../../../codegen/schema";
import { termsandconditionsMany } from "../subscription";

interface TermsandconditionsResolverParams {
  prev: FindManyTermsandconditionsQuery;
  payload: TermsandconditionsManySubscription["termsandconditionsMany"];
}

function createTermsandconditions({
  prev,
  payload,
}: TermsandconditionsResolverParams) {
  const termsandconditions = payload.data;

  if (!prev.findManyTermsandconditions) return prev;
  return Object.assign({}, prev, {
    findManyTermsandconditions: [
      termsandconditions,
      ...prev.findManyTermsandconditions,
    ],
  });
}

function termsandconditionsResolver({
  prev,
  payload,
}: TermsandconditionsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createTermsandconditions({ prev, payload });

    // case MutationType.Update:
    //   return updateTermsandconditions({ prev, payload });

    // case MutationType.Delete:
    //   return deleteTermsandconditions({ prev, payload });

    default:
      return prev;
  }
}

export function FindManyTermsandconditions(
  query: Pick<
    QueryResult<
      FindManyTermsandconditionsQuery,
      FindManyTermsandconditionsQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: termsandconditionsMany,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: TermsandconditionsManySubscription };
      }
    ) =>
      termsandconditionsResolver({
        prev,
        payload: payload.subscriptionData.data.termsandconditionsMany,
      }),
    variables: query.variables,
  });
}
