import { QueryResult } from "@apollo/client";
import {
  ScenariochildSubscription,
  FindUniqueScenarioQuery,
  MutationType,
  FindUniqueScenarioQueryVariables,
} from "../../../codegen/schema";
import { scenariochild } from "../subscription";

interface ScenariochildResolverParams {
  prev: FindUniqueScenarioQuery;
  payload: ScenariochildSubscription["scenariochild"];
}
function createScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenariochild:
        scenariochild.scenario_ID === prev.findUniqueScenario.ID
          ? [...prev.findUniqueScenario.scenariochild, scenariochild]
          : prev.findUniqueScenario.scenariochild,
    },
  });
}

function updateScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenariochild: prev.findUniqueScenario.scenariochild.map(
        (scenariochilds) =>
          scenariochild.ID === scenariochilds.ID
            ? { ...scenariochilds, ...scenariochild }
            : scenariochilds
      ),
    },
  });
}

function deleteScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario: {
      ...prev.findUniqueScenario,
      scenariochild: [...prev.findUniqueScenario.scenariochild].filter(
        (scenariochilds) => scenariochilds.ID !== scenariochild.ID
      ),
    },
  });
}

function scenariochildResolver({ prev, payload }: ScenariochildResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenariochild({ prev, payload });

    case MutationType.Update:
      return updateScenariochild({ prev, payload });

    case MutationType.Delete:
      return deleteScenariochild({ prev, payload });

    default:
      return prev;
  }
}

export function scenariochildFindUniqueScenario(
  query: Pick<
    QueryResult<FindUniqueScenarioQuery, FindUniqueScenarioQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenariochild,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenariochildSubscription } }
    ) =>
      scenariochildResolver({
        prev,
        payload: payload.subscriptionData.data.scenariochild,
      }),
    variables: query.variables,
  });
}
