import remarkGfm from "remark-gfm";
import {
  useCreateOneTermsandconditionsMutation,
  useFindCurrentStaffQuery,
  useFindManyTermsandconditionsQuery,
} from "../../codegen/schema";
import { Button } from "../Buttons";
import { Modal } from "../Modal";
import Markdown from "react-markdown";
import { useContext, useEffect } from "react";
import { StoreContext } from "../../Datastore/Store";
import { ComponentLoader } from "../Loaders/ComponentLoader";
import findManyTermsandconditionsSubscription from "../../API/termsandconditions/subscriptions/findManyTermsandconditions";
import { Termsandconditions_v1 } from "./Versions/v1";
import { Refetch } from "../Refetch";

/**
 * DATES OF TERMS AND CONDITIONS
 * Version 1: 25-10-2023
 */

type TCS_Version = 1;

const latestVersion: TCS_Version = 1;

export default function TermsandconditionsModal() {
  const [, dispatch] = useContext(StoreContext);

  const {
    data: { findCurrentStaff: currentstaff } = {},
    error: currentstaffError,
    loading: currentstaffLoading,
    refetch: currentstaffRefetch,
    // ...findCurrentStaffResult
  } = useFindCurrentStaffQuery({ fetchPolicy: "cache-and-network" });

  const {
    data: { findManyTermsandconditions: termsandconditions } = {},
    error: termsandconditionsError,
    loading: termsandconditionsLoading,
    refetch: termsandconditionsRefetch,
    ...termsandconditionsResult
  } = useFindManyTermsandconditionsQuery({
    fetchPolicy: "cache-and-network",
    skip: !currentstaff,
    variables: {
      where: {
        staff_ID: { equals: currentstaff && currentstaff.ID },
      },
    },
  });

  const [
    createOneTermsandconditions,
    { loading: createOneTermsandconditionsLoading },
  ] = useCreateOneTermsandconditionsMutation({
    onCompleted(_data) {
      dispatch({
        action: "set/success",
        payload: "Terms and conditions accepted.",
      });
    },
    onError(error) {
      dispatch({
        action: "set/error",
        payload: error.message,
      });
    },
  });

  useEffect(() => {
    if (currentstaff && !termsandconditions) {
      findManyTermsandconditionsSubscription(termsandconditionsResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentstaff]);

  if (
    (currentstaffError && !currentstaffLoading) ||
    (termsandconditionsError && !termsandconditionsLoading)
  ) {
    return (
      <Refetch
        refetch={() => {
          currentstaffRefetch();
          termsandconditionsRefetch();
        }}
      />
    );
  }

  if (
    (!currentstaff && currentstaffLoading) ||
    (!termsandconditions && termsandconditionsLoading)
  ) {
    return <ComponentLoader />;
  }

  if (!termsandconditions || !currentstaff) return null;

  if (termsandconditions.some((tcs) => tcs.Version === latestVersion))
    return null;

  return (
    <Modal modalOptions={{ defaultOpen: true, disableClickAway: true }}>
      {({ setOpen }) => (
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center overflow-auto max-h-[600px]">
            <h2>We've updated our terms and conditions</h2>
            <div className="flex flex-col gap-2 py-2">
              <h1>Terms and conditions</h1>
              <Markdown
                remarkPlugins={[remarkGfm]}
                children={Termsandconditions_v1}
              />
            </div>
          </div>
          <div className="pt-4 w-full">
          <Button
            text="Accept"
            type="default"
            loading={createOneTermsandconditionsLoading}
            onClick={
              () =>
                createOneTermsandconditions({
                  variables: {
                    data: {
                      Version: latestVersion,
                      created_by: currentstaff.ID,
                      staff: {
                        connect: {
                          ID_organisation_ID: {
                            ID: currentstaff.ID,
                            organisation_ID: currentstaff.organisation_ID,
                          },
                        },
                      },
                    },
                  },
                })
              // .then(() => setOpen(false))
            }
          />
        </div>
        </div>
      )}
    </Modal>
  );
}
