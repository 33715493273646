import { gql } from "@apollo/client";
import { historyFragment } from "./fragment";

export const history = gql`
  subscription History($where: historyWhereUniqueInput) {
    history(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...history
      }
      assets_has_history {
        history_ID
        assets_ID
      }
      liabilities_has_history {
        history_ID
        liabilities_ID
      }
    }
  }
  ${historyFragment}
`;

export const historyMany = gql`
  subscription HistoryMany($where: historyWhereInput) {
    historyMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...history
      }
      assets_has_history {
        history_ID
        assets_ID
      }
      liabilities_has_history {
        history_ID
        liabilities_ID
      }
    }
  }
  ${historyFragment}
`;
