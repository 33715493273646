import React, { useContext } from "react";

import {
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { StoreContext } from "../../../../../../../Datastore/Store";
import { ComparisonData } from "../../../Calculations";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  comparisonData: ComparisonData[] | undefined;
}

export const SuperannuationContributionSplittingComparisonModelGraph: React.FC<
  Props
> = ({ comparisonData }) => {
  const [context] = useContext(StoreContext);

  if (!comparisonData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={comparisonData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />

        <XAxis
          interval={comparisonData.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>

        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />

        <ReferenceLine y={0} stroke="#000" />

        {comparisonData[0] &&
          comparisonData
            .reduce<string[]>(
              (accumulator, entry) => [
                ...new Set([
                  ...accumulator,
                  ...Object.keys(entry).filter(
                    (key) => key !== "year" && key !== "transferBalanceCap"
                  ),
                ]),
              ],
              []
            )
            .map((key, keyIndex) => (
              <Line
                key={key}
                dataKey={key}
                stroke={
                  keyIndex === 0
                    ? context.colours.blue
                    : keyIndex === 1
                    ? context.colours.lightPurple300
                    : keyIndex === 2
                    ? context.colours.orange
                    : keyIndex === 3
                    ? context.colours.yellow
                    : context.colours[
                        Object.keys(context.colours)[keyIndex + 1]
                      ]
                }
                isAnimationActive={false}
              />
            ))}

        <Line
          dataKey={"transferBalanceCap"}
          stroke={context.colours.green400}
          isAnimationActive={false}
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
