import { QueryResult } from "@apollo/client";
import {
  FindUniqueReviewQuery,
  MutationType,
  AdvicegoalsSubscription,
  FindUniqueReviewQueryVariables,
} from "../../../codegen/schema";
import { advicegoals } from "../subscription";

interface AdvicegoalsResolverParams {
  prev: FindUniqueReviewQuery;
  payload: AdvicegoalsSubscription["advicegoals"];
}

function createAdvicegoals({ prev, payload }: AdvicegoalsResolverParams) {
  const advicegoals = payload.data;

  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview: {
      ...prev.findUniqueReview,
      advicegoals:
        advicegoals.review_ID === prev.findUniqueReview.ID
          ? [...prev.findUniqueReview.advicegoals, advicegoals]
          : prev.findUniqueReview.advicegoals,
    },
  });
}

function updateAdvicegoals({ prev, payload }: AdvicegoalsResolverParams) {
  const advicegoals = payload.data;

  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview: {
      ...prev.findUniqueReview,
      advicegoals: prev.findUniqueReview.advicegoals.map((advicegoal) =>
        advicegoal.ID === advicegoals.ID
          ? { ...advicegoal, ...advicegoals }
          : advicegoal
      ),
    },
  });
}

function deleteAdvicegoals({ prev, payload }: AdvicegoalsResolverParams) {
  const advicegoals = payload.data;

  if (!prev.findUniqueReview) return prev;
  return Object.assign({}, prev, {
    findUniqueReview: {
      ...prev.findUniqueReview,
      advicegoals: [...prev.findUniqueReview.advicegoals].filter(
        (advicegoal) => advicegoal.ID !== advicegoals.ID
      ),
    },
  });
}

function advicegoalsResolver({ prev, payload }: AdvicegoalsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAdvicegoals({ prev, payload });

    case MutationType.Update:
      return updateAdvicegoals({ prev, payload });

    case MutationType.Delete:
      return deleteAdvicegoals({ prev, payload });

    default:
      return prev;
  }
}

export function advicegoalsFindUniqueReview(
  query: Pick<
    QueryResult<FindUniqueReviewQuery, FindUniqueReviewQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: advicegoals,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdvicegoalsSubscription } }
    ) =>
      advicegoalsResolver({
        prev,
        payload: payload.subscriptionData.data.advicegoals,
      }),
    variables: query.variables,
  });
}
