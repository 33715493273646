import { QueryResult } from "@apollo/client";
import {
  ScenarioSubscription,
  FindUniqueScenarioQuery,
  MutationType,
  FindUniqueScenarioQueryVariables,
} from "../../../codegen/schema";
import { scenario } from "../subscription";

interface ScenarioResolverParams {
  prev: FindUniqueScenarioQuery;
  payload: ScenarioSubscription["scenario"];
}

function updateScenario({ prev, payload }: ScenarioResolverParams) {
  const scenario = payload.data;

  if (!prev.findUniqueScenario) return prev;

  return Object.assign({}, prev, {
    findUniqueScenario:
      scenario.ID === prev.findUniqueScenario.ID
        ? {
            ...prev.findUniqueScenario,
            ...scenario,
          }
        : prev.findUniqueScenario,
  });
}

// function deleteScenario({ prev, payload }: ScenarioResolverParams) {
//   const scenario = payload.data;

//   if (!prev.findUniqueScenario) return prev;
//   return Object.assign({}, prev, {
//     findUniqueScenario:
//       prev.findUniqueScenario.ID === scenario.ID
//         ? {
//             ...prev.findUniqueScenario,
//             undefined,
//           }
//         : prev.findUniqueScenario,
//   });
// }

function scenarioResolver({ prev, payload }: ScenarioResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createScenario(prev, payload);

    case MutationType.Update:
      return updateScenario({ prev, payload });

    // case MutationType.Delete:
    //   return deleteScenario({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioFindUniqueScenario(
  query: Pick<
    QueryResult<FindUniqueScenarioQuery, FindUniqueScenarioQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenario,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioSubscription } }
    ) =>
      scenarioResolver({
        prev,
        payload: payload.subscriptionData.data.scenario,
      }),
    variables: query.variables,
  });
}
