import { gql } from "@apollo/client";
import { scenarioclientFragment } from "./fragment";

export const createOneScenarioclient = gql`
  mutation CreateOneScenarioclient($data: scenarioclientCreateInput!) {
    createOneScenarioclient(data: $data) {
      ...scenarioclient
    }
  }
  ${scenarioclientFragment}
`;

export const updateOneScenarioclient = gql`
  mutation UpdateOneScenarioclient(
    $data: scenarioclientUpdateInput!
    $where: scenarioclientWhereUniqueInput!
  ) {
    updateOneScenarioclient(data: $data, where: $where) {
      ...scenarioclient
    }
  }
  ${scenarioclientFragment}
`;

export const deleteOneScenarioclient = gql`
  mutation DeleteOneScenarioclient($where: scenarioclientWhereUniqueInput!) {
    deleteOneScenarioclient(where: $where) {
      ...scenarioclient
    }
  }
  ${scenarioclientFragment}
`;
