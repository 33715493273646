import { gql } from "@apollo/client";

export const staffFragment = gql`
  fragment staff on staff {
    ID
    organisation_ID
    Title
    FirstName
    MiddleName
    PreferredName
    LastName
    PostNominals
    CreditLicenceNumber
    CreditLicenceeNumber
    CreditLicensee
    ASICNumber
    DOB
    MobileNumber
    HomeNumber
    WorkNumber
    PersonalEmail
    WorkEmail
    Skype
    SocialMedia
    Coffee
    StartDate
    AddressLine1
    AddressLine2
    Suburb
    Postcode
    State
    Country
    Employer
    Team
    Photo
    AdviserProfileLink
    FSGHyperlink
    FSGCreatedDate
    OtherLicensingInformation
    TaskView
    create_time
    update_time
    created_by
    updated_by
  }
`;
