import { QueryResult } from "@apollo/client";
import {
  UserSubscription,
  FindCurrentUserQuery,
  MutationType,
  FindCurrentUserQueryVariables,
} from "../../../codegen/schema";
import { user } from "../subscription";

interface UserResolverParams {
  prev: FindCurrentUserQuery;
  payload: UserSubscription["user"];
}

function updateUser({ prev, payload }: UserResolverParams) {
  const user = payload.data;

  if (!prev.findCurrentUser) return prev;
  return Object.assign({}, prev, {
    findCurrentUser:
      user.staff_ID === prev.findCurrentUser.staff_ID
        ? {
            ...prev.findCurrentUser,
            ...user,
          }
        : prev.findCurrentUser,
  });
}

function deleteUser({ prev, payload }: UserResolverParams) {
  const user = payload.data;

  if (!prev.findCurrentUser) return prev;
  return Object.assign({}, prev, {
    findCurrentUser:
      prev.findCurrentUser.staff_ID === user.staff_ID
        ? {
            ...prev.findCurrentUser,
            undefined,
          }
        : prev.findCurrentUser,
  });
}

// function logout(prev, payload, query) {
//   const { data: logout } = payload.subscriptionData.data.userLogoutSubscription;

//   if (prev.findCurrentUser.staff_ID === logout.staff_ID) {
//     query.client.clearStore();
//   }
//   return Object.assign({}, prev, {
//     findCurrentUser:
//       prev.findCurrentUser.staff_ID === logout.staff_ID
//         ? null
//         : prev.findCurrentUser,
//   });
// }

function userResolver({ prev, payload }: UserResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createUser(prev, payload);

    case MutationType.Update:
      return updateUser({ prev, payload });

    case MutationType.Delete:
      return deleteUser({ prev, payload });

    default:
      return prev;
  }
}

export function userFindCurrentUser(
  query: Pick<
    QueryResult<FindCurrentUserQuery, FindCurrentUserQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: user,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: UserSubscription } }
    ) =>
      userResolver({
        prev,
        payload: payload.subscriptionData.data.user,
      }),
    variables: query.variables,
  });
}
