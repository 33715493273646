import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Editor, Element as SlateElement } from "slate";
import { ListType } from "@prezly/slate-lists";

import { Element, FindUniqueThemeQuery } from "../../../codegen/schema";

import { CustomEditor } from "../Functions";
import { insertLink } from "../Functions/links";
import { TextElement } from "../Functions/types";
import { toggleListBlock } from "../Functions/lists";

import { SlateSelected } from "../Types";
import { Button } from "../../Buttons";
import { ErrorBoundary } from "../ErrorBoundary";
import { ButtonMenu } from "../../Menus/ButtonMenu";
import { Combobox, ComboboxOptionType } from "../../Inputs/Combobox";
import { ColourInput } from "../../Inputs/Input/ColourInput";
import { Input } from "../../Inputs/Input";

interface TextEditorSidebarProps {
  disabled?: boolean;
  editor?: Editor;
  theme?: FindUniqueThemeQuery["findUniqueTheme"];
  selected?: SlateSelected;
}

export const Toolbar: React.FC<TextEditorSidebarProps> = ({
  disabled,
  editor,
  theme,
  selected,
}) => {
  const [selectedElement, setSelectedElement] = useState(
    editor && editor.selection
      ? Editor.fragment(editor, editor.selection)[0]
      : undefined
  );

  const [selectedMarks, setSelectedMarks] = useState(
    editor && editor.selection ? Editor.marks(editor) : undefined
  );

  const [defaultElement, setDefaultElement] = useState<Element | undefined>(
    undefined
  );
  const [selectedHeaderStyle, setSelectedHeaderStyle] = useState<
    Element | undefined
  >(undefined);

  useEffect(() => {
    setSelectedElement(
      editor && editor.selection
        ? Editor.fragment(editor, editor.selection)[0]
        : undefined
    );

    setSelectedMarks(
      editor && editor.selection ? Editor.marks(editor) : undefined
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, editor, editor?.selection]);

  useEffect(() => {
    if (editor && editor.selection) {
      CustomEditor.reSelect({
        editor: editor,
        target: editor.selection,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarks, selectedElement]);

  useEffect(() => {
    theme &&
      setDefaultElement(theme.element.find((element) => element.Type === "P"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  useEffect(() => {
    selectedElement &&
      setSelectedHeaderStyle(
        theme?.element.find((element) => {
          return (
            SlateElement.isElement(selectedElement) &&
            element.Type === selectedElement.type
          );
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElement]);

  return (
    <ErrorBoundary editor={editor}>
      <div
        className="flex items-center gap-1 divide-x bg-neutral-50 rounded-full divide-neutral-100 toolbar-inputs ml-2 mr-4 pl-4 mb-2"
        id="slatedocuments-toolbar"
      >
        {/* Headings  */}
        <div className="w-28">
          {theme ? (
            <Combobox
              defaultValue={
                selectedHeaderStyle
                  ? {
                      id: selectedHeaderStyle.ID,
                      value: selectedHeaderStyle.Name ?? "",
                      JSON: JSON.stringify(selectedHeaderStyle),
                    }
                  : defaultElement
                  ? {
                      id: defaultElement.ID,
                      value: defaultElement.Name ?? "",
                      JSON: JSON.stringify(defaultElement),
                    }
                  : {
                      id: 0,
                      value: "Default style",
                      JSON: JSON.stringify({
                        Name: "Default style",
                        Type: "P",
                        FontSize: 10,
                        FontFamily: "Inter",
                        FontWeight: 400,
                        Colour: "#000000",
                      }),
                    }
              }
              options={theme.element
                .filter((element) => element.Type !== "PageNo")
                .map((element) => ({
                  id: element.ID,
                  value: element.Name ?? "",
                  JSON: JSON.stringify(element),
                }))}
              mutation={(value: ComboboxOptionType) => {
                editor &&
                  CustomEditor.setHeaderStyle({
                    editor,
                    element: JSON.parse(value.JSON!),
                  });

                setSelectedElement(
                  editor && editor.selection
                    ? Editor.fragment(editor, editor.selection)[0]
                    : undefined
                );

                setSelectedMarks(
                  editor && editor.selection ? Editor.marks(editor) : undefined
                );
              }}
              disabled={
                editor && (disabled !== undefined ? !disabled : true)
                  ? false
                  : true
              }
            />
          ) : (
            <i className="text-2xs text-neutral-400">No theme selected</i>
          )}
        </div>

        {/* Font family  */}
        <div>
          <Combobox
            defaultValue={
              selectedMarks?.fontFamily
                ? { id: 0, value: selectedMarks.fontFamily }
                : defaultElement && defaultElement.FontFamily
                ? { id: defaultElement.ID, value: defaultElement.FontFamily }
                : {
                    id: 0,
                    value: "Poppins",
                  }
            }
            options={[
              "Assistant",
              "Cabin",
              "Inter",
              "Jost",
              "Lane",
              "Lato",
              "League Spartan",
              "Lora",
              "Montserrat",
              "Mulish",
              "Noto Serif",
              "Nunito",
              "Open Sans",
              "Poppins",
              "Raleway",
              "Roboto",
              "Source Sans Pro",
              "Ubuntu",
              "Work Sans",
            ].map((font, index) => ({ id: index, value: font }))}
            mutation={(value: ComboboxOptionType) => {
              editor &&
                CustomEditor.toggleMark({
                  editor,
                  format: "fontFamily",
                  value: value.value,
                });

              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            fontselect
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
          />
        </div>

        {/* Font Size  */}
        <div className="w-24">
          <Combobox
            defaultValue={
              selectedMarks?.fontSize
                ? { id: 0, value: selectedMarks.fontSize.toString() }
                : defaultElement && defaultElement.FontSize
                ? {
                    id: defaultElement.ID,
                    value: defaultElement.FontSize.toString(),
                  }
                : {
                    id: 0,
                    value: "12",
                  }
            }
            options={[
              "8",
              "9",
              "10",
              "11",
              "12",
              "14",
              "15",
              "16",
              "18",
              "20",
              "22",
              "24",
              "28",
              "32",
            ].map((size, index) => ({ id: index, value: size }))}
            mutation={(value: ComboboxOptionType) => {
              editor &&
                CustomEditor.toggleMark({
                  editor,
                  format: "fontSize",
                  value: value.value,
                });

              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
          />
        </div>

        {/* Font Weight */}
        <div className="w-28">
          <Combobox
            defaultValue={
              selectedMarks?.fontWeight
                ? {
                    id: selectedMarks?.fontWeight,
                    value:
                      selectedMarks?.fontWeight === 300
                        ? "Light"
                        : selectedMarks?.fontWeight === 400
                        ? "Regular"
                        : selectedMarks?.fontWeight === 500
                        ? "Medium"
                        : selectedMarks?.fontWeight === 600
                        ? "Semi-bold"
                        : selectedMarks?.fontWeight === 700
                        ? "Bold"
                        : "Extra-bold",
                  }
                : {
                    id: 400,
                    value: "Regular",
                  }
            }
            options={[
              { value: "Light", id: 300 },
              { value: "Regular", id: 400 },
              { value: "Medium", id: 500 },
              { value: "Semi-bold", id: 600 },
              { value: "Bold", id: 700 },
              { value: "Extra-bold", id: 800 },
            ]}
            mutation={(value: ComboboxOptionType) => {
              editor &&
                CustomEditor.toggleMark({
                  editor,
                  format: "fontWeight",
                  value: value.id,
                });
              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
          />
        </div>

        {/* Colour + Highlight */}
        <div className="w-16">
          <ColourInput
            name="Colour"
            defaultValue={
              selectedMarks?.color
                ? selectedMarks.color
                : defaultElement && defaultElement.Colour
                ? defaultElement.Colour
                : "#000000"
            }
            theme={theme ?? undefined}
            onChange={(value: string) => {
              editor &&
                CustomEditor.toggleMark({
                  editor,
                  format: "color",
                  value: value,
                });
              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
          />
        </div>
        <div className="w-16">
          <ColourInput
            name="Highlight"
            defaultValue={
              selectedMarks?.backgroundColor
                ? selectedMarks.backgroundColor
                : "#FFFFFF"
            }
            theme={theme ?? undefined}
            onChange={(value: string) => {
              editor &&
                CustomEditor.toggleMark({
                  editor,
                  format: "backgroundColor",
                  value: value,
                });
              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
            icon={"fa-regular fa-highlighter"}
          />
        </div>

        {/* Bold + Italic + Underline */}
        {["bold", "italic", "underline"].map((item) => {
          return (
            <div
              key={item}
              className={clsx(
                `fa-${item}`,
                (selectedMarks?.bold && item === "bold") ||
                  (selectedMarks?.italic && item === "italic") ||
                  (selectedMarks?.underline && item === "underline")
                  ? "text-blue-500"
                  : "",
                "icon fa-regular cursor-pointer w-6 justify-center "
                // (!editor && "disabled "),
                // (selectedMarks?.bold
                //   ? " selected"
                //   : !selectedMarks &&
                //     defaultElement &&
                //     defaultElement.FontWeight &&
                //     defaultElement.FontWeight >= 600
                //   ? " selected"
                //   : "")
              )}
              onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault();

                editor &&
                  CustomEditor.toggleMark({
                    editor,
                    format: item as "bold" | "italic" | "underline",
                    value: true,
                  });

                setSelectedMarks(
                  editor && editor.selection ? Editor.marks(editor) : undefined
                );
              }}
            />
          );
        })}

        <div
          className={
            "icon fa-regular fa-link-horizontal pointer w-10 justify-center " +
            (!editor && "disabled ") +
            (SlateElement.isElement(selectedElement) &&
            selectedElement?.type === "link"
              ? "selected"
              : "")
          }
          onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            const url = window.prompt("Enter the URL of the link:");
            if (!url) return;
            editor && insertLink(editor, url);
          }}
        />

        {/* Line Height  */}
        <div>
          <ButtonMenu
            buttonProps={{
              type: "no-style",
              text: <i className="icon fa-regular fa-line-height" />,
              disabled:
                editor && (disabled !== undefined ? !disabled : true)
                  ? false
                  : true,
            }}
            options={["Single", "1.15", "1.5", "Double"].map((value) => ({
              text: value,
              onClick: () => {
                editor &&
                  CustomEditor.setLineHeight({
                    editor,
                    value:
                      value === "Single"
                        ? 1
                        : value === "Double"
                        ? 2
                        : parseFloat(value),
                  });
                setSelectedMarks(
                  editor && editor.selection ? Editor.marks(editor) : undefined
                );
              },
            }))}
          />
        </div>

        {/* Letter Spacing  */}
        <div className="w-16">
          <Input
            name="LetterSpacing"
            defaultValue={selectedMarks?.letterSpacing || 0}
            type="number"
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              editor &&
                CustomEditor.toggleMark({
                  editor,
                  format: "letterSpacing",
                  value: event.target.value,
                });
              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
            adornment={"fa-regular fa-text-width"}
            min={0}
          />
        </div>

        {/* Unordered List  */}
        <div>
          <Button
            type="no-style"
            text={
              <div
                className={clsx(
                  selectedElement &&
                    SlateElement.isElement(selectedElement) &&
                    selectedElement.type === "ul"
                    ? "bg-blue-100 text-semantic-info-500"
                    : "",
                  `rounded-md flex justify-center h-9 w-9 py-2
                 text-neutral-300 icon fa-regular fa-list-ul cursor-pointer hover:text-semantic-info-500`
                )}
              />
            }
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              editor &&
                toggleListBlock({
                  editor,
                  format: ListType.UNORDERED,
                });

              setSelectedElement(
                editor && editor.selection
                  ? Editor.fragment(editor, editor.selection)[0]
                  : undefined
              );
              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
          />
        </div>
        {/* Ordered List  */}
        <div>
          <Button
            type="no-style"
            text={
              <div
                className={clsx(
                  selectedElement &&
                    SlateElement.isElement(selectedElement) &&
                    selectedElement.type === "ol"
                    ? "bg-blue-100 text-semantic-info-500"
                    : "",
                  `rounded-md flex justify-center h-9 w-9 py-2
                 text-neutral-300 icon fa-regular fa-list-ol cursor-pointer hover:text-semantic-info-500`
                )}
              />
            }
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              editor &&
                toggleListBlock({
                  editor,
                  format: ListType.ORDERED,
                });

              setSelectedElement(
                editor && editor.selection
                  ? Editor.fragment(editor, editor.selection)[0]
                  : undefined
              );
              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
          />
        </div>
        {/* <div>
          <Button
            type="no-style"
            text={
              <div
                className={clsx(
                  // selectedElement &&
                  //   SlateElement.isElement(selectedElement) &&
                  //   selectedElement.columns  === 2
                  //   ? "bg-blue-100 text-semantic-info-500"
                  //   : "",
                  `rounded-md flex justify-center h-9 w-9 py-2
                 text-neutral-300 icon fa-regular fa-line-columns cursor-pointer hover:text-semantic-info-500`
                )}
              />
            }
            // selected={() => {
            //   var element = { ...selectedElement } as TextElement;

            //   if (
            //     element &&
            //     SlateElement.isElement(element) &&
            //     element.columns === 2
            //   ) {
            //     return true;
            //   } else {
            //     return false;
            //   }
            // }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();

              editor && CustomEditor.toggleBlock({ editor, format: "columns" });

              setSelectedElement(
                editor && editor.selection
                  ? Editor.fragment(editor, editor.selection)[0]
                  : undefined
              );
              setSelectedMarks(
                editor && editor.selection ? Editor.marks(editor) : undefined
              );
            }}
            disabled={
              editor && (disabled !== undefined ? !disabled : true)
                ? false
                : true
            }
          />
        </div> */}

        {/* Alignment  */}
        {["left", "center", "right", "justify"].map((alignment, index) => {
          var element = { ...selectedElement } as TextElement;

          var defaultAlignment =
            element && SlateElement.isElement(element) && element.align
              ? element.align
              : defaultElement
              ? defaultElement.Alignment
              : "left";

          return (
            <div key={index}>
              <Button
                type="no-style"
                ariapressed={defaultAlignment === alignment}
                text={
                  <div
                    className={clsx(
                      defaultAlignment === alignment
                        ? "bg-blue-100 text-semantic-info-500"
                        : "",
                      `rounded-md flex justify-center h-9 w-9 py-2
                        text-neutral-300 icon fa-regular cursor-pointer fa-align-${alignment} hover:text-semantic-info-500`
                    )}
                  />
                }
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();

                  editor &&
                    CustomEditor.toggleBlock({ editor, format: alignment });

                  setSelectedElement(
                    editor && editor.selection
                      ? Editor.fragment(editor, editor.selection)[0]
                      : undefined
                  );
                  setSelectedMarks(
                    editor && editor.selection
                      ? Editor.marks(editor)
                      : undefined
                  );
                }}
                disabled={
                  editor && (disabled !== undefined ? !disabled : true)
                    ? false
                    : true
                }
              />
            </div>
          );
        })}
      </div>
    </ErrorBoundary>
  );
};
