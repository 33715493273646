import { QueryResult } from "@apollo/client";
import {
  FindUniqueChildQuery,
  FindUniqueChildQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueChildQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, child_has_notes } = payload;

  if (!prev.findUniqueChild) return prev;
  return Object.assign({}, prev, {
    findUniqueChild: {
      ...prev.findUniqueChild,
      notes: child_has_notes.some(
        (relation) => relation.child_ID === prev.findUniqueChild?.ID
      )
        ? [...prev.findUniqueChild.notes, notes]
        : prev.findUniqueChild.notes,
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueChild) return prev;
  return Object.assign({}, prev, {
    findUniqueChild: {
      ...prev.findUniqueChild,
      notes: prev.findUniqueChild.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueChild) return prev;
  return Object.assign({}, prev, {
    findUniqueChild: {
      ...prev.findUniqueChild,
      notes: prev.findUniqueChild.notes.filter((note) => note.ID !== notes.ID),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueChild(
  query: Pick<
    QueryResult<FindUniqueChildQuery, FindUniqueChildQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes
      }),
    variables: query.variables,
  });
}
