import { gql } from "@apollo/client";
import { scenarioFragment } from ".";
/** Scenario */
export const createOneScenario = gql`
  mutation CreateOneScenario($data: scenarioCreateInput!) {
    createOneScenario(data: $data) {
      ...scenario
    }
  }
  ${scenarioFragment}
`;

export const updateOneScenario = gql`
  mutation UpdateOneScenario(
    $data: scenarioUpdateInput!
    $where: scenarioWhereUniqueInput!
  ) {
    updateOneScenario(data: $data, where: $where) {
      ...scenario
    }
  }
  ${scenarioFragment}
`;

export const deleteOneScenario = gql`
  mutation DeleteOneScenario($where: scenarioWhereUniqueInput!) {
    deleteOneScenario(where: $where) {
      ...scenario
    }
  }
  ${scenarioFragment}
`;
