import { QueryResult } from "@apollo/client";
import {
  PayeeSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { payee } from "../subscription";

interface PayeeResolverParams {
  prev: FindUniqueGroupQuery;
  payload: PayeeSubscription["payee"];
}

function createPayee({ prev, payload }: PayeeResolverParams) {
  const { data: payee, services_has_payee } = payload;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement: prev.findUniqueGroup.adviceagreement.map(
        (agreement) => ({
          ...agreement,
          services: agreement.services.map((service) =>
            services_has_payee.some((entry) => entry.services_ID === service.ID)
              ? {
                  ...service,
                  payee: [...service.payee, payee],
                }
              : service
          ),
        })
      ),
    },
  });
}

function updatePayee({ prev, payload }: PayeeResolverParams) {
  const payee = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      adviceagreement: prev.findUniqueGroup.adviceagreement.map(
        (agreement) => ({
          ...agreement,
          services: agreement.services.map((service) => {
            return {
              ...service,
              payee: service.payee.map((pay) =>
                pay.ID === payee.ID
                  ? {
                      ...pay,
                      ...payee,
                    }
                  : { ...pay }
              ),
            };
          }),
        })
      ),
    },
  });
}

function deletePayee({ prev, payload }: PayeeResolverParams) {
  const payee = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,

      adviceagreement: prev.findUniqueGroup.adviceagreement.map(
        (agreement) => ({
          ...agreement,
          services: agreement.services.map((service) => {
            return {
              ...service,
              payee: [...service.payee].filter((pay) => pay.ID !== payee.ID),
            };
          }),
        })
      ),
    },
  });
}

function payeeResolver({ prev, payload }: PayeeResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createPayee({ prev, payload });

    case MutationType.Update:
      return updatePayee({ prev, payload });

    case MutationType.Delete:
      return deletePayee({ prev, payload });

    default:
      return prev;
  }
}

export function payeeFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: payee,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: PayeeSubscription } }
    ) =>
      payeeResolver({
        prev,
        payload: payload.subscriptionData.data.payee
      }),
    variables: query.variables,
  });
}
