import {
  CollectiblesSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { collectibles } from "../subscription";
import { QueryResult } from "@apollo/client";

interface CollectiblesResolverParams {
  prev: FindUniqueGroupQuery;
  payload: CollectiblesSubscription["collectibles"];
}

function createCollectibles({ prev, payload }: CollectiblesResolverParams) {
  const { data: createCollectibles, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            collectibles: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.collectibles, createCollectibles]
              : client.assets.collectibles,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            collectibles: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.collectibles, createCollectibles]
              : entity.assets.collectibles,
          },
        };
      }),
    },
  });
}

function updateCollectibles({ prev, payload }: CollectiblesResolverParams) {
  const { data: updateCollectibles, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the collectibles object
   * This is because updateCollectibles only returns the collectibles info
   * without assetallocation, notes, etc.
   */
  var updatedCollectibles = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.collectibles.forEach((collectible) => {
      if (collectible.assets_ID === updateCollectibles.assets_ID) {
        result = {
          ...collectible,
          ...updateCollectibles,
        };
      }
    });
    return result;
  }, updateCollectibles);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update collectibles first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          collectibles: [
            ...client.assets.collectibles
              .map((collectibles) =>
                collectibles.ID === updatedCollectibles.ID
                  ? { ...collectibles, ...updatedCollectibles }
                  : collectibles
              )
              .filter(
                (collectibles) =>
                  ![...data.clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(collectibles.assets_ID)
              ),
            ...(data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? data.clients_has_assets.reduce<
                  Array<CollectiblesSubscription["collectibles"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedCollectibles.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedCollectibles];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    collectibles: liability.assets.collectibles.map(
                      (collectibles) =>
                        collectibles.ID === updateCollectibles.ID
                          ? {
                              ...collectibles,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateCollectibles,
                            }
                          : collectibles /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            collectibles: goal.assets.collectibles.map((collectibles) =>
              collectibles.ID === updatedCollectibles.ID
                ? { ...collectibles, ...updatedCollectibles }
                : collectibles
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      collectibles: liability.assets.collectibles.map(
                        (collectibles) =>
                          collectibles.ID === updateCollectibles.ID
                            ? {
                                ...collectibles,
                                /** Update loan info */
                                ...updateCollectibles,
                              }
                            : collectibles
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing collectibles object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          collectibles: [
            ...entity.assets.collectibles
              .map((collectibles) =>
                collectibles.ID === updatedCollectibles.ID
                  ? { ...collectibles, ...updatedCollectibles }
                  : collectibles
              )
              .filter(
                (collectibles) =>
                  ![...data.entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(collectibles.assets_ID)
              ),
            ...(data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? data.entities_has_assets.reduce<
                  Array<CollectiblesSubscription["collectibles"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedCollectibles.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedCollectibles];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    collectibles: liability.assets.collectibles.map(
                      (collectibles) =>
                        collectibles.ID === updateCollectibles.ID
                          ? {
                              ...collectibles,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateCollectibles,
                            }
                          : collectibles /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteCollectibles({ prev, payload }: CollectiblesResolverParams) {
  const { data: deleteCollectibles } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            collectibles: [...client.assets.collectibles].filter(
              (collectible) => collectible.ID !== deleteCollectibles.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      collectibles: liability.assets.collectibles.filter(
                        (collectibles) =>
                          collectibles.ID !== deleteCollectibles.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              collectibles: goal.assets.collectibles.filter(
                (collectible) => collectible.ID !== deleteCollectibles.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        collectibles: liability.assets.collectibles.filter(
                          (collectibles) =>
                            collectibles.ID !== deleteCollectibles.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            collectibles: [...entity.assets.collectibles].filter(
              (collectible) => collectible.ID !== deleteCollectibles.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      collectibles: liability.assets.collectibles.filter(
                        (collectibles) =>
                          collectibles.ID !== deleteCollectibles.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function collectiblesResolver({ prev, payload }: CollectiblesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createCollectibles({ prev, payload });

    case MutationType.Update:
      return updateCollectibles({ prev, payload });

    case MutationType.Delete:
      return deleteCollectibles({ prev, payload });

    default:
      return prev;
  }
}

export function collectiblesFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: collectibles,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: CollectiblesSubscription } }
    ) =>
      collectiblesResolver({
        prev,
        payload: payload.subscriptionData.data.collectibles
      }),
    variables: query.variables,
  });
}
