import {
  Content_ModelToRender,
  Contenttemplate_ModelToRender,
  Maybe,
  Theme,
} from "../../../../../../codegen/schema";
import { PropertyValueComparisonPlaceholderModel } from "../Comparison/Placeholder";
import { PropertyValueScenarioPlaceholderModel } from "../Scenario/Placeholder";

interface PropertyValueComparisonModelPlaceholderProps {
  ModelToRender?:
    | Maybe<Content_ModelToRender>
    | Maybe<Contenttemplate_ModelToRender>;
  theme?: Theme;
}

export const PropertyValueModelPlaceholder = ({
  ModelToRender,
  theme,
}: PropertyValueComparisonModelPlaceholderProps) => {
  if (
    ModelToRender === Content_ModelToRender.Comparison ||
    ModelToRender === Contenttemplate_ModelToRender.Comparison
  ) {
    return <PropertyValueComparisonPlaceholderModel theme={theme} />;
  } else if (
    ModelToRender === Content_ModelToRender.Scenario ||
    ModelToRender === Contenttemplate_ModelToRender.Scenario
  ) {
    return <PropertyValueScenarioPlaceholderModel theme={theme} />;
  } else return <p>Please choose a Scenario model or Comparison model.</p>;
};
