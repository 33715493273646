import styled from "styled-components";
import { Element } from "../../../codegen/schema";

interface TextProps {
  element: Element | undefined;
}

export const StyledText = styled.p`
  ${({ element }: TextProps) =>
    element
      ? `
        font-family: ${element.FontFamily};
        font-size: ${element.FontSize}pt;
        font-weight: ${element.FontWeight};
        color: ${element.Colour};
        text-align: ${element.Alignment};
        letter-spacing: ${element.LetterSpacing}px;
        line-height: ${element.LineHeight || "1"};
      `
      : ""}
`;

export const StyledInput = styled.input`
  ${({ element }: TextProps) =>
    element
      ? `
        font-family: ${element.FontFamily};
        font-size: ${element.FontSize}pt;
        font-weight: ${element.FontWeight};
        color: ${element.Colour};
        text-align: ${element.Alignment};
        letter-spacing: ${element.LetterSpacing}px;
        line-height: ${element.LineHeight || "1"};
      `
      : ""}
`;

export const StyledTextarea = styled.textarea`
  ${({ element }: TextProps) =>
    element
      ? `
        font-family: ${element.FontFamily};
        font-size: ${element.FontSize}pt;
        font-weight: ${element.FontWeight};
        color: ${element.Colour};
        text-align: ${element.Alignment};
        letter-spacing: ${element.LetterSpacing}px;
        line-height: ${element.LineHeight || "1"};
      `
      : ""}
`;
