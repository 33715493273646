import {
  ScenarioassetSubscription,
  FindUniqueAdviceQuery,
  MutationType,
  FindUniqueAdviceQueryVariables,
} from "../../../codegen/schema";
import { scenarioasset } from "../subscription";
import { QueryResult } from "@apollo/client";

interface ScenarioassetResolverParams {
  prev: FindUniqueAdviceQuery;
  payload: ScenarioassetSubscription["scenarioasset"];
}

function createScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioasset.scenario_ID
          ? {
              ...scenario,
              scenarioasset: [...scenario.scenarioasset, scenarioasset],
            }
          : scenario
      ),
    },
  });
}

function updateScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioasset.scenario_ID
          ? {
              ...scenario,
              scenarioasset: scenario.scenarioasset.map((scenarioassets) =>
                scenarioassets.ID === scenarioasset.ID
                  ? { ...scenarioassets, ...scenarioasset }
                  : scenarioassets
              ),
            }
          : scenario
      ),
    },
  });
}

function deleteScenarioasset({ prev, payload }: ScenarioassetResolverParams) {
  const scenarioasset = payload.data;

  if (!prev.findUniqueAdvice) return prev;

  return Object.assign({}, prev, {
    findUniqueAdvice: {
      ...prev.findUniqueAdvice,
      scenario: prev.findUniqueAdvice.scenario.map((scenario) =>
        scenario.ID === scenarioasset.scenario_ID
          ? {
              ...scenario,
              scenarioasset: scenario.scenarioasset.filter(
                (scenarioassets) => scenarioassets.ID !== scenarioasset.ID
              ),
            }
          : scenario
      ),
    },
  });
}

function scenarioassetResolver({ prev, payload }: ScenarioassetResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioasset({ prev, payload });

    case MutationType.Update:
      return updateScenarioasset({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioasset({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioassetFindUniqueAdvice(
  query: Pick<
    QueryResult<FindUniqueAdviceQuery, FindUniqueAdviceQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioasset,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioassetSubscription } }
    ) =>
      scenarioassetResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioasset,
      }),
    variables: query.variables,
  });
}
