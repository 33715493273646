import { gql } from "@apollo/client";
import { teamFragment, teamCoreFragment } from "./fragment";

export const team = gql`
  subscription Team($where: teamWhereUniqueInput) {
    team(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...team
      }
    }
  }
  ${teamFragment}
`;

export const teamMany = gql`
  subscription TeamMany($where: teamWhereInput) {
    teamMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...teamCore
      }
    }
  }
  ${teamCoreFragment}
`;
