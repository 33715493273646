import { gql } from "@apollo/client";
import { groupsCoreFragment, groupsFragment } from "./fragment";

export const findUniqueGroup = gql`
  query FindUniqueGroup($where: groupsWhereUniqueInput!) {
    findUniqueGroup(where: $where) {
      ...groups
    }
  }
  ${groupsFragment}
`;

export const findManyGroups = gql`
  query FindManyGroups(
    $where: groupsWhereInput
    $distinct: [GroupsScalarFieldEnum!]
    $orderBy: [groupsOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: groupsWhereUniqueInput
  ) {
    findManyGroups(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...groupsCore
    }
  }
  ${groupsCoreFragment}
`;

export const findManyGroupsAdSerach = gql`
  query FindManyGroupsAdSearch(
    $where: groupsWhereInput
    $distinct: [GroupsScalarFieldEnum!]
    $orderBy: [groupsOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: groupsWhereUniqueInput
  ) {
    findManyGroups(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...groupsCore

      tag {
        ID
        organisation_ID
        Text
        Colour
        Priority
        update_time
        updated_by
        create_time
        created_by
      }

      team {
        ID
        organisation_ID
        Text
        Colour
        created_by
        create_time
        updated_by
        update_time
      }
    }
  }
  ${groupsCoreFragment}
`;

export const findManyGroupsTotalCount = gql`
  query findManyGroupsTotalCount($where: groupsWhereInput) {
    findManyGroupsTotalCount(where: $where)
  }
`;
