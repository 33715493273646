import { gql } from "@apollo/client";
import { entitiesFragment } from "./fragment";

export const entities = gql`
  subscription Entities($where: entitiesWhereUniqueInput) {
    entities(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...entities
      }
    }
  }
  ${entitiesFragment}
`;

export const entitiesMany = gql`
  subscription EntitiesMany($where: entitiesWhereInput) {
    entitiesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...entities
      }
    }
  }
  ${entitiesFragment}
`;
