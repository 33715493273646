import { groupFilter, groups_ID } from "./Group";
import { snackbar } from "./snackbar";

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

const rootReducer = combineReducers({
  snackbar,
  groupFilter,
  groups_ID,
});

export { rootReducer };
