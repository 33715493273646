import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";

export const annuityCoreFragment = gql`
  fragment annuityCore on annuityCore {
    ID
    assets_ID
    Type
    GuaranteePeriod
    Reversionary
    Indexed
    ResidualCapitalValue
    PaymentAmount
    PaymentFrequency
    OwnershipType
    TotalAnnual
    ReferenceNumber
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const annuityFragment = gql`
  fragment annuity on annuity {
    ID
    assets_ID
    Type
    GuaranteePeriod
    Reversionary
    Indexed
    ResidualCapitalValue
    PaymentAmount
    PaymentFrequency
    OwnershipType
    TotalAnnual
    ReferenceNumber
    create_time
    update_time
    created_by
    updated_by

    assetallocation {
      ...assetallocation
    }
    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${notesFragment}
  ${historyFragment}
`;
