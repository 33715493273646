import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";

export const cashCoreFragment = gql`
  fragment cashCore on cashCore {
    ID
    assets_ID
    Amount
    Bank
    Type
    ReferenceNumber
    OwnershipType
    EstimatedAnnualIncome
    MinimumCashHolding
    BSB
    AccountNumber
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const cashFragment = gql`
  fragment cash on cash {
    ID
    assets_ID
    Amount
    Bank
    Type
    ReferenceNumber
    OwnershipType
    EstimatedAnnualIncome
    MinimumCashHolding
    BSB
    AccountNumber
    create_time
    update_time
    created_by
    updated_by

    assetallocation {
      ...assetallocation
    }
    notes {
      ...notes
    }
    history {
      ...history
    }
  }
  ${assetallocationFragment}
  ${notesFragment}
  ${historyFragment}
`;
