import { YjsEditor } from "@slate-yjs/core";
import { Editor, Transforms, Element as SlateElement, Range } from "slate";
import {
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import isUrl from "is-url";

import { LinkElement } from "../types";
import { Tooltip } from "../../../Tooltip";

export const withInlines = (editor: Editor & YjsEditor) => {
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } =
    editor;

  editor.isInline = (element) =>
    ["link"].includes(element.type!) || isInline(element);

  editor.isElementReadOnly = (element) =>
    element.type === "badge" || isElementReadOnly(element);

  editor.isSelectable = (element) =>
    element.type !== "badge" && isSelectable(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const LinkComponent = (props: RenderElementProps) => {
  const { attributes, children } = props;
  const element = props.element as LinkElement;

  const selected = useSelected();
  const focused = useFocused();
  const editor = useSlateStatic();

  var url = element.url.startsWith("https://")
    ? element.url
    : "https://" + element.url;

  return (
    <span className="relative">
      <a
        {...attributes}
        href={url}
        style={
          selected
            ? { boxShadow: " 0 0 0 3px #ddd", textDecoration: "underline" }
            : { textDecoration: "underline" }
        }
      >
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>
      {selected && focused && (
        <span
          className="flex content-center gap-2 bg-white shadow w-fit absolute py-1 px--2 text-sm"
          contentEditable={false}
        >
          <a
            href={url}
            rel="noreferrer"
            target="_blank"
            className="flex items-center gap-2 text-blue-500 underline"
          >
            <i className="icon fa-arrow-up-right-from-square fa-regular" />
            {url}
          </a>
          <Tooltip title={"Remove link"}>
            <i
              className="icon fa-link-simple-slash fa-regular pointer text-neutral-900"
              onClick={() => unwrapLink(editor)}
            />
          </Tooltip>
        </span>
      )}
    </span>
  );
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: "0",
    }}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);
