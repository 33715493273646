import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueAdviceQuery,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../codegen/schema";

import { InsuranceGrouped } from "./Grouped";
import { InsuranceIndividual } from "./Individual";

interface AssetsPropertyProps {
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
  content?: Content;
  contenttemplate?: Contenttemplate;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  theme?: Theme;
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns insurance component for advice template && document.
 */
export const InsuranceComponent: React.FC<AssetsPropertyProps> = ({
  content,
  contenttemplate,
  group,
  theme,
  advice
}) => {
  return contenttemplate?.ContentType === "Grouped" ||
    content?.ContentType === "Grouped" ? (
    <React.Fragment>
      <InsuranceGrouped
        content={content}
        contenttemplate={contenttemplate}
        group={group}
        theme={theme}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <InsuranceIndividual
        advice={advice}
        content={content}
        contenttemplate={contenttemplate}
        group={group}
        theme={theme}
      />
    </React.Fragment>
  );
};
