import React from "react";
import {
  Scenarioclient,
  Theme,
} from "../../../../../codegen/schema";

import { StyledText } from "../../../StyledText";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";

interface EntitiesComponentScenarioClientProps {
  entity: Scenarioclient;
  theme?: Theme;
}

export const EntitiesComponentScenarioClient: React.FC<EntitiesComponentScenarioClientProps> = ({
  entity,
  theme,
}) => {

  if (!entity) return null;

  return (
    <React.Fragment>
      <div>
        <div className="flex flex-col">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H4")}
            
            style={{ fontSize: "12pt" }}
          >
            {checkFieldIsEmpty(entity.Name)}
          </StyledText>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              
              style={{ fontSize: "10pt" }}
            >
              Gross income
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
              style={{ fontSize: "9pt" }}
            >
              {checkFieldIsEmpty(entity.GrossIncome)}
            </StyledText>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
