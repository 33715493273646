import dayjs from "dayjs";
import {
  Scenario,
  Scenarioasset_PropertyType,
  Scenarioasset_Type,
  Scenarioclient,
  Scenarioclient_EntityType,
  Scenarioclient_Residency,
  Scenarioclient_Type,
  Scenarioinsurance_Type,
  Scenarioliability_InterestOnlyOrPrincipalAndInterest,
  Scenarioliability_Type,
} from "../../../../../../codegen/schema";
import {
  CONCESSIONAL_CONTRIBUTIONS_CAP,
  DIV293_THRESHOLD,
  WPI,
} from "../../../../../../Constants/Calculations";
import { ScenarioassetPropertyProjectionByYear } from "../Scenarioasset/Property";
import { ScenarioliabilityCalculatedFields } from "../Scenarioliability";
import { ScenariocontributionwithdrwawalCalculatedForYear } from "../Scenariocontributionwithdrawal";

interface StudyRepaymentBracket {
  min: number;
  max?: number;
  percentage: number;
}

interface StudyRepaymentYears {
  2022: StudyRepaymentBracket[];
  2023: StudyRepaymentBracket[];
  2024: StudyRepaymentBracket[];
  2025: StudyRepaymentBracket[];
}

const StudyRepaymentRates: StudyRepaymentYears = {
  2022: [
    { min: 0, max: 47013, percentage: 0 },
    { min: 47014, max: 54282, percentage: 0.01 },
    { min: 54283, max: 57538, percentage: 0.02 },
    { min: 57539, max: 60991, percentage: 0.025 },
    { min: 60992, max: 64651, percentage: 0.03 },
    { min: 64652, max: 68529, percentage: 0.035 },
    { min: 68530, max: 72641, percentage: 0.04 },
    { min: 72642, max: 77001, percentage: 0.045 },
    { min: 77002, max: 81620, percentage: 0.05 },
    { min: 81621, max: 86518, percentage: 0.055 },
    { min: 86519, max: 91709, percentage: 0.06 },
    { min: 91710, max: 97212, percentage: 0.065 },
    { min: 97213, max: 103045, percentage: 0.07 },
    { min: 103046, max: 109227, percentage: 0.075 },
    { min: 109228, max: 115781, percentage: 0.08 },
    { min: 115782, max: 122728, percentage: 0.085 },
    { min: 122729, max: 130092, percentage: 0.09 },
    { min: 130093, max: 137897, percentage: 0.095 },
    { min: 137898, max: undefined, percentage: 0.1 },
  ],
  2023: [
    { min: 0, max: 48360, percentage: 0 },
    { min: 48361, max: 55836, percentage: 0.01 },
    { min: 55837, max: 59186, percentage: 0.02 },
    { min: 59187, max: 62738, percentage: 0.025 },
    { min: 62739, max: 66502, percentage: 0.03 },
    { min: 66503, max: 70492, percentage: 0.035 },
    { min: 70493, max: 74722, percentage: 0.04 },
    { min: 74723, max: 79206, percentage: 0.045 },
    { min: 79207, max: 83958, percentage: 0.05 },
    { min: 83959, max: 88996, percentage: 0.055 },
    { min: 88997, max: 94336, percentage: 0.06 },
    { min: 94337, max: 99996, percentage: 0.065 },
    { min: 99997, max: 105996, percentage: 0.07 },
    { min: 105997, max: 112355, percentage: 0.075 },
    { min: 112356, max: 119097, percentage: 0.08 },
    { min: 119098, max: 126243, percentage: 0.085 },
    { min: 126244, max: 133818, percentage: 0.09 },
    { min: 133819, max: 141847, percentage: 0.095 },
    { min: 141848, max: undefined, percentage: 0.1 },
  ],
  2024: [
    { min: 0, max: 51550, percentage: 0 },
    { min: 51551, max: 59518, percentage: 0.01 },
    { min: 59519, max: 63089, percentage: 0.02 },
    { min: 63090, max: 66875, percentage: 0.025 },
    { min: 66876, max: 70888, percentage: 0.03 },
    { min: 70889, max: 75140, percentage: 0.035 },
    { min: 75141, max: 79649, percentage: 0.04 },
    { min: 79650, max: 84429, percentage: 0.045 },
    { min: 84430, max: 89494, percentage: 0.05 },
    { min: 89495, max: 94865, percentage: 0.055 },
    { min: 94866, max: 100557, percentage: 0.06 },
    { min: 100558, max: 106590, percentage: 0.065 },
    { min: 106591, max: 112985, percentage: 0.07 },
    { min: 112986, max: 119764, percentage: 0.075 },
    { min: 119765, max: 126950, percentage: 0.08 },
    { min: 126951, max: 134568, percentage: 0.085 },
    { min: 134569, max: 142642, percentage: 0.09 },
    { min: 142643, max: 151200, percentage: 0.095 },
    { min: 151201, max: undefined, percentage: 0.1 },
  ],
  2025: [
    { min: 0, max: 54435, percentage: 0 },
    { min: 54435, max: 62850, percentage: 0.01 },
    { min: 62851, max: 66620, percentage: 0.02 },
    { min: 66621, max: 70618, percentage: 0.025 },
    { min: 70619, max: 74855, percentage: 0.03 },
    { min: 74856, max: 79346, percentage: 0.035 },
    { min: 79347, max: 84107, percentage: 0.04 },
    { min: 84108, max: 89154, percentage: 0.045 },
    { min: 89155, max: 94503, percentage: 0.05 },
    { min: 94504, max: 100174, percentage: 0.055 },
    { min: 100175, max: 106185, percentage: 0.06 },
    { min: 106186, max: 112556, percentage: 0.065 },
    { min: 112557, max: 119309, percentage: 0.07 },
    { min: 119310, max: 126467, percentage: 0.075 },
    { min: 126468, max: 134056, percentage: 0.08 },
    { min: 134057, max: 142100, percentage: 0.085 },
    { min: 142101, max: 150626, percentage: 0.09 },
    { min: 150627, max: 159663, percentage: 0.095 },
    { min: 159664, max: undefined, percentage: 0.1 },
  ],
};

interface IncomeTaxBracket {
  min: number;
  max?: number;
  gross: number;
  perDollar: number;
}

interface TaxYear {
  medicareLevy: number;
  brackets: IncomeTaxBracket[];
}

type Residency = {
  [key in Scenarioclient_Residency]: TaxYear;
};

interface IncomeTaxYears {
  2022: Residency;
  2023: Residency;
  2024: Residency;
  2025: Residency;
}

const IncomeTaxRates: IncomeTaxYears = {
  2022: {
    Resident: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 18200, gross: 0, perDollar: 0 },
        { min: 18201, max: 45000, gross: 5092, perDollar: 0.19 },
        { min: 45001, max: 120000, gross: 24375, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 22200, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 0, perDollar: 0.45 },
      ],
    },
    Foreign_resident: {
      medicareLevy: 0,
      brackets: [
        { min: 0, max: 120000, gross: 0, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 39000, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 61200, perDollar: 0.45 },
      ],
    },
    Working_holiday: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 45000, gross: 0, perDollar: 0.15 },
        { min: 45001, max: 120000, gross: 6750, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 31125, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 53325, perDollar: 0.45 },
      ],
    },
  },
  2023: {
    Resident: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 18200, gross: 0, perDollar: 0 },
        { min: 18201, max: 45000, gross: 5092, perDollar: 0.19 },
        { min: 45001, max: 120000, gross: 24375, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 22200, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 0, perDollar: 0.45 },
      ],
    },
    Foreign_resident: {
      medicareLevy: 0,
      brackets: [
        { min: 0, max: 120000, gross: 0, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 39000, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 61200, perDollar: 0.45 },
      ],
    },
    Working_holiday: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 45000, gross: 0, perDollar: 0.15 },
        { min: 45001, max: 120000, gross: 6750, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 31125, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 53325, perDollar: 0.45 },
      ],
    },
  },
  2024: {
    Resident: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 18200, gross: 0, perDollar: 0 },
        { min: 18201, max: 45000, gross: 5092, perDollar: 0.19 },
        { min: 45001, max: 120000, gross: 24375, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 22200, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 0, perDollar: 0.45 },
      ],
    },
    Foreign_resident: {
      medicareLevy: 0,
      brackets: [
        { min: 0, max: 120000, gross: 0, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 39000, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 61200, perDollar: 0.45 },
      ],
    },
    Working_holiday: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 45000, gross: 0, perDollar: 0.15 },
        { min: 45001, max: 120000, gross: 6750, perDollar: 0.325 },
        { min: 120001, max: 180000, gross: 31125, perDollar: 0.37 },
        { min: 180001, max: undefined, gross: 53325, perDollar: 0.45 },
      ],
    },
  },
  2025: {
    Resident: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 18200, gross: 0, perDollar: 0 },
        { min: 18201, max: 45000, gross: 4288, perDollar: 0.16 },
        { min: 45001, max: 135000, gross: 31288, perDollar: 0.3 },
        { min: 135001, max: 190000, gross: 51638, perDollar: 0.37 },
        { min: 190001, max: undefined, gross: 0, perDollar: 0.45 },
      ],
    },
    Foreign_resident: {
      medicareLevy: 0,
      brackets: [
        { min: 0, max: 135000, gross: 0, perDollar: 0.3 },
        { min: 135001, max: 190000, gross: 40500, perDollar: 0.37 },
        { min: 190001, max: undefined, gross: 60850, perDollar: 0.45 },
      ],
    },
    Working_holiday: {
      medicareLevy: 0.02,
      brackets: [
        { min: 0, max: 45000, gross: 0, perDollar: 0.15 },
        { min: 45001, max: 135000, gross: 6750, perDollar: 0.3 },
        { min: 135001, max: 190000, gross: 33750, perDollar: 0.37 },
        { min: 190001, max: undefined, gross: 54100, perDollar: 0.45 },
      ],
    },
  },
};

export function calculateIncomeTaxByBracket({
  taxableIncome,
  residency,
  year = dayjs().month() >= 6 ? dayjs().year() + 1 : dayjs().year(),
}: {
  taxableIncome: number;
  residency: Scenarioclient_Residency;
  year: number;
}) {
  var data: {
    brackets: Array<{
      netIncome: number;
      taxPayable: number;
      perDollar: number;
    }>;
    estimatedIncomeTax: number;
  } = { brackets: [], estimatedIncomeTax: 0 };

  const taxYear = IncomeTaxRates[year as keyof IncomeTaxYears];

  const residencyRates = taxYear[residency];

  residencyRates.brackets.forEach((bracket) => {
    var useBracket = bracket.max
      ? // If max exists (i.e. not max bracket) then if income greater than max or within min
        // and max, use bracket.
        taxableIncome >= bracket.max ||
        (taxableIncome < bracket.max && taxableIncome > bracket.min)
      : //Else if max bracket and greater than min required.
        taxableIncome > bracket.min;

    // If bracket not used (i.e. client income less than min)
    if (!useBracket) {
      data.brackets.push({
        netIncome: 0,
        taxPayable: 0,
        perDollar: bracket.perDollar,
      });
      return;
    }

    var taxPayable =
      // If max exists (i.e. not max bracket)
      bracket.max && taxableIncome >= bracket.max
        ? // If income greater than max for this bracket, use gross.
          bracket.gross
        : // Else, calculate tax based on difference between gross income and bracket min.
          // If medicare levy exempt, don't add it, else add it to tax payable.
          (taxableIncome - bracket.min - 1) * bracket.perDollar;

    var netIncome =
      // If max exists (i.e. not max bracket)
      bracket.max && taxableIncome >= bracket.max
        ? // If income greater than max, calculate amount of tax for this bracket.
          bracket.max - bracket.min - taxPayable
        : // Else, calculate tax based on difference between gross income and bracket min.
          // Medicare levy accounted for in taxpayable
          taxableIncome - bracket.min - taxPayable;

    // Add to income tax
    data.estimatedIncomeTax += taxPayable;

    // Add to taxBrackets
    data.brackets.push({
      netIncome,
      taxPayable,
      perDollar: bracket.perDollar,
    });
  });

  return data;
}

interface ScenarioclientIncomeTaxProps {
  scenario: Scenario;
  scenarioclient: Scenarioclient;
  /**
   * If needed, a year to index the income at can be provided. This is primarily used
   * in calculations where the client may have retired and certain things are no longer
   * applicable (e.g. super contr splitting, if retired, don't send any contributions).
   *
   * Note that this should be a number such as 0, 1, 2 and not a year such as 2023.
   */
  year?: number;
}

export interface ScenarioclientIncomeTaxReturn {
  brackets: { netIncome: number; taxPayable: number; perDollar: number }[];
  income: {
    estimatedAnnualExpenses: number;
    gross: number;
    pension: number;
    rental: number;
    splittingReceived: number;
    splittingSent: number;
    deductions: {
      rentalPropertyExpenses: number;
      rentalPropertyInterest: number;
      rentalPropertyNonCash: number;
      incomeProtectionInsurance: number;
      salaryPackage: number;
      salarySacrifice: number;
    };
  };
  insurances: Array<{
    text: string;
    type: Scenarioinsurance_Type;
    preimumPerYear: number;
  }>;
  deductibleLoans: Array<{
    text: string;
    interest: number;
    repayments: number;
  }>;
  rentalProperties: Array<{
    text: string;
    income: number;
    nonCashTaxDeductions: number;
    expenses: number;
  }>;
  superannuation: {
    concessionalContributions: number;
    excessConcessionalContributions: number;
    nonConcessionalContributions: number;
    contributionSplittingReceived: number;
    contributionSplittingSent: number;
  };
  tax: {
    income: number;
    medicareLevy: number;
    concessionalContributions: number;
    excessConcessionalContributions: number;
    excessConcessionalContributionsTaxOffset: number;
    nonConcessionalContributions: number;
    div293: number;
    studyRepayments: number;
  };
  totals: {
    combinedLoanRepayments: number;
    combinedScenarioassetContributions: number;
    combinedScenarioassetContributionsFromIncome: number;
    combinedScenarioliabilityContributions: number;
    taxableIncome: number;
    netIncome: number;
    taxPayable: number;
    superannuationTax: number;
    surplus: number;
  };
}

export function ScenarioclientIncomeTax({
  scenario,
  scenarioclient,
  year = 0,
}: ScenarioclientIncomeTaxProps): ScenarioclientIncomeTaxReturn {
  /** Same indecation expression used throughout this file. */
  var WPIIndexation = Math.pow(1 + WPI, year);

  var scenarioclientAge = dayjs(scenarioclient.DateOfBirth).isValid()
    ? dayjs().year() + year - dayjs(scenarioclient.DateOfBirth).year()
    : (scenarioclient.RetirementAge ?? 65) - year - 18; // min 18

  var concessionalContributionsCap: number =
    // (CONCESSIONAL_CONTRIBUTIONS_CAP.find(
    //   (entry) => entry.year === dayjs().year() + year
    // )?.value ??
    (CONCESSIONAL_CONTRIBUTIONS_CAP.find((entry) => {
      // Determine the financial year based on the month
      // Australian financial year starts on July 1st
      const financialYear =
        dayjs().month() >= 6 ? dayjs().year() + 1 : dayjs().year();

      // Find the entry by the calculated financial year, plus the `year` offset
      return entry.year === financialYear + year;
    })?.value ??
      Math.floor(
        (CONCESSIONAL_CONTRIBUTIONS_CAP[
          CONCESSIONAL_CONTRIBUTIONS_CAP.length - 1
        ].value *
          WPIIndexation) /
          2500
      ) * 2500) +
    // Add carried forward contributions caps if client superannuation less than 500k
    (scenario.scenarioasset
      .filter(
        (asset) =>
          asset.Type === Scenarioasset_Type.Superannuation &&
          scenarioclient.NominatedSuperannuation_scenarioasset_ID === asset.ID
      )
      .reduce(
        (accumulator, scenarioasset) => (accumulator += scenarioasset.Value),
        0
      ) < 500000
      ? scenarioclient.CarriedForwardSuperannuationContributionsCapIncrease
      : 0);

  var data: ScenarioclientIncomeTaxReturn = {
    brackets: [],
    income: {
      estimatedAnnualExpenses:
        (scenarioclient.EstimatedMonthlyLivingExpenses ?? 0) * 12,
      gross: (scenarioclient.GrossIncome ?? 0) * WPIIndexation,
      rental: 0,
      pension: scenarioclient.PensionIncome ?? 0,
      splittingReceived: 0,
      splittingSent: (scenarioclient.IncomeSplitting ?? 0) * WPIIndexation,
      deductions: {
        rentalPropertyExpenses: 0,
        rentalPropertyInterest: 0,
        rentalPropertyNonCash: 0,
        incomeProtectionInsurance: 0,
        // Not indexed because it would be a fixed cost
        salaryPackage: scenarioclient.SalaryPackage ?? 0,
        salarySacrifice: (scenarioclient.SalarySacrifice ?? 0) * WPIIndexation,
      },
    },
    insurances: [],
    deductibleLoans: [],
    rentalProperties: [],
    superannuation: {
      concessionalContributions:
        (scenarioclient.EmployerSuperannuationContributions * WPIIndexation ??
          0) +
        (scenarioclient.AdditionalConcessionalContributions * WPIIndexation ??
          0) +
        (scenarioclient.SalarySacrifice ?? 0),
      excessConcessionalContributions: 0,
      nonConcessionalContributions:
        scenarioclient.NonConcessionalContributions * WPIIndexation,
      contributionSplittingReceived: 0,
      // Multiply total concessional contributions by the percentage that's being split
      // @todo in future add limits to this, e.g. 85% of concessional cap etc.
      contributionSplittingSent:
        ((scenarioclient.EmployerSuperannuationContributions * WPIIndexation ??
          0) +
          (scenarioclient.AdditionalConcessionalContributions * WPIIndexation ??
            0) +
          (scenarioclient.SalarySacrifice ?? 0)) *
        ((scenarioclient.SuperannuationContributionSplitting ?? 0) / 100),
    },
    tax: {
      income: 0,
      medicareLevy: 0,
      concessionalContributions: 0,
      excessConcessionalContributions: 0,
      excessConcessionalContributionsTaxOffset: 0,
      nonConcessionalContributions: 0,
      div293: 0,
      studyRepayments: 0,
    },
    totals: {
      combinedScenarioassetContributions: 0,
      combinedScenarioassetContributionsFromIncome: 0,
      combinedScenarioliabilityContributions: 0,
      combinedLoanRepayments: 0,
      taxPayable: 0,
      taxableIncome: 0,
      netIncome: 0,
      superannuationTax: 0,
      surplus: 0,
    },
  };

  // #region SUPERANNUATION_TAX -------------------------------------------------------------------

  // Calculate tax paid first - needs to be calculated before splitting occurs
  data.tax.concessionalContributions +=
    data.superannuation.concessionalContributions * 0.15;

  // No tax on non concessional as tax has already been paid prior
  data.tax.nonConcessionalContributions = 0;

  // Determine capped amount
  var excessConcessionalContributions = Math.max(
    data.superannuation.concessionalContributions -
      concessionalContributionsCap,
    0
  );

  if (excessConcessionalContributions > 0) {
    // Add excess concessional contributions to data
    data.superannuation.excessConcessionalContributions =
      excessConcessionalContributions;

    // Calculate tax offset
    data.tax.excessConcessionalContributionsTaxOffset =
      excessConcessionalContributions * 0.15;

    // Add excess to income to be taxed at marginal rate further down in file so as to not skew
    // gross salary.
  }

  // #endregion SUPERANNUATION_TAX ----------------------------------------------------------------

  var nClients = scenario.scenarioclient.filter(
    (scenarioclient) => scenarioclient.Type === Scenarioclient_Type.Client
  ).length;

  // Calculate total premiums paid for insurances by the client
  scenario.scenarioinsurance
    .filter(
      (scenarioinsurance) =>
        scenarioinsurance.ClientInsured_scenarioclient_ID ===
          scenarioclient.ID ||
        scenarioinsurance.ClientInsured_scenarioclient_ID === 0
    )
    .forEach((scenarioinsurance) => {
      var preimumPerYear =
        scenarioinsurance.AnnualPremium *
        (1 - scenarioinsurance.PercentagePaidBySuperannuation / 100);

      // Only non super portion of IP is tax deductible
      if (scenarioinsurance.Type === Scenarioinsurance_Type.IncomeProtection) {
        data.income.deductions.incomeProtectionInsurance +=
          scenarioinsurance.ClientInsured_scenarioclient_ID === 0
            ? preimumPerYear / nClients
            : preimumPerYear;
      }

      data.insurances.push({
        text: scenarioinsurance.Name ?? "",
        type: scenarioinsurance.Type!,
        preimumPerYear:
          scenarioinsurance.ClientInsured_scenarioclient_ID === 0
            ? preimumPerYear / nClients
            : preimumPerYear,
      });
    });

  // Calculate rental income and associated expenses
  scenario.scenarioasset
    .filter(
      (scenarioasset) =>
        scenarioasset.Type === Scenarioasset_Type.Property &&
        scenarioasset.PropertyType === Scenarioasset_PropertyType.Investment &&
        (scenarioasset.PropertyIncomePaidTo_scenarioclient_ID ===
          scenarioclient.ID ||
          scenarioasset.PropertyIncomePaidTo_scenarioclient_ID === 0)
    )
    .forEach((property) => {
      var { annualCost, annualRent } = ScenarioassetPropertyProjectionByYear({
        scenario,
        scenarioasset: property,
        year,
      });

      // Property expenses
      var propertyExpenses =
        property.PropertyIncomePaidTo_scenarioclient_ID === 0
          ? annualCost / nClients
          : annualCost;

      data.income.deductions.rentalPropertyExpenses += propertyExpenses;

      // Property income
      var propertyIncome =
        property.PropertyIncomePaidTo_scenarioclient_ID === 0
          ? annualRent / nClients
          : annualRent;

      data.income.rental += propertyIncome;

      // Property non cash deductions
      var propertyNonCashTaxDeductions =
        property.PropertyIncomePaidTo_scenarioclient_ID === 0
          ? (property.PropertyNonCashTaxDeductions ?? 0) / nClients
          : property.PropertyNonCashTaxDeductions ?? 0;

      data.income.deductions.rentalPropertyNonCash +=
        propertyNonCashTaxDeductions;

      data.rentalProperties.push({
        text: property.Name ?? "",
        income: propertyIncome,
        nonCashTaxDeductions: propertyNonCashTaxDeductions,
        expenses: propertyExpenses,
      });

      /**
       * Calculate tax deductible interest for investment properties.
       * Only properties that clients are receiving income for can be claimed as investments.
       * This is to avoice "investment" loans, that aren't used for investment purposes.
       */
      // Contributions and withdrawals -------------------------------------------------------------|
      /**
       * Calculate contributions and withdrawals at an annual rate, then divide this by
       * the RepaymentFrequency to determine the contribution or withdrawal amount for that
       * period.
       */
      var loans = scenario.scenarioliability.filter(
        (scenarioliability) =>
          // Make sure it's just for loans that are secured by this property
          scenarioliability.LoanSecuredBy_scenarioasset_ID === property.ID &&
          scenarioliability.Type === Scenarioliability_Type.Loan
      );

      var offsets = scenario.scenarioasset.filter(
        (scenarioasset) =>
          scenarioasset.Type === Scenarioasset_Type.Offset &&
          loans.some(
            (loan) => loan.LoanOffset_scenarioasset_ID === scenarioasset.ID
          )
      );

      /** @description Should be positive or 0. Total value of offsets. */
      var offsetsTotalValue: number = offsets.reduce(
        (value, offset) => (value += offset?.Value),
        0
      );

      var remainingOffset = offsetsTotalValue;
      loans.forEach((loan) => {
        var {
          calculatedInterestRate,
          frequency,
          minimumRepayments,
          interestOnlyMinimumRepayments,
        } = ScenarioliabilityCalculatedFields({ scenarioliability: loan! });

        var { contributions: loanContributions, withdrawals: loanWithdrawals } =
          ScenariocontributionwithdrwawalCalculatedForYear({
            scenarioitem: loan!,
            year,
          });

        /**
         * For each period in frequency, calculate interest on loan after offset and repayments
         * for this year.
         */
        // if (remainingOffset >= loan.Value) {
        //   // Subtract loan value from remaing offset because it is fully offset and has no interest
        //   remainingOffset -= loan.Value;
        // } else {
        var interest = 0;
        var repayments = 0;
        if (
          loan!.InterestOnlyOrPrincipalAndInterest ===
            Scenarioliability_InterestOnlyOrPrincipalAndInterest.InterestOnly &&
          dayjs(loan!.InterestOnlyExpiryDate).isValid() &&
          dayjs(loan!.InterestOnlyExpiryDate).isAfter(year) &&
          dayjs(loan!.InterestOnlyExpiryDate).year() - year > 0
        ) {
          interest +=
            property.PropertyIncomePaidTo_scenarioclient_ID === 0
              ? (interestOnlyMinimumRepayments * frequency) / nClients
              : interestOnlyMinimumRepayments * frequency;

          repayments +=
            property.PropertyIncomePaidTo_scenarioclient_ID === 0
              ? (interestOnlyMinimumRepayments * frequency) / nClients
              : interestOnlyMinimumRepayments * frequency;
        } else {
          for (var i = 1; i <= frequency; i++) {
            /**
             * @todo account for conts withs for loans in here so that it can re enter
             * the cycle if the figures line up
             */
            interest +=
              property.PropertyIncomePaidTo_scenarioclient_ID === 0
                ? ((loan?.Value -
                    remainingOffset +
                    loanContributions / frequency +
                    loanWithdrawals / frequency -
                    minimumRepayments * i) *
                    calculatedInterestRate) /
                  nClients
                : (loan?.Value -
                    remainingOffset +
                    loanContributions / frequency +
                    loanWithdrawals / frequency -
                    minimumRepayments * i) *
                  calculatedInterestRate;
          }
          repayments +=
            property.PropertyIncomePaidTo_scenarioclient_ID === 0
              ? (minimumRepayments * frequency) / nClients
              : minimumRepayments * frequency;
        }
        // Reset remaining offset as it has all been used
        remainingOffset =
          remainingOffset > loan?.Value ? remainingOffset - loan?.Value : 0;
        // }
        data.income.deductions.rentalPropertyInterest += interest;
        data.deductibleLoans.push({
          text: loan.Name ?? "",
          interest,
          repayments,
        });
      }, 0);
    });

  // Combined loan repayments
  data.totals.combinedLoanRepayments = scenario.scenarioliability
    .filter(
      (scenarioliability) =>
        // Remove any loans being paid by an SMSF
        !scenario.scenarioclient
          .filter(
            (scenarioclient) =>
              scenarioclient.Type === Scenarioclient_Type.Entity &&
              scenarioclient.EntityType === Scenarioclient_EntityType.Smsf
          )
          .some(
            (scenarioclient) =>
              scenarioclient.ID === scenarioliability.Owner_scenarioclient_ID
          )
    )
    .reduce((accumulator, scenarioliability) => {
      var { frequency, interestOnlyMinimumRepayments, minimumRepayments } =
        ScenarioliabilityCalculatedFields({
          scenarioliability,
        });

      var repayments = 0;

      if (
        scenarioliability.InterestOnlyOrPrincipalAndInterest ===
          Scenarioliability_InterestOnlyOrPrincipalAndInterest.InterestOnly &&
        dayjs(scenarioliability.InterestOnlyExpiryDate).isValid() &&
        dayjs(scenarioliability.InterestOnlyExpiryDate).isAfter(year) &&
        dayjs(scenarioliability.InterestOnlyExpiryDate).year() - year > 0
      ) {
        repayments += interestOnlyMinimumRepayments * frequency;
      } else {
        // remainingOffset = 0; // Reset remaining offset as it has all been used
        repayments +=
          scenarioliability.Repayment > minimumRepayments
            ? scenarioliability.Repayment * frequency
            : minimumRepayments * frequency;
      }

      return (accumulator += repayments);
    }, 0);

  // Find external super contr splitting and income splitting being received
  scenario.scenarioclient.forEach((client) => {
    if (
      client.IncomeSplitting_scenarioclient_ID === scenarioclient.ID ||
      client.SuperannuationContributionSplitting_scenarioclient_ID ===
        scenarioclient.ID
    ) {
      var clientAge = dayjs(scenarioclient.DateOfBirth).isValid()
        ? dayjs().year() + year - dayjs(scenarioclient.DateOfBirth).year()
        : (scenarioclient.RetirementAge ?? 65) - year - 18; // min 18

      if (client.IncomeSplitting_scenarioclient_ID === scenarioclient.ID) {
        // Value has already been indexed because we provide the year to the function
        /** @description Calculated income received from splitting. */
        data.income.splittingReceived +=
          clientAge >= (client.RetirementAge ?? 65)
            ? 0
            : client.IncomeSplitting * WPIIndexation ?? 0;
      }

      if (
        client.SuperannuationContributionSplitting_scenarioclient_ID ===
        scenarioclient.ID
      ) {
        // Value has already been indexed because we provide the year to the function
        /** @description Calculated contributions received from splitting. */
        data.superannuation.contributionSplittingReceived +=
          clientAge >= (client.RetirementAge ?? 65)
            ? 0
            : ((client.EmployerSuperannuationContributions * WPIIndexation ??
                0) +
                (client.AdditionalConcessionalContributions * WPIIndexation ??
                  0) +
                (client.SalarySacrifice ?? 0)) *
              ((client.SuperannuationContributionSplitting * WPIIndexation ??
                0) /
                100);
      }
    }
  });

  // If client has retired, set splitting sent for income and super to 0
  if (scenarioclientAge >= (scenarioclient.RetirementAge ?? 65)) {
    data.superannuation.contributionSplittingSent = 0;
    data.income.splittingSent = 0;
  }

  // Adjust Gross income
  data.income.gross =
    data.income.gross -
    data.income.splittingSent +
    data.income.splittingReceived +
    data.income.rental +
    data.income.pension +
    data.superannuation.excessConcessionalContributions;

  // Adjust taxable income
  data.totals.taxableIncome =
    data.income.gross -
    data.income.pension -
    data.income.deductions.incomeProtectionInsurance -
    data.income.deductions.salaryPackage -
    data.income.deductions.salarySacrifice -
    data.income.deductions.rentalPropertyExpenses -
    data.income.deductions.rentalPropertyInterest -
    data.income.deductions.rentalPropertyNonCash -
    data.tax.excessConcessionalContributionsTaxOffset;

  // #region DIV_293 CALCULATIONS ------------------------------------------------------------------
  if (
    data.totals.taxableIncome +
      data.superannuation.concessionalContributions -
      excessConcessionalContributions >
    DIV293_THRESHOLD
  ) {
    // Div293 is conributions + income - excess conc. contributions
    var DIV293Contributions = data.superannuation.concessionalContributions;

    var DIV293Excess =
      data.totals.taxableIncome +
      data.superannuation.concessionalContributions -
      excessConcessionalContributions -
      DIV293_THRESHOLD;

    // Calculate DIV293 less any excess contributions

    // Of the contributions or the excess, find the lesser then calculate the tax
    data.tax.div293 =
      DIV293Contributions > DIV293Excess
        ? DIV293Excess * 0.15
        : DIV293Contributions * 0.15;
  }
  // #endregion DIV_293 CALCULATIONS ---------------------------------------------------------------

  // Calculate medicare levy
  data.tax.medicareLevy =
    scenarioclient.MedicareExemption === 1
      ? 0
      : data.totals.taxableIncome * 0.02;

  // Calculate study repayments if required
  if (scenarioclient.StudyRepayments === 1) {
    // Get the relevant brackets for the year
    const studyRepaymentYear =
      StudyRepaymentRates[dayjs().year() as keyof StudyRepaymentYears];

    // Find the correct percentage to determine amount
    const studyRepaymentPercentage =
      studyRepaymentYear.find((entry) =>
        entry.max
          ? data.totals.taxableIncome >= entry.min &&
            data.totals.taxableIncome <= entry.max
          : data.totals.taxableIncome >= entry.min
      )?.percentage ?? 0;

    // Calculate amount to repay
    data.tax.studyRepayments =
      data.totals.taxableIncome * studyRepaymentPercentage;
  }

  // Calculate estimated income tax from ATO tax brackets.
  const { estimatedIncomeTax, brackets } = calculateIncomeTaxByBracket({
    taxableIncome: data.totals.taxableIncome,
    residency: scenarioclient.Residency!,
    year:
      scenarioclient.FinancialYear ?? dayjs().month() >= 6
        ? dayjs().year() + 1
        : dayjs().year(),
  });
  data.tax.income += estimatedIncomeTax;
  data.brackets = brackets;

  // Calculate estimated excessionalContributionsTax by determining difference between
  // estimatedIncomeTax with and without excessionConcessionalContributions
  var estimatedExcessConcessionalContributionsTax =
    estimatedIncomeTax -
    calculateIncomeTaxByBracket({
      taxableIncome:
        data.totals.taxableIncome -
        data.superannuation.excessConcessionalContributions +
        data.tax.excessConcessionalContributionsTaxOffset,
      residency: scenarioclient.Residency!,
      year:
        scenarioclient.FinancialYear ?? dayjs().month() >= 6
          ? dayjs().year() + 1
          : dayjs().year(),
    }).estimatedIncomeTax;

  data.tax.excessConcessionalContributions =
    estimatedExcessConcessionalContributionsTax > 0
      ? estimatedExcessConcessionalContributionsTax
      : 0;

  // Add to totals
  data.totals.taxPayable =
    data.tax.income + data.tax.medicareLevy + data.tax.studyRepayments;

  data.totals.superannuationTax =
    data.tax.concessionalContributions +
    data.tax.nonConcessionalContributions +
    (scenarioclient.PayDIV293TaxFromIncome === 0 ? data.tax.div293 : 0) +
    (scenarioclient.PayExcessConcessionalContributionsTaxFromIncome === 0
      ? data.tax.excessConcessionalContributions
      : 0);

  data.totals.netIncome = data.totals.taxableIncome - data.totals.taxPayable;

  data.totals.combinedScenarioassetContributions =
    scenario.scenarioasset.reduce((accumulator, scenarioasset) => {
      var { contributions } = ScenariocontributionwithdrwawalCalculatedForYear({
        scenarioitem: scenarioasset,
        year: dayjs().year(),
      });

      return (accumulator += contributions);
    }, 0);

  data.totals.combinedScenarioassetContributionsFromIncome =
    scenario.scenarioasset.reduce((accumulator, scenarioasset) => {
      var { contributionsFromIncome } =
        ScenariocontributionwithdrwawalCalculatedForYear({
          scenarioitem: scenarioasset,
          year: dayjs().year(),
        });

      return (accumulator += contributionsFromIncome);
    }, 0);

  data.totals.combinedScenarioliabilityContributions =
    scenario.scenarioliability.reduce((accumulator, scenarioliability) => {
      var { contributions } = ScenariocontributionwithdrwawalCalculatedForYear({
        scenarioitem: scenarioliability,
        year: dayjs().year(),
      });

      return (accumulator += contributions);
    }, 0);

  // add back pension to gross income
  data.totals.netIncome += data.income.pension;

  data.totals.surplus =
    data.totals.netIncome - data.income.estimatedAnnualExpenses;

  //   data.tax.income = bracket.max
  //   ? // If max exists (i.e. not max bracket)
  //     data.totals.taxableIncome >= bracket.max
  //     ? // If income greater than max, calculate amount of tax for this bracket.
  //       (bracket.max - bracket.min - 1) * bracket.perDollar
  //     : // Else, calculate tax based on difference between gross income and bracket min.
  //       (data.totals.taxableIncome - bracket.min - 1) * bracket.perDollar
  //   : // Else if no max (i.e. is max bracket)
  //     // calculate tax based on difference between gross income and bracket min.
  //     (data.totals.taxableIncome - bracket.min - 1) * bracket.perDollar;

  // // Add total taxPayable
  // data.totals.taxPayable +=
  //   data.tax.income + data.tax.medicareLevy + data.tax.salarySacrifice;

  // data.totals.netIncome = data.totals.taxableIncome - data.totals.taxPayable
  return data;
}
