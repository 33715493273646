import { QueryResult } from "@apollo/client";
import { FindManyGroupsTotalCount } from "..";
import {
  FindManyGroupsTotalCountQuery,
  FindManyGroupsTotalCountQueryVariables,
} from "../../../codegen/schema";

export default function findManyGroupsTotalCountSubscription(
  query: Pick<
    QueryResult<
      FindManyGroupsTotalCountQuery,
      FindManyGroupsTotalCountQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  FindManyGroupsTotalCount(query);
}
