import { QueryResult } from "@apollo/client";
import {
  HistorySubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { history } from "../subscription";

interface HistoryResolverParams {
  prev: FindUniqueGroupQuery;
  payload: HistorySubscription["history"];
}

function createHistory({ prev, payload }: HistoryResolverParams) {
  const {
    data: history,
    assets_has_history,
    liabilities_has_history,
  } = payload;

  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          /** Assets history */
          assets: {
            ...client.assets,
            /** Annuity history */
            annuity: client.assets.annuity.map((annuity) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === annuity.assets_ID
              )
                ? {
                    ...annuity,
                    history: [...annuity.history, history],
                  }
                : annuity;
            }),
            /** Cash history */
            cash: client.assets.cash.map((cash) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === cash.assets_ID
              )
                ? {
                    ...cash,
                    history: [...cash.history, history],
                  }
                : cash;
            }),
            /** Collectibles history */
            collectibles: client.assets.collectibles.map((collectibles) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === collectibles.assets_ID
              )
                ? {
                    ...collectibles,
                    history: [...collectibles.history, history],
                  }
                : collectibles;
            }),
            /** Offset history */
            offset: client.assets.offset.map((offset) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === offset.assets_ID
              )
                ? {
                    ...offset,
                    history: [...offset.history, history],
                  }
                : offset;
            }),
            /** Otherassets history */
            otherassets: client.assets.otherassets.map((otherassets) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === otherassets.assets_ID
              )
                ? {
                    ...otherassets,
                    history: [...otherassets.history, history],
                  }
                : otherassets;
            }),
            /** Property history */
            property: client.assets.property.map((property) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === property.assets_ID
              )
                ? {
                    ...property,
                    history: [...property.history, history],
                  }
                : property;
            }),
            /** Shares history */
            shares: client.assets.shares.map((shares) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === shares.assets_ID
              )
                ? {
                    ...shares,
                    history: [...shares.history, history],
                  }
                : shares;
            }),
            /** Superannuation history */
            superannuation: client.assets.superannuation.map(
              (superannuation) => {
                return assets_has_history.some(
                  (relation) => relation.assets_ID === superannuation.assets_ID
                )
                  ? {
                      ...superannuation,
                      history: [...superannuation.history, history],
                    }
                  : superannuation;
              }
            ),
            /** Vehicles history */
            vehicles: client.assets.vehicles.map((vehicles) => {
              return assets_has_history.some(
                (relation) => relation.assets_ID === vehicles.assets_ID
              )
                ? {
                    ...vehicles,
                    history: [...vehicles.history, history],
                  }
                : vehicles;
            }),
          },

          /** Liabilities history */
          liabilities: {
            ...client.liabilities,
            /** Loans history */
            loans: client.liabilities.loans.map((loans) => {
              return liabilities_has_history.some(
                (relation) => relation.liabilities_ID === loans.liabilities_ID
              )
                ? {
                    ...loans,
                    history: [...loans.history, history],
                  }
                : loans;
            }),
            /** Otherliabilities history */
            otherliabilities: client.liabilities.otherliabilities.map(
              (otherliabilities) => {
                return liabilities_has_history.some(
                  (relation) =>
                    relation.liabilities_ID === otherliabilities.liabilities_ID
                )
                  ? {
                      ...otherliabilities,
                      history: [...otherliabilities.history, history],
                    }
                  : otherliabilities;
              }
            ),
          },
        };
      }),
    },
  });
}

function updateHistory({ prev, payload }: HistoryResolverParams) {
  const history = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            /** Annuity history */
            annuity: client.assets.annuity.map((annuity) => {
              return {
                ...annuity,
                history: annuity.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Cash history */
            cash: client.assets.cash.map((cash) => {
              return {
                ...cash,
                history: cash.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Collectibles history */
            collectibles: client.assets.collectibles.map((collectible) => {
              return {
                ...collectible,
                history: collectible.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Offset history */
            offset: client.assets.offset.map((offset) => {
              return {
                ...offset,
                history: offset.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Otherassets history */
            otherassets: client.assets.otherassets.map((otherasset) => {
              return {
                ...otherasset,
                history: otherasset.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Property history */
            property: client.assets.property.map((property) => {
              return {
                ...property,
                history: property.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Shares history */
            shares: client.assets.shares.map((shares) => {
              return {
                ...shares,
                history: shares.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Superannuation history */
            superannuation: client.assets.superannuation.map(
              (superannuation) => {
                return {
                  ...superannuation,
                  history: superannuation.history.map((value) =>
                    value.ID === history.ID ? { ...value, ...history } : value
                  ),
                };
              }
            ),
            /** Vehicles history */
            vehicles: client.assets.vehicles.map((vehicles) => {
              return {
                ...vehicles,
                history: vehicles.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
          },

          /** Liabilities history */
          liabilities: {
            ...client.liabilities,
            /** Loans history */
            loans: client.liabilities.loans.map((loan) => {
              return {
                ...loan,
                history: loan.history.map((value) =>
                  value.ID === history.ID ? { ...value, ...history } : value
                ),
              };
            }),
            /** Otherliabilities history */
            otherliabilities: client.liabilities.otherliabilities.map(
              (other) => {
                return {
                  ...other,
                  history: other.history.map((value) =>
                    value.ID === history.ID ? { ...value, ...history } : value
                  ),
                };
              }
            ),
          },
        };
      }),
    },
  });
}

function deleteHistory({ prev, payload }: HistoryResolverParams) {
  const history = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            /** Annuity history */
            annuity: client.assets.annuity.map((annuity) => {
              return {
                ...annuity,
                history: annuity.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Cash history */
            cash: client.assets.cash.map((cash) => {
              return {
                ...cash,
                history: cash.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Collectibles history */
            collectibles: client.assets.collectibles.map((collectible) => {
              return {
                ...collectible,
                history: collectible.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Offset history */
            offset: client.assets.offset.map((offset) => {
              return {
                ...offset,
                history: offset.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Otherassets history */
            otherassets: client.assets.otherassets.map((otherasset) => {
              return {
                ...otherasset,
                history: otherasset.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Property history */
            property: client.assets.property.map((property) => {
              return {
                ...property,
                history: property.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Shares history */
            shares: client.assets.shares.map((shares) => {
              return {
                ...shares,
                history: shares.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Superannuation history */
            superannuation: client.assets.superannuation.map(
              (superannuation) => {
                return {
                  ...superannuation,
                  history: superannuation.history.filter(
                    (value) => value.ID !== history.ID
                  ),
                };
              }
            ),
            /** Vehicles history */
            vehicles: client.assets.vehicles.map((vehicles) => {
              return {
                ...vehicles,
                history: vehicles.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
          },

          liabilities: {
            ...client.liabilities,
            /** Loans history */
            loans: client.liabilities.loans.map((loan) => {
              return {
                ...loan,
                history: loan.history.filter(
                  (value) => value.ID !== history.ID
                ),
              };
            }),
            /** Otherliabilities history */
            otherliabilities: client.liabilities.otherliabilities.map(
              (other) => {
                return {
                  ...other,
                  history: other.history.filter(
                    (value) => value.ID !== history.ID
                  ),
                };
              }
            ),
          },
        };
      }),
    },
  });
}

function historyResolver({ prev, payload }: HistoryResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createHistory({ prev, payload });

    case MutationType.Update:
      return updateHistory({ prev, payload });

    case MutationType.Delete:
      return deleteHistory({ prev, payload });

    default:
      return prev;
  }
}

export function historyFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: history,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: HistorySubscription } }
    ) =>
      historyResolver({
        prev,
        payload: payload.subscriptionData.data.history,
      }),
    variables: query.variables,
  });
}
