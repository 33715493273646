import React from "react";
import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../../Functions/GeneralFunctions";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../../codegen/schema";

import { StyledText } from "../../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../../Functions/Owner";

interface AssetsSuperannuationProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns superannuation component for advice template && document.
 */
export const AssetsSuperannuationGrouped: React.FC<
  AssetsSuperannuationProps
> = ({ content, contenttemplate, theme, group }) => {
  type Superannuation = {
    assets_ID: number | null;
    FundName: string;
    Owner: string;
    Value: string;
  };

  const summarySuperannuation: Superannuation[] = group
    ? getGroupAssets(group).superannuation
    : [
        { FundName: "Mason Stevens", Owner: "Jane Smith", Value: "$600,000" },
        { FundName: "Mason Stevens", Owner: "John Smith", Value: "$400,000" },
      ];

  return (
    <React.Fragment>
      <div className="grid grid-cols-3 gap-2">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
          
        >
          Fund Name
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
          
        >
          Owner
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
          
        >
          Value
        </StyledText>
      </div>

      {summarySuperannuation.map((superannuation, objectIndex) => {
        return (
          <div
           className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(superannuation.FundName)}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(
                group
                  ? ownerOptionsUpdateAssetDefaultValue(superannuation, group)
                      .text
                  : superannuation.Owner
              )}
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(superannuation.Value, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  );
};
