import React from "react";
import { InsuranceTraumaScenarioModelGraph } from "../Graph";
import { InsuranceTraumaScenarioDataReturn } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";

interface InsuranceTraumaPlaceholderModelProps {
  theme?: Theme;
}

var data: InsuranceTraumaScenarioDataReturn[] = [
  {
    clientName: "John Smith",
    existingCover: 303114,
    calculatedCover: 300000,
    recommendedCover: 250000,
    gap: -53114,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
  {
    clientName: "Jane Smith",
    existingCover: 274933,
    calculatedCover: 183333.33333333337,
    recommendedCover: 250000,
    gap: -24933,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
];

export const InsuranceTraumaScenarioPlaceholderModel = ({
  theme,
}: InsuranceTraumaPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
         Trauma Insurance
        </StyledText>

        <ModelTooltip type={Model_Type.InsuranceTrauma} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InsuranceTraumaScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
