import * as React from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  arrow,
  FloatingArrow,
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";

export interface TooltipProps {
  background?: "dark" | "light";
  placement?: Placement;
  children: JSX.Element;
  title: string | JSX.Element;
}

interface TooltipOptions {}

export const Tooltip = ({
  background = "dark",
  placement,
  children,
  title,
}: TooltipProps) => {
  return (
    <TooltipComponent placement={placement ?? "top-end"}>
      <TooltipTrigger>{children}</TooltipTrigger>
      {!title ? null : (
        <TooltipContent
          className={
            (background === "dark"
              ? "bg-neutral-800 text-white"
              : "bg-neutral-50") +
            " text-xs p-2 font-display font-medium rounded-lg z-50 shadow-lg ring-1 ring-neutral-500 ring-opacity-5 outline-none max-w-md"
          }
        >
          {title}
        </TooltipContent>
      )}
    </TooltipComponent>
  );
};

export function useTooltip({ placement }: { placement: Placement }) {
  const [open, setOpen] = React.useState(false);
  const arrowRef = React.useRef(null);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: true,
  });
  const focus = useFocus(context, {
    enabled: true,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      arrowRef,
    }),
    [open, setOpen, interactions, data]
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

export function TooltipComponent({
  children,
  placement,
}: { children: React.ReactNode; placement: Placement } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip({ placement: placement });

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    );
  }

  return (
    <div
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </div>
  );
});

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      >
        {props.children}
        <FloatingArrow
          ref={context.arrowRef}
          context={context.context}
          className="
            fill-neutral-800 [&>path:first-of-type]:stroke-blue-500 [&>path:last-of-type]:stroke-neutral-800
            "
          height={4}
          width={10}
        />
      </div>
    </FloatingPortal>
  );
});
