import React from "react";
import { InsuranceIncomeProtectionScenarioModelGraph } from "../Graph";
import { InsuranceIncomeProtectionScenarioDataReturn } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { StyledText } from "../../../../../StyledText";

interface InsuranceIncomeProtectionPlaceholderModelProps {
  theme?: Theme;
}

var data: InsuranceIncomeProtectionScenarioDataReturn[] = [
  {
    clientName: "John Smith",
    existingCover: 140000,
    calculatedCover: 150000,
    recommendedCover: 140000,
    gap: 70000,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
  {
    clientName: "Jane Smith",
    existingCover: 60000,
    calculatedCover: 36000,
    recommendedCover: 80000,
    gap: 50000,
    incomeReplacement: 0,
    clearDebt: 0,
    estatePlanning: 0,
    childrenExpenses: 0,
  },
];

export const InsuranceIncomeProtectionScenarioPlaceholderModel = ({
  theme,
}: InsuranceIncomeProtectionPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Income Protection Insurance
        </StyledText>

        <ModelTooltip type={Model_Type.InsuranceIncomeProtection} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InsuranceIncomeProtectionScenarioModelGraph scenarioData={data} />
      </div>
    </React.Fragment>
  );
};
