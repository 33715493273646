import { QueryResult } from "@apollo/client";
import {
  EstateplanningSubscription,
  FindUniqueEstateplanningQuery,
  FindUniqueEstateplanningQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { estateplanning } from "../subscription";

interface EstateplanningResolverParams {
  prev: FindUniqueEstateplanningQuery;
  payload: EstateplanningSubscription["estateplanning"];
}

function updateEstateplanning({ prev, payload }: EstateplanningResolverParams) {
  const estateplanning = payload.data;

  if (!prev.findUniqueEstateplanning) return prev;
  return Object.assign({}, prev, {
    findUniqueEstateplanning:
      estateplanning.ID === prev.findUniqueEstateplanning.ID
        ? {
            ...prev.findUniqueEstateplanning,
            ...estateplanning,
          }
        : prev.findUniqueEstateplanning,
  });
}

function estateplanningResolver({
  prev,
  payload,
}: EstateplanningResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createEstateplanning(prev, payload);

    case MutationType.Update:
      return updateEstateplanning({ prev, payload });

    // case MutationType.Delete:
    //   return deleteEstateplanning({ prev, payload });

    default:
      return prev;
  }
}

export function FindUniqueEstateplanning(
  query: Pick<
    QueryResult<
      FindUniqueEstateplanningQuery,
      FindUniqueEstateplanningQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: estateplanning,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: EstateplanningSubscription } }
    ) =>
      estateplanningResolver({
        prev,
        payload: payload.subscriptionData.data.estateplanning
      }),
    variables: query.variables,
  });
}
