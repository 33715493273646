import { QueryResult } from "@apollo/client";
import {
  ReviewagendaSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { reviewagenda } from "../subscription";

interface ReviewagendaResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ReviewagendaSubscription["reviewagenda"];
}

function createReviewagenda({ prev, payload }: ReviewagendaResolverParams) {
  const reviewagenda = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      reviewagenda:
        reviewagenda.groups_ID === prev.findUniqueGroup.ID
          ? [...prev.findUniqueGroup.reviewagenda, reviewagenda]
          : prev.findUniqueGroup.reviewagenda,
    },
  });
}

function updateReviewagenda({ prev, payload }: ReviewagendaResolverParams) {
  const reviewagenda = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      reviewagenda: prev.findUniqueGroup.reviewagenda.map((agenda) =>
        agenda.ID === reviewagenda.ID ? { ...agenda, ...reviewagenda } : agenda
      ),
    },
  });
}

function deleteReviewagenda({ prev, payload }: ReviewagendaResolverParams) {
  const reviewagenda = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      reviewagenda: [...prev.findUniqueGroup.reviewagenda].filter(
        (agenda) => agenda.ID !== reviewagenda.ID
      ),
    },
  });
}

function reviewagendaResolver({ prev, payload }: ReviewagendaResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createReviewagenda({ prev, payload });

    case MutationType.Update:
      return updateReviewagenda({ prev, payload });

    case MutationType.Delete:
      return deleteReviewagenda({ prev, payload });

    default:
      return prev;
  }
}

export function reviewagendaFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: reviewagenda,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ReviewagendaSubscription } }
    ) =>
      reviewagendaResolver({
        prev,
        payload: payload.subscriptionData.data.reviewagenda,
      }),
    variables: query.variables,
  });
}
