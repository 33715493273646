import {
  AnnuitySubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { annuity } from "../subscription";
import { QueryResult } from "@apollo/client";

interface AnnuityResolverParams {
  prev: FindUniqueGroupQuery;
  payload: AnnuitySubscription["annuity"];
}

function createAnnuity({ prev, payload }: AnnuityResolverParams) {
  const { data: createAnnuity, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            annuity: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.annuity, createAnnuity]
              : client.assets.annuity,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            annuity: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.annuity, createAnnuity]
              : entity.assets.annuity,
          },
        };
      }),
    },
  });
}

function updateAnnuity({ prev, payload }: AnnuityResolverParams) {
  const { data: updateAnnuity, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the annuity object
   * This is because updateAnnuity only returns the annuity info
   * without assetallocation, notes, etc.
   */
  var updatedAnnuity = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.annuity.forEach((annuity) => {
      if (annuity.assets_ID === updateAnnuity.assets_ID) {
        result = {
          ...annuity,
          ...updateAnnuity,
        };
      }
    });
    return result;
  }, updateAnnuity);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update annuity first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          annuity: [
            ...client.assets.annuity
              .map((annuity) =>
                annuity.ID === updatedAnnuity.ID
                  ? { ...annuity, ...updatedAnnuity }
                  : annuity
              )
              .filter(
                (annuity) =>
                  ![...data.clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(annuity.assets_ID)
              ),
            ...(data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? data.clients_has_assets.reduce<
                  Array<AnnuitySubscription["annuity"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedAnnuity.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedAnnuity];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    annuity: liability.assets.annuity.map(
                      (annuity) =>
                        annuity.ID === updateAnnuity.ID
                          ? {
                              ...annuity,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateAnnuity,
                            }
                          : annuity /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            annuity: goal.assets.annuity.map((annuity) =>
              annuity.ID === updatedAnnuity.ID
                ? { ...annuity, ...updatedAnnuity }
                : annuity
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      annuity: liability.assets.annuity.map((annuity) =>
                        annuity.ID === updateAnnuity.ID
                          ? {
                              ...annuity,
                              /** Update loan info */
                              ...updateAnnuity,
                            }
                          : annuity
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing annuity object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          annuity: [
            ...entity.assets.annuity
              .map((annuity) =>
                annuity.ID === updatedAnnuity.ID
                  ? { ...annuity, ...updatedAnnuity }
                  : annuity
              )
              .filter(
                (annuity) =>
                  ![...data.entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(annuity.assets_ID)
              ),
            ...(data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? data.entities_has_assets.reduce<
                  Array<AnnuitySubscription["annuity"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedAnnuity.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedAnnuity];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    annuity: liability.assets.annuity.map(
                      (annuity) =>
                        annuity.ID === updateAnnuity.ID
                          ? {
                              ...annuity,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateAnnuity,
                            }
                          : annuity /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteAnnuity({ prev, payload }: AnnuityResolverParams) {
  const { data: deleteAnnuity } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            annuity: [...client.assets.annuity].filter(
              (annuity) => annuity.ID !== deleteAnnuity.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      annuity: liability.assets.annuity.filter(
                        (annuity) => annuity.ID !== deleteAnnuity.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              annuity: goal.assets.annuity.filter(
                (annuity) => annuity.ID !== deleteAnnuity.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        annuity: liability.assets.annuity.filter(
                          (annuity) => annuity.ID !== deleteAnnuity.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            annuity: [...entity.assets.annuity].filter(
              (annuity) => annuity.ID !== deleteAnnuity.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      annuity: liability.assets.annuity.filter(
                        (annuity) => annuity.ID !== deleteAnnuity.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function annuityResolver({ prev, payload }: AnnuityResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAnnuity({ prev, payload });

    case MutationType.Update:
      return updateAnnuity({ prev, payload });

    case MutationType.Delete:
      return deleteAnnuity({ prev, payload });

    default:
      return prev;
  }
}

export function annuityFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: annuity,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AnnuitySubscription } }
    ) =>
      annuityResolver({
        prev,
        payload: payload.subscriptionData.data.annuity
      }),
    variables: query.variables,
  });
}
