import { gql } from "@apollo/client";
import { userFragment } from "./fragment";

export const user = gql`
  subscription User($where: userWhereUniqueInput) {
    user(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...user
      }
    }
  }
  ${userFragment}
`;

export const userMany = gql`
  subscription UserMany($where: userWhereInput) {
    userMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...user
      }
    }
  }
  ${userFragment}
`;
