import { gql } from "@apollo/client";
import { historyFragment } from "../history";
import { notesFragment } from "../notes/fragment";
// import { assetsCoreFragment } from "../assets";
import { annuityCoreFragment } from "../annuity";
import { cashCoreFragment } from "../cash";
import { collectiblesCoreFragment } from "../collectibles";
import { otherassetsCoreFragment } from "../otherassets";
import { propertyCoreFragment } from "../property";
import { sharesCoreFragment } from "../shares";
import { superannuationCoreFragment } from "../superannuation";
import { vehiclesCoreFragment } from "../vehicles";

export const createOneLoan = gql`
  mutation CreateOneLoans($data: loansCreateInput!) {
    createOneLoans(data: $data) {
      ID
      liabilities_ID
      Bank
      BSB
      AccountNumber
      TaxDeductible
      Purpose
      SecuredBy
      RelatedAsset
      InterestRate
      VariableFixed
      FixedExpiry
      InterestOnlyExpiry
      RepaymentPerMonth
      LoanLimit
      AvailableRedraw
      RepaymentType
      LoanOwnership
      CurrentLoanOutstanding
      LoanType
      LoanStartDate
      LoanExpiry
      Description
      InterestOnlyStartDate
      RateReviewedDate
      Offset
      Position
      Refinance
      create_time
      update_time
      created_by
      updated_by

      assets {
        annuity {
          ...annuityCore
        }

        cash {
          ...cashCore
        }

        collectibles {
          ...collectiblesCore
        }

        otherassets {
          ...otherassetsCore
        }

        offset {
          ID
          assets_ID
          Bank
          BSB
          AccountNumber
          Amount
          RelatedLoan
          Purpose
          OwnershipType
          create_time
          update_time
          created_by
          updated_by
        }

        property {
          ...propertyCore
        }

        shares {
          ...sharesCore
        }

        superannuation {
          ...superannuationCore
        }

        vehicles {
          ...vehiclesCore
        }
      }

      notes {
        ...notes
      }

      history {
        ...history
      }
    }
  }
  ${annuityCoreFragment}
  ${cashCoreFragment}
  ${collectiblesCoreFragment}
  ${otherassetsCoreFragment}
  ${propertyCoreFragment}
  ${sharesCoreFragment}
  ${superannuationCoreFragment}
  ${vehiclesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;

export const updateOneLoan = gql`
  mutation UpdateOneLoans(
    $data: loansUpdateInput!
    $where: loansWhereUniqueInput!
  ) {
    updateOneLoans(data: $data, where: $where) {
      ID
      liabilities_ID
      Bank
      BSB
      AccountNumber
      TaxDeductible
      Purpose
      SecuredBy
      RelatedAsset
      InterestRate
      VariableFixed
      FixedExpiry
      InterestOnlyExpiry
      RepaymentPerMonth
      LoanLimit
      AvailableRedraw
      RepaymentType
      LoanOwnership
      CurrentLoanOutstanding
      LoanType
      LoanStartDate
      LoanExpiry
      Description
      InterestOnlyStartDate
      RateReviewedDate
      Offset
      Position
      Refinance
      create_time
      update_time
      created_by
      updated_by

      assets {
        annuity {
          ...annuityCore
        }

        cash {
          ...cashCore
        }

        collectibles {
          ...collectiblesCore
        }

        otherassets {
          ...otherassetsCore
        }

        offset {
          ID
          assets_ID
          Bank
          BSB
          AccountNumber
          Amount
          RelatedLoan
          Purpose
          OwnershipType
          create_time
          update_time
          created_by
          updated_by
        }

        property {
          ...propertyCore
        }

        shares {
          ...sharesCore
        }

        superannuation {
          ...superannuationCore
        }

        vehicles {
          ...vehiclesCore
        }
      }

      notes {
        ...notes
      }

      history {
        ...history
      }
    }
  }
  ${annuityCoreFragment}
  ${cashCoreFragment}
  ${collectiblesCoreFragment}
  ${otherassetsCoreFragment}
  ${propertyCoreFragment}
  ${sharesCoreFragment}
  ${superannuationCoreFragment}
  ${vehiclesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;

export const deleteOneLoan = gql`
  mutation DeleteOneLoans($where: loansWhereUniqueInput!) {
    deleteOneLoans(where: $where) {
      ID
      liabilities_ID
      Bank
      BSB
      AccountNumber
      TaxDeductible
      Purpose
      SecuredBy
      RelatedAsset
      InterestRate
      VariableFixed
      FixedExpiry
      InterestOnlyExpiry
      RepaymentPerMonth
      LoanLimit
      AvailableRedraw
      RepaymentType
      LoanOwnership
      CurrentLoanOutstanding
      LoanType
      LoanStartDate
      LoanExpiry
      Description
      InterestOnlyStartDate
      RateReviewedDate
      Offset
      Position
      Refinance
      create_time
      update_time
      created_by
      updated_by

      assets {
        annuity {
          ...annuityCore
        }

        cash {
          ...cashCore
        }

        collectibles {
          ...collectiblesCore
        }

        otherassets {
          ...otherassetsCore
        }

        offset {
          ID
          assets_ID
          Bank
          BSB
          AccountNumber
          Amount
          RelatedLoan
          Purpose
          OwnershipType
          create_time
          update_time
          created_by
          updated_by
        }

        property {
          ...propertyCore
        }

        shares {
          ...sharesCore
        }

        superannuation {
          ...superannuationCore
        }

        vehicles {
          ...vehiclesCore
        }
      }

      notes {
        ...notes
      }

      history {
        ...history
      }
    }
  }
  ${annuityCoreFragment}
  ${cashCoreFragment}
  ${collectiblesCoreFragment}
  ${otherassetsCoreFragment}
  ${propertyCoreFragment}
  ${sharesCoreFragment}
  ${superannuationCoreFragment}
  ${vehiclesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;
