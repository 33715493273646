import { QueryResult } from "@apollo/client";
import {
  FindUniqueModelQuery,
  FindUniqueModelQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueModelQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, model_has_notes } = payload;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      notes: model_has_notes.some(
        (relation) => relation.model_ID === prev.findUniqueModel?.ID
      )
        ? [...prev.findUniqueModel.notes, notes]
        : prev.findUniqueModel.notes,
    },
  });
}
 
function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      notes: prev.findUniqueModel.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      notes: prev.findUniqueModel.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueModel(query: Pick<
  QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
  "subscribeToMore" | "variables"
>) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes
      }),
    variables: query.variables,
  });
}
