import { gql } from "@apollo/client";
import { addressFragment } from "../address";
import { assetsFragment } from "../assets";
import { contributionswithdrawalsFragment } from "../contributionswithdrawals";
import { employmentFragment } from "../employment";
import { estateplanningFragment } from "../estateplanning";
import { goalsFragment } from "../goals";
import { incomeFragment } from "../income";
import { insuranceFragment } from "../insurance";
import { interestFragment } from "../interest";
import { liabilitiesFragment } from "../liabilities";
import { notesFragment } from "../notes";
import { salarypackagingFragment } from "../salarypackaging";

export const clientsCoreFragment = gql`
  fragment clientsCore on clients {
    ID
    groups_ID
    groups_organisation_ID
    Title
    FirstName
    MiddleName
    LastName
    PreferredName
    DateOfBirth
    Gender
    MaritalStatus
    Status
    Spouse
    Salutation
    MailingName
    LastContact
    MobileNumber
    OtherMobile
    HomeNumber
    WorkNumber
    Fax
    PersonalEmail
    OtherEmail
    WorkEmail
    PreferredEmail
    ContactMethod
    TownCountryOfBirth
    Nationality
    ResidencyStatus
    CountryOfTaxResidency
    ArrivedInAus
    CountryCitizenship
    DriversLicenceNumber
    PassportNumber
    MedicareNumber
    TFN
    ABN
    BAS
    ACN
    Coffee
    ArchivedDate
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const clientsFragment = gql`
  fragment clients on clients {
    ...clientsCore

    address {
      ...address
    }

    assets {
      ...assets
    }

    employment {
      ...employment
    }

    estateplanning {
      ...estateplanning
    }

    goals {
      ...goals
    }

    income {
      ...income
    }

    insurance {
      ...insurance
    }

    interest {
      ...interest
    }

    liabilities {
      ...liabilities
    }

    salarypackaging {
      ...salarypackaging
    }

    notes {
      ...notes
    }
  }
  ${clientsCoreFragment}
  ${notesFragment}
  ${estateplanningFragment}
  ${addressFragment}
  ${assetsFragment}
  ${liabilitiesFragment}
  ${employmentFragment}
  ${contributionswithdrawalsFragment}
  ${goalsFragment}
  ${incomeFragment}
  ${insuranceFragment}
  ${interestFragment}
  ${salarypackagingFragment}
`;
