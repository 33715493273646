import { gql } from "@apollo/client";
import { scenarioclientFragment } from "./fragment";

export const scenarioclient = gql`
  subscription Scenarioclient($where: scenarioclientWhereUniqueInput) {
    scenarioclient(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioclient
      }
      model_has_scenarioclient {
        model_ID
        scenarioclient_ID
      }
    }
  }
  ${scenarioclientFragment}
`;

export const scenarioclientMany = gql`
  subscription ScenarioclientMany($where: scenarioclientWhereInput) {
    scenarioclientMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioclient
      }
      model_has_scenarioclient {
        model_ID
        scenarioclient_ID
      }
    }
  }
  ${scenarioclientFragment}
`;
