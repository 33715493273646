import { QueryResult } from "@apollo/client";
import {
  GoalsSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { goals } from "../subscription";

interface GoalsResolverParams {
  prev: FindUniqueGroupQuery;
  payload: GoalsSubscription["goals"];
}

function createGoals({ prev, payload }: GoalsResolverParams) {
  const goal = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          goals:
            goal.clients_ID === client.ID
              ? [...client.goals, goal]
              : client.goals,
        };
      }),
    },
  });
}

function updateGoals({ prev, payload }: GoalsResolverParams) {
  const { data: goal } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          goals: client.goals.map((goals) =>
            goals.ID === goal.ID ? { ...goals, ...goal } : goals
          ),
        };
      }),
    },
  });
}

function deleteGoals({ prev, payload }: GoalsResolverParams) {
  const goal = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          goals: [...client.goals].filter((goals) => goals.ID !== goal.ID),
        };
      }),
    },
  });
}

function goalsResolver({ prev, payload }: GoalsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createGoals({ prev, payload });

    case MutationType.Update:
      return updateGoals({ prev, payload });

    case MutationType.Delete:
      return deleteGoals({ prev, payload });

    default:
      return prev;
  }
}

export function goalsFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: goals,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: GoalsSubscription } }
    ) =>
      goalsResolver({
        prev,
        payload: payload.subscriptionData.data.goals
      }),
    variables: query.variables,
  });
}
