import React, { useEffect, useState } from "react";
import { Button, ButtonProps } from "../../Buttons";
import clsx from "clsx";
import { Tooltip } from "../../Tooltip";

export interface DropdownOptions {
  onClick?: Function;
  text: string;
  icon?: string;
  className?: string;
  tooltip?: string;
  loading?: boolean;
  disabled?: boolean;
  selected?: boolean; //If item is currently selected, like theme select for documents
  options?:
    | Array<DropdownOptions>
    | JSX.Element
    | (({
        setMenuOpen,
        setSecondMenuOpen,
      }: {
        setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setSecondMenuOpen: React.Dispatch<
          React.SetStateAction<string | undefined>
        >;
      }) => JSX.Element);
}

export interface MultiLevelDropdownProps {
  buttonProps: ButtonProps;
  id: string;
  options: Array<DropdownOptions>;
}

export const MultiLevelDropdown: React.FC<MultiLevelDropdownProps> = ({
  buttonProps,
  id,
  options,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [secondMenuOpen, setSecondMenuOpen] = useState<string | undefined>(
    undefined
  );

  const [menuPosition, setMenuPosition] = useState({ y: "top-8", x: "left-0" });

  /* This determines the left and right position of the multi level dropdown depending on the 
  position of the button (i.e if button is on the right side of the screen, add a right classname) */
  useEffect(() => {
    var dropdownDiv = document.getElementById("dropdown-menu-id-" + id);
    var newMenuPosition = { y: "top-8", x: "left-0" };
    if (dropdownDiv) {
      var position = dropdownDiv.getBoundingClientRect();
      var x = position.left;
      var y = position.top;

      if (x > window.innerWidth / 2) {
        newMenuPosition.x = "right-0";
      } else {
        newMenuPosition.x = "left-0";
      }

      if (y > window.innerHeight / 2) {
        newMenuPosition.y = "bottom-8";
      } else {
        newMenuPosition.y = "top-8";
      }
    }

    setMenuPosition(newMenuPosition);
  }, [menuOpen, id]);

  useEffect(() => {
    setSecondMenuOpen(undefined);
  }, [menuOpen]);

  return (
    <React.Fragment>
      {menuOpen && (
        <div
          className="fixed inset-0 z-30 bg-neutral-300/5"
          onClick={() => setMenuOpen(false)}
        />
      )}
      <div
        className={clsx(
          menuOpen ? "z-40" : "",
          "relative text-left max-w-48 inline-block justify-center"
        )}
        id={"menu-" + id}
      >
        <Button
          {...buttonProps}
          id={"dropdown-button-id-" + id}
          onClick={() => setMenuOpen(!menuOpen)}
          ariapressed={menuOpen}
        />
        {menuOpen && (
          <ul
            className={clsx(
              menuPosition.y,
              menuPosition.x,
              "flex absolute z-40 rounded-md min-w-72 bg-white shadow-lg ring-1 ring-neutral-500 ring-opacity-5 outline-none"
            )}
            id={"dropdown-menu-id-" + id}
          >
            <div className="py-1 w-full">
              {options.map((option, index) => {
                return option.options ? (
                  <li
                    key={index}
                    onClick={() => {
                      setSecondMenuOpen(option.text + index);
                    }}
                    className={clsx(
                      "group/parent relative w-full flex justify-between items-center px-4 py-2 gap-1 text-sm text-neutral-600 cursor-pointer hover:bg-blue-500"
                    )}
                  >
                    <Tooltip title={option.tooltip ?? ""}>
                      <div className="flex gap-1 items-centre group-hover/parent:text-white">
                        {option.icon && (
                          <div className={clsx(option.icon, "icon")} />
                        )}
                        {option.text}
                      </div>
                    </Tooltip>
                    <ul
                      id={"dropdown-secondmenu-id-" + id}
                      className={clsx(
                        menuPosition.x.includes("right")
                          ? "right-full"
                          : "left-full",
                        secondMenuOpen === option.text + index
                          ? "flex"
                          : "hidden",
                        "absolute z-50 top-0 flex flex-col items-center ",
                        "rounded-md min-w-72 bg-white shadow-lg ring-1 ring-neutral-500",
                        "ring-opacity-5 outline-none"
                      )}
                    >
                      {Array.isArray(option.options) ? (
                        option.options.map((secondOption, secondIndex) => {
                          return (
                            <li
                              key={secondOption.text + secondIndex}
                              onClick={(event) => {
                                secondOption.onClick &&
                                  secondOption.onClick(event);
                                setMenuOpen(false);
                                setSecondMenuOpen(undefined);
                              }}
                              className={clsx(
                                "group/child w-full flex justify-between items-center px-4 py-2 gap-1",
                                "text-sm text-neutral-600 cursor-pointer hover:bg-blue-500"
                              )}
                            >
                              <div
                                className={clsx(
                                  secondOption.selected ? "font-bold" : "",
                                  "flex gap-1 items-centre group-hover/child:text-white"
                                )}
                              >
                                {secondOption.icon && (
                                  <div
                                    className={clsx(secondOption.icon, "icon")}
                                  />
                                )}
                                {secondOption.text}
                              </div>
                              {secondOption.selected && (
                                <div
                                  className={
                                    "icon fa-check fa-solid text-blue-500 group-hover/child:text-white"
                                  }
                                />
                              )}
                            </li>
                          );
                        })
                      ) : (
                        <div className="px-2 py-1 w-full">
                          {typeof option.options === "function"
                            ? option.options({
                                setMenuOpen,
                                setSecondMenuOpen,
                              })
                            : option.options}
                        </div>
                      )}
                    </ul>
                    <div
                      className={
                        "icon fa-solid fa-caret-right group-hover/parent:text-white"
                      }
                    />
                  </li>
                ) : (
                  <li
                    key={index}
                    onClick={(event) => {
                      option.onClick && option.onClick(event);
                      setMenuOpen(false);
                    }}
                    className={clsx(
                      "group w-full flex justify-between items-center px-4 py-2 gap-1 text-sm text-neutral-600 cursor-pointer hover:bg-blue-500 hover:text-white"
                    )}
                  >
                    <div className="flex gap-1 items-centre">
                      {option.icon && (
                        <div className={clsx(option.icon, "icon ")} />
                      )}
                      {option.text}
                    </div>
                  </li>
                );
              })}
            </div>
          </ul>
        )}
      </div>
    </React.Fragment>
  );
};
