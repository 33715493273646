import { QueryResult } from "@apollo/client";
import {
  FindUniqueStaffQuery,
  FindUniqueStaffQueryVariables,
  MutationType,
  UserSubscription,
} from "../../../codegen/schema";
import { user } from "../subscription";

interface UserResolverParams {
  prev: FindUniqueStaffQuery;
  payload: UserSubscription["user"];
}

function updateUser({ prev, payload }: UserResolverParams) {
  const user = payload.data;

  if (!prev.findUniqueStaff) return prev;
  return Object.assign({}, prev, {
    findUniqueStaff:
      prev.findUniqueStaff.ID === user.staff_ID
        ? {
            ...prev.findUniqueStaff,
            user: {
              ...prev.findUniqueStaff.user,
              ...user,
            },
          }
        : prev.findUniqueStaff,
  });
}

function deleteUser({ prev, payload }: UserResolverParams) {
  const user = payload.data;

  if (!prev.findUniqueStaff) return prev;
  return Object.assign({}, prev, {
    findUniqueStaff:
      prev.findUniqueStaff.ID === user.staff_ID
        ? { ...prev.findUniqueStaff, user: undefined }
        : prev.findUniqueStaff,
  });
}

function addressResolver({ prev, payload }: UserResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createAddress(prev, payload);

    case MutationType.Update:
      return updateUser({ prev, payload });

    case MutationType.Delete:
      return deleteUser({ prev, payload });

    default:
      return prev;
  }
}

export function userFindUniqueStaff(
  query: Pick<
    QueryResult<FindUniqueStaffQuery, FindUniqueStaffQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: user,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: UserSubscription } }
    ) =>
      addressResolver({
        prev,
        payload: payload.subscriptionData.data.user
      }),
    variables: query.variables,
  });
}
