import { QueryResult } from "@apollo/client";
import {
  AdviceSubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { advice } from "../subscription";

interface AdviceResolverParams {
  prev: FindUniqueModelQuery;
  payload: AdviceSubscription["advice"];
}

function createAdvice({ prev, payload }: AdviceResolverParams) {
  const { data: advice, model_has_advice } = payload;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      advice: model_has_advice.some(
        (relation) =>
          relation.model_ID === prev.findUniqueModel?.ID &&
          relation.advice_ID === advice.ID
      )
        ? [...prev.findUniqueModel.advice, advice]
        : prev.findUniqueModel.advice,
    },
  });
}

function updateAdvice({ prev, payload }: AdviceResolverParams) {
  const { data: advice, model_has_advice } = payload;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      /** If advice already in model.advice, update, else add to array if model_has_advice */
      advice: prev.findUniqueModel.advice.some(
        (advices) => advices.ID === advice.ID
      )
        ? prev.findUniqueModel.advice.map((advices) =>
            advices.ID === advice.ID ? { ...advices, ...advice } : advices
          )
        : model_has_advice.some(
            (relation) =>
              relation.model_ID === prev.findUniqueModel?.ID &&
              relation.advice_ID === advice.ID
          )
        ? [advice, ...prev.findUniqueModel.advice]
        : prev.findUniqueModel.advice,
    },
  });
}

function deleteAdvice({ prev, payload }: AdviceResolverParams) {
  const advice = payload.data;

  if (!prev.findUniqueModel) return prev;
  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      advice: [...prev.findUniqueModel.advice].filter(
        (data) => data.ID !== advice.ID
      ),
    },
  });
}

function adviceResolver({ prev, payload }: AdviceResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createAdvice({ prev, payload });

    case MutationType.Update:
      return updateAdvice({ prev, payload });

    case MutationType.Delete:
      return deleteAdvice({ prev, payload });

    default:
      return prev;
  }
}

export function adviceFindUniqueModel(
  query: Pick<
    QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: advice,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: AdviceSubscription } }
    ) =>
      adviceResolver({
        prev,
        payload: payload.subscriptionData.data.advice
      }),
    variables: query.variables,
  });
}
