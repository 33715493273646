import { QueryResult } from "@apollo/client";
import {
  ScenariochildSubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { scenariochild } from "../subscription";

interface ScenariochildResolverParams {
  prev: FindUniqueModelQuery;
  payload: ScenariochildSubscription["scenariochild"];
}

function createScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const { data: scenariochild, model_has_scenariochild } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenariochild: model_has_scenariochild.some(
        (relation) =>
          relation.model_ID === prev.findUniqueModel?.ID &&
          relation.scenariochild_ID === scenariochild.ID
      )
        ? [...prev.findUniqueModel.scenariochild, scenariochild]
        : prev.findUniqueModel.scenariochild,
    },
  });
}

function updateScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const { data: scenariochild, model_has_scenariochild } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      /** If scenariochild already in model.scenariochild, update, else add to array if
       * model_has_scenariochild */
      scenariochild: prev.findUniqueModel.scenariochild.some(
        (scenariochilds) => scenariochilds.ID === scenariochild.ID
      )
        ? prev.findUniqueModel.scenariochild.map((scenariochilds) =>
            scenariochilds.ID === scenariochild.ID
              ? { ...scenariochilds, ...scenariochild }
              : scenariochilds
          )
        : model_has_scenariochild.some(
            (relation) =>
              relation.model_ID === prev.findUniqueModel?.ID &&
              relation.scenariochild_ID === scenariochild.ID
          )
        ? [...prev.findUniqueModel.scenariochild, scenariochild]
        : prev.findUniqueModel.scenariochild,
    },
  });
}

function deleteScenariochild({ prev, payload }: ScenariochildResolverParams) {
  const scenariochild = payload.data;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenariochild: [...prev.findUniqueModel.scenariochild].filter(
        (scenariochilds) => scenariochilds.ID !== scenariochild.ID
      ),
    },
  });
}

function scenariochildResolver({ prev, payload }: ScenariochildResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenariochild({ prev, payload });

    case MutationType.Update:
      return updateScenariochild({ prev, payload });

    case MutationType.Delete:
      return deleteScenariochild({ prev, payload });

    default:
      return prev;
  }
}

export function scenariochildFindUniqueModel(
  query: Pick<
    QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenariochild,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenariochildSubscription } }
    ) =>
      scenariochildResolver({
        prev,
        payload: payload.subscriptionData.data.scenariochild,
      }),
    variables: query.variables,
  });
}
