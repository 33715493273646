export function IncomeTaxModelDescription() {
  return (
     <p className="text-2xs italic">
      The income tax model displays the net income and tax payable of each
      scenario client.
    </p>
  );
}

export function IncomeTaxCalculationTooltip() {
  return (
    <>
      <p>
        <b>Scenario</b><br/>
        This graph shows what percentage of income tax is paid at each tax
        bracket. This is based on the latest tax bracket information from the
        ATO and individual residency status per client.
      </p>
      <p>
        <b>Comparison</b><br/>
        The comparison graph shows the total amount of tax paid per client per scenario.
      </p>
    </>
  );
}