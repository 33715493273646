import { gql } from "@apollo/client";
import { servicesFragment } from "./fragment";

export const services = gql`
  subscription Services($where: servicesWhereUniqueInput) {
    services(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...services
      }
    }
  }
  ${servicesFragment}
`;

export const servicesMany = gql`
  subscription ServicesMany($where: servicesWhereInput) {
    servicesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...services
      }
    }
  }
  ${servicesFragment}
`;
