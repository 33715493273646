import { QueryResult } from "@apollo/client";
import { FindManyAdviceagreement } from "..";
import {
  FindManyAdviceagreementQuery,
  FindManyAdviceagreementQueryVariables,
} from "../../../codegen/schema";

export function findManyAdviceagreementSubscription(
  query: Pick<
    QueryResult<
      FindManyAdviceagreementQuery,
      FindManyAdviceagreementQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  FindManyAdviceagreement(query);
}
