import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  FindUniqueUserQuery,
  useFindUniqueGroupQuery,
} from "../../../../codegen/schema";
import { Refetch } from "../../../../Components/Refetch";
import { ComponentLoader } from "../../../../Components/Loaders/ComponentLoader";
import { GroupStatus } from "../../../../Pages/Group&Client/Settings/GroupStatus";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Params = {
  groupsid: string;
};
interface SidebarGroupProps {
  user: FindUniqueUserQuery["findUniqueUser"];
}

export const SidebarGroup = ({ user }: SidebarGroupProps) => {
  const params = useParams<Params>();
  // const [, dispatch] = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: { findUniqueGroup: group } = {},
    error: groupError,
    loading: groupLoading,
    refetch: groupRefetch,
  } = useFindUniqueGroupQuery({
    fetchPolicy: "cache-and-network",
    variables: { where: { ID: parseInt(params.groupsid!) } },
  });

  const navigation = [
    { name: "Details", href: "details", icon: "fa-circle-user" },
    {
      name: "Financial data",
      href: "financialdata",
      icon: "fa-badge-dollar",
    },
    {
      name: "Property & Finance",
      href: "propertyfinance",
      icon: "fa-house",
    },
    { name: "Advice", href: "advice", icon: "fa-chart-area" },
    { name: "Tasks", href: "tasks", icon: "fa-calendar" },
    { name: "Fees", href: "fees", icon: "fa-file-invoice" },
    { name: "Settings", href: "settings", icon: "fa-screwdriver-wrench" },
  ];

  if (groupError && !groupLoading) {
    return (
      <Refetch
        refetch={() => {
          groupRefetch();
        }}
      />
    );
  }

  if (!group && groupLoading) {
    return <ComponentLoader />;
  }

  return (
    group && (
      <div className="pl-24 flex grow flex-col gap-y-4 overflow-y-auto border-r border-neutral-100 bg-white pr-4 py-6">
        <div className="flex justify-between items-center">
          <GroupStatus buttonType="no-style" user={user} group={group} />
          <h6 className="text-xs">ID: {group.ID}</h6>
        </div>
        <h4 className="text-center">{group?.GroupName}</h4>

        {group.clients.map((client) => {
          return (
            <h5
              className="text-xs text-center flex flex-col gap-1"
              // onClick={() => {
              //   dispatch({
              //     type: "set/groupFilter",
              //     payload: [client.ID],
              //   });
              // }}
              key={client.ID}
            >
              {client.FirstName} {client.LastName}{" "}
              {client.PreferredName !== null && client.PreferredName !== "" ? (
                <>({client.PreferredName})</>
              ) : (
                ""
              )}
              <b className="text-neutral-300">ID: {client.ID}</b>
            </h5>
          );
        })}

        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => {
                  const pathname = location.pathname.substring(1);
                  const current =
                    item.name === "Advice"
                      ? pathname.includes(item.href) &&
                        !pathname.includes("fees")
                      : pathname.includes(item.href);

                  return (
                    <li key={item.name}>
                      <div
                        onClick={() =>
                          navigate(`group/ID/${group.ID}/${item.href}`)
                        }
                        className={classNames(
                          current
                            ? "bg-neutral-50 text-blue-500"
                            : "hover:bg-neutral-50 text-neutral-600",
                          "group flex gap-x-3 items-center rounded-md p-3 text-sm leading-6 font-semibold cursor-pointer hover:text-blue-500"
                        )}
                      >
                        <i
                          className={classNames(
                            current ? " text-blue-500" : "text-neutral-400",
                            item?.icon ? item.icon : "",
                            "fa-regular shrink-0 fa-xl group-hover:text-blue-500"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    )
  );
};
