import { FindUniqueModel } from "..";
import { scenarioassetFindUniqueModel } from "../../scenarioasset";
// import { scenarioFindUniqueModel } from "../../scenario/reducers";
import { adviceFindUniqueModel } from "../../advice";
import { scenarioliabilityFindUniqueModel } from "../../scenarioliability/reducers";
import { scenariocontributionwithdrawalFindUniqueModel } from "../../scenariocontributionwithdrawal";
import { scenarioclientFindUniqueModel } from "../../scenarioclient";
import { scenarioinsuranceFindUniqueModel } from "../../scenarioinsurance";
import { scenariochildFindUniqueModel } from "../../scenariochild";
import { goalFindUniqueModel } from "../../goal";
import { notesFindUniqueModel } from "../../notes";
import {
  FindUniqueModelQuery,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export default function findUniqueModelSubscription(
  query: Pick<
    QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  adviceFindUniqueModel(query);
  FindUniqueModel(query);
  // scenarioFindUniqueModel(query);
  scenarioassetFindUniqueModel(query);
  scenarioliabilityFindUniqueModel(query);
  scenarioclientFindUniqueModel(query);
  scenariocontributionwithdrawalFindUniqueModel(query);
  scenarioinsuranceFindUniqueModel(query);
  scenariochildFindUniqueModel(query);
  goalFindUniqueModel(query);
  notesFindUniqueModel(query);
}
