import React, { ReactNode } from "react";
import { Editor, Transforms } from "slate";

export class ErrorBoundary extends React.Component<{
  children: ReactNode;
  editor?: Editor;
}> {
  componentDidCatch(error: any) {
    console.log(error);

    if (this.props.editor) {
      Transforms.deselect(this.props.editor);
    }
  }

  render() {
    return this.props.children;
  }
}
