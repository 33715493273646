import { gql } from "@apollo/client";
import { reviewFragment } from "./fragment";

export const createOneReview = gql`
  mutation CreateOneReview($data: reviewCreateInput!) {
    createOneReview(data: $data) {
      ...review
    }
  }
  ${reviewFragment}
`;

export const updateOneReview = gql`
  mutation UpdateOneReview(
    $data: reviewUpdateInput!
    $where: reviewWhereUniqueInput!
  ) {
    updateOneReview(data: $data where: $where) {
      ...review
    }
  }
  ${reviewFragment}
`;

export const deleteOneReview = gql`
  mutation DeleteOneReview($where: reviewWhereUniqueInput!) {
    deleteOneReview(where: $where) {
      ...review
    }
  }
  ${reviewFragment}
`;
