import { gql } from "@apollo/client";
import { bestinterestFragment } from "./fragment";

export const bestinterest = gql`
  subscription Bestinterest($where: bestinterestWhereUniqueInput) {
    bestinterest(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...bestinterest
      }
    }
  }
  ${bestinterestFragment}
`;

export const bestinterestMany = gql`
  subscription BestinterestMany($where: bestinterestWhereInput) {
    bestinterestMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...bestinterest
      }
    }
  }
  ${bestinterestFragment}
`;
