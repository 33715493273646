import { gql } from "@apollo/client";
import { interestFragment } from "./fragment";

export const interest = gql`
  subscription Interest($where: interestWhereUniqueInput) {
    interest(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...interest
      }
    }
  }
  ${interestFragment}
`;

export const interestMany = gql`
  subscription InterestMany($where: interestWhereInput) {
    interestMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...interest
      }
    }
  }
  ${interestFragment}
`;
