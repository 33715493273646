import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { findManyClientsSubscription } from "../API/clients/subscriptions/findManyClients";
import { findManyEntitiesSubscription } from "../API/entities/subscriptions/findManyEntities";
import { findManyGroupsSubscription } from "../API/groups";
import findCurrentUserSubscription from "../API/user/subscriptions/findCurrentUser";
import findCurrentStaffSubscription from "../API/staff/subscriptions/findCurrentStaff";

import {
  SortOrder,
  useFindCurrentStaffQuery,
  useFindCurrentUserQuery,
  useFindManyClientsQuery,
  useFindManyEntitiesQuery,
  useFindManyGroupsQuery,
} from "../codegen/schema";

import TermsandconditionsModal from "../Components/Termsandconditions";
import FullPageLoader from "../Components/Loaders/FullPageLoader";
import { Refetch } from "../Components/Refetch";
import { Sidebar } from "./Sidebar";
import { TopMenu } from "./TopMenu";

const RouteLayout: React.FC = () => {
  const location = useLocation();

  /** Poll every 900,000 ms = 15 mins. Test with 5000 ms = 5 secs */
  /** @todo Set fetchPolicy to "network-only" and handle ForbiddenErrors by redirecting to login */
  const {
    data: { findCurrentUser: user } = {},
    error: userError,
    loading: userLoading,
    refetch: userRefetch,
    ...findCurrentUserResult
  } = useFindCurrentUserQuery({
    fetchPolicy: "cache-and-network",
  });

  /**
   * Required here so that any nested routes receive staff data via cache
   * so dependant queries can be executed correctly
   */
  const {
    error: staffError,
    loading: staffLoading,
    refetch: staffRefetch,
    ...findCurrentStaffResult
  } = useFindCurrentStaffQuery({ skip: !user });

  const {
    error: allGroupsError,
    loading: allGroupsLoading,
    refetch: allGroupsRefetch,
    ...allGroupsResult
  } = useFindManyGroupsQuery({
    skip: !user,
    variables: {
      orderBy: { Status: SortOrder.Asc },
    },
  });

  const {
    error: allClientsError,
    loading: allClientsLoading,
    refetch: allClientsRefetch,
    ...allClientsResult
  } = useFindManyClientsQuery({
    fetchPolicy: "cache-and-network",
    skip: !user,
  });

  const {
    error: allEntitiesError,
    loading: allEntitiesLoading,
    refetch: allEntitiesRefetch,
    ...allEntitiesResult
  } = useFindManyEntitiesQuery({
    skip: !user,
  });

  //Navigation sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    findCurrentStaffSubscription(findCurrentStaffResult);
    findCurrentUserSubscription(findCurrentUserResult);
    findManyGroupsSubscription(allGroupsResult);
    findManyClientsSubscription(allClientsResult);
    findManyEntitiesSubscription(allEntitiesResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setTimeout(() => setLoader(false), 4000);
  // }, []);

  // if (loader) {
  //   return (
  //     <React.Fragment>
  //       <FullPageLoader />
  //     </React.Fragment>
  //   );
  // }

  // If after query there is no user, go to login page.
  // Note that an error will be returned for unauthorised request to resource.
  if (!user && userError && !userLoading) {
    return <Navigate to="login" />;
  }

  if (
    (userError && !userLoading) ||
    (allGroupsError && !allGroupsLoading) ||
    (allClientsError && !allClientsLoading) ||
    (allEntitiesError && !allEntitiesLoading)
  ) {
    return (
      <Refetch
        refetch={() => {
          userRefetch();
          allGroupsRefetch();
          allClientsRefetch();
          allEntitiesRefetch();
        }}
      />
    );
  }

  if (
    location.pathname.includes("/lvpo/group/ID") ||
    location.pathname.includes("review-pdf") ||
    location.pathname.includes("adviceagreement-pdf") ||
    location.pathname.includes("document-pdf") ||
    location.pathname.includes("documenttemplate-pdf")
  ) {
    return (
      <Suspense fallback={<FullPageLoader />}>
        <Outlet />
      </Suspense>
    );
  } else {
    return (
      <React.Fragment>
        <TermsandconditionsModal />

        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <div
          className={
            location.pathname.includes("template/ID") ||
            location.pathname.includes("document/ID") ||
            location.pathname.includes("theme/ID")
              ? "lg:pl-20"
              : "lg:pl-72"
          }
        >
          {/* IF NOT TEMPLATER OR DOCUMENT */}
          {!location.pathname.includes("template/ID") &&
            !location.pathname.includes("document/ID") &&
            !location.pathname.includes("theme/ID") && (
              <TopMenu user={user} setSidebarOpen={setSidebarOpen} />
            )}

          <main className="py-3">
            <div className="px-1 sm:px-6 lg:px-2">
              <Suspense fallback={<FullPageLoader />}>
                <Outlet />
              </Suspense>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
};

export default RouteLayout;
