import dayjs from "dayjs";
import React from "react";
import { RetirementPlanningComparisonModelGraph } from "../Graph";
import { ComparisonData } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface RetirementPlanningPlaceholderModelProps {
  theme?: Theme;
}

const initialValue = 100000;
var data: ComparisonData[] = [];

Array.from(Array(20).keys()).forEach((value) => {
  var previousYear = data[value - 1];

  data.push({
    year: dayjs().year() + value,
    "Scenario 1": previousYear
      ? initialValue * (1 + 0.05 / 12) ** (12 * value)
      : initialValue,
    "Scenario 2": previousYear
      ? initialValue * (1 + 0.04 / 12) ** (12 * value)
      : initialValue,
    "Scenario 3": previousYear
      ? initialValue * (1 + 0.07 / 12) ** (12 * value)
      : initialValue,
    target: value === 19 ? 500000 : undefined,
  });
});

export const RetirementPlanningComparisonPlaceholderModel = ({
  theme,
}: RetirementPlanningPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Retirement Planning
        </StyledText>

        <ModelTooltip type={Model_Type.RetirementPlanning} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <RetirementPlanningComparisonModelGraph comparisonData={data} />
      </div>
    </React.Fragment>
  );
};
