import { gql } from "@apollo/client";
import { scenarioassetFragment } from "../scenarioasset";
import { scenarioliabilityFragment } from "../scenarioliability";
import { scenarioclientFragment } from "../scenarioclient/fragment";
import { scenarioinsuranceFragment } from "../scenarioinsurance";
import { scenariochildFragment } from "../scenariochild";

export const scenarioCoreFragment = gql`
  fragment scenarioCore on scenario {
    ID
    advice_ID
    advice_groups_ID
    advice_groups_organisation_ID
    Title
    create_time
    update_time
    created_by
    updated_by
  }
`;

export const scenarioFragment = gql`
  fragment scenario on scenario {
    ...scenarioCore

    scenarioasset {
      ...scenarioasset
    }
    scenarioliability {
      ...scenarioliability
    }
    scenarioclient {
      ...scenarioclient
    }
    scenarioinsurance {
      ...scenarioinsurance
    }
    scenariochild {
      ...scenariochild
    }
  }
  ${scenarioCoreFragment}
  ${scenarioassetFragment}
  ${scenarioliabilityFragment}
  ${scenarioclientFragment}
  ${scenarioinsuranceFragment}
  ${scenariochildFragment}
`;
