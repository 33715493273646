import { QueryResult } from "@apollo/client";
import {
  ScenarioclientSubscription,
  FindUniqueModelQuery,
  MutationType,
  FindUniqueModelQueryVariables,
} from "../../../codegen/schema";
import { scenarioclient } from "../subscription";

interface ScenarioclientResolverParams {
  prev: FindUniqueModelQuery;
  payload: ScenarioclientSubscription["scenarioclient"];
}

function createScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const { data: scenarioclient, model_has_scenarioclient } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioclient: model_has_scenarioclient.some(
        (relation) =>
          relation.model_ID === prev.findUniqueModel?.ID &&
          relation.scenarioclient_ID === scenarioclient.ID
      )
        ? [...prev.findUniqueModel.scenarioclient, scenarioclient]
        : prev.findUniqueModel.scenarioclient,
    },
  });
}

function updateScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const { data: scenarioclient, model_has_scenarioclient } = payload;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      /** If scenarioclient already in model.scenarioclient, update, else add to array if
       * model_has_scenarioclient */
      scenarioclient: prev.findUniqueModel.scenarioclient.some(
        (scenarioclients) => scenarioclients.ID === scenarioclient.ID
      )
        ? prev.findUniqueModel.scenarioclient.map((scenarioclients) =>
            scenarioclients.ID === scenarioclient.ID
              ? { ...scenarioclients, ...scenarioclient }
              : scenarioclients
          )
        : model_has_scenarioclient.some(
            (relation) =>
              relation.model_ID === prev.findUniqueModel?.ID &&
              relation.scenarioclient_ID === scenarioclient.ID
          )
        ? [...prev.findUniqueModel.scenarioclient, scenarioclient]
        : prev.findUniqueModel.scenarioclient,
    },
  });
}

function deleteScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueModel) return prev;

  return Object.assign({}, prev, {
    findUniqueModel: {
      ...prev.findUniqueModel,
      scenarioclient: [...prev.findUniqueModel.scenarioclient].filter(
        (scenarioclients) => scenarioclients.ID !== scenarioclient.ID
      ),
    },
  });
}

function scenarioclientResolver({
  prev,
  payload,
}: ScenarioclientResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioclient({ prev, payload });

    case MutationType.Update:
      return updateScenarioclient({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioclient({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioclientFindUniqueModel(
  query: Pick<
    QueryResult<FindUniqueModelQuery, FindUniqueModelQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioclient,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioclientSubscription } }
    ) =>
      scenarioclientResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioclient,
      }),
    variables: query.variables,
  });
}
