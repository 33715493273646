import React, { useEffect, useState } from "react";

import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../codegen/schema";

import { StyledText } from "../../StyledText";

import { AssetsAnnuity } from "./Annuity";
import { AssetsCash } from "./Cash";
import { AssetsCollectible } from "./Collectible";
import { AssetsOffset } from "./Offset";
import { AssetsOtherasset } from "./Otherasset";
import { AssetsProperty } from "./Property";
import { AssetsShares } from "./Shares";
import { AssetsSuperannuation } from "./Superannuation";
import { AssetsVehicle } from "./Vehicle";

interface AssetsComponentProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

export const AssetsComponent: React.FC<AssetsComponentProps> = ({
  content = undefined,
  contenttemplate = undefined,
  theme,
  group,
}) => {
  const [currentContent, setCurrentContent] = useState(
    content ? content : contenttemplate
  );

  useEffect(() => {
    setCurrentContent(content ? content : contenttemplate);
  }, [content, contenttemplate]);

  const components = {
    Annuity: AssetsAnnuity,
    Cash: AssetsCash,
    Collectible: AssetsCollectible,
    Offset: AssetsOffset,
    Other_Asset: AssetsOtherasset,
    Property: AssetsProperty,
    Shares: AssetsShares,
    Superannuation: AssetsSuperannuation,
    Vehicle: AssetsVehicle,
  };

  type ComponentType =
    | "Annuity"
    | "Cash"
    | "Collectible"
    | "Offset"
    | "Other_Asset"
    | "Property"
    | "Shares"
    | "Superannuation"
    | "Vehicle";

  if (!currentContent) return null;

  const ContentComponent =
    components[currentContent.AssetType as ComponentType];

  return (
    <React.Fragment>
      <div className="flex flex-col w-full">
        {currentContent.ContentType === "Grouped" ? (
          <>
            <div
              className=" mb-1"
              style={{
                borderBottom:
                  "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
              }}
            >
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H4"
                )}
              >
                {currentContent.AssetType?.replaceAll("_", " ")}
              </StyledText>
            </div>
            <ContentComponent
              content={content}
              contenttemplate={contenttemplate}
              theme={theme}
              group={group}
            />
          </>
        ) : (
          <ContentComponent
            content={content}
            contenttemplate={contenttemplate}
            theme={theme}
            group={group}
          />
        )}
      </div>
    </React.Fragment>
  );
};
