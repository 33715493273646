import {
  FindManyClientsQuery,
  FindManyClientsQueryVariables,
} from "../../../codegen/schema";
import { FindManyClients } from "../";
import { QueryResult } from "@apollo/client";

export function findManyClientsSubscription(
  query: Pick<
    QueryResult<FindManyClientsQuery, FindManyClientsQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  FindManyClients(query);
}
