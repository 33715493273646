import { gql } from "@apollo/client";

export const findUniqueFile = gql`
  query FindUniqueFile($filePath: String!) {
    findUniqueFile(filePath: $filePath) {
      filePath
      fileExists
    }
  }
`;
