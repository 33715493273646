import { gql } from "@apollo/client";
import { advicegoalsFragment } from "./fragment";

export const advicegoals = gql`
  subscription Advicegoals($where: advicegoalsWhereUniqueInput) {
    advicegoals(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...advicegoals
      }
    }
  }
  ${advicegoalsFragment}
`;

export const advicegoalsMany = gql`
  subscription AdvicegoalsMany($where: advicegoalsWhereInput) {
    advicegoalsMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...advicegoals
      }
    }
  }
  ${advicegoalsFragment}
`;
