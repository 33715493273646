import { gql } from "@apollo/client";
import { estateplanningFragment } from ".";

export const updateOneEstateplanning = gql`
  mutation UpdateOneEstateplanning(
    $data: estateplanningUpdateInput!
    $where: estateplanningWhereUniqueInput!
  ) {
    updateOneEstateplanning(data: $data, where: $where) {
      ...estateplanning
    }
  }
  ${estateplanningFragment}
`;
