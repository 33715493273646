import { gql } from "@apollo/client";

export const tagFragment = gql`
  fragment tag on tag {
    ID
    organisation_ID
    Text
    Colour
    Priority
    update_time
    updated_by
    create_time
    created_by
  }
`;
