import { gql } from "@apollo/client";
import { otherliabilitiesFragment } from "./fragment";

export const otherliabilities = gql`
  subscription Otherliabilities($where: otherliabilitiesWhereUniqueInput) {
    otherliabilities(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...otherliabilities
      }
      assets_has_liabilities {
        assets_ID
        liabilities_ID
      }
      clients_has_liabilities {
        clients_ID
        liabilities_ID
      }
      entities_has_liabilities {
        entities_ID
        liabilities_ID
      }
    }
  }
  ${otherliabilitiesFragment}
`;

export const otherliabilitiesMany = gql`
  subscription OtherliabilitiesMany($where: otherliabilitiesWhereInput) {
    otherliabilitiesMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...otherliabilities
      }
      assets_has_liabilities {
        assets_ID
        liabilities_ID
      }
      clients_has_liabilities {
        clients_ID
        liabilities_ID
      }
      entities_has_liabilities {
        entities_ID
        liabilities_ID
      }
    }
  }
  ${otherliabilitiesFragment}
`;
