import { QueryResult } from "@apollo/client";
import {
  ClientsSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { clients } from "../subscription";

interface ClientsResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ClientsSubscription["clients"];
}

function createClients({ prev, payload }: ClientsResolverParams) {
  const clients = payload.data;
  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup:
      prev.findUniqueGroup.ID === clients.groups_ID
        ? {
            ...prev.findUniqueGroup,
            clients: prev.findUniqueGroup.clients.every(
              (client) => client.ID !== clients.ID
            )
              ? [...prev.findUniqueGroup.clients, clients]
              : prev.findUniqueGroup.clients,
          }
        : prev.findUniqueGroup,
  });
}

function updateClients({ prev, payload }: ClientsResolverParams) {
  const clients = payload.data;
  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) =>
        client.ID === clients.ID ? { ...client, ...clients } : client
      ),
    },
  });
}

function deleteClients({ prev, payload }: ClientsResolverParams) {
  const clients = payload.data;
  if (!prev.findUniqueGroup) return prev;
  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: [...prev.findUniqueGroup.clients].filter(
        (client) => client.ID !== clients.ID
      ),
    },
  });
}

function clientsResolver({ prev, payload }: ClientsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createClients({ prev, payload });

    case MutationType.Update:
      return updateClients({ prev, payload });

    case MutationType.Delete:
      return deleteClients({ prev, payload });

    default:
      return prev;
  }
}

export function clientsFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: clients,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ClientsSubscription } }
    ) =>
      clientsResolver({
        prev,
        payload: payload.subscriptionData.data.clients
      }),
    variables: query.variables,
  });
}
