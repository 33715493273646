import { QueryResult } from "@apollo/client";
import {
  VehiclesSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { vehicles } from "../subscription";

interface VehiclesResolverParams {
  prev: FindUniqueGroupQuery;
  payload: VehiclesSubscription["vehicles"];
}

function createVehicles({ prev, payload }: VehiclesResolverParams) {
  const { data: createVehicle, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            vehicles: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.vehicles, createVehicle]
              : client.assets.vehicles,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            vehicles: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.vehicles, createVehicle]
              : entity.assets.vehicles,
          },
        };
      }),
    },
  });
}

function updateVehicles({ prev, payload }: VehiclesResolverParams) {
  const { data: updateVehicle, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the vehicles object
   * This is because updateVehicle only returns the vehicles info
   * without assetallocation, notes, etc.
   */
  var updatedVehicle = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.vehicles.forEach((vehicles) => {
      if (vehicles.assets_ID === updateVehicle.assets_ID) {
        result = {
          ...vehicles,
          ...updateVehicle,
        };
      }
    });
    return result;
  }, updateVehicle);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update vehicles first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          vehicles: [
            ...client.assets.vehicles
              .map((vehicles) =>
                vehicles.ID === updatedVehicle.ID
                  ? { ...vehicles, ...updatedVehicle }
                  : vehicles
              )
              .filter(
                (vehicles) =>
                  ![...data.clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(vehicles.assets_ID)
              ),
            ...(data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? data.clients_has_assets.reduce<
                  Array<VehiclesSubscription["vehicles"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedVehicle.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedVehicle];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    vehicles: liability.assets.vehicles.map(
                      (vehicles) =>
                        vehicles.ID === updateVehicle.ID
                          ? {
                              ...vehicles,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateVehicle,
                            }
                          : vehicles /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            vehicles: goal.assets.vehicles.map((vehicles) =>
              vehicles.ID === updatedVehicle.ID
                ? { ...vehicles, ...updatedVehicle }
                : vehicles
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      vehicles: liability.assets.vehicles.map((vehicles) =>
                        vehicles.ID === updateVehicle.ID
                          ? {
                              ...vehicles,
                              /** Update loan info */
                              ...updateVehicle,
                            }
                          : vehicles
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing vehicles object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          vehicles: [
            ...entity.assets.vehicles
              .map((vehicles) =>
                vehicles.ID === updatedVehicle.ID
                  ? { ...vehicles, ...updatedVehicle }
                  : vehicles
              )
              .filter(
                (vehicles) =>
                  ![...data.entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(vehicles.assets_ID)
              ),
            ...(data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? data.entities_has_assets.reduce<
                  Array<VehiclesSubscription["vehicles"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedVehicle.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedVehicle];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    vehicles: liability.assets.vehicles.map(
                      (vehicles) =>
                        vehicles.ID === updateVehicle.ID
                          ? {
                              ...vehicles,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateVehicle,
                            }
                          : vehicles /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteVehicles({ prev, payload }: VehiclesResolverParams) {
  const { data: deleteVehicle } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            vehicles: [...client.assets.vehicles].filter(
              (vehicle) => vehicle.ID !== deleteVehicle.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      vehicles: liability.assets.vehicles.filter(
                        (vehicles) => vehicles.ID !== deleteVehicle.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              vehicles: goal.assets.vehicles.filter(
                (vehicles) => vehicles.ID !== deleteVehicle.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        vehicles: liability.assets.vehicles.filter(
                          (vehicles) => vehicles.ID !== deleteVehicle.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            vehicles: [...entity.assets.vehicles].filter(
              (vehicle) => vehicle.ID !== deleteVehicle.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      vehicles: liability.assets.vehicles.filter(
                        (vehicles) => vehicles.ID !== deleteVehicle.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function vehiclesResolver({ prev, payload }: VehiclesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createVehicles({ prev, payload });

    case MutationType.Update:
      return updateVehicles({ prev, payload });

    case MutationType.Delete:
      return deleteVehicles({ prev, payload });

    default:
      return prev;
  }
}

export function vehiclesFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: vehicles,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: VehiclesSubscription } }
    ) =>
      vehiclesResolver({
        prev,
        payload: payload.subscriptionData.data.vehicles
      }),
    variables: query.variables,
  });
}
