import { gql } from "@apollo/client";
import { scenarioliabilityFragment } from "./fragment";

export const createOneScenarioliability = gql`
  mutation CreateOneScenarioliability($data: scenarioliabilityCreateInput!) {
    createOneScenarioliability(data: $data) {
      ...scenarioliability
    }
  }
  ${scenarioliabilityFragment}
`;

export const updateOneScenarioliability = gql`
  mutation UpdateOneScenarioliability(
    $data: scenarioliabilityUpdateInput!
    $where: scenarioliabilityWhereUniqueInput!
  ) {
    updateOneScenarioliability(data: $data, where: $where) {
      ...scenarioliability
    }
  }
  ${scenarioliabilityFragment}
`;

export const deleteOneScenarioliability = gql`
  mutation DeleteOneScenarioliability(
    $where: scenarioliabilityWhereUniqueInput!
  ) {
    deleteOneScenarioliability(where: $where) {
      ...scenarioliability
    }
  }
  ${scenarioliabilityFragment}
`;
