import { gql } from "@apollo/client";
import { contenttemplateFragment } from ".";

export const createOneContenttemplate = gql`
  mutation CreateOneContenttemplate($data: contenttemplateCreateInput!) {
    createOneContenttemplate(data: $data) {
      ...contenttemplate
    }
  }
  ${contenttemplateFragment}
`;

export const updateOneContenttemplate = gql`
  mutation UpdateOneContenttemplate(
    $data: contenttemplateUpdateInput!
    $where: contenttemplateWhereUniqueInput!
  ) {
    updateOneContenttemplate(data: $data, where: $where) {
      ...contenttemplate
    }
  }
  ${contenttemplateFragment}
`;

export const deleteOneContenttemplate = gql`
  mutation DeleteOneContenttemplate($where: contenttemplateWhereUniqueInput!) {
    deleteOneContenttemplate(where: $where) {
      ...contenttemplate
    }
  }
  ${contenttemplateFragment}
`;
