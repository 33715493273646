import { gql } from "@apollo/client";
import { sharesFragment } from ".";

/** Shares */
export const createOneShares = gql`
  mutation CreateOneShares($data: sharesCreateInput!) {
    createOneShares(data: $data) {
      ...shares
    }
  }
  ${sharesFragment}
`;

export const updateOneShares = gql`
  mutation UpdateOneShares(
    $data: sharesUpdateInput!
    $where: sharesWhereUniqueInput!
  ) {
    updateOneShares(data: $data, where: $where) {
      ...shares
    }
  }
  ${sharesFragment}
`;

export const deleteOneShares = gql`
  mutation DeleteOneShares($where: sharesWhereUniqueInput!) {
    deleteOneShares(where: $where) {
      ...shares
    }
  }
  ${sharesFragment}
`;
