import React from "react";
import {
  Theme,
  useFindUniqueFeesettingsQuery,
} from "../../../../../../../../../../codegen/schema";
import { checkFieldIsEmpty } from "../../../../../../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../../../../../../../../Components/Advice/StyledText";
import { ComponentLoader } from "../../../../../../../../../../Components/Loaders/ComponentLoader";
import { Refetch } from "../../../../../../../../../../Components/Refetch";

interface AdviceAgreementServicesProps {
  theme?: Theme;
}

/**
 * @created 01-02-2023
 * @updated 25-05-2023
 * @returns Returns advice agreement services component for advice template && document.
 */
export const AdviceAgreementServicesSummary: React.FC<
  AdviceAgreementServicesProps
> = ({ theme }) => {
  const {
    data: { findUniqueFeesettings: feesettings } = {},
    error: feesettingsError,
    loading: feesettingsLoading,
    refetch: feesettingsRefetch,
  } = useFindUniqueFeesettingsQuery();

  var servicetypes = feesettings?.servicetypes
    .map((service) => service.Name)
    .filter((value, index, self) => self.indexOf(value) === index);

  if (!feesettings && feesettingsLoading) {
    return <ComponentLoader />;
  }

  if (feesettingsError && !feesettingsLoading) {
    return (
      <Refetch
        refetch={() => {
          feesettingsRefetch();
        }}
      />
    );
  }

  if (!servicetypes) return null;

  return (
    <React.Fragment>
      <div className="w-full">
        {servicetypes.map((servicetype, index) => {
          if (index < 1 || servicetype === "Required Services") return null;
          return (
            <div
              className="grid grid-cols-[80%,20%]"
              key={index}
              style={{
                borderBottom:
                  "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
              }}
            >
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H5"
                )}
                
              >
                {servicetype}
              </StyledText>
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H3"
                )}
                className="flex items-center justify-center"
              >
                <i
                  className="icon fa-solid fa-check text-base"
                  style={{
                    color: theme?.colour[0]?.Value ?? "#000000",
                  }}
                />
              </StyledText>
            </div>
          );
        })}

        <div className="grid grid-cols-[80%,20%]">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H2")}
          >
            Total (Incl. GST)
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H2")}
            className="flex justify-center"
          >
            {checkFieldIsEmpty(5000, "dollar")}
          </StyledText>
        </div>
      </div>
    </React.Fragment>
  );
};
