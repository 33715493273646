import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../../../../../Datastore/Store";
import { LiveData, ScenarioData } from "../../../Calculations";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  scenarioData: ScenarioData[] | undefined;
  liveData: LiveData[] | undefined;
}

export const TrustPlanningScenarioModelGraph: React.FC<Props> = ({
  scenarioData,
  liveData,
}) => {
  const [context] = useContext(StoreContext);

  const [data, setData] = useState<ScenarioData[]>([]);

  useEffect(() => {
    /* Need to combine the live and scenario data arrays. Live data will have liveProperties, liveLoans and year values.
    /* These need to be combinded with the scenario data array. 
    /* Check if duplicate years exist, if so add liveData object to scenarioData entry, else, create a new entry
    /* where scenario data values are 0 or undefined. */
    if (scenarioData && liveData) {
      var combinedData = [...scenarioData];

      liveData.forEach((liveEntry) => {
        var prevEntry = combinedData.find(
          (entry) => entry.year === liveEntry.year
        );

        if (prevEntry) {
          combinedData = combinedData.map((entry) =>
            entry.year === liveEntry.year
              ? {
                  ...entry,
                  historicalSharesValue: liveEntry.historicalShareValue,
                }
              : entry
          );
        } else {
          combinedData.push({
            ...liveEntry,
            capital: 0,
            contributions: 0,
            fees: 0,
            growth: 0,
            target: undefined,
            withdrawals: 0,
          });
        }
      });
      setData(combinedData.sort((a, b) => a.year - b.year));
    }
  }, [scenarioData, liveData]);

  if (!scenarioData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />
        <XAxis
          interval={data.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            offset={-30}
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />
        <ReferenceLine y={0} stroke="#000" />

        <Bar
          type="monotone"
          dataKey={"capital"}
          fill={context.colours.blue200}
          isAnimationActive={false}
          stackId={1}
        />

        <Bar
          type="monotone"
          dataKey={"contributions"}
          fill={context.colours.contributions}
          isAnimationActive={false}
          stackId={1}
        />

        <Bar
          type="monotone"
          dataKey={"withdrawals"}
          fill={context.colours.withdrawals}
          isAnimationActive={false}
          stackId={1}
        />

        <Bar
          type="monotone"
          dataKey={"fees"}
          fill={context.colours.purple300}
          isAnimationActive={false}
          stackId={1}
        />

        <Bar
          type="monotone"
          dataKey={"growth"}
          fill={context.colours.lightPurple300}
          isAnimationActive={false}
          stackId={1}
        />

        <Line
          type="monotone"
          dataKey={"target"}
          stroke={context.colours.target}
          isAnimationActive={false}
          dot={{
            strokeWidth: 1,
            r: 4,
          }}
        />

        {liveData && liveData.length > 0 && (
          <Line
            type="monotone"
            dataKey={"historicalShareValue"}
            stroke={context.colours.lightPurple300}
            isAnimationActive={false}
            strokeWidth={2}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
