import { QueryResult } from "@apollo/client";
import { FindManyGroups } from "../";
import { FindManyGroupsQuery, FindManyGroupsQueryVariables } from "../../../codegen/schema";

export function findManyGroupsSubscription(query: Pick<
  QueryResult<
    FindManyGroupsQuery,
    FindManyGroupsQueryVariables
  >,
  "subscribeToMore" | "variables"
>) {
  FindManyGroups(query);
}
