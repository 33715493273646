import { QueryResult } from "@apollo/client";
import {
  TagSubscription,
  FindUniqueTagQuery,
  MutationType,
  FindUniqueTagQueryVariables,
} from "../../../codegen/schema";
import { tag } from "../subscription";

interface TagResolverParams {
  prev: FindUniqueTagQuery;
  payload: TagSubscription["tag"];
}

function updateTag({ prev, payload }: TagResolverParams) {
  const tag = payload.data;

  if (!prev.findUniqueTag) return prev;
  return Object.assign({}, prev, {
    findUniqueTag:
      tag.ID === prev.findUniqueTag.ID
        ? {
            ...prev.findUniqueTag,
            ...tag,
          }
        : prev.findUniqueTag,
  });
}

function deleteTag({ prev, payload }: TagResolverParams) {
  const tag = payload.data;

  if (!prev.findUniqueTag) return prev;
  return Object.assign({}, prev, {
    findUniqueTag:
      prev.findUniqueTag.ID === tag.ID
        ? {
            ...prev.findUniqueTag,
            undefined,
          }
        : prev.findUniqueTag,
  });
}

function tagResolver({ prev, payload }: TagResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createTag(prev, payload);

    case MutationType.Update:
      return updateTag({ prev, payload });

    case MutationType.Delete:
      return deleteTag({ prev, payload });

    default:
      return prev;
  }
}

export function tagFindUniqueTag(
  query: Pick<
    QueryResult<FindUniqueTagQuery, FindUniqueTagQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: tag,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: TagSubscription } }
    ) =>
      tagResolver({
        prev,
        payload: payload.subscriptionData.data.tag,
      }),
    variables: query.variables,
  });
}
