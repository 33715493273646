import { gql } from "@apollo/client";
import { assetsFragment } from "../assets";
import { liabilitiesFragment } from "../liabilities/fragment";
import { notesFragment } from "../notes";

export const entitiesCoreFragment = gql`
  fragment entitiesCore on entities {
    ID
    groups_ID
    groups_organisation_ID
    Name
    Created
    Updated
    LastContacted
    PrimaryEmail
    SecondaryEmail
    MobilePhone
    OfficePhone
    PostalAddressStreet
    PostalCity
    PostalState
    PostalPostcode
    PostalCountry
    RetainerFeeType
    RetainerPaymentMethod
    BASFrequency
    ABN
    ACN
    RegisteredForGST
    Type
    TFN
    ArchivedDate
    Archived

    RegisteredOffice
    ROAddress1
    ROAddress2
    ROSuburb
    ROPostcode
    ROState
    ROCountry
    Description
    RelatedFee
    ManagedByOrganisation
    Value
    Members
    Directors
    Fax
    MemberBalances
    PensionPayments
    create_time
    created_by
    update_time
    updated_by
    ASICKey
  }
`;

export const entitiesFragment = gql`
  fragment entities on entities {
    ...entitiesCore

    assets {
      ...assets
    }
    liabilities {
      ...liabilities
    }
    notes {
      ...notes
    }
  }
  ${entitiesCoreFragment}
  ${assetsFragment}
  ${liabilitiesFragment}
  ${notesFragment}
`;
