import { QueryResult } from "@apollo/client";
import {
  ScenarioclientSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { scenarioclient } from "../subscription";

interface ScenarioclientResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ScenarioclientSubscription["scenarioclient"];
}

function createScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioclient.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioclient.scenario_ID
                  ? {
                      ...scenario,
                      scenarioclient: [
                        ...scenario.scenarioclient,
                        scenarioclient,
                      ],
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function updateScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioclient.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioclient.scenario_ID
                  ? {
                      ...scenario,
                      scenarioclient: scenario.scenarioclient.map(
                        (scenarioclients) =>
                          scenarioclients.ID === scenarioclient.ID
                            ? { ...scenarioclients, ...scenarioclient }
                            : scenarioclients
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function deleteScenarioclient({ prev, payload }: ScenarioclientResolverParams) {
  const scenarioclient = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      advice: prev.findUniqueGroup.advice.map((advice) =>
        advice.ID === scenarioclient.scenario_advice_ID
          ? {
              ...advice,
              scenario: advice.scenario.map((scenario) =>
                scenario.ID === scenarioclient.scenario_ID
                  ? {
                      ...scenario,
                      scenarioclient: scenario.scenarioclient.filter(
                        (scenarioclients) =>
                          scenarioclients.ID !== scenarioclient.ID
                      ),
                    }
                  : scenario
              ),
            }
          : advice
      ),
    },
  });
}

function scenarioclientResolver({
  prev,
  payload,
}: ScenarioclientResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createScenarioclient({ prev, payload });

    case MutationType.Update:
      return updateScenarioclient({ prev, payload });

    case MutationType.Delete:
      return deleteScenarioclient({ prev, payload });

    default:
      return prev;
  }
}

export function scenarioclientFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: scenarioclient,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: ScenarioclientSubscription } }
    ) =>
      scenarioclientResolver({
        prev,
        payload: payload.subscriptionData.data.scenarioclient,
      }),
    variables: query.variables,
  });
}
