import React from "react";
import { InsuranceIncomeProtectionComparisonModelGraph } from "../Graph";
import { InsuranceIncomeProtectionComparisonDataReturn } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface InsuranceIncomeProtectionPlaceholderModelProps {
  theme?: Theme;
}

var data: InsuranceIncomeProtectionComparisonDataReturn[] = [
  {
    scenarioDataKey: "Scenario 1",
    [`John Smith Cover Adjustment`]: 70000,
    [`John Smith Existing Cover`]: 50000,
    [`Jane Smith Cover Adjustment`]: 50000,
    [`Jane Smith Existing Cover`]: 30000,
  },
  {
    scenarioDataKey: "Scenario 2",
    [`John Smith Cover Adjustment`]: 30000,
    [`John Smith Existing Cover`]: 60000,
    [`Jane Smith Cover Adjustment`]: 60000,
    [`Jane Smith Existing Cover`]: 20000,
  },
];

export const InsuranceIncomeProtectionComparisonPlaceholderModel = ({
  theme,
}: InsuranceIncomeProtectionPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Income Protection insurance
        </StyledText>

        <ModelTooltip type={Model_Type.InsuranceIncomeProtection} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InsuranceIncomeProtectionComparisonModelGraph comparisonData={data} />
      </div>
    </React.Fragment>
  );
};
