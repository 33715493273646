import React from "react";
import { InsuranceTraumaComparisonModelGraph } from "../Graph";
import { InsuranceTraumaComparisonDataReturn } from "../../../Calculations";
import { ModelTooltip } from "../../../../Utils/Tooltip";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";

interface InsuranceTraumaPlaceholderModelProps {
  theme?: Theme;
}

var data: InsuranceTraumaComparisonDataReturn[] = [
  {
    scenarioDataKey: "Scenario 1",
    [`John Smith Existing Cover`]: 274933,
    [`John Smith Cover Adjustment`]: -24933,
    [`Jane Smith Existing Cover`]: 303114,
    [`Jane Smith Cover Adjustment`]: -53114,
  },
  {
    scenarioDataKey: "Scenario 2",
    [`John Smith Existing Cover`]: 321216,
    [`John Smith Cover Adjustment`]: -41216,
    [`Jane Smith Existing Cover`]: 303114,
    [`Jane Smith Cover Adjustment`]: -23114,
  },
];

export const InsuranceTraumaComparisonPlaceholderModel = ({
  theme,
}: InsuranceTraumaPlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Trauma insurance
        </StyledText>

        <ModelTooltip type={Model_Type.InsuranceTrauma} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <InsuranceTraumaComparisonModelGraph comparisonData={data} />
      </div>
    </React.Fragment>
  );
};
