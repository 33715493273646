import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FindUniqueDocumenttemplateQuery,
  FindUniqueOrganisationQuery,
  FindUniqueSectiontemplateQuery,
  Theme,
  useFindUniquePagetemplateQuery,
} from "../../../../../../codegen/schema";

import { Editor } from "slate";
import { useInView } from "react-intersection-observer";
import { Refetch } from "../../../../../../Components/Refetch";
import { PageContent } from "./PageContent";
import { PageHeader } from "../PageHeader";
import { PageFooter } from "../PageFooter";
import { SlateSelected } from "../../../../../../Components/Slate/Types";
import { WebsocketProvider } from "../../../../../../Components/Slate/Yjs";
import { findUniquePagetemplateSubscription } from "../../../../../../API/pagetemplate/subscriptions";
import { ComponentLoader } from "../../../../../../Components/Loaders/ComponentLoader";

interface PageTemplateProps {
  organisation: FindUniqueOrganisationQuery["findUniqueOrganisation"];
  documenttemplate: FindUniqueDocumenttemplateQuery["findUniqueDocumenttemplate"];
  sectiontemplate: FindUniqueSectiontemplateQuery["findUniqueSectiontemplate"];
  theme?: Theme;
  pagetemplate_ID: number;
  activeEditor: Editor | undefined;
  setActiveEditor: React.Dispatch<React.SetStateAction<Editor | undefined>>;
  selected?: SlateSelected;
  setSelected: React.Dispatch<React.SetStateAction<SlateSelected | undefined>>;
  yjsProvider: WebsocketProvider;
}

export const PageTemplate: React.FC<PageTemplateProps> = ({
  organisation,
  documenttemplate,
  sectiontemplate,
  theme,
  pagetemplate_ID,
  activeEditor,
  setActiveEditor,
  selected,
  setSelected,
  yjsProvider,
}) => {
  const navigate = useNavigate();
  /* page ID is saved in url - how to access varible. 
  Cannot useParams as it is Query Parameter not a Path segment
  const [searchParams] = useSearchParams();
  const pageidFromURL = searchParams.get("page"); */

  const {
    data: { findUniquePagetemplate: pagetemplate } = {},
    error: pageError,
    loading: pageLoading,
    refetch: pageRefetch,
    ...pageResult
  } = useFindUniquePagetemplateQuery({
    fetchPolicy: "cache-and-network",
    skip: !pagetemplate_ID,
    variables: {
      where: {
        ID: pagetemplate_ID,
      },
    },
  });

  useEffect(() => {
    findUniquePagetemplateSubscription(pageResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ref, inView, entry } = useInView({
    rootMargin: "0px 0px",
    threshold: 0.5,
  });

  useEffect(() => {
    if (entry) {
      var id = entry.target.id.replaceAll("page ", "");

      if (entry.isIntersecting) {
        setSelected({
          ...selected,
          pageID: parseInt(id),
          contenttemplate: undefined,
        });

        // Update URL to include page_ID
        navigate(`?page=${id}`, { replace: true });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, inView]);

  /* Reset editor function for scroll event */
  function resetEditor() {
    activeEditor && activeEditor.deselect();
    setActiveEditor(undefined);
  }

  /* Window event listener to check for scroll, on scroll reset the editor */
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", resetEditor);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", resetEditor);
    };
  });

  if (!pagetemplate && pageLoading) {
    return <ComponentLoader />;
  }

  if (pageError && !pageLoading) {
    return (
      <Refetch
        refetch={() => {
          pageRefetch();
        }}
      />
    );
  }

  if (!pagetemplate || !documenttemplate || !sectiontemplate) return null;

  return (
    <React.Fragment>
      <div
        className={"page mb-4 " + documenttemplate.Orientation}
        ref={ref}
        id={"page " + pagetemplate.ID.toString()}
      >
        <div
          className="flex "
          style={{ padding: "0 1cm", height: "2.5cm", marginBottom: "0.5cm" }}
        >
          {pagetemplate.PageNumber ? (
            <PageHeader
              documenttemplate={documenttemplate}
              sectiontemplate={sectiontemplate}
              theme={theme}
              pagetemplate={pagetemplate}
            />
          ) : (
            ""
          )}
        </div>

        <div style={{ margin: "0 1cm" }}>
          <div
            // ID needed for content overflow restrictions
            id={`page-content ${pagetemplate.ID}`}
            // onClick={() => {
            //   setSelected({
            //     ...selected,
            //     pageID: pagetemplate.ID,
            //     contenttemplate: undefined,
            //   });
            //   setActiveEditor(undefined);
            // }}
            style={{
              display: "grid",
              height: "23.7cm",
              gridTemplateColumns: "repeat(2, 9.25cm)",
              columnGap: "0.5cm"
            }}
          >
            <PageContent
              organisation={organisation}
              documenttemplate={documenttemplate}
              theme={theme}
              pagetemplate_ID={pagetemplate.ID}
              activeEditor={activeEditor}
              setActiveEditor={setActiveEditor}
              selected={selected}
              setSelected={setSelected}
              yjsProvider={yjsProvider}
            />
          </div>
        </div>

        <div
          className="flex items-center"
          style={{ padding: "0 1cm", height: "2.5cm", marginTop: "0.5cm" }}
        >
          {pagetemplate.Footer ? (
            <PageFooter
              organisation={organisation}
              documenttemplate={documenttemplate}
              theme={theme}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
