import { gql } from "@apollo/client";
import { annuityCoreFragment, annuityFragment } from "./fragment";

export const annuity = gql`
  subscription Annuity($where: annuityWhereUniqueInput) {
    annuity(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...annuity
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${annuityFragment}
`;

export const annuityMany = gql`
  subscription AnnuityMany($where: annuityWhereInput) {
    annuityMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...annuity
      }
      clients_has_assets {
        clients_ID
        assets_ID
      }
      entities_has_assets {
        entities_ID
        assets_ID
      }
    }
  }
  ${annuityCoreFragment}
`;
