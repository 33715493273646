import { QueryResult } from "@apollo/client";
import {
  FindUniqueOpportunityQuery,
  FindUniqueOpportunityQueryVariables,
  MutationType,
  NotesSubscription,
} from "../../../codegen/schema";
import { notes } from "../subscription";

interface NotesResolverParams {
  prev: FindUniqueOpportunityQuery;
  payload: NotesSubscription["notes"];
}

function createNote({ prev, payload }: NotesResolverParams) {
  const { data: notes, opportunity_has_notes } = payload;

  if (!prev.findUniqueOpportunity) return prev;
  return Object.assign({}, prev, {
    findUniqueOpportunity: {
      ...prev.findUniqueOpportunity,
      notes: opportunity_has_notes.some(
        (relation) => relation.opportunity_ID === prev.findUniqueOpportunity?.ID
      )
        ? [...prev.findUniqueOpportunity.notes, notes]
        : prev.findUniqueOpportunity.notes,
    },
  });
}

function updateNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueOpportunity) return prev;
  return Object.assign({}, prev, {
    findUniqueOpportunity: {
      ...prev.findUniqueOpportunity,
      notes: prev.findUniqueOpportunity.notes.map((note) =>
        note.ID === notes.ID ? { ...note, ...notes } : note
      ),
    },
  });
}

function deleteNote({ prev, payload }: NotesResolverParams) {
  const notes = payload.data;

  if (!prev.findUniqueOpportunity) return prev;
  return Object.assign({}, prev, {
    findUniqueOpportunity: {
      ...prev.findUniqueOpportunity,
      notes: prev.findUniqueOpportunity.notes.filter(
        (note) => note.ID !== notes.ID
      ),
    },
  });
}

function notesResolver({ prev, payload }: NotesResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createNote({ prev, payload });

    case MutationType.Update:
      return updateNote({ prev, payload });

    case MutationType.Delete:
      return deleteNote({ prev, payload });

    default:
      return prev;
  }
}

export function notesFindUniqueOpportunity(
  query: Pick<
    QueryResult<
      FindUniqueOpportunityQuery,
      FindUniqueOpportunityQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: notes,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: NotesSubscription } }
    ) =>
      notesResolver({
        prev,
        payload: payload.subscriptionData.data.notes
      }),
    variables: query.variables,
  });
}
