import { QueryResult } from "@apollo/client";
import {
  CashSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { cash } from "../subscription";

interface CashResolverParams {
  prev: FindUniqueGroupQuery;
  payload: CashSubscription["cash"];
}

function createCash({ prev, payload }: CashResolverParams) {
  const { data: createCash, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            cash: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.cash, createCash]
              : client.assets.cash,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            cash: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.cash, createCash]
              : entity.assets.cash,
          },
        };
      }),
    },
  });
}

function updateCash({ prev, payload }: CashResolverParams) {
  const { data: updateCash, clients_has_assets, entities_has_assets } = payload;
  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the cash object
   * This is because updateCash only returns the cash info
   * without assetallocation, notes, etc.
   */
  var updatedCash = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.cash.forEach((entry) => {
      if (entry.assets_ID === updateCash.assets_ID) {
        result = {
          ...entry,
          ...updateCash,
        };
      }
    });
    return result;
  }, updateCash);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update cash first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          cash: [
            ...client.assets.cash
              .map((cash) =>
                cash.ID === updatedCash.ID ? { ...cash, ...updatedCash } : cash
              )
              .filter(
                (cash) =>
                  ![...clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(cash.assets_ID)
              ),
            ...(clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? clients_has_assets.reduce<
                  Array<CashSubscription["cash"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedCash.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedCash];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    cash: liability.assets.cash.map(
                      (cash) =>
                        cash.ID === updateCash.ID
                          ? {
                              ...cash,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateCash,
                            }
                          : cash /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            cash: goal.assets.cash.map((cash) =>
              cash.ID === updatedCash.ID ? { ...cash, ...updatedCash } : cash
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      cash: liability.assets.cash.map((cash) =>
                        cash.ID === updateCash.ID
                          ? {
                              ...cash,
                              /** Update loan info */
                              ...updateCash,
                            }
                          : cash
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing cash object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          cash: [
            ...entity.assets.cash
              .map((cash) =>
                cash.ID === updatedCash.ID ? { ...cash, ...updatedCash } : cash
              )
              .filter(
                (cash) =>
                  ![...entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(cash.assets_ID)
              ),
            ...(entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? entities_has_assets.reduce<
                  Array<CashSubscription["cash"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedCash.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedCash];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    cash: liability.assets.cash.map(
                      (cash) =>
                        cash.ID === updateCash.ID
                          ? {
                              ...cash,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateCash,
                            }
                          : cash /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteCash({ prev, payload }: CashResolverParams) {
  const { data: deleteCash } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            cash: [...client.assets.cash].filter(
              (entry) => entry.ID !== deleteCash.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      cash: liability.assets.cash.filter(
                        (cash) => cash.ID !== deleteCash.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              cash: goal.assets.cash.filter(
                (entry) => entry.ID !== deleteCash.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        cash: liability.assets.cash.filter(
                          (cash) => cash.ID !== deleteCash.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            cash: [...entity.assets.cash].filter(
              (entry) => entry.ID !== deleteCash.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      cash: liability.assets.cash.filter(
                        (cash) => cash.ID !== deleteCash.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function cashResolver({ prev, payload }: CashResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createCash({ prev, payload });

    case MutationType.Update:
      return updateCash({ prev, payload });

    case MutationType.Delete:
      return deleteCash({ prev, payload });

    default:
      return prev;
  }
}

export function cashFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: cash,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: CashSubscription } }
    ) =>
      cashResolver({
        prev,
        payload: payload.subscriptionData.data.cash,
      }),
    variables: query.variables,
  });
}
