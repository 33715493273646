import React, { useContext } from "react";

import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { StoreContext } from "../../../../../../../Datastore/Store";
import { ComparisonData } from "../../../Calculations";
import {
  RechartsCustomRenderLegend,
  RechartsCustomRenderTooltip,
} from "../../../../Utils/Recharts";

interface Props {
  comparisonData: ComparisonData[] | undefined;
}

export const RetirementPlanningComparisonModelGraph: React.FC<Props> = ({
  comparisonData,
}) => {
  const [context] = useContext(StoreContext);

  if (!comparisonData) {
    return <p> check calculation</p>;
  }

  return (
    <ResponsiveContainer width={"100%"} height={320} maxHeight={320}>
      <LineChart
        data={comparisonData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke={context.colours.purple100} strokeDasharray="4" />

        <XAxis
          interval={comparisonData.length > 12 ? 4 : 0}
          tickLine={false}
          dataKey="year"
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
        >
          <Label
            value="Years"
            position="center"
            dy={20}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </XAxis>

        <YAxis
          tickLine={false}
          stroke={context.colours.purple300}
          style={{
            fontSize: "0.7rem",
            fontFamily: context.fonts.secondaryFont,
            fontWeight: 700,
          }}
          tickFormatter={(tick) => {
            return parseFloat(tick).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
          }}
        >
          <Label
            value="Dollar value"
            position="center"
            angle={-90}
            dx={-50}
            style={{
              fontSize: "0.7rem",
              fontFamily: context.fonts.secondaryFont,
              fontWeight: 700,
              fill: context.colours.purple300,
              textTransform: "uppercase",
            }}
          />
        </YAxis>
        <Tooltip
          content={RechartsCustomRenderTooltip}
          wrapperStyle={{ outline: "none" }}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          content={RechartsCustomRenderLegend}
          wrapperStyle={{ bottom: -10 }}
        />

        <ReferenceLine y={0} stroke="#000" />

        {[
          ...new Set(
            comparisonData.flatMap((entry) =>
              Object.keys(entry).filter(
                (value) => value !== "year" && !value.includes("target")
              )
            )
          ),
        ].map((key, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={key}
            stroke={context.colours[Object.keys(context.colours)[index]]}
            strokeWidth={2}
            dot={false}
            isAnimationActive={false}
          />
        ))}

        {[
          ...new Set(
            comparisonData.flatMap((entry) =>
              Object.keys(entry).filter((value) => value.includes("target"))
            )
          ),
        ].map((key, index) => (
          <Line
            key={key + index}
            type="monotone"
            dataKey={key}
            stroke={context.colours[Object.keys(context.colours)[index]]}
            isAnimationActive={false}
            dot={{
              strokeWidth: 1,
              r: 4,
            }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
