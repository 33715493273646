import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  FindUniqueAdviceQuery,
  Theme,
} from "../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupLiabilities,
} from "../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../StyledText";

interface LiabilitiesLoanProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"] | undefined;
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];

}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns loan component for advice template && document.
 */
export const LiabilitiesLoan: React.FC<LiabilitiesLoanProps> = ({
  content,
  contenttemplate,
  theme,
  group,
  advice
}) => {
  type Loan = {
    liabilities_ID: number | null;
    LoanType: string;
    Purpose: string;
    CurrentLoanOutstanding: string;
  };

  const summaryLoan: Loan[] = content
    ? content?.ContentType === "Grouped"
      ? getGroupLiabilities(group).loans
      : content?.liability_ID
      ? getGroupLiabilities(group).loans.find(
          (loan: Loan) => content?.liability_ID === loan.liabilities_ID
        )
      : {}
    : [
        {
          LoanType: "Home",
          Purpose: "Offset",
          CurrentLoanOutstanding: "$420,000",
        },
        {
          LoanType: "Investment",
          Purpose: "Business loan",
          CurrentLoanOutstanding: "$200,000",
        },
      ];

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryLoan.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Type
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Purpose
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            
          >
            Outstanding
          </StyledText>
        </div>
      ) : (
        <h5>No loans recorded</h5>
      )}
      {summaryLoan.map((loan, objectIndex) => {
        return (
          <div
           className="grid grid-cols-3 gap-2 mt-2"
            key={objectIndex}
          >
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(loan.LoanType)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(loan.Purpose)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              {checkFieldIsEmpty(loan.CurrentLoanOutstanding, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>Individual Loan view</React.Fragment>
  );
};
