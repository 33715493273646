import { QueryResult } from "@apollo/client";
import {
  EmploymentManySubscription,
  FindManyEmploymentQuery,
  FindManyEmploymentQueryVariables,
  MutationType,
} from "../../../codegen/schema";
import { employmentMany } from "../subscription";

interface EmploymentResolverParams {
  prev: FindManyEmploymentQuery;
  payload: EmploymentManySubscription["employmentMany"];
}

function createEmployment({ prev, payload }: EmploymentResolverParams) {
  const employment = payload.data;

  return Object.assign({}, prev, {
    findManyEmployment: [...prev.findManyEmployment, employment],
  });
}

function updateEmployment({ prev, payload }: EmploymentResolverParams) {
  const employment = payload.data;

  return Object.assign({}, prev, {
    findManyEmployment: prev.findManyEmployment.map((packaging) =>
      packaging.ID === employment.ID
        ? { ...packaging, ...employment }
        : packaging
    ),
  });
}

function deleteEmployment({ prev, payload }: EmploymentResolverParams) {
  const employment = payload.data;

  return Object.assign({}, prev, {
    findManyEmployment: [...prev.findManyEmployment].filter(
      (packaging) => packaging.ID !== employment.ID
    ),
  });
}

function employmentResolver({ prev, payload }: EmploymentResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createEmployment({ prev, payload });

    case MutationType.Update:
      return prev.findManyEmployment.some(
        (employment) => employment.ID === data.ID
      )
        ? updateEmployment({ prev, payload })
        : createEmployment({ prev, payload });

    case MutationType.Delete:
      return deleteEmployment({ prev, payload });

    default:
      return prev;
  }
}
export function FindManyEmployment(
  query: Pick<
    QueryResult<FindManyEmploymentQuery, FindManyEmploymentQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: employmentMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: EmploymentManySubscription } }
    ) =>
      employmentResolver({
        prev,
        payload: payload.subscriptionData.data.employmentMany,
      }),
    variables: query.variables,
  });
}
