import { gql } from "@apollo/client";
import { fileFragment } from "./fragment";

export const file = gql`
  subscription File($filePath: String!) {
    file(filePath: $filePath) {
      mutationType
      organisation {
        ID
      }
      data {
        ...file
      }
    }
  }
  ${fileFragment}
`;
