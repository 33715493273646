import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../../codegen/schema";

import { getGroupAssets } from "../../../../../../Functions/GeneralFunctions";

interface AssetsOffsetProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns offset component for advice template && document.
 */
export const AssetsOffsetIndividual: React.FC<AssetsOffsetProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  type Offset = {
    assets_ID: number | null;
    Bank: string;
    Owner: string;
    Amount: string;
  };

  const summaryOffset: Offset = group
    ? content?.asset_ID
      ? getGroupAssets(group).offset.find(
          (offset: Offset) => content?.asset_ID === offset.assets_ID
        )
      : {}
    : { Bank: "Westpac", Owner: "Joint", Amount: "$200,000" };

  return (
    <React.Fragment>
      <div>Individual offset view {summaryOffset.Amount}</div>
    </React.Fragment>
  );
};
