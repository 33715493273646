import { gql } from "@apollo/client";
import { contributionswithdrawalsFragment } from "./fragment";

export const contributionswithdrawals = gql`
  subscription Contributionswithdrawals(
    $where: contributionswithdrawalsWhereUniqueInput
  ) {
    contributionswithdrawals(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...contributionswithdrawals
      }
    }
  }
  ${contributionswithdrawalsFragment}
`;

export const contributionswithdrawalsMany = gql`
  subscription ContributionswithdrawalsMany(
    $where: contributionswithdrawalsWhereInput
  ) {
    contributionswithdrawalsMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...contributionswithdrawals
      }
    }
  }
  ${contributionswithdrawalsFragment}
`;
