import React from "react";
import {
  Theme,
  useFindUniqueFeesettingsQuery,
} from "../../../../../../../../../../codegen/schema";
import { ComponentLoader } from "../../../../../../../../../../Components/Loaders/ComponentLoader";
import { StyledText } from "../../../../../../../../../../Components/Advice/StyledText";
import { checkFieldIsEmpty } from "../../../../../../../../../../Functions/GeneralFunctions";
import { Refetch } from "../../../../../../../../../../Components/Refetch";

interface AdviceAgreementServiceCostProps {
  theme?: Theme;
}

/**
 * @created 01-02-2023
 * @updated 25-05-2023
 * @returns Returns advice agreement services component for advice template && document.
 */
export const AdviceAgreementServiceCost: React.FC<
  AdviceAgreementServiceCostProps
> = ({ theme }) => {
  const {
    data: { findUniqueFeesettings: feesettings } = {},
    error: feesettingsError,
    loading: feesettingsLoading,
    refetch: feesettingsRefetch,
  } = useFindUniqueFeesettingsQuery();

  if (!feesettings && feesettingsLoading) {
    return <ComponentLoader />;
  }

  if (feesettingsError && !feesettingsLoading) {
    return (
      <Refetch
        refetch={() => {
          feesettingsRefetch();
        }}
      />
    );
  }

  if (!feesettings) return null;

  return (
    <React.Fragment>
      <div
        className="grid gap-2 grid-cols-2 rounded-lg w-full"
        style={{
          padding: "1cm 5%",
          border: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
        }}
      >
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          
        >
          Total Combined Cost
        </StyledText>

        <StyledText
          element={theme?.element.find((element) => element.Type === "H2")}
          className="justify-self-end"
        >
          {checkFieldIsEmpty(5000, "dollar")}
        </StyledText>

        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
          className="col-start-1 col-span-full"
          style={{
            borderBottom: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
          }}
        >
          Cost allocated to
        </StyledText>
        <div className="col-start-1 col-span-full" style={{ padding: "0.5cm 0" }}>
          <div className="grid gap-1 grid-cols-2 items-center ml-1">
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
              
            >
              John Smith
            </StyledText>

            <StyledText
              element={theme?.element.find((element) => element.Type === "H6")}
              className="justify-self-end"
            >
              $5,000.00
            </StyledText>
          </div>
        </div>
        <div
          className="col-start-1 col-span-full grid gap-1 grid-cols-2"
          style={{
            borderTop: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
            borderBottom: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
          }}
        >
          <StyledText
            element={theme?.element.find((element) => element.Type === "H5")}
            
          >
            Total
          </StyledText>

          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
            className="font-extrabold justify-self-end"
          >
            $5,000.00
          </StyledText>
        </div>
      </div>
    </React.Fragment>
  );
};
