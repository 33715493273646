import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";

import { useLocation, useNavigate } from "react-router-dom";
import { navigationOption } from "..";
import clsx from "clsx";

interface SidebarFullWidthProps {
  navigation: navigationOption[];
}

export const SidebarFull: React.FC<SidebarFullWidthProps> = ({
  navigation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const RenderNavItem = ({
    item,
    current,
    open,
    close,
  }: {
    item: navigationOption;
    current: boolean;
    open?: boolean;
    close: (
      focusableElement?:
        | HTMLElement
        | React.MutableRefObject<HTMLElement | null>
        | undefined
    ) => void;
  }) => {
    return (
      <li
        key={item.name}
        onClick={() => {
          navigate(item.href);
          if (!location.pathname.includes(item.href)) close();
        }}
      >
        <div
          className={clsx(
            current
              ? "bg-neutral-50 text-blue-500"
              : "hover:bg-neutral-50 text-neutral-600",
            "group flex gap-x-3 items-center rounded-md p-3 text-sm font-body leading-6 font-semibold cursor-pointer hover:text-blue-500"
          )}
        >
          <i
            className={clsx(
              current ? " text-blue-500" : "text-neutral-400",
              item?.icon ? item.icon : "",
              "fa-regular shrink-0 fa-xl group-hover:text-blue-500"
            )}
            aria-hidden="true"
          />
          {item.name}
          {item.children && (
            <i
              className={clsx(
                open ? "rotate-90 text-neutral-400" : "text-neutral-400",
                "ml-auto h-5 w-5 shrink-0 icon fa-solid fa-chevron-right"
              )}
              aria-hidden="true"
            />
          )}
        </div>
      </li>
    );
  };

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-neutral-100 bg-white px-6 py-2">
      <div className="flex h-16 shrink-0 items-center">
        <img
          className="h-16 w-auto"
          src={
            process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
            "/website/logos/500px_symbol_default.png"
          }
          alt="MOAS logo"
        />
      </div>

      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {navigation.map((item) => {
                const pathname = location.pathname.substring(1);
                const current =
                  item.name === "Tasks"
                    ? pathname.includes("tasks")
                    : item.name === "Metrics"
                    ? pathname.includes("metrics")
                    : item.name === "Settings"
                    ? pathname.includes("settings")
                    : item.name === "Advice"
                    ? pathname.includes("advice") && !pathname.includes("metrics")
                    : pathname === item.href;

                return (
                  <Disclosure key={item.name} as="div" defaultOpen={current}>
                    {({ open, close }) => (
                      <>
                        {!item.children ? (
                          <div>
                            <RenderNavItem
                              item={item}
                              current={current}
                              close={close}
                            />
                          </div>
                        ) : (
                          <>
                            <DisclosureButton className={"w-full"}>
                              <RenderNavItem
                                item={item}
                                current={current}
                                open={open}
                                close={close}
                              />
                            </DisclosureButton>
                            {current && (
                              <DisclosurePanel as="ul" className="mt-1 px-2">
                                {item.children?.map((subItem) => {
                                  const subItemcurrent =
                                    subItem.name === "Teams"
                                      ? pathname.includes("team")
                                      : subItem.name === "Projects"
                                      ? pathname.includes(
                                          "tasks-module/projects"
                                        )
                                      : subItem.name === "Templates"
                                      ? pathname.includes(
                                          "tasks-module/templates"
                                        )
                                      : subItem.name === "Users"
                                      ? pathname.includes("users")
                                      : subItem.name === "Advice"
                                      ? !pathname.includes("adviceagreements") && !pathname.includes("groups") && pathname.includes("advice")
                                      : pathname.includes(subItem.href);

                                  return (
                                    <li key={subItem.name}>
                                      {!subItem.children ? (
                                        <div
                                          onClick={() => navigate(subItem.href)}
                                          className={clsx(
                                            subItemcurrent
                                              ? "bg-neutral-50"
                                              : "hover:bg-neutral-50",
                                            "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-neutral-600 cursor-pointer"
                                          )}
                                        >
                                          {subItem.name}
                                        </div>
                                      ) : (
                                        <Disclosure as="div">
                                          {({ open }) => (
                                            <>
                                              <DisclosureButton
                                                className={clsx(
                                                  subItemcurrent
                                                    ? "bg-neutral-50"
                                                    : "hover:bg-neutral-50 text-neutral-600",
                                                  "flex gap-x-3 items-center w-full rounded-md py-2 pr-2 pl-9 text-sm leading-6"
                                                )}
                                              >
                                                {subItem.name}
                                                <i
                                                  className={clsx(
                                                    open
                                                      ? "rotate-90 text-neutral-400"
                                                      : "text-neutral-400",
                                                    "ml-auto h-5 w-5 shrink-0 icon fa-solid fa-chevron-right"
                                                  )}
                                                  aria-hidden="true"
                                                />
                                              </DisclosureButton>
                                              <DisclosurePanel
                                                as="ul"
                                                className="mt-1 px-2"
                                              >
                                                {subItem.children?.map(
                                                  (subSubItem) => {
                                                    const subSubItemcurrent =
                                                      pathname ===
                                                      subSubItem.href;

                                                    return (
                                                      <li key={subSubItem.name}>
                                                        {/* 44px */}
                                                        <div
                                                          onClick={() =>
                                                            navigate(
                                                              subSubItem.href
                                                            )
                                                          }
                                                          className={clsx(
                                                            subSubItemcurrent
                                                              ? "bg-neutral-50"
                                                              : "hover:bg-neutral-50",
                                                            "block rounded-md py-2 pr-2 pl-9 text-xs leading-6 text-neutral-600 cursor-pointer"
                                                          )}
                                                        >
                                                          {subSubItem.name}
                                                        </div>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </DisclosurePanel>
                                            </>
                                          )}
                                        </Disclosure>
                                      )}
                                    </li>
                                  );
                                })}
                              </DisclosurePanel>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Disclosure>
                );
              })}
            </ul>
          </li>
          <li className="-mx-2 mt-auto ">
            <a
              href="https://docs.moasapp.com"
              target="_blank"
              rel="noreferrer"
              className="flex gap-x-4 px-6 py-3 rounded-md p-2 text-sm text-neutral-400 leading-6 hover:bg-neutral-50"
            >
              <i className="icon fa-solid fa-message-bot text-neutral-300" />
              Help
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};
