import { gql } from "@apollo/client";
import { historyFragment } from ".";

export const createOneHistory = gql`
  mutation CreateOneHistory($data: historyCreateInput!) {
    createOneHistory(data: $data) {
      ...history
    }
  }
  ${historyFragment}
`;

export const updateOneHistory = gql`
  mutation UpdateOneHistory(
    $data: historyUpdateInput!
    $where: historyWhereUniqueInput!
  ) {
    updateOneHistory(data: $data, where: $where) {
      ...history
    }
  }
  ${historyFragment}
`;

export const deleteOneHistory = gql`
  mutation DeleteOneHistory($where: historyWhereUniqueInput!) {
    deleteOneHistory(where: $where) {
      ...history
    }
  }
  ${historyFragment}
`;
