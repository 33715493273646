import {
  FindManySalarypackagingQuery,
  FindManySalarypackagingQueryVariables,
} from "../../../codegen/schema";
import { salarypackagingFindManySalarypackaging } from "..";
import { QueryResult } from "@apollo/client";

export function findManySalarypackagingSubscription(
  query: Pick<
    QueryResult<
      FindManySalarypackagingQuery,
      FindManySalarypackagingQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  salarypackagingFindManySalarypackaging(query);
}
