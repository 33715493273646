import { QueryResult } from "@apollo/client";
import { FindManyPagetemplateTotalCount } from "..";
import {
  FindManyPagetemplateTotalCountQuery,
  FindManyPagetemplateTotalCountQueryVariables,
} from "../../../codegen/schema";

export default function findManyPagetemplateTotalCountSubscription(
  query: Pick<
    QueryResult<
      FindManyPagetemplateTotalCountQuery,
      FindManyPagetemplateTotalCountQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  FindManyPagetemplateTotalCount(query);
}
