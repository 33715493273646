import { notesFindUniqueSalarypackaging } from "../../notes";
import { salarypackagingFindUniqueSalarypackaging } from "..";
import {
  FindUniqueSalarypackagingQuery,
  FindUniqueSalarypackagingQueryVariables,
} from "../../../codegen/schema";
import { QueryResult } from "@apollo/client";

export default function findUniqueSalarypackagingSubscription(
  query: Pick<
    QueryResult<
      FindUniqueSalarypackagingQuery,
      FindUniqueSalarypackagingQueryVariables
    >,
    "subscribeToMore" | "variables"
  >
) {
  salarypackagingFindUniqueSalarypackaging(query);
  notesFindUniqueSalarypackaging(query);
}
