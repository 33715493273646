import {
  FindManyEntitiesQuery,
  FindManyEntitiesQueryVariables,
} from "../../../codegen/schema";
import { entitiesFindManyEntities } from "../";
import { QueryResult } from "@apollo/client";

export function findManyEntitiesSubscription(
  query: Pick<
    QueryResult<FindManyEntitiesQuery, FindManyEntitiesQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  entitiesFindManyEntities(query);
}
