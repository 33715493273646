import { gql } from "@apollo/client";
import { assetallocationFragment } from "../assetallocation";
import { notesFragment } from "../notes";
import { historyFragment } from "../history";
import { otherliabilitiesCoreFragment } from "../otherliabilities";

export const createOneOffset = gql`
  mutation CreateOneOffset($data: offsetCreateInput!) {
    createOneOffset(data: $data) {
      ID
      assets_ID
      Bank
      BSB
      AccountNumber
      Amount
      RelatedLoan
      Purpose
      OwnershipType
      create_time
      update_time
      created_by
      updated_by

      assetallocation {
        ...assetallocation
      }
      liabilities {
        loans {
          ID
          liabilities_ID
          Bank
          BSB
          AccountNumber
          TaxDeductible
          Purpose
          SecuredBy
          RelatedAsset
          InterestRate
          VariableFixed
          FixedExpiry
          InterestOnlyExpiry
          RepaymentPerMonth
          LoanLimit
          AvailableRedraw
          RepaymentType
          LoanOwnership
          CurrentLoanOutstanding
          LoanType
          LoanStartDate
          LoanExpiry
          Description
          InterestOnlyStartDate
          RateReviewedDate
          Offset
          Position
          Refinance
          create_time
          update_time
          created_by
          updated_by
        }
        otherliabilities {
          ...otherliabilitiesCore
        }
      }
      notes {
        ...notes
      }
      history {
        ...history
      }
    }
  }
  ${assetallocationFragment}
  ${otherliabilitiesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;

export const updateOneOffset = gql`
  mutation UpdateOneOffset(
    $data: offsetUpdateInput!
    $where: offsetWhereUniqueInput!
  ) {
    updateOneOffset(data: $data, where: $where) {
      ID
      assets_ID
      Bank
      BSB
      AccountNumber
      Amount
      RelatedLoan
      Purpose
      OwnershipType
      create_time
      update_time
      created_by
      updated_by

      assetallocation {
        ...assetallocation
      }
      liabilities {
        loans {
          ID
          liabilities_ID
          Bank
          BSB
          AccountNumber
          TaxDeductible
          Purpose
          SecuredBy
          RelatedAsset
          InterestRate
          VariableFixed
          FixedExpiry
          InterestOnlyExpiry
          RepaymentPerMonth
          LoanLimit
          AvailableRedraw
          RepaymentType
          LoanOwnership
          CurrentLoanOutstanding
          LoanType
          LoanStartDate
          LoanExpiry
          Description
          InterestOnlyStartDate
          RateReviewedDate
          Offset
          Position
          Refinance
          create_time
          update_time
          created_by
          updated_by
        }
        otherliabilities {
          ...otherliabilitiesCore
        }
      }
      notes {
        ...notes
      }
      history {
        ...history
      }
    }
  }
  ${assetallocationFragment}
  ${otherliabilitiesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;

export const deleteOneOffset = gql`
  mutation DeleteOneOffset($where: offsetWhereUniqueInput!) {
    deleteOneOffset(where: $where) {
      ID
      assets_ID
      Bank
      BSB
      AccountNumber
      Amount
      RelatedLoan
      Purpose
      OwnershipType
      create_time
      update_time
      created_by
      updated_by

      assetallocation {
        ...assetallocation
      }
      liabilities {
        loans {
          ID
          liabilities_ID
          Bank
          BSB
          AccountNumber
          TaxDeductible
          Purpose
          SecuredBy
          RelatedAsset
          InterestRate
          VariableFixed
          FixedExpiry
          InterestOnlyExpiry
          RepaymentPerMonth
          LoanLimit
          AvailableRedraw
          RepaymentType
          LoanOwnership
          CurrentLoanOutstanding
          LoanType
          LoanStartDate
          LoanExpiry
          Description
          InterestOnlyStartDate
          RateReviewedDate
          Offset
          Position
          Refinance
          create_time
          update_time
          created_by
          updated_by
        }
        otherliabilities {
          ...otherliabilitiesCore
        }
      }
      notes {
        ...notes
      }
      history {
        ...history
      }
    }
  }
  ${assetallocationFragment}
  ${otherliabilitiesCoreFragment}
  ${notesFragment}
  ${historyFragment}
`;
