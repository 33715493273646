import { gql } from "@apollo/client";
import { pagetemplateFragment } from "./fragment";

export const findUniquePagetemplate = gql`
  query FindUniquePagetemplate($where: pagetemplateWhereUniqueInput!) {
    findUniquePagetemplate(where: $where) {
      ...pagetemplate
    }
  }
  ${pagetemplateFragment}
`;

export const findManyPagetemplate = gql`
  query FindManyPagetemplate(
    $where: pagetemplateWhereInput
    $distinct: [PagetemplateScalarFieldEnum!]
    $orderBy: [pagetemplateOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $cursor: pagetemplateWhereUniqueInput
  ) {
    findManyPagetemplate(
      where: $where
      distinct: $distinct
      orderBy: $orderBy
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      ...pagetemplate
    }
  }
  ${pagetemplateFragment}
`;

export const findManyPagetemplateTotalCount = gql`
  query findManyPagetemplateTotalCount($where: pagetemplateWhereInput) {
    findManyPagetemplateTotalCount(where: $where)
  }
`;
