import { gql } from "@apollo/client";
import { goalFragment } from ".";

/** Goal */
export const createOneGoal = gql`
  mutation CreateOneGoal($data: goalCreateInput!) {
    createOneGoal(data: $data) {
      ...goal
    }
  }
  ${goalFragment}
`;

export const updateOneGoal = gql`
  mutation UpdateOneGoal($data: goalUpdateInput!, $where: goalWhereUniqueInput!) {
    updateOneGoal(data: $data, where: $where) {
      ...goal
    }
  }
  ${goalFragment}
`;

export const deleteOneGoal = gql`
  mutation DeleteOneGoal($where: goalWhereUniqueInput!) {
    deleteOneGoal(where: $where) {
      ...goal
    }
  }
  ${goalFragment}
`;
