import React from "react";
import {
  FindUniqueDocumenttemplateQuery,
  FindUniqueOrganisationQuery,
  FindUniqueThemeQuery,
} from "../../../../../../codegen/schema";

import { Image } from "../../../../Utlis/Image";

import dayjs from "dayjs";
import { StyledText } from "../../../../../../Components/Advice/StyledText";

interface PageFooterTempalteProps {
  organisation: FindUniqueOrganisationQuery["findUniqueOrganisation"];
  documenttemplate: FindUniqueDocumenttemplateQuery["findUniqueDocumenttemplate"];
  theme: FindUniqueThemeQuery["findUniqueTheme"];
}

export const PageFooter: React.FC<PageFooterTempalteProps> = ({
  organisation,
  documenttemplate,
  theme,
}) => {
  return (
    <React.Fragment>
      <Image
        fileName="logo_symbol"
        filePath={`application/organisation/ID=${organisation?.ID}/theme/ID=${theme?.ID}/images/`}
        id={"logo-symbol"}
        label="Logo symbol"
        width={50}
        height={50}
      />

      <div className="flex flex-col ml-2">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H6")}
          style={{ fontSize: "8pt" }}
        >
          {documenttemplate?.Type}
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
          style={{ fontSize: "9pt" }}
        >
          John & Jane Smith
        </StyledText>

        <StyledText
          element={theme?.element.find((element) => element.Type === "P")}
          style={{ fontSize: "7pt" }}
        >
          {documenttemplate?.Type} dated {dayjs().format("DD/MM/YYYY")}
        </StyledText>
      </div>
    </React.Fragment>
  );
};
