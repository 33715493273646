import { QueryResult } from "@apollo/client";
import {
  ContributionswithdrawalsSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { contributionswithdrawals } from "../subscription";

interface ContributionswithdrawalsResolverParams {
  prev: FindUniqueGroupQuery;
  payload: ContributionswithdrawalsSubscription["contributionswithdrawals"];
}

function createContributionswithdrawals({
  prev,
  payload,
}: ContributionswithdrawalsResolverParams) {
  const contributionswithdrawals = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          goals: client.goals.map((goal) =>
            goal.ID === contributionswithdrawals.goals_ID
              ? {
                  ...goal,
                  contributionswithdrawals: [
                    ...goal.contributionswithdrawals,
                    contributionswithdrawals,
                  ],
                }
              : goal
          ),
        };
      }),
    },
  });
}

function updateContributionswithdrawals({
  prev,
  payload,
}: ContributionswithdrawalsResolverParams) {
  const contributionswithdrawals = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          goals: client.goals.map((goal) =>
            goal.ID === contributionswithdrawals.goals_ID
              ? {
                  ...goal,
                  contributionswithdrawals: goal.contributionswithdrawals.map(
                    (cw) =>
                      cw.ID === contributionswithdrawals.ID
                        ? {
                            ...cw,
                            ...contributionswithdrawals,
                          }
                        : cw
                  ),
                }
              : goal
          ),
        };
      }),
    },
  });
}

function deleteContributionswithdrawals({
  prev,
  payload,
}: ContributionswithdrawalsResolverParams) {
  const contributionswithdrawals = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          goals: client.goals.map((goal) =>
            goal.ID === contributionswithdrawals.goals_ID
              ? {
                  ...goal,
                  contributionswithdrawals:
                    goal.contributionswithdrawals.filter(
                      (cw) => cw.ID !== contributionswithdrawals.ID
                    ),
                }
              : goal
          ),
        };
      }),
    },
  });
}

function contributionswithdrawalsResolver({
  prev,
  payload,
}: ContributionswithdrawalsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createContributionswithdrawals({ prev, payload });

    case MutationType.Update:
      return updateContributionswithdrawals({ prev, payload });

    case MutationType.Delete:
      return deleteContributionswithdrawals({ prev, payload });

    default:
      return prev;
  }
}

export function contributionswithdrawalsFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: contributionswithdrawals,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: ContributionswithdrawalsSubscription };
      }
    ) =>
      contributionswithdrawalsResolver({
        prev,
        payload: payload.subscriptionData.data.contributionswithdrawals
      }),
    variables: query.variables,
  });
}
