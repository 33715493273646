import dayjs from "dayjs";
import React from "react";
import { SuperannuationInsuranceScenarioModelGraph } from "../Graph";
import { ScenarioData } from "../../../Calculations";
import { Model_Type, Theme } from "../../../../../../../codegen/schema";
import { StyledText } from "../../../../../StyledText";
import { ModelTooltip } from "../../../../Utils/Tooltip";

interface SuperannuationInsurancePlaceholderModelProps {
  theme?: Theme;
}

const initialValue = 100000;
var data: ScenarioData[] = [];

Array.from(Array(20).keys()).forEach((value) => {
  var previousYear = data[value - 1];

  data.push({
    year: dayjs().year() + value,
    withInsurance: previousYear
      ? initialValue * (1 + 0.04 / 12) ** (12 * value)
      : initialValue,
    withoutInsurance: previousYear
      ? initialValue * (1 + 0.05 / 12) ** (12 * value)
      : initialValue,
  });
});

export const SuperannuationInsuranceScenarioPlaceholderModel = ({
  theme,
}: SuperannuationInsurancePlaceholderModelProps) => {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
          className="flex items-center"
        >
          Superannuation Insurance
        </StyledText>

        <ModelTooltip type={Model_Type.SuperannuationInsurance} />
      </div>
      <h1
    className="flex items-center justify-center absolute h-3/4 w-full text-neutral-400 tracking-widest"
      >
        PLACEHOLDER
      </h1>
      <div className="opacity-50">
        <SuperannuationInsuranceScenarioModelGraph
          scenarioData={data}
          liveData={undefined}
        />
      </div>
    </React.Fragment>
  );
};
