import { gql } from "@apollo/client";
import { childFragment } from ".";

export const createOneChild = gql`
  mutation CreateOneChild($data: childCreateInput!) {
    createOneChild(data: $data) {
      ...child
    }
  }
  ${childFragment}
`;

export const updateOneChild = gql`
  mutation UpdateOneChild(
    $data: childUpdateInput!
    $where: childWhereUniqueInput!
  ) {
    updateOneChild(data: $data, where: $where) {
      ...child
    }
  }
  ${childFragment}
`;

export const deleteOneChild = gql`
  mutation DeleteOneChild($where: childWhereUniqueInput!) {
    deleteOneChild(where: $where) {
      ...child
    }
  }
  ${childFragment}
`;
