import { QueryResult } from "@apollo/client";
import {
  OtherassetsSubscription,
  MutationType,
  FindUniqueGroupQuery,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { otherassets } from "../subscription";

interface OtherassetsResolverParams {
  prev: FindUniqueGroupQuery;
  payload: OtherassetsSubscription["otherassets"];
}

function createOtherassets({ prev, payload }: OtherassetsResolverParams) {
  const { data: createOtherassets, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            otherassets: data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? [...client.assets.otherassets, createOtherassets]
              : client.assets.otherassets,
          },
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            otherassets: data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? [...entity.assets.otherassets, createOtherassets]
              : entity.assets.otherassets,
          },
        };
      }),
    },
  });
}

function updateOtherassets({ prev, payload }: OtherassetsResolverParams) {
  const { data: updateOtherassets, ...data } = payload;

  if (!prev.findUniqueGroup) return prev;
  /**
   * Find the otherassets object
   * This is because updateOtherasset only returns the otherassets info
   * without assetallocation, notes, etc.
   */
  var updatedOtherasset = [
    ...prev.findUniqueGroup.clients,
    ...prev.findUniqueGroup.entities,
  ].reduce((result, client) => {
    client.assets.otherassets.forEach((otherassets) => {
      if (otherassets.assets_ID === updateOtherassets.assets_ID) {
        result = {
          ...otherassets,
          ...updateOtherassets,
        };
      }
    });
    return result;
  }, updateOtherassets);

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      /** Update otherassets first */
      clients: prev.findUniqueGroup.clients.map((client) => ({
        ...client,
        assets: {
          ...client.assets,
          otherassets: [
            ...client.assets.otherassets
              .map((otherassets) =>
                otherassets.ID === updatedOtherasset.ID
                  ? { ...otherassets, ...updatedOtherasset }
                  : otherassets
              )
              .filter(
                (otherassets) =>
                  ![...data.clients_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(otherassets.assets_ID)
              ),
            ...(data.clients_has_assets.some(
              (relation) => relation.clients_ID === client.ID
            )
              ? data.clients_has_assets.reduce<
                  Array<OtherassetsSubscription["otherassets"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedOtherasset.assets_ID &&
                    relation.clients_ID === client.ID
                  ) {
                    relationResult = [...relationResult, updatedOtherasset];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...client.liabilities,
          loans: client.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    otherassets: liability.assets.otherassets.map(
                      (otherassets) =>
                        otherassets.ID === updateOtherassets.ID
                          ? {
                              ...otherassets,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateOtherassets,
                            }
                          : otherassets /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
        /** Update loans in goals. */
        goals: client.goals.map((goal) => ({
          ...goal,
          assets: {
            ...goal.assets,
            otherassets: goal.assets.otherassets.map((otherassets) =>
              otherassets.ID === updatedOtherasset.ID
                ? { ...otherassets, ...updatedOtherasset }
                : otherassets
            ),
          },
          liabilities: {
            ...goal.liabilities,
            loans: goal.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      otherassets: liability.assets.otherassets.map(
                        (otherassets) =>
                          otherassets.ID === updateOtherassets.ID
                            ? {
                                ...otherassets,
                                /** Update loan info */
                                ...updateOtherassets,
                              }
                            : otherassets
                      ),
                    },
                  }
                : liability
            ),
          },
        })),
      })),

      /** Remove the existing otherassets object from previous entities */
      entities: prev.findUniqueGroup.entities.map((entity) => ({
        ...entity,
        assets: {
          ...entity.assets,
          otherassets: [
            ...entity.assets.otherassets
              .map((otherassets) =>
                otherassets.ID === updatedOtherasset.ID
                  ? { ...otherassets, ...updatedOtherasset }
                  : otherassets
              )
              .filter(
                (otherassets) =>
                  ![...data.entities_has_assets]
                    .map((entry) => entry.assets_ID)
                    .includes(otherassets.assets_ID)
              ),
            ...(data.entities_has_assets.some(
              (relation) => relation.entities_ID === entity.ID
            )
              ? data.entities_has_assets.reduce<
                  Array<OtherassetsSubscription["otherassets"]["data"]>
                >((relationResult, relation) => {
                  if (
                    relation.assets_ID === updatedOtherasset.assets_ID &&
                    relation.entities_ID === entity.ID
                  ) {
                    relationResult = [...relationResult, updatedOtherasset];
                  }
                  return relationResult;
                }, [])
              : []),
          ],
        },
        liabilities: {
          ...entity.liabilities,
          loans: entity.liabilities.loans.map((liability) =>
            liability.assets
              ? {
                  ...liability,
                  assets: {
                    ...liability.assets,
                    otherassets: liability.assets.otherassets.map(
                      (otherassets) =>
                        otherassets.ID === updateOtherassets.ID
                          ? {
                              ...otherassets,
                              /** Update loan.assets if loan has assets ONLY LOAN INFO */
                              ...updateOtherassets,
                            }
                          : otherassets /** Update loan.assets if loan has assets ONLY LOAN INFO */
                    ),
                  },
                }
              : liability
          ),
        },
      })),
    },
  });
}

function deleteOtherassets({ prev, payload }: OtherassetsResolverParams) {
  const { data: deleteOtherassets } = payload;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      clients: prev.findUniqueGroup.clients.map((client) => {
        return {
          ...client,
          assets: {
            ...client.assets,
            otherassets: [...client.assets.otherassets].filter(
              (otherassets) => otherassets.ID !== deleteOtherassets.ID
            ),
          },
          liabilities: {
            ...client.liabilities,
            loans: client.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      otherassets: liability.assets.otherassets.filter(
                        (otherassets) => otherassets.ID !== deleteOtherassets.ID
                      ),
                    },
                  }
                : liability
            ),
          },
          /** Update loans in goals. */
          goals: client.goals.map((goal) => ({
            ...goal,
            assets: {
              ...goal.assets,
              otherassets: goal.assets.otherassets.filter(
                (otherassets) => otherassets.ID !== deleteOtherassets.ID
              ),
            },
            liabilities: {
              ...goal.liabilities,
              loans: goal.liabilities.loans.map((liability) =>
                liability.assets
                  ? {
                      ...liability,
                      assets: {
                        ...liability.assets,
                        otherassets: liability.assets.otherassets.filter(
                          (otherassets) =>
                            otherassets.ID !== deleteOtherassets.ID
                        ),
                      },
                    }
                  : liability
              ),
            },
          })),
        };
      }),
      entities: prev.findUniqueGroup.entities.map((entity) => {
        return {
          ...entity,
          assets: {
            ...entity.assets,
            otherassets: [...entity.assets.otherassets].filter(
              (otherassets) => otherassets.ID !== deleteOtherassets.ID
            ),
          },
          liabilities: {
            ...entity.liabilities,
            loans: entity.liabilities.loans.map((liability) =>
              liability.assets
                ? {
                    ...liability,
                    assets: {
                      ...liability.assets,
                      otherassets: liability.assets.otherassets.filter(
                        (otherassets) => otherassets.ID !== deleteOtherassets.ID
                      ),
                    },
                  }
                : liability
            ),
          },
        };
      }),
    },
  });
}

function otherassetsResolver({ prev, payload }: OtherassetsResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createOtherassets({ prev, payload });

    case MutationType.Update:
      return updateOtherassets({ prev, payload });

    case MutationType.Delete:
      return deleteOtherassets({ prev, payload });

    default:
      return prev;
  }
}

export function otherassetsFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: otherassets,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: OtherassetsSubscription } }
    ) =>
      otherassetsResolver({
        prev,
        payload: payload.subscriptionData.data.otherassets
      }),
    variables: query.variables,
  });
}
