import { QueryResult } from "@apollo/client";
import {
  TagManySubscription,
  FindManyTagQuery,
  MutationType,
  FindManyTagQueryVariables,
} from "../../../codegen/schema";
import { tagMany } from "../subscription";

interface TagResolverParams {
  prev: FindManyTagQuery;
  payload: TagManySubscription["tagMany"];
}

function createTag({ prev, payload }: TagResolverParams) {
  const tag = payload.data;

  return Object.assign({}, prev, {
    findManyTag: [...prev.findManyTag, tag],
  });
}

function updateTag({ prev, payload }: TagResolverParams) {
  const tag = payload.data;

  return Object.assign({}, prev, {
    findManyTag: prev.findManyTag.map((entry) =>
      entry.ID === tag.ID ? { ...entry, ...tag } : entry
    ),
  });
}

function deleteTag({ prev, payload }: TagResolverParams) {
  const tag = payload.data;

  return Object.assign({}, prev, {
    findManyTag: [...prev.findManyTag].filter((entry) => entry.ID !== tag.ID),
  });
}

function tagResolver({ prev, payload }: TagResolverParams) {
  const { data, mutationType } = payload;

  switch (mutationType) {
    case MutationType.Create:
      return createTag({ prev, payload });

    case MutationType.Update:
      // if id in array, update
      // if id not in array, create and change payload name
      return prev.findManyTag.some((tag) => tag.ID === data.ID)
        ? updateTag({ prev, payload })
        : createTag({ prev, payload });

    case MutationType.Delete:
      return deleteTag({ prev, payload });

    default:
      return prev;
  }
}

export function tagFindManyTag(
  query: Pick<
    QueryResult<FindManyTagQuery, FindManyTagQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: tagMany,
    updateQuery: (
      prev,
      payload: { subscriptionData: { data: TagManySubscription } }
    ) =>
      tagResolver({
        prev,
        payload: payload.subscriptionData.data.tagMany,
      }),
    variables: query.variables,
  });
}
