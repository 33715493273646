import { gql } from "@apollo/client";
import { notesFragment } from "../notes/fragment";

export const incomeFragment = gql`
  fragment income on income {
    ID
    clients_ID
    clients_groups_ID
    clients_groups_organisation_ID
    FinancialYear
    TaxableIncome
    GrossIncome
    TaxPaid
    TaxDate
    TotalTaxDeductions
    AdjustedTaxableIncome
    SurplusIncome
    EstimatedMonthlyLivingExpenses
    Savings
    Frequency
    MonthlyServiceRetainer
    EstimatedTaxPaymentsSchedule
    PAYGWitholdingVariations
    DeductibleIncomeProtectionPremium
    TotalTaxDeductibleLoans
    ExpectedDateForFinishedWork
    ECC
    Div293
    RegisteredForGST
    ECCDetails
    Div293Details
    Division293Amount
    MLSIncomeAmount
    GovernmentBenefitIncome
    GovernmentBenefitType
    ReportableFringeBenefits
    NetFinancialInvestmentLoss
    NetRentalPropertyLoss
    NetFamilyTrustDistribution
    SuperLumpSum
    AssessableFirstHomeSuperSaverReleasedAmount
    RecommendedConcessionalContributions
    RecommendedNonConcessionalContributions

    NominatedSuperannuation_assets_ID
    EmployerSuperannuationContributions
    SalarySacrifice
    AdditionalConcessionalContributions
    NonConcessionalContributions

    SuperannuationContributionSplitting
    SuperannuationContributionSplitting_clients_ID
    
    IncomeSplitting
    IncomeSplitting_clients_ID

    create_time
    created_by
    update_time
    updated_by
    notes {
      ...notes
    }
  }
  ${notesFragment}
`;
