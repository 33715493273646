import { gql } from "@apollo/client";
import { scenariocontributionwithdrawalFragment } from "./fragment";

export const scenariocontributionwithdrawal = gql`
  subscription Scenariocontributionwithdrawal(
    $where: scenariocontributionwithdrawalWhereUniqueInput
  ) {
    scenariocontributionwithdrawal(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenariocontributionwithdrawal
      }
      scenarioasset_has_scenariocontributionwithdrawal {
        scenarioasset_ID
        scenariocontributionwithdrawal_ID
      }
      scenarioliability_has_scenariocontributionwithdrawal {
        scenarioliability_ID
        scenariocontributionwithdrawal_ID
      }
    }
  }
  ${scenariocontributionwithdrawalFragment}
`;

export const scenariocontributionwithdrawalMany = gql`
  subscription ScenariocontributionwithdrawalMany(
    $where: scenariocontributionwithdrawalWhereInput
  ) {
    scenariocontributionwithdrawalMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenariocontributionwithdrawal
      }
      scenarioasset_has_scenariocontributionwithdrawal {
        scenarioasset_ID
        scenariocontributionwithdrawal_ID
      }
      scenarioliability_has_scenariocontributionwithdrawal {
        scenarioliability_ID
        scenariocontributionwithdrawal_ID
      }
    }
  }
  ${scenariocontributionwithdrawalFragment}
`;
