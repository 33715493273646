import { gql } from "@apollo/client";
import { reviewFragment } from "./fragment";

export const review = gql`
  subscription Review($where: reviewWhereUniqueInput) {
    review(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...review
      }
    }
  }
  ${reviewFragment}
`;

export const reviewMany = gql`
  subscription ReviewMany($where: reviewWhereInput) {
    reviewMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...review
      }
    }
  }
  ${reviewFragment}
`;
