import { gql } from "@apollo/client";
import { propertyFragment } from "./fragment";

export const createOneProperty = gql`
  mutation CreateOneProperty($data: propertyCreateInput!) {
    createOneProperty(data: $data) {
      ...property
    }
  }
  ${propertyFragment}
`;

export const updateOneProperty = gql`
  mutation UpdateOneProperty(
    $data: propertyUpdateInput!
    $where: propertyWhereUniqueInput!
  ) {
    updateOneProperty(data: $data, where: $where) {
      ...property
    }
  }
  ${propertyFragment}
`;

export const deleteOneProperty = gql`
  mutation DeleteOneProperty($where: propertyWhereUniqueInput!) {
    deleteOneProperty(where: $where) {
      ...property
    }
  }
  ${propertyFragment}
`;
