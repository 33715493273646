import { gql } from "@apollo/client";
import { clientsFragment, clientsCoreFragment } from ".";

export const createOneClients = gql`
  mutation createOneClients($data: clientsCreateInput!) {
    createOneClients(data: $data) {
      ...clients
    }
  }
  ${clientsFragment}
`;

export const updateOneClients = gql`
  mutation UpdateOneClients(
    $data: clientsUpdateInput!
    $where: clientsWhereUniqueInput!
  ) {
    updateOneClients(data: $data, where: $where) {
      ...clients
    }
  }
  ${clientsFragment}
`;

export const deleteOneClients = gql`
  mutation DeleteOneClients($where: clientsWhereUniqueInput!) {
    deleteOneClients(where: $where) {
      ...clientsCore
    }
  }
  ${clientsCoreFragment}
`;
