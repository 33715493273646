import React, { useEffect, useState } from "react";

import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  FindUniqueAdviceQuery,
  Theme,
} from "../../../../codegen/schema";

import { StyledText } from "../../StyledText";
import { LiabilitiesLoan } from "./Loan";
import { LiabilitiesOtherliability } from "./Otherliability";

interface LiabilitiesComponentProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
  advice?: FindUniqueAdviceQuery["findUniqueAdvice"];
}

export const LiabilitiesComponent: React.FC<LiabilitiesComponentProps> = ({
  content = undefined,
  contenttemplate = undefined,
  theme,
  group,
  advice,
}) => {
  const [currentContent, setCurrentContent] = useState(
    content ? content : contenttemplate
  );

  useEffect(() => {
    setCurrentContent(content ? content : contenttemplate);
  }, [content, contenttemplate]);

  const components = {
    Loan: LiabilitiesLoan,
    Other_Liability: LiabilitiesOtherliability,
  };

  type ComponentType = "Loan" | "Other_Liability";

  if (!currentContent) return null;

  const ContentComponent =
    components[currentContent.LiabilityType as ComponentType];
  return (
    <React.Fragment>
      <div className="flex flex-col w-full">
        {currentContent.ContentType === "Grouped" ? (
          <>
            <div
              className=" mb-1"
              style={{
                borderBottom:
                  "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
              }}
            >
              <StyledText
                element={theme?.element.find(
                  (element) => element.Type === "H4"
                )}
              >
                {currentContent.LiabilityType?.replaceAll("_", " ")}
              </StyledText>
            </div>
            <ContentComponent
              content={content}
              contenttemplate={contenttemplate}
              theme={theme}
              group={group}
              advice={advice}
            />
          </>
        ) : (
          <ContentComponent
            content={content}
            contenttemplate={contenttemplate}
            theme={theme}
            group={group}
            advice={advice}
          />
        )}
      </div>
    </React.Fragment>
  );
};
