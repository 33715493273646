import { gql } from "@apollo/client";
import { scenarioliabilityFragment } from "./fragment";

export const scenarioliability = gql`
  subscription Scenarioliability($where: scenarioliabilityWhereUniqueInput) {
    scenarioliability(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioliability
      }
      model_has_scenarioliability {
        model_ID
        scenarioliability_ID
      }
    }
  }
  ${scenarioliabilityFragment}
`;

export const scenarioliabilityMany = gql`
  subscription ScenarioliabilityMany($where: scenarioliabilityWhereInput) {
    scenarioliabilityMany(where: $where) {
      mutationType
      organisation {
        ID
      }
      data {
        ...scenarioliability
      }
      model_has_scenarioliability {
        model_ID
        scenarioliability_ID
      }
    }
  }
  ${scenarioliabilityFragment}
`;
