import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "../../../../Components/Tooltip";
import { navigationOption } from "..";
import clsx from "clsx";

interface SidebarNavigationProps {
  navigation: navigationOption[];
}

export const SidebarSmall: React.FC<SidebarNavigationProps> = ({
  navigation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="fixed inset-y-0 left-0 z-50 block w-20 overflow-y-auto bg-white pb-4 border-r border-neutral-100">
      <div className="flex h-16 shrink-0 items-center justify-center">
        <img
          className="h-14 w-auto"
          src={
            process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
            "/website/logos/500px_symbol_default.png"
          }
          alt="MOAS logo"
        />
      </div>
      <nav className="mt-8">
        <ul className="flex flex-col items-center space-y-1">
          {navigation.map((item) => {
            const pathname = location.pathname.substring(1);
            const current = item.href.includes("tasks")
              ? pathname.includes("tasks")
              : pathname === item.href;

            return (
              <li key={item.name}>
                <Tooltip title={item.name}>
                  <div
                    onClick={() => navigate(item.href)}
                    className={clsx(
                      current
                        ? "bg-neutral-50 text-blue-500"
                        : "hover:bg-neutral-50 text-neutral-600",
                      "group flex gap-x-3 rounded-md p-3 text-sm leading-6 cursor-pointer"
                    )}
                  >
                    <i
                      className={clsx(
                        current ? " text-blue-500" : "text-neutral-500",
                        item?.icon ? item.icon : "",
                        "flex items-center justify-center fa-regular h-6 shrink-0 fa-lg group-hover:text-blue-500"
                      )}
                      aria-hidden="true"
                    />
                    <span className="sr-only">{item.name}</span>
                  </div>
                </Tooltip>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
