import React from "react";
import {
  Content,
  Contenttemplate,
  FindUniqueGroupQuery,
  Theme,
} from "../../../../../codegen/schema";

import {
  checkFieldIsEmpty,
  getGroupAssets,
} from "../../../../../Functions/GeneralFunctions";
import { StyledText } from "../../../StyledText";
import { ownerOptionsUpdateAssetDefaultValue } from "../../../../../Functions/Owner";

interface AssetsCollectibleProps {
  content?: Content;
  contenttemplate?: Contenttemplate;
  theme?: Theme;
  group?: FindUniqueGroupQuery["findUniqueGroup"];
}

/**
 * @created 01-02-2023
 * @updated 20-02-2023
 * @returns Returns collectible component for advice template && document.
 */
export const AssetsCollectible: React.FC<AssetsCollectibleProps> = ({
  content,
  contenttemplate,
  theme,
  group,
}) => {
  type Collectible = {
    assets_ID: number | null;
    Type: string;
    Owner: string;
    Value: string;
  };

  const summaryCollectible: Collectible[] = group
    ? content?.ContentType === "Grouped"
      ? getGroupAssets(group).collectibles
      : content?.asset_ID
      ? getGroupAssets(group).collectibles.find(
          (collectibles: Collectible) =>
            content?.asset_ID === collectibles.assets_ID
        )
      : {}
    : contenttemplate?.ContentType === "Grouped"
    ? [
        { Type: "Jewellery", Owner: "Jane Smith", Value: "$5000" },
        { Type: "Other", Owner: "Joint", Value: "$80,000" },
      ]
    : { Type: "Jewellery", Owner: "Jane Smith", Value: "$5000" };

  return content?.ContentType === "Grouped" ||
    contenttemplate?.ContentType === "Grouped" ? (
    <React.Fragment>
      {summaryCollectible.length > 0 ? (
        <div className="grid grid-cols-3 gap-2">
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Type
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Owner
          </StyledText>
          <StyledText
            element={theme?.element.find((element) => element.Type === "H6")}
          >
            Value
          </StyledText>
        </div>
      ) : (
        <h5>No collectibles recorded</h5>
      )}
      {summaryCollectible.map((collectible, objectIndex) => {
        return (
          <div className="grid grid-cols-3 gap-2 mt-2" key={objectIndex}>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(collectible.Type)}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(
                group
                  ? ownerOptionsUpdateAssetDefaultValue(collectible, group).text
                  : collectible.Owner
              )}
            </StyledText>
            <StyledText
              element={theme?.element.find((element) => element.Type === "P")}
            >
              {checkFieldIsEmpty(collectible.Value, "dollar")}
            </StyledText>
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <React.Fragment>Individual Annuity view</React.Fragment>
  );
};
