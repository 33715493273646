export function IncomeOptimisationModelDescription() {
  return (
    <p>
      The income optimisation model displays the implications of the income
      strategies in place and which tax brackets are then applicable to a
      client's income.
    </p>
  );
}

export function IncomeOptimisationCalculationTooltip() {
  return (
    <>
      <p>
        <b>Scenario</b>
        <br />
        This graph shows what percentage of income tax is paid at each tax
        bracket. This is based on the latest tax bracket information from the
        ATO and individual residency status per client.
      </p>
      <p>
        <b>Comparison</b>
        <br />
        The comparison graph shows the total amount of tax paid per client per
        scenario.
      </p>
      <p>
        <b>Inputs</b>
        <br />
        The following scenario client inputs affect this model: gross salary,
        employer concessional contributions, salary sacrifice, income splitting,
        salary package.
      </p>
    </>
  );
}

export function IncomeOptimisationModelDisclaimer() {
  return (
    <h5 className="text-2xs ">
      <b className="colour-warning font-size_XS warning-100-background">
        Income optimisation graph is for income tax only - this graph will not
        work for clients with non taxable income.{" "}
      </b>
    </h5>
  );
}
