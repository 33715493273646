import { gql } from "@apollo/client";
import { collectiblesFragment } from ".";

export const createOneCollectible = gql`
  mutation CreateOneCollectibles($data: collectiblesCreateInput!) {
    createOneCollectibles(data: $data) {
      ...collectibles
    }
  }
  ${collectiblesFragment}
`;

export const updateOneCollectibles = gql`
  mutation UpdateOneCollectibles(
    $data: collectiblesUpdateInput!
    $where: collectiblesWhereUniqueInput!
  ) {
    updateOneCollectibles(data: $data, where: $where) {
      ...collectibles
    }
  }
  ${collectiblesFragment}
`;

export const deleteOneCollectible = gql`
  mutation deleteOneCollectibles($where: collectiblesWhereUniqueInput!) {
    deleteOneCollectibles(where: $where) {
      ...collectibles
    }
  }
  ${collectiblesFragment}
`;
