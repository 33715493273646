import { QueryResult } from "@apollo/client";
import {
  TeamSubscription,
  FindUniqueGroupQuery,
  MutationType,
  FindUniqueGroupQueryVariables,
} from "../../../codegen/schema";
import { team } from "../subscription";

interface TeamResolverParams {
  prev: FindUniqueGroupQuery;
  payload: TeamSubscription["team"];
}

function updateTeam({ prev, payload }: TeamResolverParams) {
  const team = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      team: prev.findUniqueGroup.team.map((teams) =>
        teams.ID === team.ID ? { ...teams, ...team } : teams
      ),
    },
  });
}

function deleteTeam({ prev, payload }: TeamResolverParams) {
  const team = payload.data;

  if (!prev.findUniqueGroup) return prev;

  return Object.assign({}, prev, {
    findUniqueGroup: {
      ...prev.findUniqueGroup,
      team: [...prev.findUniqueGroup.team].filter(
        (teams) => teams.ID !== team.ID
      ),
    },
  });
}

function teamResolver({ prev, payload }: TeamResolverParams) {
  const { mutationType } = payload;

  switch (mutationType) {
    // case MutationType.Create:
    //   return createTeam({ prev, payload });

    case MutationType.Update:
      return updateTeam({ prev, payload });

    case MutationType.Delete:
      return deleteTeam({ prev, payload });

    default:
      return prev;
  }
}

export function teamFindUniqueGroup(
  query: Pick<
    QueryResult<FindUniqueGroupQuery, FindUniqueGroupQueryVariables>,
    "subscribeToMore" | "variables"
  >
) {
  query.subscribeToMore({
    document: team,
    updateQuery: (
      prev,
      payload: {
        subscriptionData: { data: TeamSubscription };
      }
    ) =>
      teamResolver({
        prev,
        payload: payload.subscriptionData.data.team,
      }),
    variables: query.variables,
  });
}
