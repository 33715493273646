import { gql } from "@apollo/client";
import { assetallocationFragment } from ".";

export const createOneAssetallocation = gql`
  mutation CreateOneAssetallocation(
    $data: assetallocationCreateInput!
  ) {
    createOneAssetallocation(data: $data) {
      ...assetallocation
    }
  }
  ${assetallocationFragment}
`;

export const updateOneAssetallocation = gql`
  mutation UpdateOneAssetallocation(
    $data: assetallocationUpdateInput!
    $where: assetallocationWhereUniqueInput!
  ) {
    updateOneAssetallocation(data: $data, where: $where) {
      ...assetallocation
    }
  }
  ${assetallocationFragment}
`;

export const deleteOneAssetallocation = gql`
  mutation DeleteOneAssetallocation(
    $where: assetallocationWhereUniqueInput!
  ) {
    deleteOneAssetallocation(where: $where) {
      ...assetallocation
    }
  }
  ${assetallocationFragment}
`;
