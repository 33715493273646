import React from "react";
import { StyledText } from "../../../StyledText";
import { checkFieldIsEmpty } from "../../../../../Functions/GeneralFunctions";
import { Theme, FindManyChildQuery } from "../../../../../codegen/schema";
import { ChildTemplateType } from "..";

interface ChildComponentProps {
  theme?: Theme;
  children: FindManyChildQuery["findManyChild"] | ChildTemplateType[];
}

export const ChildContent: React.FC<ChildComponentProps> = ({
  theme,
  children,
}) => {
  return (
    <React.Fragment>
      <div
        className="flex flex-col w-full"
        style={{
          borderBottom: "1px solid " + (theme?.colour[0]?.Value ?? "#000000"),
        }}
      >
        <StyledText
          element={theme?.element.find((element) => element.Type === "H4")}
        >
          Children
        </StyledText>
      </div>

      <div className="grid grid-cols-4 gap-2 items-center border-b border-neutral-200 w-full">
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
        >
          Name
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
        >
          DOB
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
        >
          Gender
        </StyledText>
        <StyledText
          element={theme?.element.find((element) => element.Type === "H5")}
        >
          Dependent to Age
        </StyledText>
      </div>

      {children.length > 0
        ? children.map((child) => (
            <React.Fragment key={child.ID}>
              <div className="grid grid-cols-4 gap-2 items-center border-b border-neutral-200 w-full">
                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {child.FirstName} {child.LastName}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(child.DOB, "date")}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {child.Gender}
                </StyledText>

                <StyledText
                  element={theme?.element.find(
                    (element) => element.Type === "P"
                  )}
                >
                  {checkFieldIsEmpty(child.DependantToAge)}
                </StyledText>
              </div>
            </React.Fragment>
          ))
        : ""}
    </React.Fragment>
  );
};
